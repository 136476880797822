<div class="grid-search-bar">
    <div class="border-left border-top border-bottom rounded-left d-flex">
        <div class="category-section m-auto position-relative">
            <button ngxDocumentClickListner id="step-cat" (clickElsewhere)="closeEvent($event)" class="category-btn d-flex align-items-center cursor-p" (click)="toggelDropdown()">
          <span>All</span> <nb-icon icon="arrow-ios-downward-outline">

          </nb-icon>
        </button>
            <div *ngIf="showDropDown">
                <ngx-header-drop-list [categoryList]="categoryList" [config]="configDropdownList" (getEvent)="performAction($event)"></ngx-header-drop-list>
            </div>
        </div>
    </div>
    <div class="header-search">
        <div class="search-bar" id="step-search">
            <input type="text" class="form-control" (keyup.enter)="prepareSearchData($event.target.value)" [formControl]="searchControl" placeholder="Search content" />
            <span class="search-bar-cancel" *ngIf="searchTerm !== ''" (click)="clearSearch()">
              <nb-icon icon="close-outline" class="mr-2"></nb-icon>
            </span>
        </div>
        <div class="keyword-suggestion-box" *ngIf="suggestionEnable">
            <nb-list>
                <nb-list-item *ngFor="let item of suggestionList" (click)="prepareSearchData(item?.key)">
                    <div class="cursor-p">
                        <nb-icon icon="search-outline" class="mr-2"></nb-icon> {{ item?.key }}
                    </div>
                </nb-list-item>
            </nb-list>
        </div>
    </div>
</div>