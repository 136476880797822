<div class="p-2 rounded">
    <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex font-weight-normal m-0">
            <svg-icon svgClass="stroke-body lh-1" [svgStyle]="{ 'width.rem': .8, 'height.rem': .8 }"
                src="assets/icon-svg/filter.svg">
            </svg-icon>
            <span class="body-text fs-14 px-2">{{config?.filterTitle}}</span>
        </div>
        <span class="fs-12 font-weight-normal body-text cursor-p" (click)="clearAll()">Clear
            All</span>
    </div>

    <!-- <ng-container *ngIf="parentItem?.tabIds[selectedTab]"> -->
    <ng-container *ngFor="let parentItem of config?.innerFilterData; let index=index; let last=last">

        <!-- <div class="py-3" [ngClass]="{'border-bottom': !last}" *ngIf="parentItem?.tabIds?.includes(selectedTab)"> -->
        <div class="py-3" [ngClass]="{'border-bottom': !last}" *ngIf="parentItem?.tabIds[selectedTab]">

            <div class="d-flex" *ngIf="parentItem?.showParentSectionTitle == 1"
                (click)="toggelSectionCollpase(parentItem)">
                <svg-icon svgClass="stroke-gray cursor-p lh-1 p-1 pl-0 rotate-360" *ngIf="parentItem?.expanded == 1"
                    src="assets/icon-svg/chevron-down.svg" [svgStyle]="{'width.rem': 1.1, 'height.rem': 1.1}">
                </svg-icon>
                <svg-icon svgClass="stroke-gray cursor-p lh-1 p-1 pl-0 rotate-180" *ngIf="parentItem?.expanded == 0"
                    src="assets/icon-svg/chevron-right.svg" [svgStyle]="{'width.rem': 1.1, 'height.rem': 1.1}">
                </svg-icon>
                <div class="d-flex align-items-center justify-content-between w-100">
                    <span
                        class="fs-14 body-text font-weight-bold flex-grow-1 px-2 cursor-p">{{parentItem?.parentSectionTitle}}</span>
                    <!-- <span class="fs-14 text-common cursor-p">Clear</span> -->
                </div>
            </div>

            <div class="inner position-relative pl-4 mt-2" [hidden]="parentItem?.expanded == 0">
                <ng-container *ngIf="parentItem?.type == 1 || parentItem?.type == 2">

                    <div class="d-flex align-items-center lh-1 border rounded mb-3 px-2 py-1"
                        *ngIf="parentItem?.showSearchBar == 1">
                        <svg-icon svgClass="stroke-body lh-0 pe-1" [svgStyle]="{ 'width.rem': .8 }"
                            src="assets/icon-svg/search.svg">
                        </svg-icon>
                        <input class="fs-12 text-gray-100 border-0 outline-none px-2 w-100"
                            [(ngModel)]="parentItem.searchTerm" type="text"
                            placeholder="{{parentItem?.searchPlaceholder}}">
                        <svg-icon *ngIf="parentItem.searchTerm !=''" src="assets/icon-svg/x.svg"
                            svgClass="stroke-gray fill-text cursor-p" [svgStyle]="{'width.rem': .5, 'height.rem': .5}"
                            (click)="parentItem.searchTerm = ''">
                        </svg-icon>
                    </div>

                    <ng-container *ngIf="parentItem?.list?.length <= 5">
                        <ng-container
                            *ngFor="let item of parentItem?.list| advFilterPipe:parentItem?.searchTerm:parentItem?.localFilterParams:parentItem?.filterMetadata;let indexj=index">
                            <div class="checkBtn d-flex align-items-center pb-1 mb-2" *ngIf="parentItem?.type == 2">
                                <div class="checkbox d-inline-block align-top cursor-p">
                                    <span class="fs-12 text-gray text-capitalize">{{ item[parentItem['displayKey']]
                                        }}</span>
                                    <input type="checkbox" [value]="item?.selected" [checked]="item.selected == 1"
                                        (change)="applyFilterData($event, item, parentItem)">
                                    <span class="checkmark"></span>
                                </div>
                            </div>

                            <div class="radioBtn pb-1 mb-1 cursor-p" *ngIf="parentItem?.type == 1">
                                <label class="font-weight-normal m-0">
                                    <!-- [(ngmodel)]="parentItem.selectedValue" -->
                                    <input class="radioBtn--input m-0" type="radio"
                                        (change)="applyFilterData($event, item, parentItem)"
                                        [checked]="item[parentItem['paramterValueKey']] == parentItem.selectedValue"
                                        [value]="item[parentItem['paramterValueKey']]" name="filterRadio-{{index}}" />
                                    <span class="radioBtn--mark"></span>
                                    <span
                                        class="radioBtn--title fs-12 ml-2 cursor-p">{{item[parentItem['displayKey']]}}</span>
                                </label>
                            </div>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="parentItem?.list?.length > 5">
                        <cdk-virtual-scroll-viewport style="height: 270px;" itemSize="10">
                            <ng-container
                                *cdkVirtualFor="let item of parentItem?.list| advFilterPipe:parentItem?.searchTerm:parentItem?.localFilterParams:parentItem?.filterMetadata;let indexj=index">
                                <div class="checkBtn d-flex align-items-center pb-1 mb-2" *ngIf="parentItem?.type == 2">
                                    <div class="checkbox d-inline-block align-top cursor-p">
                                        <span class="fs-12 text-gray text-capitalize">{{ item[parentItem['displayKey']]
                                            }}</span>
                                        <input type="checkbox" [value]="item?.selected" [checked]="item.selected == 1"
                                            (change)="applyFilterData($event, item, parentItem)">
                                        <span class="checkmark"></span>
                                    </div>
                                </div>

                                <div class="radioBtn pb-1 pl-3 mb-2 cursor-p" *ngIf="parentItem?.type == 1">
                                    <label class="fw-500 m-0">
                                        <!-- [(ngmodel)]="parentItem.selectedValue" -->
                                        <input class="radioBtn--input m-0" type="radio"
                                            (change)="applyFilterData($event, item, parentItem)"
                                            [checked]="item[parentItem['paramterValueKey']] == parentItem.selectedValue"
                                            [value]="item[parentItem['paramterValueKey']]"
                                            name="filterRadio-{{index}}" />
                                        <span class="radioBtn--mark"></span>
                                        <span
                                            class="radioBtn--title fs-12 ml-2 cursor-p">{{item[parentItem['displayKey']]}}</span>
                                    </label>
                                </div>
                            </ng-container>
                        </cdk-virtual-scroll-viewport>
                    </ng-container>

                    <div [hidden]="parentItem?.filterMetadata?.count != 0">
                        {{parentItem?.noDataContainerMessage}}
                    </div>

                </ng-container>

                <ng-container *ngIf="parentItem?.type == 3">
                    <div class="input-group">
                        <!-- (ngModelChange)="applyFilterData($event, null, parentItem)" [(ngModel)]="parentItem.selectedValue" -->
                        <input class="form-control form-control-sm fs-12" nbInput placeholder="Pick Date Range"
                            [nbDatepicker]="formpicker"
                            [(ngModel)]="selectedFilterValue[parentItem?.parameterKey].selectedDate"
                            (ngModelChange)="dateChanged($event, null, parentItem)" [readonly]="isLoading"
                            id="dateRangePicker">

                        <!-- [(range)]="selectedFilterValue[parentItem?.parameterKey].selectedDate"  (rangeChange)="applyFilterData($event, null, parentItem)"-->

                        <nb-rangepicker #formpicker></nb-rangepicker>

                        <div class="input-group-append cursor-p">
                            <svg-icon *ngIf="selectedFilterValue[parentItem?.parameterKey]?.selectedValue?.length == 0"
                                class="input-group-text" svgClass="stroke-gray" src="assets/icon-svg/calender.svg"
                                [svgStyle]="{'width.rem': .8, 'height.rem': .8 }"></svg-icon>
                            <svg-icon *ngIf="selectedFilterValue[parentItem?.parameterKey]?.selectedValue?.length != 0"
                                class="input-group-text" svgClass="stroke-gray" src="assets/icon-svg/x.svg"
                                [svgStyle]="{'width.rem': .8, 'height.rem': .8 }"
                                (click)="clearDate(null, null, parentItem)">
                            </svg-icon>
                        </div>

                    </div>
                </ng-container>

            </div>

        </div>

    </ng-container>

</div>