import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  Input,
  Output,
  EventEmitter,
  // ViewChild,
  // TemplateRef,
} from '@angular/core';
import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours,
} from 'date-fns';
import {
  // CalendarEvent,
  // CalendarEventAction,
  // CalendarEventTimesChangedEvent,
  CalendarView,
} from 'angular-calendar';



const colors: any = {
  // red: {
  //   primary: '#ad2121',
  //   secondary: '#FAE3E3',
  // },
  // blue: {
  //   primary: '#1e90ff',
  //   secondary: '#D1E8FF',
  // },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};

@Component({
  selector: 'ngx-events-calendar',
  templateUrl: './events-calendar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./events-calendar.component.scss', '../../@theme/styles/_typography.scss']
})
export class EventsCalendarComponent implements OnInit {


  inputData: any = [];

  @Output() actionEvent = new EventEmitter();
  openForm: boolean = false
  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;
  currentUserData: any = null;
  viewDate: Date = new Date();
  beforeViewRenderCalled = false;
  previousDate = null;
  // selectedEventIndex = null;
  @Input() events = [
    {
      start: new Date(),
      end: new Date(),
      title: 'A 3 day event', // Meeting Name
      color: {
        primary: '#e3bc08',
        secondary: '#FDF1BA',
      },
      // customData: 13,
      // meetingName: "",
      // startDateTime: "",
      // endDateTime: "",
      // description: "",
      meetingUrl: "",
      participantName: "",
      // actions: this.actions,
      allDay: true,
      eventId: '', // Event id
      vivaTopicId: '', // Viva Topic id
      // vivaTopicName: '', // Viva Topic Name
      meetingDescription: '', // Description Name
      // participantName:'',
    },
    {
      start: startOfDay(new Date()),
      title: 'An event with no end date',
      color: colors.yellow,
      customData: 13,
      meta: {
        eventId: '', // Event id
        vivaTopicId: '', // Viva Topic id
        vivaTopicName: '', // Viva Topic Name
        meetingDescription: '', // Description Name
      }
    },
    {

      title: 'A long event that spans 2 months', // Meeting Name
      start: subDays(endOfMonth(new Date()), 3),
      end: addDays(endOfMonth(new Date()), 3),
      color: colors.yellow,
      customData: 13,
      allDay: true,
      meta: {
        eventId: '', // Event id
        vivaTopicId: '', // Viva Topic id
        vivaTopicName: '', // Viva Topic Name
        meetingDescription: '', // Description Name
      }
    },
    {

      title: 'A draggable and resizable event', // Meeting Name
      start: addHours(startOfDay(new Date()), 2),
      end: addHours(new Date(), 2),
      color: colors.yellow,
      customData: 13,
      meta: {
        eventId: '', // Event id
        vivaTopicId: '', // Viva Topic id
        vivaTopicName: '', // Viva Topic Name
        meetingDescription: '', // Description Name
      }
    },
  ];

  activeDayIsOpen: boolean = true;
  ngOnInit() {
    this.setView(CalendarView.Week);
    this.getUserData();
  }
  getUserData() {
    this.currentUserData = JSON.parse(localStorage.getItem('currentUser'));
  }
  // actions: CalendarEventAction[] = [
  //   {
  //     label: '<i class="fas fa-fw fa-pencil-alt"></i>',
  //     a11yLabel: 'Edit',
  //     onClick: ({ event }: { event: CalendarEvent }): void => {
  //       this.handleEvent('Edited', event);
  //     },
  //   },
  //   {
  //     label: '<i class="fas fa-fw fa-trash-alt"></i>',
  //     a11yLabel: 'Delete',
  //     onClick: ({ event }: { event: CalendarEvent }): void => {
  //       this.events = this.events.filter((iEvent) => iEvent !== event);
  //       this.handleEvent('Deleted', event);
  //     },
  //   },
  // ];

  // refresh: Subject<any> = new Subject();
  // : CalendarEvent[]


  constructor() { }

  dayClicked({ date, events }: { date: Date; events }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  // eventTimesChanged({
  //   event,
  //   newStart,
  //   newEnd,
  // }: CalendarEventTimesChangedEvent): void {
  //   this.events = this.events.map((iEvent) => {
  //     if (iEvent === event) {
  //       return {
  //         ...event,
  //         start: newStart,
  //         end: newEnd,
  //       };
  //     }
  //     return iEvent;
  //   });
  //   this.handleEvent('Dropped or resized', event);
  // }
  // : CalendarEvent
  handleEvent(action: string, event): void {
    console.log(action, 'handleEvent', event);
    // if (this.currentUserData.roleId == 8) {
    //   this.openPopup(event);
    // }
    // if (this.currentUserData.roleId == 7) {
    //   this.openEventForm(event);
    // }
    this.fireEvent('eventClicked', event);
  }

  // addEvent(event): void {
  //   this.events = [
  //     ...this.events,
  //     event
  //   ];
  // }

  // deleteEvent(eventToDelete) {
  //   this.events = this.events.filter((event) => event !== eventToDelete);
  // }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  hourSegementClicked(calenderEvent) {
    console.log('hourSegementClicked', calenderEvent);
    // if (this.currentUserData.roleId == 7) {
    //   let days = 1;
    //   let event = {
    //     title: 'New event', // Meeting Name
    //     start: startOfDay(calenderEvent),
    //     end: endOfDay(new Date(calenderEvent + days * 24 * 60 * 60 * 1000)),
    //     color: colors.yellow,
    //     customData: 13,
    //     meta: {
    //       eventId: '', // Event id
    //       vivaTopicId: '', // Viva Topic id
    //       vivaTopicName: '', // Viva Topic Name
    //       meetingDescription: '', // Description Name
    //     }
    //   };
    //   this.addEvent(event);
    //   this.toggelForm(true);
    // }
    this.fireEvent('dateClicked', calenderEvent);
  }


  // openEventForm(event) {
  //   console.log('openEventForm', event);
  //   // this.addEvent();
  //   this.toggelForm(true);
  // }

  // toggelForm(event) {
  //   this.openForm = event;
  // }

  fireEvent(inEvent, inData) {
    const res = {
      type: inEvent,
      data: inData,
    };
    this.actionEvent.emit(res);
  }


  dayCalender(event) {
    console.log('beforeViewRender', event);
    if (this.previousDate) {
      console.log('this.previousDate.period.end', this.previousDate.period.end);
      console.log('event.period.end', event.period.end);
      if (this.previousDate.period.end - event.period.end != 0) {
        this.previousDate = event;
        this.fireEvent('getCalenderEvents', event.period);
      }
    } else {
      this.previousDate = event;
      this.fireEvent('getCalenderEvents', event.period);
    }
  }
  // this.actionEvent.emit(event);

}
