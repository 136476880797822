import { Component, Input, OnInit, Output,EventEmitter } from '@angular/core';
@Component({
    selector: 'app-filter',
    templateUrl: './filter.component.html',
    styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {
    @Input() filterList: any = [];
    @Input() openFilter: boolean = false;
    @Output() GetResult = new EventEmitter();
    constructor() { }

    ngOnInit() {
        if(!this.filterList){
            this.filterList = [];
        }
     }

    filterObj: any = {}
    filterParams: any = {};
    changeCheckbox(item, i) {
        if (!item.selected) {
            if (this.filterObj[item.filterId] && this.filterObj[item.filterId].length > 0) {
                this.filterObj[item.filterId].push(item.id);
            } else {
                this.filterObj[item.filterId] = [];
                this.filterObj[item.filterId].push(item.id);
            }
        } else {
            if (this.filterObj[item.filterId] && this.filterObj[item.filterId].length > 0) {
                var index;
                this.filterObj[item.filterId].forEach((element, key) => {
                    if (element === item.id) {
                        index = key;
                    }
                });
                if (index != undefined) this.filterObj[item.filterId].splice(index, 1);
            }
        }
        item.selected = !item.selected;
        for (let x in this.filterObj) {
            this.filterParams[x] = "";
            this.filterParams[x] = this.filterObj[x].join();
        }
        if(this.GetResult){
            this.GetResult.emit(this.filterParams);
        }
    }
    clearAll() {
        this.filterList.forEach(element => {
            element.forEach(value => {
                value.selected = 0;
            });
        });
        this.filterParams = {};
        this.filterObj = {};
        if(this.GetResult){
            this.GetResult.emit(this.filterParams);
        }
    }
}