<!-- <p>mark-attendance works!</p> -->

<!-- <ng-container *ngIf="showAttendanceComponent"> -->
<ng-container *ngIf="attendanceComponentType == 7">
    <!-- <ng-container *ngIf="activity?.byTrainer == 1"> -->
    <ngx-common-fetch-list *ngIf="activity?.isInduction == 0" [externalHeight]="externalHeight" class="w-100"
        [paramsObject]="activity" [type]="type" (getEvents)="getEvents($event)"></ngx-common-fetch-list>

    <ngx-attendance-induction *ngIf="activity?.isInduction == 1" [externalHeight]="externalHeight" class="w-100"
        [paramsObject]="activity" [type]="type" (getEvents)="getEvents($event)"></ngx-attendance-induction>
    <!-- </ng-container> -->
</ng-container>

<ng-container *ngIf="attendanceComponentType == 8">
    <!--  *ngIf="activity?.byLearner == 1" -->
    <div class="w-100">
        <ngx-mark-attendance-learner [courseActivityData]="activity" [courseModuleActivityData]="moduleActivity"
            (getEvent)="getEvents($event)"></ngx-mark-attendance-learner>
    </div>
</ng-container>