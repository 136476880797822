import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { SharedService } from '../../../modules/shared/providers/shared.service';
// import { ToasterService } from '../../../providers/toaster/toaster.service';

@Component({
  selector: 'ngx-input-w-submit',
  templateUrl: './input-w-submit.component.html',
  styleUrls: ['./input-w-submit.component.scss']
})
export class InputWSubmitComponent implements OnInit, OnChanges {
  @Input() label;
  @Input() data;
  oldValue = '';
  setting = {
    type: 'number',
    max: 999,
    min: 0
  }

  isEditable = false;

  @Output() performAction = new EventEmitter<any>();

  isListnerAdded = false;

  pattern = [
    {
      pattern: '^[a-zA-Z._- ]+$',
      msg: `Only letters and _-. are allowed`,
      type: 'custom1'
    },
    {
      pattern: '^[a-zA-Z0-9._- ]+$',
      msg: `Only letters, numbers and _-. are allowed`,
      type: 'custom2'
    },
    {
      pattern: '^[a-zA-Z0-9 ]+$',
      msg: `Special characters not allowed`,
      type: 'specialCaharcter'
    },
    {
      pattern: '^[a-zA-Z ]+$',
      msg: `Numbers are not allowed`,
      type: 'numbers'
    }
  ]

  constructor(
    // private toaster: ToasterService,
    private sharedService: SharedService
  ) { }

  ngOnInit(): void {
    if (typeof this.label.componentAttribute === "string" && this.label.componentAttribute) {
      this.label.componentAttribute = JSON.parse(this.label.componentAttribute);
    }
  }

  ngOnChanges(changes: any) {
    console.log('InputWSubmitComponent label : ', this.label);
    console.log('InputWSubmitComponent data : ', this.data, this.data[this.label.bindingProperty]);

    if (this.data && this.data[this.label.bindingProperty]) {
      this.enableEdit(false);
    } else {
      this.enableEdit(true);
    }
  }

  checkForInputValidation(value) {
    // console.log('checkForInputValidation', this.oldValue);
    // console.log('checkForInputValidation', Number(this.data[this.label.bindingProperty]));
    // console.log('Number', Number.isInteger(Number(this.data[this.label.bindingProperty])));
    // console.log('Number check', parseInt(this.data[this.label.bindingProperty]));
    // console.log('Number check', Number.isInteger(parseInt(this.data[this.label.bindingProperty])));
    if (this.label.componentAttribute.type == 'number') {
      if (value == '') {
        this.oldValue = this.data[this.label.bindingProperty];
        this.data[this.label.bindingProperty] = '';
      } else if (!Number.isInteger(Number(this.data[this.label.bindingProperty]))) {
        this.setResetData();
      } else if (this.label.componentAttribute.min > Number(value)) {
        this.setResetData();
      } else if (this.label.componentAttribute.max < Number(value)) {
        this.setResetData();
      } else {
        this.oldValue = this.data[this.label.bindingProperty];
      }
    } else if (this.label.componentAttribute.type == 'text') {
      if (value == '') {
        this.oldValue = this.data[this.label.bindingProperty];
        this.data[this.label.bindingProperty] = '';
      } else {
        this.oldValue = this.data[this.label.bindingProperty];
      }
    }
    // console.log('Number', this.data[this.label.bindingProperty]);
  }

  setResetData() {
    setTimeout(() => {
      this.data[this.label.bindingProperty] = this.oldValue;
    })
  }

  submit(event) {
    console.log('submit event: ', event);
    if (this.sharedService.isInputValid(this.data[this.label.bindingProperty], this.label.componentAttribute)) {
      this.emitAction(null);
    }
  }

  enableEdit(status) {
    // if (status == true && this.data[this.label.disableProperty] != 1) {
    //   this.isEditable = true;
    // } else {
    //   this.isEditable = status;
    // }
    this.isEditable = status;
    // setTimeout(() => {
    //   if (this.isEditable == true) {
    //     this.addClickListner();
    //   } else {
    //     this.removeClickListner();
    //   }
    // }, 500);
  }

  emitAction(item) {
    console.log('emitAction', item);
    const event = {
      action: 'inputSubmit',
      data: item,
    }
    this.performAction.emit(event);
  }

  addClickListner() {
    this.removeClickListner();
    if (!this.isListnerAdded) {
      this.isListnerAdded = true;
      document.addEventListener("click", (evt) => {
        const flyoutEl = document.getElementById('text-div-' + this.data.userId);
        let targetEl: any = evt.target; // clicked element      
        // do {
        // if (targetEl == flyoutEl) {
        //   // This is a click inside, does nothing, just return.
        //   console.log("Clicked inside!");
        //   return;
        // }
        // // Go up the DOM
        // targetEl = targetEl.parentNode;
        //   } while (targetEl);{
        //   // This is a click outside.      
        //   console.log("Clicked outside!");
        //   this.enableEdit(false);
        // }
        if (targetEl == flyoutEl) {
          // This is a click inside, does nothing, just return.
          console.log("Clicked inside!");
        } else {
          console.log("Clicked outside!");
          this.enableEdit(!this.isEditable);
        }
      });
    }
  }

  removeClickListner() {
    document.removeEventListener("click", (evt) => {
      console.log('Event listner removed...', evt);
    });
  }

  listenClick(ev) {
    console.log('listenClick event...', ev);
    setTimeout(() => {
      this.enableEdit(false);
    }, 1000);
  }

  // async presentToastMessage(msg: string, title: string, type: string) {
  //   await this.toaster.prsentToast(msg, title, type, null);
  // }
}
