<!-- <p class="hover-underline cursor-p m-0" (click)="clickLink()">{{ link }}</p>
<p class="hover-underline cursor-p m-0" *ngIf="remove" (click)="clickRemove()">Remove</p>
<p class="hover-underline cursor-p m-0" *ngIf="edit" (click)="clickEdit()">Edit</p> -->
<div [class]="alignment">
    <ng-container *ngFor="let item of actions | jsonFormatter">
        <ng-container *ngIf="showCustomActionLabel">
            <span class="text-capitalize px-1"
                *ngIf="data[item.bindingProperty] == 0">{{data[item.bindingPropertyText]}}</span>
            <span class="hover-underline text-capitalize cursor-p px-1" (click)="emitAction(item)"
                *ngIf="data[item.bindingProperty] == 1">{{item.name}}</span>
            <span class="cursor-na px-1" *ngIf="data[item.bindingProperty] == 2">
                <span class="disable-pointer-events opacity-5">{{item.name}}</span>
            </span>
        </ng-container>
        <ng-container *ngIf="!showCustomActionLabel">
            <span class="hover-underline text-capitalize cursor-p px-1" (click)="emitAction(item)"
                *ngIf="!label">{{item.name}}</span>
            <span class="text-capitalize px-1" *ngIf="label">{{label}}</span>
        </ng-container>

        <!-- <span class="table-date">16 Jun 2021, 12:30 AM</span> -->
    </ng-container>
</div>