import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation, SimpleChanges, OnChanges, AfterViewInit } from '@angular/core';
import { SortablejsOptions } from 'sortablejs';
@Component({
  selector: 'ngx-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TableComponent implements OnInit, OnChanges {
  @Input() label: any = [];
  @Input() tableData: any = {};
  @Input() checkLabel: any;
  @Input() isChecked: any;
  @Input() paginationObject?= {
    itemsPerPage: 10,
    id: '',
  };
  @Input() setting = {
    // customPagination: false,
    // totalPages: 10,
    // currentPage: 1,
    // showCustomActionLabel: false,
    // noDatamsg: '',
    // sortRowAttr:'',
  };
  config = {
    // showArrow: true,
    customPagination: false,
    totalPages: 10,
    currentPage: 1,
    showCustomActionLabel: false,
    noDatamsg: '',
    sortRowAttrDisable: '',
    autoMarkCheckBox: false,
    expandBehaviour: 'accordian',
    childrenArrayKey: 'indirectReportees',
    numberOfChildrenKey: "indirectReporteesLimit"
  };
  @Input() sortRow = false;
  actionPerformed = '';

  p: any;
  @Input() pagination = true;

  @Output() getEvents = new EventEmitter<any>();
  // @Output() customCheckAllClicked = new EventEmitter<any>();

  @Input() allCheck: any = false;
  @Input() customCheckAll: any = false;
  @Input() customCheckAllFlag: boolean;

  selectedData: any = [];
  // @Input() checkAllFlag: boolean;
  sortListOptions: SortablejsOptions = {
    group: {
      name: 'childList',

    },
    sort: true,
    preventOnFilter: false,
    // draggable: '.draggable',
    // onEnd: this.onListItemDragEnd.bind(this),
    // handle: ".drag-handle",
    onUpdate: (event) => this.actionPerformed = 'subListSequenceChanged',
    // onAdd: (event) => console.log("activityListOptions onAdd", this.categories),
    // onRemove: (event) => this.actionPerformed = 'itemMovedToAnotherList',
    onEnd: (/**Event*/ evt) => {

      console.log("evt", evt);
      console.log("newIndex", evt.newIndex);
      console.log("oldIndex", evt.oldIndex);

      // console.log("oldDraggableIndex", evt.oldDraggableIndex);
      // console.log("newDraggableIndex",  evt.newDraggableIndex);
      // const parentIndex = Number(evt.item.getAttribute('parentIndex'));
      const subFieldIndex = Number(evt.item.getAttribute('subFieldIndex'));
      const item = this.tableData[subFieldIndex];
      const object = {
        // parentIndex: parentIndex,
        subFieldIndex: subFieldIndex,
      };
      console.log("moduleIndex", object);
      // console.log("activityIndex",  activityIndex);
      console.log("element", item);
      if (this.actionPerformed != "") {
        this.passDataToParent(this.actionPerformed,
          this.tableData, object, item);
        this.actionPerformed = "";
      }
    },
    filter: '.filtered',
    // onStart: function (/**Event*/ evt) {
    //   console.log("onStart", evt);
    // },
    // onEnd: function (/**Event*/ evt) {
    //   console.log("onEnd", evt);
    // },
    // draggable: '.draggable'
    onMove: function (e) {
      return !e.related.classList.contains('filtered');
    },
  };

  labelFilter: boolean = false;
  @Output() getAllRecordEvents = new EventEmitter<any>()
  constructor() { }

  ngOnInit(): void {
    this.config = { ...this.config, ...this.setting };
    console.log(this.config)
  }

  sendEvents(action, data, checked) {

    const event = {
      action: action,
      data: data,
      checked: checked
    }
    console.log('sendEvents', event)
    this.getEvents.emit(event);

  }

  sendCheckedList(data) {
    // if (this.selectedData.length === this.tableData.length) {
    //   this.checkAllFlag = true;
    //   console.log('Checked All Changed:', this.checkAllFlag);
    // } else {
    //   this.checkAllFlag = false;
    //   console.log('Checked All Changed:', this.checkAllFlag);
    // }
    // this.selectedData = [...this.tableData];
    // const event = {
    //   action: type,
    //   data: this.selectedData,
    // }
    this.sendEvents('selectedList', data, false);
  }

  sendCheckData(action, data, checked, label) {
    let event = {
      action: 'singelSelectData',
      on: action,
    }
    // const event = {
    //   action: ,
    //   data: data,
    //   // checked: checked
    // }
    if (this.config.autoMarkCheckBox) {
      data[action] = checked;
    }
    if (label['dontShowCheckAll'] != 1) {
      if (this.tableData) {
        let markAll = true;

        this.selectedData = [];

        for (let index = 0; index < this.tableData.length; index++) {
          const element = this.tableData[index];
          if (element[action] != 1 || element[action] != true) {
            markAll = false;
            // break;
          }
          if (element[action] == 1 || element[action] == true) {
            this.selectedData.push(element);
          }
        }
        label['checkAll'] = markAll;
      }
    }

    this.sendEvents(event, data, checked);

    this.sendCheckedList(this.selectedData);
  }

  prepareDate(data, propertyClick) {
    const action = {
      name: propertyClick,
      action: 'propertyClick'
    }
    const event = {
      action: action,
      data: data,
    }
    console.log('sendEvents', event)
    this.getEvents.emit(event);
  }

  selectAll(ev, label) {
    console.log('tableData==================>', this.tableData, label);
    this.selectedData = [];
    if (this.tableData) {
      this.tableData.forEach(element => {
        if (label['disableProperty'] && element[label['disableProperty']] != 1) {
          element[label['bindingProperty']] = ev;
        }
      });

      if (ev == 1) {
        this.selectedData = this.tableData;
      }

    };
    label.checkAll = ev;
    let action = {
      action: 'checkUncheckAll'
    };
    const data = {
      selectedOnProperty: label['bindingProperty'],
      action: ev,
    }
    // if (this.selectedData.length === this.tableData.length) {
    //   this.checkAllFlag = true;
    //   console.log('Checked All Changed:', this.checkAllFlag);
    // } else {
    //   this.checkAllFlag = false;
    //   console.log('Checked All Changed:', this.checkAllFlag);
    // }
    // this.selectedData = [...this.tableData];
    // const event = {
    //   action: type,
    //   data: this.selectedData,
    // }
    this.sendEvents(action, data, false);

    this.sendCheckedList(this.selectedData);

  }

  customCheckAllEmit(event) {
    // this.customCheckAllFlag = event;
    // this.customCheckAllClicked.emit(event);
  }

  preparePaginationData(action) {
    const pageData = {
      currentPage: this.config?.currentPage,
      totalPage: this.config?.totalPages,
    }
    let action2 = {
      action: action
    };
    // console.log('sendEvents', event);
    this.sendEvents(action2, pageData, false);
  }

  passDataToParent(action, ...args) {
    console.log('passDataToParent', action, args);
    this.sendEvents(action, args, false);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.setting) {
      this.config = { ...this.config, ...this.setting };
    }
  }

  filterDropdown(event, item) {
    item.viewFilter = event;
    console.log('this.labelFilter ====', item);
  }

  clickOutside() {
    // this.labelFilter = false;
    console.log('click outside ====', this.labelFilter);
    // for
  }

  performActionOnFilter(item, filter, selected) {
    console.log('performActionOnFilter', item, filter);
    item.filtervalue.forEach(filterValue => {
      filterValue['selected'] = 0
    });
    filter['selected'] = selected == 1 ? 0 : 1;
    this.filterDropdown(false, item)
    let filterObject = {};
    this.label.forEach(element => {
      if (element.showfilter == 1) {
        element.filtervalue.forEach(filterValue => {
          if (filterValue['selected'] == 1) {
            filterObject[filterValue.filterKey] = filterValue.id;
          }
        });
      }
    });
    console.log('filterObject', filterObject);
    this.sendEvents('filtersApplied', filterObject, false);
  }

  expandEvents(data, action) {
    // debugger
    console.log('expandEvents', data)
    data.isExpandable = true
    if (this.config.expandBehaviour == 'accordian') {
      this.tableData.forEach(element => {
        element.isExpandable = true;
      });
    }
    data.isExpandable = action ? false : true;
  }

  viewAllRecords(propertyClick, data) {
    const action = {
      name: propertyClick,
      action: 'viewall'
    }
    const event = {
      action: action,
      data: data,
    }

    this.getEvents.emit(event);
  }

  dropdownSelected(event, item) {
    // this.sendEvents(event.action, event.data, false);
    const data = {
      rowData: item,
      eventData: event.data
    }
    this.sendEvents(event.action, data, false);
  }

  inputSubmit(event, item) {
    // this.sendEvents(event.action, event.data, false);
    const data = {
      rowData: item,
      eventData: event.data
    }
    this.sendEvents(event.action, data, false);
  }
}
