import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { SharedService } from '../../providers/shared.service';
import { HelperService } from '../../../../providers/helper/helper.service';

@Component({
  selector: 'ngx-banner-details',
  templateUrl: './banner-details.component.html',
  styleUrls: ['./banner-details.component.scss']
})
export class BannerDetailsComponent implements OnInit, OnChanges {

  // @Input() inputData: any;
  @Output() actionEvent = new EventEmitter();

  @Input() config: any = null;

  inputData: any;

  closeEvent = {
    action: "close",
    btnText: "",
    classList: ''
  };

  popupConfig = {
    header: false,
    title: '',
    footer: false,
    transparentHeader: {
      show: true,
    },
  };

  loading = false;
  notFound = false;

  medialPlayerOptions = {
    controls: ['play', 'progress', 'current-time', 'mute', 'volume'],
  };

  constructor(
    protected ref: NbDialogRef<BannerDetailsComponent>,
    private helperService: HelperService,
  ) { }

  ngOnInit() {
    if (this.config.inputData) {
      this.inputData = this.config.inputData;
      console.log('BannerContentSheetComponent inputData : ', this.inputData);
      if (this.inputData) {
        this.loading = true;
        this.preapreData(this.inputData);
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('BannerContentSheetComponent : ', changes);
    // if (this.inputData && this.inputData.extraData) {
    //   this.inputData.extraData = JSON.parse(this.inputData.extraData);
    // }
    if ('config' in changes) {
      if (this.config.inputData) {
        this.inputData = this.config.inputData;
        console.log('BannerContentSheetComponent inputData : ', this.inputData);
      }
    }
    if (this.inputData) {
      this.loading = true;
      this.preapreData(this.inputData);
    }
  }

  preapreData(banner) {
    if (banner.messageMediaType == 1 || banner.messageMediaType == 7) {
      let link = banner.extraData.reference;
      this.helperService.generatePresignedUrl(link, (ref, type) => {
        this.loading = false;
        if (type) {
          banner.extraData.reference = ref;
          banner.messageMedia = ref;
        } else {
          console.log('getPreSignedUrl err...');
          this.notFound = true;
        }
      });
    } else {
      this.loading = false;
    }
  }

  // cancel() {
  //   this.fireEvent('cancel', null);
  // }

  // next() {
  //   this.fireEvent('next', this.inputData);
  // }

  performActions(button) {
    let data = {
      buttonData: button,
      eventData: this.inputData,
    }
    this.fireEvent('buttonClicked', data);
  }

  fireEvent(inEvent, inData) {
    const res = {
      type: inEvent,
      data: inData,
    };
    this.actionEvent.emit(res);
  }

  performActionPopup(event) {
    console.log('performActionPopup', event);
    switch (event.type) {
      case 'close':
        this.toggelPopup(false);
        break;
      default: console.log('invalid performAction', event);
        break;
    }
  }

  toggelPopup(event: boolean) {
    // this.popup = event;
    this.ref.close(null);
  }

  errorMessage(e) {
    console.log('errorMessage : ', e);
    this.notFound = true;
  }

}
