import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AssignmentService } from '../../providers/assignment/assignment.service';
import { ToasterService } from "../../providers/toaster/toaster.service";
import { HelperService } from "../../providers/helper/helper.service";
@Component({
  selector: 'ngx-assignment-actvity',
  templateUrl: './assignment-actvity.component.html',
  styleUrls: ['./assignment-actvity.component.scss']
})
export class AssignmentActvityComponent implements OnInit {
  @Input() activity: any;
  @Output() saveEvent = new EventEmitter<any>();
  assigementFileName = '';
  uploadedFile = null;
  // showSubmit = false;
  removeFromS3BucketList = [];
  percentage = '0';
  uploadingInProgress = false;
  userFileName = '';
  showContainer = false;
  dependencyConatiner = {
    displayFlag: false,
    subMessage: "",
    message: "",
    icon: "",
  };
  constructor(private assignmentService: AssignmentService, private toaster: ToasterService, private helperService: HelperService) { }

  ngOnInit(): void {
    console.log('activity', this.activity);
    this.resetValues();
    this.setIntialData();
  }

  setIntialData() {
    this.assigementFileName = this.getFileName(this.activity.reference);
    this.userFileName = this.activity.useruploadfile ? this.getFileName(this.activity.useruploadfile) : '';
    if (this.activity.isDeleted == 1 || this.activity.completionstatus == 'Y' || this.activity.assallowweb == 0) {
      this.setDependancyContainerData();
    } else {
      this.showContainer = false;
    }
  }

  getMonthName(index) {
    const monthShortNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return monthShortNames[index]
  }

  /*************** Set Dependancy Container Data ************/
  setDependancyContainerData() {
    this.showContainer = true;
    this.dependencyConatiner.displayFlag = true;
    if (this.activity.sessionendflag == 1) {
      this.dependencyConatiner.subMessage = "The activity submission date ended on " + this.activity.sessionenddate;
    }
    if (this.activity.isDeleted == 1) {
      this.dependencyConatiner.message = this.activity.isDeletedMsg;
      this.dependencyConatiner.icon = this.activity.actIcon;
    } else if (this.activity.assallowweb == 0) {
      this.dependencyConatiner.message = this.activity.actvismsg;
      this.dependencyConatiner.icon = this.activity.actIcon;
    }
    else {
      let dateCompleted;

      const date = new Date(this.activity.completedDate);
      if (this.activity.completedDate) {
        this.dependencyConatiner.icon = "complete";
        dateCompleted =
          date.getDate() +
          "-" +
          this.getMonthName(date.getMonth()) +
          "- " +
          date.getFullYear();
      } else {
        dateCompleted = "NA";
      }

      this.dependencyConatiner.message = "Completed on " + dateCompleted;
      this.dependencyConatiner.icon = 'complete';
    }
  }


  /*************** Set Dependancy Container Data END ************/

  resetValues() {
    // this.assigementFileName = this.getFileName(this.activity.reference);
    this.userFileName = '';
    // this.showSubmit = false;
    this.uploadedFile = null;
    this.percentage = '0';
    this.uploadingInProgress = false;
    this.removeFromS3BucketList = [];
  }


  /*************** Read File Resource ************/
  readFile(event: any) {
    const size = '10485760';
    const validExts = String(this.activity.validfiletype).split(',');
    console.log('validExts', validExts)
    let fileName = event.target.files[0].name;
    // console.log('readFile', event);
    let fileExt = String(fileName).toLocaleLowerCase().substring(fileName.lastIndexOf('.') + 1);
    if (validExts.indexOf(fileExt) < 0) {
      // this.presentToastMessage('Valid files are ' + validExts.toString(), "Warning", 'warning');
      this.presentToastMessage('Please upload a valid file type.', "Warning", 'warning');
    } else if (size <= event.target.files[0].size) {
      this.presentToastMessage('File size should be less than 100 MB', "Warning", 'warning');
    } else {
      if (event.target.files && event.target.files[0]) {
        this.userFileName = fileName;
        this.uploadedFile = event.target.files[0];
        if (this.activity.useruploadfile) {
          const obj = {
            instanceId: this.activity.instanceId,
            fileRef: this.activity.useruploadfile,
            fileRefKey: null,
          };
          this.removeFromS3BucketList.push(obj);
          this.activity.useruploadfile = '';
        }
      }
    }
  }

  /*************** Read File Resource END ************/

  /*************** Remove Selected File  ************/
  removeFile(event) {
    if (event) {
      event.stopPropagation();
      if (this.activity.useruploadfile) {
        const obj = {
          instanceId: this.activity.instanceId,
          fileRef: this.activity.useruploadfile,
          fileRefKey: null,
        };
        this.removeFromS3BucketList.push(obj);
        this.activity.useruploadfile = '';
      }
      // this.userFileName = '';
      this.resetValues();
    }
  }

  /*************** Remove Selected File END ************/

  getFileName(url) {
    return url
      ? url.substring(
        url.lastIndexOf("/") + 1
      ) : '';
  }

  /*************** Download Resource ************/
  downloadResource(link) {
    // const link = ;
    // if (link) {
    //   const element = document.createElement('a');
    //   const filename = link.replace(/^.*[\\\/]/, '');
    //   element.href = link.replaceAll(' ', '%20');
    //   element.download = 'download';
    //   element.style.display = 'none';
    //   // element.setAttribute('target', '_blank');
    //   document.body.appendChild(element);
    //   element.click();
    //   document.body.removeChild(element);
    // } else {
    //   console.log('Download link not available...');
    // }
    this.helperService.downloadResource(link, 2);
  }
  /*************** Download Resource END ************/

  /*************** Submit Assignment Activity ************/
  submitAssignment() {
    const params = this.assignmentService.populateAssignmentDetails(this.activity, this.uploadedFile, this.removeFromS3BucketList);
    console.log('params', params);
    this.uploadingInProgress = true;
    this.assignmentService.submitAssignment(params).subscribe((result) => {
      if (result.status == 'progress') {
        this.percentage = result.percentage > 99 ? result.percentage : 99;
        // if(result.percentage > 99)
      }
      if (result.status == 'completed') {
        this.resetValues();
        this.updateActivityDetails(result.data, result.data.data);
        console.log('submitAssignment result : ', result);
      }

    }, (error) => {
      // if (result.status == 'error') {
      // this.resetValues();
      // this.updateActivityDetails(result.data, result.data.data);
      console.log('error', error);
      this.uploadingInProgress = false;
      // console.log('submitAssignment result  error: ', result.type);
      this.presentToastMessage('Unable to submit activity at ths time', 'Warning', 'warning');
      // }
    })
  }

  /*************** Submit Assignment Activity ************/

  /*************** Present Toast Message ************/
  async presentToastMessage(msg, title, type) {
    await this.toaster.prsentToast(msg, title, type, null);
  }

  /*************** Present Toast Message END ************/

  /*************** Update Activity Details and Emit ************/
  updateActivityDetails(result, data) {
    if (data) {
      let event = null;
      if (data.assigementRes && data.assigementRes.length > 0) {
        this.activity.ass_comp_id = data.assigementRes[0].in_asscompId;
        this.activity.useruploadfile = data.assigementRes[0].useruploadfile;
        this.userFileName = this.getFileName(this.activity.useruploadfile);
        this.presentToastMessage(data.assigementRes[0].msg, 'Success', 'success')
      }
      if (data.contentComplitionRes && data.contentComplitionRes.length > 0) {
        this.activity.contentcomplitionId = data.contentComplitionRes[0].lastid;

        // this.activity.res = data.contentComplitionRes;
        event = {
          loaderFlag: false,
          responseData: data.contentComplitionRes[0],
          errorFlag: false,
          showComponent: "next-activity",
        };
      }
      if (data.activityCompletionRes && data.activityCompletionRes.length > 0) {
        if (this.activity.activitycompletionid === undefined || !this.activity.activitycompletionid) {
          this.activity.activitycompletionid = data.activityCompletionRes[0].lastid;
          this.activity.completionDate = data.activityCompletionRes[0].compDate;
          this.activity.languageId = data.activityCompletionRes[0].languageId;
        }
        // this.activity.res = data.activityCompletionRes;
        // this.saveEvent.emit(this.activity);
        event = {
          loaderFlag: false,
          responseData: data.activityCompletionRes[0],
          errorFlag: false,
          showComponent: "next-activity",
        };
      }
      // this.saveEvent.emit(this.activity);
      if (event) {
        this.saveEvent.emit(event);
      }
    }
  }

  /***************  Update Activity Details and Emit END ************/
}
