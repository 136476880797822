import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { webApi } from '../../../config';

@Injectable({
  providedIn: 'root'
})

export class LearningCartService {
  userData: any;
  scheduleData
  segmentButtons: any = [
    {
      id: 1,
      title: 'SCHEDULED',
      value: 'scheduled',
      contentTotal: ''
    },
    {
      id: 2,
      title: 'UNSCHEDULED',
      value: 'unscheduled',
      contentTotal: ''
    }
  ];

  scheduled: any = [
    {
      id: 1,
      title: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
      content: 'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source.',
      imgSrc: './assets/images/book.png',
      type: 'scheduled'
    },
    {
      id: 2,
      title: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
      content: 'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source.',
      imgSrc: './assets/images/book.png',
      type: 'scheduled'
    },
    {
      id: 3,
      title: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
      content: 'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source.',
      imgSrc: './assets/images/book.png',
      type: 'scheduled'
    },
    {
      id: 4,
      title: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
      content: 'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source.',
      imgSrc: './assets/images/book.png',
      type: 'scheduled'
    },
    {
      id: 5,
      title: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
      content: 'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source.',
      imgSrc: './assets/images/book.png',
      type: 'scheduled'
    },
  ];

  unscheduled: any = [
    {
      id: 1,
      title: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
      content: 'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source.',
      imgSrc: './assets/images/book.png',
      type: 'unscheduled'
    },
    {
      id: 2,
      title: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
      content: 'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source.',
      imgSrc: './assets/images/book.png',
      type: 'unscheduled'
    },
    {
      id: 3,
      title: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
      content: 'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source.',
      imgSrc: './assets/images/book.png',
      type: 'unscheduled'
    },
    {
      id: 4,
      title: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
      content: 'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source.',
      imgSrc: './assets/images/book.png',
      type: 'unscheduled'
    },
    {
      id: 5,
      title: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
      content: 'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source.',
      imgSrc: './assets/images/book.png',
      type: 'unscheduled'
    },
  ];
  segmentModel: any = this.segmentButtons[1].id;

    baseUrl: any = webApi.baseUrl;
    fetch_Cart_Data = this.baseUrl + webApi.apiUrl.fetch_my_cart;
    get_schedule_data = this.baseUrl + webApi.apiUrl.getscheduleData;
    getLearningCartContentCount = this.baseUrl + webApi.apiUrl.getLearningCartContentCount;
    updateDeleteCartContent = this.baseUrl + webApi.apiUrl.updateDeleteCartContent; 
    addToCart = this.baseUrl + webApi.apiUrl.add_data_to_cart;

  scheduleId: boolean = false; 

  constructor(public http: HttpClient,) { 
    console.log('Learning Service segmentModel ' + this.segmentModel);
     /*********************** Get Count for Scheduled & Unscheduled Data ************************/
     this.userData = JSON.parse(localStorage.getItem('currentUser'));
  }
  setSchedule(flag) {
    this.scheduleId = flag;
  }
  getSchedule() {
    return this.scheduleId;
  }

  fetchCartCount(params){
    let param = {
      userId: this.userData.id,
      roleId: this.userData.roleId,
      tenantId: this.userData.tenantId
    }
    this.http.post(this.getLearningCartContentCount, params)
     .toPromise().then((res: any) => {
       this.segmentButtons[0].contentTotal = res.scheduleCount[0].count;
       this.segmentButtons[1].contentTotal= res.unScheduleCount[0].count;
     }).catch(this.handleError);
   
  }

  public addDataToCart(data: any): Promise<any> {
    return this.http
      .post(this.addToCart, data)
      .toPromise()
      .then((response) => {
        return (response = response);
      })
      .catch(this.handleError);
  }

  // fetchCartData(type: any) {
  //   switch (type) {
  //     case 'scheduled':
  //       return this.scheduled;
  //       break;
  //     case 'unscheduled':
  //       return this.unscheduled;
  //       break;
  //     default:
  //       break;
  //   }
  // }

  public fetchCartData(param: any): Promise<any> {
    return this.http
      .post(this.fetch_Cart_Data, param)
      .toPromise()
      .then((response) => {
        return (response = response);
      })
      .catch(this.handleError);
  }

  public getScheduleData(param: any): Promise<any> {
    return this.http
      .post(this.get_schedule_data, param)
      .toPromise()
      .then((response) => {
        return (response = response);
      })
      .catch(this.handleError);
  }

  public deleteCartData(param: any): Promise<any> {
    return this.http.post(this.updateDeleteCartContent, param)
    .toPromise()
    .then(response => {
      return response;
    }).catch(this.handleError);
  };

  private handleError(error: Response | any) {
    return Promise.reject(error.message || error);
  }
}
