import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';

@Component({
  selector: 'ngx-survey-card',
  templateUrl: './survey-card.component.html',
  styleUrls: ['./survey-card.component.scss']
})
export class SurveyCardComponent implements OnInit, OnChanges {
  // courseSetting = {
  //   slidesPerView: 5,
  //   spacing: 20,
  //   controls: false,
  //   arrow: true,
  // };

  @Input() cardsData: any = {};
  @Output() getEvent = new EventEmitter();
  config = {
    // image: 'instanceThumbnail',
    title: 'instanceName',
    author: 'authorName',
    // rating: '',
    description: '',
    // showPinned: false,
    // instanceType: 'instanceType',
    // instanceTypeIcon: 'instanceTypeIcon',
    // showStars: true,
    // moreOptionArray: 'moreOptions',
    // insideSpinner: false,
    // scheduleFlag: 'isScheduled',
    // instanceProgress: 'instanceProgress',
    // goToCartFlag: 'cart_id',
    // goToCartFlag: 'cartId',
    // numberOfStars: 'outof',
    extraInfo: [{
      iconSrc: 'assets/icon-svg/Calendar_icon.svg',
      key: 'instanceDate',
    }],
    blockCard: 'isBlocked',
    blockMsg: '',
    colorClass: 'colorClass',
  };
  @Input() externalConfig = {

  };

  constructor() { }

  ngOnInit(): void {
  }

  fireEvent(event, data) {
    // if (data.compStatus !== 'UPCOMING') {
    const dataOutput = {
      type: event,
      cardData: data,
      // liked : isLiked,
    };

    console.log('Fire event called : ', dataOutput);
    this.getEvent.emit(dataOutput);
    // } else {
    //   return false;
    // }
  }

  performCardClick() {
    if (this.cardsData[this.config?.blockCard] != 1) {
      this.fireEvent("goToSurvey", this.cardsData);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('externalConfig' in changes) {
      this.config = { ...this.config, ...this.externalConfig };
    }
  }
}
