import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SafePipePipe } from "./safe-pipe.pipe";
import { DatePipePipe } from "./date-pipe.pipe";
import { NumberFormatPipe } from "./number-format.pipe";
// import { LearnFilterPipe } from "./learn-filter.pipe";
import { MandetoryquestionpipePipe } from "./mandetoryquestionpipe.pipe";
import { RemoveHtmlPipe } from "./remove-html.pipe";
import { AttFilterPipe } from './att-filter.pipe';
import { ToFixedNumberPipe } from './tofixed.pipe';
import { AdvFilterPipePipe } from './adv-filter-pipe.pipe';
import { OrderByPipe } from './order-by.pipe';
import { JsonFormatterPipe } from './json-formatter/json-formatter.pipe';
import { DateAgoPipe } from './date-ago.pipe';
import { TruncatePipe } from './truncate/truncate.pipe';

const PIPES = [
  SafePipePipe,
  DatePipePipe,
  NumberFormatPipe,
  // LearnFilterPipe,
  MandetoryquestionpipePipe,
  RemoveHtmlPipe,
  AttFilterPipe,
  ToFixedNumberPipe,
  AdvFilterPipePipe,
  OrderByPipe,
  JsonFormatterPipe,
  DateAgoPipe,
  TruncatePipe
];
@NgModule({
  declarations: [...PIPES,],
  imports: [CommonModule],
  exports: [...PIPES],
})
export class PipesModule {
  static forRoot(): ModuleWithProviders<unknown> {
    return {
      ngModule: PipesModule,
      providers: [],
      // declarations: [ ],
      // imports: [ ],
      // exports: [ ],
    };
  }
}
