import { Injectable } from '@angular/core';
import { LoginService } from '../../../providers/login/login.service';
import { ToasterService } from '../../../providers/toaster/toaster.service';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor(
    private toaster: ToasterService,
    private loginService: LoginService,
  ) { }

  getUserData() {
    const currentUser = localStorage.getItem('currentUser');
    return currentUser ? JSON.parse(currentUser) : null;
  }

  /*************** Copy text to clipboard ************/
  copytext(data) {
    console.log(" url:>", data);
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = data;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.presentToastMessage('Link Copied', 'Success', 'success');
  }
  /*************** Copy text to clipboard END ************/

  /*************** Present Toast Message ************/
  async presentToastMessage(msg, title, type) {
    await this.toaster.prsentToast(msg, title, type, null);
  }
  /*************** Present Toast Message END ************/

  goBack() {
    const currentNavHistoryLen = window.history.length;
    if (currentNavHistoryLen == 1) {
      this.loginService.goToDashboard();
    } else {
      // if (this.navHistoryLen != currentNavHistoryLen && this.navHistoryLen < currentNavHistoryLen) {
      //   const navDiff = (currentNavHistoryLen - this.navHistoryLen) + 1;
      //   window.history.go(-navDiff);
      // } else {
      window.history.back();
      // }
    }
    // this.eventsService.publish('app:pause', {});
  }

  /**************** check if object is valid start **************/
  checkIfParams(params) {
    if (params && !this.isEmpty(params)) {
      return true;
    } else {
      return false;
    }
  }

  isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }
  /**************** check if object is valid end **************/

  /**************** check if array is valid start **************/
  checkIfArray(arr) {
    if (arr && Array.isArray(arr) && !this.isArrayEmpty(arr)) {
      return true;
    } else {
      return false;
    }
  }

  isArrayEmpty(arr) {
    return arr.length === 0;
  }
  /**************** check if array is valid end **************/

  getValueFromArray(array, id, subId, matchKey, returnKey) {
    try {
      var obj = this.getObjFromArray(array, id, matchKey);
      if (subId) {
        return this.getValueFromArray(obj.subactivities, subId, null, matchKey, returnKey);
      } else {
        return obj[returnKey];
      }
    } catch (error) {
      console.log('getValueFromArray error : ', error);
      return '';
    }
  }

  getObjFromArray(array, id, matchKey) {
    try {
      var obj = array.find((value, index) => {
        return id == value[matchKey];
      });
      return obj;
    } catch (error) {
      console.log('getObjFromArray error : ', error);
      return null;
    }
  }

  getRequestedItemIndex(array, key, value) {
    const index = array.map((item) => item[key]).indexOf(Number(value));
    return index;
  }

  /********** input validation start *********/
  isInputValid(value, componentAttribute) {
    // const value = this.data[this.label.bindingProperty];
    if (componentAttribute.type == 'text') {
      if (value == '') {
        this.presentToastMessage('Input is required', 'Warning!', 'warning');
        return false;
      } else if (!this.isPatternValid(value, componentAttribute.pattern)) {
        // this.presentToastMessage(`Special characters not allowed`, 'Warning!', 'warning');
        return false;
      } else if (componentAttribute.min < Number(value)) {
        this.presentToastMessage(`Minimum ${componentAttribute.min} characher required`, 'Warning!', 'warning');
        return false;
      } else if (componentAttribute.max > Number(value)) {
        this.presentToastMessage(`Maximum ${componentAttribute.max} characher required`, 'Warning!', 'warning');
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  isPatternValid(text, patterns = []) {
    // const regEx = new RegExp('^[a-zA-Z0-9 ]+$');
    // return regEx.test(text);
    let status = true;
    for (let i = 0; i < patterns.length; i++) {
      const elem = patterns[i];
      const regEx = new RegExp(elem.pattern);
      if (!regEx.test(text)) {
        status = false;
        this.presentToastMessage(elem.msg, 'Warning!', 'warning');
        break;
      }
    }
    return status;
  }
  /********** input validation end *********/
}
