<!-- <div class="tbinner-texticon d-flex">
  <img src="assets/icon-svg/course.svg" alt="">
  <p class="tbinner-name body-text pl-2 m-0">{{iconText}}</p>
</div> -->

<div class="d-flex align-items-center {{data[setting?.classText]}}">
  <svg-icon *ngIf="setting?.icon" [src]="data[setting?.icon]" class="lh-1"
    [svgStyle]="{'width.rem': 1, 'height.rem': 1}"></svg-icon>
  <span *ngIf="setting?.name" class="word-break-all lh-1 pl-2">{{data[setting?.name]}}</span>
</div>

<!-- <svg-icon src="assets/icon-svg/valid-circle-check.svg" class="lh-1"
[svgStyle]="{'width.rem': 1, 'height.rem': 1}"></svg-icon> -->