import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  SimpleChanges,
} from "@angular/core";
import { PdfViewer } from "../../interfaces/pdf-viewer";
import { PdfService } from "../../providers/activity/pdf/pdf.service";
@Component({
  selector: "ngx-pdf-viewer",
  templateUrl: "./pdf-viewer.component.html",
  styleUrls: ["./pdf-viewer.component.scss"],
})
export class PdfViewerComponent implements OnInit {

  @Output() saveEvent = new EventEmitter<any>();
  @Output() openDocument = new EventEmitter<any>();
  @Output() loadError = new EventEmitter<any>();
  @Input() documenturl: any;
  loading = true;
  @Input() ViewerConfig: Partial<PdfViewer> = {};

  documentViewerConfig: PdfViewer = {
    showBorders: false,
    mobileFriendlyZoomSetting: "150%",
    zoom: "150%",
    enablePinchOnMobile: true,
    slideOpts: {
      centeredSlides: true,
    },
    showSidebarButton: false,
    showFindButton: false,
    showPagingButtons: false,
    showZoomButtons: false,
    showPresentationModeButton: false,
    showOpenFileButton: false,
    showPrintButton: false,
    showDownloadButton: false,
    showBookmarkButton: false,
    showSecondaryToolbarButton: false,
    showRotateButton: false,
    showHandToolButton: false,
    showScrollingButton: false,
    showSpreadButton: false,
    showPropertiesButton: false,
    useBrowserLocale: true,
    mobileFriendlyZoom: true,
    height: "68vh",
    textLayer: false,
  };

  @Input() activity: any; // HTMLVideoElement;

  totalPages;
  previousPage = 1;
  currentProgress = 0;

  constructor(
    private pdfService: PdfService
  ) {
    this.updateViewerConfig();
  }

  ngOnInit(): void {
    this.previousPage = 1;
    this.currentProgress = 0;

    if (!this.activity) {
      console.log('Activity is undefined. Initializing with default values...');
      this.activity = {
        completionstatus: 'N',
        pdfPreviousPage: 1,
        // pdfTotalPages: 1        
      };
    }

  }

  onPdfEvent(type, event) {
    console.log(type, " : ", event);
    // if (type == "pdfLoaded") {
    //   this.saveEvent.emit();
    // }
    // if (type == "pdfLoadingFailed") {
    //   this.loadError.emit();
    // }

    switch (type) {
      case 'pdfLoaded':
        // this.saveEvent.emit(this.activity);
        break;
      case 'pdfLoadingFailed':
        this.loadError.emit();
        break;
      case 'pageChange':
        this.calculateProgress(event, null);
        break;
      default:
        break;
    }

    this.loading = false;
  }

  onPagesLoaded(event) {
    console.log("onPagesLoaded : ", event);

    let previousPage = this.activity['pdfPreviousPage'] || 1;
    let totalPages = this.activity['pdfTotalPages'] || event.pagesCount;
    this.calculateProgress(previousPage, totalPages);
  }

  documentViewerContainerClicked(event) {
    console.log("documentViewerContainerClicked : ", event);
    // this.openDocument.emit(this.documenturl);
    setTimeout(() => {
      this.openDocument.emit(this.documenturl);
    }, 500);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.updateViewerConfig();

    // testing
    // if (this.activity) {
    //   this.activity['pdfPreviousPage'] = 4;
    //   this.activity['pdfTotalPages'] = 12;
    // }
  }

  updateViewerConfig() {
    this.documentViewerConfig = {
      ...this.documentViewerConfig,
      ...this.ViewerConfig,
    };
  }

  calculateProgress(currentPage, totalPages) {
    // if (!this.activity) {
    //   console.error('Activity is not defined.');
    //   return; 
    // }
    if (this.activity.completionstatus != 'Y') {
      if (totalPages && !this.totalPages) {
        this.totalPages = totalPages;
      }
      if (this.totalPages) {
        // console.log('currentPage : ', currentPage);
        this.currentProgress = this.getProgress(currentPage, this.currentProgress);
        console.log(`PDF is ${this.currentProgress}% completed...`);
        if (this.currentProgress == 100) {
          this.activity['completionstatus'] = 'Y';
          this.saveEvent.emit(this.activity);
        } else {
          this.activity['completionstatus'] = 'UP';
          this.pdfService.updateProgress(this.currentProgress);
        }
      }
    } else {
      console.log('PDF already completed, No need to calculate progress!');
    }
  }

  getProgress(currentPage, progress) {
    // let progress = 0;
    if (currentPage == this.totalPages) {
      progress = 100;
    } else {
      if (this.previousPage < currentPage) {
        progress = (currentPage / this.totalPages) * 100;
        this.previousPage = currentPage;
      }
    }
    return Math.ceil(progress);
  }

  emitProgress() {
    if (this.activity.completionstatus != 'Y') {
      if (this.activity['pdfPreviousPage'] < this.previousPage) {
        this.activity['pdfPreviousPage'] = this.previousPage;
      }
      this.activity['pdfTotalPages'] = this.totalPages;
      // this.activity['currentProgress'] = this.currentProgress;
      // this.activity['completionstatus'] = 'UP';
      if (this.previousPage == this.totalPages) {
        this.activity['completionstatus'] = 'Y';
      } else {
        this.activity['completionstatus'] = 'UP';
      }
      this.saveEvent.emit(this.activity);
    }
  }

  ngOnDestroy() {
    this.emitProgress();
  }
}
