import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ThemeService {
  constructor() {}

  variables: any = {
    button: "--colorBgbtn",
    commonColor: "--colorCommon",
    header: "--colorHeader",
    icon: "--colorIcons",
    pageBackground: "--colorBgPage",
    sidemenu: "--colorSidebar",
    commonColorRGB: "--commonColorRGB",
  };

  changeTheme() {
    const tempThemes = localStorage.getItem("theme");
    if (tempThemes) {
      const themes = JSON.parse(tempThemes);
      for (let key in themes) {
        let value = themes[key];
        // console.log("elemnet=======>>>", value);
        this.setTheme(key, value);
      }
    }
  }
  setTheme(colorName, colorValue) {
    // Object.keys(this.variables).forEach(function(val) {
    // console.log('themes=========>>>>>>>>>>>', themes[key]);
    let themes = document.querySelector("body");
    for (let val in this.variables) {
      if (val === colorName) {
        if (colorName === "header") {
          themes.style.setProperty(this.variables[val], colorValue);
          const headerTxt = this.getContrastYIQ(colorValue);
          themes.style.setProperty("--colorHeadertxt", headerTxt);
        } else if (colorName === "sidemenu") {
          themes.style.setProperty(this.variables[val], colorValue);
          const sidebarTxt = this.getContrastYIQ(colorValue);
          themes.style.setProperty("--colorSidebartxt", sidebarTxt);
        } else if (colorName === "button") {
          themes.style.setProperty(this.variables[val], colorValue);
          const btnTxt = this.getContrastYIQ(colorValue);
          themes.style.setProperty("--colorBtnTxt", btnTxt);
        } else if (colorName === "commonColor") { 
          themes.style.setProperty(this.variables[val], colorValue);
          const commonColorRGB = this.hexToRgb(colorValue);
          themes.style.setProperty("--commonColorRGB", commonColorRGB);
        } else {
          themes.style.setProperty(this.variables[val], colorValue);
        }
      }
    }
    // });
  }
  getContrastYIQ(hexcolor) {
    hexcolor = hexcolor.replace("#", "");
    var r = parseInt(hexcolor.substr(0, 2), 16);
    var g = parseInt(hexcolor.substr(2, 2), 16);
    var b = parseInt(hexcolor.substr(4, 2), 16);
    var match = (r * 299 + g * 587 + b * 114) / 1000;
    return match >= 128 ? "#000000" : "#ffffff";
  }

  hexToRgb(hex) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (result) {
      const r = parseInt(result[1], 16);
      const g = parseInt(result[2], 16);
      const b = parseInt(result[3], 16);
      return r + ',' + g + ',' + b; //  return 23,14,45 -> reformat if needed
    }
    return null;
  }
}
