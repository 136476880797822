import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse, HttpInterceptor, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { ActivityService } from "../../../../../providers/activity/activity.service";
@Injectable({
  providedIn: 'root'
})
abstract class HttpCacheService {

  constructor() { }

  abstract get(req: HttpRequest<any>): HttpResponse<any> | null;

  abstract put(req: HttpRequest<any>, resp: HttpResponse<any> | void);
}

@Injectable({
  providedIn: 'root'
})
export class CacheService implements HttpCacheService {

  private cache: any = [];

  constructor(public activityService: ActivityService,) {

  }
  get(req: HttpRequest<any>): HttpResponse<any> {
    if (this.cache[req.body.filename]) {
      let oldResponse = this.cache[req.body.filename].body.data;
      let oldTimeStamp = moment(oldResponse.split('Date=')[1].split('&')[0]);
      let expiresInSeconds = Number(oldResponse.split('Date=')[1].split('&')[1].split('Expires=')[1]);
      let now = moment();
      let diffInTime = (Number(now) - Number(oldTimeStamp)) / 1000;
      if (diffInTime >= expiresInSeconds) {
        return null
      } else {
        return this.cache[req.body.filename];
      }
    } else {
      return null
    }
  }
  put(req: HttpRequest<any>, resp: void | HttpResponse<any>) {
    this.cache[req.body.filename] = resp;
  }

  // updateProfile(params: any, cb: any) {

  //     let oldResponse = params.picture_url;
  //     let oldTimeStamp = moment(oldResponse.split('Date=')[1].split('&')[0]);
  //     let expiresInSeconds = Number(oldResponse.split('Date=')[1].split('&')[1].split('Expires=')[1]);
  //     let now = moment();
  //     let diffInTime = (Number(now) - Number(oldTimeStamp))/1000;
  //     if(diffInTime >= expiresInSeconds) {
  //       const activityData = {
  //         filename: params.picture_url
  //       };
  //       this.activityService.getPreSignedUrl(activityData).then(res => {
  //         console.log('getPreSignedUrl res : ', res);
  //         if (res.type === true) {
  //           let currentUser = JSON.parse(localStorage.getItem("currentUser"));
  //           currentUser.picture_url = res.data;
  //           localStorage.setItem('currentUser', JSON.stringify(currentUser));
  //           cb(currentUser);
  //         } else {
  //           cb(params);
  //         }
  //       }, err => {
  //         console.log('getPreSignedUrl err : ', err);
  //         cb(params);
  //       });
  //       // return true;
  //     } else {
  //       return false;
  //     }
  // };

  updateS3URL(s3URL: any, cb: any) {
    if (s3URL) {
      let oldTimeStamp = moment(s3URL.split('Date=')[1].split('&')[0]);
      let expiresInSeconds = Number(s3URL.split('Date=')[1].split('&')[1].split('Expires=')[1]);
      let now = moment();
      let diffInTime = (Number(now) - Number(oldTimeStamp)) / 1000;
      if (diffInTime >= expiresInSeconds) {
        const activityData = {
          filename: s3URL
        };
        this.activityService.getPreSignedUrl(activityData).then(res => {
          console.log('getPreSignedUrl res : ', res);
          if (res.type === true && res.data) {
            // let currentUser = JSON.parse(localStorage.getItem("currentUser"));
            // currentUser.picture_url = res.data;
            // localStorage.setItem('currentUser', JSON.stringify(currentUser));
            cb(res.data);
          } else {
            cb(s3URL);
          }
        }, err => {
          console.log('getPreSignedUrl err : ', err);
          cb(s3URL);
        });
      } else {
        cb(s3URL);
      }
    } else {
      cb(s3URL);
    }
  };
}
