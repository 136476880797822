<img
  #image
  (error)="errorLoad()"
  [hidden]="!viewImage"
  (load)="loadImage($event)"
  [src]="url"
/>
<div
  [nbSpinner]="!viewImage"
  class="w-100 h-100"
  nbSpinnerSize="giant"
  *ngIf="!viewImage"
  nbSpinnerStatus="primary"
></div>
