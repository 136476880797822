import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation, OnChanges, SimpleChanges  } from '@angular/core';

@Component({
  selector: 'ngx-tetimonials',
  templateUrl: './tetimonials.component.html',
  styleUrls: ['./tetimonials.component.scss', '../../@theme/styles/_typography.scss'],
})
export class TetimonialsComponent implements OnInit, OnChanges {
  @Input('data') data: any;
  @Output() getEvent = new EventEmitter();
  item: any;
  constructor() {}

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    if(this.data !== undefined) {
      this.item = this.data;
    }
  }

  fireEvent(event: any, data: any) {
    const dataOutput = {
      type : event,
      cardData: data
    };
    this.getEvent.emit(dataOutput);
  };
}
