<div class="header-container">
  <div class="logo-container">
    <!-- <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a> -->
    <!-- <a class="logo" href="#" (click)="navigateHome()">ngx-<span>admin</span></a> -->
    <!-- <img [src]="currentBrandData?.dashboard_logo" *ngIf="!featureConfig?.allowDynamicDbLogoImage"> -->
    <a class="logo" href="#" (click)="goToDashboard()">
      <img [src]="themebind()" (onError)="errorHandler($event)" />
    </a>
  </div>
  <!-- <nb-select
    [selected]="currentTheme"
    (selectedChange)="changeTheme($event)"
    status="primary"
  >
    <nb-option *ngFor="let theme of themes" [value]="theme.value">
      {{ theme.name }}</nb-option
    >
  </nb-select> -->
  <!-- <ngx-layout-direction-switcher
    class="direction-switcher"
  ></ngx-layout-direction-switcher> -->
  <ngx-header-search *ngIf="currentUser?.roleId == 8"></ngx-header-search>
</div>

<!-- header-container -->
<div class="header-actions d-grid align-items-center justify-content-center px-3">
  <ngx-language-dropdown></ngx-language-dropdown>
  <ng-container *ngFor="let item of tabsArr">
    <ng-container *ngIf="!item?.link">
      <nb-actions class="justify-content-center">
        <nb-action class="p-0" size="small" [nbPopover]="componentMap[item.identifier]" [nbPopoverPlacement]="'bottom'"
          [nbPopoverOffset]="10"
          [ngClass]="{'user-action profile-icon': item?.identifier =='PROFILE', 'control-item': item?.identifier !='PROFILE'}"
          [id]="item?.identifier">
          <ng-container *ngIf="item?.identifier !='PROFILE'">
            <div class="text-center cursor-p " (click)="performAction(item)">
              <div class="position-relative d-inline-block">
                <!-- <nb-icon [icon]="item?.icon"></nb-icon> -->
                <img class="icon" src="assets/icon-svg/menu/{{item?.icon}}.svg" alt="icon">
                <nb-badge class="badge_icon" *ngIf="countObject[item?.identifier]" badgePosition="top" status="primary"
                  [text]="countObject[item?.identifier]"></nb-badge>
              </div>
              <div class="icon-text font-weight-normal">{{item?.title}}</div>
            </div>
          </ng-container>
          <ng-container *ngIf="item?.identifier =='PROFILE'">
            <nb-user onlyPicture [name]="currentUser?.firstname + ' ' + currentUser?.lastname"
              [picture]="currentUser?.picture_url">
            </nb-user>
            <nb-icon class="cursor-p" icon="arrow-ios-downward-outline" [nbPopoverTrigger]="noopFlag ? 'noop' : 'click'"
              [nbPopover]="profileComponent" [nbPopoverOffset]="24"></nb-icon>
          </ng-container>
        </nb-action>
      </nb-actions>
    </ng-container>

    <ng-container *ngIf="item?.link">
      <nb-actions class="justify-content-center">
        <!-- [ngClass]="{'user-action profile-icon': item?.identifier =='PROFILE', 'control-item': item?.identifier !='PROFILE'}" -->
        <nb-action size="small" class="control-item p-0" [id]="item?.identifier">
          <!-- <ng-container *ngIf="item?.identifier !='PROFILE'"> -->
          <div class="text-center cursor-p " (click)="performAction(item)">
            <div class="position-relative d-inline-block">
              <!-- <nb-icon [icon]="item?.icon"></nb-icon> -->
              <img class="icon" src="assets/icon-svg/menu/{{item?.icon}}.svg" alt="icon">
              <nb-badge class="badge_icon" *ngIf="countObject[item?.identifier]" badgePosition="top" status="primary"
                [text]="countObject[item?.identifier]"></nb-badge>
            </div>
            <div class="icon-text font-weight-normal">{{item?.title}}</div>
          </div>
          <!-- </ng-container> -->
        </nb-action>
      </nb-actions>
    </ng-container>
  </ng-container>
</div>