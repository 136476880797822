import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'advFilterPipe',
  pure: false,
})
export class AdvFilterPipePipe implements PipeTransform {

  transform(items: any[], keyword: any, properties: string[], filterMetadata?: any): any[] {
    if (!items) {
      if (filterMetadata) {
        filterMetadata.count = 0;
      }
      return [];
    }
    if (!keyword) {
      if (filterMetadata) {
        filterMetadata.count = items.length;
      }
      return items
    };

    const filteredItems = this.filterItems(items, properties, keyword);
    if (filterMetadata) {
      filterMetadata.count = filteredItems.length;
      // debugger;
    }
    return filteredItems;
  }

  filterItems(items, properties, keyword) {
    return items.filter(item => {
      var itemFound: Boolean;
      for (let i = 0; i < properties.length; i++) {
        if (String(item[properties[i]]).toLowerCase().indexOf(String(keyword).toLowerCase()) !== -1) {
          itemFound = true;
          break;
        }
      }
      return itemFound;
    });

  }
}
