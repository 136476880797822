import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { webApi } from '../../../../../config';
// import { webApi } from 'src/config';
// import { modelType } from '../modal-container/modal-container.service';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {

  validRoutes = [
    {
      route: '/shared/calendar',
      typeId: 4,
      headerData: {
        title: 'Calendar',
        count: 0,
      },
      headerConfig: {
        showBackbtn: true,
        color: 'primary',
        textClass: 'text-white',
        showSettings: true,
        // showFilter: true,
        "showButton": true,
        "showButtonData": [
          {
            "name": "Refresh",
            "cssClass": "",
            "appIcon": "reload-outline",
            "webIcon": "",
            "action": "refresh",
            "event": {
              "id": "refresh",
              "value": "1"
            },
            "isVisible": true
          },
          {
            "name": "Filter",
            "cssClass": "",
            "appIcon": "filter-outline",
            "webIcon": "",
            "action": "filter",
            "event": {
              "id": "filter",
              "value": "1"
            },
            "isVisible": true
          },
        ]
      }
    },
  ];

  // filterModelConfig: any = {
  //   popupTitle: 'Filter',
  //   showClose: true,
  //   type: modelType.assignContent,
  //   typeId: 1,
  //   api: '',
  //   noDataContainerMessage: '',
  //   params: {
  //     areaId: '',
  //     instanceId: '',
  //     pageLimit: 10,
  //     pageNo: 1,
  //     searchString: '',
  //     selectedIds: ''
  //   },
  //   pageParamName: 'pageNo',
  //   searchStringParamName: 'searchString',
  //   dataLimitParamName: 'pageLimit',
  //   showButtons: true,
  //   button: [
  //     {
  //       btnText: 'Clear All',
  //       classList: 'customBtn',
  //       event: {
  //         type: 'clear'
  //       },
  //       btnSate: 1
  //     },
  //     {
  //       btnText: 'Apply',
  //       classList: 'customBtn',
  //       event: {
  //         type: 'apply'
  //       },
  //       btnSate: 1
  //     }
  //   ],
  //   cssClass: '',
  //   mode: 'md',
  //   isFakeState: false
  // };

  baseUrl: any = webApi.baseUrl;
  private headers = new HttpHeaders();

  getCalendarEventsUrl: any = this.baseUrl + webApi.apiUrl.getCalendarEvents;
  getCalendarFiltersUrl: any = this.baseUrl + webApi.apiUrl.getCalendarFilters;
  deleteEventUrl: any = this.baseUrl + webApi.apiUrl.deleteEvent;

  constructor(
    public http: HttpClient,
  ) { }

  /*********** Create random events start *********/
  createRandomEvents() {
    const events = [];
    for (let i = 0; i < 50; i += 1) {
      const date = new Date();
      const eventType = Math.floor(Math.random() * 2);
      const startDay = Math.floor(Math.random() * 90) - 45;
      let endDay = Math.floor(Math.random() * 2) + startDay;
      let tempStartTime;
      let tempEndTime;
      if (eventType === 0) {
        tempStartTime = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate() + startDay));
        if (endDay === startDay) {
          endDay += 1;
        }
        tempEndTime = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate() + endDay));
        events.push(
          {
            id: i,
            title: 'All Day - ' + i,
            startTime: tempStartTime,
            endTime: tempEndTime,
            start: tempStartTime,
            end: tempEndTime,
            allDay: true,
            color: {
              primary: '#e3bc08',
              secondary: '#FDF1BA',
            },
            "areaId": 2,
            "instanceId": 20298,
            "eventType": 1,
            "subTitle": " Project Management",
            "contentType": "Course",
            "description": "This is a project management course",
            "showButtons": 0,
            "buttons": null,
            "eventDate": "2023-06-05T18:30:00.000Z",
            "requestJson": "{\"areaId\": 2, \"instanceId\": 20298, \"enrolId\": 10491728, \"wfId\": null}",
            "extraData": null,
            "startDateOnly": "06 Jun 2023",
            "startTimeOnly": "12:00 AM",
            "endTimeOnly": "12:00 AM",
            "endDateOnly": "06 Jun 2023"
          }
        );
      } else {
        const startMinute = Math.floor(Math.random() * 24 * 60);
        const endMinute = Math.floor(Math.random() * 180) + startMinute;
        tempStartTime = new Date(date.getFullYear(), date.getMonth(), date.getDate() + startDay, 0, date.getMinutes() + startMinute);
        tempEndTime = new Date(date.getFullYear(), date.getMonth(), date.getDate() + endDay, 0, date.getMinutes() + endMinute);
        events.push(
          {
            id: i,
            title: 'Event - ' + i,
            startTime: tempStartTime,
            endTime: tempEndTime,
            start: tempStartTime,
            end: tempEndTime,
            allDay: false,
            color: {
              primary: '#e3bc08',
              secondary: '#FDF1BA',
            },
            "areaId": 2,
            "instanceId": 20298,
            "eventType": 1,
            "subTitle": " Project Management",
            "contentType": "Course",
            "description": "This is a project management course",
            "showButtons": 0,
            "buttons": null,
            "eventDate": "2023-06-05T18:30:00.000Z",
            "requestJson": "{\"areaId\": 2, \"instanceId\": 20298, \"enrolId\": 10491728, \"wfId\": null}",
            "extraData": null,
            "startDateOnly": "06 Jun 2023",
            "startTimeOnly": "12:00 AM",
            "endTimeOnly": "12:00 AM",
            "endDateOnly": "06 Jun 2023"
          }
        );
      }
    }
    console.log('Random generated events : ', events);
    return events;
  }
  /*********** Create random events end *********/

  public getCalendarEvents(data: any): Promise<any> {
    return this.http
      .post(this.getCalendarEventsUrl, data)
      .toPromise()
      .then((response) => {
        return (response = response);
      })
      .catch(this.handleError);
  }

  public getCalendarFilters(learnData: any): Promise<any> {
    return this.http.post(this.getCalendarFiltersUrl, learnData, { headers: this.headers })
      .toPromise()
      .then(response => {
        // return response
        return response;
      })
      .catch(this.handleError);
  }

  public deleteEvent(data: any): Promise<any> {
    return this.http
      .post(this.deleteEventUrl, data, { headers: this.headers })
      .toPromise()
      .then((response) => {
        return (response = response);
      })
      .catch(this.handleError);
  }

  private handleError(error: Response | any) {
    return Promise.reject(error.message || error);
  }
}
