<div class="notify__popup" nbSpinnerStatus="primary" [nbSpinner]="loading">
    <div class="notify__popup__lists border-left pt-2">
        <div class="notify__popup__lists__header">
            <h3 class="notify__popup__lists__header--title px-3">Notifications</h3>
            <h3 class="notify__popup__lists__header--title px-3">{{totalNotificationsCount}}</h3>
        </div>
        <div class="list" *ngIf="notificationsList?.length !=0" nbInfiniteList [threshold]="300" (bottomThreshold)="loadData($event)">
            <!-- <label class="list__label px-3">New</label> -->
            <div class="list__item border-bottom cursor-p mb-2 cursor-p" (click)="openNotification(notify)" *ngFor="let notify of notificationsList; let i = index">
                <!-- [ngClass]= { 'poll survey mail' } -->
                <div class="border-right">
                    <div class="list__item--icon poll">
                        <img src="assets/icon-svg/notification/mail-outline.svg" alt="noti-icon">
                    </div>
                </div>
                <div class="list__item--data pr-3">
                    <h5 class="icon-text">{{ notify?.title ? notify?.title : 'NA'}}</h5>
                    <!--  [innerHTML]="makeHtmlReady(notify.message)" -->
                    <p class="m-0">
                        <app-read-more [maxLength]="50" [text]="notify?.message"></app-read-more>
                    </p>
                    <!-- <span>a few seconds ago</span> -->
                </div>
                <div class=" d-flex align-items-center">
                    <span class="list__item--unread-count" *ngIf="notify?.isRead == 0"></span>
                </div>
            </div>
            <ng-container *ngIf="working">
                <div class="notification-spinner-container" [nbSpinner]="true" nbSpinnerStatus="primary">

                </div>
            </ng-container>
        </div>

    </div>
</div>