import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-filter-table',
  templateUrl: './filter-table.component.html',
  styleUrls: ['./filter-table.component.scss']
})
export class FilterTableComponent implements OnInit {

  @Input() config = {
    pageTitle: 'Call Flow',
    cards: {
      showCards: true,
      cardList: [
        // {
        //   count: '25',
        //   name: 'Audience Limit'
        // },
        // {
        //   count: '25',
        //   name: 'Audience Limit'
        // },
        // {
        //   count: '25',
        //   name: 'Audience Limit'
        // },
        // {
        //   count: '25',
        //   name: 'Audience Limit'
        // },
      ]
    },
    tableData: [
      // { 'name': 'Call Flow 1', 'dateTime': '12 Jun 2021 - 26 Aug 2022 | 12:00 PM', 'description': 'Lorem ipsum dummy text of printing' },
      // { 'name': 'Call Flow 1', 'dateTime': '12 Jun 2021 - 26 Aug 2022 | 12:00 PM', 'description': 'Lorem ipsum dummy text of printing' },
      // { 'name': 'Call Flow 1', 'dateTime': '12 Jun 2021 - 26 Aug 2022 | 12:00 PM', 'description': 'Lorem ipsum dummy text of printing' },
      // { 'name': 'Call Flow 1', 'dateTime': '12 Jun 2021 - 26 Aug 2022 | 12:00 PM', 'description': 'Lorem ipsum dummy text of printing' },
      // { 'name': 'Call Flow 1', 'dateTime': '12 Jun 2021 - 26 Aug 2022 | 12:00 PM', 'description': 'Lorem ipsum dummy text of printing' },
      // { 'name': 'Call Flow 1', 'dateTime': '12 Jun 2021 - 26 Aug 2022 | 12:00 PM', 'description': 'Lorem ipsum dummy text of printing' },
      // { 'name': 'Call Flow 1', 'dateTime': '12 Jun 2021 - 26 Aug 2022 | 12:00 PM', 'description': 'Lorem ipsum dummy text of printing' },
      // { 'name': 'Call Flow 1', 'dateTime': '12 Jun 2021 - 26 Aug 2022 | 12:00 PM', 'description': 'Lorem ipsum dummy text of printing' },
      // { 'name': 'Call Flow 1', 'dateTime': '12 Jun 2021 - 26 Aug 2022 | 12:00 PM', 'description': 'Lorem ipsum dummy text of printing' },
      // { 'name': 'Call Flow 1', 'dateTime': '12 Jun 2021 - 26 Aug 2022 | 12:00 PM', 'description': 'Lorem ipsum dummy text of printing' },
    ],
    labels: [
      // { labelname: 'Name', bindingProperty: 'name', componentType: 'text' },
      // { labelname: 'Start & End Date Time', bindingProperty: 'dateTime', componentType: 'text' },
      // { labelname: 'Description', bindingProperty: 'description', componentType: 'text' },
    ],
  }

  constructor() { }

  ngOnInit(): void {
  }

}
