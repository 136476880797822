import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CookieService {

  constructor() { }


  getCookies(name: any) {
    const value = `; ${document.cookie}`;
    const parts: any = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

  getAllCookies() {
    var pairs = document.cookie.split(';');
    var cookies: any = {};
    for (var i = 0; i < pairs.length; i++) {
      var pair = pairs[i].split('=');
      cookies[(pair[0] + '').trim()] = unescape(pair.slice(1).join('='));
    }
    return cookies;
  }

  setCookies(name: any, value: any, options: any) {
    options = {
      path: '/',
      // add other defaults here if necessary
      ...options,
    };

    if (options.expires instanceof Date) {
      options.expires = options.expires.toUTCString();
    }

    let updatedCookie =
      encodeURIComponent(name) + '=' + encodeURIComponent(value);

    for (let optionKey in options) {
      updatedCookie += '; ' + optionKey;
      let optionValue = options[optionKey];
      if (optionValue !== true) {
        updatedCookie += '=' + optionValue;
      }
    }
    document.cookie = updatedCookie;
  }

  deleteCookie(name: any) {
    this.setCookies(name, '', {
      'max-age': -1,
    });
  }

  deleteMultiCookie(list: any) {
    if (list && Array.isArray(list) && list.length > 0) {
      list.forEach((item) => {
        this.deleteCookie(item);
      });
    }
  }
}
