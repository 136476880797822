<div class="container_height" id="step-profile-more">
    <div class="pt-2 pl-3 pr-3 pb-3">
        <nb-user size="medium" [name]="currentUser?.firstname + ' ' + currentUser?.lastname" [title]="currentUser?.email ? currentUser?.email : 'NA'" [picture]="currentUser?.picture_url">
        </nb-user>
    </div>
    <div class="border-top">
        <div class="pt-2 pl-3 pr-3 pb-2">
            <!-- [ngClass]="{ 'mt-2': !isFirst }" [hidden]="item?.sectionId == 1" -->
            <div class="form-check cursor-p" [ngClass]="{ 'mt-2': !isFirst }" *ngFor="let item of roleList; let i = index; first as isFirst">
                <input class="form-check-input" type="radio" name="exampleRadios" [disabled]="item.status" [id]="'role' + i" value="option1" [checked]="item.status" (click)="performActionForRoleChange(item)" />
                <label class="form-check-label cursor-p" [for]="'role' + i">
          {{ item?.roleName }}
        </label>
            </div>
        </div>
    </div>
    <div class="border-top" *ngIf="listArray?.list2?.length !=0">
        <div class="pl-3 pr-3 pt-2 pb-2">
            <ul class="list-group">
                <li (click)="goToPage(child?.link)" class="list-group-item cursor-p m-0 pt-0 pl-0 pr-0 border-0" [ngClass]="{ 'pb-0': isLast }" *ngFor="let child of listArray.list2; last as isLast">
                    {{ child?.title }}
                </li>
            </ul>
        </div>
    </div>
    <div class="border-top" *ngIf="list3?.length !=0">
        <div class="pl-3 pr-3 pt-2 pb-2">
            <ul class="list-group">
                <li (click)="performActionForClick(child)" class="list-group-item cursor-p m-0 pt-0 pl-0 pr-0 border-0" [ngClass]="{ 'pb-0': isLast }" *ngFor="let child of list3; last as isLast">
                    {{ child?.label }}
                </li>
            </ul>
        </div>
    </div>
</div>