<div class="content d-inline-block position-relative border rounded w-100 cursor-p">

  <div class="content__img position-relative">

    <img class="w-100 h-100 border-bottom" [src]="bindBackgroundImage(data[config?.image])"
      onerror="this.onerror=null;this.src='assets/images/activity1.jpg';" alt="image"
      (click)="data[config?.showButton] != 1 ? fireEvent('goToContent', data) : false">

    <!-- progress -->
    <!-- <div class="progress h-4-px w-100">
            <div class="progress-bar" role="progressbar" [style.width.%]="data[config?.prrogressInPerc]"
                aria-valuemin="0" aria-valuemax="100">
            </div>
        </div> -->

    <div class="content__icons text-right" (mouseleave)="mouseLeave(data)">
      <i class="fas fa-ellipsis-v option cursor-p" *ngIf="data[config?.showOptions] == 1"
        (mouseenter)="mouseEnter(data)"></i>
      <div class="showlist" *ngIf="data?.show ">
        <ng-container *ngFor="let item of buttons">
          <label class="m-0 d-block cursor-p" (click)="optionClicked(item.event, data)">
            <svg-icon [src]="item?.icon" [stretch]="true"></svg-icon>
            <span>{{ item?.text }}</span>
          </label>
        </ng-container>
      </div>
    </div>

  </div>

  <ng-container
    *ngIf="(data[config?.compStatusId] == 11) || (data[config?.compStatusId] == 7) || (data[config?.compStatusId] == 5) || (data[config?.compStatusId] == 15)">
    <div class="content__overlay position-absolute d-flex p-4">
      <div class="content__overlay--text text-white text-center pt-5">{{data[config?.compStatusMsg]}}</div>
    </div>
  </ng-container>

  <div class="content__content">

    <div (click)="data[config?.showButton] != 1 ? fireEvent('goToContent', data) : false">

      <!--  || (data[config?.typeId] != 8) -->
      <div class="content__content--type lh-1 pb-1" *ngIf="(data[config?.typeId] != 4)">
        <svg-icon svgClass="stroke-gray" [svgStyle]="{'width.rem': .8, 'height.rem': .8}"
          src="assets/icon-svg/course-card/type.svg"></svg-icon>
        <span class="fs-12 text-gray pl-2">{{data[config?.type]}}</span>
      </div>

      <!--  || (data[config?.typeId] == 8) -->
      <div class="content__content--type lh-1 pb-1" *ngIf="(data[config?.typeId] == 4)">
        <svg-icon svgClass="stroke-gray" [svgStyle]="{'width.rem': .8, 'height.rem': .8}"
          src="assets/icon-svg/course-card/type.svg"></svg-icon>
        <span class="fs-12 text-gray pl-2">{{data[config?.subType]}}</span>
      </div>

      <div class="content__content--title fs-14 font-weight-bold text-capitalize line-wrap line-clamp-2"
        [title]="data[config?.title]">
        {{data[config?.title]}}
      </div>

      <div class="d-flex align-items-center py-2" *ngIf="data[config?.showRating] == 1">
        <span class="fs-12">({{data[config?.rating]}})</span>
        <star-rating [starType]="'svg'" [showHalfStars]="true" [numOfStars]="data[config?.ratingOutOf]" [step]="0.5"
          color="warning" [rating]="data[config?.rating]" [readOnly]="true"></star-rating>
      </div>

      <ng-container *ngIf="data[config?.showExtraParams] == 1">
        <div class="d-flex align-items-center lh-0 py-1" *ngFor="let item of data[config?.paramsArray]">
          <svg-icon svgClass="stroke-gray" [svgStyle]="{'width.rem': .8, 'height.rem': .8}"
            src="assets/icon/svg/course-card/{{item[config?.params?.icon]}}.svg"></svg-icon>
          <span class="fs-12 text-gray pl-2">{{item[config?.params?.value]}}</span>
        </div>
      </ng-container>

      <!-- Likes -->
      <ng-container>
        <!--  && data[config['likeCount']] -->
        <div class="d-flex align-items-center lh-0 py-1" *ngIf="config['likeEnabled']">
          <svg-icon svgClass="fill-white stroke-gray"
            [svgStyle]="{'width.rem': 1, 'height.rem': 1, 'stroke-width': 1.5}"
            src="assets/icon-svg/Thumbs_up_icon.svg"></svg-icon>
          <span class="fs-12 body-text pl-2">{{ data[config['likeCount']] || 0 }} Likes</span>
        </div>
      </ng-container>

      <!-- Dislikes -->
      <ng-container>
        <!--  && data[config['dislikeCount']] -->
        <div class="d-flex align-items-center lh-0 py-1" *ngIf="config['dislikeEnabled']">
          <svg-icon svgClass="fill-white stroke-gray"
            [svgStyle]="{'width.rem': 1, 'height.rem': 1, 'stroke-width': 1.5}"
            src="assets/icon-svg/Thumbs_down_icon.svg"></svg-icon>
          <span class="fs-12 body-text pl-2">{{ data[config['dislikeCount']] || 0 }} Dislikes</span>
        </div>
      </ng-container>

      <!-- height -->
      <div class="pt-4 my-2" *ngIf="data[config?.showExtraParams] != 1 && data[config?.showRating] != 1"></div>

      <!-- subtype -->
      <!-- <div class=""
                *ngIf="data[config?.typeId] != 4 && (data[config?.compStatusId] != 13) && (data[config?.compStatusId] != 14)">
                <div class="d-flex align-items-center lh-0 pb-2">
                    <svg-icon svgClass="stroke-gray" [svgStyle]="{'width.rem': .8, 'height.rem': .8}"
                        src="assets/icon-svg/course-card/type.svg"></svg-icon>
                    <span class="fs-12 text-gray pl-2">{{data[config?.subType]}}</span>
                </div>
            </div> -->

      <!-- *ngIf="data[config?.typeId] == 4" -->
      <!-- <div class="d-flex align-items-center lh-0 pb-2">
            <svg-icon svgClass="stroke-gray" [svgStyle]="{'width.rem': .8, 'height.rem': .8}"
                src="assets/icon-svg/calender.svg"></svg-icon>
            <span class="fs-12 text-gray pl-2">23-Jan-22 12:56 to 11-May-22 09:45</span> -->
      <!-- <span class="fs-12 text-gray pl-2">{{data[config?.startDate]}} to {{data[config?.endDate]}}</span> -->
      <!-- </div> -->

      <div class="d-flex align-items-center pt-2" *ngIf="data[config?.showProgress] == 1">
        <span class="fs-12 text-gray">
          {{data[config?.prrogressInPerc]}}%
        </span>
        <div class="progress h-4-px w-100 ml-2">
          <div class="progress-bar" role="progressbar" [style.width.%]="data[config?.prrogressInPerc]" aria-valuemin="0"
            aria-valuemax="100">
          </div>
        </div>
      </div>
    </div>

    <!-- <button class="fs-12 btn btn-outline py-1 w-100" *ngIf="config?.showButton"
            (click)="fireEvent('enrol', data)">{{config?.buttonText}}</button> -->

    <!-- cip btns [ngClass]="{'content__content--spacing': true}" -->
    <div *ngIf="data[config?.showButton] == 1"
      [ngClass]="{'content__content--spacing': data[config?.buttonsArray].length > 1}">
      <div *ngFor="let item of data[config?.buttonsArray]" class="{{item[config?.buttons?.class]}} mt-1"
        (click)="buttonClicked(item,data);$event.stopPropagation()">
        {{item[config?.buttons?.text]}}
      </div>
      <!-- <div class="fs-12 text-success d-inline-block text-center border-top border-right py-1 w-50">Confirm</div>
            <div class="fs-12 text-danger d-inline-block text-center border-top py-1 w-50">Not interested</div> -->
    </div>

    <div class="d-flex align-items-center lh-1" *ngIf="config?.languageEnabled && data[config?.languageName]">
      <svg-icon svgClass="stroke-gray fill-white" src="../../../assets/icon-svg/User_icon.svg"
        [svgStyle]="{'width.rem': 1, 'height.rem': 1, 'stroke-width': 1.4}"></svg-icon>
      <span class="fs-14 pl-1"> {{data[config?.languageName]}}</span>
    </div>

  </div>

  <!-- like & dislike -->
  <!-- <ng-container >
        <div class="d-flex align-items-center justify-content-around border-top p-2">
             <div class="d-flex align-items-center lh-0">
                <svg-icon svgClass="fill-white stroke-text" [svgStyle]="{'width.rem': 1, 'height.rem': 1, 'stroke-width': 1.5}"
                    src="assets/icon-svg/Thumbs_up_icon.svg"></svg-icon>
                <span class="fs-12 body-text pl-2">Like</span>
            </div>

            <ng-container>
                <span class="border-right h-12-px"></span>
                <div class="d-flex align-items-center lh-0">
                    <svg-icon svgClass="fill-white stroke-text" [svgStyle]="{'width.rem': 1, 'height.rem': 1, 'stroke-width': 1.5}"
                    src="assets/icon-svg/Thumbs_down_icon.svg"></svg-icon>
                    <span class="fs-12 body-text pl-2">Like</span>
                </div>
            </ng-container>
        </div>
    </ng-container> -->
</div>


<!-- learner btn class = fs-12 btn btn-outline py-1 w-100 -->
<!-- trainer accept btn class = fs-12 text-success d-inline-block text-center border-top border-right py-1 w-50  -->
<!-- trainer not interested btn class = fs-12 text-danger d-inline-block text-center border-top py-1 w-50  -->

<!-- 08 Sep 2022 12:56 to 10 Sep 2022 11:00 -->
<!-- 23-Jan-22 12:56 to 11-May-22 09:45 -->
