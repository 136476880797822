import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { EventsService } from '../../../../providers/events/events.service';
import { NotificationsService } from '../../../../providers/notifications/notifications.service';
@Component({
  selector: 'ngx-notification-drawer',
  templateUrl: './notification-drawer.component.html',
  styleUrls: ['./notification-drawer.component.scss']
})
export class NotificationDrawerComponent implements OnInit {
  currentUserData: any;
  loading: boolean = false;
  currentPageNo: any = 1;
  nextPageNo: any;
  totalPageCount: any;
  noNotifications = false;
  notificationsList = [];
  working: boolean;
  totalNotificationsCount = 0;
  constructor(public eve: EventsService, private notificationsService: NotificationsService, private sanitizer: DomSanitizer) {
    this.eve.publish('user:callGetPushNotificationsCount', true);
  }

  ngOnInit(): void {
    this.getUserData();
  }

  getUserData() {
    this.currentUserData = JSON.parse(localStorage.getItem('currentUser'));
    // if (this.networkservice.getOnlineStatus()) {
    //   this.fetchPushNotificationsForFirstPage();
    this.loading = true;
    // this.load.presentLoading('Please wait....');
    this.fetchPushNotifications(1, (res) => {
      console.log('Notifications fetched...');
      this.loading = false;
      // this.load.dismiss();
    });
    // } else {
    // }
    // this.eve.publish('user:checkLogout', this.currentUserData);
  }

  fetchPushNotifications(currentPageNo, cb) {
    this.currentPageNo = currentPageNo;
    const notificationsParams = {
      userId: this.currentUserData.id,
      tenantId: this.currentUserData.tenantId,
      pageLimit: 10,
      pageNo: this.currentPageNo,
      // searchString: this.searchEmpTerm,
      currentRole: this.currentUserData.roleId
    };
    this.fetchPushNotificationsList(notificationsParams, result => {
      if (result.type === true) {
        if (this.currentPageNo !== 1) {
          const nextNotificationsList = result.data;
          this.totalPageCount = result.lastPage;
          this.totalNotificationsCount = result.rowsTotalCount;
          this.notificationsList = this.notificationsList.concat(nextNotificationsList);
        } else {
          this.totalPageCount = result.lastPage;
          this.notificationsList = result.data;
          this.totalNotificationsCount = result.rowsTotalCount;
        }
        if (this.notificationsList.length === 0) {
          this.noNotifications = true;
        } else {
          this.currentPageNo++;
        }
        // this.cdf.detectChanges();
        cb(true);
      } else {
        console.log('err ', result);
        cb(false);
      }
    });
    // this.infiniteScroll.disabled = false;
  }

  fetchPushNotificationsList(params: any, cb) {
    // this.load.presentLoading('Please wait....');
    this.notificationsService.getPushNotificationsList(params).then(
      res => {
        if (res.type === true) {
          console.log(params.pageNo, ' page Notifications ', res);
          //   this.load.dismiss();
          // this.currentPageNo++;
          cb(res);
        } else {
          // this.load.dismiss();
          console.log('err ', res);
        }
      },
      err => {
        // this.load.dismiss();
        console.log(err);
      }
    );
  }

  loadData(event) {
    setTimeout(() => {
      if (this.currentPageNo > this.totalPageCount) {
        // this.infiniteScroll.disabled = true;
        // event.target.disabled = true;
      } else {
        // this.currentPageNo++;
        if (!this.working) {
          this.working = true;
          // this.fetchNextPagePushNotifications(() => {
          //     this.working = false;
          //     console.log('Done');
          //     this.infiniteScroll.complete();
          // });
          this.fetchPushNotifications(this.currentPageNo, (res) => {
            console.log('Notifications fetched...');
            this.working = false;
            console.log('Done');
            // this.infiniteScroll.complete();
          });
        }
      }
    }, 500);
  }


  openNotification(notificationData) {
    console.log('notificationData', notificationData);
    // const res = {
    //   'notification': {
    //     'payload': {
    //       'additionalData' : JSON.parse(notificationData.additionalData);
    //     }
    //   }
    // }
    // result['notification']['payload']['additionalData'] = ;
    if (notificationData.isRead == 0) {
      this.updateNotificationsStatus(notificationData);
    }
    // this.notificationsService.RedirectNotification(notificationData);
  }

  updateNotificationsStatus(notificationData) {
    const params = {
      notificationId: notificationData.id,
      currentRole: this.currentUserData.roleId
    };

    this.notificationsService.readPushNotifications(params).then(
      res => {
        if (res.type === true) {
          console.log('Read Notifications ', res);
          // this.load.dismiss();
          notificationData.isRead = 1;
          this.eve.publish('user:callGetPushNotificationsCount', true);
        } else {
          // this.load.dismiss();
          console.log('err ', res);
        }
      },
      err => {
        // this.load.dismiss();
        console.log(err);
      }
    );
  }

}
