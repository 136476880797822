import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { ToasterService } from '../../providers/toaster/toaster.service';
import { LoadingService } from '../../providers/loading/loading.service';
import { DeliveryService } from '../../cip/providers/delivery/delivery.service';
import { UntypedFormControl } from "@angular/forms";
import { debounceTime } from 'rxjs/operators';
import { AssessorWorkflowDetailsService } from '../../cip/providers/assessor-workflow-details/assessor-workflow-details.service';
import { HelperService } from '../../providers/helper/helper.service';
import { webApi } from "../../../config";
import { AssignNotifyService } from '../../providers/assign-notify/assign-notify.service';
import { SearchableSelectService } from '../../modules/shared/providers/searchable-select/searchable-select.service';
import { popupConfig } from '../../interfaces/popup-config.model';
import { MarkAttendanceService } from '../../modules/shared/providers/mark-attendance/mark-attendance.service';
@Component({
  selector: 'ngx-common-fetch-list',
  templateUrl: './common-fetch-list.component.html',
  styleUrls: ['./common-fetch-list.component.scss']
})
export class CommonFetchListComponent implements OnInit {
  // paramsObject = null;
  currentUserData = null;
  // tableData = [];
  noDataFound = false;
  labels = [];
  @Input() type: any = 1;
  @Input() externalHeight: any = '60vh';
  @Input() paramsObject = null;
  @Output() getEvents = new EventEmitter<any>();
  currentPageNo: any;
  totalPageCount: any;
  working: any;
  public searchControl: UntypedFormControl;
  dataLimit: any;
  searchTerm: any;
  totalContentCount: any;
  tableData: any = [];
  // @Input() instanceData: any = null;
  notFound: any;
  loading: boolean = true;
  // shareFormSubmitting = false;
  selectedUserList = [];
  noDataFoundContainer = {
    showImage: true,
    title: 'Content not available',
    discription: 'Users you are looking for are not available this time, please try after some time.',
  };
  getActivityIdsStrFrom = '';
  config = {
    autoMarkCheckBox: true,
    showCustomActionLabel: true,
  };
  isInternalComponent = false;
  filterMetadata = { count: 0 };


  remindPopUpConfig: popupConfig = {
    title: 'Send Reminder'
  }
  remindNotifyConfig = {
    params: {
      searchString: '', // keyword
      instanceId: null,
      areaId: '2',
      eventIds: '165',
      type: 2,
      selectedIds: '',
      selectedUserIds: '',
    },
    submitAPI: null,
  };
  showAssignNotify = false;
  saveAndNotifyWorking = false;

  selectedUsers: any;

  configUploadPopup = {
    sidebarTitle: 'Bulk Attendance',
    buttons: [],
    footer: false,
  };

  constructor(
    private deliveryService: DeliveryService,
    public spinner: LoadingService,
    private toaster: ToasterService,
    private router: Router,
    private route: ActivatedRoute,
    private workflowDetailsService: AssessorWorkflowDetailsService,
    private helperService: HelperService,
    private assignNotifyService: AssignNotifyService,
    private searchableSelectService: SearchableSelectService,
    private markAttendanceService: MarkAttendanceService,
  ) { }

  ngOnInit(): void {
    this.bindIsInternal();
    this.resetValues();
    this.searchControl = new UntypedFormControl();
    this.initFormControl();
    // this.route.paramMap.subscribe((routeParams: any) => {
    //   console.log('route params', routeParams);
    //   // this.courseId = routeParams.params.courseId;
    //   // this.wfId = routeParams.params.wfId;
    //   this.paramsObject = routeParams.params;
    //
    // });
    this.getUserData();
  }

  bindIsInternal() {
    switch (this.type) {
      case 1: this.isInternalComponent = false; break;
      case 2: this.isInternalComponent = false; break;
      case 3: this.isInternalComponent = false; break;
      case 4: this.isInternalComponent = true; break;
      case 5: this.isInternalComponent = true; break;
    }
  }

  initFormControl() {
    this.searchControl.valueChanges
      .pipe(debounceTime(700))
      .subscribe((search: any) => {
        // this.setFilteredItems();
        console.log("Search term : ", search);
        this.searchTerm = search;
        if (this.searchTerm.length >= 3 || (this.searchTerm.length == 0 && this.notFound)) {
          //   if (!this.isWorking) {
          this.triggerSearch(search);
          //   }
        } else if (this.searchTerm.length == 0) {
          // this.searchTerm = search;
          // this.triggerSearch(true, (res) => {
          //   console.log('resetSearch completed...', res);
          // });
          this.triggerSearch(search);
        }
      });
  }

  triggerSearch(searchCourseTerm: any) {
    console.log("searchCourseTerm ", searchCourseTerm);
    if (!this.loading) {
      this.tableData = [];
      this.currentPageNo = 1;
      this.loading = true;
      this.getSearchedData((res: any) => {
        console.log('Search completed...', res);
      });
    }
  }

  getSearchedData(cb: { (res: any): void; (arg0: boolean): void; }) {
    this.fetchSearchData(1, () => {
      console.log('Searched  fetched...');
      this.loading = false;
      cb(true);
    });
  }

  clearSearch() {
    this.searchTerm = '';
    // // this.searchOnPage(this.searchTerm);
    this.setValueForSearch("", { emitEvent: true });

    // this.prepareSearchData("");
    // this.goBack();;
  }

  setValueForSearch(value: string, obj: { emitEvent: boolean; }) {
    if (this.searchControl) {
      this.searchControl.setValue(value, obj);
    }
  }

  resetValues() {
    this.currentUserData = {};
    this.notFound = false;
    this.totalPageCount = 3;
    this.working = false;
    this.selectedUserList = [];
    this.dataLimit = 20;
    this.currentPageNo = 1;
    this.searchTerm = '';
    this.totalContentCount = 0;
    this.tableData = [];
    this.labels = [];
    this.loading = false;

    this.selectedUsers = [];
  }

  getUserData() {
    this.currentUserData = JSON.parse(localStorage.getItem('currentUser'));
    this.loading = true;
    this.fetchSearchData(this.currentPageNo, () => {
      console.log('Done');
      // this.moveSelectedElem(
      //   this.tableData,
      //   this.selectedUserList,
      //   true
      // );
      this.loading = false;

      // this.searchFilterList = this.incomingFilterData;
    });
  }

  /**************** infinite scroll start **************/
  loadMoreData(event: any) {
    // if (this.networkservice.getOnlineStatus()) {
    // setTimeout(() => {
    if (this.currentPageNo != 1) {
      if (this.currentPageNo > this.totalPageCount) {
        // if (this.infiniteScroll) {
        //   this.infiniteScroll.disabled = true;
        // }
      } else {
        if (!this.working) {
          this.working = true;
          // if (this.networkservice.getOnlineStatus()) {
          this.fetchSearchData(this.currentPageNo, () => {
            this.working = false;
            console.log("Done");
            // this.infiniteScroll.complete();
          });
          // }
        }
      }
    }
    // }, 500);
    // } else {
    //   console.log("Unable to get data at this time");
    // }
  }
  /**************** infinite scroll end **************/
  /*************** get infinite scroll data start ************/
  fetchSearchData(currentPageNo: number, cb) {
    this.notFound = false;
    this.currentPageNo = currentPageNo;
    let params = {
      batchId: this.paramsObject.courseId,
      searchStr: this.searchTerm,
      pageNo: this.currentPageNo,
      limit: this.dataLimit,
      type: this.type,
      wfId: this.paramsObject.wfId,
      typeId: this.paramsObject.typeId,
      // isSelectedList:'',
    }
    if (this.type == 4 || this.type == 5) {
      params['actId'] = this.paramsObject.activityId
    }
    console.log('params', params);
    this.getUserList(params, (result, type) => {
      if (type && result.type === true) {
        if (this.currentPageNo !== 1) {
          const nexttableData = result.data;
          // this.totalContentCount = result.totalDocCount.value;
          // this.totalContentCount = result.totalCount;
          // this.totalPageCount = Math.ceil(this.totalContentCount / this.dataLimit);
          this.prepareCheckAll(nexttableData);
          this.tableData = this.tableData.concat(
            nexttableData
          );

        } else {
          this.tableData = result.data;
          // this.totalContentCount = result.totalCount;
          this.totalPageCount = result.totalPages;
          this.labels = result.labels;
          this.getActivityIdsStrFrom = result.activityIdsStr;
          if (this.searchTerm != '') {
            this.bindCheckAll();
          }
          // this.totalPageCount = Math.ceil(this.totalContentCount / this.dataLimit);
        }

        if (this.tableData.length > 0) {
          this.currentPageNo++;
        } else {
          this.setNotFound();
        }
        cb(true);
      } else {
        cb(false);
        this.setNotFound();
      }
    });

  }

  bindCheckAll() {
    this.labels.forEach(element => {
      if (element['dontShowCheckAll'] == 0) {
        element['dontShowCheckAll'] = 1
      }
    });
  }
  getUserList(params: any, cb) {
    console.log('type', params.type);
    let url = '';
    if (params.type == 1) {
      url = webApi.apiUrl.getAttendanceList;
      // this.deliveryService.fetchAttendanceList(params).then(
      //   (res: any) => {
      //     console.log(params.pageNo, ' page data list ', res);
      //     cb(res, true);
      //   },
      //   (err: any) => {
      //     console.log('err ', err);
      //     this.loading = false;
      //     cb(null, false);
      //     this.setNotFound();
      //     // this.searching = false;
      //     this.presentToastMessage('Unable to get results at this time.', 'Warning!', 'warning');
      //   }
      // );
    } else if (params.type == 2) {
      url = webApi.apiUrl.getMockList;
      // this.deliveryService.fetchMockList(params).then(
      //   (res: any) => {
      //     console.log(params.pageNo, ' page data list ', res);
      //     cb(res, true);
      //   },
      //   (err: any) => {
      //     console.log('err ', err);
      //     this.loading = false;
      //     cb(null, false);
      //     this.setNotFound();
      //     // this.searching = false;
      //     this.presentToastMessage('Unable to get results at this time.', 'Warning!', 'warning');
      //   }
      // );
    } else if (params.type == 3) {
      url = webApi.apiUrl.getFinalAssesmentList;
      // this.deliveryService.fetchFinalAssessmentList(params).then(
      //   (res: any) => {
      //     console.log(params.pageNo, ' page data list ', res);
      //     cb(res, true);
      //   },
      //   (err: any) => {
      //     console.log('err ', err);
      //     this.loading = false;
      //     cb(null, false);
      //     this.setNotFound();
      //     // this.searching = false;
      //     this.presentToastMessage('Unable to get results at this time.', 'Warning!', 'warning');
      //   }
      // );
    } else if (params.type == 4) {
      url = webApi.apiUrl.getAttendanceActivityUsers;
      // this.deliveryService.getAttendanceActivityUsers(params).then(
      //   (res: any) => {
      //     console.log(params.pageNo, ' page data list ', res);
      //     cb(res, true);
      //   },
      //   (err: any) => {
      //     console.log('err ', err);
      //     this.loading = false;
      //     cb(null, false);
      //     this.setNotFound();
      //     // this.searching = false;
      //     this.presentToastMessage('Unable to get results at this time.', 'Warning!', 'warning');
      //   }
      // );
    } else if (params.type == 5) {
      url = webApi.apiUrl.getEvaluationFeedbackUser;
    }
    if (url != '') {
      this.deliveryService.fetchSearchableSelectListDynamic(url, params).then(
        (res: any) => {
          console.log(params.pageNo, ' page data list ', res);
          cb(res, true);
        },
        (err: any) => {
          console.log('err ', err);
          this.loading = false;
          cb(null, false);
          this.setNotFound();
          // this.searching = false;
          this.presentToastMessage('Unable to get results at this time.', 'Warning!', 'warning');
        }
      ).catch(err => {
        console.log('err ', err);
        this.loading = false;
        cb(null, false);
        this.setNotFound();
        // this.searching = false;
        this.presentToastMessage('Unable to get results at this time.', 'Warning!', 'warning');
      });
    } else {
      console.log('unable to get data url');
    }
  }
  /*************** get infinite scroll data end ************/

  async presentToastMessage(msg: string, title: string, type: string) {
    await this.toaster.prsentToast(msg, title, type, null);
  }

  prepareCheckAll(data) {
    for (let index = 0; index < data.length; index++) {
      const loopdata = data[index];
      this.labels.forEach(element => {
        if (element['showCheckAll'] == 1 && element['checkAll'] == 1) {
          loopdata[element['bindingProperty']] = true;
        }
      });
    }
  }
  checkChanged(user: any, action) {
    // user[action] = !user[action];
    // if (user['isSelected']) {
    //   user['isTemp'] = true
    //   this.addToSelected(user, this.selectedUserList);
    //   this.removeFromSelected(user, this.tableData);
    //   // this.removeFromSelected(user, this.selectedUserList);
    // } else {
    //   // this.removeFromSelected(user, this.selectedUserList);
    // }
    // if (user['isSelected'] == 0) {

    //   user['isSelected'] = 1;

    //   if (!user.isTemp) {

    //     user.isTemp = true;

    //     this.addToSelected(user, this.selectedUserList);

    //     this.removeFromSelected(user, this.tableData);

    //   }

    // } else {

    //   user['isSelected'] = 0;

    //   // this.removeFromSelected(user, this.selectedUserList);

    // }
    console.log('selected Array', this.selectedUserList);
  }

  addToSelected(item: any, arr: any[]) {
    if (arr && arr.length >= 0) {
      arr.push(item);
    } else {
      console.log("Selected array not defined");
    }
  }

  setNotFound() {
    if (this.searchTerm !== '') {
      this.noDataFoundContainer = {
        showImage: true,
        title: 'Searched users are not available',
        discription: 'Searched users are not available this time, please try after some time.',
      };
    } else {
      this.noDataFoundContainer = {
        showImage: true,
        title: 'Users not available',
        discription: 'Users you are looking for are not available this time, please try after some time.',
      };
    }
    if (this.tableData.length != 0 || this.filterMetadata.count > 0) {
      this.notFound = false;
    } else {
      this.notFound = true;
    }
  }

  /******************* submit form data start ***************/


  updateAttendance(event) {
    console.log('updateAttendance', event);
    let params = {
      actionId: null,
      courseId: this.paramsObject.courseId,
      learnerStr: '',
      moduleId: '',
      activityId: '',
      trainerId: this.currentUserData.referenceId,
    }
    event == 1 ? params.actionId = 1 : params.actionId = 2;
    const data = this.prepareDataString(event);
    if (data.allStr == null && data.learnerStr == null) {
      let msg = '';
      params.actionId == 1 ? msg = 'Please select valid user(s) to marked' : msg = 'Please select valid user(s) to unmarked';
      this.presentToastMessage(msg, 'Warning', 'warning');
      return null;
    };
    params = { ...params, ...data }
    this.spinner.presentLoading('Loading...');
    this.deliveryService.markUnmark(params).then((response: any) => {
      if (response.type == true) {
        this.presentToastMessage(response.message, 'Success', 'success');
        this.spinner.dismiss();
        this.resetValues();
        this.setValueForSearch('', { emitEvent: false })
        this.getUserData();
      } else {
        this.presentToastMessage('Unable to save attendance.', 'Warning', 'warning');
        this.spinner.dismiss();
      }
    }, (err) => {
      console.error(err);
      this.spinner.dismiss();
      this.presentToastMessage('Unable to save attendance.', 'Warning', 'warning');
    }).catch((error) => {
      console.error(error);
      this.spinner.dismiss();
      this.presentToastMessage('Unable to save attendance.', 'Warning', 'warning');
    });
  }

  prepareDataString(type) {

    this.selectedUsers = [];

    let activityArray = this.getActivityIdsStrFrom ? this.getActivityIdsStrFrom.split(',') : [];
    let checkAllString = '';
    let learnStr = '';

    for (let index = 0; index < this.labels.length; index++) {
      if (this.labels[index]['showCheckAll'] == 1 && this.labels[index]['checkAll']) {
        if ((type == 1 && this.labels[index]['allMarked'] == 0) || (type == 2 && this.labels[index]['allUnMarked'] == 0)) {
          if (checkAllString != '') {
            checkAllString += ',';
          }
          checkAllString += this.labels[index]['bindingProperty'];
        }
        activityArray = activityArray.filter((e) => {
          Number(e) !== Number(this.labels[index]['bindingProperty'])
        });
      }
    };

    for (let index = 0; index < this.tableData.length; index++) {
      const learner = this.tableData[index];
      for (let j = 0; j < activityArray.length; j++) {
        let activityId = activityArray[j];
        // const DataKey = 'attrDate' + activityId;
        const disableKey = 'disable' + activityId;
        const attendanceMarkedKey = 'attrMarked' + activityId;
        if (learner[disableKey] != 1 && learner[activityId] && ((type == 1 && learner[attendanceMarkedKey] != 1) || (type == 2 && learner[attendanceMarkedKey] == 1))) {
          learnStr += learner.enrolId + '|' + learner.employeeId + '|' + learner.userId + '|' + activityId + '#';

          this.selectedUsers.push(learner);

        }
        // console.log('this.selectedLearners after' + this.selectedLearners);
      }
    }

    console.log('checkAllString', checkAllString);
    console.log('learnStr', learnStr);

    return {
      allStr: checkAllString == '' ? null : checkAllString,
      learnerStr: learnStr == '' ? null : learnStr
    };
  }
  /******************* submit form data END ***************/

  /*************** Perform Action Start  ************/
  performAction(event) {
    console.log('event', event);
    const action = event.action.action ? event.action.action : event.action;
    switch (action) {
      case "singelSelectData":
        console.log('checkUncheck event', event);
        this.checkChanged(event.data, event.action.on);
        break;
      case "evaluate":
        console.log('evaluate event', event);
        this.goToEvaluate(event);
        break;
      case "download":
        console.log('evaluate event', event);
        this.downloadResource(event);
        break;
      case "checkUncheckAll":
        console.log('checkUncheck event', event, this.labels, this.tableData);
        break;
      case 'saveNSubmit':
        this.saveNotifySubmit(event.data);
        break;
      case 'closeAssignNotify':
        this.toggelAssignNotify(false, event.data);
        break;
      default:
        console.log('Invalid action', event, this.labels, this.tableData);
        this.sendEvents(event.action, event.data);
        break;
    }
  }
  /*************** Perform Action End  ************/

  goToEvaluate(event) {
    this.workflowDetailsService.courseData = {};
    this.workflowDetailsService.courseData['enrolId'] = event.data.enrolId;
    this.workflowDetailsService.courseData['userId'] = event.data.userId;
    this.workflowDetailsService.courseData['workflowId'] = this.paramsObject.wfId;
    this.workflowDetailsService.courseData['employeeId'] = event.data.employeeId;
    this.workflowDetailsService.courseData['activityId'] = event.action.bindingProperty;
    this.workflowDetailsService.courseData['areaId'] = 5;
    this.workflowDetailsService.courseData['supertypeId'] = 6;
    if (event.action && event.action.extraData) {
      this.workflowDetailsService.courseData = { ...this.workflowDetailsService.courseData, ...event.action.extraData }
    }
    // this.workflowDetailsService.courseData['activityTypeId'] = event.data.activityTypeId;
    let navigationExtras: NavigationExtras = {
      state: {
        isTitle: true,
        title: ''
      }
    }
    this.router.navigateByUrl('cip/evaluate/' + event.action.bindingProperty, navigationExtras);
  }

  downloadResource(event) {
    console.log('downloadResource', event);
    const link = 'downloadLink' + event.action.bindingProperty;
    this.helperService.downloadResource(event.data[link], 1);
  }
  certifyUsers() {
    console.log('certifyUsers');
    this.spinner.presentLoading('Loading ...');
    const empId = this.generateCertifyString();
    const params = {
      empId: empId,
      wfId: this.paramsObject.wfId,
      trainerId: this.currentUserData.referenceId,
    };
    if (params.empId == '') {
      this.presentToastMessage('Please select employee(s) to certify', 'Warning', 'warning');
      this.spinner.dismiss();
      return;
    }
    this.deliveryService.certifyLearner(params).then((res: any) => {
      if (res.type == true) {
        this.presentToastMessage(res.msg, 'Success', 'success');
        this.spinner.dismiss();
        this.resetValues();
        this.setValueForSearch('', { emitEvent: false })
        this.getUserData();
      } else {
        this.presentToastMessage('Unable to certify', 'Warning', 'warning');
        this.spinner.dismiss();
      }
    }).catch((error) => {
      this.spinner.dismiss();
      this.presentToastMessage('Unable to certify', 'Warning', 'warning');
      console.error(error);
    })
  }

  generateCertifyString() {
    let empIds = '';
    for (let index = 0; index < this.tableData.length; index++) {
      const learner = this.tableData[index];
      if (learner['isCertifyEnable'] == 0 && learner['isCertified'] == 1) {
        if (empIds != '') {
          empIds += '|'
        }
        empIds += learner.employeeId;
      }
    }
    return empIds;
  }

  sendEvents(action, data) {
    const event = {
      action: action,
      data: data,
    }
    console.log('sendEvents', event)
    this.getEvents.emit(event);
  }

  /************ send reminders start **********/
  sendReminders() {
    const data = this.prepareDataString(1);
    // console.log('sendReminders content data : ', this.paramsObject);
    // console.log('sendReminders users data : ', data);
    console.log('Selected users : ', this.selectedUsers);
    if (data.allStr == null && data.learnerStr == null) {
      let msg = 'Please select valid user(s) to send reminders';
      // params.actionId == 1 ? msg = 'Please select valid user(s) to marked' : msg = 'Please select valid user(s) to unmarked';
      this.presentToastMessage(msg, 'Warning', 'warning');
      return null;
    };
    const params = {
      areaId: 2,
      instanceId: this.paramsObject.courseId,
      // areaId: this.paramsObject.areaId,
      // instanceId: this.paramsObject.activityId,
      // batchId: this.paramsObject.courseId,
      // wfId: this.paramsObject.wfId,
      // typeId: this.paramsObject.typeId,
    }
    const selectedUsersStr = this.searchableSelectService.makeSelectedStrReady(this.selectedUsers, 'id', ',', false);
    this.toggelAssignNotify(true, params, selectedUsersStr);
  }

  toggelAssignNotify(flag, data?: {}, selectedIds?: null) {
    if (flag) {
      // this.remindNotifyConfig = this.assignNotifyService.getPopupConfig();
      this.remindNotifyConfig.params = {
        ...this.remindNotifyConfig.params,
        ...data,
        selectedIds: selectedIds
      }
    }
    this.showAssignNotify = flag;
    this.sendEvents('remindPopUpStatus', this.showAssignNotify);
  }

  saveNotifySubmit(data) {
    console.log('event.data', data);
    console.log('this.remindNotifyConfig.params', this.remindNotifyConfig.params);
    if (!this.saveAndNotifyWorking) {
      this.saveAndNotifyWorking = true;
      const extraParams = {
        notifyTemplate: data,
        // type: 2,
        areaId: this.paramsObject.areaId,
        instanceId: this.paramsObject.activityId,
      }
      this.spinner.presentLoading('Loading');
      this.searchableSelectService.submitSelectedData(webApi.apiUrl.sendAttendanceReminder, this.remindNotifyConfig.params, this.remindNotifyConfig.params.selectedIds, extraParams, (flag, res) => {
        this.saveAndNotifyWorking = false;
        this.spinner.dismiss();
        if (flag == 1) {

          this.toggelAssignNotify(false);
          this.getSearchedData((res: any) => {
            console.log('Search completed...', res);
          });

          this.presentToastMessage(
            res.data[0].msg, 'Success!', 'success'
          );
        } else if (flag == 2) {
          this.presentToastMessage(
            res.data[0].msg, 'Warning!', 'warning'
          );
        } else {
          this.presentToastMessage(
            "Unable to submit , please try again", 'Warning!', 'warning'
          );
        }
      })
    }
  }
  /************ send reminders end **********/

  /*************** Generate QR Code start ************/
  generateQr() {
    const params = {
      // areaId: this.paramsObject.areaId,
      activityId: this.paramsObject.activityId,
      roleId: this.currentUserData.roleId,
    }
    this.spinner.presentLoading('Loading');
    this.markAttendanceService.generateQR(params).then((res) => {
      console.log('generateQr response ', res);
      this.spinner.dismiss();
      if (res.type) {
        if (res.data) {
          this.paramsObject.qrLink = res.data.qrLink;
        }
      } else {
        this.presentToastMessage('Unable to generate QR at this time.', 'Warning', 'warning');
      }
    }).catch((err: any) => {
      this.spinner.dismiss();
      console.log('generateQr error ', err);
      this.presentToastMessage('Unable to generate QR at this time.', 'Warning', 'warning');
    });
  }
  /*************** Generate QR Code end ************/

  /*************** Download QR Code start ************/
  downloadQR(link) {
    this.helperService.downloadResource(link, 1);
  }
  /*************** Download QR Code end ************/

  /*************** Bulk mark attendance start ************/
  bulkAttendance() {
    this.sendEvents('bulkAttendanceStatus', true);
  }
  /*************** Bulk mark attendance end ************/

  ngOnDestroy() {
    this.spinner.dismiss();
  }
}
