import { ExtraOptions, RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import { AuthGuard } from "./guard/auth.guard";
import { LoginGuard } from './guard/login.guard';
import { AppGuard } from './guard/app.guard';
import { RoleGuard } from './guard/role.guard';
import { AppRoutingPreloaderService } from "./providers/appRoutingPreloader/app-routing-preloader.service";

export const routes: Routes = [
  {
    path: "pages/features",
    loadChildren: () =>
      import("./modules/features/features.module").then((m) => m.FeaturesModule)
    , canActivate: [AppGuard, AuthGuard, RoleGuard],
  },
  {
    path: "manager/features",
    loadChildren: () =>
      import("./modules/features/features.module").then((m) => m.FeaturesModule),
    canActivate: [AppGuard, AuthGuard, RoleGuard],
  },
  {
    path: "pages",
    loadChildren: () =>
      import("./pages/pages.module").then((m) => m.PagesModule),
    canActivate: [AppGuard, AuthGuard],
  },
  {
    path: "auth",
    loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule),
    canActivate: [AppGuard, LoginGuard]
  },
  { path: 'cip', loadChildren: () => import('./cip/cip.module').then(m => m.CipModule), canActivate: [AppGuard, AuthGuard, RoleGuard], },
  { path: 'manager', loadChildren: () => import('./modules/manager/manager.module').then(m => m.ManagerModule), canActivate: [AppGuard, AuthGuard], },
  { path: 'misc', loadChildren: () => import('./misc/misc.module').then(m => m.MiscModule) },
  // {
  //   path: 'auth',
  //   component: NbAuthComponent,
  //   children: [
  //     {
  //       path: '',
  //       component: NbLoginComponent,
  //     },
  //     {
  //       path: 'login',
  //       component: NbLoginComponent,
  //     },
  //     {
  //       path: 'register',
  //       component: NbRegisterComponent,
  //     },
  //     {
  //       path: 'logout',
  //       component: NbLogoutComponent,
  //     },
  //     {
  //       path: 'request-password',
  //       component: NbRequestPasswordComponent,
  //     },
  //     {
  //       path: 'reset-password',
  //       component: NbResetPasswordComponent,
  //     },
  //   ],
  // },
  { path: 'shared', loadChildren: () => import('./modules/shared/shared.module').then(m => m.SharedModule), canActivate: [AppGuard, AuthGuard] },
  {
    path: 'learner',
    loadChildren: () => import('./modules/learner/learner.module').then(m => m.LearnerModule),
    canActivate: [AppGuard, AuthGuard]
  },
  {
    path: 'learner/features',
    loadChildren: () => import('./modules/features/features.module').then(m => m.FeaturesModule),
    canActivate: [
      AppGuard,
      AuthGuard,
      RoleGuard
    ]
  },
  { path: "", redirectTo: "auth", pathMatch: "full" },
  // { path: '', redirectTo: 'pages', pathMatch: 'full' },
  { path: "**", redirectTo: "misc" },
];

const config: ExtraOptions = {
  useHash: true,
  preloadingStrategy: AppRoutingPreloaderService,
  relativeLinkResolution: "legacy",
  paramsInheritanceStrategy: 'always',
  onSameUrlNavigation: 'reload'
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
