import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommonFunctionsService {

  constructor() { }


  checkForProperData(array) {
    if (array && Array.isArray(array) && array.length != 0) {
      return true;
    }
    return false;

  }


  getMatchedArray(dataArray, arraytoMatch, variableToMatch) {
    // const IdList = dataToMatchedList.userIds.split(',');
    if (this.checkForProperData(dataArray) && this.checkForProperData(arraytoMatch) && !variableToMatch) {
      return [];
    }


    const matchedItem = [];
    const IdKey = {};
    arraytoMatch.forEach(element => {
      IdKey[element] = true;
    });

    dataArray.forEach((data, index) => {
      if (IdKey[data[variableToMatch]] == true) {
        matchedItem.push(data);
      }
    });

    return matchedItem;
  }

  getAction(event) {
    return event.action ? event.action.action ? event.action.action : event.action : event.type;
  }
}
