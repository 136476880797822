import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';

@Component({
  selector: 'ngx-quiz-container',
  templateUrl: './quiz-container.component.html',
  styleUrls: ['./quiz-container.component.scss']
})
export class QuizContainerComponent implements OnInit {

  @Output() componentDataToParent = new EventEmitter();
  @Input() courseDataContentInput: any = {};
  @Input() courseDataSummaryInput: any = {};

  quizSubmitResponse = null;
  showContainer = '';
  constructor() { }

  ngOnInit() {
    this.showContainer = 'quiz';
  }

  performActionOnChildComponentData(event){
    console.log('Quiz Data ==>', event);

    if (event['showComponent'] === 'next-activity'){
      event['loaderFlag'] = true;
      this.showContainer = '';
    }else {
      if(this.showContainer !== event.showComponent){
        this.showContainer = event.showComponent;
        this.quizSubmitResponse = event.quizSubmitResponse;
      }
    }
    this.componentDataToParent.emit(event);
  }

}
