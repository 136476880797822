import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";

import { Router } from "@angular/router";
import { MarkAttendanceService } from "../../modules/shared/providers/mark-attendance/mark-attendance.service";
import { SharedService } from "../../modules/shared/providers/shared.service";
import { ActivityService } from "../../providers/activity/activity.service";
import { PdfService } from "../../providers/activity/pdf/pdf.service";
import { EventsService } from "../../providers/events/events.service";
import { ModuleService } from "../../providers/module/module.service";
import { ToasterService } from "../../providers/toaster/toaster.service";

@Component({
  selector: "ngx-module-activity-container",
  templateUrl: "./module-activity-container.component.html",
  styleUrls: ["./module-activity-container.component.scss"],
})
export class ModuleActivityContainerComponent implements OnInit {
  chartData = [
    {
      "name": "Completed",
      "value": 0,
      "extra": {
        "code": "de"
      }
    },
  ]
  legendPosition: string = "below";
  gradient: boolean = true;
  showLegend: boolean = false;
  showLabels: boolean = false;
  isDoughnut: boolean = true;
  view: any[] = [75, 75];
  // arcWidth: 1;
  // dimension:any = 'fitContainer';
  colorScheme = {
    domain: ['blue', '#00d5a9', '#efefef', '#AAAAAA']
  };

  currentActIndex = null;
  currentModuleIndex = null;
  @Input() config = {
    moduleActivityList: null,
    courseData: null,
    autoPlayFlag: false,
    flag: "",
    requestedActId: null
  };
  // New Variables
  routeFrom = "activity"
  courseDetailSummary: any = [];
  isServiceDisabled: any;
  currentUser: any;
  moduleList: any = [];
  employeeId = null;
  currentActivityData: any;
  showArrow = true;
  //  Activity Data Variable
  checkResponse: any;
  disabledGoToCourse = false;
  passKey = "";
  showConatinerSpinner = false;
  showComponent = "";

  passWrong: boolean = false;
  submitted: boolean = false;
  password: string = "";
  showDescri: boolean = false;

  noDataFound = {
    title: "",
    description: "",
    showImage: true,
  };

  defaultLangugae = {
    languageId: null,
    languageName: "Select Language",
    // languageCode : null,
  };
  displaySettings: any = {
    'CommentCountEnabled': true,
    'likeEnabled': true,
    'dislikeEnabled': true,
  };
  // languageChangeSubscriber = null;

  requestedActId: any = null;

  remindPopupOpened = false;

  showAutoPlayConatiner = false;

  configUploadPopup = {
    sidebarTitle: 'Bulk Attendance',
    buttons: [],
    footer: false,
  };

  pdfProgressEventSub: any;
  currentPdfProgress: any;
  courseCompleted: any = false;

  constructor(
    public toaster: ToasterService,
    public moduleService: ModuleService,
    public cdf: ChangeDetectorRef,
    private eventsService: EventsService,
    public activityService: ActivityService,
    private sharedService: SharedService,
    private router: Router,
    public markAttendanceService: MarkAttendanceService,
    private pdfService: PdfService
  ) {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    const cardDisplaySetting = localStorage.getItem('displaySettings') ? JSON.parse(localStorage.getItem('displaySettings')) : null;
    if (cardDisplaySetting) {
      console.log('cardDisplaySetting', cardDisplaySetting);
      this.displaySettings.CommentCountEnabled = cardDisplaySetting['CommentCountEnabled'] == '1' ? true : false;
      this.displaySettings.likeEnabled = cardDisplaySetting['likeEnabled'] == '1' ? true : false;
      this.displaySettings.dislikeEnabled = cardDisplaySetting['dislikeEnabled'] == '1' ? true : false;
    }
  }
  windowFullScreen = false;

  ngOnInit() {
    // Get Course Information
    this.intializeData();
    this.makeModuleActivityDataReady();
    if (this.requestedActId && (this.currentActIndex != null)) {
      this.goToFirstAcitivity(this.currentModuleIndex, this.currentActIndex);
    } else {
      this.goToFirstAcitivity(0, 0);
    }
    ["", "webkit", "moz", "ms"].forEach((prefix) =>
      document.addEventListener(
        prefix + "fullscreenchange",
        () => {
          if (!window.screenTop && !window.screenY) {
            this.windowFullScreen = false;
            console.log("not fullscreen");
          } else {
            this.windowFullScreen = true;
            console.log("fullscreen");
          }
        },
        false
      )
    );
  }

  intializeData() {
    this.moduleList = this.config.moduleActivityList;
    this.autoPlayFlag = this.config.autoPlayFlag || false;
    this.courseDetailSummary = this.config.courseData;
    this.requestedActId = this.config.requestedActId;
    this.currentActIndex = null;
    this.currentModuleIndex = null;

    this.resetPDFProgress();
    this.pdfProgressEventSub = this.pdfService.progress$.subscribe((progress: number) => {
      this.currentPdfProgress = progress;
      this.updatePDFProgress();
    });
  }

  // Component working logic

  // Show Module click
  showdiv(i) {
    // console.log('data', data);
    // if (this.modules[i].activities.length > 1) {
    //   for (let k = 0; k < this.modules.length; k++) {
    //     if (k !== i) {
    //       this.modules[k].show = false;
    //     }
    //   }
    // }
    // this.modules[i].show = !this.modules[i].show;

    // if (this.moduleList[i].isDisable == "Y") {
    //   let date = "";
    //   if (this.moduleList[i].mStartDate) {
    //     date = this.moduleList[i].mStartDate;
    //   } else {
    //     date = "NA";
    //   }
    //   this.noDataFound.title =
    //     this.moduleList[i].moduleName + " will start from " + date;
    //   this.noDataFound.description = "";
    //   this.noDataFound.showImage = false;
    //   this.showComponent = "noDataFound";
    //   return null;
    // }

    if (this.moduleList[i].isDisable == "Y") {
      this.resetBeginContainerData();
    }
    if (!this.setBeginContainerData(this.moduleList[i], "module")) {
      if (this.moduleList[i].list.length > 0) {
        for (let k = 0; k < this.moduleList.length; k++) {
          if (k !== i) {
            this.moduleList[k].show = false;
          }
        }
      }
      this.moduleList[i].show = !this.moduleList[i].show;
    } else {
      this.showBeginContainer = true;
    }
  }

  // Go To first Activity
  goToFirstAcitivity(currentModuleIndex, currentActIndex) {
    if (this.courseDetailSummary && this.courseDetailSummary.areaId == 34 && !this.courseDetailSummary.playListId) {
      this.currentActivityData = this.moduleList[currentModuleIndex].list[currentActIndex];
      this.currentModuleIndex = currentModuleIndex;
      this.currentActIndex = currentActIndex;
      // this.showdiv(0);
      this.showBeginBox(this.moduleList[currentModuleIndex].list[currentActIndex], currentModuleIndex, currentActIndex);
    } else if (
      (this.moduleList[currentModuleIndex].isDisable === "Y" ||
        this.moduleList[currentModuleIndex].list[currentActIndex].completed)
      // && !this.autoPlayFlag
    ) {
      this.currentModuleIndex = currentModuleIndex;
      this.currentActIndex = currentActIndex;
      this.currentActivityData = this.moduleList[currentModuleIndex].list[currentActIndex];
      this.showdiv(currentModuleIndex);
      this.goToAutoNext(true);
    } else if (
      (this.moduleList[currentModuleIndex].list[currentActIndex].wait ||
        this.moduleList[currentModuleIndex].list[currentActIndex].byTrainer == 1)
      && this.autoPlayFlag
    ) {
      this.currentModuleIndex = currentModuleIndex;
      this.currentActIndex = currentActIndex;
      this.currentActivityData = this.moduleList[currentModuleIndex].list[currentActIndex];
      this.showdiv(currentModuleIndex);
      this.goToAutoNext(true);
    } else {
      this.showdiv(currentModuleIndex);
      // this.goToCourse(this.moduleList[0].list[0], 0 , 0);
      this.showBeginBox(this.moduleList[currentModuleIndex].list[currentActIndex], currentModuleIndex, currentActIndex);
      // this.checkIfNextExist();
      // this.checkIfPreviousExist();
    }
  }
  // lastAcitivityReachFlag = false;

  // Go To auto next Activity
  goToAutoNext(firstTimeFlag) {
    if (this.requestedActId) {
      console.log('Dont go to next...');
      // Autoplay changes
      if (this.autoPlayFlag) {
        this.showBeginBox(
          this.moduleList[this.currentModuleIndex].list[this.currentActIndex],
          this.currentModuleIndex,
          this.currentActIndex
        );
      }
    } else {
      if (
        !(this.currentModuleIndex === this.moduleList.length - 1 &&
          this.moduleList[this.currentModuleIndex].list.length - 1 === this.currentActIndex)
      ) {
        this.showComponent = "";
        this.setNoDataFoundContainer(null, false);
        this.resetBeginContainerData();
      }
      if (this.autoPlayFlag) {
        if (
          this.currentModuleIndex === this.moduleList.length - 1 &&
          this.moduleList[this.currentModuleIndex].list.length - 1 ===
          this.currentActIndex
        ) {
          if (this.moduleList[this.currentModuleIndex].isDisable == "Y") {
            return null;
          } else {
            this.showBeginBox(
              this.currentActivityData,
              this.currentModuleIndex,
              this.currentActIndex
            );
          }
        } else {
          this.skipToNextActivityAuto();
        }
      } else {
        if (firstTimeFlag) {
          this.skipToNextActivityFirstTime();
        } else {
          this.skipToNextActivity();
        }
      }

      // this.checkIfNextExist();
      // this.checkIfPreviousExist();
    }
  }

  // Go To auto previous Activity
  goToPrevious() {
    if (this.currentModuleIndex === 0 && 0 === this.currentActIndex) {
      // this.lastAcitivityReachFlag = true;
      return null;
    }

    this.showComponent = "";
    this.setNoDataFoundContainer(null, false);
    this.resetBeginContainerData();
    if (this.autoPlayFlag) {
      this.skipToPreviousActivityAuto();
    } else {
      this.skipToPreviousActivity();
    }

    // this.goToCourse(this.currentActivityData , this.currentModuleIndex, this.currentActIndex);
  }

  setRequestedActDetails(currentModIndex, currentActIndex, currentActivity) {
    if (this.currentModuleIndex == null && this.currentActIndex == null) {
      if (this.requestedActId && (currentActivity && currentActivity.activityId == this.requestedActId)) {
        this.currentClickedActivityData = currentActivity;
        // this.moduleService.updateActData = true;
        this.currentModuleIndex = currentModIndex;
        this.currentActIndex = currentActIndex;
      } else {
        console.log('Requested activity not matched...');
      }
    } else {
      console.log('Module and activity are already selected...');
    }
  }

  // Make Module activity Data Ready
  makeModuleActivityDataReady() {
    for (let i = 0; i < this.moduleList.length; i++) {
      this.moduleList[i]["show"] = false;
      this.moduleList[i].list.forEach((element, actIndex) => {
        element = this.finddependetactivity(element);
        this.setRequestedActDetails(i, actIndex, element);
      });
    }
    console.log("Final Module List ===>", this.moduleList);
  }

  // Make Dependent activity Data Ready
  finddependetactivity(detail) {
    detail["begin"] = true;
    detail["wait"] = false;
    detail["completed"] = false;
    detail["pending"] = false;
    if (detail.wCompleted === 1) {
      detail.completed = true;
      detail.wait = false;
      detail.begin = false;
      detail.pending = false;
    } else if (detail.dependentActId !== 0) {
      detail["dependentActName"] = detail.dependentActName;
      if (detail.dependentActCompStatus === 1) {
        detail.wait = false;
        detail.begin = true;
        detail.completed = false;
        detail.pending = false;
      } else {
        detail.wait = true;
        detail.begin = false;
        detail.completed = false;
        detail.pending = false;
      }
    }
    detail["actIcon"] = this.bindActivityIcon(detail);
    detail["actFormatName"] = this.bindActivityFormatName(detail);
    // detail["showBeginContainer"] = this.bindBeginContainer(detail);
    return detail;
    // console.log('Data ==>', detail);
    // if (detail.wCompleted == 1) {
    //   return 3;
    // } else if (detail.wCompleted == 0) {
    //   if (detail.dependentActId == 0) {
    //     if (detail.byTrainer == 1) {
    //       return 2;
    //     } else {
    //       return 1;
    //     }
    //   } else if (detail.dependentActId != 0) {
    //     if (detail.dependentActCompStatus == 0) {
    //       return 4;
    //     } else if (detail.dependentActCompStatus == 1) {
    //       return 1;
    //     }
    //   } if (detail.byTrainer == 1) {
    //     return 2;
    //   }
    // }
    // if(detail.dependentActId == 0){
    //   if(detail.wCompleted == 0 ){
    //     return 1;
    //   }
    // } else if(detail.wCompleted == 0 && detail.byTrainer == 1){
    //   return 2;
    // } else if(detail.wCompleted == 1){
    //   return 3;
    // } else if( detail.dependentActId != 0){
    //   if( detail.wCompleted == 0 && detail.dependentActCompStatus == 1){
    //     return 1;
    //   } else if(detail.wCompleted == 0 && detail.dependentActCompStatus == 0){
    //     return 4
    //   }
    // }
  }

  selectedLanguageData: any;
  tempSelectedLanguageData: any;

  // Change Activity Language
  changeActivityLanguage(languageData, flag) {
    // console.log('Change Activity Language =>', languageData);
    if (!this.selectedLanguageData || (this.selectedLanguageData && this.selectedLanguageData.languageId != languageData.languageId)) {
      if (this.currentActivityData['activityTypeId'] == 5) {
        if ((this.currentActivityData['resumeFlag'] == 1 || this.selectedLanguageData) && !flag) {
          let errorMsg = 'You cannot change language';
          this.presentAlert(errorMsg, "Warning!", "warning");
        } else if (flag && this.currentActivityData['resumeFlag'] != 1) {
          // this.setDefaultAutoPlay(); __pending
        } else {
          if (this.courseCompleted) {
            let errorMsg = 'Completed all attempts.';
            this.presentAlert(errorMsg, "Warning!", "warning");
          } else {
            this.changelanguage(languageData, flag);
          }
        }
      } else {
        this.changelanguage(languageData, flag);
      }
    } else {
      console.log('Same langugae selected...');
    }
  }

  changelanguage(languageData, flag) {
    this.selectedLanguageData = languageData;
    this.showAutoPlayConatiner = false;
    let multiContentActivity = null;
    if (
      this.currentActivityData &&
      Array.isArray(this.currentActivityData["multiLanguageList"])
    ) {
      // old
      // for (
      //   let index = 0;
      //   index < this.currentActivityData["multiLanguageList"].length;
      //   index++
      // ) {
      //   const element = this.currentActivityData["multiLanguageList"][index];
      //   if (
      //     Number(element["languageId"]) === Number(languageData["languageId"])
      //   ) {
      //     element["selected"] = true;
      //     this.selectedLanguageData = element;
      //   } else {
      //     element["selected"] = false;
      //   }
      // }
      // new
      multiContentActivity = this.updateLanguageStatus(languageData);
      console.log('Selected language from activity multi-language list : ', multiContentActivity);
    }
    this.setActivityData(multiContentActivity, flag);
    // this.multiLanguageService.updateActivityLanguage(this.currentActivityData);
    // console.log('Change Activity Language =>', languageData);
  }

  /********** Autoplay activity changes start *********/
  updateLanguageStatus(languageData) {
    let selectedLanguage = null;
    for (let index = 0; index < this.currentActivityData['multiLanguageList'].length; index++) {
      const element = this.currentActivityData['multiLanguageList'][index];
      if (languageData) {
        if (Number(element['languageId']) === Number(languageData['languageId'])) {
          element['selected'] = true;
          selectedLanguage = element;
        } else {
          element['selected'] = false;
        }
      } else {
        element['selected'] = false;
      }
    }
    return selectedLanguage;
  }
  /********** Autoplay activity changes end *********/

  // Get Multilanguage data at start
  setActivityDataAtStart() {
    // let multiContentActivity = null;
    // for (
    //   let index = 0;
    //   index < this.currentActivityData["multiLanguageList"].length;
    //   index++
    // ) {
    //   const element = this.currentActivityData["multiLanguageList"][index];
    //   if (element["selected"]) {
    //     multiContentActivity = element;
    //     break;
    //   }
    // }

    // old
    // if (
    //   this.currentActivityData["multiLanguageList"] &&
    //   this.currentActivityData["multiLanguageList"].length != 0
    // ) {
    //   this.currentActivityData["multiLanguageList"][0]["selected"] = true;
    //   this.setActivityData(
    //     this.currentActivityData["multiLanguageList"][0],
    //     false
    //   );
    // }

    // new
    if (
      this.currentActivityData["multiLanguageList"] &&
      this.currentActivityData["multiLanguageList"].length != 0
    ) {
      let multLangCount = this.currentActivityData["multiLanguageList"].length;
      this.checkIfShowAutoPlayTimer(this.currentActivityData, multLangCount, null, null);
    } else {
      // this.showBeginBox(this.moduleList[this.currentModuleIndex].list[this.currentActIndex], this.currentModuleIndex, this.currentActIndex);
      // this.showBeginBox(
      //   this.currentActivityData,
      //   this.currentModuleIndex,
      //   this.currentActIndex
      // );

      // Autoplay playlist changes
      if (this.autoPlayFlag) {
        this.currentActivityData['isAutoPlay'] = this.currentClickedActivityData['isAutoPlay'];
      }
      console.log('Multi-Languages not available...', this.currentActivityData);
    }
  }

  checkIfShowAutoPlayTimer(currentActivity, multLangCount, type, autoPlayType) {
    // currentActivity.showAutoPlayTime &&
    if (multLangCount > 0) {
      // if (autoPlayType == 1) {
      let langId = this.courseDetailSummary.lastLang || this.courseDetailSummary.firstLang;
      let defLangId = currentActivity.defLangId || this.currentClickedActivityData.defLangId;
      //  || defLangId
      if (langId) {
        let langData = this.sharedService.getObjFromArray(currentActivity.multiLanguageList, langId, 'languageId');
        let selectedLanguage;
        if (langData) {
          selectedLanguage = this.updateLanguageStatus(langData);
        } else if (defLangId) {
          let defLangData = this.sharedService.getObjFromArray(currentActivity.multiLanguageList, defLangId, 'languageId');
          if (defLangData) {
            selectedLanguage = this.updateLanguageStatus(defLangData);
          } else {
            let tempLang = currentActivity.multiLanguageList[0];
            selectedLanguage = this.updateLanguageStatus(tempLang);
          }
        } else {
          let tempLang = currentActivity.multiLanguageList[0];
          selectedLanguage = this.updateLanguageStatus(tempLang);
        }
        console.log('Autoplay Selected language from activity multi-language list : ', selectedLanguage);
        this.changeActivityLanguage(selectedLanguage, true);
        // let selectedLanguageIndex = this.sharedService.getRequestedItemIndex(currentActivity.multiLanguageList, 'languageId', selectedLanguage.languageId);
        // if (selectedLanguageIndex != -1) {
        //   this.setActivityData(
        //     this.currentActivityData["multiLanguageList"][selectedLanguageIndex],
        //     false
        //   );
        // } else {
        //   console.log('Requested langugae not found...');
        // }
      } else {
        this.currentActivityData["multiLanguageList"][0]["selected"] = true;
        this.setActivityData(
          this.currentActivityData["multiLanguageList"][0],
          false
        );
      }
    } else {
      // this.makeActivityContainerDataReady();
    }
  }

  getPreSignedUrlForContent(actData, cb) {
    const activityData = {
      // fileType: 2,
      filename: actData.reference,
      // expirationtime: 3600,
    };
    // this.load.presentLoading('Please wait...');
    this.activityService.getPreSignedUrl(activityData).then(res => {
      //   this.load.dismiss();
      console.log('getPreSignedUrl res : ', res);
      // cb(res.url);
      if (res.type === true) {
        actData.reference = res.data;
        cb(actData, true);
      } else {
        actData['isDeleted'] = 1;
        actData['isDeletedMsg'] = res.msg;
        cb(actData, false);
      }
    }, err => {
      // this.load.dismiss();
      console.log('getPreSignedUrl err : ', err);
      actData['isDeleted'] = 1;
      actData['isDeletedMsg'] = 'Unable to get data';
      cb(actData, false);
    });
  }

  // Set MultiLanguage activity Data
  setActivityData(multiContentActivity, flag) {
    this.showConatinerSpinner = true;
    this.showComponent = "";
    this.cdf.detectChanges();
    this.currentActivityData["formatId"] = multiContentActivity["formatId"];
    this.currentActivityData["contentId"] = multiContentActivity["contentId"];
    this.currentActivityData["contentRepId"] = multiContentActivity["contentId"];
    // this.currentActivityData['defaultlangId'] = multiContentActivity['languageId'];
    this.currentActivityData["languageName"] =
      multiContentActivity["languageName"];
    this.currentActivityData["mimeType"] = multiContentActivity["mimeType"];
    this.currentActivityData["reference"] = multiContentActivity["reference"];
    this.currentActivityData["referenceType"] =
      multiContentActivity["referenceType"];
    this.currentActivityData["fileinfo"] = multiContentActivity["fileinfo"];

    this.currentActivityData["defaultlangId"] =
      multiContentActivity["languageId"];

    // Autoplay changes
    this.currentActivityData["languageId"] =
      multiContentActivity["languageId"];

    //new change
    this.currentActivityData["isDeleted"] = multiContentActivity["isDeleted"];
    this.currentActivityData["isDeletedMsg"] = multiContentActivity["isDeletedMsg"];
    // this.currentActivityData['icon'] = this.bindActivityContainerIcon(multiContentActivity)
    //end change
    this.currentActivityData['contentSource'] = multiContentActivity['contentSource'];
    this.currentActivityData['referanceKey'] = multiContentActivity['referanceKey'];

    // autoplay activity change
    this.currentActivityData['isAutoPlay'] = this.currentClickedActivityData['isAutoPlay'];

    this.currentActivityData['byTrainer'] = this.currentClickedActivityData['byTrainer'];
    this.currentActivityData['byLearner'] = this.currentClickedActivityData['byLearner'];

    if (
      !(
        Number(this.currentActivityData.complitionlangId) ===
        Number(multiContentActivity["languageId"])
      )
    ) {
      this.currentActivityData["contentwatchedtime"] = 0;

      this.currentActivityData['pdfPreviousPage'] = 1;
      this.currentActivityData['pdfTotalPages'] = null;
    }

    // && String(this.currentActivityData["assetMode"]).toUpperCase() == "F" && this.currentActivityData.contentSource != 2
    if (this.currentActivityData["isDeleted"] == 0 && this.currentActivityData["s3Upload"] == 1) {
      this.getPreSignedUrlForContent(this.currentActivityData, (updatedMultiContentActivity, status) => {
        // if (status) {
        this.updateReference(updatedMultiContentActivity, flag);
        // } else {
        //     console.log('Unable to get presigned url..');
        // }
      });
    } else {
      this.updateReference(multiContentActivity, flag);
    }
    if (flag) {
      // this.multiLanguageService.updateActivityLanguage(
      //   this.currentActivityData
      // );
      this.eventsService.publish(
        "course:languageChange",
        this.currentActivityData
      );
    }
    console.log("currentActivity", this.currentActivityData);
  }

  updateReference(multiContentActivity, flag) {
    this.currentActivityData['reference'] = multiContentActivity.reference;

    // setTimeout(() => {
    //     this.setActivityData(multiContentActivity);
    // }, 300);

    setTimeout(() => {
      if (
        !(
          Number(this.currentActivityData.complitionlangId) ===
          Number(multiContentActivity["languageId"])
        )
      ) {
        this.currentActivityData["contentwatchedtime"] = 0;

        this.currentActivityData['pdfPreviousPage'] = 1;
        this.currentActivityData['pdfTotalPages'] = null;
      }
      this.showComponent = this.currentActivityData['activityTypeId'] !== 5 ? "course" : "quiz";
      this.showConatinerSpinner = false;
    }, 300);

    if (flag) {
      // this.multiLanguageService.updateActivityLanguage(
      //   this.currentActivityData
      // );
      this.eventsService.publish(
        "course:languageChange",
        this.currentActivityData
      );
    }

  }

  // Show Auto Begin Container Based on auto flag
  showBeginContainer = false;
  currentClickedActivityData = null;
  autoPlayFlag = false;

  // Autoplay changes
  updateLanguageDetails() {
    if (this.selectedLanguageData) {
      this.courseDetailSummary.lastLang = this.selectedLanguageData.languageId;
    }
    this.selectedLanguageData = undefined;
  }

  showBeginBox(data, moduleIndex, activityIndex) {

    this.resetPDFProgress();

    this.currentModuleIndex = moduleIndex;
    this.currentActIndex = activityIndex;
    this.currentClickedActivityData = data;
    this.showConatinerSpinner = true;

    this.showComponent = "";
    console.log("data", data);
    this.resetBeginContainerData();

    // Autoplay changes
    this.updateLanguageDetails();

    // this.setBeginContainerData(data);
    // this.showBeginContainer = true;
    this.getSelectedActData(data, (activity, error) => {
      if (error) {
        this.currentActivityData = null;
        setTimeout(() => {
          this.showConatinerSpinner = false;
          this.cdf.markForCheck();
        }, 300);
        this.setNoDataFoundContainer("noActivityFound", true);
      } else {
        this.setNoDataFoundContainer(null, false);
        // this.currentActivityData = activity;
        this.currentActivityData = this.prepareActivityData(activity, data);
        // this.currentActivityData = this.finddependetactivity(activity);
        const waitTrainerFlag = (this.currentUser.roleId == 8 && data.byTrainer == 1);
        // || (this.currentUser.roleId == 7 && data.byTrainer == 0 && data.activityTypeId == 9)
        if (data.wait || waitTrainerFlag || data.assallowweb == 0) {
          activity.assallowweb = data.assallowweb;
          activity.actvismsg = data.actvismsg;
          this.setBeginContainerData(activity, "activity");
          this.showBeginContainer = true;
          setTimeout(() => {
            this.showConatinerSpinner = false;
            this.cdf.markForCheck();
          }, 300);
        } else {
          if (this.currentActivityData["showBeginContainer"]) {
            this.showConatinerSpinner = false;
            this.setBeginContainerData(activity, "activity");
            if (this.currentActivityData['resumeFlag']) {
              this.setActivityDataAtStart();
            }
            this.showBeginContainer = true;
            setTimeout(() => {
              this.showConatinerSpinner = false;
              this.cdf.markForCheck();
            }, 300);
          } else {
            this.setActivityDataAtStart();
            this.showBeginContainer = false;
            setTimeout(() => {
              this.showConatinerSpinner = false;
              this.cdf.markForCheck();
            }, 300);
            // if (this.currentClickedActivityData.isAutoPlay) {
            this.startActivity();
            // } else {
            //   this.showConatinerSpinner = false;
            //   this.setBeginContainerData(activity, "activity");
            //   this.showBeginContainer = true;
            //   setTimeout(() => {
            //     this.showConatinerSpinner = false;
            //     this.cdf.markForCheck();
            //   }, 300);
            // }
          }
        }
      }
    });
  }

  // Begin Container Data
  beginContainer = {
    waitState: false,
    completedState: true,
    name: "",
    descrition: "",
    message: "",
    goToButton: false,
    beginButton: true,
    showLanguages: false,
    icon: "",
  };
  setBeginContainerData(data, type) {
    // this.showComponent = "";
    if (type === "activity") {
      this.beginContainer.name = this.currentActivityData.activityName;
      this.beginContainer.descrition = data.description;
      this.beginContainer.goToButton = data.wait;
      this.beginContainer.completedState = data.completed;
      const waitFlag = (this.currentUser.roleId == 8 && data.byTrainer == 1);
      // || (this.currentUser.roleId == 7 && data.byTrainer == 0 && data.activityTypeId == 9)
      this.beginContainer.waitState =
        data.wait || (data.begin && waitFlag && !data.completed);
      this.beginContainer.showLanguages = false;
      if (data.assallowweb == 0) {
        this.beginContainer.beginButton = false;
        this.beginContainer.icon = 'lock';
        this.beginContainer.waitState = true;
        this.beginContainer.message = data['actvismsg'];
      } else {
        if (data.begin && data.byTrainer != 1) {
          this.beginContainer.beginButton = true;
        } else if (data.completed) {
          this.beginContainer.beginButton = true;
        } else {
          this.beginContainer.beginButton = false;
        }
        let message = "";
        if (data["wait"]) {
          message =
            'Complete activity " ' +
            data.dependentActName +
            '" from module "' +
            data.dependentModName +
            '" to unlock';
          this.beginContainer.icon = "lock";
        }
        else if (waitFlag) {
          if (data.completed) {
            message = "Attendance is marked by trainer";
          } else {
            message = this.currentUser.roleId == 8 ? data.byTrainerMsg : "Attendance is marked by learner";
          }
          this.beginContainer.beginButton = false;
          this.beginContainer.icon = "lock";
        }
        else if (data.completed) {
          let dateCompleted;
          const monthShortNames = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ];
          const date = new Date(data.completedDate);
          if (data.completedDate) {
            this.beginContainer.icon = "complete";
            dateCompleted =
              date.getDate() +
              "-" +
              monthShortNames[date.getMonth()] +
              "- " +
              date.getFullYear();
          } else {
            dateCompleted = "NA";
          }
          if (data["activityTypeId"] == 6 || data["activityTypeId"] == 9) {
            this.beginContainer.beginButton = false;
          }
          message = "Completed on " + dateCompleted;
        } else {
          this.beginContainer.icon = data["actIcon"];
        }
        this.beginContainer.message = message;

      }

      if (data.activityTypeId == 5) {
        if (data.resumeFlag == 1) {
          this.beginContainer.showLanguages = false;
          this.beginContainer.beginButton = true;
        } else {
          this.beginContainer.showLanguages = true;
          this.beginContainer.beginButton = false;
        }
      }
      else if (!this.beginContainer.waitState && data.multiLanguageList.length > 0) {
        this.beginContainer.showLanguages = true;
        this.beginContainer.beginButton = false;
      } else if (!this.beginContainer.waitState) {
        this.beginContainer.showLanguages = false;
        this.beginContainer.beginButton = true;
      }

    } else if (type === "module") {
      if (data.isDisable == "Y") {
        let date = "";
        if (data.mStartDate) {
          date = data.mStartDate;
        } else {
          date = "NA";
        }
        this.beginContainer.name = data.moduleName;
        this.beginContainer.descrition = "";
        this.beginContainer.goToButton = false;
        this.beginContainer.completedState = false;
        this.beginContainer.waitState = true;
        this.beginContainer.icon = "lock";
        this.beginContainer.message =
          data.moduleName + " will start from " + date;
        this.beginContainer.icon = "lock";
      } else {
        this.beginContainer.waitState = false;
      }
    } else if (type === "quiz") {
      this.beginContainer.name = data.moduleName;
      this.beginContainer.descrition = "";
      this.beginContainer.goToButton = false;
      this.beginContainer.completedState = false;
      this.beginContainer.waitState = true;
      this.beginContainer.icon = this.currentActivityData["actIcon"];
      this.beginContainer.message = data;
      // if(data.)

    } else if (type === "feedback") {
      this.beginContainer.name = this.currentActivityData.activityName;
      this.beginContainer.descrition = "";
      this.beginContainer.goToButton = false;
      this.beginContainer.completedState = false;
      this.beginContainer.waitState = true;
      this.beginContainer.icon = this.currentActivityData["actIcon"];
      this.beginContainer.message = data;
    } else if (type === "noModuleActivityFound") {
      this.beginContainer.name = "No Module/Sessions or Activity to display";
      this.beginContainer.descrition = "";
      this.beginContainer.goToButton = false;
      this.beginContainer.completedState = false;
      this.beginContainer.waitState = true;
      this.beginContainer.icon = "warning";
      this.beginContainer.message = "";
    } else {
      this.beginContainer.showLanguages = true;
    }

    return this.beginContainer.waitState;
  }

  resetBeginContainerData() {
    this.showBeginContainer = false;
    this.beginContainer = {
      waitState: false,
      completedState: true,
      name: "",
      descrition: "",
      message: "",
      goToButton: false,
      beginButton: true,
      showLanguages: false,
      icon: "",
    };
    this.showAutoPlayConatiner = false;
  }

  // Set No Data found container
  setNoDataFoundContainer(message, displayFlag) {
    if (displayFlag) {
      this.noDataFound.showImage = true;
      this.noDataFound.description =
        "Something went wrong please try again later";
      this.showComponent = "noDataFound";
    } else {
      this.noDataFound.showImage = false;
      this.noDataFound.description = "";
      this.showComponent = "";
    }
  }

  // Set Activity Data for container
  startActivity() {
    if (this.disabledGoToCourse) {
      return null;
    }
    this.showComponent = "";
    // this.spinner.show();
    setTimeout(() => {
      this.showConatinerSpinner = true;
      this.cdf.markForCheck();
    }, 200);
    this.showArrow = true;
    this.setNoDataFoundContainer(null, false);
    this.resetBeginContainerData();
    if (this.currentActivityData.activity_type === "Quiz") {
      let param = {
        qId: this.currentActivityData.quizId,
        tId: this.currentUser.tenantId,
        uId: this.currentUser.eid,
        cId: this.currentActivityData.courseId,
        mId: this.currentActivityData.moduleId,
        aId: this.currentActivityData.activityId, //  || this.currentActivityData.instanceId
        enId: this.currentActivityData.enrolId,
      };
      this.activityService
        .quiz(param)
        .then((result) => {
          var temp: any = result;
          if (temp.data !== null || temp.data !== undefined) {
            if (temp.data.length > 0) {
              console.log("CHECk QUIZ FOR USER---->", temp.data[0]);
              this.checkResponse = temp.data[0];
              if (temp.data[0].flag == "true") {
                console.log("PassCheck===>", temp.data[0]);
                if (temp.data[0].qPassword == 0) {
                  if (this.checkResponse.instructions) {
                    this.disabledGoToCourse = false;

                    setTimeout(() => {
                      this.showConatinerSpinner = false;
                      this.cdf.markForCheck();
                    }, 200);

                    this.openPop("quizIntro");
                  } else {
                    this.disabledGoToCourse = false;
                    setTimeout(() => {
                      this.showConatinerSpinner = false;
                      this.cdf.markForCheck();
                    }, 200);
                    this.showComponent = "quiz";
                    this.showArrow = false;
                  }
                } else {
                  this.disabledGoToCourse = false;
                  setTimeout(() => {
                    this.showConatinerSpinner = false;
                    this.cdf.markForCheck();
                  }, 200);
                  this.openPop("quizPass");
                  this.passKey = temp.data[0].passWord;
                }
              } else {
                this.disabledGoToCourse = false;
                setTimeout(() => {
                  this.showConatinerSpinner = false;
                  this.cdf.markForCheck();
                }, 200);

                // Quiz Next Attempt warning
                const message =
                  temp.data[0].msg + " " + temp.data[0].nextAttemptDate;
                // this.setNoDataFoundContainer(obj, false);
                // this.toastr
                //   .warning(message, "Warning!")
                //   .onHidden.subscribe(() => this.toastr.clear());
                this.presentAlert(message, "Warning!", "warning");
                this.showBeginContainer = this.setBeginContainerData(
                  message,
                  "quiz"
                );
              }
            } else {
              this.disabledGoToCourse = false;
              setTimeout(() => {
                this.showConatinerSpinner = false;
                this.cdf.markForCheck();
              }, 200);
              this.setNoDataFoundContainer("quizError", true);
              // this.toastr
              //   .warning(
              //     "Something went wrong please try again later",
              //     "Error!"
              //   )
              //   .onHidden.subscribe(() => this.toastr.clear());
              this.presentAlert(
                "Something went wrong please try again later",
                "Error!",
                "warning"
              );
            }
          } else {
            // this.spinner.hide();
            this.disabledGoToCourse = false;
            setTimeout(() => {
              this.showConatinerSpinner = false;
              this.cdf.markForCheck();
            }, 200);

            this.setNoDataFoundContainer("quizError", true);
            // this.toastr
            //   .warning("Something went wrong please try again later", "Error!")
            //   .onHidden.subscribe(() => this.toastr.clear());
            this.presentAlert(
              "Something went wrong please try again later",
              "Error!",
              "warning"
            );
          }
        })
        .catch((result) => {
          // this.spinner.hide();
          setTimeout(() => {
            this.showConatinerSpinner = false;
            this.cdf.markForCheck();
          }, 200);

          this.disabledGoToCourse = false;

          this.setNoDataFoundContainer("quizError", true);
          console.log("ServerResponseError :", result);
          // this.toastr
          //   .warning("Something went wrong please try again later", "Error!")
          //   .onHidden.subscribe(() => this.toastr.clear());
          this.presentAlert(
            "Something went wrong please try again later",
            "Error!",
            "warning"
          );
          // this.spinner.hide();
        });
    } else if (this.currentActivityData.activity_type === "Feedback") {
      this.disabledGoToCourse = false;
      let params = {
        fId: this.currentActivityData.feedbackId,
        tId: this.currentUser.tenantId,
        eId: this.currentUser.eid,
        cId: this.currentActivityData.courseId,
        mId: this.currentActivityData.moduleId,
        aId: this.currentActivityData.activityId,
        enId: this.currentActivityData.enrolId,
      };
      // const url = ENUM.domain + ENUM.url.checkFeedbackUser;
      this.activityService
        .feedback(params)
        .then((result) => {
          var temp: any = result;
          if (temp.data !== null || temp.data !== undefined) {
            if (temp.data.length > 0) {
              console.log("CHECk FEEDBACK FOR USER---->", temp.data[0]);
              if (temp.data[0].flag == "true") {
                this.disabledGoToCourse = false;
                setTimeout(() => {
                  this.showConatinerSpinner = false;
                  this.showComponent = "feedback";
                  this.cdf.markForCheck();
                }, 200);
              } else {
                this.disabledGoToCourse = false;
                setTimeout(() => {
                  this.showConatinerSpinner = false;
                  this.cdf.markForCheck();
                }, 200);

                this.showBeginContainer = this.setBeginContainerData(
                  temp.data[0].msg,
                  "feedback"
                );
                // this.spinner.hide();
                // this.toastr
                //   .warning(temp.data[0].msg, "Warning!")
                //   .onHidden.subscribe(() => this.toastr.clear());
                this.presentAlert(
                  "Something went wrong please try again later",
                  "Error!",
                  "warning"
                );
              }
            } else {
              this.setNoDataFoundContainer("feedbackError", true);
              this.disabledGoToCourse = false;
              setTimeout(() => {
                this.showConatinerSpinner = false;
                this.cdf.markForCheck();
              }, 200);
              // this.toastr
              //   .warning(
              //     "Something went wrong please try again later",
              //     "Error!"
              //   )
              //   .onHidden.subscribe(() => this.toastr.clear());
              this.presentAlert(
                "Something went wrong please try again later",
                "Error!",
                "warning"
              );
            }
          } else {
            this.setNoDataFoundContainer("feedbackError", true);
            this.disabledGoToCourse = false;
            setTimeout(() => {
              this.showConatinerSpinner = false;
              this.cdf.markForCheck();
            }, 200);

            // this.toastr
            //   .warning("Something went wrong please try again later", "Error!")
            //   .onHidden.subscribe(() => this.toastr.clear());
            this.presentAlert(
              "Something went wrong please try again later",
              "Error!",
              "warning"
            );
          }
        })
        .catch((result) => {
          this.setNoDataFoundContainer("feedbackError", true);
          this.disabledGoToCourse = false;
          setTimeout(() => {
            this.showConatinerSpinner = false;
            this.cdf.markForCheck();
          }, 200);

          // this.spinner.hide();
          console.log("ServerResponseError :", result);
          // this.toastr
          //   .warning("Something went wrong please try again later", "Error!")
          //   .onHidden.subscribe(() => this.toastr.clear());
          this.presentAlert(
            "Something went wrong please try again later",
            "Error!",
            "warning"
          );
        });
    } else if (this.currentActivityData.activity_type === "Viva") {

      this.disabledGoToCourse = false;
      setTimeout(() => {
        this.showConatinerSpinner = false;
        this.cdf.markForCheck();
      }, 200);
      this.showComponent = "feedback";

      // this.disabledGoToCourse = false;
      // let params = {
      //   fId: this.currentActivityData.feedbackId,
      //   tId: this.currentUser.tenantId,
      //   eId: this.currentUser.eid,
      //   cId: this.currentActivityData.courseId,
      //   mId: this.currentActivityData.moduleId,
      //   aId: this.currentActivityData.activityId,
      //   enId: this.currentActivityData.enrolId,
      // };
      // // const url = ENUM.domain + ENUM.url.checkFeedbackUser;
      // this.activityService
      //   .feedback(params)
      //   .then((result) => {
      //     var temp: any = result;
      //     if (temp.data !== null || temp.data !== undefined) {
      //       if (temp.data.length > 0) {
      //         console.log("CHECk FEEDBACK FOR USER---->", temp.data[0]);
      //         if (temp.data[0].flag == "true") {
      //           this.disabledGoToCourse = false;
      //           setTimeout(() => {
      //             this.showConatinerSpinner = false;
      //             this.showComponent = "feedback";
      //             this.cdf.markForCheck();
      //           }, 200);
      //         } else {
      //           this.disabledGoToCourse = false;
      //           setTimeout(() => {
      //             this.showConatinerSpinner = false;
      //             this.cdf.markForCheck();
      //           }, 200);

      //           this.showBeginContainer = this.setBeginContainerData(
      //             temp.data[0].msg,
      //             "feedback"
      //           );
      //           // this.spinner.hide();
      //           // this.toastr
      //           //   .warning(temp.data[0].msg, "Warning!")
      //           //   .onHidden.subscribe(() => this.toastr.clear());
      //           this.presentAlert(
      //             "Something went wrong please try again later",
      //             "Error!",
      //             "warning"
      //           );
      //         }
      //       } else {
      //         this.setNoDataFoundContainer("feedbackError", true);
      //         this.disabledGoToCourse = false;
      //         setTimeout(() => {
      //           this.showConatinerSpinner = false;
      //           this.cdf.markForCheck();
      //         }, 200);
      //         // this.toastr
      //         //   .warning(
      //         //     "Something went wrong please try again later",
      //         //     "Error!"
      //         //   )
      //         //   .onHidden.subscribe(() => this.toastr.clear());
      //         this.presentAlert(
      //           "Something went wrong please try again later",
      //           "Error!",
      //           "warning"
      //         );
      //       }
      //     } else {
      //       this.setNoDataFoundContainer("feedbackError", true);
      //       this.disabledGoToCourse = false;
      //       setTimeout(() => {
      //         this.showConatinerSpinner = false;
      //         this.cdf.markForCheck();
      //       }, 200);

      //       // this.toastr
      //       //   .warning("Something went wrong please try again later", "Error!")
      //       //   .onHidden.subscribe(() => this.toastr.clear());
      //       this.presentAlert(
      //         "Something went wrong please try again later",
      //         "Error!",
      //         "warning"
      //       );
      //     }
      //   })
      //   .catch((result) => {
      //     this.setNoDataFoundContainer("feedbackError", true);
      //     this.disabledGoToCourse = false;
      //     setTimeout(() => {
      //       this.showConatinerSpinner = false;
      //       this.cdf.markForCheck();
      //     }, 200);

      //     // this.spinner.hide();
      //     console.log("ServerResponseError :", result);
      //     // this.toastr
      //     //   .warning("Something went wrong please try again later", "Error!")
      //     //   .onHidden.subscribe(() => this.toastr.clear());
      //     this.presentAlert(
      //       "Something went wrong please try again later",
      //       "Error!",
      //       "warning"
      //     );
      //   });
    } else if (this.currentActivityData.activity_type === "Attendance") {
      this.disabledGoToCourse = false;
      setTimeout(() => {
        this.showConatinerSpinner = false;
        this.cdf.markForCheck();
      }, 200);
      this.showComponent = "mark-attendance";
    } else if (this.currentActivityData.activity_type === "Evaluation Feedback") {
      this.disabledGoToCourse = false;
      setTimeout(() => {
        this.showConatinerSpinner = false;
        this.cdf.markForCheck();
      }, 200);
      this.showComponent = "trainerEvaluationFeedback";
    } else if (this.currentActivityData.activity_type === "Webinar") {
      this.disabledGoToCourse = false;
      setTimeout(() => {
        this.showConatinerSpinner = false;
        this.cdf.markForCheck();
      }, 200);
      this.showComponent = "course";
    } else if (this.currentActivityData.activity_type === "Assignment") {
      this.disabledGoToCourse = false;
      setTimeout(() => {
        this.showConatinerSpinner = false;
        this.cdf.markForCheck();
      }, 200);
      this.showComponent = "assignment";
    } else {
      this.disabledGoToCourse = false;
      if (this.currentActivityData.areaId == 34) {
        this.showComponent = "course";
      }
      if (
        this.currentActivityData &&
        this.currentActivityData["reference"] &&
        this.currentActivityData["reference"] !== ""
      ) {
        // this.router.navigate(['course'], { relativeTo: this.routes });
        setTimeout(() => {
          this.showConatinerSpinner = false;
          this.cdf.markForCheck();
        }, 200);
        // this.showComponent = "course";
      } else if (
        this.currentActivityData &&
        this.currentActivityData["referanceKey"] &&
        this.currentActivityData["contentSource"] == 2
      ) {
        setTimeout(() => {
          this.showConatinerSpinner = false;
          this.cdf.markForCheck();
        }, 200);
      } else {
        this.setNoDataFoundContainer("activityDataError", true);
        setTimeout(() => {
          this.showConatinerSpinner = false;
          this.cdf.markForCheck();
        }, 200);
        // this.toastr
        //   .warning("No Data Available in this activity", "Warning!")
        //   .onHidden.subscribe(() => this.toastr.clear());
        this.presentAlert(
          "Something went wrong please try again later",
          "Error!",
          "warning"
        );
      }
    }
  }

  // //icon new change
  // bindActivityContainerIcon(detail) {
  //   // if (detail.activity_type === 'Quiz') {

  //    if (detail && detail.formatId) {
  //     switch (Number(detail.formatId)) {
  //       case 1: // video
  //         return "video";
  //       case 2: // Audio
  //         return "audio";
  //       case 3: // PDF
  //         return "pdf";
  //       case 4: // K-point
  //         return "kpoint";
  //       case 5: // Scrom
  //         return "scrom";
  //       case 6: // Youtube
  //         return "youtube";
  //       case 7: // Image
  //         return "image";
  //       case 8: // External link
  //         return "url";
  //       case 9: // Practice file
  //         return "practice_file";
  //       case 10: // PPT
  //         return "ppt";
  //       case 11: // Excel
  //         return "excel";
  //       case 12: // Word
  //         return "word";
  //       default:
  //         return "";
  //     }
  //   } else {
  //     return "";
  //   }

  // }
  // // end new change

  // Activity Icons Binding
  bindActivityIcon(detail) {
    // if (detail.activity_type === 'Quiz') {
    if (detail.activityTypeId == 5) {
      return "quiz";
      // } else if ( detail.activity_type  === 'Feedback') {
    } else if (detail.activityTypeId == 6 || detail.activityTypeId == 16 || detail.activityTypeId == 12) {
      // return 'Feedback', 'Viva Feedback', 'Evaluation feedback';
      return "feedback";
    } else if (detail.activityTypeId == 9) {
      return "attendance";
    } else if (detail.activityTypeId == 11) {
      return "webinar";
      // } else if ( detail.activity_type === 'Attendance') {
    } else if (detail.activityTypeId == 17) {
      return "assignment";
      // } else if ( detail.activity_type === 'Attendance') {
    } else if (detail.activityTypeId == 1 || detail.activityTypeId == 2 || detail.areaId == 34) {
      switch (Number(detail.formatId)) {
        case 1: // video
          return "video";
        case 2: // Audio
          return "audio";
        case 3: // PDF
          return "pdf";
        case 4: // K-point
          return "kpoint";
        case 5: // Scrom
          return "scrom";
        case 6: // Youtube
          return "youtube";
        case 7: // Image
          return "image";
        case 8: // External link
          return "url";
        case 9: // Practice file
          return "practice_file";
        case 10: // PPT
          return "ppt";
        case 11: // Excel
          return "excel";
        case 12: // Word
          return "word";
        case 15: // h5p
          return "h5p";
        case 16: // article
          return 'article';
        default:
          return "";
      }
    } else {
      return "";
    }
  }

  // Activity Format Name Binding
  bindActivityFormatName(detail) {
    // if (detail.activity_type === 'Quiz') {
    if (detail.activityTypeId == 5) {
      return "Quiz";
      // } else if ( detail.activity_type  === 'Feedback') {
    } else if (detail.activityTypeId == 6 || detail.activityTypeId == 16 || detail.activityTypeId == 12) {
      // return 'Feedback', 'Viva Feedback', 'Evaluation feedback';
      return "Feedback";
    } else if (detail.activityTypeId == 11) {
      return "Webinar";
      // } else if ( detail.activity_type === 'Attendance') {
    } else if (detail.activityTypeId == 17) {
      return "Assignment";
      // } else if ( detail.activity_type === 'Attendance') {
    } else if (detail.activityTypeId == 9) {
      return "Attendance";
    } else if (detail.activityTypeId == 1 || detail.activityTypeId == 2) {
      switch (Number(detail.formatId)) {
        case 1: // video
          return "Video";
        case 2: // Audio
          return "Audio";
        case 3: // PDF
          return "Pdf";
        case 4: // K-point
          return "Kpoint";
        case 5: // Scrom
          return "Scrom";
        case 6: // Youtube
          return "Youtube";
        case 7: // Image
          return "Image";
        case 8: // External link
          return "URL";
        case 9: // Practice file
          return "Practice File";
        case 10: // PPT
          return "PPT";
        case 11: // Excel
          return "Excel";
        case 12: // Word
          return "Word";
        case 15: // h5p
          return "H5P";
        case 16: // article
          return 'article';
        default:
          return "";
      }
    } else {
      return "";
    }
  }
  // Show Begin Container
  bindBeginContainer(detail) {
    let multiLangLen = detail.multiLanguageList.length;
    let langId = this.courseDetailSummary.lastLang || this.courseDetailSummary.firstLang;
    //  && this.currentClickedActivityData.isAutoPlay
    if (this.autoPlayFlag && multiLangLen > 0 && detail.activityTypeId != 5 && langId) {
      return false;
    } else {
      if (detail.activityTypeId == 5) {
        // if(detail['resumeFlag'] == 1){
        //   return false;
        // }else {
        //   return true;
        // }
        return true;
        // return 'quiz';
      } else if (detail.activityTypeId == 6) {
        // return 'feedback';
        return true;
      } else if (detail.activityTypeId == 16) {
        // return 'viva feedback';
        return false;
      } else if (detail.activityTypeId == 12) {
        // return 'Evaluation feedback';
        return false;
      } else if (detail.activityTypeId == 11) {
        // return 'webinar';
        return false;
      } else if (detail.activityTypeId == 9) {
        // return 'attendance';
        return false;
      } else if (detail.activityTypeId == 17) {
        // return 'Assignment';
        return false;
        // } else if ( detail.activity_type === 'Attendance') {
      } else if (detail.activityTypeId == 1 || detail.activityTypeId == 2) {
        switch (Number(detail.formatId)) {
          case 1: // video
            return true;
          case 2: // Audio
            return true;
          case 3: // PDF
            return true;
          case 4: // K-point
            return true;
          case 5: // Scrom
            return true;
          case 6: // Youtube
            return true;
          case 7: // Image
            return true;
          case 8: // External link
            return true;
          case 9: // Practice file
            return true;
          case 10: // PPT
            return true;
          case 11: // Excel
            return true;
          case 12: // Word
            return true;
          case 15: // h5p
            return true;
        }
        return true;
        // return 'video';
      } else {
        return false;
      }
    }
  }

  getActivityModuleData(tempCurrentModIndex, tempCurrentActIndex) {
    // if (
    //   String(
    //     this.moduleList[tempCurrentModIndex].isDisable
    //   ).toLowerCase() === "y"
    // ) {
    //   tempCurrentModIndex = tempCurrentModIndex + 1;
    //   tempCurrentActIndex = 0;
    // } else
    // if (
    //   this.moduleList[tempCurrentModIndex].list.length - 1 ===
    //   tempCurrentActIndex
    // ) {
    //   tempCurrentModIndex = tempCurrentModIndex+ 1;
    //   tempCurrentActIndex = 0;
    // } else {
    //   tempCurrentActIndex = tempCurrentActIndex + 1;
    // }

    const nextActivity = this.getNextActivityData(
      tempCurrentModIndex,
      tempCurrentActIndex
    );
    if (nextActivity) {
      // this.resetDependencyConatiner();
      this.showBeginBox(nextActivity, tempCurrentModIndex, tempCurrentActIndex);
    } else {
      // console.log('No activity available ...');
      this.setBeginContainerData(null, "noModuleActivityFound");
    }
  }

  // Skip To next activity
  skipToNextActivity() {
    this.resetBeginContainerData();
    let tempCurrentModIndex = this.currentModuleIndex;
    let tempCurrentActIndex = this.currentActIndex;
    let nextModuleFound = false;
    if (this.moduleList.length > 0) {
      for (
        let index = tempCurrentModIndex;
        index < this.moduleList.length;
        index++
      ) {
        if (String(this.moduleList[index].isDisable).toLowerCase() === "y") {
          // tempCurrentModIndex = index;
        } else {
          tempCurrentModIndex = index;
          if (tempCurrentModIndex !== this.currentModuleIndex) {
            tempCurrentActIndex = 0;
          } else {
            if (
              this.moduleList[tempCurrentModIndex].list.length - 1 ===
              tempCurrentActIndex
            ) {
              tempCurrentModIndex = tempCurrentModIndex + 1;
              tempCurrentActIndex = 0;
            } else {
              tempCurrentActIndex = tempCurrentActIndex + 1;
            }
          }
          nextModuleFound = true;
          break;
        }
      }
      if (nextModuleFound) {
        if (this.currentModuleIndex != tempCurrentModIndex) {
          this.showdiv(tempCurrentModIndex);
        }
        this.getActivityModuleData(tempCurrentModIndex, tempCurrentActIndex);
      } else {
        if (this.currentModuleIndex + 1 < this.moduleList.length - 1) {
          this.currentModuleIndex = this.currentModuleIndex + 1;
          this.currentActIndex = 0;
          this.setBeginContainerData(
            this.moduleList[this.currentModuleIndex],
            "module"
          );
        } else {
          this.setBeginContainerData(null, "noModuleActivityFound");
        }
        this.showBeginContainer = true;
      }
    } else {
      // console.log('No module available ...');

      this.setBeginContainerData(null, "noModuleActivityFound");
      this.showBeginContainer = true;
    }
  }

  skipToNextActivityFirstTime() {
    this.resetBeginContainerData();
    let tempCurrentModIndex = this.currentModuleIndex;
    let tempCurrentActIndex = this.currentActIndex;
    let nextModuleFound = false;
    let moduleIndexChangeFlag = false;
    let noModuleActivityFoundDisplay = true;
    if (this.moduleList.length > 0) {
      for (
        let index = tempCurrentModIndex;
        index < this.moduleList.length;
        index++
      ) {
        let breakFlag = false;
        if (this.moduleList[index].isDisable === "N") {
          nextModuleFound = true;
          for (
            let indexj = 0;
            indexj < this.moduleList[index].list.length;
            indexj++
          ) {
            if (
              this.moduleList[index].list[indexj].activityId ===
              this.currentActivityData.activityId
            ) {
              tempCurrentActIndex = indexj;
              tempCurrentModIndex = index;
            }
            if (tempCurrentModIndex < index) {
              tempCurrentActIndex = 0;
              if (
                tempCurrentActIndex <= indexj &&
                !this.moduleList[index].list[indexj]["completed"]
              ) {
                if (tempCurrentModIndex !== index) {
                  moduleIndexChangeFlag = true;
                }
                noModuleActivityFoundDisplay = false;
                tempCurrentActIndex = indexj;
                tempCurrentModIndex = index;
                // this.currentActivityData = this.moduleList[index].list[indexj];
                breakFlag = true;
                break;
              }
            } else if (tempCurrentModIndex === index) {
              if (
                tempCurrentActIndex < indexj &&
                !this.moduleList[index].list[indexj]["completed"]
              ) {
                if (tempCurrentModIndex !== index) {
                  moduleIndexChangeFlag = true;
                }
                noModuleActivityFoundDisplay = false;
                tempCurrentActIndex = indexj;
                tempCurrentModIndex = index;
                // this.currentActivityData = this.moduleList[index].list[indexj];
                breakFlag = true;
                break;
              }
            }
          }
          // this.moduleList[index].list[indexj] = this.finddependetactivity(this.moduleList[index].list[indexj]);
        }
        if (breakFlag || index === this.moduleList.length) {
          break;
        }
      }
      // if (nextModuleFound) {
      //   this.getActivityModuleData(tempCurrentModIndex, tempCurrentActIndex);
      // } else {
      //   if (this.currentModuleIndex + 1 < this.moduleList.length - 1) {
      //     this.currentModuleIndex = this.currentModuleIndex + 1;
      //     this.currentActIndex = 0;
      //     this.setBeginContainerData(
      //       this.moduleList[this.currentModuleIndex],
      //       "module"
      //     );
      //   } else {
      //     this.setBeginContainerData(null, "noModuleActivityFound");
      //   }
      if (noModuleActivityFoundDisplay) {
        if (!nextModuleFound) {
          if (this.currentModuleIndex + 1 < this.moduleList.length - 1) {
            this.currentModuleIndex = this.currentModuleIndex + 1;
            this.currentActIndex = 0;
            this.setBeginContainerData(
              this.moduleList[this.currentModuleIndex],
              "module"
            );
          } else {
            this.setBeginContainerData(null, "noModuleActivityFound");
            this.showBeginContainer = true;
          }
        } else {
          if (moduleIndexChangeFlag) {
            this.showdiv(tempCurrentModIndex);
          }
          this.getActivityModuleData(tempCurrentModIndex, tempCurrentActIndex);
        }
      } else {
        if (moduleIndexChangeFlag) {
          this.showdiv(tempCurrentModIndex);
        }
        this.getActivityModuleData(tempCurrentModIndex, tempCurrentActIndex);
      }
      this.showBeginContainer = true;
    } else {
      // console.log('No module available ...');
      this.showBeginContainer = true;
      this.setBeginContainerData(null, "noModuleActivityFound");
    }
  }

  // Skip To next activity auto
  skipToNextActivityAuto() {
    let noModuleActivityFoundDisplay = true;
    let moduleIndexChangeFlag = false;

    // if (
    //   this.currentModuleIndex === this.moduleList.length - 1 &&
    //   this.moduleList[this.currentModuleIndex].list.length - 1 ===
    //   this.currentActIndex
    // ) {
    //   // this.lastAcitivityReachFlag = true;

    //   // if (this.moduleList[this.currentModuleIndex].isDisable !== "Y") {
    //   //   if (
    //   //     !(this.moduleList[this.currentModuleIndex].list[this.currentActIndex]["wait"] ||
    //   //       this.moduleList[this.currentModuleIndex].list[this.currentActIndex].byTrainer === 1)
    //   //   ) {
    //   //     if (this.currentActivityData) {
    //   //       noModuleActivityFoundDisplay = false;
    //   //     } else {
    //   //       return null;
    //   //     }
    //   //   }
    //   // } else {
    //   //   // this.currentActivityData = null;
    //   //   return null;
    //   // }

    //   return null;
    // }

    // this.showComponent = "";
    // this.setNoDataFoundContainer(null, false);
    // this.resetBeginContainerData();
    // let noModuleActivityFoundDisplay = true;
    // let moduleIndexChangeFlag = false;

    for (let index = 0; index < this.moduleList.length; index++) {
      let breakFlag = false;
      if (this.moduleList[index].isDisable === "N") {
        for (
          let indexj = 0;
          indexj < this.moduleList[index].list.length;
          indexj++
        ) {
          if (
            this.moduleList[index].list[indexj].activityId ===
            this.currentActivityData.activityId
          ) {
            this.currentActIndex = indexj;
            this.currentModuleIndex = index;
          }
          if (this.currentModuleIndex < index) {
            this.currentActIndex = 0;
            if (
              this.currentActIndex <= indexj &&
              !(
                // this.moduleList[index].list[indexj]["assallowweb"] == 0 ||
                this.moduleList[index].list[indexj]["wait"] ||
                this.moduleList[index].list[indexj].byTrainer === 1 ||
                this.moduleList[index].list[indexj]["completed"]
              )
            ) {
              if (this.currentModuleIndex !== index) {
                moduleIndexChangeFlag = true;
              }
              noModuleActivityFoundDisplay = false;
              this.currentActIndex = indexj;
              this.currentModuleIndex = index;

              // this.currentActIndex = 0;
              this.currentActivityData = this.moduleList[index].list[indexj];
              breakFlag = true;
              break;
            } else {
              console.log('skipToNextActivityAuto...');
              if (this.currentActivityData) {
                noModuleActivityFoundDisplay = false;
              }
            }
          } else if (this.currentModuleIndex === index) {
            if (
              this.currentActIndex < indexj &&
              !(
                // this.moduleList[index].list[indexj]["assallowweb"] == 0 ||
                this.moduleList[index].list[indexj]["wait"] ||
                this.moduleList[index].list[indexj].byTrainer === 1 ||
                this.moduleList[index].list[indexj]["completed"]
              )
            ) {
              if (this.currentModuleIndex !== index) {
                moduleIndexChangeFlag = true;
              }
              noModuleActivityFoundDisplay = false;
              this.currentActIndex = indexj;
              this.currentModuleIndex = index;

              // this.currentActIndex = 0;
              this.currentActivityData = this.moduleList[index].list[indexj];

              breakFlag = true;
              break;
            }
          }
        }
        // this.moduleList[index].list[indexj] = this.finddependetactivity(this.moduleList[index].list[indexj]);
      }
      if (breakFlag || index === this.moduleList.length) {
        break;
      }
    }
    //  && !this.currentActivityData
    if (noModuleActivityFoundDisplay) {
      // this.noDataFound.title = "No Module Activity to display";
      // this.noDataFound.description = "";
      // this.noDataFound.showImage = false;
      // this.showComponent = "noDataFound";
      this.setBeginContainerData(null, "noModuleActivityFound");
      this.showBeginContainer = true;
      // this.currentModuleIndex = this.moduleList.length - 1;
      // this.currentActIndex =
      //   this.moduleList[this.currentModuleIndex].list.length - 1;
      // this.currentActivityData = this.moduleList[this.currentModuleIndex].list[
      //   this.currentActIndex
      // ];
      // if (this.moduleList[this.currentModuleIndex].isDisable == "Y") {
      //   let date = "";
      //   if (this.moduleList[this.currentModuleIndex].mStartDate) {
      //     date = this.moduleList[this.currentModuleIndex].mStartDate;
      //   } else {
      //     date = "NA";
      //   }
      //   this.noDataFound.title =
      //     this.moduleList[this.currentModuleIndex].moduleName +
      //     " will start from " +
      //     date;
      //   this.noDataFound.description = "";
      //   this.noDataFound.showImage = false;
      //   this.showComponent = "noDataFound";
      //   return null;
      // }
      // this.showBeginBox(
      //   this.currentActivityData,
      //   this.currentModuleIndex,
      //   this.currentActIndex,
      // );
      // this.showdiv(this.currentModuleIndex);
      // this.moduleList[this.currentModuleIndex].show = true;
    } else {
      if (moduleIndexChangeFlag) {
        this.showdiv(this.currentModuleIndex);
      }
      // this.goToCourse(this.currentActivityData, this.currentModuleIndex, this.currentActIndex);
      this.showBeginBox(
        this.currentActivityData,
        this.currentModuleIndex,
        this.currentActIndex
      );
    }
  }

  // Skip To previous activity
  skipToPreviousActivity() {
    this.resetBeginContainerData();
    let tempCurrentModIndex = this.currentModuleIndex;
    let tempCurrentActIndex = this.currentActIndex;
    let nextModuleFound = false;

    if (this.moduleList.length > 0) {
      for (let index = tempCurrentModIndex; index >= 0; index--) {
        if (String(this.moduleList[index].isDisable).toLowerCase() === "y") {
          // tempCurrentModIndex = index;
        } else {
          tempCurrentModIndex = index;
          if (tempCurrentModIndex !== this.currentModuleIndex) {
            tempCurrentActIndex =
              this.moduleList[tempCurrentModIndex].list.length - 1;
          } else {
            if (tempCurrentActIndex === 0) {
              tempCurrentModIndex = tempCurrentModIndex - 1;
              tempCurrentActIndex =
                this.moduleList[tempCurrentModIndex].list.length - 1;
            } else {
              tempCurrentActIndex = tempCurrentActIndex - 1;
            }
          }
          nextModuleFound = true;
          break;
        }
      }
      if (nextModuleFound) {
        if (this.currentModuleIndex != tempCurrentModIndex) {
          this.showdiv(tempCurrentModIndex);
        }
        this.getActivityModuleData(tempCurrentModIndex, tempCurrentActIndex);
      } else {
        if (this.currentModuleIndex - 1 > 0) {
          this.currentModuleIndex = this.currentModuleIndex - 1;
          this.currentActIndex =
            this.moduleList[this.currentModuleIndex].list.length - 1;
          this.setBeginContainerData(
            this.moduleList[this.currentModuleIndex],
            "module"
          );
        } else {
          this.setBeginContainerData(null, "noModuleActivityFound");
        }
        this.showBeginContainer = true;
      }
    } else {
      // console.log('No module available ...');
      this.setBeginContainerData(null, "noModuleActivityFound");
      this.showBeginContainer = true;
    }
  }

  // Skip To previous activity auto
  skipToPreviousActivityAuto() {
    const moduleLength = this.moduleList.length - 1;
    let noModuleActivityFoundDisplay = true;
    let moduleIndexChangeFlag = false;
    for (let index = moduleLength; index >= 0; index--) {
      let breakFlag = false;
      if (this.moduleList[index].isDisable === "N") {
        for (
          let indexj = this.moduleList[index].list.length - 1;
          indexj >= 0;
          indexj--
        ) {
          if (this.currentModuleIndex > index) {
            noModuleActivityFoundDisplay = false;
            this.currentActIndex = this.moduleList[index].list.length - 1;
            if (
              this.currentActIndex >= indexj &&
              !(
                // this.moduleList[index].list[indexj]["assallowweb"] == 0 ||
                this.moduleList[index].list[indexj]["wait"] ||
                this.moduleList[index].list[indexj].byTrainer === 1 ||
                this.moduleList[index].list[indexj]["completed"]
              )
            ) {
              if (this.currentModuleIndex !== index) {
                moduleIndexChangeFlag = true;
              }
              this.currentActIndex = indexj;
              this.currentModuleIndex = index;

              // this.currentActIndex = 0;
              this.currentActivityData = this.moduleList[index].list[indexj];
              breakFlag = true;
              break;
            }
          } else if (this.currentModuleIndex === index) {
            if (
              this.currentActIndex > indexj &&
              !(
                // this.moduleList[index].list[indexj]["assallowweb"] == 0 ||
                this.moduleList[index].list[indexj]["wait"] ||
                this.moduleList[index].list[indexj].byTrainer === 1 ||
                this.moduleList[index].list[indexj]["completed"]
              )
            ) {
              noModuleActivityFoundDisplay = false;
              if (this.currentModuleIndex !== index) {
                moduleIndexChangeFlag = true;
              }
              this.currentActIndex = indexj;
              this.currentModuleIndex = index;

              // this.currentActIndex = 0;
              this.currentActivityData = this.moduleList[index].list[indexj];
              breakFlag = true;
              break;
            }
          }

          // this.moduleList[index].list[indexj] = this.finddependetactivity(this.moduleList[index].list[indexj]);
        }
      }
      if (breakFlag || index === 0) {
        break;
      }
    }
    if (noModuleActivityFoundDisplay) {
      this.setBeginContainerData(null, "noModuleActivityFound");
      this.showBeginContainer = true;
      if (moduleIndexChangeFlag) {
        this.showdiv(this.currentModuleIndex);
      }
      this.moduleList[this.currentModuleIndex].show = true;
    } else {
      if (moduleIndexChangeFlag) {
        this.showdiv(this.currentModuleIndex);
      }
      // this.goToCourse(this.currentActivityData, this.currentModuleIndex, this.currentActIndex);
      this.showBeginBox(
        this.currentActivityData,
        this.currentModuleIndex,
        this.currentActIndex
      );
    }
  }

  getNextActivityData(nextModIndex, nextActIndex) {
    if (this.moduleList[nextModIndex]) {
      if (
        this.moduleList[nextModIndex].list &&
        this.moduleList[nextModIndex].list.length > 0
      ) {
        return this.moduleList[nextModIndex].list[nextActIndex];
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  goToDependantActivity() {
    this.resetBeginContainerData();
    this.setNoDataFoundContainer(null, false);
    const depModId = this.currentActivityData.dependentModId;
    const depActId = this.currentActivityData.dependentActId;
    const tempCurrentModIndex = this.checkModIfExists(
      depModId,
      this.moduleList
    );
    const actList = this.moduleList[tempCurrentModIndex].list;
    const tempCurrentActIndex = this.checkActIfExists(depActId, actList);
    if (tempCurrentModIndex !== -1 && tempCurrentActIndex !== -1) {
      const nextActivity = this.getNextActivityData(
        tempCurrentModIndex,
        tempCurrentActIndex
      );
      if (nextActivity) {
        // this.getActivityData(tempCurrentActIndex, nextActivity, tempCurrentModIndex);
        if (tempCurrentModIndex != this.currentModuleIndex) {
          if (this.currentModuleIndex != tempCurrentModIndex) {
            this.showdiv(tempCurrentModIndex);
          }
        }
        this.showBeginBox(
          nextActivity,
          tempCurrentModIndex,
          tempCurrentActIndex
        );
      } else {
        // console.log('No activity available ...');
        this.setBeginContainerData(null, "noModuleActivityFound");
        this.showBeginContainer = true;
      }
    } else {
      // console.log('No module/activity available ...');
      this.setBeginContainerData(null, "noModuleActivityFound");
      this.showBeginContainer = true;
    }
  }

  checkModIfExists(id, array) {
    for (let i = 0; i < array.length; i++) {
      if (array[i].moduleId == id) {
        return i;
      }
    }
    return -1;
  }

  checkActIfExists(id, array) {
    for (let i = 0; i < array.length; i++) {
      if (array[i].activityId == id) {
        return i;
      }
    }
    return -1;
  }

  // Dependent activity button
  perfornContainerAction(action) {
    if (action) {
      switch (action) {
        case "dependant":
          this.goToDependantActivity();
          break;
      }
    }
  }

  // Get Clicked Activity Data
  getSelectedActData(activty, cb) {
    let params = {};
    params = {
      areaId: activty.areaId,
      courseId: activty.courseId,
      suptId: activty.areaId == 34 ? null : activty.supertypeId,
      subtId: activty.areaId == 34 ? null : activty.activityTypeId,
      instanceId: activty.instanceId || activty.activityId,
      instanceName: activty.instanceName || activty.activityName,
      uId: this.currentUser.id,
      // tempId: activty.enrolId ? false : true,
      // Old
      // enrolId: this.courseDetailSummary.enrolId
      //   ? this.courseDetailSummary.enrolId
      //   : null,
      // New
      enrolId: activty.enrolId,
      roleId: this.currentUser["roleId"],
      playlistId: activty.playlistId
    };
    // if (this.config.flag === "trainer") {
    //   if (Number(this.currentUser["roleId"]) === 8) {
    //     params["tempId"] = true;
    //   } else {
    //     params["tempId"] = false;
    //   }
    // }

    // this.load.presentLoading('Please wait...');
    this.moduleService
      .getLearnActivitiesList(params)
      .then((result: any) => {
        if (result.type === true) {
          console.log("RESULT-->", result);

          // if (
          //   this.courseDetailSummary["courseFrom"] == 2 ||
          //   this.courseDetailSummary["courseFrom"] == 3
          // ) {
          //   if (params["tempId"]) {
          //     result.data["enrolId"] = this.courseDetailSummary.enrolId;
          //   }
          // }
          if (this.courseDetailSummary.areaId != 29) {
            result.data['wfId'] = this.courseDetailSummary.wfId ? this.courseDetailSummary.wfId : null;
          } else {
            result.data['wfId'] = activty.wfId ? activty.wfId : null;
          }
          result.data['stepId'] = activty.stepId ? activty.stepId : null;
          result.data['compAreaId'] = this.courseDetailSummary.compAreaId ? this.courseDetailSummary.compAreaId : null;

          // this.currentActivityData = result.data;
          cb(result.data, false);
        } else {
          // this.toastr.warning('Something went wrong please try again later', 'Warning!').onHidden.subscribe(()=>
          // this.toastr.clear());
          cb(null, true);
        }
      })
      .catch((result) => {
        // this.spinner.show();
        cb(null, true);
        // this.toastr.warning('Something went wrong please try again later', 'Warning!').onHidden.subscribe(()=>
        // this.toastr.clear());
      });
  }

  performActionOnChildComponentData(event) {
    if (event) {
      if (event.action == 'bulkAttendanceStatus') {
        this.toggleBulkAttendance(event);
      } else {
        if (this.showComponent === 'mark-attendance' && event.action == 'remindPopUpStatus') {
          this.remindPopupOpened = event.data;
        } else {
          this.remindPopupOpened = false;
          setTimeout(() => {
            this.showConatinerSpinner = event.loaderFlag;
            this.cdf.markForCheck();
          }, 200);

          if (event) {
            if (event.responseData) {
              this.updateModuleActivityList(
                event.responseData,
                this.currentActivityData,
                event['quizSubmitResponse']
              );
            } else if (event.showAutoPlayConatiner == false && this.showAutoPlayConatiner == true) {
              this.cancelAutoPlay();
            } else if (event.showAutoPlayConatiner == true && this.showAutoPlayConatiner == false) {
              let extraParams = event.extraParams || null
              this.prepareForAutoplay(extraParams);
            }
          }

          console.log("Event ==>", event);
          //  this.cdf.markForCheck();
          if (event["showComponent"] && event["showComponent"] === "quiz") {
            this.showArrow = false;
            if (event["showComponent"] && event["showComponent"] === "quiz" && event["reattempt"] == true) {
              this.selectedLanguageData = this.tempSelectedLanguageData;
            }
          } else if (event["showComponent"] && event["showComponent"] === "quiz-review" && event["quizSubmitResponse"]) {
            this.currentActivityData['resumeFlag'] = 0;
            this.tempSelectedLanguageData = this.selectedLanguageData;
            this.updateLanguageDetails();
          } else {
            this.showArrow = true;
          }
        }
      }
    }
    console.log('showBeginContainer status : ', this.showBeginContainer);
  }

  // Update Activity Module list after activity completion
  updateModuleActivityList(responseData, currentActivity, submitResponse = []) {
    for (let index = 0; index < this.moduleList.length; index++) {
      const module = this.moduleList[index];
      for (
        let indexj = 0;
        indexj < this.moduleList[index].list.length;
        indexj++
      ) {
        let activity = module.list[indexj];
        if (((activity.areaId == 5 || activity.areaId == 29) && activity.activityId === currentActivity.activityId)
          || (activity.areaId == 34 && activity.activityId === currentActivity.contentId)) {
          if (activity.areaId != 34) {
            activity["activitycompletionid"] =
              responseData.lastid;
            activity["complitionlangId"] =
              responseData.complitionlangId;
            this.moduleList[index].isModuleCompFlag = responseData["mcompflag"];
          } else {
            activity['contentcomplitionId'] = responseData.lastid;
          }
          activity.completedDate =
            responseData.compDate;
          activity["completedStatus"] =
            responseData.compStatus;
          // activity["multiLanguageList"] =
          //   currentActivity.multiLanguageList;
          // if(responseData.compStatus === 'Y'){
          //   this.currentActivityData.wCompleted = 1;
          // }
          // activity.wCompleted = responseData['compStatus'] === 'Y' ? 1 : 0;
          activity.wCompleted =
            responseData["compStatus"] === "Y" ? 1 : 0;
          this.currentActivityData["complitionlangId"] =
            responseData.complitionlangId;
          // this.currentActivityData.completedDate =
          // responseData.compDate;
        }
        if (
          activity.dependentActId ===
          currentActivity.activityId &&
          responseData["compStatus"] === "Y"
        ) {
          activity.dependentActCompStatus = 1;
        }

        activity = this.finddependetactivity(
          activity
        );
      }
    }

    console.log("updateModuleActivityList ==>", this.moduleList);
    console.log("currentActivity ==>", this.currentActivityData);

    this.courseDetailSummary.percComplete = responseData.perc;

    // Autoplay changes
    this.prepareForAutoplay(responseData);

    this.eventsService.publish(
      "course:updatedCourseDetails",
      this.courseDetailSummary
    );
    this.updateLanguageDetails();
    this.currentActivityData['resumeFlag'] = 0;
    let submitdata = submitResponse['length'] > 0 ? submitResponse[0] : null;
    if (submitdata) {
      if (submitdata.remAttempts <= 0 && submitdata.isUnlimited == 0) {
        this.courseCompleted = true;
      } else if (submitdata.remAttempts >= 0 && submitdata.isUnlimited != 0) {
        this.courseCompleted = true;
      }
    }
  }

  prepareForAutoplay(responseData) {
    if (responseData.lastLang) {
      this.courseDetailSummary['firstLang'] = responseData.firstLang;
      this.courseDetailSummary['lastLang'] = responseData.lastLang;
    } else {
      console.log('last language not found...', responseData);
    }

    if (this.courseDetailSummary.isAutoPlay) {
      this.updateNextActivityDetails();
      this.showAutoPlayContainer();
    } else {
      console.log('Autoplay is not enabled...');
    }
  }

  showAutoPlayContainer() {
    console.log('Consumed activity details : ', this.currentActivityData);
    console.log('dependencyConatiner.showAutoPlayConatiner', this.showAutoPlayConatiner);
    if (!this.isLastActivity()) {
      // actDependencyContainer.defaultAuto.showAutoPlayConatiner = true;
      // this.dependencyConatiner = actDependencyContainer.defaultAuto;
      this.showAutoPlayConatiner = true;
      // this.checkIfShowAutoPlayTimer(this.currentActivtyData, this.currentActivtyData.multiLanguageList.length, 2);
    }
  }

  isLastActivity() {
    let lstModIndex = this.moduleList.length - 1;
    let lstActIndex = this.moduleList[lstModIndex].list.length - 1;
    if (
      (lstModIndex == this.currentModuleIndex) &&
      (lstActIndex == this.currentActIndex)
    ) {
      return true;
    } else {
      return false;
    }
  }

  async presentAlert(msg, title, type) {
    await this.toaster.prsentToast(msg, title, type, null);
  }

  // pop-up
  showPopup: boolean = false;
  quizIntro: boolean = false;
  quizPass: boolean = false;
  share: boolean = false;
  rating: boolean = false;
  ratingText: boolean = false;
  private body = document.querySelector("body");
  openPop(pop) {
    // for(let item in this.modals) {
    //   if(item === pop) {
    //   }
    // }
    console.log("pop ====", pop);
    this.showPopup = true;

    switch (pop) {
      case "share":
        this.share = true;
        break;

      case "rating":
        this.rating = true;
        break;

      case "quizPass":
        this.quizPass = true;
        break;

      case "quizIntro":
        this.quizIntro = true;
        break;

      default:
        break;
    }
    this.body.style.overflow = "hidden";
    this.cdf.markForCheck();
  }

  closePopup() {
    this.showPopup = false;
    this.share = false;
    this.rating = false;
    this.quizPass = false;
    this.quizIntro = false;
    setTimeout(() => {
      this.body.style.overflow = "inherit";
    }, 1000);
  }

  onSubmitInstruction() {
    // this.router.navigate(["quiz"], { relativeTo: this.routes });
    this.showComponent = "quiz";
    this.cdf.markForCheck();
    this.closePopup();
  }

  onSubmitPassword() {
    this.submitted = true;
    // this.serviceButtonDisabled = true;
    if (this.password === this.passKey) {
      // this.passCheck = false;
      this.closePopup();
      if (this.checkResponse.instructions) {
        // this.serviceButtonDisabled = true;
        this.openPop("quizIntro");
        // this.displayInstructions = true;
      } else {
        // this.serviceButtonDisabled = true;
        this.showComponent = "quiz";
        this.cdf.markForCheck();
        // this.displayInstructions = false;
        // this.router.navigate(["quiz"], { relativeTo: this.routes });
      }
    } else {
      this.passWrong = true;
    }
  }

  updateCouseDetails(rating) {
    console.log('updateCouseDetails', rating);
    if (rating) {
      this.courseDetailSummary.rating = rating;
    }

  }


  prepareActivityData(activity, data) {
    activity["dependentActName"] = data["dependentActName"];
    activity["dependentModName"] = data["dependentModName"];
    activity["completed"] = data.completed;
    activity["wait"] = data.wait;
    activity["begin"] = data.begin;
    activity["begin"] = data.begin;
    activity["completedDate"] = data.completedDate;
    activity["actIcon"] = data.actIcon;
    activity["activityTypeId"] = data.activityTypeId;
    activity["activity_type"] = data.activity_type;
    activity["byTrainer"] = data.byTrainer;
    activity["byTrainerMsg"] = data.byTrainerMsg;
    activity["byLearner"] = data.byLearner;
    activity["dependentModId"] = data.dependentModId;
    activity["dependentActId"] = data.dependentActId;
    activity["supertypeId"] = data.supertypeId;
    activity["actFormatName"] = data["actFormatName"];
    activity[
      "showBeginContainer"
    ] = this.bindBeginContainer(activity);
    activity["instanceId"] = data["instanceId"];

    activity["isModuleinProgress"] = data["isModuleinProgress"];

    activity["isInduction"] = data["isInduction"];





    return activity;
  }

  ngOnDestroy() {
    ["", "webkit", "moz", "ms"].forEach((prefix) =>
      document.removeEventListener(
        prefix + "fullscreenchange",
        () => {
          if (!window.screenTop && !window.screenY) {
            this.windowFullScreen = false;
            console.log("not fullscreen");
          } else {
            this.windowFullScreen = true;
            console.log("fullscreen");
          }
        },
        false
      )
    );
  }

  /*************** Autoplay changes start ************/
  performActionsNew(event) {
    console.log('performActionsNew event: ', event);
    if (event.type) {
      switch (event.type) {
        case 'timer': this.timerUpdated(event.data);
          break;
        case 'close': this.toggleBulkAttendance(event.data);
          break;
      }
    }
  }

  /*************** Autoplay activity after timer start ************/
  timerUpdated(counter) {
    console.log('timerUpdated : ', counter);
    if (counter == 0) {
      setTimeout(() => {
        this.showNextActivity();
      }, 500);
    }
  }

  showNextActivity() {
    this.skipToNextActivity();
  }

  cancelAutoPlay() {
    // this.dependencyConatiner.showAutoPlayConatiner = false;
    this.showAutoPlayConatiner = false;
  }
  /*************** Autoplay activity after timer end ************/

  /*************** Update next activity details in current activity start ************/
  updateNextActivityDetails(currentModIndex = this.currentModuleIndex, currentActIndex = this.currentActIndex) {
    // let tempCurrentModIndex = currentModIndex;
    // let tempCurrentActIndex = currentActIndex;
    // if (this.moduleList.length > 0) {
    //   if (String(this.moduleList[this.currentModuleIndex].isDisable).toLowerCase() === 'y') {
    //     tempCurrentModIndex = this.currentModuleIndex + 1;
    //     tempCurrentActIndex = 0;
    //   } else if ((this.moduleList[this.currentModuleIndex].list.length - 1) === this.currentActIndex) {
    //     tempCurrentModIndex = this.currentModuleIndex + 1;
    //     tempCurrentActIndex = 0;
    //   } else {
    //     tempCurrentActIndex = this.currentActIndex + 1;
    //   }
    //   let nextActivity = this.getNextActivityData(tempCurrentModIndex, tempCurrentActIndex);
    //   if (nextActivity) {
    //     let tempNextActivity = {
    //       nextActId: '',
    //       nextActName: '',
    //       // firstLang: 0,
    //       // lastLang: 0,
    //     }
    //     if (nextActivity.wait || nextActivity.pending) {
    //       this.updateNextActivityDetails(tempCurrentModIndex, tempCurrentActIndex);
    //     } else {
    //       tempNextActivity = {
    //         nextActId: nextActivity.activityId,
    //         nextActName: nextActivity.activityName,
    //         // firstLang: this.currentCourseData.firstLang || this.currentActivtyData.firstLang,
    //         // lastLang: this.currentCourseData.lastLang || this.currentActivtyData.lastLang,
    //       }
    //     }
    //     this.currentActivityData = { ...this.currentActivityData, ...tempNextActivity };
    //   } else {
    //     console.log('No activity available ...');
    //   }
    // } else {
    //   console.log('No module available ...');
    // }
    this.updateActOrSkipToNext(this.currentModuleIndex, this.currentActIndex, false);
  }

  updateActOrSkipToNext(currentModIndex = this.currentModuleIndex, currentActIndex = this.currentActIndex, skip = true) {
    let tempCurrentModIndex = currentModIndex;
    let tempCurrentActIndex = currentActIndex;
    if (this.moduleList.length > 0) {
      if (String(this.moduleList[currentModIndex].isDisable).toLowerCase() === 'y') {
        tempCurrentModIndex = currentModIndex + 1;
        tempCurrentActIndex = 0;
      } else if ((this.moduleList[currentModIndex].list.length - 1) === currentActIndex) {
        tempCurrentModIndex = currentModIndex + 1;
        tempCurrentActIndex = 0;
      } else {
        tempCurrentActIndex = currentActIndex + 1;
      }
      const nextActivity = this.getNextActivityData(tempCurrentModIndex, tempCurrentActIndex);
      if (nextActivity) {
        if (skip) {
          console.log('Activity switching....');
          // App
          // this.getActivityData(tempCurrentActIndex, nextActivity, tempCurrentModIndex);
          // Portal
          this.skipToNextActivity();
          // Playlist Changes
          // this.goToNextActByRouting(nextActivity);
        } else {
          console.log('Activity updating....');
          // if (nextActivity.wait || nextActivity.pending) {
          //     this.updateActOrSkipToNext(tempCurrentModIndex, tempCurrentActIndex);
          // } else {
          let tempNextActivity = {
            nextActId: nextActivity.activityId || '',
            nextActName: nextActivity.activityName || '',
            // firstLang: this.currentCourseData.firstLang || this.currentActivtyData.firstLang,
            // lastLang: this.currentCourseData.lastLang || this.currentActivtyData.lastLang,
          }
          // }
          this.currentActivityData = { ...this.currentActivityData, ...tempNextActivity };
        }
      } else {
        console.log('No activity available ...');
      }
    } else {
      console.log('No module available ...');
    }
  }
  /*************** Update next activity details in current activity end ************/

  /*************** Playlist changes start ************/
  goToNextActByRouting(content) {
    let actId = content.instanceId || content.activityId;
    this.router.navigate(['/pages/module-activity/' + content.areaId + '/' + actId + '/' + 0 + '/' + 0]);
  }
  /*************** Playlist changes end ************/

  /*************** Autoplay changes end ************/

  /*************** Bulk mark attendance for trainer start ************/

  showBulkAttendace = false;
  excelUploadConfig: any;

  toggleBulkAttendance(data) {
    if (!this.showBulkAttendace) {
      this.excelUploadConfig = this.markAttendanceService.excelUploadConfig;
      this.excelUploadConfig.extraParams = {
        roleId: this.currentUser.roleId,
        courseId: this.currentActivityData.courseId,
        moduleId: this.currentActivityData.moduleId,
        activityId: this.currentActivityData.activityId,
      };
    } else {
      // this.showBeginBox(
      //   this.moduleList[this.currentModuleIndex].list[this.currentActIndex],
      //   this.currentModuleIndex,
      //   this.currentActIndex
      // );
    }
    this.showBulkAttendace = !this.showBulkAttendace;
  }

  performActionOnEvents(event) {
    console.log('performActionOnEvents : ', event);
    switch (event.action) {
      case "updatedFormData":
        // this.saveUpdatedFormData(event.data)
        break;
      case "excelSelected":
        // this.saveBulkData(event);
        break;
      case "excelUploaded":
        this.updateActivityDataAfterFileUpload(event.data);
        break;
      default: console.log('invalid action')
        break;
    }
  }

  updateActivityDataAfterFileUpload(event) {
    if (event.success != 0) {
      this.showBeginBox(
        this.moduleList[this.currentModuleIndex].list[this.currentActIndex],
        this.currentModuleIndex,
        this.currentActIndex
      );
    }
  }
  /*************** Bulk mark attendance for trainer end ************/

  resetPDFProgress() {
    this.currentPdfProgress = 0;
    this.updatePDFProgress();
  }

  updatePDFProgress() {
    this.chartData[0].value = this.currentPdfProgress;
  }

}
