import { Injectable } from '@angular/core';
import * as CryptoJS from "crypto-js";

@Injectable({
  providedIn: 'root'
})
export class CryptoService {
  private encryptionKey = 'worldisfullofdevelopers';
  constructor() { }

  encryptData(data, key) {
    const cryptoKey = this.validate(data, key);
    if (cryptoKey) {
      if (typeof data != 'string') {
        data = JSON.stringify(data);
      }
      return data = CryptoJS.AES.encrypt(data, cryptoKey).toString();
    } else {
      return null;
    }
  }

  decryptData(data, key) {
    const cryptoKey = this.validate(data, key);
    if (cryptoKey) {
      if (typeof data != 'string') {
        return data;
      }
      // return data = CryptoJS.AES.decrypt(data, cryptoKey);
      const bytes = CryptoJS.AES.decrypt(data, cryptoKey);
      return bytes.toString(CryptoJS.enc.Utf8);
    } else {
      return null;
    }
  }

  validate(data, key) {
    let cryptoKey = '';
    if (!data) {
      return null;
    }
    if (key) {
      cryptoKey = key;
    } else {
      cryptoKey = this.encryptionKey;
    }
    return cryptoKey;
  }
}
