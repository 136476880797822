import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { webApi } from '../../../config';

@Injectable({
  providedIn: 'root'
})
export class CommentsService {
  private headers = new HttpHeaders();
  baseUrl = webApi.baseUrl;
  getAllComments = webApi.baseUrl + webApi.apiUrl.getAllComments;
  insertUpdateComment = webApi.baseUrl + webApi.apiUrl.insertUpdateComment;

  constructor(public http: HttpClient,) { }

  public loadComments(param: any): Promise<any> {
    return this.http
      .post(this.getAllComments, param)
      .toPromise()
      .then((response) => {
        return (response = response);
      })
      .catch(this.handleError);
  }

  public updateComment(param: any): Promise<any> {
    return this.http
      .post(this.insertUpdateComment, param)
      .toPromise()
      .then((response) => {
        return (response = response);
      })
      .catch(this.handleError);
  }
  private handleError(error: Response | any) {
    return Promise.reject(error.message || error);
  }
}


