<div class="mainDiv h-100" *ngIf="!submit">

  <div class="feedbackRow p-4">
    <div class="d-flex align-items-center mb-4" *ngIf="inInternalFlag">
      <nb-icon icon="arrow-back-outline" class="cursor-p align-self-center mr-2 margin-left-icon"
        (click)="isInternalBack()"></nb-icon>
      <!-- <img class="cursor-p" src="assets/icon-svg/chevron-left.svg" (click)="isInternalBack()" alt="left-arrow"> -->
      <h3 class="mb-0 ml-2">{{activity?.activityName}}</h3>
    </div>
    <!-- <div class="feedbackmat border border-bottom-0" *ngFor="let question of feedbacklist; let i = index">
      <div *ngIf="i == 0 || question.secName !== feedbacklist[i-1].secName" class="section-name p-2 bg-light">
        <h4>{{ question.secName }}</h4>
      </div>
      <div class="question-answer   mb-5" ngClass="">
        <div class="feedback_question_container">
           \* <div>
            <h3 class="question64">{{ i + 1 }}</h3>
          </div> *\
          <div>
            <h3 class="question64">
              {{ getQuestionNumberForSection(i, question.secName) }}
            </h3>
          </div>
          <div>
            <h3 class="question64" [innerHtml]="makeFeedbackQuestionReady(question) | safePipePipe:'html'"></h3>
          </div>
        </div>

        <div class="survey-textarea question-margin" *ngIf="question.questionTypeId == 1">
          <textarea cols="12" rows="5" class="txtArea"
            [disabled]="question?.isAnswered == 1  && activity?.completionstatus == 'Y'"
            (ngModelChange)="checkIfValidInputText(i)" [(ngModel)]="question.optionList[0].sAns"
            minlength="{{ question?.optionList[0]?.minLength }}" maxlength="{{ question?.optionList[0]?.maxLength }}"
            #name="ngModel" [placeholder]="question.questionPlaceholder"></textarea>
        </div>

        <div class="questions-answer" *ngIf="question.questionTypeId == 2 || question.questionTypeId == 3">
          <button class="options45" [disabled]="question?.isAnswered == 1  && activity?.completionstatus == 'Y'"
            *ngFor="let option of question.optionList; let j = index" [ngClass]="{ active: option.sFlag }"
            (click)="selectOption(question, j)">
            {{ option?.option }}
          </button>
        </div>

        <div class="question-margin" *ngIf="question.questionTypeId == 4">
          <star-rating [starType]="'svg'" [disabled]="question?.isAnswered == 1 && activity?.completionstatus == 'Y'"
            [showHalfStars]="true" [numOfStars]="4" staticColor="positive" [rating]="question.optionList[0].sRateAns"
            size="large" (ratingChange)="onRateChange($event, i)"></star-rating>
        </div>
        <div *ngIf="!question?.isValid" class="mandatoryQues mt-3">
          <p>{{ question.msg }}</p>
        </div>
      </div>
    </div> -->
    <div *ngFor="let question of feedbacklist; let i = index">
      <!-- Section Container for grouping questions -->
      <div *ngIf="i == 0 || question.secName !== feedbacklist[i-1].secName" 
           class="section-container mb-4 border  border-dark p-2 ">
        <div class="section-name p-1  bg-light">
          <h3>{{ question.secName }}</h3>
        </div>
        <div *ngFor="let secQuestion of  getQuestionsForSection(question.secName, i)" class="m-1">
          <div class="question-answer mb-5">
            <div class="feedback_question_container">
              <div>
                <h3 class="question64">
                  {{ getQuestionNumberForSection(feedbacklist.indexOf(secQuestion), secQuestion.secName) }}
                </h3>
              </div>
              <div>
                <h3 class="question64" [innerHtml]="makeFeedbackQuestionReady(secQuestion) | safePipePipe:'html'"></h3>
              </div>
            </div>
    
            <div class="survey-textarea question-margin" *ngIf="secQuestion.questionTypeId == 1">
              <textarea cols="12" rows="5" class="txtArea"
                [disabled]="secQuestion?.isAnswered == 1  && activity?.completionstatus == 'Y'"
                (ngModelChange)="checkIfValidInputText(feedbacklist.indexOf(secQuestion))" 
                [(ngModel)]="secQuestion.optionList[0].sAns"
                [minlength]="secQuestion?.optionList[0]?.minLength" 
                [maxlength]="secQuestion?.optionList[0]?.maxLength"
                #name="ngModel" [placeholder]="secQuestion.questionPlaceholder"></textarea>
            </div>
    
            <div class="questions-answer" *ngIf="secQuestion.questionTypeId == 2 || secQuestion.questionTypeId == 3">
              <button class="options45" 
                [disabled]="secQuestion?.isAnswered == 1  && activity?.completionstatus == 'Y'"
                *ngFor="let option of secQuestion.optionList; let j = index" 
                [ngClass]="{ active: option.sFlag }"
                (click)="selectOption(secQuestion, j)">
                {{ option?.option }}
              </button>
            </div>
    
            <div class="question-margin" *ngIf="secQuestion.questionTypeId == 4">
              <star-rating [starType]="'svg'" 
                [disabled]="secQuestion?.isAnswered == 1 && activity?.completionstatus == 'Y'"
                [showHalfStars]="true" [numOfStars]="4" staticColor="positive" 
                [rating]="secQuestion.optionList[0].sRateAns"
                size="large" (ratingChange)="onRateChange($event, feedbacklist.indexOf(secQuestion))">
              </star-rating>
            </div>
    
            <div *ngIf="!secQuestion?.isValid" class="mandatoryQues mt-3">
              <p>{{ secQuestion.msg }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  </div>
  <div class="footerDiv text-right" *ngIf="currentUser?.roleId != 7 && activity?.completionstatus != 'Y'">
    <button class="nxt" [disabled]="disableSubmit" (click)="submitFeedbackForm()">
      Submit
    </button>
  </div>
  <div class="text-right" *ngIf="currentUser?.roleId == 7 && activity?.completionstatus != 'Y'">
    <button class="button btn-outline" [disabled]="disableSubmit" (click)="submitFeedbackFormTTT(0)">Save</button>
    <button class="button btn-fill ml-3" [disabled]="disableSubmit" (click)="submitFeedbackFormTTT(1)">Publish</button>
  </div>
</div>
<div class="show-content-center">
  <div class="feedback-content-div" *ngIf="submit">
    {{ submit?.msg }}
  </div>
</div>