import {
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
  NgModule,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { PlyrModule } from "ngx-plyr";
import {
  NbSpinnerModule, NbDialogModule, NbIconModule,
  NbContextMenuModule,
  NbButtonModule,
  NbUserModule,
  NbCardModule,
  NbListModule,
  NbActionsModule,
  NbDatepickerModule
} from "@nebular/theme";
import { PipesModule } from "../pipes/pipes.module";
import { SortablejsModule } from "ngx-sortablejs";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DpDatePickerModule } from 'ng2-date-picker';
import { StarRatingModule } from "angular-star-rating";
import { ImageCropperModule } from 'ngx-image-cropper';
import { TabsModule } from 'ngx-tabset';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { ImageViewerModule } from "./image-viewer/image-viewer.module";
import { NgxPaginationModule } from 'ngx-pagination';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
// import { ClickOutsideModule } from "ng-click-outside";

import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

import { PreloaderImgComponent } from "./preloader-img/preloader-img.component";
import { NgxExtendedPdfViewerModule } from "ngx-extended-pdf-viewer";
import { ScormPlayerComponent } from "./scorm-player/scorm-player.component";
import { KPointComponent } from "./k-point/k-point.component";
import { PdfViewerComponent } from "./pdf-viewer/pdf-viewer.component";
import { MediaPlayerComponent } from "./media-player/media-player.component";
import { ActivitiesContainerComponent } from "./activities-container/activities-container.component";
import { ModuleActivityContainerComponent } from "./module-activity-container/module-activity-container.component";
import { FeedbackComponent } from "../pages/activities/feedback/feedback.component";
import { QuizContainerComponent } from "../pages/activities/quiz-container/quiz-container.component";
import { QuizReviewComponent } from "../pages/activities/quiz-container/quiz-review/quiz-review.component";
import { QuizComponent } from "../pages/activities/quiz-container/quiz/quiz.component";
import { MarkAttendanceComponent } from "./mark-attendance/mark-attendance.component";
import { NoDataComponent } from "./no-data/no-data.component";
import { CourseCardAdvanceComponent } from "./course-card-advance/course-card-advance.component";
import { CourseCardV3Component } from "./course-card-v3/course-card-v3.component";
import { StepperComponent } from './stepper/stepper.component';
import { SchedulepopupComponent } from "./schedulepopup/schedulepopup.component";
import { DialogViewerComponent } from "./dialog-viewer/dialog-viewer.component";
import { PopupwithiconComponent } from './popupwithicon/popupwithicon.component';
import { ContentSearchCardComponent } from './content-search-card/content-search-card.component';
import { AdvanceFilterComponent } from './advance-filter/advance-filter.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ScrollTopComponent } from './scroll-top/scroll-top.component';
import { FilterComponent } from './filter/filter.component';
import { ImagePickerComponent } from './image-picker/image-picker.component';
import { SendTestimonialComponent } from "./send-testimonial/send-testimonial.component";
import { ShareContentComponent } from './share-content/share-content.component';
import { ContentRatingComponent } from './content-rating/content-rating.component';
import { ReadMoreComponent } from './read-more/read-more.component';
import { ContentLearnCardComponent } from './content-learn-card/content-learn-card.component';
import { WorkflowCardComponent } from "./workflow-card/workflow-card.component";
// import { ImagePickerComponent } from './components/image-picker/image-picker.component';

// import { RaiseLearningRequestComponent } from "./raise-learning-request/raise-learning-request.component";
// import { CarouselItemComponent } from './carousel-item/carousel-item.component';
import { H5pPlayerComponent } from './h5p-player/h5p-player.component';
import { ModuleActivityListComponent } from './module-activity-list/module-activity-list.component';
import { TableComponent } from './table/table.component';
import { TextComponent } from './table/text/text.component';
import { CheckboxComponent } from "./table/checkbox/checkbox.component";
import { UserImageComponent } from './table/user-image/user-image.component';
import { IconTextComponent } from "./table/icon-text/icon-text.component";
import { SortableComponent } from "./table/sortable/sortable.component";
import { SplitColumnComponent } from "./table/split-column/split-column.component";
import { InputComponent } from './table/input/input.component';
import { TableSkeletonComponent } from './table/table-skeleton/table-skeleton.component';

import { EventsCalendarComponent } from './events-calendar/events-calendar.component';
import { ActionsComponent } from './table/actions/actions.component';
import { RightSideDrawerComponent } from './right-side-drawer/right-side-drawer.component';
import { EvFeedbackComponent } from '../pages/activities/ev-feedback/ev-feedback.component';
import { TetimonialsComponent } from "./tetimonials/tetimonials.component";
import { PopupComponent } from './popup/popup.component';
import { CertificatesComponent } from './certificates/certificates.component';
import { AssignmentActvityComponent } from './assignment-actvity/assignment-actvity.component';
import { FieldIconComponent } from './table/field-icon/field-icon.component';
import { CommentsComponent } from './comments/comments.component';
import { SearchableSelectListComponent } from './searchable-select-list/searchable-select-list.component';
import { UserSearchableListComponent } from './user-searchable-list/user-searchable-list.component';
import { ModuleActivityFrameComponent } from './module-activity-frame/module-activity-frame.component';
import { CommonFetchListComponent } from './common-fetch-list/common-fetch-list.component';
import { TrainerEvaluateActivityComponent } from './trainer-evaluate-activity/trainer-evaluate-activity.component';
import { SliderComponent } from './slider/slider.component';
import { LayoutModule } from '@angular/cdk/layout';
import { CallDetailsComponent } from './call-details/call-details.component';
import { IdpComponent } from './idp/idp.component';
import { AssignNotifyComponentComponent } from './assign-notify-component/assign-notify-component.component';
import { QuillModule } from 'ngx-quill';
import { HtmlEditorComponent } from './html-editor/html-editor.component';
import { DropdownComponent } from './table/dropdown/dropdown.component';

// import { CIPSharedModule } from '../cip/shared/cip-shared.module';
// import { CertificateComponent } from './certificate/certificate.component';

import * as fromSharedComponents from '../modules/shared/components/index';
import * as fromLearnerComponents from '../modules/learner/components/index';
import { ScrollingModule } from "@angular/cdk/scrolling";
import { HttpClientModule } from "@angular/common/http";
import { ReasonPopupComponent } from './reason-popup/reason-popup.component';
import { IconTextV2Component } from './table/icon-text-v2/icon-text-v2.component';
import { InputWSubmitComponent } from './table/input-w-submit/input-w-submit.component';
import { DirectivesModule } from "../directives/directives.module";
import { CircleProgressCountComponent } from './circle-progress-count/circle-progress-count.component';
import { FullCalendarModule } from "@fullcalendar/angular";
import { SwiperComponent } from './swiper/swiper.component';
import { SwiperDirective } from "./swiper/swiper-directive.directive";
import { ArticleActivityComponent } from './article-activity/article-activity.component';
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { NgCircleProgressModule } from "ng-circle-progress";
// import { SwiperDirective } from "./swiper/swiper-directive.directive";

const COMPONENTS = [
  PreloaderImgComponent,
  ScormPlayerComponent,
  KPointComponent,
  PdfViewerComponent,
  MediaPlayerComponent,
  FeedbackComponent,
  QuizContainerComponent,
  QuizReviewComponent,
  QuizComponent,
  ActivitiesContainerComponent,
  ModuleActivityContainerComponent,
  MarkAttendanceComponent,
  NoDataComponent,
  CourseCardAdvanceComponent,
  CourseCardV3Component,
  // DialogComponent,
  StepperComponent,
  // DialogViewerComponent,
  CourseCardV3Component,
  // SchedulepopupComponent,
  // PopupwithiconComponent,
  ContentSearchCardComponent,
  AdvanceFilterComponent,
  ScrollTopComponent,
  FilterComponent,
  // ImagePickerComponent,
  SendTestimonialComponent,
  CommentsComponent,
  // RaiseLearningRequestComponent,
  ContentRatingComponent,
  ReadMoreComponent,
  ContentLearnCardComponent,
  // ProfileLearningRequestComponent,
  WorkflowCardComponent,
  H5pPlayerComponent,
  ModuleActivityListComponent,
  TableComponent,
  TextComponent,
  ActionsComponent,
  SplitColumnComponent,
  IconTextComponent,
  SortableComponent,
  InputComponent,
  IconTextComponent,
  RightSideDrawerComponent,
  EventsCalendarComponent,
  EvFeedbackComponent,
  TetimonialsComponent,
  CertificatesComponent,
  // BookSlotFormComponent,
  CheckboxComponent,
  UserImageComponent,
  PopupComponent,
  AssignmentActvityComponent,
  FieldIconComponent,
  SearchableSelectListComponent,
  UserSearchableListComponent,
  TableSkeletonComponent,
  ModuleActivityFrameComponent,
  CommonFetchListComponent,
  TrainerEvaluateActivityComponent,
  SliderComponent,
  CallDetailsComponent,
  AssignNotifyComponentComponent,
  HtmlEditorComponent,
  DropdownComponent,
  CircleProgressCountComponent,
  SwiperComponent,
  ArticleActivityComponent,
  ...fromSharedComponents.components,
  ...fromLearnerComponents.components,
];

const MODULES = [
  NbSpinnerModule,
  PlyrModule,
  NgxExtendedPdfViewerModule,
  PipesModule,
  StarRatingModule,
  NbDialogModule,
  SortablejsModule,
  FormsModule,
  // StarRatingModule,
  NbIconModule,
  NbContextMenuModule,
  NbButtonModule,
  DpDatePickerModule,
  // AngularSvgIconModule,
  ImageCropperModule,
  NbUserModule,
  NbCardModule,
  NbListModule,
  NbActionsModule,
  ReactiveFormsModule,
  TabsModule.forRoot(),
  ImageViewerModule.forRoot(),
  CalendarModule.forRoot({
    provide: DateAdapter,
    useFactory: adapterFactory,
  }),
  NgxPaginationModule,
  // ClickOutsideModule,
  // CIPSharedModule
  LayoutModule,
  QuillModule.forRoot(),
  AngularMultiSelectModule,
  ScrollingModule,
  NbDatepickerModule,
  HttpClientModule,
  AngularSvgIconModule.forRoot(),
  DirectivesModule,
  FullCalendarModule,
  // AngularSvgIconModule,
  NgxChartsModule,
  NgCircleProgressModule.forRoot({}),
];

const EntryComponents = [
  PopupwithiconComponent,
  SchedulepopupComponent,
  DialogViewerComponent,
  ImagePickerComponent,
  IdpComponent,
  ShareContentComponent,
  SwiperComponent,
  ArticleActivityComponent,
];
@NgModule({
  imports: [CommonModule, ...MODULES],
  declarations: [...COMPONENTS, ...EntryComponents, ReasonPopupComponent, IconTextV2Component, InputWSubmitComponent, SwiperDirective],
  exports: [...COMPONENTS],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class ComponentsModule { }
