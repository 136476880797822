<!-- The start button --->
<!-- <button id="startbtn">Start</button> -->

<div mat-card-image plyr class="w-100 h-100 " *ngIf="playerSource?.length > 0" [plyrType]="playerSource[0]?.type"
  [plyrOptions]="playerOptions" [plyrPlaysInline]="true" [plyrCrossOrigin]="true"
  (plyrTimeUpdate)="plyrTimeUpdate($event)" (plyrLoadedData)="plyrLoadedData($event)"
  [plyrSources]="playerSource[0]?.sources" (plyrPause)="($event)" (plyrInit)="player = $event"
  (plyrReady)="plyrReady($event)" (plyrCanPlay)="plyrCanPlay(activity)" (plyrPlay)="played($event)"
  (plyrEnded)="plyrEnded($event)" (plyrEnterFullScreen)="plyrEnterFullScreen($event)"
  (plyrExitFullScreen)="plyrExitFullScreen($event)" (plyrError)="plyrError($event)"></div>
<!-- -->
<!-- <div
  mat-card-image
  plyr
  [plyrType]="playerSource[0].type"
  [plyrOptions]="playerOptions"
  [plyrPlaysInline]="true"
  [plyrCrossOrigin]="true"
  [plyrSources]="playerSource[0].sources"
  (plyrInit)="player = $event"
  (plyrReady)="plyrReady($event)"
  (plyrPlay)="played($event)"
></div> -->
<!-- <div
  mat-card-image
  plyr
  #PlyrComponent
  [plyrType]="playerSource[0]?.type"
  [plyrOptions]="playerOptions"
  [plyrPlaysInline]="true"
  [plyrCrossOrigin]="true"
  [plyrSources]="playerSource[0]?.sources"
></div> -->