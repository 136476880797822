<!-- <ng-template> && selectedUserList?.length !=0 && tableData?.length !=0-->
<div class="p-3" [nbSpinner]="loading" nbSpinnerStatus="primary">
    <div>
        <div class="d-flex justify-content-between pb-3 pt-2" *ngIf="!notFound">
            <input class="flex-grow-1 border rounded outline-none px-2 mr-3" type="search" placeholder="Search"
                [formControl]="searchControl" maxlength="20">
            <!--(input)="search($event.target.value)"-->
            <span [ngClass]="{'pt-3 pb-3': (type==2 || type == 3 && paramsObject?.typeId == 2) || type == 5}">

                <ng-container *ngIf="type == 1 || type == 4">
                    <button class="button btn-outline"
                        *ngIf="!paramsObject?.qrLink && paramsObject?.byQR && paramsObject?.qrGenAvailable"
                        (click)="generateQr()">Generate
                        QR</button>
                    <button class="button btn-outline ml-3" (click)="updateAttendance(1)">Mark</button>
                    <button class="button btn-outline ml-3" (click)="updateAttendance(2)">Unmark</button>
                    <button class="button btn-outline ml-3" *ngIf="type == 4 && paramsObject?.isModuleinProgress == 1"
                        (click)="sendReminders()">Remind</button>
                    <button class="button btn-outline ml-3" *ngIf="paramsObject?.isbulkAttendance"
                        (click)="bulkAttendance()">Bulk Attendance</button>
                </ng-container>

                <!-- <select class="button btn-outline ml-3" (change)="activityChange($event.target.value)">
                <option *ngFor="let item of activityList" value="{{item.activityId}}">
                  {{item.activityName}}
                </option>
            </select> -->
                <ng-container *ngIf="type == 3 && paramsObject?.typeId == 1">
                    <button class="button btn-outline" (click)="certifyUsers()">Certify</button>
                </ng-container>
            </span>
        </div>

        <div class="qrimage position-relative h-12-rem w-12-rem border rounded mb-3 mx-auto"
            *ngIf="!notFound && paramsObject?.qrLink && paramsObject?.qrShow">
            <img class="w-100 h-100 rounded" [src]="paramsObject?.qrLink" alt="qr">
            <span
                class="qrimage__download position-absolute bg-white right-5-px top-5-px border-radius-4px border shadow p-1 px-2 cursor-p"
                (click)="downloadQR(paramsObject?.qrLink)">
                <svg-icon svgClass="lh-0 stroke-primary" class="lh-0" src="../../../assets/icon-svg/Download_icon.svg"
                    [svgStyle]="{'width.rem': 1, 'height.rem': 1, 'stroke-width': 1.4}"></svg-icon>
            </span>
        </div>

        <div [ngClass]="{'maxHeight': isInternalComponent}"
            [style.maxHeight]="isInternalComponent ? externalHeight : null" nbInfiniteList
            [listenWindowScroll]="!isInternalComponent" [threshold]="500" (bottomThreshold)="loadMoreData($event)">
            <ngx-table *ngIf="!notFound" [setting]="config" [tableData]="tableData" [label]="labels"
                [pagination]="false" (getEvents)="performAction($event)"></ngx-table>
            <ng-container *ngIf="working">
                <div class="p-4" [nbSpinner]="true" nbSpinnerStatus="primary">

                </div>
            </ng-container>
        </div>
    </div>
</div>

<ng-container *ngIf="!loading && !working && notFound">
    <div [ngClass]="{'maxHeight': type == 4}" [style.maxHeight]="type == 4 ? externalHeight : null">
        <div class="nodata">
            <ngx-no-data [title]="noDataFoundContainer?.title" [discription]="noDataFoundContainer?.discription"
                [showImage]="noDataFoundContainer?.showImage"></ngx-no-data>
        </div>
    </div>
</ng-container>
<!-- </ng-template> -->

<ngx-assign-notify-component (getEvents)="performAction($event)" [popupConfig]="remindPopUpConfig"
    [config]="remindNotifyConfig" *ngIf="showAssignNotify">
</ngx-assign-notify-component>