import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-call-details',
  templateUrl: './call-details.component.html',
  styleUrls: ['./call-details.component.scss']
})
export class CallDetailsComponent implements OnInit {
  config = {
    showCustomActionLabel: true,
  }
  // callList: any = [
  //   { 'startdate': '22-07-2022 10:00:00', 'enddate': '22-07-2022 10:00:00', 'asdsd': '123' },
  // ];

  // labels: any = [
  //   { labelname: 'Start Date Time', bindingProperty: 'startdate', componentType: 'text' },
  //   { labelname: 'End Date Time', bindingProperty: 'enddate', componentType: 'text' },
  //   {
  //     labelname: 'Action',
  //     componentType: 'action',
  //     bindingProperty: [
  //       { name: 'Edit', id: 'edit' },
  //     ]
  //   },
  // ];

  labels = [
    {
      "labelname": "Start Date Time",
      "bindingProperty": "startdate",
      "componentType": "text",
      "setting": "{\"maxLength\": \"20\"}",
      "alignment": ""
    },
    {
      "labelname": "End Date Time",
      "bindingProperty": "enddate",
      "componentType": "text",
      "setting": "{\"maxLength\": \"20\"}",
      "alignment": ""
    },
    {
      "labelname": "Action",
      "bindingProperty": "[{\"name\": \"Confirm\",\"cssClass\": \"inactive\",\"bindingProperty\":\"isCopy\",\"bindingPropertyText\":\"isCopyText\", \"action\": \"copyContent\",\"appIcon\": \"url\",\"webIcon\":\"\",\"event\": {\"id\": \"copyContent\",\"value\": 1},\"isVisible\": true}]",
      "componentType": "action",
      "setting": "",
      "alignment": "text-right"
    }
  ]

  callList = [
    {
      "startdate": "2022-08-10T12:00:29.000Z",
      "enddate": "2022-08-10T12:00:29.000Z",
      "isCopy": 1
    },
    {
      "startdate": "2022-08-10T12:00:29.000Z",
      "enddate": "2022-08-10T12:00:29.000Z",
      "isCopy": 1
    },
    {
      "startdate": "2022-08-10T12:00:29.000Z",
      "enddate": "2022-08-10T12:00:29.000Z",
      "isCopy": 1
    },
    {
      "startdate": "2022-08-10T12:00:29.000Z",
      "enddate": "2022-08-10T12:00:29.000Z",
      "isCopy": 1
    },
    {
      "startdate": "2022-08-10T12:00:29.000Z",
      "enddate": "2022-08-10T12:00:29.000Z",
      "isCopy": 1
    },
    {
      "startdate": "2022-08-10T12:00:29.000Z",
      "enddate": "2022-08-10T12:00:29.000Z",
      "isCopy": 1
    },
    {
      "startdate": "2022-08-10T12:00:29.000Z",
      "enddate": "2022-08-10T12:00:29.000Z",
      "isCopy": 1
    },
    {
      "startdate": "2022-08-10T12:00:29.000Z",
      "enddate": "2022-08-10T12:00:29.000Z",
      "isCopy": 1
    },
    {
      "startdate": "2022-08-10T12:00:29.000Z",
      "enddate": "2022-08-10T12:00:29.000Z",
      "isCopy": 1
    },
    {
      "startdate": "2022-08-10T12:00:29.000Z",
      "enddate": "2022-08-10T12:00:29.000Z",
      "isCopy": 1
    },
    {
      "startdate": "2022-08-10T12:00:29.000Z",
      "enddate": "2022-08-10T12:00:29.000Z",
      "isCopy": 1
    },
    {
      "startdate": "2022-08-10T12:00:29.000Z",
      "enddate": "2022-08-10T12:00:29.000Z",
      "isCopy": 1
    },
    {
      "startdate": "2022-08-10T12:00:29.000Z",
      "enddate": "2022-08-10T12:00:29.000Z",
      "isCopy": 1
    },
  ]
  
  constructor() { }

  ngOnInit(): void {
  }

}
