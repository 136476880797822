<div class="module-activity-trainer-frame" [ngClass]="{
        'fullscreen_nogrid': courseDetailSummary && (courseDetailSummary.areaId === 34 || courseDetailSummary?.areaId === 13)
        && !(courseDetailSummary.areaId === 34 && courseDetailSummary?.playListId),
        'pt-4 mt-2' : currentUser?.roleId == 7
    }">
  <!-- <div class="" *ngIf="courseDetailSummary?.areaId == 34"></div> -->
  <div class="activity" [ngClass]="{'cip-module': currentUser?.roleId == 7}">


    <div class="inner-activity" [ngClass]="{'popUp-opened': remindPopupOpened}" [nbSpinner]="showConatinerSpinner"
      nbSpinnerSize="giant" nbSpinnerStatus="primary" id="fullDiv">
      <!-- arrow -->

      <span class="next cursor-p" [hidden]="
          currentActIndex === moduleList[currentModuleIndex]?.list.length - 1 &&
          currentModuleIndex === moduleList?.length - 1
        " *ngIf="showArrow" (click)="goToAutoNext()">
        <i class="fas fa-chevron-right"></i>
      </span>
      <span class="right cursor-p" *ngIf="showArrow" [hidden]="currentActIndex === 0 && currentModuleIndex === 0"
        (click)="goToPrevious()">
        <i class="fas fa-chevron-left"></i>
      </span>
      <!-- no-data found -->
      <div *ngIf="showComponent == 'noDataFound'" [ngClass]="{ 'show-content-center': !noDataFound?.showImage }">
        <div class="d-flex align-items-center justify-content-center">
          <ngx-no-data [title]="noDataFound?.title" [showImage]="noDataFound?.showImage" [ngClass]="{
              'mb-5': !noDataFound?.showImage,
              'w-100': !noDataFound?.showImage
            }" [discription]="noDataFound?.description"></ngx-no-data>
        </div>
      </div>

      <div class="h-100 position-relative" *ngIf="showComponent != 'noDataFound'">

        <!-- Autoplay Container -->
        <!--  *ngIf="dependencyConatiner?.showAutoPlayConatiner" -->
        <ng-container *ngIf="showAutoPlayConatiner">
          <!--  *ngIf="!currentActivityData?.showAutoPlayTime" -->
          <ng-container *ngIf="currentClickedActivityData?.showAutoPlayTime">
            <div class="position-absolute top-0-px left-0-px h-100 w-100 z-10">
              <div class="layer d-flex align-items-center justify-content-center flex-column h-100 w-100">
                <div class="text-lg text-white pb-2">Next Activity</div>
                <div class="text-28-px text-white pb-3">{{currentActivityData?.nextActName}}</div>
                <ngx-circle-progress-count [maxTime]="5"
                  (getEvent)="performActionsNew($event)"></ngx-circle-progress-count>
                <div class="text-20-px text-white pt-3 cursor-p" (click)="cancelAutoPlay()">Cancel</div>
              </div>
            </div>
          </ng-container>

          <!--  *ngIf="currentActivityData?.showAutoPlayTime" -->
          <ng-container *ngIf="!currentClickedActivityData?.showAutoPlayTime">
            <div class="tcontainer d-flex align-items-center position-absolute z-10">
              <div
                class="tcontainer__next bg-black d-flex align-items-center justify-content-between rounded cursor-p p-3 border"
                (click)="showNextActivity()">
                <div class="d-inline-flex align-items-center">
                  <svg-icon svgClass="stroke-black fill-white cursor-p"
                    src="../../../assets/icon-svg/activity-icons/filled/Play_icon.svg"
                    [svgStyle]="{'width.rem': 2, 'height.rem': 2}">
                  </svg-icon>
                  <span class="text-white text-16-px pl-1">{{currentActivityData?.nextActName}}</span>
                </div>
                <svg-icon svgClass="stroke-white ml-2 cursor-p" src="../../../assets/icon-svg/chevron-right.svg"
                  [svgStyle]="{'width.rem': 1, 'height.rem': 1}">
                </svg-icon>
              </div>
              <div
                class="text-white bg-black d-flex align-items-center text-16-px h-66-px border border-white rounded cursor-p p-4 ml-3"
                (click)="cancelAutoPlay()">
                <span class="pr-1">Cancel</span>
                <svg-icon svgClass="stroke-black fill-white cursor-p" src="../../../assets/icon-svg/close.svg"
                  [svgStyle]="{'width.rem': 1.5, 'height.rem': 1.5}">
                </svg-icon>
              </div>
            </div>
          </ng-container>
        </ng-container>

        <!-- Begin Container -->
        <div class="begin-section d-flex text-center p-4" [ngClass]="{ 'begin-section-fullscreen': windowFullScreen }"
          *ngIf="showBeginContainer">
          <div class="begin-inner">
            <span>
              <!-- <img class="begin-icon mb-2" src="assets/icon-svg/{{ beginContainer?.icon }}.svg" alt="" srcset="" /> -->
              <img class="begin-icon mb-2" src="assets/icon-svg/activity-icons/filled/{{
                  beginContainer?.icon
                }}.svg" alt="" srcset="" />
            </span>
            <!-- <span *ngIf="beginContainer?.completedState">
                  <svg id="checkmark-ani" aria-labelledby="svg-my-icon-title" focusable="false" xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 290 290">
                      <circle class="circle" cx="145" cy="145" r="124.67"
                          style="fill:none;stroke:#43a047;stroke-miterlimit:10;stroke-width:10px" />
                      <polyline class="checkmark" points="88.75 148.26 124.09 183.6 201.37 106.32"
                          style="fill:none;stroke:#43a047;stroke-linecap:round;stroke-linejoin:round;stroke-width:16px" />
                  </svg>
              </span> -->
            <h3 class="m-0 pb-3">{{ beginContainer?.name }}</h3>
            <!-- <p *ngIf="beginContainer?.descrition">{{beginContainer?.descrition}} </p> -->
            <div class="complet-msg font-weight-bold mb-2">
              <p class="m-0" *ngIf="beginContainer?.message">
                {{ beginContainer?.message }}
              </p>
            </div>
            <button class="commonBtn start-btn cursor-p" *ngIf="
               ( beginContainer?.beginButton && ( currentActivityData?.resumeFlag == 1 || currentActivityData?.activityTypeId != 2))
              " (click)="startActivity()">
              <ng-container *ngIf="currentActivityData?.resumeFlag == 1">
                Resume
              </ng-container>
              <ng-container *ngIf="currentActivityData?.resumeFlag != 1">
                Begin
              </ng-container>

            </button>
            <button class="commonBtn start-btn cursor-p" *ngIf="beginContainer?.goToButton"
              (click)="perfornContainerAction('dependant')">
              Go to <i class="fas fa-external-link-alt"></i>
            </button>
            <div class="begin-language" *ngIf="
                !beginContainer?.goToButton &&
                beginContainer?.showLanguages &&
                currentActivityData?.resumeFlag != 1
              ">
              <!-- <div *ngIf = "currentActivityData.isDeleted == 1">
              <pclass="pb-1 m-0">
              {{currentActivityData.isDeletedMsg}}
              </p>
            </div> -->

              <div *ngIf="beginContainer?.icon != 'lock'">
                <p *ngIf="currentActivityData?.multiLanguageList?.length > 1">
                  Content available in multiple language, Select language to begin :
                </p>
                <p *ngIf="currentActivityData?.multiLanguageList?.length === 1">
                  Content available in {{ (currentActivityData?.multiLanguageList)[0]?.languageName }}
                  language, Select language to begin :
                </p>
                <button (click)="changeActivityLanguage(item, false); startActivity()" class="cursor-p" *ngFor="
                    let item of currentActivityData?.multiLanguageList;
                    let last = last
                  ">
                  {{ item?.languageName }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Activity Container -->
        <div class="activity-box position-relative h-100" [style.display]="!showConatinerSpinner ? 'block' : 'none'"
          *ngIf="!showBeginContainer">
          <ngx-quiz-container [courseDataContentInput]="currentActivityData"
            [courseDataSummaryInput]="courseDetailSummary"
            (componentDataToParent)="performActionOnChildComponentData($event)" *ngIf="showComponent === 'quiz'">
          </ngx-quiz-container>
          <ngx-feedback [activity]="currentActivityData" (componentData)="performActionOnChildComponentData($event)"
            *ngIf="showComponent === 'feedback'">
          </ngx-feedback>



          <ngx-mark-attendance
            [externalHeight]="windowFullScreen ? '83vh' : currentActivityData?.qrLink && currentActivityData?.qrShow == 1 ? '30vh' : '57vh'"
            [activity]="currentActivityData" [moduleActivity]="currentClickedActivityData"
            *ngIf="showComponent === 'mark-attendance'" (componentData)="performActionOnChildComponentData($event)">
          </ngx-mark-attendance>
          <ngx-activities-container [courseDataContentInput]="currentActivityData"
            [courseDataSummaryInput]="courseDetailSummary" (componentData)="performActionOnChildComponentData($event)"
            *ngIf="showComponent === 'course'">
          </ngx-activities-container>
          <!-- <ngx-trainer-mark-attendance *ngIf="showComponent === 'course'"></ngx-trainer-mark-attendance> -->
          <!-- <ngx-quiz-feedback-response *ngIf="showComponent === 'course'"></ngx-quiz-feedback-response> -->
          <!-- <ngx-trainer-evaluation-feedback
            [trainerbatch]="courseDetailSummary"
            *ngIf="showComponent === 'course'"
          ></ngx-trainer-evaluation-feedback> -->
          <ngx-assignment-actvity [activity]="currentActivityData"
            (saveEvent)="performActionOnChildComponentData($event)" *ngIf="showComponent === 'assignment'">
          </ngx-assignment-actvity>
          <ngx-trainer-evaluate-activity [externalHeight]="windowFullScreen ? '83vh' : '60vh'"
            [activity]="currentActivityData" (componentData)="performActionOnChildComponentData($event)"
            *ngIf="showComponent === 'trainerEvaluationFeedback'">
          </ngx-trainer-evaluate-activity>

        </div>
      </div>
    </div>
    <div class="discription p-3">
      <div class="d-flex align-items-center justify-content-between">
        <h5 class="font-weight-bold d-flex justify-content-between align-items-center mb-2">
          {{ currentActivityData?.activityName ? currentActivityData?.activityName : "NA" }}
          <!-- <i *ngIf="currentActivityData?.areaId == 34" class="fas fa-check-circle check-icon"></i> -->
        </h5>

        <!-- activityLoaded &&  -->
        <div class="d-flex align-items-center">
          <!-- [dimension]="dimension"  -->
          <!-- [arcWidth]="arcWidth"  -->
          <div class="position-relative h-40-px w-40-px"
            *ngIf="(currentActivityData?.areaId == 5 || currentActivityData?.areaId == 34)
                                && !showBeginContainer && currentActivityData?.formatId == 3 && currentClickedActivityData?.begin && !currentClickedActivityData?.completed">
            <!-- <span
              class="fs-9 d-flex align-items-center justify-content-center position-absolute top-0-px left-0-px w-100 h-100">
              {{currentPdfProgress}}%
            </span>
            <div class="position-absolute top-n18-px left-n18-px">
              <ngx-charts-pie-chart [view]="view" [scheme]="colorScheme" [results]="chartData" [gradient]="gradient"
                [legend]="showLegend" [legendPosition]="legendPosition" [labels]="showLabels" [doughnut]="isDoughnut">
                <ng-template #tooltipTemplate let-model="model">
                  <span>{{currentPdfProgress}}%</span>
                </ng-template>
              </ngx-charts-pie-chart>
            </div> -->
            <circle-progress [percent]="currentPdfProgress" [radius]="15" [outerStrokeWidth]="3" [innerStrokeWidth]="0"
              showInnerStroke="false" [space]="0" [backgroundPadding]="0" [outerStrokeColor]="'#0d6efd'"
              [showSubtitle]="false" [animation]="false" [startFromZero]="true" [showTitle]="true" [titleFontSize]="9"
              [showUnits]="true" [unitsFontSize]="7"></circle-progress>
          </div>
          <div class="act-duration font-weight-normal ml-2">{{ currentActivityData?.activityDuration }}</div>
        </div>
      </div>

      <div class="d-flex justify-content-between align-items-baseline">
        <div>
          <ng-container *ngIf="
            currentActivityData?.multiLanguageList?.length > 0 &&
            !showBeginContainer
          ">
            <h4>Content available in following language(s)</h4>
            <!-- <h4 *ngIf="">Content available in {{currentActivityData?.multiLanguageList[0]?.languageName}} language, Select language to begin:</h4> -->
            <div class="language-bttons-container d-inline-flex">
              <button type="button" class="btn btn-sm mb-2" [disabled]="item?.selected"
                (click)="changeActivityLanguage(item, false)" [ngClass]="{
                'btn-primary': item?.selected,
                'btn-outline-primary': !item?.selected
              }" *ngFor="
                let item of currentActivityData?.multiLanguageList;
                let last = last
              ">
                {{ item?.languageName }}
              </button>
            </div>
            <!-- <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique cum non mollitia voluptatibus aliquid. Voluptatum ab dolore maiores dignissimos excepturi laboriosam fugit officiis fugiat laudantium cum neque, veritatis velit ipsum?</p> -->

          </ng-container>
        </div>
      </div>
      <!-- *ngIf="" -->
      <div class="about-course pt-2" *ngIf="currentActivityData?.description">

        <p [innerHTML]="currentActivityData?.description"></p>

      </div>

      <div class="about-course">
        <ng-container *ngIf="courseDetailSummary?.areaId !== 34 && courseDetailSummary?.areaId !== 13">
          <ng-container *ngIf="courseDetailSummary?.description || courseDetailSummary?.instanceDescription">
            <hr>
            <h4>Course Description</h4>
            <p [innerHTML]="courseDetailSummary?.description || courseDetailSummary?.instanceDescription">
              <!-- {{ currentActivityData?.description ? currentActivityData?.description : "NA" }} -->
            </p>
          </ng-container>
          <hr *ngIf="courseDetailSummary?.courseContentList?.length != 0 ">
          <ng-container
            *ngIf="courseDetailSummary?.courseContentList && courseDetailSummary?.courseContentList?.length != 0">
            <div class="row">
              <div class="col-md-2">

                <h5 *ngIf="courseDetailSummary?.areaId !== 34 && courseDetailSummary?.areaId !== 13">
                  Activities</h5>
              </div>
              <div class="col-md-10">
                <div>
                  <span [hidden]="!item?.webfg" *ngFor="let item of courseDetailSummary?.courseContentList">
                    {{item?.value}} {{item?.key}} &nbsp;
                  </span>
                </div>
                <hr>
              </div>
            </div>

          </ng-container>
          <ng-container *ngIf="courseDetailSummary?.courseCertificate?.isCertavailable !=0">
            <div class="row">
              <div class="col-md-2 pt-2">

                <h5>Certificate</h5>
              </div>
              <div class="col-md-10 pt-2">
                <div>
                  {{courseDetailSummary['courseCertificate']?.isCertavailableMsg}}
                </div>
                <hr>
              </div>
            </div>

          </ng-container>

        </ng-container>

        <!-- send Testimonial -->

        <ng-container
          *ngIf="courseDetailSummary?.content_managers.length>0 && courseDetailSummary?.actionButtons?.buttonsStatus?.TestimonialEnabled">
          <ngx-send-testimonial id="sendTestimonial" [routeFrom]="routeFrom"
            [courseDetail]="courseDetailSummary"></ngx-send-testimonial>

        </ng-container>
        <!-- End Testimonial -->

        <!-- #ElementRefName -->
        <ng-container *ngIf="courseDetailSummary?.rating">
          <div id="leaveRating">
            <ngx-content-rating (ratingChanged)="updateCouseDetails($event)"
              *ngIf="courseDetailSummary?.actionButtons?.buttonsStatus?.GiveRatingEnabled"
              [courseDetail]="courseDetailSummary"></ngx-content-rating>

          </div>
        </ng-container>

        <!-- comment start -->
        <ng-container *ngIf="displaySettings?.CommentCountEnabled && currentUser?.roleId != 7">
          <ngx-comments [currentCourseData]="courseDetailSummary"
            [commentsEnabled]="courseDetailSummary?.actionButtons?.buttonsStatus?.CommentsEnabled"
            [routeFrom]="routeFrom"></ngx-comments>

        </ng-container>
        <!-- <div class="row">
                    <div class="col-md-2">
                        <h5>Comment</h5>
                    </div>
                    <div class="col-md-10">
                        <textarea name="comment" class="form-control" name= "comment" placeholder="Enter comment" minlength="50" maxlength="200" rows="3"></textarea>
                        <div class="d-flex justify-content-end mt-4">
                            <button class="button-sm button btn-fill">Submit</button>
                        </div>
                        <hr>
                        <div class="reviews mb-4" *ngFor="let review of [1,2]">
                            <div class="reviews__inner">
                                <div class="border-bottom">
                                    <div class="d-flex align-items-center mb-1">
                                        <img class="border" src="assets/images/cip/user-image (0).jpg" alt="user-image" width="50" height="50">
                                        <span class="pl-3">
                                            <h5 class="m-0">Michal Clark</h5>
                                            <p class="m-0">3 weeks ago</p>
                                        </span>
                                    </div>
                                    <p class="pt-2">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies
                                        nec, pellentesque eu, pretium quis, sem.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->

        <!-- revivew start -->
        <!-- <div class="reviews row mb-4">
                    <div class="col-md-2">
                        <h5>Reviews</h5>
                    </div>
                    <div class="reviews__inner col-md-10">
                        <div class="rating border-bottom  pb-4 mb-4">
                            <div class="d-flex align-items-center">
                                <div class="rating__head text-center">
                                    <h1 class="mb-1">4.1</h1>
                                    <star-rating [starType]="'svg'" [showHalfStars]="true" [numOfStars]="4" staticColor="warning" rating="3" [readOnly]="true"></star-rating>
                                </div>
                                <div class="ating__data w-100">
                                    <div class="d-flex flex-grow-1 align-items-center" *ngFor="let rate of [1,2,3,4]">
                                        <div class="progress border w-100 mx-1">
                                            <div class="progress-bar" role="progressbar" style.width="20%" aria-valuenow="" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                        <star-rating class="mx-1" [starType]="'svg'" [showHalfStars]="true" [numOfStars]="4" staticColor="warning" rating="3" [readOnly]="true"></star-rating>
                                        <span class="rating-progress">10%</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <ng-container *ngFor="let review of [1,2]">
                            <div class="border-bottom mb-3">
                                <div class="d-flex align-items-center mb-1">
                                    <img class="border" src="assets/images/cip/user-image (0).jpg" alt="user-image" width="50" height="50">
                                    <span class="pl-3">
                                        <h5 class="m-0">Michal Clark</h5>
                                        <p class="m-0">3 weeks ago</p>
                                    </span>
                                </div>
                                <p class="pt-2">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies
                                    nec, pellentesque eu, pretium quis, sem.</p>
                            </div>
                        </ng-container>
                    </div>
                </div> -->
        <!-- End Review -->
      </div>
    </div>
  </div>
  <!-- *ngIf="!(courseDetailSummary?.areaId == 34 || courseDetailSummary?.areaId == 13)
        ||  (courseDetailSummary?.areaId == 34 && courseDetailSummary?.playListId)" -->
  <div class="module border-left" [ngClass]="{ 'cip-module' : currentUser?.roleId == 7}" *ngIf="(courseDetailSummary?.areaId !== 34 && courseDetailSummary?.areaId !== 13)
        || (courseDetailSummary?.areaId == 34 && courseDetailSummary?.playListId)">
    <div class="module-inner" *ngFor="let item of moduleList; let moduleIndex = index">
      <div class="module-name cursor-p" [ngClass]="{ 'module-active': moduleIndex === currentModuleIndex }"
        (click)="showdiv(moduleIndex)">
        <h3 class="m-0">
          <span>
            <i class="fas fa-check-circle pr-2 check-icon" *ngIf="item?.isModuleCompFlag == 1">
            </i>
            {{ item.moduleName }}
          </span>
          <span class="arrow" *ngIf="item?.isDisable == 'N' && !item?.show">
            <i class="fas fa-chevron-down"></i></span>
          <span class="arrow" *ngIf="item?.isDisable == 'N' && item?.show">
            <i class="fas fa-chevron-up"></i></span>
          <span class="arrow" *ngIf="item?.isDisable == 'Y'"><i class="fas fa-lock"></i></span>
        </h3>
        <a class="d-flex">
          <label *ngIf="item.isDisable == 'Y'" class="m-0">Start Date {{ item?.mStartDate }}</label>
        </a>
      </div>
      <div class="activity-list" *ngIf="item?.isDisable == 'N'">
        <ul class="p-0 m-0" *ngIf="item.show">
          <li class="cursor-p d-flex" [ngClass]="{
              'selected-activity':
                currentActIndex === activityIndex &&
                moduleIndex === currentModuleIndex
            }" *ngFor="let activity of item?.list; let activityIndex = index"
            (click)="showBeginBox(activity, moduleIndex, activityIndex)">
            <div class="d-flex justify-content-between align-items-center w-100">
              <span class="act-icon-text d-flex justify-content-between align-items-center">
                <!-- <img class="act-icon" src="assets/icon-svg/outline/{{ activity?.actIcon }}.svg" alt="" srcset="" /> -->
                <!-- }"
            *ngFor="let activity of item?.list; let activityIndex = index"
            (click)="showBeginBox(activity, moduleIndex, activityIndex)"
          > -->
                <div class="d-flex justify-content-between align-items-center w-100">
                  <span class="act-icon-text d-flex justify-content-between align-items-center">
                    <img class="act-icon" src="assets/icon-svg/activity-icons/outline/{{
                    activity?.actIcon
                  }}.svg" alt="" srcset="" />
                    <div class="act-nameMsg">
                      <label class="act-name m-0 cursor-p">{{
                        activity?.activityName
                        }}</label>
                      <!-- <span class="message d-block"
                                        *ngIf="!activity?.completed && !activity?.wait">Video</span> -->
                      <span class="message d-block" *ngIf="activity?.completed">Completed on
                        {{ activity?.completedDate | date: "dd-MMM-yyyy" }}</span>
                      <span class="message d-block" *ngIf="activity?.wait">Complete activity
                        "{{ activity?.dependentActName }}" from
                        module "{{ activity?.dependentModName }}" to unlock</span>
                      <span class="message d-block" *ngIf="
                      activity?.begin &&
                      (activity?.byTrainer == 1 && currentUser.roleId == 8) &&
                      !activity?.completed
                    ">
                        {{ activity?.byTrainerMsg}}</span>
                      <span class="message d-block" *ngIf="
                      activity?.begin &&
                     (activity?.byTrainer == 1 && currentUser.roleId == 8) &&
                      activity?.completed
                    ">
                        Attendance is marked by trainer</span>
                      <span class="message d-block"
                        *ngIf="activity?.begin &&  ((activity?.byTrainer == 0 && currentUser.roleId == 8) || currentUser.roleId == 7)">
                        {{ activity?.actFormatName }}</span>
                    </div>
                  </span>
                  <span>
                    <i *ngIf="activity?.completed" class="fas fa-check-circle check-icon"></i>
                    <i *ngIf="
                    activity?.wait ||
                    ( (activity?.byTrainer == 1 && currentUser.roleId == 8) && !activity?.completed)
                  " class="fas fa-lock"></i>
                  </span>
                </div>
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!-- <div class="" *ngIf="courseDetailSummary?.areaId == 34"></div> -->
</div>
<!-- modal pop-up -->
<div class="pop-back" *ngIf="showPopup" (click)="closePopup()"></div>
<div class="fade" *ngIf="showPopup" [ngClass]="{ 'show show-modal': showPopup }">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" *ngIf="share">Share this course</h4>
        <h4 class="modal-title" *ngIf="rating">Rating</h4>
        <h4 class="modal-title" *ngIf="quizPass">Quiz password</h4>
        <h4 class="modal-title" *ngIf="quizIntro">Instruction</h4>
        <button type="button" class="close" (click)="closePopup()">
          &times;
        </button>
      </div>
      <div class="modal-body">
        <div class="share-modal" *ngIf="share">
          <div class="input-group mb-3">
            <input type="text" class="form-control" aria-label="Recipient's username" aria-describedby="basic-addon2" />
            <div class="input-group-append">
              <button class="btn btn-outline-secondary" type="button">
                Copy
              </button>
            </div>
          </div>
          <div class="shareIcon d-flex justify-content-around">
            <i class="fab fa-twitter"></i>
            <i class="fab fa-facebook-f"></i>
            <i class="fas fa-envelope"></i>
          </div>
        </div>
        <div class="rating-modal text-center" *ngIf="rating">
          <!-- <star-rating value="0" totalstars="5" checkedcolor="#256DDA" uncheckedcolor="gray" size="50px"
                        readonly="false" (rate)="onRate($event)"></star-rating> -->
          <textarea *ngIf="ratingText" name="feedback" cols="30" rows="5"></textarea>
          <button class="pop-btn mt-2" *ngIf="ratingText">Save</button>
        </div>
        <div class="quiz-pass-modal text-right" *ngIf="quizPass">
          <input type="password" [(ngModel)]="password" class="form-control" placeholder="Password" />
          <p *ngIf="passWrong">
            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>Password is wrong
          </p>
          <button class="pop-btn mt-2" (click)="onSubmitPassword()">
            Save
          </button>
        </div>
        <div class="quiz-intro-modal text-right" *ngIf="quizIntro">
          <!-- <h5 class="text-left">Title</h5> -->
          <!-- <p class="text-left">Lorem Ipsum is simply dummy text of the printin</p> -->
          <p class="text-left">
            {{ checkResponse?.instructions ? checkResponse?.instructions : "NA" }}
          </p>
          <button class="pop-btn" (click)="onSubmitInstruction()">Save</button>
        </div>
      </div>
    </div>
  </div>
</div>


<ngx-right-side-drawer *ngIf="showBulkAttendace" [config]="configUploadPopup" (actionEvent)="performActionsNew($event)">
  <ngx-excel-upload-and-preview [configExcelUpload]="excelUploadConfig"
    (getEvents)="performActionOnEvents($event)"></ngx-excel-upload-and-preview>
</ngx-right-side-drawer>