import {
  Component,
  OnInit,
  OnChanges,
  ViewEncapsulation,
  Output,
  Input,
  EventEmitter,
} from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { ActivityService } from "../../../../providers/activity/activity.service";
import { ModuleService } from "../../../../providers/module/module.service";
import { ToasterService } from "../../../../providers/toaster/toaster.service";
import {
  ImageViewerConfig,
  CustomEvent,
} from "../../../../components/image-viewer/image-viewer-config.model";
@Component({
  selector: "ngx-quiz-review",
  templateUrl: "./quiz-review.component.html",
  styleUrls: ["./quiz-review.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class QuizReviewComponent implements OnInit, OnChanges {
  @Output() componentData = new EventEmitter();
  @Input() courseDataContentInput: any = {};
  @Input() courseDataSummaryInput: any = {};
  @Input() quizSubmitResponse: any = {};

  courseDataSummary: any;
  courseDataContent: any;
  courseTitle: any;
  userdetail: any;
  // activityData: any;
  displayQues: any = {};
  displayQues1: any = {};
  displayOptns: any = {};
  displayNav: boolean = false;
  rightAns: any = 0;
  quizScore: any = 0;
  quesTotalScore = 0;
  quesTotalPenalty = 0;
  page_no: number = 0;
  total_page: number = 0;
  behaviour: any;
  reviewFlag: boolean = false;
  displayConfirm: boolean = false;
  last = false;
  answerSelected = false;
  selectedAnswerData;
  multiSelectedAnswerData;
  currentQuestionIndex: any = 0;
  expand = false;

  currentQuestionTypeId;
  selectedAnswer: any;

  answerConfirmed = false;
  singleSelectAns: any;
  multiSelectAns: any = [];
  showfb = false;
  feedbacktext: any;
  quiz1: any = [
    {
      id: 1,
      list: [
        {
          answers:
            "2##https://bhaveshedgetest.s3.ap-south-1.amazonaws.com/512.png#scdvfb#1#1|1#zxhjkjl##stkkfgf#1#1",
          contentRef:
            "https://bhaveshedgetest.s3.ap-south-1.amazonaws.com/alan.png",
          depQuestionId: 0,
          depQuestionValue: "",
          id: 1,
          optionList: [
            {
              optionMatch: "scdvfb",
              optionRef:
                "https://bhaveshedgetest.s3.ap-south-1.amazonaws.com/512.png",
              optionText: "",
              optionType: "2",
              penalty: "1",
              sFlag: false,
              score: "1",
              expand: false,
            },
            {
              optionMatch: "stkkfgf",
              optionRef: "",
              optionText: "zxhjkjl",
              optionType: "1",
              penalty: "1",
              sFlag: false,
              score: "1",
              expand: false,
            },
          ],
          matchPairList: [
            {
              id: 1,
              name: "match 1",
              status: false,
            },
            {
              id: 2,
              name: "match 2",
              status: false,
            },
            {
              id: 3,
              name: "match 3",
              status: false,
            },
            {
              id: 4,
              name: "match 4",
              status: false,
            },
          ],
          points: null,
          qName: "Demo 44",
          qSelectId: "1",
          qSelectLabel: "Pre-Made",
          qTypeId: "2",
          qTypeLabel: "Matching",
          qid: 1,
          qorder: 2,
          qqid: 11,
          questionId: 8,
          quizqId: 8,
          sName: "Section 2",
          sid: 2,
          tenantId: 1,
          timemodified: "2019-03-28T10:19:28.000Z",
          usermodified: 4,
          visible: 1,
        },
      ],
      qqid: 11,
      answerConfirmed: false,
    },
    {
      id: 2,
      list: [
        {
          // tslint:disable-next-line:max-line-length
          answers:
            "1#1#1##0#1#0|1#2##https://bhaveshedgetest.s3.ap-south-1.amazonaws.com/calendar-icon.png#0#1#0|1#1#adssdvnsdnkd##0#0#1|1#2##https://bhaveshedgetest.s3.ap-south-1.amazonaws.com/calendar-icon.png#0#1#0",
          contentRef: null,
          depQuestionId: null,
          depQuestionValue: "",
          id: 1,
          optionList: [
            {
              optionText: "sequence Option 1",
              sequenceNo: 1,
              score: 1,
              penalty: 0,
              sFlag: false,
              cFlag: false,
            },
            {
              optionText: "sequence Option 2",
              sequenceNo: 2,
              score: 1,
              penalty: 0,
              sFlag: false,
              cFlag: false,
            },
            {
              optionText: "sequence Option 3",
              sequenceNo: 3,
              score: 1,
              penalty: 0,
              sFlag: false,
              cFlag: false,
            },
            {
              optionText: "sequence Option 4",
              sequenceNo: 4,
              score: 1,
              penalty: 0,
              sFlag: false,
              cFlag: false,
            },
          ],
          points: 2,
          qName: "Demo Question",
          qSelectId: "1",
          qSelectLabel: "Pre-Made",
          qTypeId: "3",
          qTypeLabel: "Choice",
          qid: 1,
          qorder: 0,
          qqid: 1,
          questionId: 1,
          quizqId: 1,
          sName: "Section 2",
          sid: 2,
          tenantId: 1,
          timemodified: "2019-03-28T10:19:28.000Z",
          usermodified: 4,
          visible: 1,
        },
      ],
      qqid: 1,
      answerConfirmed: false,
    },
    {
      id: 1,
      list: [
        {
          answers: "demo final 2#0#12#0|demo final 22#1#1#0",
          contentRef: "",
          depQuestionId: 0,
          depQuestionValue: "0",
          id: 4,
          optionList: [
            {
              cFlag: false,
              optionText: "demo final 2",
              penalty: "0",
              sFlag: false,
              score: "12",
              sequenceNo: "0",
            },
            {
              cFlag: false,
              optionText: "demo final 22",
              penalty: "0",
              sFlag: false,
              score: "1",
              sequenceNo: "1",
            },
          ],
          points: 13,
          qName: "demo final 2",
          qSelectId: "1",
          qSelectLabel: "Pre-Made",
          qTypeId: "3",
          qTypeLabel: "Sequence",
          qid: 1,
          qorder: 0,
          qqid: 13,
          questionId: 15,
          quizqId: 15,
          sName: "Section 2",
          sid: 2,
          tenantId: 1,
          timemodified: "2019-04-11T11:34:09.000Z",
          usermodified: 4,
          visible: 1,
        },
      ],
      qqid: 13,
      answerConfirmed: false,
    },
    {
      id: 4,
      list: [
        {
          answers:
            "2#1#wsfsegrh##3#0#1|2#1#fdasgdsd##2#0#1|2#1#wsafdvdsds##7#0#1",
          contentRef:
            "https://bhaveshedgetest.s3.ap-south-1.amazonaws.com/category.jpg",
          depQuestionId: null,
          depQuestionValue: null,
          id: 6,
          optionList: [
            {
              cFlag: false,
              choiceType: "2",
              isCorrect: "1",
              penalty: "0",
              qOption: "wsfsegrh",
              qOptionRef: "",
              qOptionType: "1",
              sFlag: false,
              score: "3",
            },
            {
              cFlag: false,
              choiceType: "2",
              isCorrect: "1",
              penalty: "0",
              qOption: "fdasgdsd",
              qOptionRef: "",
              qOptionType: "1",
              sFlag: false,
              score: "2",
            },
            {
              cFlag: false,
              choiceType: "2",
              isCorrect: "1",
              penalty: "0",
              qOption: "wsafdvdsds",
              qOptionRef: "",
              qOptionType: "1",
              sFlag: false,
              score: "7",
            },
          ],
          points: 12,
          qName: "Demo 44",
          qSelectId: "1",
          qSelectLabel: "Pre-Made",
          qTypeId: "1",
          qTypeLabel: "Choice",
          qid: 1,
          qorder: 1,
          qqid: 3,
          questionId: 3,
          quizqId: 3,
          sName: "Section 2",
          sid: 2,
          tenantId: 1,
          timemodified: "2019-04-11T13:28:00.000Z",
          usermodified: 4,
          visible: 1,
        },
      ],
      qqid: 3,
      answerConfirmed: false,
    },
  ];
  isReviewEn = true;
  activityData: any = {
    activityId: "",
    activityNo: "",
    activityTitle: "",
    activitycompletionid: "",
    attId: "",
    attempt: "",
    behaviorName: "",
    behaviour: "",
    closingDate: "",
    completionCriteria: "",
    completionstatus: "",
    contenttime: "",
    contentwatchedtime: "",
    corsQuizId: "",
    courseId: "",
    creditAllocId: "",
    dba: "",
    delayBetweenAttempts: "",
    delayTime: "",
    dependentActId: "",
    employeeId: "",
    enrolId: "",
    fileinfo: "",
    moduleId: "",
    navType: "",
    navigation: "",
    openDate: "",
    orderType: "",
    points: "",
    qOPId: "",
    qname: "",
    qpassword: "",
    questionsOnPage: "",
    quizCompCriteria: "",
    quizId: "",
    quizOrder: "",
    scoreSelection: "",
    show: "",
    ssName: "",
    tags: "",
    tenantId: "",
    timelimit: "",
    viewed: "",
  };

  quiz: any = [
    {
      id: "",
      list: [
        {
          answers: "",
          contentRef: "",
          depQuestionId: "",
          depQuestionValue: "",
          id: "",
          optionList: [
            {
              optionMatch: "",
              optionRef: "",
              optionText: "",
              optionType: "",
              penalty: "",
              sFlag: "",
              score: "",
              expand: "",
            },
          ],
          matchPairList: [
            {
              id: "",
              name: "",
              status: "",
            },
          ],
          points: "",
          qName: "",
          qSelectId: "",
          qSelectLabel: "",
          qTypeId: "",
          qTypeLabel: "",
          qid: "",
          qorder: "",
          qqid: "",
          questionId: "",
          quizqId: "",
          sName: "",
          sid: "",
          tenantId: "",
          timemodified: "",
          usermodified: "",
          visible: "",
        },
      ],
      qqid: "",
      answerConfirmed: "",
    },
  ];

  questionProgress = 0;

  defaultMatchLabel = "Tap to select a matching tile";
  selectedMatchAns = "";

  currentCourseData: any = {
    approvalStatus: "",
    cat_id: "",
    category: "",
    compStatus: "",
    completed_courses: "",
    courseDesc: "",
    courseTitle: "",
    courseType: "",
    courseType2: "",
    cpoints: "",
    endDate: "",
    enrolId: "",
    id: "",
    isBookmark: "",
    isDislike: "",
    isLike: "",
    nextStage: "",
    noDislikes: "",
    noLikes: "",
    noOfmodules: "",
    percComplete: "",
    startDate: "",
    total_courses: "",
    typeImage: "",
  };

  quizSubmitRes: any;

  timeInSeconds: any;
  time: any;
  runTimer: any;
  hasStarted: any;
  hasFinished: any;
  remainingTime: any;
  displayTime: any;

  quizCompRes: any;
  notAnswered = false;
  resultData: any;
  showScore: boolean;

  constructor(
    public moduleService: ModuleService,
    public activityService: ActivityService,
    // private router: Router,
    // private routes: ActivatedRoute,
    // public spinner: NgxSpinnerService,
    public toaster: ToasterService,
    private sanitizer: DomSanitizer
  ) {
    // this.quiz = this.quiz1;
    // this.total_page = this.quiz.length;

    console.log("RESULTDATA--->", this.resultData);

    // this.courseDataContent = activityService.getDataContent();
    // this.courseDataSummary = activityService.getDataSummary();
    // if (localStorage.getItem('userdetail')) {
    //   this.userdetail = JSON.parse(localStorage.getItem('userdetail'));
    // }
  }

  ngOnInit() {
    this.showScore = false;
    this.getUserData();
  }

  getQuizQuestions() {
    if (this.activityData) {
      // this.spinner.show();
      const event = {
        loaderFlag: true,
        responseData: null,
        errorFlag: false,
        // 'showQuizReview': true,
        // 'showQuiz': false,
        showComponent: "quiz-review",
        showAutoPlayConatiner: false
      };
      this.componentData.emit(event);
      this.getSubmittedQuizReview(this.activityData, (result) => {
        if (result.type === true && result.data) {
          this.quiz = result.data;
          console.log("Get quiz questions for review --->", this.quiz);
          if (this.quiz.length === 1) {
            this.last = true;
          }
          this.showScore = true;
          // this.spinner.hide();
          const event = {
            loaderFlag: false,
            responseData: null,
            errorFlag: false,
            // 'showQuizReview': true,
            // 'showQuiz': false,
            showComponent: "quiz-review",
            showAutoPlayConatiner: false
          };
          this.componentData.emit(event);
        } else {
          const event = {
            loaderFlag: false,
            responseData: null,
            errorFlag: false,
            // 'showQuizReview': true,
            // 'showQuiz': false,
            showComponent: "quiz-review",
            showAutoPlayConatiner: false
          };
          this.componentData.emit(event);
          // this.router.navigate(['../../../learn'], {
          //   relativeTo: this.routes
          // });
          // this.toastr
          //   .warning("Something went wrong please try again later", "Warning!")
          //   .onHidden.subscribe(() => this.toastr.clear());
          this.presentAlert(
            "Something went wrong please try again later",
            "Warning!"
          );
        }
      });
      // this.activityService.getQuizForReview(param)
      //   .then((result: any) => {
      //     if (result.data !== undefined) {

      //       // let timeSpent = 0;
      //       // this.total_page = this.quiz.length;
      //       // if (this.activityData.contentwatchedtime !== null) {
      //       //   timeSpent = this.activityData.contentwatchedtime;
      //       // } else {
      //       //   timeSpent = this.timeInSeconds - this.remainingTime;
      //       // }
      //       // this.makeQuizCompDataReady(null, this.timeInSeconds, timeSpent);
      //       this.showScore = true;
      //       // this.spinner.hide();
      //       const event = {
      //         'loaderFlag': false,
      //         'responseData': null,
      //         'errorFlag': false,
      //         // 'showQuizReview': true,
      //         // 'showQuiz': false,
      //         'showComponent': 'quiz-review',
      //       };
      //       this.componentData.emit(event);
      //     } else {
      //       // this.spinner.hide();
      //       const event = {
      //         'loaderFlag': false,
      //         'responseData': null,
      //         'errorFlag': false,
      //         // 'showQuizReview': true,
      //         // 'showQuiz': false,
      //         'showComponent': 'quiz-review',
      //       };
      //       this.componentData.emit(event);
      //       // this.router.navigate(['../../../learn'], {
      //       //   relativeTo: this.routes
      //       // });
      //       this.toastr.warning('Something went wrong please try again later', 'Warning!').onHidden.subscribe(()=>
      //       this.toastr.clear());
      //     }
      //   })
      //   .catch(result => {
      //     // this.spinner.hide();
      //     const event = {
      //       'loaderFlag': false,
      //       'responseData': null,
      //       'errorFlag': false,
      //       // 'showQuizReview': true,
      //       // 'showQuiz': false,
      //       'showComponent': 'quiz-review',
      //     };
      //     this.componentData.emit(event);
      //     console.log('ServerResponseError :', result);
      //     this.toastr.warning('Something went wrong please try again later', 'Warning!').onHidden.subscribe(()=>
      //     this.toastr.clear());
      //   });
    } else {
      console.log("Activity data is not available", this.activityData);
    }
  }

  getSubmittedQuizReview(quizData, cb) {
    const questionData = {
      qId: quizData.quizId,
      tId: quizData.tenantId,
      qOrder: quizData.quizOrder,
      qOPId: quizData.qOPId,
      empId: this.userdetail.eid,
      enId: quizData.enrolId,
      actId: quizData.activityId,
    };
    this.activityService.getQuizSubmitReview(questionData).then(
      (result) => {
        cb(result);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  /**************** TIMER COMPONENT START ***************/

  makeTimerDataReady(activityDuration) {
    if (activityDuration) {
      const resStr = activityDuration.split(":");
      // const formattedDuration = new Date();
      // formattedDuration.setHours(resStr[0], resStr[1], resStr[2]);
      // console.log('Quiz Duration ', formattedDuration);
      const seconds = +resStr[0] * 60 * 60 + +resStr[1] * 60 + +resStr[2];
      // console.log('Quiz Duration in seconds ', seconds);
      return seconds;
    } else {
      return null;
    }
  }

  // initTimer() {
  //   if (!this.timeInSeconds) {
  //     if (this.activityData.activityDuration) {
  //       this.timeInSeconds = this.makeTimerDataReady(
  //         this.activityData.activityDuration
  //       );
  //     } else {
  //       this.timeInSeconds = 1800;
  //     }
  //   }
  //   this.time = this.timeInSeconds;
  //   this.runTimer = false;
  //   this.hasStarted = false;
  //   this.hasFinished = false;

  //   if (this.activityData.contentwatchedtime !== null) {
  //     this.remainingTime =
  //       this.timeInSeconds - this.activityData.contentwatchedtime;
  //   } else {
  //     this.remainingTime = this.timeInSeconds;
  //   }

  //   this.displayTime = this.getSecondsAsDigitalClock(this.remainingTime);
  //   console.log(this.displayTime);
  // }

  // timerTick() {
  //   setTimeout(() => {
  //     this.remainingTime--;
  //     this.displayTime = this.getSecondsAsDigitalClock(this.remainingTime);
  //     if (this.remainingTime > 0) {
  //       this.timerTick();
  //     } else {
  //       this.hasFinished = true;
  //       // this.result();
  //     }
  //   }, 1000);
  // }

  // getSecondsAsDigitalClock(inputSeconds: number) {
  //   const sec_num = parseInt(inputSeconds.toString(), 10); // don't forget the second param
  //   const hours = Math.floor(sec_num / 3600);
  //   const minutes = Math.floor((sec_num - hours * 3600) / 60);
  //   const seconds = sec_num - hours * 3600 - minutes * 60;
  //   let hoursString = '';
  //   let minutesString = '';
  //   let secondsString = '';
  //   hoursString = hours < 10 ? '0' + hours : hours.toString();
  //   minutesString = minutes < 10 ? '0' + minutes : minutes.toString();
  //   secondsString = seconds < 10 ? '0' + seconds : seconds.toString();
  //   return hoursString + ':' + minutesString + ':' + secondsString;
  //   // return  minutesString + ':' + secondsString;
  // }
  /**************** TIMER COMPONENT END ***************/

  getUserData() {
    // this.userdetail = JSON.parse(localStorage.getItem("currentUser"));
    if (localStorage.getItem("currentUser")) {
      this.userdetail = JSON.parse(localStorage.getItem("currentUser"));
      // this.tenantId = this.userdetail.tenantId;
    }
  }

  // dragStart(event) {
  //   event.dataTransfer.setData('Text', event.target.id);
  // }

  // dragging(event) {
  //   console.log('Button is being dragged');
  // }

  // allowDrop(event) {
  //   event.preventDefault();
  //   // event.target.style.border = 'none';
  // }

  // drop(event, mainData, index, stat) {
  //   event.preventDefault();
  //   const data = event.dataTransfer.getData('Text');
  //   // const data1 = event.currentTarget.outerHTML;
  //   if (event.currentTarget.outerText == '' || event.currentTarget.outerText == 'Drop Matching Tiles') {
  //     event.target.appendChild(document.getElementById(data));
  //     // document.getElementById('demo').innerHTML = 'The p element was dropped.';
  //     console.log('The p element was dropped.', event);
  //     if (stat == 1) {
  //       mainData.matchPairList[index].status = 0;
  //       mainData.matchPairList[index].selected = '';
  //     } else {
  //       mainData.matchPairList[index].status = 1;
  //       mainData.matchPairList[index].selected =
  //         event.toElement.firstElementChild.innerText;
  //     }
  //   } else {
  //     this.toastr.error('Cannot move the tile to the dropped place.', 'Error Occurred');
  //   }
  //   // this.selectOption(mainData, index);
  // }

  // selectOption(QuesIndex, mainData, index, optData) {
  //   // console.log('SELECT');
  //   if (mainData.qTypeId == '1') {
  //     mainData.uSelected = '';
  //     for (var i = 0; i < mainData.optionList.length; i++) {
  //       if (mainData.optionList[i].choiceType == '1') {
  //         mainData.optionList[i].sFlag = false;
  //       }
  //     }
  //     if (mainData.optionList[index].sFlag == true) {
  //       mainData.optionList[index].sFlag = false;
  //     } else {
  //       mainData.optionList[index].sFlag = true;
  //     }
  //     if (mainData.optionList[index].qOption) {
  //       mainData.uSelected = mainData.optionList[index].qOption;
  //       if (mainData.optionList[index].isCorrect == '0') {
  //         mainData.optionList[index].cFlag = 'false';
  //       } else {
  //         mainData.optionList[index].cFlag = 'true';
  //       }
  //     } else {
  //       mainData.uSelected = mainData.optionList[index].qOptionRef;
  //       if (mainData.optionList[index].isCorrect == '0') {
  //         mainData.optionList[index].cFlag = 'false';
  //       } else {
  //         mainData.optionList[index].cFlag = 'true';
  //       }
  //     }
  //     console.log('mainData--->', mainData);
  //     console.log('INDEX--->', index);
  //   } else if (mainData.qTypeId == '2') {
  //     for (var i = 0; i < mainData.optionList.length; i++) {
  //       if (mainData.matchPairList[i].status == 1) {
  //         mainData.optionList[index].sFlag = true;
  //         if (
  //           mainData.matchPairList[i].selected ==
  //           mainData.optionList[i].optionMatch
  //         ) {
  //           mainData.optionList[index].cFlag = 'true';
  //         } else {
  //           mainData.optionList[index].cFlag = 'false';
  //         }
  //       } else {
  //         mainData.optionList[index].sFlag = false;
  //       }
  //     }
  //   } else if (mainData.qTypeId == '3') {
  //     console.log('mainData--->', mainData);
  //     for (var i = 0; i < mainData.optionList.length; i++) {
  //       var temp = parseInt(mainData.optionList[i].sequenceNo);
  //       if (i == temp) {
  //         mainData.optionList[i].cFlag = 'true';
  //       } else {
  //         mainData.optionList[i].cFlag = 'false';
  //       }
  //     }
  //   }
  // }

  // selectOption1(mainData, index) {
  //   console.log('mainData===>', mainData);
  //   console.log('index===>', index);
  // }
  // makeActivityLogDataReady(currentActivity) {
  //   const params = {
  //     area: this.areaenum.activity,
  //     action: this.logenum.viewed,
  //     target: "activity",
  //     instanceid: currentActivity.activityId,
  //     other: "",
  //     crud: "r",
  //   };
  //   this.saveLogs(params);
  // }

  // saveLogs(params) {
  //   this.logservice.saveLogged(params).then(
  //     (res) => {
  //       console.log(res);
  //     },
  //     (err) => {
  //       console.log(err);
  //     }
  //   );
  // }

  makeAnsweredQuestionsDataReady(questionData) {
    const answeredQuesData = {
      question: "",
      answer: "",
      score: "",
      penalty: "",
    };
  }

  // checkIfQuestionIsMandatory(questionsData) {
  //   if (questionsData.isMandatory === 1) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  // makeQuestionAnsDataReday(questionsData, quizCompFlag) {
  //   console.log('currentPageQuesData ', questionsData);
  //   const questions = questionsData.list;
  //   let quesIds = '';
  //   const quesLen = '';
  //   let quesAnswers = '';
  //   let quesScore = '';
  //   let quesPenalty = '';
  //   // let quesTotalScore = 0;
  //   // let quesTotalPenalty = 0;
  //   let singleAnswers = '';
  //   const multiAnswers = '';
  //   let matchOptions = '';
  //   const matchAnswers = '';
  //   let sequenceAnswers = '';

  //   let allOptionValues = '';
  //   let ansConfirmed = '';
  //   let ansCorrect = '';
  //   let sectionIds = '';

  //   let answeredQues = '';
  //   let answeredFlag = true;

  //   if (questions.length > 0) {
  //     for (let i = 0; i < questions.length; i++) {
  //       const question = questions[i];
  //       if (quesIds !== '') {
  //         quesIds += '$';
  //       }
  //       if (
  //         String(question.questionId) !== '' &&
  //         String(question.questionId) !== 'null'
  //       ) {
  //         quesIds += question.questionId;
  //       }

  //       if (quesScore !== '') {
  //         quesScore += '$';
  //       }

  //       if (quesPenalty !== '') {
  //         quesPenalty += '$';
  //       }

  //       if (sectionIds !== '') {
  //         sectionIds += '$';
  //       }

  //       if (ansCorrect !== '') {
  //         ansCorrect += '$';
  //       }

  //       if (ansConfirmed !== '') {
  //         ansConfirmed += '$';
  //       }

  //       if (answeredQues !== '') {
  //         answeredQues += '$';
  //       }

  //       if (allOptionValues !== '') {
  //         allOptionValues += '$';
  //       }

  //       if (quesAnswers !== '') {
  //         quesAnswers += '$';
  //       }
  //       if (question.qTypeId === '1') {
  //         let optScore = 0;
  //         let optPenalty = 0;
  //         let optionValues = '';
  //         const selectedOptArr = [];
  //         const correctOptArr = [];
  //         for (let j = 0; j < question.optionList.length; j++) {
  //           if (optionValues !== '') {
  //             optionValues += '|';
  //           }

  //           if (singleAnswers !== '') {
  //             singleAnswers += '#';
  //           }

  //           if (question.optionList[j].sFlag) {
  //             selectedOptArr.push(question.optionList[j]);

  //             if (question.optionList[j].qOption) {
  //               if (
  //                 String(question.optionList[j].qOption) !== '' &&
  //                 String(question.optionList[j].qOption) !== 'null'
  //               ) {
  //                 singleAnswers += question.optionList[j].qOption;
  //               }
  //             } else {
  //               if (
  //                 String(question.optionList[j].qOptionRef) !== '' &&
  //                 String(question.optionList[j].qOptionRef) !== 'null'
  //               ) {
  //                 singleAnswers += question.optionList[j].qOptionRef;
  //               }
  //             }
  //             if (question.optionList[j].isCorrect === '1') {
  //               question.optionList[j].cFlag = 'true';
  //               if (
  //                 String(question.optionList[j].score) !== '' &&
  //                 String(question.optionList[j].score) !== 'null'
  //               ) {
  //                 optScore += Number(question.optionList[j].score);
  //               }
  //               this.quesTotalScore += Number(question.optionList[j].score);

  //               correctOptArr.push(question.optionList[j]);
  //             } else {
  //               question.optionList[j].cFlag = 'false';
  //               if (
  //                 String(question.optionList[j].penalty) !== '' &&
  //                 String(question.optionList[j].penalty) !== 'null'
  //               ) {
  //                 optPenalty += Number(question.optionList[j].penalty);
  //               }
  //               this.quesTotalPenalty += Number(question.optionList[j].penalty);
  //             }
  //           }

  //           let optionValue = '';
  //           // if (optionValue !== '') {
  //           //   optionValue += '#';
  //           // }
  //           optionValue += question.optionList[j].choiceType;
  //           optionValue += '#';
  //           optionValue += question.optionList[j].qOptionType;
  //           optionValue += '#';
  //           optionValue += question.optionList[j].qOption;
  //           optionValue += '#';
  //           optionValue += question.optionList[j].qOptionRef;
  //           optionValue += '#';
  //           optionValue += question.optionList[j].score;
  //           optionValue += '#';
  //           optionValue += question.optionList[j].penalty;
  //           optionValue += '#';
  //           optionValue += question.optionList[j].feedback;
  //           optionValue += '#';
  //           optionValue += question.optionList[j].isCorrect;
  //           optionValue += '#';
  //           optionValue += question.optionList[j].sFlag;
  //           optionValue += '#';
  //           optionValue += question.optionList[j].cFlag;

  //           optionValues += optionValue;
  //         }
  //         quesAnswers += singleAnswers;
  //         quesScore += optScore;
  //         quesPenalty += optPenalty;
  //         allOptionValues += optionValues;
  //         sectionIds += question.sid;

  //         if (correctOptArr.length === 0) {
  //           ansCorrect += 0;
  //           answeredQues += 0;
  //         } else if (selectedOptArr.length === correctOptArr.length) {
  //           ansCorrect += 2;
  //           answeredQues += 1;
  //         } else {
  //           ansCorrect += 1;
  //           answeredQues += 1;
  //         }

  //         if (selectedOptArr.length === 0) {
  //           answeredFlag = false;
  //           question.Answered = 0;
  //         } else {
  //           answeredFlag = true;
  //           question.Answered = 1;
  //         }

  //         if (!answeredFlag && this.checkIfQuestionIsMandatory(question)) {
  //           // this.presentMandatoryQuestionPrompt();
  //           this.toastr.warning(
  //             'Please answer all mandatory questions that are marked by ' * '.',
  //             'Warning!!!'
  //           );
  //           this.notAnswered = this.checkIfQuestionIsMandatory(question);
  //           return;
  //         }
  //       }
  //       if (question.qTypeId === '2') {
  //         let optScore = 0;
  //         let optPenalty = 0;
  //         let optionValues = '';
  //         const selectedOptArr = [];
  //         const correctOptArr = [];
  //         for (let j = 0; j < question.optionList.length; j++) {
  //           if (optionValues !== '') {
  //             optionValues += '|';
  //           }

  //           const option = question.optionList[j];

  //           if (option.expand === true) {
  //             option.expand = false;
  //           }

  //           if (matchOptions !== '') {
  //             matchOptions += '$';
  //           }
  //           if (option.optionText) {
  //             if (
  //               String(option.optionText) !== '' &&
  //               String(option.optionText) !== 'null'
  //             ) {
  //               matchOptions += option.optionText;
  //               for (let k = 0; k < option.matchPairList.length; k++) {
  //                 if (option.matchPairList[k].selected) {
  //                   selectedOptArr.push(option);

  //                   if (matchOptions !== '') {
  //                     matchOptions += '#';
  //                   }
  //                   if (
  //                     String(option.matchPairList[k].name) !== '' &&
  //                     String(option.matchPairList[k].name) !== 'null'
  //                   ) {
  //                     matchOptions += option.matchPairList[k].name;
  //                   }

  //                   if (option.optionMatch === option.matchPairList[k].name) {
  //                     option.cFlag = 'true';

  //                     correctOptArr.push(option);
  //                     if (
  //                       String(question.optionList[j].score) !== '' &&
  //                       String(question.optionList[j].score) !== 'null'
  //                     ) {
  //                       optScore += Number(question.optionList[j].score);
  //                     }
  //                     this.quesTotalScore += Number(
  //                       question.optionList[j].score
  //                     );
  //                   } else {
  //                     option.cFlag = 'false';
  //                     if (
  //                       String(question.optionList[j].penalty) !== '' &&
  //                       String(question.optionList[j].penalty) !== 'null'
  //                     ) {
  //                       optPenalty += Number(question.optionList[j].penalty);
  //                     }
  //                     this.quesTotalPenalty += Number(
  //                       question.optionList[j].penalty
  //                     );
  //                   }
  //                 }
  //               }
  //             }
  //           } else {
  //             if (
  //               String(option.optionRef) !== '' &&
  //               String(option.optionRef) !== 'null'
  //             ) {
  //               matchOptions += option.optionRef;
  //               for (let k = 0; k < option.matchPairList.length; k++) {
  //                 if (option.matchPairList[k].selected) {
  //                   selectedOptArr.push(option);

  //                   if (matchOptions !== '') {
  //                     matchOptions += '#';
  //                   }
  //                   if (
  //                     String(option.matchPairList[k].name) !== '' &&
  //                     String(option.matchPairList[k].name) !== 'null'
  //                   ) {
  //                     matchOptions += option.matchPairList[k].name;
  //                   }

  //                   if (option.optionMatch === option.matchPairList[k].name) {
  //                     option.cFlag = 'true';

  //                     correctOptArr.push(option);
  //                     if (
  //                       String(question.optionList[j].score) !== '' &&
  //                       String(question.optionList[j].score) !== 'null'
  //                     ) {
  //                       optScore += Number(question.optionList[j].score);
  //                     }
  //                     this.quesTotalScore += Number(
  //                       question.optionList[j].score
  //                     );
  //                   } else {
  //                     option.cFlag = 'false';
  //                     if (
  //                       String(question.optionList[j].penalty) !== '' &&
  //                       String(question.optionList[j].penalty) !== 'null'
  //                     ) {
  //                       optPenalty += Number(question.optionList[j].penalty);
  //                     }
  //                     this.quesTotalPenalty += Number(
  //                       question.optionList[j].penalty
  //                     );
  //                   }
  //                 }
  //               }
  //             }
  //           }

  //           let optionValue = '';
  //           optionValue += question.optionList[j].optionType;
  //           optionValue += '#';
  //           optionValue += question.optionList[j].optionText;
  //           optionValue += '#';
  //           optionValue += question.optionList[j].optionRef;
  //           optionValue += '#';
  //           optionValue += question.optionList[j].optionMatch;
  //           optionValue += '#';
  //           optionValue += question.optionList[j].score;
  //           optionValue += '#';
  //           optionValue += question.optionList[j].penalty;
  //           optionValue += '#';
  //           optionValue += question.optionList[j].sFlag;
  //           optionValue += '#';
  //           optionValue += question.optionList[j].cFlag;

  //           optionValues += optionValue;
  //         }
  //         quesAnswers += matchOptions;
  //         quesScore += optScore;
  //         quesPenalty += optPenalty;
  //         allOptionValues += optionValues;
  //         sectionIds += question.sid;

  //         if (correctOptArr.length === 0) {
  //           ansCorrect += 0;
  //           answeredQues += 0;
  //         } else if (selectedOptArr.length === correctOptArr.length) {
  //           ansCorrect += 2;
  //           answeredQues += 1;
  //         } else {
  //           ansCorrect += 1;
  //           answeredQues += 1;
  //         }

  //         if (selectedOptArr.length === 0) {
  //           answeredFlag = false;
  //           question.Answered = 0;
  //         } else {
  //           answeredFlag = true;
  //           question.Answered = 1;
  //         }

  //         if (!answeredFlag && this.checkIfQuestionIsMandatory(question)) {
  //           this.toastr.warning(
  //             'Please answer all mandatory questions that are marked by ' * '.',
  //             'Warning!!!'
  //           );
  //           this.notAnswered = this.checkIfQuestionIsMandatory(question);
  //           return;
  //         }
  //       }
  //       if (question.qTypeId === '3') {
  //         let optScore = 0;
  //         let optPenalty = 0;
  //         let optionValues = '';
  //         const selectedOptArr = [];
  //         const correctOptArr = [];
  //         for (let j = 0; j < question.optionList.length; j++) {
  //           if (optionValues !== '') {
  //             optionValues += '|';
  //           }

  //           const option = question.optionList[j];
  //           if (sequenceAnswers !== '') {
  //             sequenceAnswers += '$';
  //           }
  //           if (
  //             String(option.optionText) !== '' &&
  //             String(option.optionText) !== 'null'
  //           ) {
  //             sequenceAnswers += option.optionText;
  //             if (sequenceAnswers !== '') {
  //               sequenceAnswers += '#';
  //             }
  //             if (sequenceAnswers !== '') {
  //               sequenceAnswers += j;
  //             }
  //           }

  //           if (j === Number(option.sequenceNo)) {
  //             option.cFlag = 'true';
  //             correctOptArr.push(option);
  //             if (
  //               String(option.score) !== '' &&
  //               String(option.score) !== 'null'
  //             ) {
  //               optScore += Number(option.score);
  //             }
  //             this.quesTotalScore += Number(option.score);
  //           } else {
  //             option.cFlag = 'false';
  //             if (
  //               String(option.penalty) !== '' &&
  //               String(option.penalty) !== 'null'
  //             ) {
  //               optPenalty += Number(option.penalty);
  //             }
  //             this.quesTotalPenalty += Number(option.penalty);
  //           }

  //           let optionValue = '';

  //           optionValue += question.optionList[j].optionText;
  //           optionValue += '#';
  //           optionValue += question.optionList[j].sequenceNo;
  //           optionValue += '#';
  //           optionValue += question.optionList[j].score;
  //           optionValue += '#';
  //           optionValue += question.optionList[j].penalty;
  //           optionValue += '#';
  //           optionValue += question.optionList[j].sFlag;
  //           optionValue += '#';
  //           optionValue += question.optionList[j].cFlag;

  //           optionValues += optionValue;
  //         }
  //         quesAnswers += sequenceAnswers;
  //         quesScore += optScore;
  //         quesPenalty += optPenalty;
  //         allOptionValues += optionValues;
  //         sectionIds += question.sid;

  //         if (correctOptArr.length === 0) {
  //           ansCorrect += 0;
  //           answeredQues += 0;
  //         } else if (question.optionList.length === correctOptArr.length) {
  //           ansCorrect += 2;
  //           answeredQues += 1;
  //         } else {
  //           ansCorrect += 1;
  //           answeredQues += 1;
  //         }
  //         question.Answered = 1;
  //       }
  //       // ansConfirmed += questionsData.answerConfirmed;
  //       ansConfirmed += true;
  //       this.notAnswered = false;
  //     }
  //   }

  //   const timeSpent = this.timeInSeconds - this.remainingTime;

  //   if (localStorage.getItem('employeeId')) {
  //     var employeeId = localStorage.getItem('employeeId');
  //   }

  //   const quesAnsData = {
  //     empId: employeeId,
  //     cId: this.activityData.courseId,
  //     mId: this.activityData.moduleId,
  //     qId: this.activityData.quizId,
  //     quesId: quesIds,
  //     quesLen: questions.length,
  //     quesAns: quesAnswers,
  //     quesOptions: allOptionValues,
  //     answerCorrect: ansCorrect,
  //     answerConfirmed: ansConfirmed,
  //     sectionIds: sectionIds,
  //     score: quesScore,
  //     penalty: quesPenalty,
  //     tS: this.quesTotalScore,
  //     tP: this.quesTotalPenalty,
  //     quizComp: quizCompFlag,
  //     tId: this.userdetail.tenantId,
  //     uId: this.userdetail.id,
  //     Answered: answeredQues,
  //     cWT: timeSpent,
  //   };

  //   console.log('quesAnsData ', quesAnsData);
  //   return quesAnsData;
  // }

  // submitAnswer(currentPageQuesData, optId) {
  //   if (optId === 1) {
  //     if (!currentPageQuesData.answerConfirmed) {
  //       if (this.currentQuestionIndex === this.quiz.length - 1) {
  //         this.submitQuestionAnswer(currentPageQuesData, true);
  //       } else {
  //         this.submitQuestionAnswer(currentPageQuesData, false);
  //       }
  //     }
  //     if (!this.notAnswered) {
  //       this.confirmAnswer();
  //     }
  //   } else if (optId === 2) {
  //     if (!currentPageQuesData.answerConfirmed) {
  //       if (this.activityData.behaviour === 1) {
  //         if (this.currentQuestionIndex === this.quiz.length - 1) {
  //           this.submitQuestionAnswer(currentPageQuesData, true);
  //         } else {
  //           this.submitQuestionAnswer(currentPageQuesData, false);
  //         }
  //       }
  //     }
  //     if (!this.notAnswered) {
  //       this.nextPage();
  //     }
  //   } else if (optId === 3) {
  //     if (!currentPageQuesData.answerConfirmed) {
  //       if (this.activityData.behaviour === 1) {
  //         if (this.currentQuestionIndex === this.quiz.length - 1) {
  //           this.submitQuestionAnswer(currentPageQuesData, true);
  //         } else {
  //           this.submitQuestionAnswer(currentPageQuesData, false);
  //         }
  //       }
  //     }
  //     if (!this.notAnswered) {
  //       this.result();
  //       this.confirmAnswer();
  //     }
  //   } else {
  //     if (this.reviewFlag) {
  //       this.result();
  //     }
  //   }
  //   // if (this.activityData.behaviour === 2) {
  //   //   this.submitQuestionAnswer(currentPageQuesData);
  //   //   this.confirmAnswer();
  //   // } else {
  //   //   this.submitQuestionAnswer(currentPageQuesData);
  //   //   if (!this.last) {
  //   //     this.nextPage();
  //   //   } else {
  //   //     this.result();
  //   //   }
  //   // }
  // }

  // nextPage() {
  //   this.currentQuestionIndex++;
  //   if (this.currentQuestionIndex === this.quiz.length - 1) {
  //     this.last = true;
  //     this.questionProgress = 1.0;
  //   } else {
  //     // const totalQuesCount = this.quiz.length - 1;
  //     this.questionProgress =
  //       this.currentQuestionIndex / (this.quiz.length - 1);
  //   }
  // }

  // prevPage() {
  //   console.log('', this.answerSelected);
  //   this.currentQuestionIndex--;
  //   if (this.currentQuestionIndex !== this.quiz.length - 1) {
  //     this.last = false;
  //     this.questionProgress =
  //       this.currentQuestionIndex / (this.quiz.length - 1);
  //   }
  // }

  // confirmAnswer() {
  //   // this.answerConfirmed = true;
  //   this.quiz[this.currentQuestionIndex].answerConfirmed = true;
  // }

  // submitQuestionAnswer(currentPageQuesData, quizCompFlag) {
  //   const questionData = this.makeQuestionAnsDataReday(
  //     currentPageQuesData,
  //     quizCompFlag,
  //   );
  //   console.log('MAKEQADATAREADY:--', questionData);
  //   // if (!this.notAnswered) {
  //   // 	var url = ENUM.domain + ENUM.url.submitQuizAnswers;
  //   // 	this.activityService.submitQuizData(url, questionData).then((res: any) => {
  //   // 		this.quizSubmitRes = res.data;
  //   // 		console.log(res);
  //   // 		// if (this.last) {
  //   // 		//   this.result();
  //   // 		// }
  //   // 	}, err => {
  //   // 		console.log(err);
  //   // 	});

  //   // 	const timeSpent = this.timeInSeconds - this.remainingTime;
  //   // 	this.makeQuizCompDataReady(null, this.timeInSeconds, timeSpent);
  //   // }
  // }

  makeQuizCompDataReady(quizStatus, quizDuration, quizTimeSpent) {
    const quizcCompData = {
      duration: quizDuration,
      currentTime: quizTimeSpent,
      status: quizStatus,
    };
    this.saveQuizComp(quizcCompData);
  }

  saveQuizComp(event) {
    const activity = this.activityData;
    if (activity.contenttime === null) {
      activity.contenttime = event.duration;
    }
    activity.contentwatchedtime = event.currentTime;
    if (activity.completionstatus === "Y") {
      activity.completionstatus = "Y";
    } else if (event.status === "Y") {
      activity.completionstatus = "Y";
      this.saveQuizCompletionData(activity);
    } else {
      activity.completionstatus = "UP";
      this.saveQuizCompletionData(activity);
    }
  }

  saveQuizCompletionData(activityData) {
    // this.activityService.saveActivityCompletion(activityData).then(
    //   (res: any) => {
    //     if (res.type === true) {
    //       this.quizCompRes = res;
    //       if (
    //         this.activityData.activitycompletionid === undefined ||
    //         !this.activityData.activitycompletionid
    //       ) {
    //         this.activityData.activitycompletionid = res.data[0][0].lastid;
    //       }
    //       console.log('Quiz completion res', res);
    //     }
    //   },
    //   err => {
    //     console.log(err);
    //   }
    // );
  }

  // result() {
  //   console.log('Quiz data with answers ', this.quiz);

  //   this.activityService.currentQuizData.quizData = this.quiz;
  //   this.activityService.currentQuizData.quizSumitData = this.quizSubmitRes;

  //   const timeSpent = this.timeInSeconds - this.remainingTime;
  //   this.makeQuizCompDataReady('Y', this.timeInSeconds, timeSpent);

  //   // this.router.navigateByUrl('/result');
  //   // this.showScore = true;
  // }
  // gotoNext(data) {
  //   console.log('DATA==>', data);
  // }

  gotoNextActivity() {
    // this.router.navigate(['../../../../learn/course-detail'], {
    //   relativeTo: this.routes
    // });
    const quizData = {
      quizData: "",
      reviewFlag: false,
      reattemptFlag: false,
    };
    this.activityService.currentQuizData.reviewFlag = quizData.reviewFlag;
    this.activityService.currentQuizData.quizData = quizData.quizData;
    // window.history.go(-2);
    const event = {
      loaderFlag: true,
      responseData: null,
      quizSubmitResponse: this.quizSubmitRes,
      quizData: this.quiz,
      errorFlag: false,
      // 'showQuizReview': false,
      // 'showQuiz': true,

      showComponent: "next-activity",
    };
    this.componentData.emit(event);
  }

  reattemptQuiz() {
    const quizData = {
      quizData: "",
      reviewFlag: false,
      reattemptFlag: true,
    };
    this.activityService.currentQuizData.reviewFlag = quizData.reviewFlag;
    this.activityService.currentQuizData.quizData = quizData.quizData;
    this.activityService.currentQuizData.reattemptFlag = quizData.reattemptFlag;
    // window.history.go(-1);
    const event = {
      loaderFlag: false,
      responseData: null,
      errorFlag: false,
      // 'showQuizReview': false,
      // 'showQuiz': true,
      showComponent: "quiz",
      showAutoPlayConatiner: false,
      reattempt: true
    };
    this.componentData.emit(event);
  }

  check_quiz_for_user_for_reattempt() {
    this.checkQuizforUser(this.activityData, (result) => {
      if (result && result.type === true) {
        if (result.data && result.data[0].flag === "true") {
          console.log("PassCheck===>", result.data[0]);
          this.reattemptQuiz();
        } else {
          let message =
            result.data[0].msg + " " + result.data[0].nextAttemptDate;
          // this.toastr
          //   .warning(message, "Warning!")
          //   .onHidden.subscribe(() => this.toastr.clear());
          this.presentAlert(message, "Warning!");
        }
      } else {
        // this.toastr
        //   .warning("Something went wrong please try again later", "Warning!")
        //   .onHidden.subscribe(() => this.toastr.clear());
        this.presentAlert(
          "Something went wrong please try again later",
          "Warning!"
        );
      }
    });
  }

  checkQuizforUser(quizData, cb) {
    const data = {
      cId: quizData.courseId,
      mId: quizData.moduleId,
      aId: quizData.activityId,
      qId: quizData.quizId,
      tId: quizData.tenantId,
      uId: this.userdetail.eid,
      enId: quizData.enrolId,
    };
    console.log("Quiz check for user re-attempt : ", data);
    this.activityService.quiz(data).then(
      (res) => {
        console.log(res);
        cb(res);
      },
      (err) => {
        console.log("check quiz for user re-attempt error : ", err);
        cb(err);
      }
    );
  }

  showReview() {
    // if(){

    // }
    this.getQuizQuestions();
    // this.showScore = true;
  }

  showResult() {
    this.showScore = false;
  }

  // submitServiceCall(parameters) {

  // 	var url = ENUM.domain + ENUM.url.submitQuizAnswers;
  // 	this.activityService.submitQuizData(url, parameters)
  // 		.then((result: any) => {
  // 			console.log('RESULT==>', result);
  // 		}).catch(result => {
  // 			console.log('ServerResponseError :', result);
  // 		})
  // }
  makequizQuestionReady(question, type): SafeHtml {
    if (type == "question") {
      if (question.isMandatory == 1) {
        let questionFinal = question.qText + ' <b class="mandatoryQues">*</b>';
        return this.sanitizer.bypassSecurityTrustHtml(questionFinal);
      } else {
        return this.sanitizer.bypassSecurityTrustHtml(question.qText);
      }
    } else {
      return this.sanitizer.bypassSecurityTrustHtml(question);
    }
  }

  makefeedback(question) {
    if (question) {
      var fb = question.replace(/<[^>]+>/g, "");
      fb = fb.replace(/&nbsp;/g, "");
      if (fb.length > 92) {
        return fb.substring(0, 92);
      } else {
        return fb;
      }
    }
  }
  readfb(fbtext) {
    this.feedbacktext = fbtext;
    this.showfb = true;
  }
  closefb() {
    this.showfb = false;
  }

  ngOnChanges() {
    if (
      this.courseDataContentInput &&
      this.courseDataSummaryInput &&
      this.quizSubmitResponse
    ) {
      this.courseDataContent = this.courseDataContentInput;
      this.courseDataSummary = this.courseDataSummaryInput;
      // this.resultData = this.activityService.getResultData();
      // if (this.resultData) {
      this.resultData = this.quizSubmitResponse[0];
      this.isReviewEn = this.activityService.currentQuizData.isReviewEn;
      // }
      this.getUserData();
      this.reIntializeData();
    }
  }

  reIntializeData() {
    //*****************************GET QUIZ CONTENT*************************************///
    if (this.courseDataContent) {
      // var params = {
      //   suptId: this.courseDataContent.supertypeId,
      //   subtId: this.courseDataContent.activityTypeId,
      //   aId: this.courseDataContent.activityId,
      //   uId: this.userdetail.userId,
      //   eId: this.courseDataContent.enrolId
      // };
      var params = {
        suptId: this.courseDataContent.supertypeId,
        subtId: this.courseDataContent.activityTypeId,
        aId: this.courseDataContent.activityId,
        uId: this.userdetail.userId,
        eId: this.courseDataContent.enrolId,
        tempId: this.courseDataSummary.courseFrom === 2 || 3 ? true : false,
        enId: this.courseDataContent.enrolId,
        enrolId: this.courseDataContent.enrolId,
        areaId: this.courseDataContent.areaId || 2,
        instanceId: this.courseDataContent.instanceId || this.courseDataContent.activityId
      };
    }
    if (this.courseDataSummary.courseFrom === 2 || 3) {
      params["eId"] = null;
      if (Number(this.userdetail["roleId"]) === 8) {
        params["tempId"] = true;
      } else {
        params["tempId"] = false;
      }
    }
    this.moduleService
      .getLearnActivitiesList(params)
      .then((result: any) => {
        this.activityData = result.data;
        console.log("Activity Data ===>", this.activityData);
        if (this.activityData.navigation == 1) {
          this.displayNav = true;
        } else {
          this.displayNav = false;
        }
        if (params["tempId"]) {
          this.activityData.enrolId = this.courseDataContent.enrolId;
        }
        if (this.activityData.behaviour == 1) {
          this.behaviour = this.activityData.behaviour;
          this.displayConfirm = true;
        } else {
          this.behaviour = this.activityData.behaviour;
          this.displayConfirm = false;
        }

        //*****************************GET QUIZ CONTENT START*************************************///
        // if (this.activityData) {
        //   if (localStorage.getItem('employeeId')) {
        //     var employeeId = localStorage.getItem('employeeId');
        //   }
        //   var param = {
        //     qId: this.courseDataContent.quizId,
        //     tId: this.userdetail.tenantId,
        //     qOPId: this.activityData.qOPId,
        //     qOrder: this.activityData.quizOrder,
        //     empId: employeeId,
        //     cId: this.activityData.courseId,
        //     mId: this.activityData.moduleId,
        //     uId: this.userdetail.id,
        //     contentTime: this.makeTimerDataReady(
        //       this.activityData.activityDuration
        //     ),
        //     enId :this.courseDataContent.enrolId,
        //   };
        // }
        // this.activityService.getQuizForReview(param)
        //   .then((result: any) => {
        //     if (result.data !== undefined) {
        //       this.quiz = result.data;
        //       console.log('QUIZ Activity GET SUCCESSFUL--->', this.quiz);
        //       this.spinner.hide();
        //       if (this.quiz.length === 1) {
        //         this.last = true;
        //       }
        //       let timeSpent = 0;
        //       this.total_page = this.quiz.length;
        //       if (this.activityData.contentwatchedtime !== null) {
        //         timeSpent = this.activityData.contentwatchedtime;
        //       } else {
        //         timeSpent = this.timeInSeconds - this.remainingTime;
        //       }
        //       this.makeQuizCompDataReady(null, this.timeInSeconds, timeSpent);
        //     } else {
        //       this.spinner.hide();
        //       this.router.navigate(['../../../learn'], {
        //         relativeTo: this.routes
        //       });
        //     }
        //   })
        //   .catch(result => {
        //     this.spinner.hide();
        //     console.log('ServerResponseError :', result);
        //   });
        //*****************************GET QUIZ CONTENT END*************************************///
      })
      .catch((result) => {
        // this.spinner.hide();
        const event = {
          loaderFlag: false,
          responseData: null,
          errorFlag: false,
          // 'showQuizReview': true,
          // 'showQuiz': false,
          showComponent: "quiz-review",
        };
        this.componentData.emit(event);
        console.log("ServerResponseError :", result);
      });
    this.courseTitle = this.courseDataSummary.courseTitle;
  }

  handleEvent(event: CustomEvent) {
    console.log(`${event.name} has been click on img ${event.imageIndex + 1}`);

    switch (event.name) {
      case "exit":
        this.showImageInFullScreen = false;
        console.log("exit logic");
        break;
    }
  }

  config: ImageViewerConfig = {
    wheelZoom: true,
    allowFullscreen: false,
    customBtns: [
      {
        name: "exit",
        icon: "fa fa-times",
        buttonClass: "showOnTop",
        tooltip: "Exit",
      },
    ],
  };
  imageIndexOne = 0;
  images = [
    // 'https://images.unsplash.com/photo-1591027265828-5678792f7c33?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1000&q=80'
    // 'https://i.ytimg.com/vi/nlYlNF30bVg/hqdefault.jpg',
    // 'https://www.askideas.com/media/10/Funny-Goat-Closeup-Pouting-Face.jpg'
  ];
  showImageInFullScreen = false;
  openInFullScreen(imageRef) {
    if (!imageRef && imageRef == "" && !this.isURL(imageRef)) {
      // this.toastr.warning("Invalid image!", "Warning");
      this.presentAlert("Invalid image!", "Warning");
      return null;
    }
    this.images[0] = imageRef;
    this.showImageInFullScreen = true;
    // this.toggleClassChange = true;
    // setTimeout(()=>openInFullScreen{
    //   const element = document.querySelector('ngx-image-viewer');
    //   this.makeElementFullScreen(element);
    // }, 2);
  }

  isURL(str) {
    const regex = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
    const pattern = new RegExp(regex);
    return pattern.test(str);
  }
  async presentAlert(msg, title) {
    // this.alert = await this.alertController.create({
    //   header: "",
    //   subHeader: "",
    //   backdropDismiss: false,
    //   message: msg,
    //   buttons: [
    //     {
    //       text: "OK",
    //       cssClass: "okbutton",
    //       handler: (blah) => {
    //         console.log("Confirm Cancel: blah");
    //         // this.router.navigateByUrl('/tabs/learn');
    //         // if (this.courseActivityData.frompage == 'module') {
    //         //   this.router.navigateByUrl('/module');
    //         // }
    //         // if (this.courseActivityData.frompage == 'trainer') {
    //         //   this.router.navigateByUrl('/trainer-detail');
    //         // }
    //         if (this.formIsValid) {
    //           window.history.back();
    //         }
    //       },
    //     },
    //   ],
    // });
    // this.alert.present();
    await this.toaster.prsentToast(msg, title, "warning", null);
  }
}
