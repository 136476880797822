import { Component, OnInit, ChangeDetectorRef, Input, Output, EventEmitter, OnDestroy, OnChanges, ViewChild, HostListener } from '@angular/core';
import { CalendarOptions, DateSelectArg, EventClickArg, EventApi, EventInput } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import { INITIAL_EVENTS, createEventId } from '../event-utils';
import { CalendarService } from '../../../providers/calendar/calendar.service';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { CommonFunctionsService } from '../../../../../providers/common-functions/common-functions.service';
import * as moment from 'moment';

@Component({
  selector: 'ngx-events-calendar-new',
  templateUrl: './events-calendar-new.component.html',
  styleUrls: ['./events-calendar-new.component.scss']
})
export class EventsCalendarNewComponent implements OnInit, OnChanges, OnDestroy {

  @Input() inputData: any = [];
  @Input() eventSource: any = [];
  @Input() filterConfig: any = {
    filterTitle: 'Filter',
    innerFilterData: [],
    selectedFilterValue: null,
  };
  @Input() updateEventSource: any = false;
  @Input() fetchingEvents: any;
  @Output() actionEvent = new EventEmitter();
  viewType = null;
  events = [
    // {
    //   "areaId": 2,
    //   "instanceId": 20324,
    //   "eventType": 2,
    //   "eventStartDate": "2023-06-06T18:30:00.000Z",
    //   "eventEndDate": "2023-06-06T18:30:00.000Z",
    //   "eventStartTime": null,
    //   "eventEndTime": null,
    //   "requestJson": {
    //     "wfId": null,
    //     "areaId": 2,
    //     "enrolId": 10491943,
    //     "instanceId": 20324
    //   },
    //   "title": "Mandatory",
    //   "subTitle": "profile access course one",
    //   "contentType": "Course",
    //   "description": "",
    //   "showButtons": 1,
    //   "buttons": [
    //     {
    //       "text": "Learn",
    //       "class": "fs-12 text-success d-inline-block text-center border-top border-right py-1 w-50",
    //       "btnVisibilitySts": 3,
    //       "action": "learn",
    //       "alertDesc": "",
    //       "alertIcon": "",
    //       "alertTitle": ""
    //     },
    //     {
    //       "text": "View",
    //       "class": "fs-12 text-danger d-inline-block text-center border-top py-1 w-50",
    //       "btnVisibilitySts": 1,
    //       "action": "view",
    //       "alertDesc": "",
    //       "alertIcon": "",
    //       "alertTitle": ""
    //     }
    //   ],
    //   "allDay": true,
    //   "startTime": "2023-06-06T18:30:00.000Z",
    //   "endTime": "2023-06-06T18:30:00.000Z",
    //   "id": '4',
    //   "extraData": null,
    //   "startDateOnly": "07 Jun 2023",
    //   "startTimeOnly": "12:00 AM",
    //   "endTimeOnly": "12:00 AM",
    //   "endDateOnly": "07 Jun 2023",
    //   // start: '2023-06-07',
    //   // end: '2023-06-08'
    //   "start": "2023-06-09T04:30:00.000Z",
    //   "end": "2023-06-09T05:00:00.000Z",
    // },
    // {
    //   title: 'event1',
    //   start: '2023-06-07'
    // },
    // {
    //   title: 'event2',
    //   start: '2023-06-08',
    //   end: '2023-06-08'
    // },
    // {
    //   title: 'event3',
    //   start: '2023-06-08T12:30:00',
    //   allDay: false // will make the time show
    // }
  ];

  refreshCalender = true;

  config = {
    sidebarTitle: 'Event',
    buttons: [
      // {
      //   action: "yes",
      //   btnText: "Accept",
      //   classList: 'btn-success' //btn-fill
      // },
      // {
      //   action: "no",
      //   btnText: "Reject",
      //   classList: 'btn-danger ml-1' //btn-outline
      // },
    ],
    footer: true,
  };



  eventVisible = false;

  // references the #calendar in the template
  @ViewChild('calendar', { static: false }) calendarComponent: FullCalendarComponent;

  // @HostListener('document:keydown.escape', ['$event'])
  // handleKeyboardEvent(event: KeyboardEvent) {
  //   console.log('escape called !!!!');
  //     this.toggleEventDetail(false);
  // }

  // calendarVisible = true;
  calendarOptions: CalendarOptions = {
    eventDisplay: 'block',
    plugins: [
      interactionPlugin,
      dayGridPlugin,
      timeGridPlugin,
      // listPlugin,
    ],

    dayMaxEventRows: true, // for all non-TimeGrid views
    views: {
      // timeGrid: {
      //   dayMaxEventRows: 2,
      //   eventLimit: 2,
      //   eventMaxStack: 2 // adjust to 6 only for timeGridWeek/timeGridDay
      // }
    },
    customButtons: {
      filter: {
        text: 'Filter',
        click: this.performAction.bind(this, { data: true, action: 'toggleFilter' })
      },
      refresh: {
        text: 'Refresh',
        // icon: 'fc-icon fc-icon-chevrons-left',
        click: this.performAction.bind(this, { data: true, action: 'refreshList' })
      },
      currentDay: {
        text: 'Today',
        // icon: 'fc-icon fc-icon-chevrons-left',
        click: this.performAction.bind(this, { data: true, action: 'goToToday' })
      },
    },
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'refresh filter dayGridMonth,timeGridWeek'
    },
    initialView: 'dayGridMonth',
    initialEvents: [], // alternatively, use the `events` setting to fetch from a feed
    weekends: true,
    editable: false,
    selectable: true,
    selectMirror: false,
    dayMaxEvents: true,
    // select: this.performAction.bind(this, { data: null, action: 'dataSelected' }),
    eventClick: (event) => {
      this.performAction({ data: event, action: 'eventSelected' })
    },
    // eventsSet: this.handleEvents.bind(this),
    // events: [
    //   {
    //     "areaId": 2,
    //     "instanceId": 20324,
    //     "eventType": 2,
    //     "eventStartDate": "2023-06-06T18:30:00.000Z",
    //     "eventEndDate": "2023-06-06T18:30:00.000Z",
    //     "eventStartTime": null,
    //     "eventEndTime": null,
    //     "requestJson": {
    //       "wfId": null,
    //       "areaId": 2,
    //       "enrolId": 10491943,
    //       "instanceId": 20324
    //     },
    //     "title": "Mandatory",
    //     "subTitle": "profile access course one",
    //     "contentType": "Course",
    //     "description": "",
    //     "showButtons": 1,
    //     "buttons": [
    //       {
    //         "text": "Learn",
    //         "class": "fs-12 text-success d-inline-block text-center border-top border-right py-1 w-50",
    //         "btnVisibilitySts": 3,
    //         "action": "learn",
    //         "alertDesc": "",
    //         "alertIcon": "",
    //         "alertTitle": ""
    //       },
    //       {
    //         "text": "View",
    //         "class": "fs-12 text-danger d-inline-block text-center border-top py-1 w-50",
    //         "btnVisibilitySts": 1,
    //         "action": "view",
    //         "alertDesc": "",
    //         "alertIcon": "",
    //         "alertTitle": ""
    //       }
    //     ],
    //     "allDay": true,
    //     "startTime": "2023-06-06T18:30:00.000Z",
    //     "endTime": "2023-06-06T18:30:00.000Z",
    //     "id": '4',
    //     "extraData": null,
    //     "startDateOnly": "07 Jun 2023",
    //     "startTimeOnly": "12:00 AM",
    //     "endTimeOnly": "12:00 AM",
    //     "endDateOnly": "07 Jun 2023",
    //     // start: '2023-06-07',
    //     // end: '2023-06-08'
    //     "start": "2023-06-09T04:30:00.000Z",
    //     "end": "2023-06-09T05:00:00.000Z",
    //   },
    //   {
    //     title: 'event1',
    //     start: '2023-06-07'
    //   },
    //   {
    //     title: 'event2',
    //     start: '2023-06-08',
    //     end: '2023-06-08'
    //   },
    //   {
    //     title: 'event3',
    //     start: '2023-06-08T12:30:00',
    //     allDay: false // will make the time show
    //   }
    // ],
    /* you can update a remote database when these fire:
    eventAdd:
    eventChange:
    eventRemove:
    */
    dateClick: this.handleDateClick.bind(this),
    datesSet: this.handleDatesChanged.bind(this),
    // moreLinkClick: () => {console.log('moreLinkClick')},
    // moreLinkDidMount: 
  };
  currentEvents: EventApi[] = [];
  selectedFilterData = null;
  selectedEventData = null;

  eventsPromise: Promise<EventInput>;

  constructor(
    private changeDetector: ChangeDetectorRef,
    // public navCtrl: NavController,
    // private actionSheetCtrl: ActionSheetController,
    // private modalCtrl: ModalController,
    // public popoverController: PopoverController,
    // public animationCtrl: AnimationController,
    private calendarService: CalendarService,
    private commonFunctionsService: CommonFunctionsService,
    // private customActionPopoverService: CustomActionPopoverService,
  ) {
  }

  ngOnInit() {
    this.resetValues();
  }

  ngOnChanges(changes: any) {
    console.log('EventsCalendarComponent changes called...', changes);
    try {
      if ('eventSource' in changes) {
        this.checkEventSourceInput(changes.eventSource);
      }
      // if ('fetchingEvents' in changes) {
      //   // this.isFetching = this.fetchingEvents;
      //   // if (!changes.fetchingEvents.currentValue) {
      //   //   this.isFetching = false;
      //   // } else {
      //   //   this.isFetching = true;
      //   // }
      //   this.checkEventSourceInput(changes.fetchingEvents);
      // }
    } catch (error) {
      console.log('EventsCalendarComponent changes error', error);
    }
    // this.loadEvents();
    // if (this.updateEventSource) {
    //   this.slideUpdate();
    // }
  }

  checkEventSourceInput(evSource) {
    // You can also use eventSource.previousValue and
    // eventSource.firstChange for comparing old and new values
    console.log('Event source current value : ', evSource.currentValue);
    console.log('Event source previous value : ', evSource.previousValue);
    console.log('Event source first change value : ', evSource.firstChange);
    if (evSource.currentValue && Array.isArray(evSource.currentValue)) {
      this.toggleEventDetail(false);
      this.events = [...this.eventSource];
      this.displayEventForAPerticularDate(new Date());
    }
  }


  // convertInputSource(input) {
  //   const array = [];
  //   input.forEach(element => {
  //     let object = JSON.parse(JSON.stringify(element));
  //     // object['id'] = String(element['id']);
  //     // object['title'] = element["title"];
  //     // object["subTitle"] = "profile access course one";
  //     object['start'] = new Date(element['start']).toISOString().replace(/T.*$/, '');
  //     object['end'] = new Date(element['end']).toISOString().replace(/T.*$/, '');
  //     // element['start'] = new Date(element['start']).toISOString().replace(/T.*$/, '');
  //     // element['end'] = new Date(element['end']).toISOString().replace(/T.*$/, '');
  //     // object['startDate'] = new Date(element['startDate']).toISOString().replace(/T.*$/, '');
  //     delete object['startDate'];
  //     delete object['endDate'];

  //     array.push(object);
  //   });
  //   this.events = [
  //     ...array
  //   ];
  //   this.changeDetector.detectChanges();
  // }


  resetValues() {
    this.inputData = [];
    this.eventSource = [];
    this.updateEventSource = false;
    // this.selectedDay = new Date();
    // this.selectedObject = {};
    // this.viewTitle = '';
    // this.isToday = false;
    // this.allDayLabel = 'All Day';
    // this.selectedEventIndex = 0;
    // this.isFetching = false;
  }

  loadEvents() {
    this.eventSource = this.calendarService.createRandomEvents();
  }

  // handleCalendarToggle() {
  //   this.calendarVisible = !this.calendarVisible;
  // }

  handleWeekendsToggle() {
    const { calendarOptions } = this;
    calendarOptions.weekends = !calendarOptions.weekends;
  }


  // handleEvents(events: EventApi[]) {
  //   this.currentEvents = events;
  //   console.log('this.currentEvents ==', this.currentEvents)
  //   this.changeDetector.detectChanges();
  // }

  handleDatesChanged(ev) {
    console.log('range changed: startTime: ' + ev.start + ', endTime: ' + ev.end);
    const tempDateRange = {
      fromDate: ev.start,
      toDate: ev.end,
      startTime: ev.start.toISOString(),
      endTime: ev.end.toISOString()
    };
    this.fireEvent('rangeChanged', tempDateRange);
  }

  performAction(event) {
    console.log('event', event);
    this.hideEventPopover();
    const type = this.commonFunctionsService.getAction(event);
    switch (type) {
      case 'toggleFilter': this.fireEvent('toggleFilter', true); break;
      case 'eventSelected': this.findAndDisplayEvent(event.data.event.id); break;
      case 'eventSidebar': this.eventActions(event.data); break;
      case "view": this.findAndDisplayEvent(event.data.id); break;
      case "join": this.joinEvent(event.data.id); break;
      case 'notInt': this.fireEvent(type, event.data, event.action);
        break;
      // case 'goToToday': this.goToSpecficDate(); break;
      // case 'close': this.toggleEventDetail(false); break;
      // case 'filterSidebar': this.filterActions(event.data); break;
      // case 'cancel': this.toggleEventDetail(false); break;
      default: this.fireEvent(type, event.data); break;
    }
  }
  handleDateClick(arg) {
    console.log('date click! ' + arg.dateStr);
    this.displayEventForAPerticularDate(arg.dateStr);
  }

  displayEventForAPerticularDate(todaydate) {
    // moment
    const currentdate = moment(todaydate).startOf('day');
    // var startDate = new Date(
    //   currentdate.getFullYear(),
    //   currentdate.getMonth(),
    //   currentdate.getDate()
    // );
    // var endDate = new Date(
    //   currentdate.getFullYear(),
    //   currentdate.getMonth() + 1,
    //   currentdate.getDate()
    // );
    this.events = [];
    this.eventSource.forEach(element => {
      const start = moment(element.start).startOf('day');
      const end = moment(element.end).startOf('day');
      if (
        // currentdate.isSame(start) || currentdate.isSame(end)
        // currentdate.isBetween(start, end, 'days')
        !(currentdate.isBefore(start) || currentdate.isAfter(end))
      ) {
        this.events.push(element);
      }
    });

  }

  findAndDisplayEvent(data) {
    // const id = data.event.id;
    this.viewType = 1;
    const selectedEvent = this.getSelectedEventById(data);
    console.log('selected Event', selectedEvent);
    if (selectedEvent.length != 0) {
      this.selectedEventData = selectedEvent[0];
      this.toggleEventDetail(true);
    } else {
      console.log('selected event not found');
    }
  }

  getSelectedEventById(data) {
    return this.commonFunctionsService.getMatchedArray(this.eventSource, [data], 'id');
    // selectedEvent;
  }

  joinEvent(data) {
    const selectedEvent = this.getSelectedEventById(data);
    if (selectedEvent.length != 0) {
      this.selectedEventData = selectedEvent[0];
      if (this.selectedEventData['meetings']) {
        const meetings = this.selectedEventData['meetings'] ? JSON.parse(this.selectedEventData['meetings']) : [];
        if (meetings.length == 1) {
          this.performAction({ data: meetings[0], action: 'openJoinModel' })
        } else {
          this.viewType = 2;
          this.toggleEventDetail(true);
        }
        // this.viewType = 2;
        // this.toggleEventDetail(true);
      } else {
        console.log('meetings empty', this.selectedEventData);
      }
    } else {
      console.log('selected event not found');
    }
  }



  fireEvent(inEvent, inData, extraData = null) {
    const res = {
      type: inEvent,
      data: inData,
      extraData: extraData
    };
    this.actionEvent.emit(res);
  }



  toggleEventDetail(flag) {
    console.log('toggleEventDetail', flag);
    const configEvent = {
      sidebarTitle: 'Event',
      buttons: [
        // {
        //   action: "yes",
        //   btnText: "Accept",
        //   classList: 'btn-success' //btn-fill
        // },
        // {
        //   action: "no",
        //   btnText: "Reject",
        //   classList: 'btn-danger ml-1' //btn-outline
        // },
      ],
      footer: true,
    };

    if (flag) {
      configEvent.buttons = this.prepareSidebarConfig();
      if (this.viewType == 1) {
        if (configEvent.buttons.length == 0) {
          configEvent.footer = false;
        } else {
          configEvent.footer = true;
        }
      } else {
        configEvent.footer = false;
      }
      this.config = configEvent;
    } else {
      this.selectedEventData = null;
    }

    this.eventVisible = flag;
  }

  prepareSidebarConfig() {
    const buttonArray = []
    if (this.selectedEventData && this.selectedEventData['buttons']) {
      this.selectedEventData.buttons.forEach(element => {
        if (element['btnVisibilitySts'] == 2 || element['btnVisibilitySts'] == 3) {
          buttonArray.push(element);
        }
      });
    }
    return buttonArray;

  }


  eventActions(event) {
    const type = this.commonFunctionsService.getAction(event);
    switch (type) {
      case 'close': this.toggleEventDetail(false); break;
      case 'outsideClick': this.toggleEventDetail(false); break;
      // case 'filterSidebar': this.filterActions(event.data); break;
      case 'cancel': this.toggleEventDetail(false); break;
      // case 'apply': this.fireEvent('filterValueChanged', this.selectedFilterData); this.toggleFilter(false); break;
      // default: console.log('invalid type', event); break;
      case 'notInt': this.fireEvent(type, this.selectedEventData, event.extraData);
        break;
      default: this.fireEvent(type, this.selectedEventData); break;
    }

  }

  goToSpecficDate() {
    let calendarApi = this.calendarComponent.getApi();
    calendarApi.gotoDate('2000-01-01');
    calendarApi.select('2000-01-01');
  }



  ngOnDestroy() {
    this.resetValues();
  }

  hideEventPopover() {
    var evenPopoverList:any = document.getElementsByClassName('fc-more-popover');
    for (let i = 0; i < evenPopoverList.length; i++) {
      let element = evenPopoverList[i];
      element.style.display = 'none';
    }
    // console.log('calendarPopover ============', evenPopoverList);
  }

}
