import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'ngx-article-activity',
  templateUrl: './article-activity.component.html',
  styleUrls: ['./article-activity.component.scss']
})
export class ArticleActivityComponent implements OnInit {
  @ViewChild('iframe') iframe: ElementRef;
  @Output() saveEvent = new EventEmitter<any>();
  // @Output() openImage = new EventEmitter<any>();
  @Output() loadError = new EventEmitter<any>();
  @Input() activity: any;
  isWorking = true;
  constructor(private httpClient: HttpClient) { }


  ngOnInit() {
    // this.activity.reference = 'https://www.youtube.com/embed/tgbNymZ7vqY';
    this.initComponent();
  }


  ngAfterViewInit() {
  }


  initComponent() {
    // this.checkIfUrlIsValid((error, data) => {
    //   if (error) {
    //     this.loadError.emit();
    //   }
    // })
  }



  iframeLoaded() {
    console.log('iframe loaded');
    this.isWorking = false;
    this.saveEvent.emit();
  }

  checkIfUrlIsValid(cb) {
    this.httpClient.get(this.activity?.reference).subscribe(
      response => {
        console.log('iframeLoaded', response);
        cb(false, response);
      }
      , error => {
        cb(true, error);
        // this.loadError.emit();
        console.log('iframeLoaded error', error);
      });
  }

}
