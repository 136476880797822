import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { webApi } from "../../../config";

@Injectable({
  providedIn: "root",
})
export class ModuleService {
  courseData: any = {
    approvalStatus: "",
    cat_id: "",
    category: "",
    compStatus: "",
    completed_courses: "",
    courseDesc: "",
    courseTitle: "",
    courseType: "",
    courseType2: "",
    cpoints: "",
    endDate: "",
    enrolId: "",
    id: "",
    isBookmark: "",
    isDislike: "",
    isLike: "",
    nextStage: "",
    noDislikes: "",
    noLikes: "",
    noOfmodules: "",
    percComplete: "",
    startDate: "",
    total_courses: "",
    typeImage: "",
  };
  moduleList: any;
  course: any;
  baseUrl: any = webApi.baseUrl;
  // getModulesListUrl = this.baseUrl + webApi.apiUrl.get_all_learn_modules_list;
  getModulesListUrl = this.baseUrl + webApi.apiUrl.get_all_learn_modules_asset_list;
  
  // getActivitiesListUrl = this.baseUrl + webApi.apiUrl.get_all_modules_activities_list;
  getActivitiesListUrl = this.baseUrl + webApi.apiUrl.get_all_learn_modules_list_and_asset;
  gettrainerListURL = this.baseUrl + webApi.apiUrl.gettrainerList;
  getlearnerListURL = this.baseUrl + webApi.apiUrl.getlearnerlist;

  getMyCoursesWithFilterUrl =
    this.baseUrl + webApi.apiUrl.get_my_courses_with_filter;
    fetchMyLearning =
    this.baseUrl + webApi.apiUrl.fetch_my_learning;
  getAreawiseModuleData = this.baseUrl + webApi.apiUrl.getAreawiseModuleData;
  constructor(private http: HttpClient) {}

  public getLearnModulesList(learnModules: any): Promise<any> {
    return this.http
      .post(this.getModulesListUrl, learnModules)
      .toPromise()
      .then((response) => {
        return (response = response);
      })
      .catch(this.handleError);
  }

  public getLearnActivitiesList(learnActivities: any): Promise<any> {
    return this.http
      .post(this.getActivitiesListUrl, learnActivities)
      .toPromise()
      .then((response) => {
        return (response = response);
      })
      .catch(this.handleError);
  }

  public gettrainer(data: any): Promise<any> {
    return this.http
      .post(this.gettrainerListURL, data)
      .toPromise()
      .then((response) => {
        return (response = response);
      })
      .catch(this.handleError);
  }
  public getlearner(data: any): Promise<any> {
    return this.http
      .post(this.getlearnerListURL, data)
      .toPromise()
      .then((response) => {
        return (response = response);
      })
      .catch(this.handleError);
  }

  getCourseDetailsData(url, data) {
    return this.http
      .post(this.baseUrl + url, data)
      .toPromise()
      .then((response) => {
        return (response = response);
      })
      .catch(this.handleError);
  }

  getFetchUrlBasedOnType(type) {
    if (type == 1) {
      return this.getMyCoursesWithFilterUrl;
    } else if (type == 2) {
      // return this.getMyPathwaysWithFilterUrl;
    } else if (type == 3) {
      // return this.getOpenCoursesWithFilterUrl;
    } else if (type == 4) {
      return this.fetchMyLearning;
    }
  }

  public getLearnCourseList(learnData: any, type): Promise<any> {
    const url = this.getFetchUrlBasedOnType(type);
    return this.http
      .post(url, learnData)
      .toPromise()
      .then((response) => {
        const res = {
          data: response,
          type: type,
        };
        // return response
        return res;
      })
      .catch(this.handleError);
  }

  public getAreawiseData(learnModules: any): Promise<any> {
    return this.http
      // .post(this.getModulesListUrl, learnModules)
      .post(this.getAreawiseModuleData, learnModules)
      .toPromise()
      .then((response) => {
        return (response = response);
      })
      .catch(this.handleError);
  }

  private handleError(error: Response | any) {
    return Promise.reject(error.message || error);
  }
  
}
