<!-- <ion-button (click)="downloadScorm(scormInfo)" [disabled]="downloading">Download</ion-button> -->
<!-- <ion-progress-bar type="indeterminate" *ngIf="downloading"></ion-progress-bar> -->
<div
  [nbSpinner]="isWorking"
  class="w-100 h-100"
  nbSpinnerSize="giant"
  *ngIf="isWorking"
  nbSpinnerStatus="primary"
></div>

<!-- <button class='btn btn-default' (click)="refreshScorm()">Refresh</button> -->
<!-- <div *ngIf="manifesturl != null || manifesturl != undefined" width="100%" [ngStyle]="{'height':manifesturl != null || manifesturl != undefined ? '100vh' : '100vh' }">
    <iframe [src]="manifesturl | safePipePipe" *ngIf="isWorking == false" width="100%" [ngStyle]="{'height':manifesturl != null || manifesturl != undefined ? '100vh' : '100vh' }"></iframe>

</div> -->
<ngx-no-data
  class="w-100 d-flex align-items-center justify-content-center h-100"
  [title]="'Scrom Activity'"
  [showImage]="false"
  *ngIf="!isWorking"
  [discription]="'Activity will open in new open window'"
></ngx-no-data>
<div *ngIf="errormsg != null || errormsg != undefined">
  {{ errormsg }}
</div>
