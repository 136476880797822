import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { webApi } from "../../../config";

@Injectable({
  providedIn: "root",
})
export class ActivityService {
  courseData: any = {
    approvalStatus: "",
    cat_id: "",
    category: "",
    compStatus: "",
    completed_courses: "",
    courseDesc: "",
    courseTitle: "",
    courseType: "",
    courseType2: "",
    cpoints: "",
    endDate: "",
    enrolId: "",
    id: "",
    isBookmark: "",
    isDislike: "",
    isLike: "",
    nextStage: "",
    noDislikes: "",
    noLikes: "",
    noOfmodules: "",
    percComplete: "",
    startDate: "",
    total_courses: "",
    typeImage: "",
  };

  activityData: any = {
    activityId: "",
    activityNo: "",
    activityTitle: "",
    activitycompletionid: "",
    attId: "",
    attempt: "",
    behaviorName: "",
    behaviour: "",
    closingDate: "",
    completionCriteria: "",
    completionstatus: "",
    contenttime: "",
    contentwatchedtime: "",
    corsQuizId: "",
    courseId: "",
    creditAllocId: "",
    dba: "",
    delayBetweenAttempts: "",
    delayTime: "",
    dependentActId: "",
    employeeId: "",
    enrolId: "",
    fileinfo: "",
    moduleId: "",
    navType: "",
    navigation: "",
    openDate: "",
    orderType: "",
    points: "",
    qOPId: "",
    qname: "",
    qpassword: "",
    questionsOnPage: "",
    quizCompCriteria: "",
    quizId: "",
    quizOrder: "",
    scoreSelection: "",
    show: "",
    ssName: "",
    tags: "",
    tenantId: "",
    timelimit: "",
    viewed: "",
  };

  currentQuizData: any = {
    quizData: "",
    reviewFlag: false,
    quizSumitData: "",
    reattemptFlag: false,
    isReviewEn: false,
  };

  fbdata: any;
  baseUrl: any = webApi.baseUrl;
  saveActivityCompletionUrl: any =
    this.baseUrl + webApi.apiUrl.save_activity_completion;
  saveContentCompletionUrl: any = this.baseUrl + webApi.apiUrl.save_content_completion;
  checkquizUrl = this.baseUrl + webApi.apiUrl.check_quiz_for_user;
  getquizQuestion = this.baseUrl + webApi.apiUrl.get_quiz_questions;
  submitQuizQuestionAnswers =
    this.baseUrl + webApi.apiUrl.submit_quiz_questions_answers;
  getQuizReview = this.baseUrl + webApi.apiUrl.get_quiz_review;

  checkfeedbackUrl = this.baseUrl + webApi.apiUrl.checkFeedbackUser;
  getfeedbackQuestion = this.baseUrl + webApi.apiUrl.getFeedbackQuestions;
  getVivaFeedbackQuestions = this.baseUrl + webApi.apiUrl.getVivaFeedbackQuestions;
  submitFeedbackAnswersurl = this.baseUrl + webApi.apiUrl.submitFeedbackAnswers;
  submitFeedbackAnswersTTTurl = this.baseUrl + webApi.apiUrl.submitFeedbackAnswersTTT;

  // Old
  // getAttendanceDataUrl = webApi.baseUrl + webApi.apiUrl.getAttendanceActData;
  // markAttendanceUrl = webApi.baseUrl + webApi.apiUrl.attendance;

  // New
  getAttendanceDataUrl = webApi.baseUrl + webApi.apiUrl.getLearnerAttendanceDetails;
  markAttendanceUrl = webApi.baseUrl + webApi.apiUrl.markAttendanceByLearner;

  getUpdatedActivityUrl = webApi.baseUrl + webApi.apiUrl.getUpdatedActivityData;
  // private headers = new HttpHeaders();

  currentLoggedUserData: any;

  saveBatchWebinarActivityCompletion: any =
    this.baseUrl + webApi.apiUrl.batch_webinar_activity_completion;

  fetchContentDetailUrl: any = this.baseUrl + webApi.apiUrl.fetchContentDetail;
  genratepresignedurl: any = this.baseUrl + webApi.apiUrl.genratepresignedurl;
  constructor(public http: HttpClient) { }

  populateActivityCompletion(activity) {
    const currentLoggedUserData = JSON.parse(
      localStorage.getItem("currentUser")
    );
    if (!activity) {
      return null;
    } else {
      const completion = {
        // For Activity Only
        id: activity.activitycompletionid,
        activityId: activity.activityId || activity.instanceId,
        moduleId: activity.moduleId,
        courseId: activity.courseId,

        // Common for Activity and Descrete Content
        areaId: activity.areaId,
        contentwatchedtime: activity.contentwatchedtime,
        contenttime: activity.contenttime,
        enrolId: activity.enrolId,
        userid: currentLoggedUserData.id,
        tenantId: activity.tenantId,
        viewed: 1,
        completionstatus: activity.completionstatus,
        completionstate:
          activity.completionstate === undefined ? 0 : activity.completionstate,
        // languageId: activity["defaultlangId"] ? activity.defaultlangId : null,
        languageId: activity["languageId"] ? activity["languageId"] : activity.defaultlangId,
        contentId: activity.contentId,
        // stepId: activity["stepId"] ? activity.stepId : null
        compAreaId: activity.compAreaId ? activity.compAreaId : null,
        playlistId: activity.playlistId ? activity.playlistId : null,
      };
      return completion;
    }
  }

  populateContentCompletion(content) {
    const currentLoggedUserData = JSON.parse(
      localStorage.getItem("currentUser")
    );
    if (!content) {
      return null;
    } else {
      const areaId = content.areaId;
      const completion = {
        // For Activity Only
        cont_comp_Id: areaId == 34 ? content.contentcomplitionId : null,
        act_comp_Id: areaId == 34 ? null : content.activitycompletionid,
        contentId: content.contentId,
        completiontype: areaId === 34 ? 1 : 2,
        courseId: content.courseId || null,
        moduleId: content.moduleId || null,
        activityId: areaId === 34 ? null : (content.activityId || content.instanceId),
        enrolId: content.enrolId,
        completionstatus: content.completionstatus,
        completionstate: content.completionstate,
        viewed: 1,
        contentwatchedtime: content.contentwatchedtime,
        contenttime: content.contenttime,
        languageId: content["defaultlangId"] ? content.defaultlangId : null,
        wfId: content.wfId ? content.wfId : null,
        stepId: content["stepId"] ? content.stepId : null,
        empId: currentLoggedUserData.eid,
        compAreaId: content.compAreaId ? content.compAreaId : null,
        playlistId: content.playlistId ? content.playlistId : null,
        pdfPreviousPage: content.pdfPreviousPage ? content.pdfPreviousPage : null,
        pdfTotalPages: content.pdfTotalPages ? content.pdfTotalPages : null,

        /*
            activityId: content.activityId || content.instanceId,
            moduleId: content.moduleId,
            courseId: content.courseId,

            // Common for Activity and Descrete Content
            areaId: content.areaId,
            contentwatchedtime: content.contentwatchedtime,
            contenttime: content.contenttime,
            enrolId: content.enrolId,
            userid: currentLoggedUserData.id,
            tenantId: content.tenantId,
            viewed: 1,
            completionstatus: content.completionstatus,
            completionstate:
            content.completionstate === undefined ? 0 : content.completionstate,
            languageId: content["defaultlangId"] ? content.defaultlangId : null*/
      };
      return completion;
    }
  }

  // saveActivityCompletion(activity){
  //     let param = this.populateActivityCompletion(activity);
  //     let url = this.baseUrl + this.saveActivityCompletionUrl;
  //     return new Promise(resolve => {
  //         this.http.post(url, param)
  //         .subscribe(data => {
  //             resolve(data);
  //         },
  //         err => {
  //             resolve(err);
  //             if(err){
  //                 console.log('Please check server connection','Server Error!');
  //             }
  //         });
  //     });
  // }

  public saveActivityCompletionForWebinar(param: any): Promise<any> {
    return this.http
      .post(this.saveBatchWebinarActivityCompletion, param)
      .toPromise()
      .then((response) => {
        return (response = response);
      })
      .catch(this.handleError);
  }

  public saveActivityCompletion(activity: any): Promise<any> {
    // const calledFrom = this.activityData.frompage ? this.activityData.frompage : '';
    // if (calledFrom !== 'trainer') {
    const param = this.populateActivityCompletion(activity);
    return this.http
      .post(this.saveActivityCompletionUrl, param)
      .toPromise()
      .then((response) => {
        return (response = response);
      })
      .catch(this.handleError);
    // } else {
    //   console.log('called from : ', this.activityData['frompage']);
    // }
  }

  public saveContentCompletion(content: any): Promise<any> {
    // const calledFrom = this.activityData.frompage ? this.activityData.frompage : '';
    // if (calledFrom !== 'trainer') {
    const param = this.populateContentCompletion(content);
    return this.http
      .post(this.saveContentCompletionUrl, param)
      .toPromise()
      .then((response) => {
        return (response = response);
      })
      .catch(this.handleError);
    // } else {
    //   console.log('called from : ', this.activityData['frompage']);
    // }
  }

  public quiz(data: any): Promise<any> {
    return this.http
      .post(this.checkquizUrl, data)
      .toPromise()
      .then((response) => {
        return (response = response);
      })
      .catch(this.handleError);
  }

  public quizQuestion(data: any): Promise<any> {
    return this.http
      .post(this.getquizQuestion, data)
      .toPromise()
      .then((response) => {
        return (response = response);
      })
      .catch(this.handleError);
  }

  public submitQuestionAnswers(data: any): Promise<any> {
    return this.http
      .post(this.submitQuizQuestionAnswers, data)
      .toPromise()
      .then((response) => {
        return (response = response);
      })
      .catch(this.handleError);
  }


  public submitQuestionAnswersTTT(data: any): Promise<any> {
    return this.http
      .post(this.submitFeedbackAnswersTTTurl, data)
      .toPromise()
      .then((response) => {
        return (response = response);
      })
      .catch(this.handleError);
  }

  public getQuizSubmitReview(data: any): Promise<any> {
    return this.http
      .post(this.getQuizReview, data)
      .toPromise()
      .then((response) => {
        return (response = response);
      })
      .catch(this.handleError);
  }

  public feedback(data: any): Promise<any> {
    return this.http
      .post(this.checkfeedbackUrl, data)
      .toPromise()
      .then((response) => {
        return (response = response);
      })
      .catch(this.handleError);
  }

  public feedbackQuestion(data: any): Promise<any> {
    return this.http
      .post(this.getfeedbackQuestion, data)
      .toPromise()
      .then((response) => {
        return (response = response);
      })
      .catch(this.handleError);
  }

  public getVivaQuestions(data: any): Promise<any> {
    return this.http
      .post(this.getVivaFeedbackQuestions, data)
      .toPromise()
      .then((response) => {
        return (response = response);
      })
      .catch(this.handleError);
  }

  public submitfeedback(data: any): Promise<any> {
    return this.http
      .post(this.submitFeedbackAnswersurl, data)
      .toPromise()
      .then((response) => {
        return (response = response);
      })
      .catch(this.handleError);
  }

  public getAttendanceData(data: any): Promise<any> {
    return this.http
      .post(this.getAttendanceDataUrl, data)
      .toPromise()
      .then((response) => {
        return (response = response);
      })
      .catch(this.handleError);
  }
  public markAttendance(data: any): Promise<any> {
    return this.http
      .post(this.markAttendanceUrl, data)
      .toPromise()
      .then((response) => {
        return (response = response);
      })
      .catch(this.handleError);
  }
  public getQuizImages(fileurl: any): Promise<any> {
    // this.http.get(fileurl, {responseType: 'blob'})
    // .subscribe((data: Blob) => {
    //   const fileName = fileurl.substring(fileurl.lastIndexOf('/') + 1);
    //   const modifiedFileName = fileName.replace(/\s+/g, '_');
    //   // const fileName = 'yourfile.jpg';
    //   this.file.writeFile(this.file.dataDirectory, modifiedFileName, data, { replace: true })
    //   .then((fileEntry: FileEntry) => {
    //       console.log('quiz file write successful : ', fileEntry);
    //   })
    //   .catch(error => {
    //     console.log(error);
    //   });
    // });
    return this.http
      .get(fileurl, { responseType: "blob" })
      .toPromise()
      .then((data: Blob) => {
        return (data = data);
      })
      .catch(this.handleError);
  }

  public getUpdatedActivityData(data: any): Promise<any> {
    return this.http
      .post(this.getUpdatedActivityUrl, data)
      .toPromise()
      .then((response) => {
        return (response = response);
      })
      .catch(this.handleError);
  }

  public fetchContentDetail(params: any): Promise<any> {
    // const calledFrom = this.activityData.frompage ? this.activityData.frompage : '';
    // if (calledFrom !== 'trainer') {
    return this.http
      .post(this.fetchContentDetailUrl, params)
      .toPromise()
      .then((response) => {
        return (response = response);
      })
      .catch(this.handleError);
    // } else {
    //   console.log('called from : ', this.activityData['frompage']);
    // }
  }

  public getPreSignedUrl(params: any): Promise<any> {
    return this.http
      .post(this.genratepresignedurl, params)
      .toPromise()
      .then((response) => {
        return (response = response);
      })
      .catch(this.handleError);
  }

  private handleError(error: Response | any) {
    return Promise.reject(error.message || error);
  }
}
