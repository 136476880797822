import { Component, OnInit, Output, Input, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'ngx-mark-attendance',
  templateUrl: './mark-attendance.component.html',
  styleUrls: ['./mark-attendance.component.scss']
})
export class MarkAttendanceComponent implements OnInit, OnChanges {

  type = 4;
  paramsObject = null;

  @Output() componentData = new EventEmitter();

  @Input() activity: any = {};
  @Input() moduleActivity: any = {};

  @Input() externalHeight: any = '60vh';

  showAttendanceComponent = false;
  currentUserDetails = null;

  attendanceComponentType = 0;

  constructor() {

  }

  ngOnInit(): void {
    this.currentUserDetails = JSON.parse(localStorage.getItem("currentUser"));
    if (this.currentUserDetails.roleId == 7) {
      this.showAttendanceComponent = true;
    }
    this.attendanceComponentType = this.currentUserDetails.roleId;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.activity) {
      console.log('MarkAttendanceComponent activity : ', this.activity);
    }
  }

  getEvents(event) {
    console.log('MarkAttendanceComponent getEvents : ', event);
    this.componentData.emit(event);
  }

  sendEvents(action, data) {
    const event = {
      action: action,
      data: data,
    }
    // console.log('sendEvents', event);
    this.componentData.emit(event);
  }

}
