<div class="ftable d-grid py-4">
    <ngx-filter></ngx-filter>
    <div class="pl-4 ml-2">
        <div class="d-flex justify-content-between align-items-center pb-3">
            <h6 class="m-0">{{ config?.pageTitle }}</h6>
            <div class="fs-14 d-flex align-items-center">
                <input class="border outline-none rounded p-1 px-2 mx-2" type="text" placeholder="search text">
                <button class="commonBtn btn-outline mx-2">Mark Present</button>
                <button class="commonBtn btn-outline mx-2">Mark Absent</button>
                <button class="commonBtn btn-outline mx-2">Send Reminder</button>
                <span class="text-primary cursor-p">Back</span>
            </div>
        </div>

        <div class="widget d-grid body-text mb-4" *ngIf="config?.cards?.showCards">
            <div class="d-flex bg-pastel-{{i+1}} rounded p-4"
                *ngFor="let item of config?.cards?.cardList;let i = index;">
                <svg-icon src="assets/icon-svg/audience_outline.svg" class="stroke-black"
                    [svgStyle]="{'width.rem': 2.5, 'height.rem': 2.5}">
                </svg-icon>
                <div class="text-center pr-5 w-100">
                    <h2>{{ item.count }}</h2>
                    <p class="m-0">{{ item.name }}</p>
                </div>
            </div>
        </div>
        
        <div class="tscroll">
            <ngx-table [tableData]="config?.tableData" [pagination]="true" [label]="config?.labels"></ngx-table>
        </div>
    </div>
</div>