import { ModuleWithProviders, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  NbActionsModule,
  NbLayoutModule,
  NbUserModule,
  NbContextMenuModule,
  NbIconModule,
  NbSpinnerModule,
  NbThemeModule,
  NbBadgeModule,
  NbPopoverModule,
  NbListModule,
} from "@nebular/theme";
import { NbEvaIconsModule } from "@nebular/eva-icons";
// import { NbSecurityModule } from "@nebular/security";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularSvgIconModule } from 'angular-svg-icon';
import {
  HeaderComponent,
} from "./components";
import {
  OneColumnLayoutComponent,
  CIPOneColumnLayoutComponent,
  ManagerOneColumnLayoutComponent
} from "./layouts";
import { DEFAULT_THEME } from "./styles/theme.default";
import { COSMIC_THEME } from "./styles/theme.cosmic";
import { CORPORATE_THEME } from "./styles/theme.corporate";
import { DARK_THEME } from "./styles/theme.dark";
import { NotificationDrawerComponent } from "./components/header/notification-drawer/notification-drawer.component";
import { ProfileDrawerComponent } from "./components/header/profile-drawer/profile-drawer.component";
import { HeaderSearchComponent } from "./components/header/header-search/header-search.component";
import { HeaderDropListComponent } from './components/header/header-drop-list/header-drop-list.component';
import { DirectivesModule } from '../../app/directives/directives.module';
import { ComponentsModule } from '../components/components.module';
import { MoreDrawerComponent } from './components/header/more-drawer/more-drawer.component';
import { LanguageDropdownComponent } from './components/header/language-dropdown/language-dropdown.component';

const NB_MODULES = [
  NbLayoutModule,
  // NbMenuModule,
  NbUserModule,
  NbActionsModule,
  // NbSearchModule,
  NbBadgeModule,
  NbContextMenuModule,
  // NbSecurityModule,
  // NbButtonModule,
  // NbSelectModule,
  NbIconModule,
  NbEvaIconsModule,
  NbSpinnerModule,
  NbListModule,
  // NbCardModule,
  NbPopoverModule,
  ReactiveFormsModule,
  DirectivesModule,
  ComponentsModule,
  AngularSvgIconModule,
  FormsModule
];
const COMPONENTS = [
  // SwitcherComponent,
  // LayoutDirectionSwitcherComponent,
  HeaderComponent,
  // FooterComponent,
  // SearchInputComponent,
  OneColumnLayoutComponent,
  NotificationDrawerComponent,
  // ThreeColumnsLayoutComponent,
  // TwoColumnsLayoutComponent,
  ProfileDrawerComponent,
  CIPOneColumnLayoutComponent,
  HeaderSearchComponent,
  HeaderDropListComponent,
  MoreDrawerComponent,
  ManagerOneColumnLayoutComponent,
  LanguageDropdownComponent
];
const PIPES = [
];

@NgModule({
  imports: [CommonModule, ...NB_MODULES],
  exports: [CommonModule, ...PIPES, ...COMPONENTS],
  declarations: [...COMPONENTS, ...PIPES],
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders<ThemeModule> {
    return {
      ngModule: ThemeModule,
      providers: [
        ...NbThemeModule.forRoot(
          {
            name: "default",
          },
          [DEFAULT_THEME, COSMIC_THEME, CORPORATE_THEME, DARK_THEME]
        ).providers,
      ],
    };
  }
}
