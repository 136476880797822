<!-- modal-dialog -->
<div class="content dialog modal-dialog-centered" role="document" nbSpinnerStatus="primary" [nbSpinner]="shareFormSubmitting">
    <div class="modal-content" [nbSpinner]="loading && selectedUserList?.length !=0 && searchUserList?.length !=0" nbSpinnerStatus="primary">
        <div class="modal-header header">
            <p>{{modelSetting?.popupTitle}}</p>
            <button type="button" *ngIf="modelSetting.showClose" (click)="performActionOnClick(closeEvent, null)" class="close" data-dismiss="modal" aria-label="Close">
  <span aria-hidden="true">&times;</span>
</button>
        </div>
        <div class="modal-body py-0 body">
            <div class="search-bar-container">
                <!-- (keyup.enter)="prepareSearchData($event.target.value)"  -->
                <input type="text" class="search-bar-container-search mb-1" placeholder="Add Employee" [formControl]="searchControl" />
                <span class="search-bar-container-cancel" *ngIf="searchTerm !== ''" (click)="clearSearch()">
            <nb-icon class="cancel-icon " icon="close-outline" class="mr-2 cursor-p"></nb-icon>
          </span>
            </div>
            <div nbInfiniteList [threshold]="250" class="search-container" (bottomThreshold)="loadMoreData($event)">
                <div *ngFor="let user of selectedUserList| advFilterPipe:searchTerm:modelSetting?.localFilterParams:filterMetadata; let isFirst = first">
                    <ngx-user-searchable-list [config]="modelSetting" [ngClass]="{ 'd-block': !isFirst }" (getEvent)="performActionOnClick($event, user)" [user]="user"></ngx-user-searchable-list>
                </div>
                <ngx-user-searchable-list [config]="modelSetting" [ngClass]="{ 'd-block': !isFirst }" (getEvent)="performActionOnClick($event, user)" *ngFor="let user of searchUserList; let isFirst = first" [user]="user"></ngx-user-searchable-list>

                <ng-container *ngIf="working || loading && !shareFormSubmitting">
                    <div class="search-spinner-container" [nbSpinner]="true" nbSpinnerStatus="primary">

                    </div>
                </ng-container>
                <ng-container *ngIf="!loading && !working && notFound">
                    <div class="no-data-found-container-search">
                        <!-- noDataFoundContainer?.title -->
                        <ngx-no-data [showImage]="noDataFoundContainer?.showImage" [title]="" [discription]="noDataFoundContainer?.discription"></ngx-no-data>
                    </div>
                </ng-container>
            </div>

            <!-- </nb-list>
            </nb-card>
      </nb-body> -->

        </div>
        <div class=" modal-footer footer footer-btn d-flex justify-content-end" *ngIf="modelSetting?.showFooter">
            <!-- <button type="button" class="customBtn"><nb-icon icon="link-outline"></nb-icon>
                  Copy Link</button> -->
            <div class="d-flex">
                <!-- <button type="button" class="customBtnWhite" (click)="closePopup(null)">Cancel</button>
                <button type="button" class="commonBtn" *ngFor="let item of modelSetting?.button"
                [disabled]="shareFormSubmitting"
                (click)="shareContentToUser()">
                Share
              </button> -->
                <button type="button" *ngFor="let item of modelSetting?.button; let isFirst = first" class="{{item?.classList}}" (click)="performActionOnClick(item)" [ngClass]="{ 'ml-2': !isFirst }">
                {{item?.btnText}}
              </button>
            </div>
        </div>
    </div>
</div>