import { Injectable } from '@angular/core';
import { webApi } from "../../../../config";
import { HttpClient, HttpHeaders } from "@angular/common/http";
@Injectable({
  providedIn: 'root'
})
export class DeliveryService {
  baseUrl: any = webApi.baseUrl;
  getDeliveryTabs: string = this.baseUrl + webApi.apiUrl.getBatchTabs;
  getBatchActivityDropdown: string = this.baseUrl + webApi.apiUrl.getBatchActivityDropdown;
  getAttendanceList: string = this.baseUrl + webApi.apiUrl.getAttendanceList;
  markUnmarkAttendance: string = this.baseUrl + webApi.apiUrl.markUnmarkAttendance;
  getMockList: string = this.baseUrl + webApi.apiUrl.getMockList;
  getFinalAssesmentList: string = this.baseUrl + webApi.apiUrl.getFinalAssesmentList;
  certifyLearnerFinalAssesment: string = this.baseUrl + webApi.apiUrl.certifyLearnerFinalAssesment;
  generatePPR: string = this.baseUrl + webApi.apiUrl.generatePPR;
  getAttendanceActivityUsersUrl: string = this.baseUrl + webApi.apiUrl.getAttendanceActivityUsers;
  getDeliveryCategotyProgramList: string = this.baseUrl + webApi.apiUrl.getDeliveryCategotyProgramList;
  tabData: any = {};
  constructor(public http: HttpClient) { }

  public async getTabs(data: any): Promise<any> {
    try {
      const response = await this.http.post(this.getDeliveryTabs, data).toPromise();
      return response;
    } catch (error) {
      return this.handleError(error);
    }
  };

  public async fetchActivityDropdown(data: any): Promise<any> {
    try {
      const response = await this.http.post(this.getBatchActivityDropdown, data).toPromise();
      return response;
    } catch (error) {
      return this.handleError(error);
    }
  };

  public async fetchAttendanceList(data: any): Promise<any> {
    try {
      const response = await this.http.post(this.getAttendanceList, data).toPromise();
      return response;
    } catch (error) {
      return this.handleError(error);
    }
  };

  public async markUnmark(data: any): Promise<any> {
    try {
      const response = await this.http.post(this.markUnmarkAttendance, data).toPromise();
      return response;
    } catch (error) {
      return this.handleError(error);
    }
  };

  public async fetchMockList(data: any): Promise<any> {
    try {
      const response = await this.http.post(this.getMockList, data).toPromise();
      return response;
    } catch (error) {
      return this.handleError(error);
    }
  };

  public async fetchFinalAssessmentList(data: any): Promise<any> {
    try {
      const response = await this.http.post(this.getFinalAssesmentList, data).toPromise();
      return response;
    } catch (error) {
      return this.handleError(error);
    }
  };

  public async certifyLearner(data: any): Promise<any> {
    try {
      const response = await this.http.post(this.certifyLearnerFinalAssesment, data).toPromise();
      return response;
    } catch (error) {
      return this.handleError(error);
    }
  };

  public async getPPR(data: any): Promise<any> {
    try {
      const response = await this.http.post(this.generatePPR, data).toPromise();
      return response;
    } catch (error) {
      return this.handleError(error);
    }
  }

  /*--------------Get Batch Activity Attendance Data----------------*/

  public getAttendanceActivityUsers(data): Promise<any> {
    return this.http
      .post(this.getAttendanceActivityUsersUrl, data)
      .toPromise()
      .then((response) => {
        return (response = response);
      })
      .catch(this.handleError);
  }
  /*--------------END TABS Data----------------*/

  /*--------------Get Batch Activity Attendance Data----------------*/

  public getCategotyProgramList(data): Promise<any> {
    return this.http
      .post(this.getDeliveryCategotyProgramList, data)
      .toPromise()
      .then((response) => {
        return (response = response);
      })
      .catch(this.handleError);
  }
  /*--------------END TABS Data----------------*/


  /*--------------Start Data----------------*/

  public fetchSearchableSelectListDynamic(api, data): Promise<any> {
    const url = this.baseUrl + api;
    return this.http
      .post(url, data)
      .toPromise()
      .then((response) => {
        return (response = response);
      })
      .catch(this.handleError);
  }
  /*--------------END  Data----------------*/

  private handleError(error: Response | any) {
    return Promise.reject(error.message || error);
  };
}
