<div [hidden]="!show" class="popup-bg"  (click)="close()"></div>
<div class="popup">
    <div class="popup__dialog"  [ngClass]="{'show': show}">
        <div class="popup__content">
            <div class="popup__header d-flex align-items-center justify-content-center text-center bg-danger border-bottom p-3">
                <svg-icon svgClass="fill-white" src="assets/icon-svg/warning.svg" alt="icon" [stretch]="true" [svgStyle]="{ 'width.px':70, 'height.px':70}"></svg-icon>
            </div>
            <div class="popup__body p-3">
                <div class="text-center">
                    <h2 class="ion-font-weight-bold ion-padding-top">Warning!</h2>
                    <h6 class="body-text font-weight-normal py-3">An error has occurred while creating the popup.</h6>
                    <button class="button btn-fill bg-danger"  (click)="close()">OK</button>
                </div>
            </div>
        </div>
    </div>
</div>