import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'attFilter',
  pure: false
})
export class AttFilterPipe implements PipeTransform {

  transform(items: any[], field: string, value: string, filterMetadata?: any): any[] {
    if (!items) {
      if (filterMetadata) {
        filterMetadata.count = 0;
      }
      return [];
    }
    if (!value || value.length == 0) {
      if (filterMetadata) {
        filterMetadata.count = items.length;
      }

      return items;
    }
    let filteredItems = this.filter(items, field, value);
    // if (filterMetadata && filterMetadata['filterKeys'] && filterMetadata['filterKeys'].length != 0) {
    //   filterMetadata['filterKeys'].forEach(element => {
    //     filteredItems = this.filter(filteredItems, element, value)
    //   });
    // }
    if (filterMetadata) {
      filterMetadata.count = filteredItems.length;
    }
    return filteredItems;
  }

  filter(items, field, value) {
    return items.filter(it =>
      it[field].toLowerCase().indexOf(value.toLowerCase()) != -1);
  }

}
