// tslint:disable-next-line:max-line-length
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { KpointService } from "../../providers/kpoint/kpoint.service";
declare var kPoint;
let _this: any;
var kpointprivate;

@Component({
  selector: "ngx-k-point",
  templateUrl: "./k-point.component.html",
  styleUrls: ["./k-point.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class KPointComponent implements OnInit, AfterViewInit, OnDestroy {
  kPointObj: any;
  @ViewChild("kpplayer") kpplayer: any;
  // @Input() activity: activity;
  @Input() activity: any;
  @Output() saveEvent = new EventEmitter<any>();

  passData = {
    status: null,
    duration: 0,
    currentTime: 0,
  };

  kPoint = {
    host: "",
    id: "",
    src: "",
    url: "",
    player: "kPlayer",
    params: {
      "add-widgets": ["hello"],
      "add-config": {
        hello: {
          enabled: 1,
        },
      },
    },
  };

  currentProgress: any = 0;
  isPlayerFullScreen = true;
  token: string;
  constructor(
    private kpointservice: KpointService // private userservice: UserService
  ) { }

  ngOnInit() {
    console.log("kPoint - ", this.activity);
    this.makeKPointDataReady(this.activity);
    this.kPointObj = this.kPoint;
  }
  ngAfterViewInit() {
    setTimeout(() => {
      if (document.addEventListener) {
        document.addEventListener(
          "webkitfullscreenchange",
          this.togglefullscreen,
          false
        );
        document.addEventListener(
          "mozfullscreenchange",
          this.togglefullscreen,
          false
        );
        document.addEventListener(
          "fullscreenchange",
          this.togglefullscreen,
          false
        );
        document.addEventListener(
          "MSFullscreenChange",
          this.togglefullscreen,
          false
        );
      }
      _this = this;
    }, 500);
    try {
      const user = JSON.parse(localStorage.getItem("currentUser"));
      if (user) {
        const param = {
          email: user.email,
          displayname: user.firstname + " " + user.lastname,
          // 'userId': 519,
          // 'account_number': '23X519'
        };
        this.kpointservice
          .getToken(param)
          .then((result) => {
            this.token = result["token"];
            this.kpointConfigure(result["token"]);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } catch (e) { }
  }

  kpointConfigure(token) {
    // const offset = this.activity.contentwatchedtime;
    this.activity.contentwatchedtime = this.secondsToMiliseconds(this.activity.contentwatchedtime);
    this.activity.contenttime = this.secondsToMiliseconds(this.activity.contenttime);
    let offset = 0;
    if (
      (this.activity.contentwatchedtime / this.activity.contenttime) * 100 <
      97
    ) {
      offset = this.activity.contentwatchedtime;
    }

    let isAutoPlay = this.activity.isAutoPlay ? true : false;

    const player = kPoint.Player(this.kpplayer.nativeElement, {
      kvideoId: this.kPointObj.id,
      videoHost: this.kPointObj.host,
      params: {
        autoplay: isAutoPlay,
        xt: token,
        // 'offset': offset,
        hide: "search, toc, logo",
        resume: false,
        "playercontrols": {"hide": "fullscreen"}
      },
    });

    // player.addEventListener(player.events.loaddata, () => {
    //   console.log(player.events.loaddata);
    //   // if (this.activity.contentwatchedtime) {
    //   //   this.setProgress(this.activity.contentwatchedtime);
    //   // }
    //   this.setPlayerDuration(player);
    // });
    player.addEventListener(player.events.loaded, () => {
      console.log(player.events.loaddata);
      // if (this.activity.contentwatchedtime) {
      //   this.setProgress(this.activity.contentwatchedtime);
      // }
      this.setPlayerDuration(player);
    });
    player.addEventListener(player.events.onStateChange, () => {
      console.log(player.events.onStateChange);
      // console.log('getCurrentTime - ' , player.getCurrentTime());
      // console.log('getDuration - ' , player.getDuration());
      // console.log('getPlayState - ' , player.getPlayState());
      // if (player.getPlayState() == player.playStates.REPLAYING) {
      //   // player.replayVideo();
      //   this.activity.contentwatchedtime = 0;
      //   this.kpointConfigure(this.token);
      // }
      const playstate = player.getPlayState();
      console.log("State ", playstate);
      if (playstate === player.playStates.REPLAYING) {
        offset = 0;
      }
      this.getProgress(player);
      this.setWatchedData(player);
      this.resizeKpoint(document["fullscreenElement"]);
    });
    player.addEventListener(player.events.timeUpdate, () => {
      // console.log(player.events.timeUpdate);
    });
    player.addEventListener(player.events.ready, () => {
      console.log(player.events.ready);
      // if (this.activity.contentwatchedtime && this.activity.contenttime !== this.activity.contentwatchedtime) {
      //   const s = this.activity.contentwatchedtime;
      //   // const ms = s * 1000;
      //   player.seekTo(s);
      // }
      // autoplay
      // if (isAutoPlay) {
      //   setTimeout(() => {
      //     player.playVideo();
      //     // player.startVideo();
      //   }, 100);
      // }
    });
    player.addEventListener(player.events.started, () => {
      console.log(player.events.started);
      player.seekTo(offset);
      // var ms = this.activity.contentwatchedtime * 1000;
      // player.seekTo(ms);
    });

    // Old ended event
    // player.addEventListener(player.events.ended, () => {
    //   console.log("Kpoint ended : ", player.events.ended);
    //   this.makePassDataReady('Y', player.getDuration(), player.getCurrentTime());
    //   this.saveEvent.emit(this.passData);
    // });

    player.addEventListener(player.events.kapsuleEnded, () => {
      console.log('Kpoint kapsuleEnded : ', player.events.kapsuleEnded);
      this.makePassDataReady('Y', player.getDuration(), player.getCurrentTime());
      this.saveEvent.emit(this.passData);
    });

    player.addEventListener(player.events.error, () => {
      console.log(player.events.error);
    });
    player.addEventListener(player.events.reinit, () => {
      console.log(player.events.reinit);
    });
    kpointprivate = player;
  }

  getProgress(player) {
    this.currentProgress = player.getCurrentTime();
  }

  setPlayerDuration(player) {
    this.activity.contenttime = player.getDuration();
  }

  makeKPointDataReady(activity) {
    const kpointData = activity;
    const kPointUrl = activity.reference;
    if (kPointUrl.split("/").length > 0) {
      this.kPoint.host = kPointUrl.split("/")[2];
      // this.kPoint.id = kPointUrl.split("/")[4];
      this.kPoint.id = this.kpointparser(kPointUrl);
      this.kPoint.src = kPointUrl;
      this.kPoint.url =
        kPointUrl.split("/")[0] + "//" + kPointUrl.split("/")[2];
    }
  }

  kpointparser(url) {
    if (!url) return url;
    const regExp = /^.*((kapsule\/)|(video\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
    const match = url.match(regExp);
    if (match && match[7]) {
      const b = match[7].split('/')[0];
      console.log(b);
      return b;
    } else {
      // console.log('Incorrect URL');
      const regExp = /^.*((kapsule\/)|(video\/)|(web\/videos\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;;
      const match = url.match(regExp);
      if (match && match[8]) {
        var b = match[8].split('/')[0];
        console.log(b);
        return b;
      } else {
        console.log('Incorrect URL');
        return url;
      }
    }
  }

  setWatchedData(player) {
    const currentTime = Math.round(player.getCurrentTime());
    const totalDuration = Math.round(player.getDuration());
    console.log("k-point round off currentTime : ", currentTime);
    console.log("k-point round off totalDuration : ", totalDuration);
    if (this.currentProgress === 0) {
      this.makePassDataReady(
        null,
        player.getDuration(),
        player.getCurrentTime()
      );
      // this.saveEvent.emit(this.passData);
      // } else if (player.getDuration() === player.getCurrentTime()) {
    } else if (currentTime === totalDuration) {
      this.makePassDataReady(
        "Y",
        player.getDuration(),
        player.getCurrentTime()
      );
      // this.saveEvent.emit(this.passData);
    } else {
      this.makePassDataReady(
        null,
        player.getDuration(),
        player.getCurrentTime()
      );
      // this.saveEvent.emit(this.passData);
    }
  }

  makePassDataReady(status, duration, currentTime) {
    this.passData = {
      status: status,
      duration: this.milisecondsToSeconds(duration),
      currentTime: this.milisecondsToSeconds(currentTime),
    };
    // this.passData = {
    //   status: status,
    //   duration: duration,
    //   currentTime: currentTime,
    // };
    console.log("K-point activity completion data : ", this.passData);
  }

  togglefullscreen(e) {
    // this.video.video.nativeElement.requestFullscreen();
    // console.log('fullscreen ', _this.video.isFullscreen);
    if (!window.screenTop && !window.screenY) {
      _this.resizeKpoint(false);
      console.log("not fullscreen");
    } else {
      // this.windowFullScreen = true;
      _this.resizeKpoint(true);
      console.log("fullscreen");
    }
    _this = _this;
  }

  resizeKpoint(fullScreen) {
    setTimeout(() => {
      const ratio = this.getAspectRation(fullScreen);
      if (ratio) {
        const element = document.querySelector(".kresizable");
        if (element) {
          element["style"]["paddingBottom"] = ratio + "%";
        }
      }
    });
  }
  getAspectRation(fullScreen) {
    let parentElementSize = null;
    if (fullScreen) {
      parentElementSize = document["fullscreenElement"];
    } else {
      parentElementSize = document.querySelector("#fullDiv");
    }
    if (parentElementSize) {
      const number =
        (Number(parentElementSize["offsetHeight"]) /
          Number(parentElementSize["offsetWidth"])) *
        100;
      return number;
    }
    return null;
    //  const number =  (Number(parentElementSize['offsetHeight'])
    //   / Number(parentElementSize['offsetWidth'])) * 100;
    //   return number;
  }
  millisToMinutesAndSeconds(millis) {
    let minutes = Math.floor(millis / 60000);
    let seconds: any = ((millis % 60000) / 1000).toFixed(0);
    return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
  }
  milisecondsToSeconds(mili) {
    return mili / 1000;
  }
  secondsToMiliseconds(seconds) {
    return seconds * 1000;
  }
  ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    this.setWatchedData(kpointprivate);
    this.saveEvent.emit(this.passData);
  }
}
