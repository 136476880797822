<div class="work d-inline-block border cursor-p" (click)="fireEvent('goToContent',workflowData)" [ngClass]="{'work-rs': wokflowConfig.responsive, 'work-card': wokflowConfig.customClass, 'work-border': wokflowConfig.border }">
    <div class="work__img work-rs__img">
        <img [src]="workflowData[wokflowConfig.image]" onerror="this.onerror=null; this.src='./assets/images/activity1.jpg'" alt="workflow-image">
        <div class="allIcons text-right" (mouseleave)="mouseLeave(workflowData)">
            <i class="fas fa-ellipsis-v option cursor-p" *ngIf="workflowData?.isMoreEnable == 1" (mouseenter)="mouseEnter(workflowData) "></i>
            <!-- <label class="m-0 d-block cursor-p " (click)="outputEvent(cardItem, cardEntity.pin) ">
            <svg-icon src="../../../assets/icon-svg/course-card/pin.svg "   [stretch]="true ">
            </svg-icon>
            <span>{{ cardsData?.isPinned === 1 ? 'Pinned' : 'Pin' }}</span>
        </label> -->
            <!-- *ngIf="selectedSubTabName=='ONGOING' " *ngIf="selectedSubTabName=='ONGOING' " -->
            <div class="showlist " *ngIf="workflowData?.show ">

                <!-- <label class="m-0 d-block cursor-p " (click)="fireEvent('schedule',cardsData)">
            <svg-icon src="../../../assets/icon-svg/course-card/clock.svg "   [stretch]="true ">
            </svg-icon>
            <span>{{ cardsData[config.scheduleFlag] ? 'Reschedule' : 'Schedule' }}</span>
        </label>
              <label class="m-0 d-block cursor-p " (click)="fireEvent('addToCart',cardsData)">
            <svg-icon src="../../../assets/icon-svg/course-card/cart.svg "   [stretch]="true ">
            </svg-icon>
            <span>{{ cardsData[config.goToCartFlag] ? 'Go To cart' : 'Add to cart' }}</span>
        </label> -->
                <ng-container *ngFor="let item of buttons">
                    <label class="m-0 d-block cursor-p " *ngIf="item?.isVisible == 1" (click)="fireEventButton($event ,item.event.id,  item.event.value)">
              <svg-icon [src]="item?.icon"   [stretch]="true ">
              </svg-icon>
              <span>{{ item?.text }}</span>
          </label>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="work__content work-rs__content">
        <h4 class="m-0">{{ workflowData[wokflowConfig.name] }}</h4>
        <div class="datepoints d-flex justify-content-between" *ngIf="wokflowConfig?.showDate || wokflowConfig.showPoints">
            <span class="datepoints__date" *ngIf="wokflowConfig.showDate">{{ workflowData[wokflowConfig.date] }}</span>
            <span class="datepoints__points" *ngIf="wokflowConfig.showPoints">{{ workflowData[wokflowConfig.points] }}</span>
        </div>
        <div class="location d-flex align-items-center mt-1" *ngIf="wokflowConfig.showLocation">
            <svg-icon class="location__icon" src="assets/icon-svg/ttt/location.svg"></svg-icon>
            <span class="pl-2">{{workflowData[wokflowConfig.location]}}</span>
        </div>
    </div>
</div>