import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { WorkflowCard } from '../../interfaces/workflow-card.model';

@Component({
  selector: 'ngx-workflow-card',
  templateUrl: './workflow-card.component.html',
  styleUrls: ['./workflow-card.component.scss', '../../@theme/styles/_typography.scss']
})
export class WorkflowCardComponent implements OnInit {

  @Input() workflowData: any;
  @Output() getEvents = new EventEmitter();
  @Input() wokflowConfig: WorkflowCard = {
    image: '',
    name: '',
    date: '',
    points: '',
    location: '',
    showLocation: false,
    responsive: true,
    // className: '',
    customClass: false,
    border: true,
  };
  buttons = [];
  constructor() { }

  ngOnInit(): void {
    if (this.workflowData) {

    }
  }

  mouseLeave(item) {
    item.show = false;
  }

  mouseEnter(item) {
    item.show = true;
  }

  fireEvent(event, data) {
    // if (data.compStatus !== 'UPCOMING') {
    const dataOutput = {
      action: event,
      data: data,
      // liked : isLiked,
    };

    console.log('Fire event called : ', dataOutput);
    this.getEvents.emit(dataOutput);
    // } else {
    //   return false;
    // }
  }

  fireEventButton(eventStopData, event, data) {
    this.stopPropagation(eventStopData);
    this.fireEvent(event, data)
  }
  stopPropagation(event) {
    if (event) {
      event.stopPropagation();
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    // console.log('options', changes)
    if (changes && changes.workflowData) {
      const options = this.makeButtonsReady(this.workflowData);
      // const buttons = []
      this.buttons = [];
      // console.log('options', options)
      for (let opt of options) {
        if (opt.isVisible) {
          // let button = {
          //   text: opt.text,
          //   cssClass: opt.cssClass,
          //   icon: opt.icon,
          // }
          this.buttons.push(opt);
        }
      }
      // console.log('buttons', this.buttons);
    }
    // if(changes)
  }

  makeButtonsReady(cardsData) {
    const options = [
      {
        text: 'Generate PPR',
        cssClass: cardsData.isPPREnable == 0 ? 'inactive' : 'active',
        icon: 'assets/icon-svg/course-card/pin.svg',
        event: {
          id: 'generateReportAndPPR',
          value: cardsData,
        },
        isVisible: cardsData.isPPRShow == 0 ? false : true
      },
    ];
    return options;
  }
}
