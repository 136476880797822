import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ngx-field-icon',
  templateUrl: './field-icon.component.html',
  styleUrls: ['./field-icon.component.scss']
})
export class FieldIconComponent implements OnInit {
  @Input() labelData = {
    userImage: 'icon',
    name: 'name'
  };
  @Input() setting = null;
  @Input() defaultImage = '';
  @Input() data: any = {

  };
  constructor() { }

  ngOnInit(): void {
  }

}
