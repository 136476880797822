import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ngx-header-drop-list',
  templateUrl: './header-drop-list.component.html',
  styleUrls: ['./header-drop-list.component.scss']
})
export class HeaderDropListComponent implements OnInit {
  @Input() categoryList: any = null;
  @Input() config = {};
  @Output() getEvent = new EventEmitter<any>();
  innerArray = [];
  hideChild = false;
  constructor() { }

  ngOnInit(): void {
  }


  innnerTag(item) {
    // console.log('showitemn', item);
    if (item && item.children) {
      let array = item.children;
      if (array) {
        for (let index = 0; index < array.length; index++) {
          const element = array[index];
          element['activeFlag'] = false;
        }
      }
      for (let index = 0; index < this.categoryList.length; index++) {
        const element = this.categoryList[index];
        element['activeFlag'] = false;
      }
      item['activeFlag'] = true;
      let catValue = '';
      if (item.field_value) {
        catValue = item.field_key + '_' + item.field_value;
      } else {
        catValue = item.field_key;
      }
      let breadCrumbs = {
        field_id: item.field_id,
        field_key: item.field_name,
        field_name: item.field_name,
        field_value: item.field_value,
        lov_type_id: item.lov_type_id,
        lov_type_key: item.lov_type_key,
        parent_field_id: item.parent_field_id,
        value_name: item.value_name,
        catFilterValue: catValue,
        catFilterKey: item.filter_on,
      }
      let breadCrumbsList = [];
      if (item['breadcrumbs']) {
        breadCrumbsList = [...item['breadcrumbs']];
      }
      breadCrumbsList.push(breadCrumbs);
      for (let index = 0; index < array.length; index++) {
        array[index]['breadcrumbs'] = breadCrumbsList;
      }
      this.innerArray = array;
      // this.levelUp = true;
      // console.log('showitemn1', this.innerArray);
    }

    // for (let index = 0; index < this.innerArray.length; index++) {
    //   this.innerArray[index]['show'] = false;
    // }
  }

  removeInner() {
    // console.log('cat', cat);
    // for (let index = 0; index < this.innerArray.length; index++) {
    //   this.innerArray[index]['show'] = false;
    // }
    this.innerArray = [];
    // for (let i = 0; i < this.innerArray.length; i++) {
    //   if (cat.field_id === this.innerArray[i].field_id) {
    //     this.innerArray.splice(i, 1);
    //   }
    // }
    // for(let i = 0; i < this.catIdArray.legth; i++) {
    //   if(cat.categoryId === this.catIdArray[i]) {
    //     this.catIdArray.splice(i, 1);
    //     console.log('removecsat', this.catIdArray);
    //   }
    // }
    // this.catIdArray = this.catIdArray.filter(function(item) {
    //   return item !== cat.categoryId
    // });
    // console.log('removecsat', this.catIdArray);
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log('changes', changes);
    if ('categoryList' in changes) {
      // do your thing
      this.innerArray = [];
    }
  }

  performAction(performAction) {
    // console.log(performAction, " Category selected!");
    this.getEvent.emit(performAction);
  }

}
