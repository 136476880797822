<div class="animcard position-relative cursor-p" (mouseenter)="mouseEnter()" (mouseleave)="mouseLeave()"
  [ngClass]="{ 'hover': hideCard }">
  <div class="animcard__top position-relative">
    <img class="animcard__top--image hover-image" (click)="fireEvent('goToContent',cardItem)"
      [src]="cardItem?.instanceThumbnail" onerror="this.onerror=null; this.src='./assets/images/activity1.jpg'"
      alt="card-image" />
    <div class="animcard__top--bg position-absolute hover-bg" (click)="fireEvent('goToContent',cardItem)">
      <h4 class="animcard__top--text m-0">{{ cardItem[cardConfig["title"]] }}</h4>
    </div>
    <div class="allIcons text-right" (mouseleave)="hideIcon()">
      <svg-icon (mouseenter)="showIcon(cardItem)" class="cursor-p"
        src="../../../assets/icon-svg/course-card/vertical-three-dot.svg" [stretch]="false"></svg-icon>
      <!-- <i class="fas fa-ellipsis-v option cursor-p" (mouseenter)="showIcon(cardItem)"></i> -->
      <div class="showlist mt-1" [nbSpinner]="cardItem?.loading" nbSpinnerStatus="primary" nbSpinnerMessage="Loading..."
        nbSpinnerSize="large" *ngIf="!flagStart" [hidden]="!show">
        <!-- <label class="m-0 d-block cursor-p" (click)="outputEvent(cardItem, cardEntity.schedule)">
                <svg-icon src="../../../assets/icon-svg/course-card/clock.svg"  [stretch]="true"></svg-icon>
                <span>{{ cardItem[cardConfig['isScheduled']]  == 1 ? 'Reschedule' : 'Schedule' }}</span>
            </label>
                <label class="m-0 d-block cursor-p" (click)="outputEvent(cardItem, cardEntity.addtocart)">
                <svg-icon src="../../../assets/icon-svg/course-card/cart.svg" [stretch]="true"></svg-icon>
                <span>{{ cardItem[cardConfig['isAddToCart']]  != 0 ? 'Go To cart' : 'Add to cart' }}</span>
            </label> -->
        <ng-container *ngFor="let item of buttons">
          <!-- (click)="fireEvent( item.event.id,  item.event.value)" -->
          <span class="m-0 d-block align-items-center cursor-p " (click)="optionClicked(item.event, cardItem)">
            <svg-icon [src]="item?.icon" [stretch]="true ">
            </svg-icon>
            <span class="more-text">{{ item?.text }}</span>
          </span>
        </ng-container>
      </div>
      <div class="showlist" *ngIf="flagStart" id="step-card-hover">
        <!-- <label class="m-0 d-block cursor-p" (click)="outputEvent(cardItem, cardEntity.pin)">
              <svg-icon src="../../../assets/icon-svg/course-card/pin.svg"   [stretch]="true"></svg-icon>
              <span>{{ cardItem[cardConfig['isPinned']] == 1 ? 'Pinned' : 'Pin' }}</span>
          </label>
                <label class="m-0 d-block cursor-p" id="step-hover-schedule" (click)="outputEvent(cardItem, cardEntity.schedule)">
              <svg-icon src="../../../assets/icon-svg/course-card/clock.svg" [stretch]="true"></svg-icon>
              <span>{{ cardItem[cardConfig['isScheduled']]  == 1 ? 'Reschedule' : 'Schedule' }}</span>
        </label>
                <label class="m-0 d-block cursor-p" id="step-hover-addedToCart" (click)="outputEvent(cardItem, cardEntity.addtocart)">
              <svg-icon src="../../../assets/icon-svg/course-card/cart.svg" [stretch]="true"></svg-icon>
              <span>{{ cardItem[cardConfig['isAddToCart']]  != 0 ? 'Go To cart' : 'Add to cart' }}</span>
        </label> -->
        <ng-container *ngFor="let item of buttons">
          <!-- (click)="fireEvent( item.event.id,  item.event.value)" -->
          <span class="m-0 d-block align-items-center cursor-p " (click)="optionClicked(item.event, cardItem)">
            <svg-icon [src]="item?.icon" [stretch]="true ">
            </svg-icon>
            <span class="more-text">{{ item?.text }}</span>
          </span>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="animcard__bottom hover-bottom" (click)="fireEvent('goToContent',cardItem)">
    <div class="animcard__bottom--type d-flex align-items-center mb-1">
      <img src="assets/icon-svg/course.svg" alt="instance-icon">
      <span class="ml-2">{{ cardItem[cardConfig["type"]] }}</span>
    </div>
    <h4 class="animcard__bottom--text">{{ cardItem[cardConfig["title"]] }}</h4>
    <div class="count pb-1">
      <!-- <ul class="count__list d-flex flex-wrap align-items-center list-unstyled pl-0">
                <li class="count__list--item">{{ cardItem[cardConfig["viewLabel"]] }} Views</li>
                <ng-container *ngIf="cardConfig['CommentCountEnabled']">
                    <span class="count__list--dot mx-2"></span>
                    <li class="count__list--item">{{ cardItem[cardConfig["commentsLabel"]] }} Comments</li>
                </ng-container>
                <ng-container *ngIf="cardConfig['showShareCount']">
                    <span class="count__list--dot mx-2"></span>
                    <li class="count__list--item">{{ cardItem[cardConfig["shareLabel"]] }} Share</li>
                </ng-container>
                <ng-container *ngIf="cardConfig['likeEnabled']">
                    <span class="count__list--dot mx-2"></span>
                    <li class="count__list--item">{{ cardItem[cardConfig['likeCount']] || 0 }} Likes</li>
                </ng-container>
                <ng-container *ngIf="cardConfig['dislikeEnabled']">
                    <span class="count__list--dot mx-2"></span>
                    <li class="count__list--item">{{ cardItem[cardConfig['dislikeCount']] || 0 }} Dislikes</li>
                </ng-container>
            </ul> -->

      <div class="d-flex flex-wrap align-items-center list-unstyled pl-0">
        <div class="">
          <svg-icon svgClass="stroke-gray fill-white" src="assets/icon-svg/Eye_icon.svg"
            [svgStyle]="{'width.rem': 1, 'height.rem': 1, 'stroke-width': 1.4}"></svg-icon>
          <span class="fs-12 pl-1">{{ cardItem[cardConfig["viewLabel"]] }}</span>
        </div>
        <ng-container *ngIf="cardConfig['CommentCountEnabled']">
          <!-- <span class="count__list--dot mx-2"></span> -->
          <span class="h-16-px w-1-px bg-gray-100 mx-2"></span>
          <div class="">
            <svg-icon svgClass="stroke-gray fill-white" src="assets/icon-svg/Message_icon.svg"
              [svgStyle]="{'width.rem': 1, 'height.rem': 1, 'stroke-width': 1.4}"></svg-icon>
            <span class="fs-12 pl-1">{{ cardItem[cardConfig["commentsLabel"]] }}</span>
          </div>
        </ng-container>
        <ng-container *ngIf="cardConfig['showShareCount']">
          <!-- <span class="count__list--dot mx-2"></span> -->
          <span class="h-16-px w-1-px bg-gray-100 mx-2"></span>
          <div class="">
            <svg-icon svgClass="stroke-gray fill-white" src="assets/icon-svg/Share_icon.svg"
              [svgStyle]="{'width.rem': 1, 'height.rem': 1, 'stroke-width': 1.4}"></svg-icon>
            <span class="fs-12 pl-1">{{ cardItem[cardConfig["shareLabel"]] }} Share</span>
          </div>
        </ng-container>
        <ng-container *ngIf="cardConfig['likeEnabled']">
          <!-- <span class="count__list--dot mx-2"></span> -->
          <span class="h-16-px w-1-px bg-gray-100 mx-2"></span>
          <div class="">
            <svg-icon svgClass="stroke-gray fill-white" src="assets/icon-svg/Thumbs_up_icon.svg"
              [svgStyle]="{'width.rem': 1, 'height.rem': 1, 'stroke-width': 1.4}"></svg-icon>
            <span class="fs-12 pl-1">{{ cardItem[cardConfig['likeCount']] || 0 }}</span>
          </div>
        </ng-container>
        <ng-container *ngIf="cardConfig['dislikeEnabled']">
          <!-- <span class="count__list--dot mx-2"></span> -->
          <span class="h-16-px w-1-px bg-gray-100 mx-2"></span>
          <div class="">
            <svg-icon svgClass="stroke-gray fill-white" src="assets/icon-svg/Thumbs_down_icon.svg"
              [svgStyle]="{'width.rem': 1, 'height.rem': 1, 'stroke-width': 1.4}"></svg-icon>
            <span class="fs-12 pl-1">{{ cardItem[cardConfig['dislikeCount']] || 0 }}</span>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="animcard__rating d-flex align-items-center" *ngIf="cardConfig?.showStars">
      <p class="animcard__rating--text mb-0">({{cardItem[cardConfig['starCountLabel']] || 0}})</p>
      <star-rating staticColor="warning" readOnly="true" [starType]="'svg'" [size]="'medium'" [showHalfStars]="true"
        [numOfStars]="cardItem?.outof" Color="ok" rating="{{cardItem[cardConfig['starCountLabel']] || 0}}">
      </star-rating>
    </div>
  </div>

</div>
<br><br><br>
