<div class="quizContainer pt-3">
  <!-- header -->
  <div class="quiz_top_container mx-3">
    <div class="heading123 p-2">
      <h3 class="title123">Quiz Assessment for {{ courseTitle }}</h3>
    </div>

    <div class="timeoutdiv">
      <h4 class="time">
        <i class="fas fa-stopwatch watch pr-2"></i>{{ displayTime }}
      </h4>
    </div>

    <div class="question_number">
      <h1 class="question_No m-0">
        {{ currentQuestionIndex + 1 }} / {{ quiz?.length }}
      </h1>
    </div>
  </div>

  <!-- content -->
  <div class="questions_container">
    <div class="matcard">
      <div class="question_answer_container p-3" *ngFor="let Ques of quiz[currentQuestionIndex]?.list; let i = index">
        <div class="" *ngIf="Ques.qTypeId == 1">
          <div *ngIf="Ques.questionTypeId == 1">
            <h5 class="questionSName text-gray">{{ Ques.sName }}</h5>
            <h3 class="question" [innerHtml]="Ques.qText | mandetoryquestionpipe: Ques:'question'"></h3>
            <!-- <h3 class="question" [innerHtml]="makequizQuestionReady(Ques,'question')">
                        </h3> -->
            <!-- {{ Ques.qText }} <span class="mandatoryQues" *ngIf="Ques.isMandatory == 1">&nbsp;*</span> -->
          </div>

          <div *ngIf="Ques.questionTypeId == 2" class="mb-3">
            <h5 class="questionSName text-gray">{{ Ques.sName }}</h5>
            <!-- <div class="image_center"> -->
            <div class="d-flex w-10-rem h-6-rem">
              <!-- <img class="contentImg" [src]="Ques.contentRef | safePipePipe: 'resourceUrl'" /> -->
              <img class="w-100 rounded border" [src]="Ques.contentRef | safePipePipe: 'resourceUrl'" />
              <div class="question-tooltip-above">
                <span tooltip="Open Image In Full Screen Mode" flow="up">
                  <i class="fas fa-external-link-alt open_image_full_icon ml-2" aria-hidden="true"
                    (click)="openInFullScreen(Ques.contentRef)"></i>
                </span>
              </div>
            </div>
          </div>

          <p class="questionInstructions mb-3" *ngIf="Ques.instructions">
            <span class="questionInstructionsLabel"> Instructions : </span>
            <!-- <span class="questionInstructionsData"> {{Ques.instructions}} </span> -->
            <span class="questionInstructionsData">
              {{ Ques.instructions | removeHtml: 92 }}
              <!-- {{ makefeedback(Ques.instructions) }} -->
              <span class="read_more_feedback" *ngIf="(Ques.instructions | removeHtml: 92)?.length >= 90"
                (click)="readfb(Ques.instructions)">
                ReadMore
              </span>
            </span>
          </p>

          <!-- <div class="answer" [ngClass]="{'disableQuestion': Ques.Answered == 1 && activityData.behaviour == 2}"> -->
          <div class="answer">
            <div *ngIf="!reviewFlag">
              <div class="multiple_choice_question_container d-grid">
                <ng-container *ngFor="let options of Ques.optionList; let j = index">
                  <ng-container *ngIf="options.qOptionType == '1'">
                    <div class="button_option_container" (click)="selectOption(i, Ques, j, options)" [ngClass]="{
                        active: options.sFlag,
                        options: !options.sFlag,
                        disableQuestion:
                          (quiz[currentQuestionIndex].answerConfirmed &&
                            activityData.behaviour == 2) ||
                          (Ques.Answered == 1 && activityData.behaviour == 2),
                        activeCorrect:
                          options.sFlag &&
                          options.cFlag == 'true' &&
                          activityData.behaviour == 2 &&
                          quiz[currentQuestionIndex].answerConfirmed,
                        activeWrong:
                          options.sFlag &&
                          options.cFlag == 'false' &&
                          activityData.behaviour == 2 &&
                          quiz[currentQuestionIndex].answerConfirmed
                      }">
                      <div>{{ j + 1 }} ) &nbsp;</div>
                      <div [innerHTML]="options.qOption | safePipePipe: 'html'"></div>
                    </div>
                  </ng-container>
                  <div class="feedback_read_more" *ngIf="
                      activityData.behaviour == 2 &&
                      options.sFlag &&
                      options.feedback &&
                      quiz[currentQuestionIndex].answerConfirmed
                    ">
                    <p class="questionInstructions">
                      <span class="questionInstructionsLabel">
                        Feedback :
                      </span>
                      <span class="questionInstructionsData">
                        {{ options.feedback | removeHtml: 92 }}
                        <span class="read_more_feedback" *ngIf="
                            (options.feedback | removeHtml: 92)?.length >= 90
                          " (click)="readfb(options.feedback)">
                          ReadMore
                        </span>
                      </span>
                    </p>
                  </div>
                </ng-container>
              </div>
              
              <div class="multiple_choice_image_container d-grid">
                <ng-container *ngFor="let options of Ques.optionList; let j = index">
                   <ng-container *ngIf="options.qOptionType == '2'">
                    <!-- <div class="c"> -->
                      <div class="d-inline-flex w-10-rem h-6-rem" (click)="selectOption(i, Ques, j, options)" [ngClass]="{
                          disableQuestion:
                            (quiz[currentQuestionIndex].answerConfirmed &&
                              activityData.behaviour == 2) ||
                            (Ques.Answered == 1 && activityData.behaviour == 2),
                          activeCorrect:
                            options.sFlag &&
                            options.cFlag == 'true' &&
                            activityData.behaviour == 2 &&
                            quiz[currentQuestionIndex].answerConfirmed,
                          activeWrong:
                            options.sFlag &&
                            options.cFlag == 'false' &&
                            activityData.behaviour == 2 &&
                            quiz[currentQuestionIndex].answerConfirmed
                        }">
                        <div class="mr-2">{{ j + 1 }}) &nbsp;</div>
                        <img class="object-fit-contain w-100 rounded" [ngClass]="{ border: !options.sFlag, imageActive: options.sFlag}" 
                          [src]="options.qOptionRef | safePipePipe: 'resourceUrl'" />
                        <span tooltip="Open Image In Full Screen Mode" flow="up">
                          <i class="fas fa-external-link-alt open_image_full_icon ml-2" aria-hidden="true"
                            (click)="openInFullScreen(options.qOptionRef)"></i>
                        </span>
                      </div>
                    <!-- </div> -->
                  </ng-container>
                  <div class="feedback_read_more" *ngIf="
                      activityData.behaviour == 2 &&
                      options.sFlag &&
                      options.feedback &&
                      quiz[currentQuestionIndex].answerConfirmed
                    ">
                    <p class="questionInstructions">
                      <span class="questionInstructionsLabel">
                        Feedback :
                      </span>
                      <span class="questionInstructionsData">
                        {{ options.feedback | removeHtml: 92 }}
                        <span class="read_more_feedback" *ngIf="
                            (options.feedback | removeHtml: 92)?.length >= 90
                          " (click)="readfb(options.feedback)">
                          ReadMore
                        </span>
                      </span>
                    </p>
                    <!-- <span class="questionFeedbackLabel" (click)="readfb(options.feedback)">Read Feedback  </span> -->
                    <!-- <span class="questionFeedbackData" [innerHtml]="makequizQuestionReady(options.feedback ,'feedback')"></span> -->
                  </div>
                </ng-container>
              </div>
            </div>

            <div *ngIf="reviewFlag">
              <div class="row marginbottom">
                <div class="col-md-6 margBot" *ngFor="let options of Ques.optionList; let j = index">
                  <button *ngIf="options.qOptionType == '1'" class="options button_option_container" [ngClass]="{
                      active: options.sFlag,
                      options: !options.sFlag,
                      disableQuestion:
                        (quiz[currentQuestionIndex].answerConfirmed &&
                          activityData.behaviour == 2) ||
                        (Ques.Answered == 1 && activityData.behaviour == 2),
                      activeCorrect:
                        reviewFlag ||
                        (options.sFlag &&
                          options.cFlag == 'true' &&
                          activityData.behaviour == 2),
                      activeWrong:
                        reviewFlag ||
                        (options.sFlag &&
                          options.cFlag == 'false' &&
                          activityData.behaviour == 2)
                    }">
                    <div class="">{{ j + 1 }} ) &nbsp;</div>
                    <!-- {{ options.qOption }} -->
                    <div [innerHtml]="
                        makequizQuestionReady(options.qOption, 'qOption')
                      "></div>
                  </button>
                  <span *ngIf="options.qOptionType == '2'" tooltip="Open Image In Full Screen Mode" flow="up">
                    <i class="fas fa-external-link-alt open_image_full_icon" aria-hidden="true"
                      (click)="openInFullScreen(options.qOptionRef)"></i>
                  </span>

                  <div class="d-flex w-10-rem h-10-rem" *ngIf="options.qOptionType == '2'" [ngClass]="{
                      imageActive: options.sFlag,
                      disableQuestion:
                        (quiz[currentQuestionIndex].answerConfirmed &&
                          activityData.behaviour == 2) ||
                        (Ques.Answered == 1 && activityData.behaviour == 2),
                      activeCorrect:
                        reviewFlag ||
                        (options.sFlag &&
                          options.cFlag == 'true' &&
                          activityData.behaviour == 2),
                      activeWrong:
                        reviewFlag ||
                        (options.sFlag &&
                          options.cFlag == 'false' &&
                          activityData.behaviour == 2)
                    }">
                    <!-- <div class="button_option_container"> -->
                      <div class="">{{ j + 1 }} ) &nbsp;</div>
                      <img class="w-100 rounded border" [src]="options.qOptionRef | safePipePipe: 'resourceUrl'" />
                    <!-- </div> -->
                  </div>

                  <div class="feedback_read_more" *ngIf="
                      activityData.behaviour == 2 &&
                      options.sFlag &&
                      options.feedback &&
                      quiz[currentQuestionIndex].answerConfirmed
                    ">
                    <p class="questionInstructions">
                      <span class="questionInstructionsLabel">
                        Feedback :
                      </span>
                      <span class="questionInstructionsData">
                        {{ options.feedback | removeHtml: 92 }}
                        <span class="read_more_feedback" *ngIf="
                            (options.feedback | removeHtml: 92)?.length >= 90
                          " (click)="readfb(options.feedback)">
                          ReadMore
                        </span>
                      </span>
                    </p>
                    <!-- <span class="questionFeedbackLabel" (click)="readfb(options.feedback)">Read Feedback  </span> -->
                    <!-- <span class="questionFeedbackData" [innerHtml]="makequizQuestionReady(options.feedback ,'feedback')"></span> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="inline" *ngIf="Ques.qTypeId == 2">
          <div *ngIf="Ques.questionTypeId == 1">
            <h5 class="questionSName text-gray">{{ Ques.sName }}</h5>
            <!-- <h3 class="question">
                            {{ Ques.qText }} <span class="mandatoryQues" *ngIf="Ques.isMandatory == 1">&nbsp;*</span>
                          </h3> -->
            <!-- <h3 class="question" [innerHtml]="makequizQuestionReady(Ques,'question')"></h3> -->
            <!-- <h3 class="question" >{{ Ques.qText | mandetoryquestionpipe:Ques :'question' }} -->
            <h3 class="question" [innerHtml]="Ques.qText | mandetoryquestionpipe: Ques:'question'"></h3>
          </div>

          <div *ngIf="Ques.questionTypeId == 2" class="mb-3">
            <h5 class="questionSName text-gray">{{ Ques.sName }}</h5>
            <div class="d-flex w-10-rem h-6-rem">
              <img class="object-fit-contain w-100 rounded border" [src]="Ques.contentRef | safePipePipe: 'resourceUrl'" />
              <div class="question-tooltip-above">
                <span tooltip="Open Image In Full Screen Mode" flow="up">
                  <i class="fas fa-external-link-alt open_image_full_icon ml-2" aria-hidden="true"
                    (click)="openInFullScreen(Ques.contentRef)"></i>
                </span>
              </div>
            </div>
          </div>

          <p class="questionInstructions mb-3" *ngIf="Ques.instructions">
            <span class="questionInstructionsLabel"> Instructions : </span>
            <!-- <span class="questionInstructionsData"> {{Ques.instructions}} </span> -->
            <span class="questionInstructionsData">
              {{ Ques.instructions | removeHtml: 92 }}
              <span class="read_more_feedback" *ngIf="(Ques.instructions | removeHtml: 92)?.length >= 90"
                (click)="readfb(Ques.instructions)">
                ReadMore
              </span>
            </span>
          </p>

          <div class="answer" [ngClass]="{
              disableQuestion: Ques.Answered == 1 && activityData.behaviour == 2
            }">
            <div *ngIf="!reviewFlag">
              <div class="select_drop_list_container" *ngFor="let options of Ques.optionList; let j = index">
                  <button *ngIf="options.optionType == '1'" [innerHTML]="options.optionText | safePipePipe: 'html'"
                    class="options" [ngClass]="{
                      active: options.sFlag,
                      options: !options.sFlag,
                      activeCorrect:
                        options.sFlag &&
                        options.cFlag == 'true' &&
                        activityData.behaviour == 2 &&
                        quiz[currentQuestionIndex].answerConfirmed,
                      activeWrong:
                        options.sFlag &&
                        options.cFlag == 'false' &&
                        activityData.behaviour == 2 &&
                        quiz[currentQuestionIndex].answerConfirmed
                    }"></button>
                  <ng-container *ngIf="options.optionType == '2'">
                    <div class="m-auto">
                      <div class="d-flex w-10-rem h-10-rem" [ngClass]="{
                        imageActive: options.sFlag,
                          activeCorrect:
                            options.sFlag &&
                            options.cFlag == 'true' &&
                            activityData.behaviour == 2 &&
                            quiz[currentQuestionIndex].answerConfirmed,
                          activeWrong:
                            options.sFlag &&
                            options.cFlag == 'false' &&
                            activityData.behaviour == 2 &&
                            quiz[currentQuestionIndex].answerConfirmed
                        }">
                        <!-- <img class="optionsImg" [src]="options.optionRef | safePipePipe: 'resourceUrl'" /> -->
                        <img class="object-fit-contain w-100 rounded" [src]="options.optionRef | safePipePipe: 'resourceUrl'" />
                        <span tooltip="Open Image In Full Screen Mode" flow="up">
                          <i class="fas fa-external-link-alt open_image_full_icon ml-2" aria-hidden="true"
                            (click)="openInFullScreen(options.optionRef)"></i>
                        </span>
                      </div>
                  </div>
                </ng-container> 

                <div class="dropable dropped_content" id="droptarget{{ i }}{{ j }}" (dragover)="allowDrop($event)"
                  (drop)="drop($event, Ques, j, 0)">
                  Drop Matching Tiles
                </div>
                <div class="dropable" id="droptarget{{ i }}{{ j }}" (dragover)="allowDrop($event)"
                  (drop)="drop($event, Ques, j, 1)">
                  <button (dragstart)="dragStart($event)" (drag)="dragging($event)" draggable="true"
                    id="dragtarget{{ i }}{{ j }}" class="options">
                    <!-- {{ options.optionMatch }} -->
                    <div [innerHTML]="
                        options.matchPairList[j].name | safePipePipe: 'html'
                      "></div>
                    <!-- {{ options.matchPairList[j].name }} -->
                  </button>
                </div>
              </div>
            </div>

            <div *ngIf="reviewFlag">
              <div class="select_drop_list_container" *ngFor="let options of Ques.optionList; let j = index">
                <div>
                  <button *ngIf="options.optionType == '1'" class="options" [ngClass]="{
                      active: options.sFlag,
                      options: !options.sFlag,
                      activeCorrect:
                        reviewFlag ||
                        (options.sFlag &&
                          options.cFlag == 'true' &&
                          activityData.behaviour == 2 &&
                          quiz[currentQuestionIndex].answerConfirmed),
                      activeWrong:
                        reviewFlag ||
                        (options.sFlag &&
                          options.cFlag == 'false' &&
                          activityData.behaviour == 2 &&
                          quiz[currentQuestionIndex].answerConfirmed)
                    }">
                    {{ options.optionText }}
                  </button>
                  <div class="text-right" *ngIf="options.optionType == '2'">
                    <span tooltip="Open Image In Full Screen Mode" flow="up">
                      <i class="fas fa-external-link-alt open_image_full_icon" aria-hidden="true"
                        (click)="openInFullScreen(options.optionRef)"></i>
                    </span>
                  </div>

                  <button *ngIf="options.optionType == '2'" class="options option_img_button" [ngClass]="{
                      active: options.sFlag,
                      options: !options.sFlag,
                      activeCorrect:
                        reviewFlag ||
                        (options.sFlag &&
                          options.cFlag == 'true' &&
                          activityData.behaviour == 2 &&
                          quiz[currentQuestionIndex].answerConfirmed),
                      activeWrong:
                        reviewFlag ||
                        (options.sFlag &&
                          options.cFlag == 'false' &&
                          activityData.behaviour == 2 &&
                          quiz[currentQuestionIndex].answerConfirmed)
                    }">
                    <img class="optionsImg" [src]="options.optionRef | safePipePipe: 'resourceUrl'" />
                  </button>
                </div>
                <div class="dropable dropped_content">Drop Matching Tiles</div>
                <div class="dropable" id="droptarget{{ i }}{{ j }}">
                  <button id="droptarget{{ i }}{{ j }}" class="options">
                    <div [innerHTML]="options.optionMatch | safePipePipe: 'html'"></div>
                    <!-- {{  }} -->
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="inline" *ngIf="Ques.qTypeId == 3">
          <div *ngIf="Ques.questionTypeId == 1">
            <h5 class="questionSName text-gray">{{ Ques.sName }}</h5>
            <!-- <h3 class="question">{{ Ques.qText }}
                              <span class="mandatoryQues" *ngIf="Ques.isMandatory == 1">&nbsp;*</span>
                          </h3> -->
            <!-- <h3 class="question" [innerHtml]="makequizQuestionReady(Ques,'question')"></h3> -->
            <h3 class="question" [innerHtml]="Ques.qText | mandetoryquestionpipe: Ques:'question'"></h3>
          </div>

          <div *ngIf="Ques.questionTypeId == 2" class="mb-3">
            <h5 class="questionSName text-gray">{{ Ques.sName }}</h5>
            <div class="d-flex w-10-rem h-6-rem">
              <img class="w-100 rounded border" [src]="Ques.contentRef | safePipePipe: 'resourceUrl'" />
              <div class="question-tooltip-above">
                <span tooltip="Open Image In Full Screen Mode" flow="up">
                  <i class="fas fa-external-link-alt open_image_full_icon ml-2" aria-hidden="true"
                    (click)="openInFullScreen(Ques.contentRef)"></i>
                </span>
              </div>
            </div>
          </div>

          <p class="questionInstructions mb-3" *ngIf="Ques.instructions">
            <span class="questionInstructionsLabel"> Instructions : </span>
            <!-- <span class="questionInstructionsData"> {{Ques.instructions}} </span> -->
            <span class="questionInstructionsData">
              {{ Ques.instructions | removeHtml: 92 }}
              <span class="read_more_feedback" *ngIf="(Ques.instructions | removeHtml: 92)?.length >= 90"
                (click)="readfb(Ques.instructions)">
                ReadMore
              </span>
            </span>
          </p>

          <div class="answer" [ngClass]="{
              disableQuestion: Ques.Answered == 1 && activityData.behaviour == 2
            }">
            <div *ngIf="!reviewFlag">
              <div class="">
                <div [sortablejs]="Ques.optionList" (change)="selectOption1(Ques, i)" class="sequence_list">
                  <div *ngFor="let options of Ques.optionList; let j = index">
                    <div class="sequence_list_option" [ngClass]="{
                        activeCorrect:
                          options.cFlag == 'true' &&
                          activityData.behaviour == 2 &&
                          quiz[currentQuestionIndex].answerConfirmed,
                        activeWrong:
                          options.cFlag == 'false' &&
                          activityData.behaviour == 2 &&
                          quiz[currentQuestionIndex].answerConfirmed
                      }">
                      <div class="d-flex">
                        <div>{{ j + 1 }} ) &nbsp;</div>
                        <div [innerHTML]="options.optionText | safePipePipe: 'html'"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="reviewFlag">
              <div class="">
                <div class="" (change)="selectOption1(Ques, i)">
                  <div class="" *ngFor="let options of Ques.optionList; let j = index" class="sequence_list">
                    <div class="" [ngClass]="{
                        activeCorrect:
                          reviewFlag ||
                          (options.cFlag == 'true' &&
                            activityData.behaviour == 2),
                        activeWrong:
                          reviewFlag ||
                          (options.cFlag == 'false' &&
                            activityData.behaviour == 2)
                      }">
                      <div class="d-flex h-6-rem">
                        <div>{{ j + 1 }} ) &nbsp;</div>
                        <div [innerHTML]="options.optionText | safePipePipe: 'html'"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="inline" *ngIf="Ques.qTypeId == 4">
          <div class="inline_div">
            <div *ngIf="Ques.questionTypeId == 1" class="inline_div">
              <h5 class="questionSName text-gray">{{ Ques.sName }}</h5>
              <h3 class="question" [innerHtml]="
                  Ques.qText | mandetoryquestionpipe: Ques:'question'
                "></h3>
            </div>

            <div *ngIf="Ques.questionTypeId == 2" class="inline_div">
              <h5 class="questionSName text-gray">{{ Ques.sName }}</h5>
              <div class="d-flex w-10-rem h-6-rem">
                <img class="object-fit-contain w-100 rounded border" [src]="Ques.contentRef" />
                <div class="question-tooltip-above">
                  <span tooltip="Open Image In Full Screen Mode" flow="up">
                    <i class="fas fa-external-link-alt open_image_full_icon ml-2" aria-hidden="true"
                      (click)="openInFullScreen(Ques.contentRef)"></i>
                  </span>
                </div>
              </div>
            </div>

            <p class="questionInstructions" class="inline_div" *ngIf="Ques.instructions">
              <span class="questionInstructionsLabel"> Instructions : </span>
              <!-- <span class="questionInstructionsData"> {{Ques.instructions}} </span> -->
              <span class="questionInstructionsData">
                {{ Ques.instructions | removeHtml: 92 }}
                <span class="read_more_feedback" *ngIf="(Ques.instructions | removeHtml: 92)?.length >= 90"
                  (click)="readfb(Ques.instructions)">
                  ReadMore
                </span>
              </span>
            </p>

            <div classs=" answer ">
              <div *ngIf="!reviewFlag">
                <div class="pt-3">
                  <textarea rows="4" cols="12" matInput placeholder="message" class="form-control commntbx"
                    name="message" [disabled]="
                      (quiz[currentQuestionIndex].answerConfirmed &&
                        activityData.behaviour == 2 &&
                        Ques.optionList[0].isValid) ||
                      (Ques.Answered == 1 &&
                        activityData.behaviour == 2 &&
                        Ques.optionList[0].isValid)
                    " [(ngModel)]="Ques.optionList[0].sAns" minLength="{{ Ques.optionList[0].minLen }}"
                    maxLength="{{ Ques.optionList[0].maxLen }}" (change)="checkIfValidInputText(i, Ques)"></textarea>
                </div>
                <div *ngIf="!Ques.optionList[0]?.isValid" class="error">
                  {{ Ques.optionList[0].msg }}
                  <!-- Answer must at least {{list.minLen}} character. -->
                </div>
              </div>
            </div>
          </div>
        </div>

        <p class="questionFeedback" *ngIf="quiz[currentQuestionIndex].answerConfirmed">
          <span class="questionFeedbackLabel"> Additional Information : </span>
          <!-- <span class="questionFeedbackData"> {{Ques.feedback}} </span> -->
          <span class="questionFeedbackData">
            {{ Ques.feedback | removeHtml: 92 }}
            <span class="read_more_feedback" *ngIf="(Ques.feedback | removeHtml: 92)?.length >= 90"
              (click)="readfb(Ques.feedback)">
              ReadMore
            </span>
          </span>
        </p>
      </div>
    </div>
  </div>
</div>

<!-- footer -->
<div class="footerDiv">
  <div class="footer" *ngIf="activityData.navigation == 1">
    <div class="stepper">
      <!-- <mat-slider
        min="1"
        max="{{ total_page }}"
        step="1"
        value="{{ quiz[currentQuestionIndex]?.id }}"
        thumbLabel
        tickInterval="1"
        class="slider"
        color="primary"
      ></mat-slider> -->
    </div>

    <div class="nextbtn">
      <button class="nxt" (click)="submitAnswer(quiz[currentQuestionIndex], 4)" [disabled]="disablSubmit" *ngIf="
          last && quiz[currentQuestionIndex]?.answerConfirmed && reviewFlag
        ">
        Go to Result
      </button>
      <!-- <button class="nxt" (click)="submitAnswer(quiz[currentQuestionIndex], 3)" [disabled]="disablSubmit" *ngIf="
          activityData.behaviour == 2 &&
          last &&
          quiz[currentQuestionIndex]?.answerConfirmed &&
          !reviewFlag
        ">
        Submit
      </button>
      <button class="nxt" (click)="submitAnswer(quiz[currentQuestionIndex], 3)" [disabled]="disablSubmit" *ngIf="
          activityData.behaviour == 1 &&
          last &&
          !quiz[currentQuestionIndex]?.answerConfirmed &&
          !reviewFlag
        ">
        Submit
      </button> -->
      <button class="nxt" (click)="submitAnswer(quiz[currentQuestionIndex], 2)" [disabled]="disablSubmit" *ngIf="
          activityData.behaviour == 2 &&
          !last &&
          quiz[currentQuestionIndex]?.answerConfirmed
        ">
        Next
        <i class="fas fa-arrow-right"></i>
      </button>
      <button class="nxt" (click)="submitAnswer(quiz[currentQuestionIndex], 2)" [disabled]="disablSubmit" *ngIf="
          activityData.behaviour == 1 &&
          !last &&
          !quiz[currentQuestionIndex]?.answerConfirmed
        ">
        Next
        <i class="fas fa-arrow-right"></i>
      </button>
      <button class="nxt" (click)="submitAnswer(quiz[currentQuestionIndex], 2)" [disabled]="disablSubmit" *ngIf="
          activityData.behaviour == 1 &&
          !last &&
          quiz[currentQuestionIndex]?.answerConfirmed
        ">
        Next
        <i class="fas fa-arrow-right"></i>
      </button>
      <button class="nxt" (click)="submitAnswer(quiz[currentQuestionIndex], 1)" [disabled]="disablSubmit" *ngIf="
          activityData.behaviour == 2 &&
          !quiz[currentQuestionIndex]?.answerConfirmed &&
          !reviewFlag
        ">
        Confirm
      </button>
    </div>
  </div>

  <div class="footer" *ngIf="activityData.navigation == 2">
    <div class="back">
      <button class="round" (click)="prevPage()" *ngIf="currentQuestionIndex != 0">
        <i class="fas fa-arrow-left"></i>
      </button>
    </div>

    <div class="stepper">
      <!-- <mat-slider
        min="1"
        max="{{ total_page }}"
        step="1"
        value="{{ quiz[currentQuestionIndex]?.id }}"
        thumbLabel
        tickInterval="1"
        class="slider"
        color="primary"
      ></mat-slider> -->
    </div>

    <div class="nextbtn">
      <button class="nxt" (click)="submitAnswer(quiz[currentQuestionIndex], 4)" [disabled]="disablSubmit" *ngIf="
          last && quiz[currentQuestionIndex]?.answerConfirmed && reviewFlag
        ">
        Go to Result
      </button>
      <button class="nxt" (click)="submitAnswer(quiz[currentQuestionIndex], 3)" [disabled]="disablSubmit" *ngIf="
          activityData.behaviour == 2 &&
          last &&
          quiz[currentQuestionIndex]?.answerConfirmed &&
          !reviewFlag
        ">
        Submit
      </button>
      <button class="nxt" (click)="submitAnswer(quiz[currentQuestionIndex], 3)" [disabled]="disablSubmit" *ngIf="
          activityData.behaviour == 1 &&
          last &&
          !quiz[currentQuestionIndex]?.answerConfirmed &&
          !reviewFlag
        ">
        Submit
      </button>
      <button class="nxt" (click)="submitAnswer(quiz[currentQuestionIndex], 4)" [disabled]="disablSubmit" *ngIf="
          activityData.behaviour == 1 &&
          last &&
          quiz[currentQuestionIndex]?.answerConfirmed &&
          !reviewFlag
        ">
        Submit
      </button>
      <button class="nxt" (click)="submitAnswer(quiz[currentQuestionIndex], 2)" [disabled]="disablSubmit" *ngIf="
          activityData.behaviour == 2 &&
          !last &&
          quiz[currentQuestionIndex]?.answerConfirmed
        ">
        Next
        <i class="fas fa-arrow-right"></i>
      </button>
      <button class="nxt" (click)="submitAnswer(quiz[currentQuestionIndex], 2)" [disabled]="disablSubmit" *ngIf="
          activityData.behaviour == 1 &&
          !last &&
          !quiz[currentQuestionIndex]?.answerConfirmed
        ">
        Next
        <i class="fas fa-arrow-right"></i>
      </button>
      <button class="nxt" (click)="submitAnswer(quiz[currentQuestionIndex], 2)" [disabled]="disablSubmit" *ngIf="
          activityData.behaviour == 1 &&
          !last &&
          quiz[currentQuestionIndex]?.answerConfirmed
        ">
        Next
        <i class="fas fa-arrow-right"></i>
      </button>
      <button class="nxt" (click)="submitAnswer(quiz[currentQuestionIndex], 1)" [disabled]="disablSubmit" *ngIf="
          activityData.behaviour == 2 &&
          !quiz[currentQuestionIndex]?.answerConfirmed &&
          !reviewFlag
        ">
        Confirm
      </button>
    </div>
  </div>
</div>

<div class="addSelfBackdrop2" [ngClass]="{ show_popup: showfb }">
  <div class="addSelfDemoModal2" [ngClass]="{ show_popup: showfb }">
    <!-- <h1 class="modalheader"> Feedback </h1> -->
    <p class="closeicon">
      <i class="fa fa-times" aria-hidden="true" (click)="closefb()"></i>
    </p>
    <base target="_blank" />
    <p [innerHtml]="feedbacktext"></p>
  </div>
</div>
<div class="addSelfBackdrop2" [ngClass]="{ show_popup: showSubmitError }">
  <div class="addSelfDemoModal2" [ngClass]="{ show_popup: showSubmitError }">
    <!-- <h1 class="modalheader">Information</h1> -->
    <!-- <button class="close closeDeleteModalBtn" aria-label="Close" (click)="close()"></button> -->
    <div class="content123 row">
      <p class="para123">
        Unable to submit your answers at this time, Please try again to submit
      </p>
      <div class="toggleIcon">
        <!-- <div class="toggle" (click)="SynSettings()">
      <i class="fas fa-toggle-off" [ngClass]="{'fa-toggle-on' : togglebtn }"></i>
    </div> -->
        <!-- <div class="toggle" (click)="toggleBtnState()" [hidden]="togglebtn.toggleON">
            <i class="fas "></i>
          </div> -->
      </div>
    </div>
    <div class="butns">
      <button type="button" class="btn btn-success mR10" [disabled]="disablSubmit" (click)="retrySubmission()">
        Yes
      </button>
      <!-- <button type="button" class="btn btn-warning" (click)="goBack()">No</button> -->
    </div>
  </div>
</div>
<div class="addSelfBackdrop2" [ngClass]="{ show_popup: showGetQuizQuestionError }">
  <div class="addSelfDemoModal2" [ngClass]="{ show_popup: showGetQuizQuestionError }">
    <div class="content123 row">
      <p class="para123">
        Unable to get question at this moment. Do you want to retry?
      </p>

      <div class="toggleIcon"></div>
    </div>
    <div class="butns">
      <button type="button" class="btn btn-success mR10" [disabled]="disableMultipleCalls"
        (click)="getQuizIntialDataAgain()">
        Yes
      </button>
      <!-- <button type="button" class="btn btn-warning" (click)="goBack()">No</button> -->
    </div>
  </div>
</div>
<ngx-image-viewer class="image_popup" *ngIf="showImageInFullScreen" [src]="images" [(config)]="config"
  [(index)]="imageIndexOne" (customEvent)="handleEvent($event)">
</ngx-image-viewer>
