import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { webApi } from '../../../../../config';
import { DeliveryService } from '../../../../cip/providers/delivery/delivery.service';
import { LoadingService } from '../../../../providers/loading/loading.service';
import { ToasterService } from '../../../../providers/toaster/toaster.service';
import { AttendanceInductionService } from '../../providers/attendance-induction/attendance-induction.service';
import { SharedService } from '../../providers/shared.service';
import { MarkAttendanceService } from '../../providers/mark-attendance/mark-attendance.service';
import { HelperService } from '../../../../providers/helper/helper.service';

@Component({
  selector: 'ngx-attendance-induction',
  templateUrl: './attendance-induction.component.html',
  styleUrls: ['./attendance-induction.component.scss']
})
export class AttendanceInductionComponent implements OnInit, OnChanges {

  @Input() type: any = 1;
  @Input() externalHeight: any = '60vh';
  @Input() paramsObject = null;
  @Output() getEvents = new EventEmitter<any>();

  working: any;
  notFound: any;
  loading: boolean = true;

  noDataFound = false;
  noDataFoundContainer = {
    showImage: true,
    title: 'Content not available',
    discription: 'Users you are looking for are not available this time, please try after some time.',
  };

  public searchControl: UntypedFormControl;
  searchTerm: any;

  tableConfig = {
    autoMarkCheckBox: true,
    showCustomActionLabel: true,
    showArrow: true,
    customPagination: false,
    totalPages: 10,
    currentPage: 1,
    defaultImage: 'https://bhaveshedgetest.s3.ap-south-1.amazonaws.com/profile194991568967581009.png',
    noDatamsg: 'Users you are looking for are not available this time, please try after some time.'
  };
  tableLabels = [];
  tableData: any = [];

  currentPageNo: any;
  dataLimit: any;
  totalPageCount: any;

  getActivityIdsStrFrom = '';
  isInternalComponent = false;
  filterMetadata = { count: 0 };

  currentUserData: any;

  userSelected: any;
  selectedStatus: any;
  remarkConfig: any;
  statusConfig: any;
  statusRemarkList: any;

  validateEnable: any;

  // LABEL: any = [
  //   // {
  //   // "labelname": "Empolyee Name",
  //   // "bindingProperty": "{\"userImage\": \"userimage\", \"name\": \"fullName\"}",
  //   // "componentType": "userImage",
  //   // "setting": "{\"maxLength\": \"20\"}"
  //   // },
  //   {
  //     "labelname": "",
  //     "bindingProperty": "isSelected",
  //     "componentType": "checkbox",
  //     "checkLabelName": "",
  //     "disableProperty": "checkboxDisabled",
  //     "dontShowCheckAll": 0,
  //     "checkAll": 0
  //   },
  //   { labelname: "Name", bindingProperty: "fullName", componentType: "text" },
  //   { labelname: "Ecn", bindingProperty: "code", componentType: "text" },
  //   { labelname: "Email Id", bindingProperty: "email", componentType: "text" },
  //   { labelname: "Designation", bindingProperty: "designation", componentType: "text" },
  //   {
  //     labelname: "Status",
  //     bindingProperty: "status",
  //     componentType: "dropdown",
  //     dropdownData: JSON.stringify([
  //       {
  //         "id": null,
  //         "name": 'Select status',
  //         "remark": '',
  //         "remarkDisabled": 1,
  //         "status": null,
  //         "statusDisabled": 1,
  //       }, {
  //         id: '1',
  //         name: 'Not inducted but joined at location',
  //         "remark": 'Re-invitee',
  //         "remarkDisabled": 1,
  //         "status": '1',
  //         "statusDisabled": 0,
  //       },
  //       {
  //         id: '2',
  //         name: 'Inducted but not joined',
  //         "remark": '',
  //         "remarkDisabled": 1,
  //         "status": '2',
  //         "statusDisabled": 1,
  //       },
  //       {
  //         id: '3',
  //         name: 'Joined',
  //         "remark": '',
  //         "remarkDisabled": 1,
  //         "status": '3',
  //         "statusDisabled": 1,
  //       },
  //       {
  //         id: '4',
  //         name: 'Not joined',
  //         "remark": '',
  //         "remarkDisabled": 0,
  //         "status": '4',
  //         "statusDisabled": 0,
  //       }
  //     ]),
  //     disableProperty: "statusDisabled",
  //   },
  //   {
  //     labelname: "Attendance",
  //     setting:
  //       JSON.stringify(
  //         {
  //           classText: "attendaceClass"
  //         }
  //       )
  //     ,
  //     bindingProperty: JSON.stringify(
  //       {
  //         icon: "attendaceIcon",
  //         name: "attendaceDate"
  //       }
  //     ),
  //     componentType: "iconTextV2",
  //   },
  //   {
  //     labelname: "Remark",
  //     bindingProperty: "remark",
  //     componentType: "inputWSubmit",
  //     disableProperty: "remarkDisabled",
  //     componentAttribute: JSON.stringify({
  //       type: 'text',
  //       max: 50,
  //       min: 0,
  //       placeholder: 'Enter remark'
  //     })
  //   },
  // ]

  // TABELE: any = [
  //   {
  //     "userimage": "",
  //     // "name": "Justin Huges",
  //     "fullName": "Justin Huges",
  //     "code": "1245987",
  //     "email": "abc@gmail.com",
  //     "number": "9865734126",
  //     "doj": "11 Jan 2023",
  //     "manager": "Direct",
  //     "designation": "Developer",
  //     "attendence": "21 jan 2023",
  //     "attendaceIcon": "assets/icon-svg/course.svg",
  //     "attendaceDate": "21 jan 2023",
  //     "attendaceClass": "text-success",
  //     "status": '1',
  //     "statusDisabled": 0,
  //     "remark": "Re-invitee",
  //     "remarkDisabled": 1,
  //     "isSelected": 0,
  //     "checkboxDisabled": 0
  //   },
  //   {
  //     "userimage": "",
  //     // "name": "Justin Huges",
  //     "fullName": "Justin Huges",
  //     "dropFieldData": '',
  //     "code": "1245987",
  //     "email": "abc@gmail.com",
  //     "number": "9865734126",
  //     "doj": "11 Jan 2023",
  //     "manager": "Direct",
  //     "designation": "Developer",
  //     "attendence": "21 jan 2023",
  //     "attendaceIcon": "assets/icon-svg/course.svg",
  //     "attendaceDate": "21 jan 2023",
  //     "attendaceClass": "text-success",
  //     "status": null,
  //     "statusDisabled": 0,
  //     "remark": "",
  //     "remarkDisabled": 0,
  //     "isSelected": 0,
  //     "checkboxDisabled": 0
  //   },
  // ];

  // statusList = JSON.stringify([{
  //   id: null,
  //   name: 'Select status',
  //   remark: '',
  //   disabled: 1
  // }, {
  //   id: '1',
  //   name: 'Not inducted but joined at location',
  //   remark: '',
  //   disabled: 0
  // },
  // {
  //   id: '2',
  //   name: 'Inducted but not joined',
  //   remark: '',
  //   disabled: 0
  // },
  // {
  //   id: '3',
  //   name: 'Joined',
  //   remark: '',
  //   disabled: 0
  // },
  // {
  //   id: '4',
  //   name: 'Not joined',
  //   remark: '',
  //   disabled: 0
  // }
  // ])
  // remarkConfig = {
  //   labelname: "Remark",
  //   bindingProperty: "remark",
  //   componentType: "inputWSubmit",
  //   disableProperty: "remarkDisabled",
  //   componentAttribute: JSON.stringify({
  //     type: 'text',
  //     max: 999,
  //     min: 0,
  //     placeholder: 'Enter remark',
  //     showSubmitBtn: 0
  //   })
  // }

  // statusConfig = {
  //   labelname: "Status",
  //   bindingProperty: "status",
  //   componentType: "dropdown",
  //   dropdownData: JSON.stringify([]),
  //   disableProperty: "statusDisabled",
  // }

  // statusRemarkList = JSON.stringify([{
  //   "id": null,
  //   "name": 'Select status',
  //   "remark": '',
  //   "remarkDisabled": 1,
  //   "status": null,
  //   "statusDisabled": 1,
  // }, {
  //   id: '1',
  //   name: 'Not inducted but joined at location',
  //   "remark": 'Re-invitee',
  //   "remarkDisabled": 1,
  //   "status": '1',
  //   "statusDisabled": 0,
  // },
  // {
  //   id: '2',
  //   name: 'Inducted but not joined',
  //   "remark": '',
  //   "remarkDisabled": 0,
  //   "status": '2',
  //   "statusDisabled": 0,
  // },
  // {
  //   id: '3',
  //   name: 'Joined',
  //   "remark": '',
  //   "remarkDisabled": 0,
  //   "status": '3',
  //   "statusDisabled": 0,
  // },
  // {
  //   id: '4',
  //   name: 'Not joined',
  //   "remark": '',
  //   "remarkDisabled": 0,
  //   "status": '4',
  //   "statusDisabled": 0,
  // }]);

  selectedUsers: any;
  defaultStatus: any;

  constructor(
    private deliveryService: DeliveryService,
    public spinner: LoadingService,
    private toaster: ToasterService,
    // private router: Router,
    // private route: ActivatedRoute,
    // private workflowDetailsService: AssessorWorkflowDetailsService,
    // private helperService: HelperService,
    // private assignNotifyService: AssignNotifyService,
    // private searchableSelectService: SearchableSelectService
    private sharedService: SharedService,
    private attendanceInductionService: AttendanceInductionService,
    private markAttendanceService: MarkAttendanceService,
    private helperService: HelperService,
  ) {

  }

  ngOnInit(): void {
    this.bindIsInternal();
    this.initGetData();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.activity) {
      // console.log('MarkAttendanceComponent activity : ', this.activity);
    }
  }

  /*********** init start *********/
  bindIsInternal() {
    this.isInternalComponent = true;
    // switch (this.type) {
    //   case 1: this.isInternalComponent = false; break;
    //   case 2: this.isInternalComponent = false; break;
    //   case 3: this.isInternalComponent = false; break;
    //   case 4: this.isInternalComponent = true; break;
    //   case 5: this.isInternalComponent = true; break;
    // }
  }

  initGetData() {
    this.resetValues();
    this.setValueForSearch('', { emitEvent: false })
    this.initFormControl();
    this.getUserData();
  }

  resetValues() {
    this.currentUserData = {};

    this.notFound = false;
    this.working = false;
    this.loading = false;

    this.searchTerm = '';

    this.totalPageCount = 3;
    this.dataLimit = 20;
    this.currentPageNo = 1;


    this.tableData = [];
    this.tableLabels = [];

    this.selectedUsers = [];
    // this.selectedUserList = [];
    // this.totalContentCount = 0;

    this.resetStatus(false, null);

    this.userSelected = false;

    this.remarkConfig = {};
    this.statusConfig = {};
    this.statusRemarkList = [];

    this.validateEnable = false;
  }

  resetStatus(enable, data) {
    if (data) {
      this.selectedStatus = {
        "id": data.status,
        "name": '',
        "remark": data.remark,
        "remarkDisabled": data.remarkDisabled,
        "status": data.status,
        "statusDisabled": data.statusDisabled,
      };
    } else {
      // this.defaultStatus = {
      //   "status": null,
      //   "remark": '',
      // }
      this.selectedStatus = {
        "id": null,
        "name": 'Select status',
        "remark": '',
        "remarkDisabled": 1,
        "status": null,
        "statusDisabled": 1,
      };
      if (enable) {
        this.selectedStatus.statusDisabled = 0
      }
    }
  }
  /*********** init end *********/

  getUserData() {
    this.currentUserData = JSON.parse(localStorage.getItem('currentUser'));
    this.loading = true;
    this.getStatusList();
  }

  /************** search start **************/
  initFormControl() {
    this.searchControl = new UntypedFormControl();
    this.searchControl.valueChanges
      .pipe(debounceTime(700))
      .subscribe((search: any) => {
        console.log("Search term : ", search);
        this.searchTerm = search;
        if (this.searchTerm.length >= 3 || (this.searchTerm.length == 0 && this.notFound)) {
          this.triggerSearch(search);
        } else if (this.searchTerm.length == 0) {
          this.triggerSearch(search);
        }
      });
  }

  triggerSearch(searchCourseTerm: any) {
    console.log("searchCourseTerm ", searchCourseTerm);
    if (!this.loading) {
      this.tableData = [];
      this.currentPageNo = 1;
      this.loading = true;

      this.userSelected = false;

      this.getSearchedData((res: any) => {
        console.log('Search completed...', res);
      });
    }
  }

  getSearchedData(cb: { (res: any): void; (arg0: boolean): void; }) {
    this.fetchSearchData(1, () => {
      console.log('Searched  fetched...');
      this.loading = false;
      cb(true);
    });
  }

  clearSearch() {
    this.searchTerm = '';
    this.setValueForSearch("", { emitEvent: true });
  }

  setValueForSearch(value: string, obj: { emitEvent: boolean; }) {
    if (this.searchControl) {
      this.searchControl.setValue(value, obj);
    }
  }
  /************** search end **************/

  /************** get status list start **************/
  getStatusList() {
    // let params = {
    //   "dataFlag": 1,
    //   "roleId": this.currentUserData.roleId,
    // }
    // let url = webApi.apiUrl.getAttendanceInductionUserStatus;
    // if (url != '') {
    //   this.deliveryService.fetchSearchableSelectListDynamic(url, params).then(
    //     (res: any) => {
    //       console.log('getStatusList res ', res);
    //       if (res.type) {
    //         // this.statusList = res.data;

    //         const { statusList, statusConfig, remarkConfig } = res.data;
    //         this.statusRemarkList = statusList;
    //         this.statusConfig = JSON.parse(statusConfig);
    //         this.remarkConfig = JSON.parse(remarkConfig);

    //         this.fetchSearchData(this.currentPageNo, () => {
    //           console.log('Done');
    //           this.loading = false;

    //           // this.tableLabels = this.LABEL;
    //           // this.tableData = this.TABELE;

    //         });

    //       } else {
    //         this.loading = false;
    //         this.presentToastMessage('Unable to get results at this time.', 'Warning!', 'warning');
    //       }
    //     },
    //     (err: any) => {
    //       console.log('getStatusList err ', err);
    //       this.loading = false;
    //       this.setNotFound();
    //       this.presentToastMessage('Unable to get results at this time.', 'Warning!', 'warning');
    //     }
    //   ).catch(err => {
    //     console.log('err ', err);
    //     this.loading = false;
    //     this.setNotFound();
    //     this.presentToastMessage('Unable to get results at this time.', 'Warning!', 'warning');
    //   });
    // } else {
    //   console.log('unable to get data url');
    // }
    this.attendanceInductionService.getStatusList((status, resData) => {
      if (status) {
        const { statusList, statusConfig, remarkConfig } = resData;
        this.statusRemarkList = statusList;
        this.statusConfig = JSON.parse(statusConfig);
        this.remarkConfig = JSON.parse(remarkConfig);

        this.fetchSearchData(this.currentPageNo, () => {
          console.log('Done');
          this.loading = false;
        });

      } else {
        this.loading = false;
        this.setNotFound();
      }
    });
  }
  /************** get status list end **************/

  /*************** get infinite scroll data start ************/
  prepareCheckAll(data) {
    for (let index = 0; index < data.length; index++) {
      const loopdata = data[index];
      this.tableLabels.forEach(element => {
        if (element['showCheckAll'] == 1 && element['checkAll'] == 1) {
          loopdata[element['bindingProperty']] = true;
        }
      });
    }

    this.enableDisableStatusAndRemark(this.userSelected, data);

  }

  bindCheckAll() {
    this.tableLabels.forEach(element => {
      if (element['dontShowCheckAll'] == 0) {
        element['dontShowCheckAll'] = 1
      }
    });
  }

  fetchSearchData(currentPageNo: number, cb) {
    this.notFound = false;
    this.currentPageNo = currentPageNo;
    let params = {
      batchId: this.paramsObject.courseId,
      searchStr: this.searchTerm,
      pageNo: this.currentPageNo,
      limit: this.dataLimit,
      type: this.type,
      wfId: this.paramsObject.wfId,
      typeId: this.paramsObject.typeId,
      // isSelectedList:'',
      "activityName": "NA",
      "roleId": this.currentUserData.roleId,
    }
    if (this.type == 4 || this.type == 5) {
      params['actId'] = this.paramsObject.activityId
    }
    console.log('params', params);
    this.getUserList(params, (result, type) => {
      if (type && result.type === true) {
        if (this.currentPageNo !== 1) {
          const nexttableData = result.data;
          this.prepareCheckAll(nexttableData);
          this.tableData = this.tableData.concat(
            nexttableData
          );

        } else {
          this.tableData = result.data;
          this.totalPageCount = result.totalPages;
          // this.tableLabels = result.labels;
          this.tableLabels = result.headerData;
          this.getActivityIdsStrFrom = result.activityIdsStr;
          // this.getActivityIdsStrFrom = "41246";

          this.validateEnable = result.valButVis;

          // if (this.searchTerm != '') {
          //   this.bindCheckAll();
          // }
        }

        if (this.tableData.length > 0) {
          this.currentPageNo++;
        } else {
          // this.setNotFound();
          if (this.searchTerm == '') {
            this.setNotFound();
          }
        }
        cb(true);
      } else {
        cb(false);
        this.setNotFound();
      }
    });
  }

  getUserList(params: any, cb) {
    console.log('type', params.type);
    let url = webApi.apiUrl.getAttendanceInductionUsers;
    if (url != '') {
      this.deliveryService.fetchSearchableSelectListDynamic(url, params).then(
        (res: any) => {
          console.log(params.pageNo, ' page data list ', res);
          cb(res, true);
        },
        (err: any) => {
          console.log('err ', err);
          this.loading = false;
          cb(null, false);
          this.setNotFound();
          this.presentToastMessage('Unable to get results at this time.', 'Warning!', 'warning');
        }
      ).catch(err => {
        console.log('err ', err);
        this.loading = false;
        cb(null, false);
        this.setNotFound();
        this.presentToastMessage('Unable to get results at this time.', 'Warning!', 'warning');
      });
    } else {
      console.log('unable to get data url');
    }
  }

  loadMoreData(event: any) {
    if (this.currentPageNo != 1) {
      if (this.currentPageNo > this.totalPageCount) {
      } else {
        if (!this.working) {
          this.working = true;
          this.fetchSearchData(this.currentPageNo, () => {
            this.working = false;
            console.log("Done");
          });
        }
      }
    }
  }
  /*************** get infinite scroll data end ************/

  setNotFound() {
    if (this.searchTerm !== '') {
      this.noDataFoundContainer = {
        showImage: true,
        title: 'Searched users are not available',
        discription: 'Searched users are not available this time, please try after some time.',
      };
    } else {
      this.noDataFoundContainer = {
        showImage: true,
        title: 'Users not available',
        discription: 'Users you are looking for are not available this time, please try after some time.',
      };
    }
    if (this.tableData.length != 0 || this.filterMetadata.count > 0) {
      this.notFound = false;
    } else {
      this.notFound = true;
    }
  }

  /*************** Perform Action Start  ************/
  performAction(event) {
    console.log('event', event);
    const action = event.action.action ? event.action.action : event.action;
    switch (action) {
      case "singelSelectData":
        console.log('checkUncheck event', event);
        // this.checkChanged(event.action, event.data);
        break;
      case "checkUncheckAll":
        console.log('checkUncheck event', event, this.tableLabels, this.tableData);
        // this.checkAllChanged(event.action, event.data);
        break;
      case 'selectedList':
        this.checkSelectedList(event.data);
        break;
      case 'dropdownSelected':
        this.statusSelected(event.data);
        break;
      case 'inputSubmit':
        this.remarkSubmitted(event.data);
        break;
      default:
        console.log('Invalid action', event);
        // this.sendEvents(event.action, event.data);
        break;
    }
  }
  /*************** Perform Action End  ************/

  // checkChanged(user: any, action) {
  //   console.log('checkChanged event : ', user, action);
  //   // this.userSelected = !this.userSelected;
  //   // this.enableDisableStatusAndRemark(this.userSelected);
  // }

  // checkAllChanged(user: any, action) {
  //   console.log('checkAllChanged event : ', user, action);
  //   // this.userSelected = !this.userSelected;
  //   // this.enableDisableStatusAndRemark(this.userSelected);
  // }

  deleteKey(obj, keys = []) {
    keys.forEach((key) => {
      if (obj[key] != undefined) {
        delete obj[key];
      } else {
        console.log('Key not found...');
      }
    })
    return obj;
  }

  enableDisableStatusAndRemark(status, data) {
    let isDisabled = status ? 1 : 0;
    data.forEach(element => {

      // // if (isDisabled == 1) {
      // //   element.statusDisabledOld = element.statusDisabled;
      // //   element.remarkDisabledOld = element.remarkDisabled;
      // // }

      // if ('disabled' in element) {
      //   element.disabled = isDisabled;
      // }

      if ('statusDisabledOld' in element) {
        element.statusDisabled = element.statusDisabledOld;
      } else {
        element.statusDisabledOld = element.statusDisabled;
        element.statusDisabled = isDisabled;
      }

      if ('remarkDisabledOld' in element) {
        element.remarkDisabled = element.remarkDisabledOld;
      } else {
        element.remarkDisabledOld = element.remarkDisabled;
        element.remarkDisabled = isDisabled;
      }

      if (isDisabled == 0) {
        element = this.deleteKey(element, ['statusDisabledOld', 'remarkDisabledOld']);
      }

    });

    // this.tableData = [...this.tableData];

    this.tableLabels.forEach(element => {
      if (element['componentAttribute'] && element['componentAttribute']['showSubmitBtn'] != null) {
        element['componentAttribute']['showSubmitBtn'] = status ? 0 : 1;
      }
    });

    console.log('enableDisableStatusAndRemark : ', data);
  }

  checkSelectedList(selectedList) {

    // this.prepareDataString(1);
    // selectedList = this.selectedUsers;

    const prevStatus = this.userSelected;

    console.log('checkSelectedList event', selectedList);
    if (selectedList.length > 0) {
      this.userSelected = true;
    } else {
      this.userSelected = false;
    }

    if (prevStatus != this.userSelected) {

      this.enableDisableStatusAndRemark(this.userSelected, this.tableData);

      this.resetStatus(true, null);
    }
  }

  statusSelected(event) {
    console.log('statusSelected event: ', event);
    const { eventData, rowData } = event;
    if (eventData && rowData) {

      rowData.remark = eventData.remark;
      rowData.remarkDisabled = eventData.remarkDisabled;

      this.resetStatus(false, rowData);
      // this.updateAttendanceStatus(2, rowData);

    } else {
      // this.selectedStatus = event;
      this.resetStatus(false, event);
    }
  }

  remarkSubmitted(event) {
    console.log('remarkSubmitted event: ', event);
    const { eventData, rowData } = event;
    if (rowData) {
      this.resetStatus(false, rowData)
      this.updateAttendanceStatus(2, rowData);
    } else {
      // this.selectedStatus = event;
      this.resetStatus(false, event);
    }
  }

  /*************** Submit remark and status start  ************/
  prepareDataString(type, data) {
    // this.selectedUsers = [];
    let checkAllString = '';
    let learnStr = '';

    if (type == 1) {
      for (let index = 0; index < this.tableLabels.length; index++) {
        if (this.tableLabels[index]['showCheckAll'] == 1 && this.tableLabels[index]['checkAll']) {
          // if ((this.tableLabels[index]['allMarked'] == 0) || (this.tableLabels[index]['allUnMarked'] == 0)) {
          //   if (checkAllString != '') {
          //     checkAllString += ',';
          //   }
          //   checkAllString += this.tableLabels[index]['bindingProperty'];
          // }
          checkAllString += 'ALL';
        }
      };

      for (let index = 0; index < this.tableData.length; index++) {
        const learner = this.tableData[index];
        const attendanceMarkedKey = 'isSelected'
        if ((learner[attendanceMarkedKey] == true)) {
          learnStr += learner.enrolId + '|' + learner.employeeId + '#';

          // this.selectedUsers.push(learner);
        }
      }
    } else {
      learnStr += data.enrolId + '|' + data.employeeId + '#';
    }

    console.log('checkAllString', checkAllString);
    console.log('learnStr', learnStr);
    return {
      allStr: checkAllString == '' ? null : checkAllString,
      learnerStr: learnStr == '' ? null : learnStr
    };
  }

  updateAttendanceStatus(type, userData) {

    // this.initGetData();

    // if (this.isFormValid()) {
    if (this.attendanceInductionService.isFormValid(this.selectedStatus, this.remarkConfig, false)) {
      console.log('Form is valid...');

      // let url = webApi.apiUrl.updateAttendanceStatusWithRemark;
      // let params = {
      //   "activityId": this.paramsObject.activityId,
      //   "courseId": this.paramsObject.courseId,
      //   "moduleId": this.paramsObject.moduleId,
      //   // "learnerStr": "121212|33#343434|55",  // enrolId1|empId1#enrolId2|empId2
      //   "learnerStr": "",
      //   "attStat": this.selectedStatus.status,
      //   "attRemark": this.selectedStatus.remark,
      //   "roleId": this.currentUserData.roleId,
      //   actionId: null,
      //   searchStr: this.searchTerm,
      // }

      // const data = this.prepareDataString(type, userData);
      // if (data.allStr == null && data.learnerStr == null) {
      //   let msg = 'Please select user(s)';
      //   this.presentToastMessage(msg, 'Warning', 'warning');
      //   return null;
      // } else {
      //   if (data.allStr) {
      //     params.learnerStr = data.allStr;
      //   } else {
      //     params.learnerStr = data.learnerStr;
      //   }
      // }

      // // params = { ...params, ...data };

      // if (url != '') {
      //   this.loading = true;
      //   this.deliveryService.fetchSearchableSelectListDynamic(url, params).then(
      //     (res: any) => {
      //       console.log('saveRemarkAndStatus res : ', res);
      //       this.presentToastMessage(res.message, 'Success', 'success');
      //       this.initGetData();
      //     },
      //     (err: any) => {
      //       console.log('saveRemarkAndStatus err : ', err);
      //       this.loading = false;
      //       this.presentToastMessage('Unable to save at this time.', 'Warning!', 'warning');
      //     }
      //   ).catch(err => {
      //     console.log('saveRemarkAndStatus err : ', err);
      //     this.loading = false;
      //     this.presentToastMessage('Unable to save at this time.', 'Warning!', 'warning');
      //   });
      // } else {
      //   console.log('unable to get data url');
      // }

      this.loading = true;
      const extraData = this.attendanceInductionService.prepareDataString(type, userData, this.tableLabels, this.tableData);
      this.attendanceInductionService.updateAttendanceStatus(this.paramsObject, this.selectedStatus, this.searchTerm, extraData, (res) => {
        this.loading = false;
        if (res) {
          this.initGetData();
        } else {
          console.log('Something went wrong...');
        }
      });

    } else {
      console.log('Form is not valid...');
    }
  }

  isFormValid() {
    if (this.selectedStatus.status) {
      if (this.selectedStatus.remark) {
        if (this.sharedService.isInputValid(this.selectedStatus.remark, this.remarkConfig.componentAttribute)) {
          return true;
        } else {
          return false;
        }
      } else {
        this.presentToastMessage('Please add remark', 'Warning', 'warning');
        return false;
      }
    } else {
      this.presentToastMessage('Please select status', 'Warning', 'warning');
      return false;
    }
  }
  /*************** Submit remark and status End  ************/

  /*************** Validate attendance start  ************/
  validateRemarkAndStatus() {
    // let params = {
    //   "roleId": this.currentUserData.roleId,
    //   "activityId": this.paramsObject.activityId,
    //   "courseId": this.paramsObject.courseId,
    //   "dataFlag": 1
    // }
    // let url = webApi.apiUrl.validateDay1Batch;
    // if (url != '') {
    //   this.deliveryService.fetchSearchableSelectListDynamic(url, params).then(
    //     (res: any) => {
    //       console.log('validateRemarkAndStatus res ', res);
    //       if (res.type) {
    //         this.presentToastMessage(res.message, 'Success', 'success');
    //         // this.initGetData();
    //       } else {
    //         this.loading = false;
    //         this.presentToastMessage('Unable to get results at this time.', 'Warning!', 'warning');
    //       }
    //     },
    //     (err: any) => {
    //       console.log('getStatusList err ', err);
    //       this.loading = false;
    //       this.presentToastMessage('Unable to get results at this time.', 'Warning!', 'warning');
    //     }
    //   ).catch(err => {
    //     console.log('err ', err);
    //     this.loading = false;
    //     this.presentToastMessage('Unable to get results at this time.', 'Warning!', 'warning');
    //   });
    // } else {
    //   console.log('unable to get data url');
    // }

    const extraParams = {
      "dataFlag": 1
    }
    this.loading = true;
    this.attendanceInductionService.validateRemarkAndStatus(this.paramsObject, extraParams, (res) => {
      this.loading = false;
      if (res) {
        // this.initGetData();
      } else {
        console.log('Something went wrong...');
      }
    });
  }
  /*************** Validate attendance End  ************/

  sendEvents(action, data) {
    const event = {
      action: action,
      data: data,
    }
    console.log('sendEvents', event)
    this.getEvents.emit(event);
  }

  async presentToastMessage(msg: string, title: string, type: string) {
    await this.toaster.prsentToast(msg, title, type, null);
  }

  /*************** Generate QR Code start ************/
  generateQr() {
    const params = {
      // areaId: this.paramsObject.areaId,
      activityId: this.paramsObject.activityId,
      roleId: this.currentUserData.roleId,
    }
    this.spinner.presentLoading('Loading');
    this.markAttendanceService.generateQR(params).then((res) => {
      console.log('generateQr response ', res);
      this.spinner.dismiss();
      if (res.type) {
        if (res.data) {
          this.paramsObject.qrLink = res.data.qrLink;
        }
      } else {
        this.presentToastMessage('Unable to generate QR at this time.', 'Warning', 'warning');
      }
    }).catch((err: any) => {
      this.spinner.dismiss();
      console.log('generateQr error ', err);
      this.presentToastMessage('Unable to generate QR at this time.', 'Warning', 'warning');
    });
  }
  /*************** Generate QR Code end ************/

  /*************** Download QR Code start ************/
  downloadQR(link) {
    this.helperService.downloadResource(link, 1);
  }
  /*************** Download QR Code end ************/

  /*************** Bulk mark attendance start ************/
  bulkAttendance() {
    this.sendEvents('bulkAttendanceStatus', true);
  }
  /*************** Bulk mark attendance end ************/
}
