import { Component, Input, OnChanges, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { getWeekYearWithOptions } from 'date-fns/fp';
import { CommentsService } from '../../providers/comments/comments.service';
import { ToasterService } from '../../providers/toaster/toaster.service';

@Component({
  selector: 'ngx-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss', '../../@theme/styles/_typography.scss']
})
export class CommentsComponent implements OnInit, OnChanges {
  @ViewChild('text1') text1: any;
  @Input('currentCourseData') currentCourseData: any;
  @Input('commentsEnabled') commentsEnabled: any = false;
  @Input('displayCommentEnabled') displayCommentEnabled: any = false;
  userDetails: any = JSON.parse(localStorage.getItem('currentUser'));
  data: any;
  comment: string;
  @Input() routeFrom = '';
  editComment: boolean = false;
  edit: boolean = false;
  editIndex: number = null;
  comment_textarea: boolean = false;
  maxPageNo: number = 0;
  currentPageNo: number = 1;
  disableSave: any = false;
  @Output() getEvent = new EventEmitter();
  fetchParams = {
    "instanceId": null,
    "areaId": null,
    "in_searchstring": "",
    "in_dataLimit": 10,
    "in_pNo": this.currentPageNo,
    "userId": this.userDetails.id
  };
  textArea: any = '';
  comment1: any;
  constructor(
    private commentService: CommentsService,
    private toaster: ToasterService) {
    console.log('currentCourseData ' + this.currentCourseData);
  }

  ngOnInit() { }

  ngOnChanges(changes: any) {
    console.log('currentCourseData ' + this.currentCourseData);
    if (this.currentCourseData) {
      this.fetchAllComments(this.currentCourseData);
    }
  }
  /** Function to fetch Comments
 * */

  fetchComments(params: any, cb) {
    this.commentService.loadComments(params).then((response: any) => {
      try {
        cb(response);
      } catch (error) {
        console.error(error);
        cb(error);
      }
    }).catch((error: any) => {
      console.error(error);
    });
  };

  async addUpdateComment(params: any, cb) {
    await this.commentService.updateComment(params).then((response: any) => {
      try {
        cb(response);
      } catch (error) {
        console.error(error);
        cb(error);
      }
    }).catch((error: any) => {
      console.error(error);
      cb(error);
    });
  }

  fetchAllComments(data: any) {
    this.fetchParams.instanceId = data.instanceId;
    // this.fetchParams.instanceId = 62;
    this.fetchParams.areaId = data.areaId;
    // this.load.presentLoading('Please wait...');

    this.fetchComments(this.fetchParams, (response: any) => {
      if (response.type == true) {
        // response.data.length > 0 ? this.data = response.data : null;
        this.data = response.data.length > 0 ? response.data : null;
        this.maxPageNo = response.pageCount;
        const event = {
          data: response.data.length,

        }
        this.getEvent.emit(event);
        // this.load.dismissAll();
      } else {
        // this.load.dismissAll();
        this.presentAlert('Unable to get data at this time. Try again later', 'warning', 'warning');
      }
    });
  };

  action(action: string, index: number, data: any) {
    switch (action) {
      case 'add':
        this.addUpdate(data);
        break;
      case 'show_edit':
        this.showEdit(index);
        break;
      case 'delete':
        this.delete(data);
        break;
      case 'cancel':
        this.cancelEdit(index, data);
        break;
      case 'edit':
        this.comment = data ? data.comments : this.comment
        this.addUpdate(data);
        break;
      default:
        break;
    }
  };

  async showEdit(index) {
    this.edit = true;
    this.editIndex = index;
    this.comment1 = this.data[index].comments
    let element: HTMLElement = document.getElementById('areatxt' + index) as HTMLElement;
    // const element = document.getElementById('areatxt');

    // element.focus()
    // let inputEle = await this.txtArea.getInputElement();
    // inputEle.setSelectionRange(this.txtArea.value.length, this.txtArea.value.length);
    // this.txtArea.nativeElement.focus();
    // this.txtArea.nativeElement.setSelectionRange(this.txtArea.value.length, this.txtArea.value.length);
  };
  cancelEdit(index, data) {
    this.edit = false;
    this.editIndex = null;
    // this.comment = data.comments?data.comments:''
    data.comments = this.comment1
    return data
  };

  async addUpdate(data: any) {
    this.comment = this.comment ? this.comment.trim() : this.comment
    if (this.comment && this.comment.length > 0) {
      if (!this.isCommentValid(this.comment)) {
        this.presentAlert('Special charcters are not allowed', 'warning', 'warning');
      } else {
        this.disableSave = true
        // this.load.presentLoading('Please wait...');
        let params = {
          "id": data.commentsId ? data.commentsId : 0,
          "instanceId": data.instanceId ? data.instanceId : this.currentCourseData.instanceId,
          "areaId": data.areaId ? data.areaId : this.currentCourseData.areaId,
          "comments": this.comment,
          "isActive": 1,
          "actionBy": null,
          "in_platform": 1,
          "in_isDelete": 0
        };
        await this.addUpdateComment(params, (response: any) => {
          this.disableSave = false
          if (response.type == true) {
            this.currentPageNo = 1
            this.fetchParams.in_pNo = 1
            this.fetchAllComments(this.currentCourseData);
            this.edit = false;
            this.editIndex = null;
            this.comment = '';
            this.textArea = ''
            // let element: HTMLElement = document.getElementById('text1') as HTMLElement;
            // element.value = ''
            // this.presentToastMessage(response.data, 0);
            this.presentAlert(response.data, 'Success', 'success');
          } else {
            let msg: string;
            if (response.msg) {
              msg = response.msg;
            } else {
              params.id == 0 && params.in_isDelete == 0 ? msg = 'Unable to add comment. Please try again later' : msg = 'Unable to edit comment. Please try again later';
            }
            // this.presentToastMessage(msg, 1);
            this.presentAlert(msg, 'warning', 'warning');
          }
        });
        // this.load.dismiss();
      }
    } else {
      this.presentAlert('Enter Comment', 'warning', 'warning');
    }
  };

  async delete(data: any) {
    this.disableSave = true
    // this.load.presentLoading('Please wait...');
    let params = {
      "id": data.commentsId,
      "instanceId": data.instanceId ? data.instanceId : this.currentCourseData.instanceId,
      "areaId": data.areaId ? data.areaId : this.currentCourseData.areaId,
      // "comments": this.comment?this.comment:'',
      "comments": data.comments ? data.comments : '',
      "isActive": 1,
      "actionBy": null,
      "in_platform": 1,
      "in_isDelete": 1
    };
    await this.addUpdateComment(params, (response: any) => {
      this.disableSave = false
      if (response.type == true) {
        this.currentPageNo = 1
        this.fetchParams.in_pNo = 1
        this.fetchAllComments(this.currentCourseData);
        this.edit = false;
        this.editIndex = null;
        this.comment = '';
        this.presentAlert(response.data, 'Success', 'success');
      } else {
        this.presentAlert('Unable to delete comment. Please try again later', 'warning', 'warning');
      }
    });
    // this.load.dismissAll();
  };

  changeEvent(comment: string) {
    this.comment = comment;
  };
  async presentToastMessage(msg, type) {
    const toast = {
      message: msg,
      cssClass: type,
    };


    // this.toasterService.showToast(toast);
  };
  async presentAlert(msg, title, type) {
    await this.toaster.prsentToast(msg, title, type, null);
  }

  async loadMore() {
    // this.load.presentLoading('Please wait...');
    this.currentPageNo += this.currentPageNo;
    if (this.currentPageNo <= this.maxPageNo) {
      this.fetchParams.in_pNo = this.currentPageNo;
      await this.fetchComments(this.fetchParams, (response: any) => {
        if (response.type == true) {
          response.data.length > 0 ? this.data = this.data.concat(response.data) : this.data;
          this.maxPageNo = response.pageCount;
        } else {
          this.presentToastMessage('Unable to get data at this time. Try again later', 1);
        }
      });
    }
    // this.load.dismissAll();
  };

  isCommentValid(text) {
    const regEx = new RegExp("^[a-zA-Z0-9 ]+$");
    return regEx.test(text);
  }
}
