import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberFormat'
})
export class NumberFormatPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let myNumber = value;
    let slicevalue = 2;
    if (String(myNumber).length > 1) {
      slicevalue = String(myNumber).length;
    }
    let formattedNumber = ("0" + myNumber).slice(-slicevalue);
    console.log(formattedNumber);
    return formattedNumber;
  }

}
