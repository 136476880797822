import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-idp',
  templateUrl: './idp.component.html',
  styleUrls: ['./idp.component.scss']
})
export class IdpComponent implements OnInit {
  config = {
    showCustomActionLabel: true,
  }

  labels = [
    {
      "labelname": "Change Competency",
      "bindingProperty": "competency",
      "componentType": "text",
      "setting": "{\"maxLength\": \"20\"}",
      "alignment": ""
    },
    {
      "labelname": "Nature of Development Areas",
      "bindingProperty": "natureAreas",
      "componentType": "text",
      "setting": "{\"maxLength\": \"20\"}",
      "alignment": ""
    },
    {
      "labelname": "Development Areas",
      "bindingProperty": "developmentAreas",
      "componentType": "text",
      "setting": "{\"maxLength\": \"20\"}",
      "alignment": ""
    },
    {
      "labelname": "Timeline",
      "bindingProperty": "timeline",
      "componentType": "text",
      "setting": "{\"maxLength\": \"20\"}",
      "alignment": ""
    },
    {
      "labelname": "Coach's Remarks",
      "bindingProperty": "coachRemark",
      "componentType": "text",
      "setting": "{\"maxLength\": \"20\"}",
      "alignment": ""
    },
    {
      "labelname": "Manager's Remarks",
      "bindingProperty": "managerRemark",
      "componentType": "text",
      "setting": "{\"maxLength\": \"20\"}",
      "alignment": ""
    },
    {
      "labelname": "Declration",
      "bindingProperty": "",
      "componentType": "checkbox",
      "setting": "{\"maxLength\": \"20\"}",
      "alignment": ""
    },
    {
      "labelname": "Comment",
      "bindingProperty": "comment",
      "componentType": "text",
      "setting": "{\"maxLength\": \"20\"}",
      "alignment": ""
    },
  ]

  idpList = [
    {
      "competency": "Competency One",
      "natureAreas": "Development One",
      "developmentAreas": "Development One",
      "areas": "Area One",
      "timeline": "Timeline",
      "coachRemark": "Lorem ipsum",
      "managerRemark": "Lorem ipsum",
      "comment": "Lorem ipsum dolar sit amet, consect",
    },
    {
      "competency": "Competency One",
      "natureAreas": "Development One",
      "developmentAreas": "Development One",
      "areas": "Area One",
      "timeline": "Timeline",
      "coachRemark": "Lorem ipsum",
      "managerRemark": "Lorem ipsum",
      "comment": "Lorem ipsum dolar sit amet, consect",
    },
    {
      "competency": "Competency One",
      "natureAreas": "Development One",
      "developmentAreas": "Development One",
      "areas": "Area One",
      "timeline": "Timeline",
      "coachRemark": "Lorem ipsum",
      "managerRemark": "Lorem ipsum",
      "comment": "Lorem ipsum dolar sit amet, consect",
    },
    {
      "competency": "Competency One",
      "natureAreas": "Development One",
      "developmentAreas": "Development One",
      "areas": "Area One",
      "timeline": "Timeline",
      "coachRemark": "Lorem ipsum",
      "managerRemark": "Lorem ipsum",
      "comment": "Lorem ipsum dolar sit amet, consect",
    },
    {
      "competency": "Competency One",
      "natureAreas": "Development One",
      "developmentAreas": "Development One",
      "areas": "Area One",
      "timeline": "Timeline",
      "coachRemark": "Lorem ipsum",
      "managerRemark": "Lorem ipsum",
      "comment": "Lorem ipsum dolar sit amet, consect",
    },
    {
      "competency": "Competency One",
      "natureAreas": "Development One",
      "developmentAreas": "Development One",
      "areas": "Area One",
      "timeline": "Timeline",
      "coachRemark": "Lorem ipsum",
      "managerRemark": "Lorem ipsum",
      "comment": "Lorem ipsum dolar sit amet, consect",
    },
    {
      "competency": "Competency One",
      "natureAreas": "Development One",
      "developmentAreas": "Development One",
      "areas": "Area One",
      "timeline": "Timeline",
      "coachRemark": "Lorem ipsum",
      "managerRemark": "Lorem ipsum",
      "comment": "Lorem ipsum dolar sit amet, consect",
    },
    {
      "competency": "Competency One",
      "natureAreas": "Development One",
      "developmentAreas": "Development One",
      "areas": "Area One",
      "timeline": "Timeline",
      "coachRemark": "Lorem ipsum",
      "managerRemark": "Lorem ipsum",
      "comment": "Lorem ipsum dolar sit amet, consect",
    },
    {
      "competency": "Competency One",
      "natureAreas": "Development One",
      "developmentAreas": "Development One",
      "areas": "Area One",
      "timeline": "Timeline",
      "coachRemark": "Lorem ipsum",
      "managerRemark": "Lorem ipsum",
      "comment": "Lorem ipsum dolar sit amet, consect",
    },
    {
      "competency": "Competency One",
      "natureAreas": "Development One",
      "developmentAreas": "Development One",
      "areas": "Area One",
      "timeline": "Timeline",
      "coachRemark": "Lorem ipsum",
      "managerRemark": "Lorem ipsum",
      "comment": "Lorem ipsum dolar sit amet, consect",
    },
    {
      "competency": "Competency One",
      "natureAreas": "Development One",
      "developmentAreas": "Development One",
      "areas": "Area One",
      "timeline": "Timeline",
      "coachRemark": "Lorem ipsum",
      "managerRemark": "Lorem ipsum",
      "comment": "Lorem ipsum dolar sit amet, consect",
    },
    {
      "competency": "Competency One",
      "natureAreas": "Development One",
      "developmentAreas": "Development One",
      "areas": "Area One",
      "timeline": "Timeline",
      "coachRemark": "Lorem ipsum",
      "managerRemark": "Lorem ipsum",
      "comment": "Lorem ipsum dolar sit amet, consect",
    },
   
  ]

  constructor() { }

  ngOnInit(): void {
  }

}
