<!-- <div appAspectRatio [isFullScreen]="isScromFullScreen" [isVertical]="isVertical" [isHorizontal]="isHorizontal" [hidden]="isIframeNotLoaded">
    <iframe class="iFrame-width" allowfullscreen="0" [src]="safeUrl" width="1600" height="1600" frameborder="0" allowtransparency="true" (load)="onLoad($event)">
  </iframe>
</div>
<div class="iframe-not-loaded" [hidden]="!isIframeNotLoaded">
    <ion-spinner name="crescent"></ion-spinner>
</div> -->

<div class="option-class">
    <iframe id="h5p-iframe" [src]="manifesturl | safePipePipe: 'resourceUrl'" frameborder="0" allowfullscreen="0" allow="geolocation *; microphone *; camera *; midi *; encrypted-media *" samesite="none"></iframe>
    <script src="https://h5p.org/sites/all/modules/h5p/library/js/h5p-resizer.js" charset="UTF-8"></script>
</div>

<!-- <div [ngClass]="{'make-scrom-full-screen-backdrop': isFullScreenEnabled}">
    <div [ngClass]="{'rotate_player': isScromHorizontalFullScreen}">

        <div [ngClass]="{'iframe-rotate-mode': isScromHorizontalFullScreen}" *ngIf="loaded === true && downloading === false && manifesturl !== '' && isFullScreenEnabled === false">
            <div class="make-scrom-full-screen-container" [ngClass]="{'make-scrom-full-screen-container--horizontal': isScromVerticalFullScreen}">
                <div class="make-scrom-full-screen-container-button" *ngIf="isFullScreenEnabled" (click)="exitFullScreen()">
                    <ion-icon name="contract"></ion-icon>
                </div>
                <div class="make-scrom-full-screen-container-button" (click)="changeScreenOrientation()">
                    <img src="assets/icon/screen_rotation-24px.svg" class="rotate_icon_color" alt="" srcset="">
                </div>
            </div>
        </div>

        <ion-fab appAbsoluteDrag startLeft="20" startTop="100" *ngIf="isFullScreenEnabled">
            <ion-fab-button class="fabbtn">
                <ion-icon ios="menu" md="menu"></ion-icon>
            </ion-fab-button>
            <ion-fab-list side="top">
                <ion-fab-button (click)="exitFullScreen()">
                    <ion-icon ios="contract" md="contract"></ion-icon>
                </ion-fab-button>
            </ion-fab-list>
        </ion-fab>

        <ng-template #scormContent>
            <app-iframe-scrom [isScromFullScreen]="isFullScreenEnabled" [isVertical]="isScromVerticalFullScreen" [isHorizontal]="isScromHorizontalFullScreen" [url]="manifesturl"></app-iframe-scrom>
        </ng-template>

        <div style="overflow: auto!important; -webkit-overflow-scrolling: touch!important;" *ngIf="loaded === true && downloading === false && manifesturl !== ''" [ngClass]="{'rotated-iframe': isScromHorizontalFullScreen, 'not-full-screen': !isFullScreenEnabled}"
            [ngStyle]="setMyStyles()">
            <ng-container *ngTemplateOutlet="scormContent"></ng-container>
        </div>
    </div>
</div> -->