import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { LoginService } from '../providers/login/login.service';
import { MsalService } from '@azure/msal-angular';
import { CheckInactivityService } from '../modules/shared/providers/utils/check-inactivity/check-inactivity.service';
@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {

  authStatus: any;
  constructor(
    private router: Router,
    // public storage: Storage,
    public authenticationService: LoginService,
    private authService: MsalService,
    private checkInactivityService: CheckInactivityService,
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {

    this.authStatus = this.authenticationService.isAuthenticated();
    console.log('LoginGuard with status', this.authStatus);

    return new Promise((resolve, reject) => {
      const val = JSON.parse(localStorage.getItem('currentUser'));
      console.log('LoginGuard currentUser', val);
      const ssoStatus = localStorage.getItem('sso_inprogress');
      if (val) {
        // this.router.navigateByUrl('/pages/features/home');
        // resolve(false);
        this.redirectUser(val, resolve);
      } else {
        // resolve(true);
        if (ssoStatus && ssoStatus == 'true') {
          if (state.url !== '/auth/login') {
            // this.router.navigate(['/auth/login']);
            this.authenticationService.redirectToLogin();
            resolve(false);
          } else {
            resolve(true);
          }
        } else {
          resolve(true);
        }
      }
      // this.storage.ready().then(() => {
      //   console.log('storage ready');
      //   this.storage.get('currentUser').then((val) => {
      //     console.log('currentUser', val);
      //     if (val) {
      //       this.router.navigateByUrl('/pages/features/home');
      //       resolve(false);
      //     } else {
      //       resolve(true);
      //     }
      //   });
      // });

    });
  }

  redirectUser(currentUser, resolve) {
    if (!this.authenticationService.logoutRunning) {
      if (currentUser) {
        if (currentUser.sso_login == 1) {
          const ssoDetails = this.authService.instance.getAccountByUsername(currentUser.email);
          if (ssoDetails) {
            this.checkInactivityService.resetValues();
            console.log('Login guard called...');
            this.authenticationService.goToDashboard();
            resolve(false);
          } else {
            console.log('SSo User email is not valid..');
            resolve(true);
          }
        } else {
          console.log('User is not logged in by sso..');
          // resolve(true);
          console.log('Login guard called...');
          this.authenticationService.goToDashboard();
          resolve(false);
        }
      } else {
        console.log('User is not logged in..');
        resolve(true);
      }
    } else {
      console.log('User logout inprogress..');
      resolve(true);
    }
  }


}
