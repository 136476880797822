<div class="cardDiv">
  <span *ngIf="disable" class="disableCard text-center"><i (click)="enable()" class="fas fa-eye"></i></span>
  <div class="cardinnerDiv">
    <div class="imageDiv">
      <img [src]="cardItem[cardConfig['image']]" (click)="fireEvent('goToContent',cardItem)"
        onerror="this.onerror=null; this.src='./assets/images/activity1.jpg'" alt="card-image" />

      <!-- (mouseleave)="mouseLeave()" -->
      <div *ngIf="cardConfig?.showMore" class="allIcons text-right" (mouseleave)="hideIcon()">
        <!-- (mouseenter)="mouseEnter(cardItem)" -->
        <i class="fas fa-ellipsis-v option cursor-p" (mouseenter)="showIcon(cardItem)"></i>
        <!-- <img src="assets/icon-svg/ellipsis-v-solid.svg" alt="" class=" option cursor-p" srcset="" (mouseenter)="mouseEnter()"/> -->
        <div class="showlist" *ngIf="!flagStart" [hidden]="!show">
          <!-- <label class="m-0 d-block cursor-p" (click)="outputEvent(cardItem, cardEntity.pin)">
                        <svg-icon src="../../../assets/icon-svg/course-card/pin.svg"   [stretch]="true">
                        </svg-icon>
                        <span>{{ cardItem[cardConfig['isPinned']] == 1 ? 'Pinned' : 'Pin' }}</span>
                    </label> -->
          <!-- <label class="m-0 d-block cursor-p" (click)="outputEvent(cardItem, cardEntity.schedule)">
                        <svg-icon src="../../../assets/icon-svg/course-card/clock.svg"   [stretch]="true">
                        </svg-icon>

                        <span>{{ cardItem[cardConfig['isScheduled']]  == 1 ? 'Reschedule' : 'Schedule' }}</span>
                    </label>
                    <label class="m-0 d-block cursor-p" (click)="outputEvent(cardItem, cardEntity.addtocart)">
                        <svg-icon src="../../../assets/icon-svg/course-card/cart.svg"   [stretch]="true">
                        </svg-icon>
                        <span>{{ cardItem[cardConfig['isAddToCart']]  != 0 ? 'Go To cart' : 'Add to cart' }}</span>
                    </label> -->
          <ng-container *ngFor="let item of buttons">
            <!-- fireEvent( item.event.id,  item.event.value) -->
            <label class="m-0 d-block align-items-center cursor-p " (click)="optionClicked(item.event, cardItem)">
              <svg-icon [src]="item?.icon" [stretch]="true ">
              </svg-icon>
              <span class="more-text">{{ item?.text }}</span>
            </label>
          </ng-container>
        </div>
        <div class="showlist" *ngIf="flagStart" id="step-card-hover">
          <!-- <label class="m-0 d-block cursor-p" (click)="outputEvent(cardItem, cardEntity.pin)">
                        <svg-icon src="../../../assets/icon-svg/course-card/pin.svg"   [stretch]="true">
                        </svg-icon>
                    <span>{{ cardItem[cardConfig['isPinned']] == 1 ? 'Pinned' : 'Pin' }}</span>
                </label>
                    <label class="m-0 d-block cursor-p" id="step-hover-schedule" (click)="outputEvent(cardItem, cardEntity.schedule)">
                        <svg-icon src="../../../assets/icon-svg/course-card/clock.svg"   [stretch]="true">
                        </svg-icon>
                        <span>{{ cardItem[cardConfig['isScheduled']]  == 1 ? 'Reschedule' : 'Schedule' }}</span>
                </label>
                    <label class="m-0 d-block cursor-p" id="step-hover-addedToCart" (click)="outputEvent(cardItem, cardEntity.addtocart)">
                        <svg-icon src="../../../assets/icon-svg/course-card/cart.svg"   [stretch]="true">
                        </svg-icon>
                        <span>{{ cardItem[cardConfig['isAddToCart']]  != 0 ? 'Go To cart' : 'Add to cart' }}</span>
                </label> -->
          <ng-container *ngFor="let item of buttons">
            <!-- fireEvent( item.event.id,  item.event.value) -->
            <label class="m-0 d-block align-items-center cursor-p " (click)="optionClicked(item.event, cardItem)">
              <svg-icon [src]="item?.icon" [stretch]="true ">
              </svg-icon>
              <span class="more-text">{{ item?.text }}</span>
            </label>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="bottomDiv" (click)="fireEvent('goToContent',cardItem)">
      <div class="type d-flex align-items-center mb-1">
        <img src="../.../../../../assets/icon-svg/course.svg" alt="instance-icon">
        <span class="ml-2">{{ cardItem[cardConfig["type"]] }}</span>
      </div>
      <h5>{{ cardItem[cardConfig["title"]] }}</h5>
      <div class="progress-bar-container two-grid-container" *ngIf="cardConfig?.showPercentage">
        <div>
          <p class="progresstext">{{ cardItem[cardConfig["percentageLabel"]] || 0 }}%</p>
        </div>
        <div class="progress">
          <div class="progress-bar" role="progressbar" aria-valuemin="0" aria-valuemax="100"
            [style.width]="cardItem[cardConfig['percentageLabel']] + '%'"></div>
        </div>
      </div>
      <div class="count" *ngIf="cardConfig?.showList">
        <!-- <div class="d-inline-flex align-content-center mLR3">
                    <span class="subcontent">{{ cardItem[cardConfig["viewLabel"]] }} Views</span>
                    </div>
                    <div class="d-inline-flex align-content-center mLR3">
                    <span class="subcontent">&#x2022; </span>
                    <span class="subcontent">{{ cardItem[cardConfig["commentsLabel"]] }} Comments</span>
                    </div>
                    <div class="d-inline-flex align-content-center mLR3">
                    <span class="subcontent">&#x2022; </span>
                    <span class="subcontent">{{ cardItem[cardConfig["shareLabel"]] }} Share</span>
                </div> -->
        <ul class="count__list d-flex align-items-center list-unstyled pl-0 mb-1">
          <li class="count__list--item">{{ cardItem[cardConfig["viewLabel"]] }} Views</li>
          <span class="count__list--dot mx-2"></span>
          <li class="count__list--item">{{ cardItem[cardConfig["commentsLabel"]] }} Comments</li>
          <span class="count__list--dot mx-2"></span>
          <li class="count__list--item">{{ cardItem[cardConfig["shareLabel"]] }} Share</li>
          <!-- <ng-container *ngIf="cardConfig['likeEnabled'] && cardItem[cardConfig['likeCount']]">
                        <span class="count__list--dot mx-2"></span>
                        <li class="count__list--item">{{ cardItem[cardConfig['likeCount']] }} Likes</li>
                    </ng-container>
                    <ng-container *ngIf="cardConfig['dislikeEnabled'] && cardItem[cardConfig['dislikeCount']]">
                        <span class="count__list--dot mx-2"></span>
                        <li class="count__list--item">{{ cardItem[cardConfig['dislikeCount']] }} Dislikes</li>
                    </ng-container> -->
        </ul>
      </div>

      <!-- Like & Dislike -->
      <!-- <div class="count list-unstyled pb-1" *ngIf="cardConfig['likeEnabled'] || cardConfig['dislikeEnabled']">
                <ng-container *ngIf="cardConfig['likeEnabled']">
                    <li class="count__list--item">{{ cardItem[cardConfig['likeCount']] || 0 }} Likes</li>
                </ng-container>
                <ng-container *ngIf="cardConfig['dislikeEnabled']">
                    <span class="count__list--dot mx-2"></span>
                    <li class="count__list--item">{{ cardItem[cardConfig['dislikeCount']] || 0 }} Dislikes</li>
                </ng-container>
            </div> -->

      <div class="d-flex align-items-center pb-2">
        <!-- likes -->
        <ng-container *ngIf="cardConfig['likeEnabled']">
          <div class="d-flex align-items-center lh-1">
            <svg-icon svgClass="stroke-gray fill-white" src="../../../assets/icon-svg/Thumbs_up_icon.svg"
              [svgStyle]="{'width.rem': 1, 'height.rem': 1, 'stroke-width': 1.4}"></svg-icon>
            <span class="fs-12 pl-1">{{ cardItem[cardConfig['likeCount']] || 0 }}</span>
          </div>
        </ng-container>
        <!-- dislikes -->
        <ng-container *ngIf="cardConfig['dislikeEnabled']">
          <span class="h-16-px w-1-px bg-gray-100 mx-2"></span>
          <div class="d-flex align-items-center lh-1">
            <svg-icon svgClass="stroke-gray fill-white" src="../../../assets/icon-svg/Thumbs_down_icon.svg"
              [svgStyle]="{'width.rem': 1, 'height.rem': 1, 'stroke-width': 1.4}"></svg-icon>
            <span class="fs-12 pl-1">{{ cardItem[cardConfig['dislikeCount']] || 0 }}</span>
          </div>
        </ng-container>
      
        <ng-container *ngIf="cardConfig?.languageEnabled && cardItem[cardConfig?.languageName]">
          <span class="h-16-px w-1-px bg-gray-100 mx-2"></span>
          <div class="d-flex align-items-center lh-1">
            <svg-icon svgClass="stroke-gray fill-white" src="assets/icon/svg/course-card/language.svg"
              [svgStyle]="{'width.rem': 1, 'height.rem': 1, 'stroke-width': 1.4}"></svg-icon>
            <span class="fs-12 pl-1">{{cardItem[cardConfig?.languageName]}}</span>
          </div>
        </ng-container>
      </div>

      <div class="d-flex align-items-center" *ngIf="cardConfig?.showStars">
        <div class="mr-1">
          <p>({{ cardItem[cardConfig["starCountLabel"]] || 0 }})</p>
        </div>
        <div>
          <star-rating readOnly="true" [starType]="'svg'" [size]="'small'" [numOfStars]="cardItem?.outof" Color="ok"
            [showHalfStars]="true" rating="{{cardItem[cardConfig['starCountLabel']] || 0}}"></star-rating>
        </div>
      </div>
    </div>
  </div>
</div>
