<ng-conatiner [nbSpinner]="loading" nbSpinnerStatus="primary">
    <!--  *ngIf="tableData.length > 0" -->
    <div class="d-flex align-items-center justify-content-between py-3 mt-1" *ngIf="!notFound">

        <div class="ml-2 w-25">
            <input class="form-control form-control-sm" type="search" placeholder="Search" [formControl]="searchControl"
                maxlength="20">
        </div>

        <div class="w-75 mr-2 d-flex align-items-center justify-content-end">

            <ng-container *ngIf="userSelected">
                <!-- <select class="custom-select custom-select-sm mx-2" [(ngModel)]="selectedStatus.id"
                    (ngModelChange)="statusSelected($event)">
                    <option *ngFor="let option of statusList | jsonFormatter" [ngValue]="option?.id"
                        [disabled]="option?.disabled == 1">
                        {{option?.name}}
                    </option>
                </select> -->
                <!-- <input type="text" class="form-control form-control-sm mx-2" placeholder="Remark"> -->

                <span class="ml-2">
                    <ngx-dropdown [label]="statusConfig" [data]="selectedStatus"
                        [dropdownList]="statusRemarkList | jsonFormatter"
                        (performAction)="performAction($event);"></ngx-dropdown>
                </span>

                <span class="ml-2">
                    <ngx-input-w-submit [label]="remarkConfig" [data]="selectedStatus"></ngx-input-w-submit>
                </span>

                <button class="fs-14 commonBtn btn-fill h-2-rem ml-2"
                    (click)="updateAttendanceStatus(1,null)">Save</button>

            </ng-container>

            <button class="fs-14 commonBtn btn-outline h-2-rem ml-2"
                *ngIf="!userSelected && validateEnable == 1 && tableData?.length > 0"
                (click)="validateRemarkAndStatus()">Validate</button>
                <!-- paramsObject?.byQR - {{paramsObject?.byQR}}
                paramsObject?.qrGenAvailable - {{paramsObject?.qrGenAvailable}} -->
            <button class="fs-14 commonBtn h-2-rem button btn-outline py-2 ml-2"
                *ngIf="!paramsObject?.qrLink && paramsObject?.byQR && paramsObject?.qrGenAvailable"
                (click)="generateQr()">
                Generate QR
            </button>

            <button class="fs-14 commonBtn h-2-rem w-10-rem button btn-outline pt-2 ml-2"
                *ngIf="paramsObject?.isbulkAttendance" (click)="bulkAttendance()">
                Bulk Attendance
            </button>

        </div>

    </div>

    <!-- <div class="h-27-rem overflow-auto"> -->
        <div class="qrimage position-relative h-12-rem w-12-rem border rounded mb-3 mx-auto"
            *ngIf="!notFound && paramsObject?.qrLink && paramsObject?.qrShow">
            <img class="w-100 h-100 rounded" [src]="paramsObject?.qrLink" alt="qr">
            <span
                class="qrimage__download position-absolute bg-white right-5-px top-5-px border-radius-4px border shadow p-1 px-2 cursor-p"
                (click)="downloadQR(paramsObject?.qrLink)">
                <svg-icon svgClass="lh-0 stroke-primary" class="lh-0" src="../../../assets/icon-svg/Download_icon.svg"
                    [svgStyle]="{'width.rem': 1, 'height.rem': 1, 'stroke-width': 1.4}"></svg-icon>
            </span>
        </div>

        <!-- <div>
        <ngx-table [tableData]="TABELE" [setting]="tableSetting" [label]="LABEL" [pagination]=false></ngx-table>
    </div> -->

        <div [ngClass]="{'maxHeight overflow-auto': isInternalComponent}"
            [style.maxHeight]="isInternalComponent ? externalHeight : null" nbInfiniteList
            [listenWindowScroll]="!isInternalComponent" [threshold]="500" (bottomThreshold)="loadMoreData($event)">

            <ngx-table *ngIf="!notFound" [setting]="tableConfig" [tableData]="tableData" [label]="tableLabels"
                [pagination]="false" (getEvents)="performAction($event)"></ngx-table>

            <ng-container *ngIf="working">
                <div class="p-4" [nbSpinner]="true" nbSpinnerStatus="primary">
                </div>
            </ng-container>
        </div>
    <!-- </div> -->

</ng-conatiner>

<ng-container *ngIf="!loading && !working && notFound">
    <!-- <div [ngClass]="{'maxHeight': type == 4}" [style.maxHeight]="type == 4 ? externalHeight : null"> -->
    <div class="h-55-vh d-flex align-items-center justify-content-center">
        <ngx-no-data [title]="noDataFoundContainer?.title" [discription]="noDataFoundContainer?.discription"
            [showImage]="noDataFoundContainer?.showImage"></ngx-no-data>
    </div>
    <!-- </div> -->
</ng-container>