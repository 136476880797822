<div class="modal-dialog  dialog modal-dialog-centered" role="document" *ngIf="config">
    <div class="modal-content">
        <div class="modal-header header">
            <button type="button" class="close" (click)="this.ref.close(closeEvent)" data-dismiss="modal"
                aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body body py-4 d-flex flex-column">
            <div class="text-center pb-2" *ngIf="config?.iconSetting?.iconImg">
                <!-- <img style="height: 6rem;color:grey" src="{{config?.iconSetting?.iconImg}}" alt=""> -->
                <svg-icon svgClass="{{config?.extraClass}}" [svgStyle]="config?.extraStyle"
                    [src]="config?.iconSetting?.iconImg">
                </svg-icon>
            </div>
            <h1 *ngIf="config?.title" class="mt-1 mb-2">{{config?.title}}</h1>
            <h2 *ngIf="config?.message">{{config?.message}}</h2>
            <p *ngIf="config?.customHtml" class="mt-2" [innerHtml]="config?.customHtml"></p>
        </div>
        <div class="modal-footer footer" *ngIf="config?.buttons && config?.buttons.length !=0">
            <!-- btn-light, btn-primary -->
            <button type="button" class="{{item?.classList}}" *ngFor="let item of config?.buttons; let isFirst = first"
                [ngClass]="{ 'ml-2': !isFirst }" (click)="this.ref.close(item)"
                data-dismiss="modal">{{item?.btnText}}</button>
            <!-- <button type="button" class="btn  " (click)="this.ref.close()">Yes</button> -->
        </div>
    </div>
</div>