<div class="course-content-learn cursor-p p-0 d-inline-block">
    <img class="course-content-learn__img w-100" (click)="fireEvent('goToContent',cardsData)" [src]="cardsData[config.image]" onerror="this.onerror=null; this.src='./assets/images/activity1.jpg'" alt="card-image" />
    <div class="allIcons text-right" (mouseleave)="mouseLeave(cardsData)">
        <i class="fas fa-ellipsis-v option cursor-p" *ngIf="cardsData?.isMoreEnable == 1" (mouseenter)="mouseEnter(cardsData) "></i>
        <!-- <label class="m-0 d-block cursor-p " (click)="outputEvent(cardItem, cardEntity.pin) ">
          <svg-icon src="../../../assets/icon-svg/course-card/pin.svg "   [stretch]="true ">
          </svg-icon>
          <span>{{ cardsData?.isPinned === 1 ? 'Pinned' : 'Pin' }}</span>
      </label> -->
        <!-- *ngIf="selectedSubTabName=='ONGOING' " *ngIf="selectedSubTabName=='ONGOING' " -->
        <div class="showlist " *ngIf="cardsData?.show ">

            <!-- <label class="m-0 d-block cursor-p " (click)="fireEvent('schedule',cardsData)">
          <svg-icon src="../../../assets/icon-svg/course-card/clock.svg "   [stretch]="true ">
          </svg-icon>
          <span>{{ cardsData[config.scheduleFlag] ? 'Reschedule' : 'Schedule' }}</span>
      </label>
            <label class="m-0 d-block cursor-p " (click)="fireEvent('addToCart',cardsData)">
          <svg-icon src="../../../assets/icon-svg/course-card/cart.svg "   [stretch]="true ">
          </svg-icon>
          <span>{{ cardsData[config.goToCartFlag] ? 'Go To cart' : 'Add to cart' }}</span>
      </label> -->
            <ng-container *ngFor="let item of buttons">
                <label class="m-0 d-block cursor-p " (click)="fireEvent( item.event.id,  item.event.value)">
            <svg-icon [src]="item?.icon"   [stretch]="true ">
            </svg-icon>
            <span>{{ item?.text }}</span>
        </label>
            </ng-container>
        </div>
    </div>
    <div class="course-content-learn__data p-2 " (click)="fireEvent('goToContent',cardsData)">
        <div class="course-content-learn__type d-flex align-items-center mb-1 ">
            <img src="../.../../../../assets/icon-svg/course.svg " width="12 " height="12 " alt="instance-icon ">
            <span class="ml-2 ">{{ cardsData[config.instanceType] }}</span>
        </div>
        <h5 class="mb-lg-2 mb-0 ">{{cardsData[config.title]}}</h5>
        <div class="d-flex align-items-center ">
            <span class="course-content-learn__progcount ">{{cardsData[config.instanceProgress] ? cardsData[config.instanceProgress] : '0'}}%</span>
            <div class="progress w-100 ml-2 " style="height: 4px; ">
                <div class="progress-bar " role="progressbar " [style.width.%]="cardsData[config.instanceProgress] ? cardsData[config.instanceProgress] : 0 " aria-valuemin="0 " aria-valuemax="100 "></div>
            </div>
        </div>
    </div>
</div>
