import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'ngx-html-editor',
  templateUrl: './html-editor.component.html',
  styleUrls: ['./html-editor.component.scss']
})
export class HtmlEditorComponent implements OnInit {
  @Input() bindingText: any = null;
  @ViewChild('htmlEditor') htmlEditor;
  @Input() isSubmitted = false;
  @Input() config: any = {
    required: false,
    // minLength: null,
    // maxLength: null
  };
  quillConfig = {
    //toolbar: '.toolbar',
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['code-block'],
        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        //[{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
        //[{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
        //[{ 'direction': 'rtl' }],                         // text direction

        //[{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        //[{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        //[{ 'font': [] }],
        //[{ 'align': [] }],

        ['clean'],                                         // remove formatting button

        ['link'],
        //['link', 'image', 'video']
      ],
    },

    // mention: {
    //   allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
    //   mentionDenotationChars: ["@", "#"],
    //   source: (searchTerm, renderList, mentionChar) => {
    //     let values;

    //     if (mentionChar === "@") {
    //       values = this.atValues;
    //     } else {
    //       values = this.hashValues;
    //     }

    //     if (searchTerm.length === 0) {
    //       renderList(values, searchTerm);
    //     } else {
    //       const matches = [];
    //       for (var i = 0; i < values.length; i++)
    //         if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())) matches.push(values[i]);
    //       renderList(matches, searchTerm);
    //     }
    //   },
    // },
    // "emoji-toolbar": true,
    // "emoji-textarea": false,
    // "emoji-shortname": true,
    keyboard: {
      bindings: {
        // shiftEnter: {
        //   key: 13,
        //   shiftKey: true,
        //   handler: (range, context) => {
        //     // Handle shift+enter
        //     console.log("shift+enter")
        //   }
        // },
        enter: {
          key: 13,
          handler: (range, context) => {
            console.log("enter");
            return true;
          }
        }
      }
    }
  };
  public htmlEditorControl: UntypedFormControl;
  @Output() getEvents = new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void {
    this.htmlEditorControl = new UntypedFormControl();
    this.initFormControl();
  }

  // ngOnChanges(changes: SimpleChanges): void {
  //   if (changes['bindingText'] && (changes['bindingText'].currentValue != changes['bindingText'].previousValue)) {
  //     setTimeout(() => {
  //       this.htmlInputChanged(this.htmlEditor, this.bindingText);
  //     }, 5000)

  //   }
  // }

  htmlInputChanged(htmlEditor, event) {
    if (htmlEditor) {
      this.fireEvent('htmlEditorInputChanged', {
        isValid: htmlEditor.valid,
        input: event
      })
    }

  }

  initFormControl() {
    this.htmlEditorControl.valueChanges
      .pipe(debounceTime(700))
      .subscribe((value) => {
        // this.setFilteredItems();
        console.log("this.htmlEditorControl : ", this.htmlEditorControl);
        this.htmlInputChanged(this.htmlEditorControl, value)
      });
  }

  fireEvent(inEvent, inData) {
    const res = {
      type: inEvent,
      data: inData,
    };
    this.getEvents.emit(res);
  }
}
