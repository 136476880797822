import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ngx-user-searchable-list',
  templateUrl: './user-searchable-list.component.html',
  styleUrls: ['./user-searchable-list.component.scss']
})
export class UserSearchableListComponent implements OnInit {
  @Input() user: any = null;
  @Input() config: any = null;
  @Output() getEvent = new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void {
  }

  sendEvent(action, user) {
    console.log('checkChanged', user);
    const event = {
      action: action,
      data: user,
    }
    this.getEvent.emit(event);
  }
}
