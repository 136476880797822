import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { webApi } from "../../../config";
import { feature } from "../../../environments/feature-enviroment";
import { Router, ActivatedRoute } from "@angular/router";
import { ThemeService } from "../../providers/theme/theme.service";
import { BehaviorSubject, Observable, of } from "rxjs";
import { EventsService } from "../events/events.service";
import { PreferencesService } from "../preferences/preferences.service";
// import { UserService } from "../userservice/user.service";
import { AlertService } from "../alert/alert.service";
// import { ToasterService } from '../toaster/toaster.service';
import * as CryptoJS from "crypto-js";
import { rejects } from "assert";
import { map } from "rxjs/internal/operators/map";
import { tap } from "rxjs/internal/operators/tap";
import { catchError } from "rxjs/internal/operators/catchError";
import { empty } from "rxjs/internal/observable/empty";
import { UserService } from '../userservice/user.service';
import { BrowserCacheLocation, PublicClientApplication } from "@azure/msal-browser";
import { LanguageService } from "../language/language.service";
import { YellowMessangerService } from "../../modules/shared/providers/yellow-messanger/yellow-messanger.service";
import { CryptoService } from "../../modules/shared/providers/utils/crypto/crypto.service";
@Injectable({
  providedIn: "root",
})
export class LoginService {
  TenantInfo: any;
  private domainBasedTenant: any;
  featureConfig = feature;
  logoutRunning = false;
  baseUrl: any = webApi.baseUrl;
  refresh_auth_token_url = this.baseUrl + webApi.apiUrl.refresh_auth_token;
  gettenantlistUrl = this.baseUrl + webApi.apiUrl.tenantList;
  currentToken: any;
  get_updated_user_details_url = this.baseUrl + webApi.apiUrl.get_updated_user_details;
  update_user_login_details_url = this.baseUrl + webApi.apiUrl.updated_user_login_details;

  sassVerifyUserUrl = this.baseUrl + webApi.apiUrl.sassVerifyUser;

  authState = new BehaviorSubject(false);
  // popupConfig = {
  //   title: "Login Info",
  //   message: "",
  //   buttons: [
  //     {
  //       id: "confirm",
  //       btnText: "Yes",
  //     },
  //     {
  //       id: "cancel",
  //       btnText: "No",
  //     },
  //   ],
  // };
  popupConfig = {
    title: "Login Info",
    message: "",
    buttons: [
      {
        id: "",
        btnText: "Okay",
      },
    ],
  };
  constructor(
    private http: HttpClient,
    private routes: ActivatedRoute,
    // private msalService: MsalService,
    // private webinarService: WebinarServiceProvider,
    // public messagingService: MessagingService,
    // private analyticsService: FirebaseAnalyticsService,
    // private userservice: UserService,
    // public multiLanguageService: MultiLanguageService,
    private router: Router, //
    private themeService: ThemeService,
    public events: EventsService,
    // private userService: UserService,
    public preferenceService: PreferencesService,
    private alertService: AlertService,
    // private toaster: ToasterService,
    private userService: UserService,
    private languageService: LanguageService,
    private yellowMessangerService: YellowMessangerService,
    private cryptoservice:CryptoService
  ) { }

  ssoverifyuser(param) {
    const url = webApi.baseUrl + "" + webApi.apiUrl.ssoverifyuser;
    return new Promise((resolve) => {
      this.http
        .post(url, param)
        // .map(res => res.json())
        .subscribe(
          (data) => {
            resolve(data);
          },
          (err) => {
            resolve(err);
          }
        );
    });
  }

  authUserData(param) {
    // var url =ENUM.domain + '' + ENUM.url.auth;
    const url = webApi.baseUrl + "" + webApi.apiUrl.ssoLogin;
    return new Promise((resolve, reject) => {
      this.http
        .post(url, param)
        // .map(res => res.json())
        .subscribe(
          (data) => {
            resolve(data);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getToken(data) {
    // var url =ENUM.domain + '' + ENUM.url.auth;
    const url = webApi.baseUrl + "" + webApi.apiUrl.getToken;
    // return new Promise((resolve) => {
    //   this.http
    //     .post(url, param)
    //     // .map(res => res.json())
    //     .subscribe(
    //       (data) => {
    //         resolve(data);
    //       },
    //       (err) => {
    //         resolve(err);
    //         if (err) {
    //           // this.toastr.error('Please check server connection', 'Server Error!');
    //           // this.toastr.warning('Something went wrong please try again later', 'Warning!');
    //         }
    //       }
    //     );
    // });
    return this.http.post(url, data)
      .toPromise()
      .then(response => {
        return response = response;
      })
      .catch(this.handleErrorStatusCode);
  }

  verifyToken(data) {
    // var url =ENUM.domain + '' + ENUM.url.auth;
    const url = webApi.baseUrl + "" + webApi.apiUrl.verifyToken;
    // const headers = new HttpHeaders({
    //   'Content-Type': 'application/json',
    //   'Authorization': `Bearer ${param.token}`
    // });
    // delete param.token;
    // return new Promise((resolve) => {
    //   this.http.post(url, param, { headers: headers })
    //     // this.http.post(url, param)
    //     // .map(res => res.json())
    //     .subscribe(
    //       (data) => {
    //         resolve(data);
    //       },
    //       (err) => {
    //         resolve(err);
    //         if (err) {
    //           // this.toastr.error('Please check server connection', 'Server Error!');
    //           // this.toastr.warning('Something went wrong please try again later', 'Warning!');
    //         }
    //       }
    //     );
    // });

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${data.token}`
    });
    delete data.token;
    return this.http.post(url, data, { headers: headers })
      .toPromise()
      .then(response => {
        return response = response;
      })
      .catch(this.handleErrorStatusCode);
  }

  resetPassword(param) {
    // var url =ENUM.domain + '' + ENUM.url.auth;
    const url = webApi.baseUrl + "" + webApi.apiUrl.resetPassword;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${param.token}`
    });
    delete param.token;
    return new Promise((resolve, reject) => {
      this.http
        .post(url, param, { headers: headers })
        // .post(url, param)
        // .map(res => res.json())
        .subscribe(
          (data) => {
            resolve(data);
          },
          (err) => {
            reject(err);
            if (err) {
              // this.toastr.error('Please check server connection', 'Server Error!');
              // this.toastr.warning('Something went wrong please try again later', 'Warning!');
            }
          }
        );
    });
  }

  LoginUsingSSO() {
    const url = webApi.baseUrl + webApi.apiUrl.ssoLogin;
    return new Promise((resolve) => {
      this.http
        .get(url)
        // .map(res => res.json())
        .subscribe(
          (data) => {
            resolve(data);
          },
          (err) => {
            resolve(err);
            if (err) {
              // this.toastr.error('Please check server connection','Server Error!');
              // this.toastr.warning('Something went wrong please try again later', 'Warning!');
            }
          }
        );
    });
  }
  msalLogin(param) {
    const url = webApi.baseUrl + webApi.apiUrl.ssoLogin;
    return new Promise((resolve) => {
      this.http
        .post(url, param)
        // .map(res => res.json())
        .subscribe(
          (data) => {
            resolve(data);
          },
          (err) => {
            resolve(err);
            // if (err) {
            //   // this.toastr.error('Please check server connection','Server Error!');
            //   this.toastr.warning(
            //     "Something went wrong please try again later",
            //     "Warning!"
            //   );
            // }
          }
        );
    });
  }

  setTenant(tenant) {
    this.TenantInfo = tenant;
  }
  getTenant() {
    return this.TenantInfo;
  }
  public getTenantList(data: any): Promise<any> {
    return this.http
      .post(this.gettenantlistUrl, data, { headers: new HttpHeaders() })
      .toPromise()
      .then((response) => {
        return (response = response);
      })
      .catch(this.handleError);
  }
  resendtoken(param) {
    const url = webApi.baseUrl + webApi.apiUrl.resendToken;
    return new Promise((resolve) => {
      this.http
        .post(url, param)
        // .map(res => res.json())
        .subscribe(
          (data) => {
            resolve(data);
          },
          (err) => {
            resolve(err);
            if (err) {
              // this.toastr.error('Please check server connection','Server Error!');
              // this.toastr.warning('Something went wrong please try again later', 'Warning!');
            }
          }
        );
    });
  }

  userLogout(param) {
    const url = webApi.baseUrl + webApi.apiUrl.logout;
    return new Promise((resolve) => {
      this.http
        .post(url, param)
        // .map(res => res.json())
        .subscribe(
          (data) => {
            resolve(data);
          },
          (err) => {
            resolve(err);
            if (err) {
              // this.toastr.error('Please check server connection','Server Error!');
              // this.toastr.warning('Something went wrong please try again later', 'Warning!');
            }
          }
        );
    });
  }

  signUp(param) {
    const url = webApi.baseUrl + webApi.apiUrl.signUp;
    return new Promise((resolve) => {
      this.http
        .post(url, param)
        // .map(res => res.json())
        .subscribe(
          (data) => {
            resolve(data);
          },
          (err) => {
            resolve(err);
          }
        );
    });
  }


  setDomainBasedTenant(tenant) {
    this.domainBasedTenant = tenant;
  }
  getDomainBasedTenant() {
    return this.domainBasedTenant;
  }

  logout(cb) {
    const logoutData = this.make_logout_data_ready();
    // if (logoutData) {
    //   const params = {
    //     id: logoutData.userId,
    //   };
    //   this.analyticsService.trackEventsWithCustomParams(
    //     EAppCustomEvents.LOGGED_OUT,
    //     params
    //   );
    // }
    // if (this.featureConfig.webinar.BBB) {
    //   if (this.webinarService.parentTab) {
    //     this.webinarService.parentTab.closeAllTabs();
    //   }
    //   this.webinarService.destroySocket();
    //   this.messagingService.showToast(null, false);
    // }
    if (!this.logoutRunning) {
      this.logoutRunning = true;
      // this.checkForReLogin();
      this.userLogout(logoutData)
        .then((res) => {
          this.logoutRunning = false;
          this.checkForReLogin();
          cb(true);
        })
        .catch((error) => {
          console.log("error", error);
          this.logoutRunning = false;
          cb(false);
        });
    } else {
      // cb(null);
      console.log('Logout is already in progress...')
    }
  }

  checkForReLogin() {
    let tenant = localStorage.getItem("tenantinfo");
    const requestedRoute = this.isSigmaUrl();
    const redirectUrl = this.getDataFromLocalStorage('redirectUrl');
    this.clear_user_data();
    this.events.publish('check-inactivity:stop', true);

    this.yellowMessangerService.showHideChatBot();

    // this.setRedirectionLink(redirectUrl);
    // tenant &&
    if (requestedRoute) {
      this.router.navigate([requestedRoute]);
    } else {
      // this.router.navigate(["/auth/login"]);
      this.redirectToLogin();
    }
  }

  clear_user_data() {
    this.languageService.removeLangugaeCookie();

    if (localStorage.getItem("remember_me_details") != null) {
      const remember_me_details = JSON.parse(
        localStorage.getItem("remember_me_details")
      );
      if (remember_me_details.remember_me === true) {
        localStorage.removeItem("auth_token");
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("userOtherDetails");
        localStorage.removeItem("currentUser");
        localStorage.removeItem("recommendationDomain");
        localStorage.removeItem("searchDomain");
        localStorage.removeItem("tenantinfo");
        localStorage.removeItem("userTabsByRole");
        localStorage.removeItem("separators");
        localStorage.removeItem("displaySettings");
        localStorage.removeItem("learningCategories");
        localStorage.removeItem("separators");
      }
    } else {
      localStorage.clear();
    }
    window.location.reload();
  }
  make_logout_data_ready() {
    let userdetail = this.getCurrentUserData();
    // console.log("userDetails ==>", userdetail);
    // localStorage.clear();
    let logoutData = null;
    if (userdetail) {
      // userdetail = JSON.parse(userdetail);
      const deviceDetails = this.userService.populateUserInfoData(userdetail);
      logoutData = {
        type: 3,
        userId: userdetail["id"],
        actDate: null,
        tenantId: userdetail["tenantId"],
        model: deviceDetails.model,
        uuid: deviceDetails.uuid,
      };
    }

    return logoutData;
  }

  // track_analytics(userData) {
  //   this.analyticsService.setUserId(userData.username);
  //   this.analyticsService.setUserProperty({
  //     [EAppCustomUserProperties.USERNAME]: userData.username,
  //   });
  //   this.analyticsService.setUserProperty({
  //     [EAppCustomUserProperties.SOURCE]: userData.login_source_name,
  //   });
  //   this.analyticsService.setUserProperty({
  //     [EAppCustomUserProperties.TENANT]: userData.tenantName,
  //   });
  //   this.analyticsService.setUserProperty({
  //     [EAppCustomUserProperties.ROLE]: userData.roll,
  //   });
  //   const params = {
  //     id: userData.id,
  //   };
  //   // const params1 = {
  //   //     // 'userid' : userData.id,
  //   //     'username' : userData.username
  //   // };
  //   this.analyticsService.trackEventsWithCustomParams(
  //     EAppCustomEvents.LOGGED_IN,
  //     params
  //   );
  //   this.analyticsService.trackScreenView(EAppPages.LOGIN);
  // }

  // setUserDataForLoginAndRedirect(response) {
  //   const themes = response.theme;
  //   localStorage.setItem("theme", JSON.stringify(themes));
  //   // this.themeService.changeTheme();
  //   const stringUserDetail = JSON.stringify(response.data);
  //   const stringUserOtherDetail = JSON.stringify(response.otherdata);
  //   const userdetail = response.data;
  //   localStorage.setItem("auth_token", response.token);
  //   // Save User Info and Track Events
  //   userdetail.userId = userdetail.id;
  //   userdetail.eId = response.employeeId;
  //   var otherdata = response.otherdata[3];
  //   console.log(otherdata);
  //   for (let i = 0; i < otherdata.length; i++) {
  //     // if (otherdata[i].fieldmasterId) {
  //     //   userdetail.referenceId = otherdata[i].fieldmasterId;
  //     //   userdetail.roll = otherdata[i].roleName;
  //     //   userdetail.roleId = otherdata[i].roleId;
  //     // } else {
  //     //   userdetail.roll = 'Learner';
  //     //   userdetail.roleId = 8;
  //     // }
  //     if (otherdata[i].isDefault == 1) {
  //       userdetail.referenceId = otherdata[i].fieldmasterId;
  //       userdetail.roll = otherdata[i].roleName;
  //       userdetail.roleId = otherdata[i].roleId;
  //       localStorage.setItem("roleName", otherdata[i].roleName);
  //     }
  //   }
  //   // this.userservice.setUser(userdetail);
  //   localStorage.setItem("currentUser", JSON.stringify(userdetail));
  //   // this.track_analytics(userdetail);
  //   if (userdetail && userdetail["userLang"]) {
  //     // this.multiLanguageService.updateLangugae(userdetail["userLang"], false);
  //   }
  //   if (stringUserOtherDetail) {
  //     let UOD = JSON.parse(stringUserOtherDetail);
  //     console.log("UOD", UOD);
  //     // var temp = UOD[3];
  //     let temp = UOD[3];
  //     for (let i = 0; i < temp.length; i++) {
  //       if (otherdata[i].isDefault === 1) {
  //         userdetail.referenceId = otherdata[i].fieldmasterId;
  //         userdetail.roll = otherdata[i].roleName;
  //         userdetail.roleId = otherdata[i].roleId;
  //       }
  //     }
  //   }
  //   localStorage.setItem("userOtherDetails", stringUserOtherDetail);
  //   // One Signal
  //   // this.onesingalService.saveUserInfo();

  //   // Firebase
  //   // this.messagingService.saveUserInfo();

  //   // Old Code
  //   const redirectURL = localStorage.getItem("redirectURL") || "";
  //   if (redirectURL !== "/login-new" && redirectURL !== "") {
  //     this.router.navigate([redirectURL], { relativeTo: this.routes });
  //   } else {
  //     // this.router.navigate(["../welcome"], { relativeTo: this.routes });
  //     // this.router.navigate(["../pages/dashboard"]);
  //     this.router.navigate(["../pages/home"], {
  //       relativeTo: this.routes,
  //     });
  //   }
  // }


  goToPreference() {
    this.router.navigate(["../pages/preferences"], {
      relativeTo: this.routes,
    });
  }

  goToDashboard() {
    const userData = JSON.parse(localStorage.getItem('currentUser'));
    if (userData) {
      const roleList = JSON.parse(localStorage.getItem('userTabsByRole'));
      // const isDynamicLinkParamsAvailable = JSON.parse(localStorage.getItem('dynamic-link-params'));
      // console.log('dynamic-link-learn params from login : ', isDynamicLinkParamsAvailable);
      const userData = this.getCurrentUserData();
      localStorage.setItem('show_intro', 'false');
      if (userData && String(userData.lp_flag) === '1' && userData.lp_last_date == null) {
        localStorage.setItem('show_intro', 'true');
        this.goToPreference();
      }
      // else if (isDynamicLinkParamsAvailable) {
      //   this.router.navigate(['dynamic-link-learn']);
      // }
      //  else if (roleList && roleList.length) {
      //   for (let i = 0; i < roleList.length; i++) {
      //     if (roleList[i].status) {
      //       if (roleList[i].roleId === 8) {
      //         this.router.navigate(["../pages/features/home"], {
      //           relativeTo: this.routes,
      //         });
      //       } else {
      //         const message = 'Unable to access system ' + roleList[i].name;
      //         this.presentAlert(message, 'Warning', 'warning');
      //         this.logout();
      //       }
      //     }
      //   }
      // } else {
      //   this.router.navigate(["auth/login"]);
      // }
      else if (roleList && roleList.length) {
        const isValid = {
          msg: '',
          status: true
        };
        for (let i = 0; i < roleList.length; i++) {
          if (roleList[i].status) {
            if (roleList[i].roleId === 8) {
              isValid.status = true;
              isValid.msg = '';
              // this.router.navigate(["../pages/features/home"], {
              //   relativeTo: this.routes,
              // });
              this.redirectAccordingToRole(roleList[i]);
              break;
            }
            else if (roleList[i].roleId === 7) {
              // this.navctrl.navigateRoot('/features/train');
              // this.router.navigate(["../pages/coming-soon"], {
              //   relativeTo: this.routes,
              // });

              isValid.status = true;
              isValid.msg = '';
              this.redirectAccordingToRole(roleList[i]);
              break;
            } else if (roleList[i].roleId === 5) {
              // this.navctrl.navigateRoot('/features/employeeList');
              // this.router.navigate(["../pages/coming-soon"], {
              //   relativeTo: this.routes,
              // });
              isValid.status = true;
              isValid.msg = '';
              this.redirectAccordingToRole(roleList[i]);
              break;
            } else if (roleList[i].roleId === 10) {
              // this.navctrl.navigateRoot('/features/learn');
              // this.router.navigate(["../pages/coming-soon"], {
              //   relativeTo: this.routes,
              // });
              isValid.status = true;
              isValid.msg = '';
              this.redirectAccordingToRole(roleList[i]);
              break;
            }
            else {
              isValid.status = false;
              isValid.msg = 'Unable to access system with ' + roleList[i].roleName + ' role.';
              break;
            }
          } else {
            isValid.status = false;
            isValid.msg = 'Your default role is not set';
            // break;
          }
        }
        if (!isValid.status) {
          // this.showToast(isValid.msg);
          this.presentAlert(isValid.msg, 'Warning', 'warning');
          // this.logout();
        }
      } else {
        console.log('User roles not present');
        // this.showToast('User roles not present');
        this.presentAlert('User roles not present', 'Warning', 'warning');
        // this.logout();
      }
      // this.events.publish('user:getPushNotificationsCount', true);
    } else {
      this.router.navigate(["auth/login"]);
      console.log('User not logged in');
    }
  }

  // presentAlert(msg) {
  //   this.popupConfig.message = msg;
  //   this.alertService
  //     .showAlert(this.popupConfig)
  //     .onClose.subscribe((data) => {
  //       console.log("Modal closed ", data);
  //       if (data.id == 'confirm') {
  //         this.goToPreference();
  //       } else {
  //         this.savePreferenceFormData();
  //       }
  //     });
  // }

  savePreferenceFormData() {
    this.goToDashboard();
    // if (this.networkservice.getOnlineStatus()) {
    const currentUserData = this.getCurrentUserData();
    const params = {
      empId: currentUserData.eid,
      dataString: '',
      action: 'n'
    };
    // this.load.presentLoading('Please wait...');
    this.preferenceService.savePreferenceForm(params).then(
      (res) => {
        // this.load.dismiss();
        if (res.type === true) {
          console.log('savePreferenceFormData res', res);
          // const submitRes = res.data;
          // this.presentAlert(submitRes[0].msg, 1);
        } else {
          // this.presentAlert('Unable to submit your preferences at this time, please try again', 0);
        }
      },
      (err) => {
        // this.load.dismiss();
        console.log(err);
        // this.presentAlert('Unable to submit your preferences at this time, please try again', 0);
      }
    );
    // } else {
    // this.presentToastMessage('Unable connect to the server at this time, Please check your internet connection and try again');
    // }
  }

  isAuthenticated() {
    return this.authState.value;
  }
  isObject(objValue) {
    return (
      objValue &&
      typeof objValue === "object" &&
      objValue.constructor === Object
    );
  }

  getCurrentUserData() {
    return JSON.parse(localStorage.getItem('currentUser'));
  }

  refreshAuthToken(loginData) {
    return this.http
      .post(this.refresh_auth_token_url, loginData)
      .toPromise()
      .then((response) => {
        return (response = response);
      })
      .catch(this.handleError);
  }

  decryptEncodedData(encryptedData) {
    let bytes = CryptoJS.AES.decrypt(encryptedData, 'worldisfullofdevelopers');
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
  }

  configureUserInfo(currentUserDetails, res) {
    const userData = res.data;
    userData.eid = res.employeeId;
    const otherdata = res.otherdata[3];

    const separators = res.separators;
    const displaySettings = res['display_settings'];
    console.log('otherdata', otherdata);

    localStorage.setItem('userTabsByRole', JSON.stringify(res.otherdata[3]));
    localStorage.setItem('theme', JSON.stringify(res.theme));
    localStorage.setItem('searchDomain', JSON.stringify(res.searchDomain));
    localStorage.setItem('recommendationDomain', JSON.stringify(res.recommendationDomain));
    if (separators) {
      localStorage.setItem(
        'separators',
        JSON.stringify(separators)
      );
    }
    if (displaySettings) {
      let preparedDisplaySettings = this.makeDisplaySettingsDataReady(displaySettings);
      if (preparedDisplaySettings) {
        localStorage.setItem(
          'displaySettings',
          JSON.stringify(preparedDisplaySettings)
        );
      }
    }

    if (currentUserDetails) {
      this.updateUserDetails(currentUserDetails, userData, otherdata);
      if (currentUserDetails.checkInactivity == 0) {
        this.events.publish('check-inactivity:stop', true);
      }
      // else if (currentUserDetails.checkInactivity == 1) {
      //   this.events.publish('check-inactivity:update', currentUserDetails);
      // }
    } else {
      this.saveUserDetails(res, userData, otherdata);
      setTimeout(() => {
        this.userService.registerUserInfo(true);
      }, 1000)

      // init chatbot
      this.yellowMessangerService.initAndShowChatBot();
    }

    localStorage.setItem('userOtherDetails', JSON.stringify(res.otherdata));
    // this.userService.setUserRole(res.otherdata[3]);

    // setting theme
    this.themeService.changeTheme();

    // Multi-lingual logs
    this.languageService.checkForLanguageChange();
  }

  updateUserDetails(currentUserDetails, userData, otherdata) {
    for (let i = 0; i < otherdata.length; i++) {
      if (currentUserDetails.roleId == otherdata[i].id) {
        otherdata[i].status = true;
      } else {
        otherdata[i].status = false;
      }
    }
    // this.events.publish('TABS:update', true);
    // this.userService.setUser(currentUserDetails);

    // this.storage.remove('currentUser');
    // this.storage.set('currentUser', currentUserDetails);
    localStorage.setItem('currentUser', JSON.stringify(currentUserDetails));

    this.events.publish('user:updateCurrentUserDetails', currentUserDetails);
  }

  saveUserDetails(res, userData, otherdata) {
    for (let i = 0; i < otherdata.length; i++) {
      if (otherdata[i].status) {
        userData.referenceId = otherdata[i].fieldmasterId ? otherdata[i].fieldmasterId : null;
        userData.roll = otherdata[i].roleName;
        userData.roleId = otherdata[i].roleId;
        userData.isBotEnabled = otherdata[i].isBotEnabled;
        break;
      }
    }
    console.log('userData', userData);

    userData.token = res.token;
    localStorage.setItem('auth_token', res.token);
    localStorage.setItem('refresh_token', res.token);
    localStorage.setItem('currentUser', JSON.stringify(userData));
    // if (userData) {​​​​​​​​
    //   this.socketService.connectSocketServer(userData.id);
    // }​​​​​​​​

    // this.userService.setUser(userData);
    // this.events.publish('user:callGetPushNotificationsCount', true);
    // this.saveLogs();

    // if (String(userData.coc_flag).toLowerCase() === 'y') {
    // this.goToCOC();
    // console.log('COC Flag condition');
    // } else {
    // /********* events start ******************/
    // if (userData.roleId !== 10) {​​​​​​​​
    //   this.events.publish('user:login', userData);
    // }​​​​​​​​
    // this.events.publish('TABS', userData);
    // /**************** events end ***************/
    // this.events.publish('user:callGetPushNotificationsCount', true);
    this.goToDashboard();
    // }
    this.authState.next(true);
  }

  public get_updated_user_details(): Promise<any> {
    return this.http.post(this.get_updated_user_details_url, '')
      .toPromise()
      .then(response => {
        return response = response;
      })
      .catch(this.handleError);
  }

  private handleError(error: Response | any) {
    return Promise.reject(error.message || error);
  }

  async presentAlert(msg, title, type) {
    setTimeout(() => {
      this.popupConfig.message = msg;
      this.alertService.showAlert(this.popupConfig).onClose.subscribe(() => {
        this.logout(res => {
          console.log('logout : ', res);
        });
        this.events.publish('check-inactivity:stop', null);
      });
    }, 800);
  }

  makeDisplaySettingsDataReady(displaySettings) {
    let object = null;
    if (Array.isArray(displaySettings) && displaySettings.length != 0) {
      object = {};
      displaySettings.forEach(
        element => {
          console.log('element', element);
          object[element.key] = element.value
        }
      )
    }
    return object;
  }

  redirectAccordingToRole(selectedRole) {
    let url = "";
    const isDynamicLinkAvailable = localStorage.hasOwnProperty('redirectUrl') ? localStorage.getItem('redirectUrl') : null;
    console.log('dynamic-link-learn : ', isDynamicLinkAvailable);
    if (isDynamicLinkAvailable) {
      // this.router.navigate(['/cip/dashboard']);
      // this.router.navigate(['/cip/tab/dashboard']);
      url = isDynamicLinkAvailable;
      localStorage.removeItem('redirectUrl');
      //  this.router.navigateByUrl(/cip/tab/dashboard);
    } else if (selectedRole.roleId === 7) {
      // this.router.navigate(['/cip/dashboard']);
      // this.router.navigate(['/cip/tab/dashboard']);
      url = '/cip/tab/dashboard';
      //  this.router.navigateByUrl(/cip/tab/dashboard);
    } else if (selectedRole.roleId === 8) {
      // this.router.navigate(['/pages/features/home']);
      url = '/pages/features/home';
      // this.router.navigateByUrl('/pages/features/home');
    } else if (selectedRole.roleId === 5) {
      // this.router.navigate(['/pages/features/home']);
      // this.router.navigate(['/pages/coming-soon']);
      url = 'manager/features/dashboard';
      // this.router.navigateByUrl('/pages/coming-soon');
    } else if (selectedRole.roleId === 10) {
      // this.router.navigate(['/pages/features/home']);
      // this.router.navigate(['/pages/coming-soon']);
      // this.router.navigateByUrl('/pages/coming-soon');
      url = '/pages/coming-soon';
    }
    console.log('routes url', this.router.url);
    if (this.router.url != url) {

      const expectedRole = this.getRoleBasedOnRoute(url);
      if (expectedRole && (expectedRole != selectedRole.roleId)) {
        const currentUserData = this.userService.getUserDetails();
        this.switchRole(currentUserData, Number(expectedRole));
      }

      this.router.navigateByUrl(url);

    }
    // else{

    // }

  }

  checkIfRouteIsValid(route, role) {
    const n = route.search(role);
    if (n != -1) {
      return true;
    } else {
      return false;
    }
  }

  getRoleBasedOnRoute(currentRoute) {
    if (this.checkIfRouteIsValid(currentRoute, 'manager')) {
      return 5;
    } else if (this.checkIfRouteIsValid(currentRoute, 'pages')) {
      return 8;
    } else if (this.checkIfRouteIsValid(currentRoute, 'cip')) {
      return 7;
    } else {
      return null;
    }
  }

  selectUserRole(selectedRole) {
    const roleList = JSON.parse(localStorage.getItem('userTabsByRole'));
    for (let i = 0; i < roleList.length; i++) {
      if (roleList[i].roleId === selectedRole.roleId) {
        roleList[i].status = true;
        // this.profileservice.selectedRoleIndex = i;
        // this.tabsService.tabsData = this.roleList[i].tabs;
      } else {
        roleList[i].status = false;
      }
    }
    localStorage.setItem("userTabsByRole", JSON.stringify(roleList));
    // return roleList;
  }

  changeUserRole(selectedRole) {
    this.selectUserRole(selectedRole);
    const currentUser = JSON.parse(localStorage.getItem("currentUser"))

    currentUser.roll = selectedRole.roleName;
    currentUser.roleId = selectedRole.roleId;
    currentUser.referenceId = selectedRole.fieldmasterId
      ? selectedRole.fieldmasterId
      : null;
    currentUser.isBotEnabled = selectedRole.isBotEnabled;

    localStorage.setItem("currentUser", JSON.stringify(currentUser));
    // init chatbot
    // this.yellowMessangerService.initAndShowChatBot();
    // show/hide chatbot
    // this.yellowMessangerService.showHideChatBot();

    setTimeout(() => {
      this.events.publish('user:callGetPushNotificationsCount', true);
      this.events.publish('user:updateCurrentUserDetails', true);
    });

  }

  switchRole(currentUser, roleId) {
    const roleList = JSON.parse(localStorage.getItem('userTabsByRole'));
    let selectedRole = null;
    if (roleList && roleList.length) {
      for (let i = 0; i < roleList.length; i++) {
        if (roleList[i].roleId === roleId) {
          roleList[i].status = true;
          selectedRole = roleList[i];
        } else {
          roleList[i].status = false;
        }
      }
      if (selectedRole) {
        localStorage.setItem('userTabsByRole', JSON.stringify(roleList));

        currentUser.roll = selectedRole.roleName;
        currentUser.roleId = selectedRole.roleId;
        currentUser.referenceId = selectedRole.fieldmasterId ? selectedRole.fieldmasterId : null;
        currentUser.isBotEnabled = selectedRole.isBotEnabled;

        localStorage.setItem('currentUser', JSON.stringify(currentUser));
        // // init chatbot
        // this.yellowMessangerService.showHideChatBot();

        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  public update_user_login_details(loginData: any): Promise<any> {
    return this.http.post(this.update_user_login_details_url, loginData)
      .toPromise()
      .then(response => {
        return response = response;
      })
      .catch(this.handleError);
  }



  makeRefreshTokenDataReady(currentUserData) {
    const deviceDetails = this.userService.populateUserInfoData(currentUserData);
    const userInfo = {
      username: currentUserData.username,
      email: currentUserData.email,
      tenantId: currentUserData.tenantId,
      sso: currentUserData.sso_login,
      usrId: currentUserData.id,
      // refreshToken: localStorage.getItem('refresh_token'),
      model: deviceDetails.model,
      uuid: deviceDetails.uuid,
    };
    return userInfo;
  }

  getAccessTokenUsingRefreshToken(): Observable<any> {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const refreshTokenTemp = localStorage.getItem('refresh_token');
    const userinfo = this.makeRefreshTokenDataReady(currentUser);
    const encryptData=this.cryptoservice.encryptData(userinfo,null);
    const param={
      data:encryptData
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${refreshTokenTemp}`
        // 'Authorization': `Bearer ${currentUser.refreshToken}`
      })
    };
    return this.http.post<any>(this.refresh_auth_token_url, param, httpOptions).pipe(
      map(response => {
        if (!response.type) {
          return null;
        } else {
          return response.token;
        }
      }),
      tap(accessToken => {
        localStorage.setItem('auth_token', accessToken);
      }),
      catchError((error, caught) => {
        if (error.status === 400) {
          if (error.error && error.error.error === 'invalid_grant') {
            // If we get a 400 and the error message is 'invalid_grant', the token is no longer valid so logout.
            this.presentAlert('Token expired', 'Please sign in again!', 'warning');
            this.logout(res => {
              console.log('logout : ', res);
            });
            this.events.publish('check-inactivity:stop', null);
          }
        }
        return empty();
      })
    );
  }

  isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  isBase64(str) {
    if (str === '' || str.trim() === '') {
      return false;
    }
    try {
      // return btoa(atob(str)) == str;
      return atob(str);
    } catch (e) {
      // something failed
      return false;
      // if you want to be specific and only catch the error which means
      // the base 64 was invalid, then check for 'e.code === 5'.
      // (because 'DOMException.INVALID_CHARACTER_ERR === 5')
    }
  }

  isValidJSON(str) {
    // if (str === '' || str.trim() === '') {
    //   return false;
    // }
    try {
      return JSON.parse(str);
    } catch (e) {
      return false;
    }
  }

  checkIfLogout() {
    const bySSO = localStorage.getItem('sso_inprogress');
    const accessMode = this.getAccessMode();
    // if (this.currentUser.sso_login == 1) {
    if (bySSO && Number(accessMode) == 3) {
      return false;
    } else {
      return true;
    }
  }

  isPureObject(input) {
    return null !== input &&
      typeof input === 'object' &&
      Object.getPrototypeOf(input).isPrototypeOf(Object);
  }

  setAccessMode(isSigma) {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    let accessMode = '0';
    if (currentUser) {
      if (currentUser.sso_login == 1) {
        if (isSigma) {
          accessMode = '3';
        } else {
          accessMode = '2';
        }
      } else {
        accessMode = '1';
      }
    }
    localStorage.setItem('access_mode', accessMode);
  }

  getAccessMode() {
    return localStorage.getItem('access_mode');
  }

  getTenantId(tenantUrl) {
    try {
      const tenantId = tenantUrl.split('/');
      if (tenantId) {
        return tenantId[0];
      } else {
        return null;
      }
    } catch (e) {
      console.log('getTenantId error : ', e);
      return null;
    }
  }

  getTenantInfo(tenantUrl) {
    try {
      const tenantInfo = tenantUrl.split('/');
      if (tenantInfo) {
        return tenantInfo[1];
      } else {
        return null;
      }
    } catch (e) {
      console.log('getTenantInfo error : ', e);
      return null;
    }
  }

  isValidTenantUrl(requestedRoute) {
    try {
      if (requestedRoute.includes('/auth/login/')) {
        const tenantUrl = requestedRoute.split('login/');
        if (Array.isArray(tenantUrl) && tenantUrl.length > 1) {
          // if (this.getTenantId(tenantUrl[1]) && this.getTenantInfo(tenantUrl[1])) {
          //   return true;
          // } else {
          //   return false;
          // }
          const tenantDetails = tenantUrl[1].split('/');
          if (Array.isArray(tenantDetails) && tenantDetails.length == 2) {
            return tenantDetails;
          } else {
            return false;
          }
        } else {
          console.log('URL is not valid...', requestedRoute);
          return false;
        }
      } else {
        return false;
      }
    } catch (e) {
      console.log('isValidTenantUrl error occured...', e);
      return false;
    }
  }

  isSigmaUrl() {
    try {
      const currentUrl = (window.location.href).split('/#');
      const requestedRoute = currentUrl.length > 1 ? currentUrl[1] : currentUrl[0];  // this.router.url;
      // if (requestedRoute.includes('/auth/login/')) {
      //   return requestedRoute;
      // } else {
      //   return null;
      // }
      if (!this.isValidTenantUrl(requestedRoute)) {
        return null;
      } else {
        return requestedRoute;
      }
    } catch (e) {
      console.log('isSigmaUrl error occured...', e);
      return null;
    }
  }

  isSigmaUser() {
    const accessMode = this.getAccessMode();
    const requestedRoute = this.isSigmaUrl();
    if (requestedRoute) {
      if (accessMode && Number(accessMode) == 3) {
        console.log('sigma User : ', requestedRoute, accessMode);
        return requestedRoute;
      } else {
        console.log('not sigma User : ', requestedRoute, accessMode);
        return null;
      }
    } else {
      console.log('Invalid values : ', requestedRoute, accessMode);
      return null;
    }
  }

  redirectToMiddleWare(): Observable<any> {
    const tenantData = this.getDomainBasedTenant();
    console.log('handle410Error..', tenantData);
    if (tenantData) {
      window.location.href = tenantData.logOutUrl ? tenantData.logOutUrl : 'https://www.edgelearning.co.in';
    }
    return of(null);
  }

  public directSassLogin(data: any): Promise<any> {
    return this.http.post(this.sassVerifyUserUrl, data, { headers: new HttpHeaders() })
      .toPromise()
      .then(response => {
        return response = response;
      })
      .catch(this.handleError);
  }

  private handleErrorStatusCode(error: Response | any) {
    return Promise.reject(error);
  }

  redirectToLogin() {
    const tenantData = this.getDomainBasedTenant();
    if (tenantData && tenantData.logOutUrl != '') {
      this.redirectToMiddleWare();
    } else {
      // this.router.navigate(['/auth/login']);
      this.router.navigate(["/auth/login"]);
    }
  }

  /************** auto login url params validation start **************/
  checkParamsForIllume(tenant, meta) {
    // const decMeta = this.ASP.isValidJSON(atob(meta));
    const decMeta = this.isValidJSON(meta);
    // if (decMeta) {
    return this.checkUrlIsValid(tenant, decMeta);
    // } else {
    //   return null;
    // }
  }

  checkParamsForSass(tenant, meta) {
    // const decAesMeta = this.ASP.decryptEncodedData(atob(meta));
    const decAesMeta = this.decryptEncodedData(meta);
    return this.checkUrlIsValid(tenant, decAesMeta);
  }

  checkUrlIsValid(tenant, meta) {
    if (meta) {
      if (tenant == meta.tenantId) {
        // console.log('Url is valid');
        return meta;
      } else {
        // console.log('Url is not valid');
        return false;
      }
    } else {
      return false;
    }
  }

  validatingParams(params) {
    const tenant = params.tenant;
    const meta = params.meta;
    console.log('Decrypted Login query params are : ', tenant, meta);
    if (tenant && meta) {
      const isValidMeta = this.isBase64(meta);
      console.log('is valid : ', isValidMeta);
      if (isValidMeta) {
        try {
          const decMeta = this.checkParamsForIllume(tenant, isValidMeta);
          if (decMeta) {
            return { status: true, type: 1 };
          } else {
            // if (decMeta == null) {
            //   return { status: false, type: 1 };
            // } else {
            const decAesMeta = this.checkParamsForSass(tenant, isValidMeta);
            if (decAesMeta) {
              return { status: true, type: 2 };
            } else {
              return { status: false, type: 2 };
            }
            // }
          }
        } catch (error) {
          console.error(error);
          return { status: false, type: 1 };
          // expected output: ReferenceError: nonExistentFunction is not defined
          // Note - error messages will vary depending on browser
        }

      } else {
        return { status: false, type: 1 };
      }
    } else {
      // console.log('Url is not valid');
      return { status: false, type: 1 };
    }
  }
  /************** auto login url params validation end **************/

  updateUserToken(refreshToken) {
    if (refreshToken) {
      const userData = JSON.parse(localStorage.getItem('currentUser'));
      userData.token = refreshToken;
      localStorage.setItem('currentUser', JSON.stringify(userData));
    } else {
      console.log('Token not available');
    }
  }

  /************** dynamic link logic start *************/
  getAppRoute(urlTemp) {
    const urlSplitRoute = '/#';
    const res = urlTemp.split(urlSplitRoute);
    let appRoute = '/';
    if (res.length == 2) {
      appRoute = res[1];
    }
    return appRoute;
  }

  setRedirectionLink(redirectUrl) {
    if (redirectUrl) {
      this.setDataToLocalStorage('redirectUrl', redirectUrl);
    } else {
      const appRoute = this.getAppRoute(window.location.href);
      this.setDataToLocalStorage('redirectUrl', appRoute);
    }
  }
  /************** dynamic link logic end *************/

  /********* local storage actions start ********/
  getDataFromLocalStorage(property) {
    if (localStorage.hasOwnProperty(property)) {
      return localStorage.getItem(property);
    } else {
      return null;
    }
  }

  setDataToLocalStorage(property, value) {
    localStorage.setItem(property, value);
  }
  /********* local storage actions end ********/
}
