<div class="image-picker">
    <div class="wrap-content w-100" [nbSpinner]="!showCropper">
        <!-- [resizeToWidth]="500" [cropperMinWidth]="500" -->
        <!--  [containWithinAspectRatio]="true" -->
        <image-cropper [alignImage]="'center'" [resizeToWidth]="300"
        [imageChangedEvent]="config?.imageSource" 
        [onlyScaleDown]="true" 
        [roundCropper]="true" 
        format="png" 
        outputType="base64" 
        (imageCropped)="imageCropped($event)" 
        (imageLoaded)="imageLoaded()" 
        (loadImageFailed)="loadImageFailed($event)"
            [style.display]="showCropper ? null : 'none'">
        </image-cropper>
    </div>
    <div class="text-center mt-3">
        <button class="commonBtn mr-2" [disabled]="!showCropper" (click)="dissmissImageCropPopModal(1)">Upload</button>
        <button class="commonBtn mr-2" (click)="dissmissImageCropPopModal(2)">Delete</button>
        <button class="commonBtn" (click)="dissmissImageCropPopModal(3)">Cancel</button>
    </div>

</div>