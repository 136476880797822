<!-- <div [innerHTML]="currentText"> -->
<div>
    <!-- <span>{{currentText | safePipePipe:'html'}}</span> -->
    <span [innerHTML]="makeHtmlReady(currentText)"></span>
    <span class="rdclr cursor-p" [class.hidden]="hideToggle" (click)="toggleView($event)"> {{isCollapsed? ' Read more':'
        Read less'}}</span>
</div>
<!-- class="d-flex"  class="overflow-auto"-->
<!-- {{ content }}
<ng-container *ngIf="nonEditedContent?.length > limit">
    <a class="rdclr" *ngIf="!isContentToggled" (click)="toggleContent()">Read More</a>
    <a class="rdclr" *ngIf="isContentToggled" (click)="toggleContent()">Read Less</a>
</ng-container> -->