import { Injectable } from "@angular/core";
// import { Storage } from '@ionic/storage';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
  HttpClient,
  HttpHeaders,
} from "@angular/common/http";
import { BehaviorSubject, Observable, throwError } from "rxjs";
// import { BehaviorSubject } from "rxjs/BehaviorSubject";
import {
  map,
  catchError,
  switchMap,
  filter,
  take,
  finalize,
} from "rxjs/operators";
import { Router } from "@angular/router";
// import { ToastController } from '@ionic/angular';
import { LoginService } from "../../providers/login/login.service";
import { CheckInactivityService } from "../../modules/shared/providers/utils/check-inactivity/check-inactivity.service";
// import { LoadingComponent } from '../../components/loading/loading.component';
import { ToasterService } from "../../providers/toaster/toaster.service";
import { CryptoService } from "../../modules/shared/providers/utils/crypto/crypto.service";
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  currentUserData: any;

  isRefreshingToken: boolean = false;
  // tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  customeHeadersData;

  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  constructor(
    private router: Router,
    // public toastController: ToastController,
    // public storage: Storage,
    public authService: LoginService, // public load: LoadingComponent,
    public checkInactivityService: CheckInactivityService,
    private toastController: ToasterService,
    private cryptoService: CryptoService
  ) { }

  makeCustomHeaderDataReady() {
    const currentUserData = JSON.parse(localStorage.getItem("currentUser"));
    if (currentUserData) {
      const fullName =
        currentUserData.firstname + " " + currentUserData.lastname;
      const customeHeadersData = {
        userId: currentUserData.id,
        userName: currentUserData.username,
        userEmail: currentUserData.email,
        fullName: fullName,
        roleId: currentUserData.roleId,
        roleName: currentUserData.roll,
        platform: "web",
        defaultMeetingProvider: currentUserData.defaultMeetingProvider
      };
      this.customeHeadersData = this.cryptoService.encryptData(customeHeadersData, null)
    } else {
      this.customeHeadersData = null
    }
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.makeCustomHeaderDataReady();
    let token = "";
    if (request.method == "GET") {
      return next.handle(request)
    } else {

      // sending the request only for the refresh token endpoint
      if (request.url.endsWith(this.authService.refresh_auth_token_url)) {
        // token = localStorage.getItem('refresh_token');
        return next.handle(request);
      } else {
        token = localStorage.getItem("auth_token");
      }

    }

    let clone: HttpRequest<any>;

    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }

    // if (!request.headers.has("Content-Type")) {
    //   request = request.clone({
    //     setHeaders: {
    //       "content-type": "application/json",
    //     },
    //   });
    // }

    if (this.customeHeadersData) {
      request = request.clone({
        headers: request.headers.set(
          "Custom-Header",
          // JSON.stringify(this.customeHeadersData)
          this.customeHeadersData
        ),
      });
    }

    request = request.clone({
      headers: request.headers.set("Accept", "application/json"),
    });

    // return next.handle(clone);

    // return next.handle(request).pipe(
    //   map((event: HttpEvent<any>) => {
    //     if (event instanceof HttpResponse) {
    //       console.log("event--->>>", event);
    //     }
    //     return event;
    //   }),
    //   catchError((error: HttpErrorResponse) => {
    //     if (error.status === 401) {
    //       // this.presentToast('Login failed');
    //       // this.authService.logout();
    //       //   return this.handle401Error(request, next);
    //       return this.handleAccessError(request, next);
    //       // if (error.error.success === false) {
    //       //   this.presentToast('Login failed');
    //       // } else {
    //       //   this.router.navigate(['login']);
    //       // }
    //       // localStorage.clear();
    //       // this.storage.remove('currentUser');
    //     } else {
    //       return throwError(error);
    //     }
    //   })
    // );
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          console.log("event--->>>", event);
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          // this.presentToast('Login failed');
          // this.authService.logout();
          //   return this.handle401Error(request, next);
          return this.handleAccessError(request, next, error);
          // if (error.error.success === false) {
          //   this.presentToast('Login failed');
          // } else {
          //   this.router.navigate(['login']);
          // }

          // this.storage.remove('currentUser');
        } else if (error.status === 410) {
          return this.handle410Error(request, next, error);
        } else {
          return throwError(error);
        }

        // if (error instanceof HttpErrorResponse) {
        //     switch ((<HttpErrorResponse>error).status) {
        //         case 400:
        //             return this.handle400Error(error);
        //         case 401:
        //             return this.handle401Error(request, next);
        //     }
        // } else {
        //     return Observable.throw(error);
        // }
      })
    );
  }

  handle410Error(request, next, error) {
    //  && error.error && error.error.error === 'invalid_grant'
    if (error && error.status === 410) {
      // If we get a 400 and the error message is 'invalid_grant', the token is no longer valid so logout.
      // return this.authService.logout(res => {
      //   console.log('Logout done..');
      // });
      return this.authService.redirectToMiddleWare();
    }
    return throwError(error);
  }

  // handle400Error(error) {
  //   if (error && error.status === 400 && error.error && error.error.error === 'invalid_grant') {
  //       // If we get a 400 and the error message is 'invalid_grant', the token is no longer valid so logout.
  //       return this.authService.logout();
  //   }
  //   return Observable.throw(error);
  // }

  // handle401Error(req: HttpRequest<any>, next: HttpHandler) {
  //   if (!this.isRefreshingToken) {
  //     this.isRefreshingToken = true;

  //     // Reset here so that the following requests wait until the token
  //     // comes back from the refreshToken call.

  //     // this.tokenSubject.next(null);

  //     // this.storage.ready().then(() => {
  //     // this.storage.get('currentUser').then((response) => {
  //     const response: any = localStorage.getItem("currentUser");
  //     if (response) {
  //       this.currentUserData = response;
  //       const userinfo = {
  //         email: this.currentUserData.username,
  //         tenantId: this.currentUserData.tenantId,
  //         sso: 0,
  //         usrId: null,
  //         refreshToken: localStorage.getItem("refresh_token"),
  //       };
  //       return this.authService
  //         .refreshAuthToken(userinfo)
  //         .then((res) => {
  //           if (res["type"] && res["token"]) {
  //             this.authService.currentToken = res["token"];
  //             localStorage.setItem("auth_token", res["token"]);
  //             localStorage.setItem("refresh_token", res["token"]);
  //             // this.load.dismiss();

  //             // this.tokenSubject.next(res["token"]);

  //             setTimeout(() => {
  //               return next.handle(this.addToken(req, res["token"]));
  //             }, 1000);
  //           } else {
  //             return this.authService.logout(res => {
  //               console.log('logout : ', res);
  //             });
  //           }
  //           // If we don't get a new token, we are in trouble so logout.
  //           // return this.authService.logout();
  //         })
  //         .catch((error) => {
  //           // If there is an exception calling 'refreshToken', bad news so logout.
  //           return this.authService.logout(res => {
  //             console.log('logout : ', res);
  //           });
  //         })
  //         .finally(() => {
  //           this.isRefreshingToken = false;
  //         });
  //     }
  //     // });
  //     // });
  //   } else {
  //     return;
  //     // this.tokenSubject.pipe(
  //     //   switchMap((token) => {
  //     //     if (token) {
  //     //       return next.handle(this.addToken(req, token));
  //     //     } else {
  //     //       // return of(null);
  //     //     }
  //     //   })
  //     // );
  //   }
  // }

  // Adds the token to your headers if it exists
  private addToken(request: HttpRequest<any>, token: any) {
    if (token) {
      let clone: HttpRequest<any>;
      clone = request.clone({
        setHeaders: {
          // Accept: `application/json`,
          // 'Content-Type': `application/json`,
          Authorization: `Bearer ${token}`,
        },
      });
      return clone;
    }
    return request;
  }

  // private isUserActive() {
  //   // const token = localStorage.getItem('auth_token');
  //   // const tokenExpiry = this.getTokenExpiry(token);
  //   // const lastAccess = localStorage.getItem('isActive');
  //   const lastAccess = this.checkInactivityService.getLastAccess();
  //   const currentTime = (Math.floor((new Date).getTime() / 1000));
  //   // const inactiveTime = 30;
  //   if (this.currentUserData) {
  //     const inactiveTime = this.currentUserData.inactiveLogoutTime;
  //     if (currentTime - (Number(lastAccess)) <= inactiveTime) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   } else {
  //     return false;
  //   }
  // }

  isError(error) {
    if (error.error && error.error.flag == 0) {
      return true;
    } else if (error.flag == 0) {
      return true;
    } else {
      return false;
    }
  }

  private handleAccessError(request: HttpRequest<any>, next: HttpHandler, error: any) {
    console.log('handleAccessError error : ', error);
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;
      this.tokenSubject.next(null);
      this.currentUserData = JSON.parse(localStorage.getItem('currentUser'));
      const isUserActive = this.checkInactivityService.isUserActive();
      console.log('handleAccessError isUserActive : ', isUserActive);
      //  && this.currentUserData.checkConcurrent == 0
      if (isUserActive && this.isError(error)) {
        return this.getRefreshToken(request, next);
      } else {
        this.isRefreshingToken = false;
        // this.doLogout();
        this.checkInactivityService.doLogout(true);
      }
    } else {
      return this.tokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(jwt => {
          return next.handle(this.addToken(request, jwt));
        }));
    }
  }

  getRefreshToken(request, next): Observable<any> {
    return this.authService.getAccessTokenUsingRefreshToken().pipe(
      switchMap((token: any) => {
        if (token) {
          // this.isRefreshingToken = false;
          this.authService.currentToken = token;
          localStorage.setItem('refresh_token', token);
          this.authService.updateUserToken(token);
          this.tokenSubject.next(token);
          return next.handle(this.addToken(request, token));
        } else {
          // this.authService.logout();
          // this.doLogout();
          this.checkInactivityService.doLogout(true);
        }
      }),
      finalize(() => {
        this.isRefreshingToken = false;
        console.log('Finally callback');
      })
    );
  }

  // doLogout() {
  //   this.checkInactivityService.stopTimer(null);
  //   this.authService.logout();
  //     this.presentToast('Session expired!');
  // }

  async presentToast(msg) {
    // const toast = await this.toastController.create({
    //   message: msg,
    //   duration: 2000,
    //   position: 'top'
    // });
    // toast.present();
    this.toastController.prsentToast(msg, "Warning", "warning", null);
  }
}
