<table class="table tskeleton">
  <thead>
    <tr>
      <th *ngFor="let item of label"
        [ngClass]="{'pl-4': item.componentType == 'userImage' || item.componentType == 'icon', 'text-left': item.componentType == 'checkbox' || item.componentType == 'userImage' || item.componentType == 'icon'}">
        <ng-container [ngSwitch]="item.componentType">
          <ng-container *ngSwitchCase="'skeleton'">
            <ngx-table-skeleton></ngx-table-skeleton>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <ng-container *ngIf="item.componentType == 'checkbox' && !item?.dontShowCheckAll">
              <ngx-checkbox id="select_All" [isChecked]="item?.checkAll" (performAction)="selectAll($event, item)">
              </ngx-checkbox>
              <!-- <ngx-checkbox id="select_All" [isChecked]="customCheckAllFlag?.flag" *ngIf="item.componentType == 'checkbox' && customCheckAll" (performAction)="customCheckAllEmit($event)"></ngx-checkbox> -->
            </ng-container>
            <ng-container>{{item.labelname }}</ng-container>
            <ng-container *ngIf="item?.showfilter == 1">
              <div class="table__filter position-relative d-inline-block" (mouseleave)="filterDropdown(false, item)">
                <!-- align-text-bottom  -->
                <svg-icon (click)="filterDropdown(true, item)" svgClass="stroke-gray ml-2 cursor-p"
                  src="assets/icon-svg/filter.svg" [svgStyle]="{'width.px': 10, 'height.px': 10}">
                </svg-icon>
                <div *ngIf="item?.viewFilter" class="table__filter--inner position-absolute">
                  <ul class="table__filter--list list-unstyled text-left bg-white border py-2 mt-4 mb-0">
                    <li class="text-gray cursor-p p-1 px-2" *ngFor="let filterItem of item?.filtervalue;let i = index;"
                      (click)="performActionOnFilter(item,filterItem, filterItem.selected)">
                      <nb-icon icon="checkmark-outline" [hidden]="filterItem?.selected != 1" class="mr-1 mt-1">
                      </nb-icon> {{filterItem?.name}}
                    </li>
                  </ul>
                </div>
              </div>
            </ng-container>

          </ng-container>
        </ng-container>
      </th>
    </tr>
  </thead>
  <tbody [sortablejs]="tableData" [sortablejsOptions]="sortListOptions">
    <!-- <tr>
            <td *ngFor="let item of label">
                <ngx-split-column *ngIf="item.componentType == 'splitCol'" [column]="item?.children"></ngx-split-column>
            </td>
        </tr> -->
    <ng-container *ngIf="tableData?.length == 0">
      <tr [ngClass]="{'filtered': true}" height="200">
        <td colspan="100">{{config?.noDatamsg}}</td>
      </tr>
    </ng-container>
    <!-- <div [sortablejs]="tableData" [sortablejsOptions]="activityListOptions"> -->
    <ng-container *ngIf="tableData?.length !== 0">
      <ng-container
        *ngFor="let item of (pagination ? (tableData | paginate:  { itemsPerPage: paginationObject.itemsPerPage, currentPage: p , id: paginationObject.id}) : tableData) ; let index = index , let isFirst = first">
        <tr class="table__tr position-relative" [attr.subFieldIndex]="index"
          [ngClass]="{'filtered': !sortRow || item[config?.sortRowAttrDisable] == 1 }">
          <td *ngFor="let thd of label" [ngClass]="{
                      'tskeleton__thd': thd?.componentType == 'skeleton',
                      'sortable p-0': thd?.componentType == 'sortable',
                      'border-left border-right p-0': thd?.componentType == 'splitCol',
                      'text-left': thd?.componentType == 'checkbox' || thd?.componentType == 'userImage' || thd?.componentType == 'icon'|| thd?.componentType == 'iconText',
                      'pl-4': thd?.componentType == 'userImage' || thd?.componentType == 'icon'|| thd?.componentType == 'iconText'
                    }">
            <ng-container [ngSwitch]="thd?.componentType">
              <!-- config?.showArrow -- {{config?.showArrow}} -->
              <span [class.active]="!item?.isExpandable">
                <svg-icon (click)="expandEvents(item, item?.isExpandable)"
                  *ngIf="config?.showArrow && item[config.childrenArrayKey]?.length > 0"
                  svgClass="table__arrow stroke-text position-absolute cursor-p"
                  src="assets/icon-svg/chevron-right.svg"></svg-icon>
              </span>
              <!-- <img class="table__arrow position-absolute cursor-p" src="assets/icon-svg/chevron-right-outline.svg" alt="arrow"> -->
              <ng-container *ngSwitchCase="'skeleton'">
                <ngx-table-skeleton *ngIf="thd?.componentType == 'skeleton'"></ngx-table-skeleton>
              </ng-container>
              <ng-container *ngSwitchCase="'sortable'">
                <ngx-sortable [ngClass]="{'cursor-p': !(!sortRow || item[config?.sortRowAttrDisable] == 1)}">
                </ngx-sortable>
              </ng-container>
              <ng-container *ngSwitchCase="'text'">
                <ngx-text [setting]="thd?.setting | jsonFormatter" [text]="item[thd?.bindingProperty]"
                  (click)="prepareDate(item,thd?.bindingProperty)"></ngx-text>
              </ng-container>
              <ng-container *ngSwitchCase="'splitCol'">
                <ngx-split-column [isfirstRow]="isFirst" [data]="item" [column]="thd?.children| jsonFormatter">
                </ngx-split-column>
              </ng-container>
              <ng-container *ngSwitchCase="'action'">
                <ngx-actions [showCustomActionLabel]="config?.showCustomActionLabel" [data]="item"
                  [actions]="thd?.bindingProperty" (performAction)="sendEvents($event, item)" [label]="item?.label"
                  [alignment]="thd.alignment"></ngx-actions>
              </ng-container>
              <ng-container *ngSwitchCase="'checkbox'">
                <ngx-checkbox [checkLabel]="item[thd?.checkLabelName]" [isChecked]="item[thd?.bindingProperty]"
                  (performAction)="sendEvents(thd?.bindingProperty, item, $event);sendCheckData(thd?.bindingProperty, item, $event, thd)"
                  [isDisabled]="item[thd?.disableProperty]"></ngx-checkbox>
              </ng-container>
              <ng-container *ngSwitchCase="'icon'">
                <ngx-field-icon [setting]="thd?.setting | jsonFormatter" [defaultImage]="config?.defaultImage"
                  [labelData]="thd?.bindingProperty | jsonFormatter" [data]="item"
                  [ngClass]="{'cursor-p': thd?.showCursor}" (click)="prepareDate(item,thd?.goToAction)">
                </ngx-field-icon>
              </ng-container>
              <ng-container *ngSwitchCase="'userImage'">
                <ngx-user-image [setting]="thd?.setting | jsonFormatter" [defaultImage]="config?.defaultImage"
                  [labelData]="thd?.bindingProperty | jsonFormatter" [data]="item"
                  [ngClass]="{'cursor-p': thd?.showCursor}" (click)="prepareDate(item,thd?.goToAction)">
                </ngx-user-image>
              </ng-container>
              <ng-container *ngSwitchCase="'input'">
                <ngx-input [label]="thd" [data]="item"></ngx-input>
              </ng-container>
              <ng-container *ngSwitchCase="'iconText'">
                <ngx-icon-text [setting]="thd?.setting | jsonFormatter" [iconText]="item[thd?.bindingProperty]">
                </ngx-icon-text>
              </ng-container>
              <ng-container *ngSwitchCase="'dropdown'">
                <ngx-dropdown [label]="thd" [data]="item" [dropdownList]="thd?.dropdownData | jsonFormatter"
                  (performAction)="dropdownSelected($event, item);"></ngx-dropdown>
              </ng-container>
              <ng-container *ngSwitchCase="'iconTextV2'">
                <ngx-icon-text-v2 [setting]="thd?.setting | jsonFormatter"
                  [labelData]="thd?.bindingProperty | jsonFormatter" [data]="item"></ngx-icon-text-v2>
              </ng-container>
              <ng-container *ngSwitchCase="'inputWSubmit'">
                <ngx-input-w-submit [label]="thd" [data]="item"
                  (performAction)="inputSubmit($event, item);"></ngx-input-w-submit>
              </ng-container>
            </ng-container>
          </td>
        </tr>
        <ng-container *ngIf="!item?.isExpandable">
          <!-- | slice:0:5 -->
          <ng-container
            *ngFor="let inner of (item[config.childrenArrayKey] | slice:start:item[config.numberOfChildrenKey]); let last = last , let first = first">
            <tr class="table__inner" [attr.subFieldIndex]="index"
              [ngClass]="{'filtered': !sortRow || item[config?.sortRowAttrDisable] == 1, 'shadow-top': first, 'shadow-bottom': last }">
              <td *ngFor="let thd of label" class="text-center align-middle" [ngClass]="{
                            'tskeleton__thd': thd?.componentType == 'skeleton',
                            'sortable p-0': thd?.componentType == 'sortable',
                            'border-left border-right p-0': thd?.componentType == 'splitCol',
                            'text-left': thd?.componentType == 'checkbox' || thd?.componentType == 'userImage' || thd?.componentType == 'icon'|| thd?.componentType == 'iconText',
                            'pl-4': thd?.componentType == 'userImage' || thd?.componentType == 'icon'|| thd?.componentType == 'iconText'
                            }">
                <ng-container [ngSwitch]="thd?.componentType">
                  <ng-container *ngSwitchCase="'skeleton'">
                    <ngx-table-skeleton *ngIf="thd?.componentType == 'skeleton'">
                    </ngx-table-skeleton>
                  </ng-container>
                  <ng-container *ngSwitchCase="'sortable'">
                    <ngx-sortable [ngClass]="{'cursor-p': !(!sortRow || inner[config?.sortRowAttrDisable] == 1)}">
                    </ngx-sortable>
                  </ng-container>
                  <ng-container *ngSwitchCase="'text'">
                    <ngx-text [setting]="thd?.setting | jsonFormatter" [text]="inner[thd?.bindingProperty]"
                      (click)="prepareDate(inner,thd?.bindingProperty)"></ngx-text>
                  </ng-container>
                  <ng-container *ngSwitchCase="'splitCol'">
                    <ngx-split-column [isfirstRow]="isFirst" [data]="inner" [column]="thd?.children| jsonFormatter">
                    </ngx-split-column>
                  </ng-container>
                  <ng-container *ngSwitchCase="'action'">
                    <ngx-actions [showCustomActionLabel]="config?.showCustomActionLabel" [data]="inner"
                      [actions]="thd?.bindingProperty" (performAction)="sendEvents($event, inner)"
                      [alignment]="thd?.alignment" [label]="inner?.label"></ngx-actions>
                  </ng-container>
                  <ng-container *ngSwitchCase="'checkbox'">
                    <ngx-checkbox [checkLabel]="inner[thd?.checkLabelName]" [isChecked]="inner[thd?.bindingProperty]"
                      (performAction)="sendEvents(thd?.bindingProperty, inner, $event);sendCheckData(thd?.bindingProperty, inner, $event, thd)"
                      [isDisabled]="inner[thd?.disableProperty]"></ngx-checkbox>
                  </ng-container>
                  <ng-container *ngSwitchCase="'icon'">
                    <ngx-field-icon [setting]="thd?.setting | jsonFormatter" [defaultImage]="config?.defaultImage"
                      [labelData]="thd?.bindingProperty | jsonFormatter" [data]="inner"
                      [ngClass]="{'cursor-p': thd?.showCursor}" (click)="prepareDate(inner,thd?.goToAction)">
                    </ngx-field-icon>
                  </ng-container>
                  <ng-container *ngSwitchCase="'userImage'">
                    <ngx-user-image [setting]="thd?.setting | jsonFormatter" [defaultImage]="config?.defaultImage"
                      [labelData]="thd?.bindingProperty | jsonFormatter" [data]="inner"
                      [ngClass]="{'cursor-p': thd?.showCursor}" (click)="prepareDate(inner,thd?.goToAction)">
                    </ngx-user-image>
                  </ng-container>
                  <ng-container *ngSwitchCase="'input'">
                    <ngx-input [label]="thd" [data]="inner"></ngx-input>
                  </ng-container>
                  <ng-container *ngSwitchCase="'iconText'">
                    <ngx-icon-text [setting]="thd?.setting | jsonFormatter" [iconText]="inner[thd?.bindingProperty]">
                    </ngx-icon-text>
                  </ng-container>
                  <ng-container *ngSwitchCase="'dropdown'">
                    <ngx-dropdown [label]="thd" [data]="item" [dropdownList]="thd?.dropdownData | jsonFormatter"
                      (performAction)="dropdownSelected($event, item);"></ngx-dropdown>
                  </ng-container>
                  <ng-container *ngSwitchCase="'iconTextV2'">
                    <ngx-icon-text-v2 [setting]="thd?.setting | jsonFormatter"
                      [labelData]="thd?.bindingProperty | jsonFormatter" [data]="item"></ngx-icon-text-v2>
                  </ng-container>
                  <ng-container *ngSwitchCase="'inputWSubmit'">
                    <ngx-input-w-submit [label]="thd" [data]="item"></ngx-input-w-submit>
                  </ng-container>
                </ng-container>
              </td>
            </tr>
          </ng-container>
          <ng-container *ngIf="item[config.childrenArrayKey]?.length > 5">
            <tr>
              <td class="p-0" colspan="12">
                <div class="view-all text-primary text-center hover-underline cursor-p p-2"
                  (click)="viewAllRecords(item, index)">
                  <span>{{item?.viewText}}</span>
                </div>
              </td>
            </tr>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </tbody>
</table>

<ng-container *ngIf="pagination && tableData?.length!=0">
  <div class="text-center mt-3 w100">
    <pagination-controls (pageChange)="p = $event" responsive="true" [id]="paginationObject.id">
    </pagination-controls>
  </div>
</ng-container>

<!-- <ng-container>
    <div class="view-all text-primary text-center hover-underline cursor-p p-2"><span>View All</span></div>
</ng-container> -->

<ng-container *ngIf="config?.customPagination">
  <div class="view-all text-primary text-center cursor-p p-2">
    <p class="body-text m-0 noSelect"> <span class="text-primary pr-2" *ngIf="1 < config?.currentPage"
        (click)="preparePaginationData('getPreviousPage')">&lt;</span> {{config?.currentPage}} of
      {{config?.totalPages}} <span class="text-primary pl-2" *ngIf="config?.currentPage < config?.totalPages"
        (click)="preparePaginationData('getNextPage')">&gt;</span> </p>
  </div>
</ng-container>