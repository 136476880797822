<ng-container *ngIf="!noDataFound">
    <div class="course-header align-items-center" [ngClass]="{ 'cip-header' : userdetails?.roleId == 7 }">
        <!-- <div class="back-button" (click)="goBack()">
  <i class="fa fa-arrow-left back-button-arrow" aria-hidden="true"></i>
</div> -->

        <div class="course-content position-relative d-flex">
            <!-- left-icon -->
            <!-- <span class="complet-date position-absolute">Completion Date: {{ courseDetailSummary?.endDate }}</span> -->
            <!-- <svg-icon svgClass=" align-self-center cursor-p " [src]="'assets/icon-svg/chevron-left-aarow.svg'" (click)="goBack()"></svg-icon> -->
            <!-- [ngClass]="{ 'd-none' : userdetails?.roleId == 7 }" -->
            <nb-icon icon="arrow-back-outline" class="cursor-p align-self-center mr-2" (click)="goBack()"></nb-icon>
            <h2 class="m-0 align-self-center">
                {{ configModuleActivityFrame?.courseData?.courseTitle ||
                configModuleActivityFrame?.courseData?.instanceName }}
            </h2>
        </div>
        <div class="action-btns">
            <!-- <ng-container>
                <button class="cursor-p"><i class="fas fa-thumbs-up pr-1"></i>32k</button>
            </ng-container> -->
            <!-- <button (click)="openPop('share')">Share <i class="far fa-share-square"></i></button>
          <button>Bookmark <i class="far fa-bookmark"></i></button> -->
            <!-- (click)="openPop('rating')" -->
            <ng-container
                *ngIf="currentCourseData?.stepId == null && userdetails?.roleId == 8 && !currentCourseData?.playListId">
                <button>Progress {{ currentCourseData?.percComplete || currentCourseData?.instanceProgress }} %</button>
            </ng-container>
            <button class="cursor-p" (click)="fullScreen()">
                Fullscreen<i class="fas fa-expand pl-2"></i></button>
            <!-- <button (click)="open()">Share <nb-icon icon="share-outline"></nb-icon></button> -->
            <ng-container *ngIf="currentCourseData?.actionButtons">
                <button class="d-flex align-items-center"
                    *ngFor="let actButton of currentCourseData?.actionButtons?.buttons; let k = index"
                    (click)="performActions(actButton?.action,actButton?.elementId, actButton)">
                    <!-- <svg-icon svgClass="fill-white stroke-primary" [svgStyle]="{'width.rem': 1, 'height.rem': 1, 'stroke-width': 1.5}"
                    src="assets/icon-svg/Share_icon.svg"></svg-icon> -->
                    <!-- Thumbs_up_icon -->
                    <!-- Rating_icon -->
                    <!-- Send_icon -->
                    <!-- Share_icon -->
                    <svg-icon svgClass="fill-white stroke-primary"
                        [svgStyle]="{'width.rem': 1, 'height.rem': 1, 'stroke-width': 1.5}"
                        src="{{actButton?.icon}}"></svg-icon>
                    <span class="pl-1">{{actButton?.name}}</span>
                </button>
            </ng-container>


        </div>
    </div>
    <ngx-module-activity-container *ngIf="configModuleActivityFrame?.moduleActivityList"
        [config]="configModuleActivityFrame"
        (sendEventToParent)="passEventToParent($event)"></ngx-module-activity-container>
</ng-container>
<ng-container *ngIf="noDataFound">
    <div class="nodata">
        <ngx-no-data [customImage]="noDataFoundContainer?.customImage" [title]="noDataFoundContainer?.title"
            [discription]="noDataFoundContainer?.discription"
            [showImage]="noDataFoundContainer?.showImage"></ngx-no-data>
    </div>
</ng-container>