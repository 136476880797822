import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { SharedService } from '../../../modules/shared/providers/shared.service';

@Component({
  selector: 'ngx-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnInit, OnChanges {
  @Input() label;
  @Input() data = {};
  @Input() dropdownList;

  @Output() performAction = new EventEmitter<any>();

  selectedOption: any;

  constructor(
    private sharedService: SharedService
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: any) {
    try {
      if ('label' in changes) {
        console.log('DropdownComponent label : ', this.label);
      }
      if ('data' in changes) {
        console.log('DropdownComponent data : ', this.data);
      }
      if ('dropdownList' in changes) {
        console.log('DropdownComponent dropdownList : ', this.dropdownList);
      }
    } catch (error) {
      console.log('DropdownComponent changes error', error);
    }
  }

  optionSelected(event) {
    console.log('optionSelected event: ', event);
    this.selectedOption = this.sharedService.getObjFromArray(this.dropdownList, event, this.label.dropValueKey);
    // this.updateRemark();
    this.emitAction(this.selectedOption);
  }

  // updateRemark() {
  //   setTimeout(() => {
  //     this.data['remark'] = this.selectedOption.remark;
  //   })
  // }

  emitAction(item) {
    console.log('emitAction', item);
    const event = {
      action: 'dropdownSelected',
      data: item,
    }
    this.performAction.emit(event);
  }

}
