import { Component, OnDestroy, OnInit, ViewChildren, QueryList } from "@angular/core";
import {
  NbMediaBreakpointsService,
  // NbMenuService,
  NbSidebarService,
  NbThemeService,
} from "@nebular/theme";
import { EventsService } from '../../../providers/events/events.service';
import { map, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { NbPopoverDirective } from '@nebular/theme';
import { NotificationDrawerComponent } from "./notification-drawer/notification-drawer.component";
import { ProfileDrawerComponent } from "./profile-drawer/profile-drawer.component";
import { MoreDrawerComponent } from "./more-drawer/more-drawer.component";
import { NotificationsService } from '../../../providers/notifications/notifications.service';
import { BrandDetailsService } from "../../../providers/brand/brand-details-service.service";
import { Router, NavigationEnd, Event } from '@angular/router';
import { LoginService } from "../../../providers/login/login.service";
@Component({
  selector: "ngx-header",
  styleUrls: ["./header.component.scss"],
  templateUrl: "./header.component.html",
})
export class HeaderComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = true;
  @ViewChildren(NbPopoverDirective) popovers: QueryList<NbPopoverDirective>;
  // @ViewChild(NbPopoverDirective) popover: NbPopoverDirective;
  currentUser = null;
  currentBrandData: any;
  themes = [
    {
      value: "default",
      name: "Light",
    },
    {
      value: "dark",
      name: "Dark",
    },
    {
      value: "cosmic",
      name: "Cosmic",
    },
    {
      value: "corporate",
      name: "Corporate",
    },
  ];
  notificationComponent = NotificationDrawerComponent;
  profileComponent = ProfileDrawerComponent;
  moreComponent = MoreDrawerComponent;
  currentTheme = "default";
  countObject = {
    'LDNOTI': 0,
    'LDCART': 0,
    'LDNOM': 0,
  };
  componentMap = {
    'LDNOTI': this.notificationComponent,
    'MORE': this.moreComponent,
  };
  // unReadNotificationsCount: any = 0;
  // cartCount: any = 0;
  // userMenu = [{ title: "Profile" }, { title: "Log out" }];
  tabsArr = [];
  constructor(
    private sidebarService: NbSidebarService,
    private eventsService: EventsService,
    private themeService: NbThemeService,
    private breakpointService: NbMediaBreakpointsService,
    public brandService: BrandDetailsService,
    private router: Router,
    private notificationService: NotificationsService,
    private loginService: LoginService,
  ) { }

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;
    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService
      .onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$)
      )
      .subscribe(
        (isLessThanXl: boolean) => (this.userPictureOnly = isLessThanXl)
      );

    this.themeService
      .onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$)
      )
      .subscribe((themeName) => (this.currentTheme = themeName));

    this.currentBrandData = this.brandService.getCurrentBrandData();
    this.setCurrentUserData();
    this.makeTabDataReady();
    this.SubcribeToEvents();
  }

  SubcribeToEvents() {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        // Hide loading indicator
        console.log('event', event);
        // this.popover.hide();
        this.close();
      }
    });
    this.eventsService.subscribe('intro:Page', (flag) => {
      this.toggelPopup(flag);
      console.log('intro:openProfile')
    });
    this.eventsService.subscribe('user:updateCurrentUserDetails', (flag) => {
      this.setCurrentUserData();
      this.makeTabDataReady();
      console.log('user:updateCurrentUserDetails')
    });
    this.eventsService.subscribe('user:getPushNotificationsCount', (status) => {
      // if (status) {
      // this.getUnreadPushNotificationsData();
      // console.log('Unread pushnotifications count : ', this.unReadNotificationsCount, this.cartCount);

      // this.countObject.LDNOTI = this.notificationService.unReadCount;
      // // if()
      // this.countObject.LDCART = this.notificationService.cartCount;
      // // this.cartCount = 11;
      // this.countObject.LDNOM = this.notificationService.cartCount;

      this.countObject = this.notificationService.badgeCount;

      console.log('Count : ', this.countObject);

      // }
    });
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, "menu-sidebar");

    return false;
  }


  themebind() {
    const themejson = localStorage.getItem("theme");
    if (themejson)
      if (themejson) {
        return JSON.parse(themejson)["logo"];
      } else {
        return this.currentBrandData.logo;
      }
  }
  errorHandler(event) {
    console.debug(event);
    event.target.src = this.currentBrandData.logo;
  }

  goToPage(path) {
    this.router.navigate([path]);
    return false
  }

  goToDashboard() {
    // this.router.navigateByUrl('/cip/tab/dashboard')
    this.loginService.goToDashboard();
    // this.loginService.redirectAccordingToRole(this.currentUser);
  }

  close() {

    if (this.popovers) {
      this.popovers.forEach(popover => {
        popover.hide();
      });
    }
  }

  noopFlag = false;
  toggelPopup(flag) {
    this.noopFlag = flag;
    if (this.popovers) {
      this.popovers.forEach((popover, index) => {
        console.log('intro:openProfile');
        // if (index == 0) {

        // } else {
        //   popover.hide();
        // }
        if (flag) {
          if (popover && popover.content && popover.content['name'] == 'ProfileDrawerComponent') {
            popover.show();
          }
        } else {
          popover.hide();
        }
      });
    }
  }

  setCurrentUserData() {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
  }

  makeTabDataReady() {
    const roleList = JSON.parse(localStorage.getItem('userTabsByRole'));
    if (roleList && roleList.length) {
      this.tabsArr = this.getSelectedRoleMenu(roleList);
      // this.tabsArr.push(this.newTab);
      // this.changeLearnComponent();
      console.log("this.tabsArr == ", this.tabsArr);
    } else {
      this.tabsArr = [

      ];
    }

    // for (let i = 0; i < this.tabsArr.length; i++) {
    //     console.log(this.router.url);
    //     const currentCompRoute = this.router.url.substring(this.router.url.lastIndexOf('/') + 1);
    //     if (currentCompRoute === this.tabsArr[i].name) {
    //         this.currentSelectedTab = this.tabsArr[i].name;
    //     }
    // }

    // const usrData = JSON.parse(localStorage.getItem('currentUser'));
    // if (this.currentSelectedTab === 'home') {
    //     this.events.publish('user:login', usrData);
    // }
  }
  getSelectedRoleMenu(roleList) {
    // localStorage.setItem('userTabsByRole', JSON.stringify(roleList));
    for (let i = 0; i < roleList.length; i++) {
      //   if(roleList.length === 1 && roleList[i].roleId === 7){
      //     this.router.navigateByUrl['/tabs/train'];
      //   }
      //   if(roleList.length === 1 && roleList[i].roleId === 8){
      //     this.router.navigateByUrl['/tabs/home'];
      //   }
      //   if(roleList.length === 1 && roleList[i].roleId === 5){
      //     this.router.navigateByUrl['/tabs/employeeList'];
      //   }
      if (roleList[i].status === true) {
        return roleList[i].menu;
      }
    }

  }

  performAction(item) {
    console.log('performAction', item);
    if (item['link']) {
      this.goToPage(item['link'])
    } else {
      console.log('item link ', item['link']);
    }
  }


  // getPopOverComponent(key) {
  //   switch (key) {
  //     case 'LDNOTI':
  //       return this.notificationComponent
  //     // break;
  //     default: return null
  //     // break;
  //   }
  // }

  goToNominations() {
    this.router.navigate(['/learner/nominations']);
  }

  ngOnDestroy() {
    this.eventsService.destroy('intro:Page');
    this.eventsService.destroy('user:updateCurrentUserDetails');
    this.destroy$.next();
    this.destroy$.complete();
  }

}
