import { ContentCardComponent } from '../components/content-card/content-card.component';
import { MarkAttendanceLearnerComponent } from './mark-attendance-learner/mark-attendance-learner.component';
import { SearchComponent } from './search/search.component';

export const components: any[] = [
    ContentCardComponent,
    SearchComponent,
    MarkAttendanceLearnerComponent,
];

export * from './content-card/content-card.component';
export * from './search/search.component';
export * from './mark-attendance-learner/mark-attendance-learner.component';
