import { Injectable } from '@angular/core';
import { webApi } from "../../../config";
import { HttpClient } from "@angular/common/http";
@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  baseUrl: any = webApi.baseUrl;
  unReadCount: any = 0;
  cartCount: any = 0;
  readPushNotificationsUrl = this.baseUrl + webApi.apiUrl.readPushNotifications;
  getPushNotificationsListUrl = this.baseUrl + webApi.apiUrl.getPushNotifications;
  get_unread_push_notifications_count_url = this.baseUrl + webApi.apiUrl.get_unread_push_notifications_count;

  badgeCount = {
    'LDNOTI': 0,
    'LDCART': 0,
    'LDNOM': 0,
  }

  constructor(public http: HttpClient) { }

  updateBadgeCount(res) {
    if (res.data && res.data.length > 0) {
      const data = res.data[0];
      this.badgeCount.LDNOTI = data.all_unread ? this.prepareBadgeCount(data.all_unread, 9) : 0;
      this.badgeCount.LDNOM = data.nominationCount ? this.prepareBadgeCount(data.nominationCount, 9) : 0;
    }
    if (res.cart) {
      this.badgeCount.LDCART = res.cart.mycartcnt ? this.prepareBadgeCount(res.cart.mycartcnt, 9) : 0;
    }
  }

  prepareBadgeCount(count, limit) {
    let tempCount = count;
    if (count > limit) {
      tempCount = limit + '+';
    }
    return tempCount;
  }

  getBadgeCount() {
    return this.badgeCount;
  }

  public getUnreadPushNotificationsCount(notData: any): Promise<any> {
    return this.http.post(this.get_unread_push_notifications_count_url, notData)
      .toPromise()
      .then(response => {
        return response = response;
      })
      .catch(this.handleError);
  }

  public getPushNotificationsList(notData: any): Promise<any> {
    return this.http.post(this.getPushNotificationsListUrl, notData)
      .toPromise()
      .then(response => {
        return response = response;
      })
      .catch(this.handleError);
  }

  public readPushNotifications(notData: any): Promise<any> {
    return this.http.post(this.readPushNotificationsUrl, notData)
      .toPromise()
      .then(response => {
        return response = response;
      })
      .catch(this.handleError);
  }
  private handleError(error: Response | any) {
    return Promise.reject(error.message || error);
  }
}
