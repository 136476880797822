<!-- *ngIf="openPanel" -->
<div class="r-drawer h-100">
  <!-- openPanel ==  -->
  <div class="r-drawer__backdrop open__backdrop" [ngClass]="{'open-drawer': true}" (click)="outsideClick()"></div>
  <div class="r-drawer__content">
    <div class="r-drawer__content--header d-flex align-items-center">
      <span class="circle-effect cursor-p" (click)="closeClick()">
        <svg-icon src="assets/icon-svg/ttt/close.svg"></svg-icon>
      </span>
      <h6 class="text-center font-weight-normal w-100 pl-2 m-0">{{config?.sidebarTitle}}</h6>
    </div>
    <!-- config?.footer -- {{config?.footer}} -->
    <div class="r-drawer__inner" [ngClass]="customClass"
      [ngStyle]="{'height': config?.footer ? null : 'calc(100vh - 7.4rem)' }">
      <ng-content></ng-content>
    </div>
    <div class="r-drawer__footer border-top p-3" *ngIf="config?.footer">
      <button class="button {{item?.classList}}" [ngClass]="{ 'ml-2': !isFirst }"
        *ngFor="let item of config?.buttons; let isFirst = first"
        (click)="fireEvent(item.action, null, item)">{{item?.btnText}}</button>
    </div>
  </div>
</div>