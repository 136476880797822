<div class="row">
    <ng-container *ngIf="data?.length == 0">
        <h4 *ngIf="!commentsEnabled" class="col-md-12 mb-3">Reviews</h4>
    </ng-container>
    <div class="col-md-2" *ngIf="commentsEnabled">
        <h5>Comment</h5>
    </div>
    <div class="col-md-10" [ngClass]="{'col-md-12': !commentsEnabled}">

        <ng-container *ngIf="commentsEnabled">
            <!-- name="comment1" -->
            <textarea class="form-control " #text1 placeholder="minimum 1 and maximum 500 characters required"
                [(ngModel)]="textArea" maxlength="500" rows="3" (input)="changeEvent($event.target.value)"
                #comment="ngModel" name="comment" pattern="^[a-zA-Z0-9 ]+$"></textarea>
            <!-- required -->
            <div *ngIf="comment?.errors && (comment?.dirty || comment?.touched)" class="invalid-msg">
                <!-- <small *ngIf="comment.errors?.required">comment is required</small> -->
                <small *ngIf="comment.hasError('pattern')">Special charcters are not allowed</small>
            </div>
            <div class="d-flex justify-content-end mt-4">
                <button class="button-sm button btn-fill" (click)="action('add', null, '');"
                    [ngClass]="{'disable': comment.invalid || !comment}"
                    [disabled]="comment.invalid || (!comment || disableSave)">
                    Submit
                </button>
            </div>
        </ng-container>

        <hr *ngIf="commentsEnabled">

        <!-- <ng-container *ngIf = "data?.length>0"> -->
        <div class="reviews mb-4" *ngFor="let item of data; let i = index">
            <div class="reviews__inner">
                <div class="border-bottom">
                    <div class="d-flex align-items-center mb-1">
                        <img class="border" *ngIf="item?.picture_url != null" src={{item?.picture_url}}
                            onerror="this.src='assets/images/cip/user-image (0).jpg';" alt="user-image" width="50"
                            height="50">
                        <nb-user onlyPicture [name]="item?.fullname" *ngIf="item?.picture_url == null"
                            [picture]="item?.picture_url"></nb-user>
                        <span class="w-100 pl-3">
                            <h5 class="m-0">{{item.fullname}}</h5>
                            <span>{{item.Date |dateAgo}}</span>
                        </span>
                        <span class="d-flex" *ngIf="userDetails.id == item.userId && !editComment && commentsEnabled">
                            <span class="text-capitalize text-primary cursor-p pl-2"
                                *ngIf="(!edit && editIndex !== i) || (edit && editIndex !== i)"
                                (click)="action('show_edit', i, null)">Edit</span>
                            <span class="text-capitalize text-primary cursor-p pl-2"
                                *ngIf="(!edit && editIndex !== i) || (edit && editIndex !== i)"
                                (click)="action('delete', i, item)"
                                [ngClass]="{'disablespan': disableSave}">Remove</span>
                            <span class="text-capitalize text-primary cursor-p pl-2" *ngIf="edit && editIndex == i"
                                (click)="action('cancel', null, item)">Cancel</span>

                            <!-- 
                                comment_textarea1.invalid || 
                            , 'disable': comment_textarea1.invalid -->
                            <span class="text-capitalize text-primary cursor-p pl-2" *ngIf="edit && editIndex == i"
                                (click)="action('edit', i, item)" [ngClass]="{'disablespan': disableSave}">Save</span>
                        </span>
                    </div>
                    <!-- [ngClass] = {'p-2': editable}  -->
                    <!-- [ngClass] = {'border-0': !editable} -->

                    <ng-container *ngIf="commentsEnabled && (edit && editIndex == i)">
                        <textarea class="textarea w-100 p-2 my-3 "
                            [ngClass]="{'border-0 p-0': (!edit && editIndex !== i) || (edit && editIndex !== i)}"
                            type="text" rows="3" value={{item.comments}} id="areatxt{{i}}"
                            [disabled]="(!edit && editIndex !== i) || (edit && editIndex !== i)" type="text"
                            maxlength="500" placeholder="minimum 1 and maximum 500 characters required"
                            [(ngModel)]="item.comments" (input)="changeEvent($event.target.value)"
                            #comment_textarea1="ngModel" name="comment_textarea1" pattern="^[a-zA-Z0-9 ]+$">
                            <!-- {{item.comments}} -->
                        </textarea>
                        <!-- required -->

                        <div *ngIf="comment_textarea1?.errors && (comment_textarea1?.dirty || comment_textarea1?.touched)"
                            class="invalid-msg">
                            <!-- <small *ngIf="comment.errors?.required">comment is required</small> -->
                            <small *ngIf="comment_textarea1.hasError('pattern')">Special charcters are not
                                allowed</small>
                        </div>
                    </ng-container>

                    <div *ngIf="commentsEnabled && ((!edit && editIndex !== i) || (edit && editIndex !== i))"
                        class="my-3 read-break">
                        <app-read-more [maxLength]="150" [text]="item.comments" [type]="1"></app-read-more>
                    </div>

                    <div *ngIf="!commentsEnabled" class="my-3 break">
                        <app-read-more [maxLength]="100" [text]="item.comments" [type]="1"></app-read-more>
                        <!-- <span>{{item.comments}}</span> -->
                    </div>
                </div>
            </div>
        </div>
        <!-- </ng-container> -->
        <div class="text-center">
            <button class="button-sm button btn-outline bg-light" (click)="loadMore()"
                *ngIf="currentPageNo < maxPageNo">View more</button>
        </div>
    </div>
</div>


<!-- revivew start -->
<!-- <div class="reviews row mb-4">
    <div class="col-md-2">
        <h5>Reviews</h5>
    </div>
    <div class="reviews__inner col-md-10">
        <div class="rating border-bottom  pb-4 mb-4">
            <div class="d-flex align-items-center">
                <div class="rating__head text-center">
                    <h1 class="mb-1">4.1</h1>
                    <star-rating [starType]="'svg'" [showHalfStars]="true" [numOfStars]="4" staticColor="warning" rating="3" [readOnly]="true"></star-rating>
                </div>
                <div class="ating__data w-100">
                    <div class="d-flex flex-grow-1 align-items-center" *ngFor="let rate of [1,2,3,4]">
                        <div class="progress border w-100 mx-1">
                            <div class="progress-bar" role="progressbar" style.width="20%" aria-valuenow="" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <star-rating class="mx-1" [starType]="'svg'" [showHalfStars]="true" [numOfStars]="4" staticColor="warning" rating="3" [readOnly]="true"></star-rating>
                        <span class="rating-progress">10%</span>
                    </div>
                </div>
            </div>
        </div>

        <ng-container *ngFor="let review of [1,2]">
            <div class="border-bottom mb-3">
                <div class="d-flex align-items-center mb-1">
                    <img class="border" src="assets/images/cip/user-image (0).jpg" alt="user-image" width="50" height="50">
                    <span class="pl-3">
                        <h5 class="m-0">Michal Clark</h5>
                        <p class="m-0">3 weeks ago</p>
                    </span>
                </div>
                <p class="pt-2">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies
                    nec, pellentesque eu, pretium quis, sem.</p>
            </div>
        </ng-container>
    </div>
</div> -->