import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { webApi } from '../../../config';
import { AlertService } from '../alert/alert.service';
import { EventsService } from '../events/events.service';
declare var DeviceUUID: any;
@Injectable({
  providedIn: 'root'
})
export class UserService {
  user: any;
  userRole: any;
  private headers = new HttpHeaders();
  baseUrl: any = webApi.baseUrl;
  registerUserInfoUrl: any = this.baseUrl + webApi.apiUrl.registerUserInfo;
  registerUserDeviceInfo: any = this.baseUrl + webApi.apiUrl.registerUserDeviceInfo;
  logoutFromOtherDevices: any = this.baseUrl + webApi.apiUrl.logoutFromOtherDevices;
  popupConfig = {
    title: `Login Alert`,
    message: `You are already logged in on another device, Do you want to logout from other device ?`,
    buttons: [
      {
        id: "confirm",
        btnText: "Confirm",
      },
      {
        id: "cancel",
        btnText: "Cancel",
      },
    ],
  };
  constructor(
    public http: HttpClient,
    private deviceService: DeviceDetectorService,
    public alertService: AlertService,
    private events: EventsService
  ) { }

  setUser(userinfo) { // set User Info/Details
    this.user = userinfo;
  }
  getUser() { // fetch User Info/Details
    return this.user;
  }
  setUserRole(roles) {
    this.userRole = roles;
  }
  getUserRole() {
    return this.userRole;
  }

  getUserUUID() {
    return new DeviceUUID().get();
  }

  getUserDetails() {
    const userDetails = localStorage.getItem('currentUser');
    if (userDetails) {
      return JSON.parse(userDetails);
    } else {
      return null;
    }
  }


  getVersion() {
    // this.getDeviceInfoForPWA();
    let versionNumber = null;
    const aux: any = document.getElementsByTagName('META');
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < aux.length; i++) {
      if (aux[i].name === 'version') {
        versionNumber = aux[i].content;
      }
    }
    return versionNumber;
  }

  getDeviceInfo() {
    // const du = new DeviceUUID().parse();
    // console.log('du deviceInfo : ', du);
    const tempDeviceInfo = this.deviceService.getDeviceInfo();
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    const isDesktopDevice = this.deviceService.isDesktop();
    console.log('deviceInfo : ', tempDeviceInfo);
    console.log('isMobile : ', isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
    console.log('isTablet : ', isTablet);  // returns if the device us a tablet (iPad etc)
    console.log('isDesktopDevice : ', isDesktopDevice); // returns if the app is running on a Desktop browser.
    const deviceInfo = {
      model: tempDeviceInfo ? (tempDeviceInfo.device + '-' + tempDeviceInfo.browser) : '',
      platform: tempDeviceInfo ? tempDeviceInfo.os : '',
      version: tempDeviceInfo ? tempDeviceInfo.os_version : '',
      browser: tempDeviceInfo ? tempDeviceInfo.browser : '',
      browser_version: tempDeviceInfo ? tempDeviceInfo.browser_version : '',
    };
    return deviceInfo;
  }

  populateUserInfoData(userDetails) {
    // const userDetails = this.getUserDetails();
    if (userDetails) {
      // const this.getVersion() = this.updateService.populateAppInfoData();
      const fetchedDeviceInfoData = this.getDeviceInfo();
      const user_uuid = new DeviceUUID().get();
      const param: any = {
        // userid: userDetails.id,
        appid: 'Illume-WEB',
        model: fetchedDeviceInfoData ? fetchedDeviceInfoData.model : '',
        platform: fetchedDeviceInfoData ? fetchedDeviceInfoData.platform : '',
        version: fetchedDeviceInfoData ? fetchedDeviceInfoData.version : '',
        pushid: '', // This token varies
        pushplayerid: '', // This ID is constant
        uuid: user_uuid ? user_uuid : '',
        appVersionNumber: this.getVersion() ? this.getVersion() : '',
        appVersionCode: this.getVersion() ? this.getVersion() : '',
        platFormId: 3,
        typeId: userDetails.typeId
      };
      return param;
    } else {
      return null;
    }
  }

  registerUserInfo(isLoggingIn) {
    const userInfo = this.getUserDetails();
    if (userInfo) {
      userInfo.typeId = isLoggingIn ? 1 : 0;
      this.registerDeviceInfo(userInfo).then(
        res => {
          console.log('registerDeviceInfo result : ', res);
          if (res && res.type) {
            const result = res.data;
            console.log('User info registered...');
            if (userInfo.checkConcurrent == 1 && isLoggingIn == true && result[0].actCount != 0) {
              this.presentAlert();
            }
            this.events.publish('user:checkLogout', null);
          } else {
            console.log('Unable to connect to server at this time : ', res);
          }
        },
        err => {
          console.log('registerDeviceInfo error : ', err);
        }
      );
    } else {
      console.log('unable to registerDeviceInfo');
    }
  }

  public registerDeviceInfo(userDetails): Promise<any> {
    const userDeviceInfo = this.populateUserInfoData(userDetails);
    return this.http.post(this.registerUserDeviceInfo, userDeviceInfo, { headers: this.headers })
      .toPromise()
      .then((response: any) => {
        return response = response;
      })
      .catch(this.handleError);
  }

  // registerUserInfo(userDetails, cb) {
  //   const userDeviceInfo = this.populateUserInfoData(userDetails);
  //   if (userDeviceInfo) {
  //     return this.http.post(this.registerUserInfoUrl, userDeviceInfo, { headers: this.headers })
  //       .toPromise()
  //       .then(response => {
  //         return response = response;
  //       })
  //       .catch(this.handleError);
  //   } else {
  //     return null;
  //   }
  // }
  logoutOthers() {
    let params = {
      uuid: new DeviceUUID().get(),
      platFormId: 3,
      model: this.getDeviceInfo().model,
    }
    return this.http.post(this.logoutFromOtherDevices, params, { headers: this.headers })
      .toPromise()
      .then(response => {
        return response = response;
      })
      .catch(this.handleError);
  }

  presentAlert() {
    console.log('Session timeout...');
    this.alertService.showAlert(this.popupConfig).onClose.subscribe((data) => {
      console.log('popup closed', data);
      if (data) {
        if (data.id == 'confirm') {
          // this.doLogout(true);
          this.logoutOthers();
        } else {
          // this.setLastAccess(false);
          this.alertService.dismissAlert();
        }
      }
    });
  }

  private handleError(error: Response | any) {
    return Promise.reject(error.message || error);
  }
}
