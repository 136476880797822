import { Injectable } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";

@Injectable({
  providedIn: "root",
})
export class LoadingService {
  constructor(private spinner: NgxSpinnerService) {}

  presentLoading(text) {
    console.log("presentLoading", text);
    this.spinner.show();
  }

  dismiss() {
    this.spinner.hide();
  }
}
