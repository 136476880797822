import { Component, OnDestroy, OnInit } from "@angular/core";

import { ActivityService } from "../../providers/activity/activity.service";
import { ModuleService } from "../../providers/module/module.service";
import { LoadingService } from "../../providers/loading/loading.service";
import { ToasterService } from "../../providers/toaster/toaster.service";
import { EventsService } from "../../providers/events/events.service";
import { Subscription } from "rxjs";
import { NbDialogService } from '@nebular/theme';
import { NbLayoutScrollService } from '@nebular/theme';
import { ContentOptionService } from '../../providers/content-option/content-option.service';
import { ShareContentComponent } from '../../components/share-content/share-content.component';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { SharedService } from "../../modules/shared/providers/shared.service";
import { webApi } from "../../../config";
import { SearchableSelectListComponent } from "../searchable-select-list/searchable-select-list.component";

@Component({
  selector: 'ngx-module-activity-frame',
  templateUrl: './module-activity-frame.component.html',
  styleUrls: ['./module-activity-frame.component.scss']
})
export class ModuleActivityFrameComponent implements OnInit, OnDestroy {
  configModuleActivityFrame = {
    moduleActivityList: null,
    courseData: null,
    autoPlayFlag: false,
    flag: "learner",
    requestedActId: null
  };
  currentCourseData = null;
  userdetails = null;
  noDataFound = false;
  noDataFoundContainer = {
    // image: 'assets/images/no-data.svg',
    customImage: '',
    showImage: true,
    title: 'Course data not available at this time',
    discription: '',
  };
  areaId: number;
  dialogRef = null;
  subscription1$: Subscription;

  // requestedActId: any = null;

  constructor(
    public toaster: ToasterService,
    public moduleService: ModuleService,
    public spinner: LoadingService,
    // public multiLanguageService: MultiLanguageService,
    private eventsService: EventsService,
    public activityService: ActivityService,
    private dialogService: NbDialogService,
    public layoutScrollService: NbLayoutScrollService,
    private contentOptionService: ContentOptionService,
    private route: ActivatedRoute,
    public router: Router,
    private sharedService: SharedService
  ) {

  }

  ngOnInit(): void {
    this.subscription1$ = this.eventsService.subscribe(
      "course:updatedCourseDetails",
      (updatedCourse) => {
        this.currentCourseData = updatedCourse;
      }
    );
    this.getParams();
  }

  initComponentData() {
    // const tempProgress = Number(this.currentCourseData.percComplete) / 100;
    // this.currentCourseData['progressVal'] = tempProgress;
    this.userdetails = JSON.parse(localStorage.getItem("currentUser"));
  }
  getParams() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.makeParamsReady(params);
    });
  }

  makeParamsReady(params) {
    this.resetValues();
    this.initComponentData();
    // this.currentUserData = this.helpersService.getCurrentUserData();
    const routeParams = { ...params };
    console.log('makeParamsReady step details route params are : ', routeParams);
    this.spinner.presentLoading('Get page data');
    this.getCourseDetails(routeParams.params, (type, result) => {
      if (type == 1) {
        this.noDataFound = false;
        this.currentCourseData = result.data[0];

        // else {
        //   this.noDataFoundContainer = {
        //     // image: 'assets/images/no-data.svg',
        //     showImage: true,
        //     title: 'Course data not available at this time',
        //     discription: '',
        //   };
        // }
        this.getCourseContentDetails(this.currentCourseData);
      } else {
        this.noDataFound = true;
        if (result && result['message'] && Array.isArray(result['message']) && result['message'].length != 0) {
          this.noDataFoundContainer = { ...this.noDataFoundContainer, ...result['message'][0] };
        }
      }
    });
  }

  /****************** get ttt workflow step details start *******************/

  getCourseDetails(routerParams, cb) {
    if (routerParams.areaId == 5) {
      this.configModuleActivityFrame.requestedActId = routerParams.instanceId == 0 ? null : routerParams.instanceId;
    }
    const param = {
      areaId: routerParams.areaId,
      instanceId: routerParams.instanceId,
      stepId: routerParams.stepId == 0 ? null : routerParams.stepId,
      roleId: this.userdetails.roleId,
      pathwayId: routerParams.pathwayId == 0 ? null : routerParams.pathwayId,
    };
    // this.currentCourseData = this.moduleService.course;
    // this.spinner.dismiss();
    // cb(true, this.currentCourseData);
    this.moduleService
      .getAreawiseData(param)
      .then((result) => {
        let flag = null;
        if (result.type && result.data && result.data.length != 0) {
          // cb(true, result.data[0]);
          flag = 1;
        } else {
          flag = 2;
          // cb(false, null);
        }
        cb(flag, result);
        this.spinner.dismiss();
      })
      .catch((result) => {
        this.spinner.dismiss();
        console.log("SummaryDATA--->", result);
        cb(3, null);
        this.presentAlert("No Data Available", "Warning!", "warning");
      });

  }
  /****************** get ttt workflow step details end *******************/

  resetValues() {
    this.currentCourseData = null;
    this.userdetails = null;
    this.areaId = null;
    this.noDataFoundContainer = {
      // image: 'assets/images/no-data.svg',
      customImage: '',
      showImage: true,
      title: 'Course data not available at this time',
      discription: '',
    };
  }

  passEventToParent(event) {
    console.log("passEventToParent", event);
  }

  // fullScreen() {
  //   console.log("fullScreen");
  //   this.eventsService.publish("course:fullScreen", "");
  // }
  full = null;
  fullScreen() {
    this.full = document.getElementById("fullDiv");
    if (this.full.requestFullscreen) {
      this.full.requestFullscreen();
    } else if (this.full.mozRequestFullScreen) {
      this.full.mozRequestFullScreen();
    } else if (this.full.webkitRequestFullscreen) {
      this.full.webkitRequestFullscreen();
    } else if (this.full.msRequestFullscreen) {
      this.full.msRequestFullscreen();
    }
  }
  getCourseContentDetails(data) {
    if (data) {
      // this.spinner.presentLoading("Get Course Module list");
      const params = {
        // courseId: data.id || data.instanceId,
        // tId: this.userdetails.tenantId,
        // eId: data.enrolId,
        instanceId: data.id || data.instanceId,
        enrolId: data.enrolId,
        areaId: data.areaId,
        stepId: data.stepId,
        wfId: data.wfId,
        roleId: this.userdetails["roleId"],
        trainerId: this.userdetails["roleId"] == 7 ? this.userdetails["referenceId"] : null,
        // instanceName: data.instanceName,
        // entintyName: data.courseTitle || data.instanceName,
      };
      this.areaId = data.areaId;
      this.moduleService
        .getLearnModulesList(params)
        .then((result) => {
          this.spinner.dismiss();
          if (result && result["data"] && result["data"].length !== 0) {
            this.bindResponseData(result);
            // this.configModuleActivityFrame.courseData['actionButtons'] = this.contentOptionService.makeDisplaySettingReady(result.display_setting);
            // this.currentCourseData['actionButtons'] = this.configModuleActivityFrame.courseData['actionButtons'];
            console.log(
              "configModuleActivityFrame ===>",
              this.configModuleActivityFrame
            );
            this.spinner.dismiss();
          } else {
            this.spinner.dismiss();
            this.presentAlert("No Data Available", "Warning!", "warning");
            this.goBack();
            console.log("No Data in course", result);
          }
          //   else {
          //     this.configModuleActivityFrame.courseData['content_managers'] = [];
          //   }
          //   if (result["display_settings"] && Array.isArray(result["display_settings"]) && result["display_settings"].length) {
          //     this.configModuleActivityFrame.courseData['display_setting'] = this.contentOptionService.makeDisplaySettingReady(result.display_settings);
          //     this.currentCourseData['actionButtons'] = this.configModuleActivityFrame.courseData['display_setting'];
          //   } else {
          //     this.configModuleActivityFrame.courseData['display_setting'] = [];
          //     this.currentCourseData['actionButtons'] = [];
          //   }
          // this.configModuleActivityFrame.courseData['actionButtons'] = this.contentOptionService.makeDisplaySettingReady(result.display_setting);
          // this.currentCourseData['actionButtons'] = this.configModuleActivityFrame.courseData['actionButtons'];
          console.log(
            "configModuleActivityFrame ===>",
            this.configModuleActivityFrame
          );
        }
        ).catch((result) => {
          this.spinner.dismiss();
          console.log("SummaryDATA--->", result);
          this.presentAlert("No Data Available", "Warning!", "warning");
        });
    }
  }

  // Future Use
  // setDataAccordingToConfig() {
  //   let data = null;
  //   switch (this.configModuleActivityFrame.flag) {
  //     case "learner":
  //       data = this.getDataForLearner();
  //       break;
  //     case "trainer":
  //       data = this.getDataForTrainer();
  //       break;
  //     default:
  //       data = this.getDataForLearner();
  //       break;
  //   }
  //   return data;
  // }

  // getDataForLearner() {
  //   const data = {};
  //   data["courseDetailSummary"] = this.configModuleActivityFrame.data;
  //   data["url"] = webApi.apiUrl.get_all_learn_modules_list;
  //   data["param"] = {
  //     courseId: data["courseDetailSummary"].id,
  //     tId: this.userdetails.tenantId,
  //     eId: data["courseDetailSummary"].enrolId,
  //   };
  //   data["param"] = {
  //     courseId: data["courseDetailSummary"].id,
  //     tId: this.userdetails.tenantId,
  //     eId: data["courseDetailSummary"].enrolId,
  //     // courseId: 1390,
  //     // eId: 2792185,
  //     // tId: 1,
  //   };
  //   return data;
  // }
  // getCourseDetails() {
  //   this.spinner.presentLoading("Get Course Module list");
  //   const data = this.setDataAccordingToConfig();
  //   this.courseDetailSummary = data.courseDetailSummary;
  //   // this.userdetails = JSON.parse(localStorage.getItem('userdetails'));
  //   // this.employeeId = localStorage.getItem('employeeId');
  //   // this.activeRole = localStorage.getItem('roleName');
  //   // console.log('this.activeRole:', this.activeRole);
  //   // console.log('userdetails course detail page:', this.CDSPuserdetails);
  //   // const param = {
  //   //   // courseId: this.courseDetailSummary.id,
  //   //   // tId: this.userdetails.tenantId,
  //   //   // eId: this.courseDetailSummary.enrolId,
  //   //   courseId: 1390,
  //   //   eId: 2792185,
  //   //   tId: 1,
  //   // };
  //   // const url = ENUM.domain + ENUM.url.getLearnModules;
  //   this.moduleService
  //     .getCourseDetailsData(data.url, data.param)
  //     .then((result) => {
  //       this.spinner.dismiss();
  //       // let temp = result;
  //       // console.log('fetched course modules:', result);
  //       // this.courseDetailTabArray = temp.data;
  //       if (result && result["data"] && result["data"].length !== 0) {
  //         this.moduleList = result["data"];
  //         console.log("Response Module List ===>", this.moduleList);

  //         this.autoPlayFlag = result["autoPlayFlag"] ? true : false;
  //         // this.autoPlayFlag = false;
  //       } else {
  //         // this.toastr.

  //         // this.toastr
  //         //   .warning("No Data Available", "Warning!")
  //         //   .onHidden.subscribe(() => this.toastr.clear());
  //         this.presentAlert("No Data Available", "Warning!", "warning");
  //         // window.history.back();
  //         this.goBack();
  //         console.log("No Data in course", result);
  //       }
  //     })
  //     .catch((result) => {
  //       this.spinner.dismiss();
  //       console.log("SummaryDATA--->", result);
  //       this.presentAlert("No Data Available", "Warning!", "warning");
  //     });
  // }

  // // Trainer Data setup

  // getDataForTrainer() {
  //   const data = {};
  //   data["courseDetailSummary"] = this.configModuleActivityFrame.data;
  //   data["url"] = "";
  //   data["param"] = {
  //     tId: this.userdetails.tenantId,
  //   };
  //   if (this.configModuleActivityFrame.subFlag == "facilitatorGuide") {
  //     data["param"]["cid"] = data["courseDetailSummary"].courseId;
  //   }
  //   if (this.configModuleActivityFrame.subFlag == "participantsGuide") {
  //     data["param"]["cid"] = data["courseDetailSummary"].batchId;
  //   }
  //   return data;
  // }

  goBack() {
    // window.history.back();
    this.sharedService.goBack();
  }

  async presentAlert(msg, title, type) {
    await this.toaster.prsentToast(msg, title, type, null);
  }

  performActions(type, data, buttonData = null) {
    if (type) {
      switch (type) {
        case 'share': this.shareContent();
          break;
        case 'send': this.goToSection(data);
          break;
        case 'rating': this.goToSection(data);
          break;
        case 'like': this.likeDislike(this.currentCourseData, 1, buttonData);
          break;
        case 'dislike': this.likeDislike(this.currentCourseData, 2, buttonData);
          break;
      }
    }
  }

  shareContent() {
    // this.dialogRef = this.dialogService.open(ShareContentComponent, {
    //   context: {
    //     // config: newVariables,
    //     instanceData: { ...this.currentCourseData },
    //   },
    //   closeOnEsc: false,
    //   closeOnBackdropClick: false,
    // });

    const curentUser = JSON.parse(localStorage.getItem('currentUser'));
    const config: any = {
      popupTitle: 'Share Content',
      showClose: true,
      api: webApi.apiUrl.getSharedUserList,
      noDataContainerMessage: '',
      params: {
        searchString: '', // keyword
        // from: this.currentPageNo, // starting point from where you want the document helpful for pagination
        areaId: this.currentCourseData.areaId,
        instanceId: this.currentCourseData.instanceId,
        pageLimit: 20,
        pageNo: 1,
        selectedIds: '',
        // user_id: '11',
        tenantId: curentUser.tenantId,
        empId: curentUser.eid,
      },
      pageParamName: 'pageNo',
      searchStringParamName: 'searchString',
      dataLimit: '20',
      showFooter: true,
      type: 'shareContent',
      // showButtons: false,
      button: [
        {
          action: "cancel",
          btnText: "Cancel",
          classList: 'customBtnWhite'
        },
        {
          action: "shareContent",
          btnText: "Share",
          classList: 'button-sm btn-fill'
        },
      ]
    };
    this.dialogRef = this.dialogService.open(SearchableSelectListComponent, {
      context: {
        // config: newVariables,
        instanceData: { ...this.currentCourseData },
        config: { ...config }
      },
      closeOnEsc: false,
      closeOnBackdropClick: false,
    });
    this.dialogRef.onClose.subscribe((event) => {
      this.dialogRef = null;
      console.log('SearchableSelectListComponent', event);
      // this.showAssignNotify = true;

    })
  }

  goToSection(sectionId) {
    const element = document.getElementById(sectionId);
    if (element) {
      const { x, y } = element.getBoundingClientRect();
      console.log('position', x, y);
      // this.layoutScrollService.scrollTo(x, y);
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  likeDislike(courseData, feedbackType, buttonData) {
    // this.load.presentLoading('Please wait...');
    this.spinner.presentLoading('Please wait...');
    let eventStatus = feedbackType;
    if ((feedbackType == 1 && courseData.isLike === 1) || (feedbackType == 2 && courseData.isDislike === 1)) {
      eventStatus = 0;
    }
    this.contentOptionService.addRemoveContentFeedback(courseData, eventStatus, (res) => {
      // this.load.dismiss();
      this.spinner.dismiss();
      if (res) {
        this.updateCourseFeedbackData(courseData, feedbackType, eventStatus, buttonData);
      }
    });
  }

  updateCourseFeedbackData(courseData, feedbackType, feedbackStatus, buttonData) {

    let currentCount = Number(buttonData.name);
    let { tempLikeDislikeCount, tempCourseData } = this.contentOptionService.getUpdatedLikeDislikeStatusAndCount(currentCount, feedbackType, feedbackStatus, courseData);

    this.updateActionButtonsData(feedbackType, feedbackStatus, tempLikeDislikeCount, tempCourseData);

    console.log('updateCourseFeedbackData : ', tempCourseData);
  }

  updateActionButtonsData(feedbackType, feedbackStatus, likeDislikeCount, courseData) {
    let actionButtons = this.currentCourseData.actionButtons.buttons;
    for (let i = 0; i < actionButtons.length; i++) {
      let button = actionButtons[i];
      //  && (feedbackStatus == 0 || feedbackStatus == 1)
      if (button.action == 'like') {
        // button.name = likeDislikeCount;
        // break;
        button.name = courseData.likeCountFormatted;
        //  && (feedbackStatus == 0 || feedbackStatus == 2)
      } else if (button.action == 'dislike') {
        // button.name = likeDislikeCount;
        // break;
        button.name = courseData.dislikeCountFormatted;
      }
    }
    // this.currentCourseData.actionButtons.buttons = actionButtons;
    // this.detectChangesAfterViewChangeForCourse();
  }

  calculateCourseProgressForOffline(moduleList) {
    // const moduleList = courseData.modulelist;
    const modCount = moduleList.length;
    let actCount = 0;
    let compActCount = 0;
    for (let i = 0; i < modCount; i++) {
      const actList = moduleList[i].list;
      actCount += actList.length;
      for (let j = 0; j < actList.length; j++) {
        const activity = actList[j];
        if (String(activity.completionstatus).toLowerCase() === 'y' || activity.wCompleted == 1) {
          compActCount++;
        }
      }
    }
    console.log('total ' + compActCount + ' of ' + actCount + ' activities completed.');
    const tempCourseProgress = (compActCount / actCount) * 100;
    const courseProgress = Math.round(tempCourseProgress);
    return courseProgress;
  }

  bindResponseData(result) {
    this.configModuleActivityFrame.moduleActivityList = result["data"];

    // autoplay change
    // this.configModuleActivityFrame.autoPlayFlag = result["autoPlayFlag"]
    //   ? true
    //   : false;

    let courseDetails = {};
    if (result["courseDetails"] && Array.isArray(result["courseDetails"]) && result["courseDetails"].length != 0) {
      courseDetails = result["courseDetails"][0];
    }
    this.currentCourseData = { ...this.currentCourseData, ...courseDetails }

    // autoplay change
    this.configModuleActivityFrame.autoPlayFlag = this.currentCourseData.isAutoPlay || result["autoPlayFlag"];

    this.configModuleActivityFrame.courseData = this.currentCourseData;
    // this.currentCourseData.percComplete = this.calculateCourseProgressForOffline(result["data"]);
    this.configModuleActivityFrame.courseData['courseContentList'] = result["courseContentList"];
    if (result["courseCertificate"] && Array.isArray(result["courseCertificate"]) && result["courseCertificate"].length) {
      this.configModuleActivityFrame.courseData['courseCertificate'] = result["courseCertificate"][0];
    }
    if (result["rating"] && Array.isArray(result["rating"]) && result["rating"].length) {
      this.configModuleActivityFrame.courseData['rating'] = result["rating"][0];
    } else {
      this.configModuleActivityFrame.courseData['rating'] = null;
    }
    if (result["content_managers"] && Array.isArray(result["content_managers"]) && result["content_managers"].length) {
      this.configModuleActivityFrame.courseData['content_managers'] = result["content_managers"];
    } else {
      this.configModuleActivityFrame.courseData['content_managers'] = [];
    }
    if (result["display_settings"] && Array.isArray(result["display_settings"]) && result["display_settings"].length) {
      this.configModuleActivityFrame.courseData['display_setting'] = this.contentOptionService.makeDisplaySettingReady(result.display_settings, this.currentCourseData);
      this.currentCourseData['actionButtons'] = this.configModuleActivityFrame.courseData['display_setting'];
    } else {
      this.configModuleActivityFrame.courseData['display_setting'] = [];
      this.currentCourseData['actionButtons'] = [];
    }
  }

  ngOnDestroy() {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
    this.spinner.dismiss();
    if (this.subscription1$) {
      this.subscription1$.unsubscribe();
    }

  }
}
