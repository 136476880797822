<!-- d-flex -->
<div class="borderdiv grid-container justify-content-between align-items-center">
    <div>
        <nb-user [title]="user?.fullname" size="large" [picture]="user?.picture_url" [name]="(user.fullname) | truncate : 20" [title]="'Employee Id :' + user?.ecn">
        </nb-user>
        <ng-container *ngIf="user?.isGo1AccessLabel && user?.isGo1AccessValue">
            <div class="d-flex">
                <span class="invisible">Dummytext</span>
                <div class="fs-12">{{user?.isGo1AccessLabel}} - {{user?.isGo1AccessValue}}</div>
            </div>
        </ng-container>
    </div>

    <div class="text-center fs-14">
        <ng-container [ngSwitch]="config?.type">
            <!-- <div *ngSwitchCase="'shareContent'">
              <nb-icon icon="done-all-outline" class="mr-3 all-select-icon "></nb-icon>
          </div> -->
            <div class="text-gray" *ngSwitchCase="'assignContent'">
                {{user?.enrolDate}}
            </div>
        </ng-container>
    </div>
    <div class="text-right" *ngIf="user.isSelected == 0 || user.isTemp == true">
        <div class="checkbox d-inline-block align-top pb-2" (click)="sendEvent('checkChanged',user)">
            <input type="checkbox" [checked]="user?.isSelected == 1">
            <span class="checkmark"></span>
        </div>
    </div>


    <div class="text-right" *ngIf="user.isTemp != true && user.isSelected == 1">
        <ng-container [ngSwitch]="config?.type">
            <div *ngSwitchCase="'shareContent'">
                <nb-icon icon="done-all-outline" class="mr-3 all-select-icon "></nb-icon>
            </div>
            <div class="fs-14 text-danger" [ngClass]="{'cursor-p': user?.buttonState == 1}" *ngSwitchCase="'assignContent'" (click)="user?.buttonState == 1 ? sendEvent('unEnroll',user) : null">
                {{user?.buttonText}}
            </div>
        </ng-container>

    </div>
</div>
