import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from '../providers/login/login.service';
import { EventsService } from '../providers/events/events.service';
@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {
  authStatus: any;

  constructor(
    private router: Router,
    // public storage: Storage,
    public authenticationService: LoginService,
    private eventsService: EventsService,
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    // this.authStatus = this.authenticationService.isAuthenticated();
    // console.log('RoleGuard with status', this.authStatus);

    return new Promise((resolve, reject) => {
      const val = JSON.parse(localStorage.getItem('currentUser'));
      console.log('RoleGuard current User role', val.roleId);
      if (val) {
        // if (val.roleId == 5 && this.checkIfRouteIsValid(state.url, 'manager')) {
        //   resolve(true);
        // } else if (val.roleId == 8 && this.checkIfRouteIsValid(state.url, 'pages')) {
        //   resolve(true);
        // } else if (val.roleId == 7 && this.checkIfRouteIsValid(state.url, 'cip')) {
        //   resolve(true);
        // } else {
        //   this.authenticationService.goToDashboard();
        //   resolve(false);
        // }
        let urlForRole = '';
        if (this.checkIfRouteIsValid(state.url, 'manager')) {
          urlForRole = '5';
        } else if (this.checkIfRouteIsValid(state.url, 'pages')) {
          urlForRole = '8';
        } else if (this.checkIfRouteIsValid(state.url, 'cip')) {
          urlForRole = '7';
        }
        // if (urlForRole != '') {
        //   this.authenticationService.goToDashboard();
        //   resolve(false);
        // } else {
        const roleData = this.checkIfThatRoleExist(urlForRole);
        if (val.roleId == urlForRole) {
          resolve(true);
        } else if (roleData.found) {
          this.switchRole(roleData.data);
          resolve(true);
        } else {
          console.log('Role guard called...');
          this.authenticationService.goToDashboard();
          resolve(false);
        }
        // }
      } else {
        resolve(true);
      }
      // this.storage.ready().then(() => {
      //   console.log('storage ready');
      //   this.storage.get('currentUser').then((val) => {
      //     console.log('currentUser', val);
      //     if (val) {
      //       this.router.navigateByUrl('/pages/features/home');
      //       resolve(false);
      //     } else {
      //       resolve(true);
      //     }
      //   });
      // });
      resolve(true);
    });
  }

  checkIfRouteIsValid(route, role) {
    const n = route.search(role);
    if (n != -1) {
      return true;
    } else {
      return false;
    }
  }

  checkIfThatRoleExist(roleId) {
    const roleData = {
      data: null,
      found: false
    }
    const roleList = JSON.parse(localStorage.getItem('userTabsByRole'));
    for (let index = 0; index < roleList.length; index++) {
      const element = roleList[index];
      if (element.id == roleId) {
        roleData.data = element;
        roleData.found = true;
        break;
      }
    }
    return roleData;
  }

  switchRole(selectedRole) {
    this.authenticationService.changeUserRole(selectedRole);
  }
}
