import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild, ElementRef, ɵConsole, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';
import { NbDialogRef } from "@nebular/theme";
import * as moment from 'moment';
// import { IDayCalendarConfig } from 'ng2-date-picker';
import { ITimeSelectConfig } from 'ng2-date-picker/time-select/time-select-config.model';
// import { AlertService } from '../../providers/alert/alert.service';
import { LearningCartService } from '../../providers/learning-cart/learning-cart.service'
import { ToasterService } from '../../providers/toaster/toaster.service';
import { EventsService } from '../../providers/events/events.service';

@Component({
  selector: 'ngx-schedulepopup',
  templateUrl: './schedulepopup.component.html',
  styleUrls: ['./schedulepopup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class SchedulepopupComponent implements OnInit {
  checked1: boolean = true;
  checked2: boolean = false;
  @ViewChild('parent') parent?: ElementRef<HTMLElement>;
  @ViewChild("datediv", { static: true }) divView: ElementRef<HTMLElement>
  @Input() config: any = null;
  dateTime3: any = this.formatDate(new Date(new Date().setDate(new Date().getDate() + 1)));
  dataSubmittedForTab = false;
  // date = new Date().setDate(getDate() -1);
  date: any = new Date(new Date().setDate(new Date().getDate()))
  // dateconfig :IDayCalendarConfig= {
  dateconfig = {

    // min : this.date.toDateString(),
    // min : this.date.toDateString(),
    min: this.formatDate(this.date),
    max: '',
    enableMonthSelector: true,
    showNearMonthDays: false,
    // moveCalendarTo:this.dateTime3
    // unSelectOnClick : false,
    // disabled : false
    // disabled : this.checked2 == true?true:false
  };

  //  startAt = date
  userData: any = JSON.parse(localStorage.getItem('currentUser'));
  timePicker: any = null
  time2valid: boolean;
  time2: any = moment();
  time3: any = moment();
  time3valid: boolean;
  // time1: any =  this.formattime('2014-08-10' + ' ' + '00:00:00', 0)
  time1: any = moment()
  time1valid: boolean;
  datePickerConfig: ITimeSelectConfig = {
    // format: "YYYY-MM-DD",
    // firstDayOfWeek: "mo",
    // hours24Format: "HH",
    showTwentyFourHours: false,
    meridiemFormat: "A",
    timeSeparator: ':',
    // showGoToCurrent: false,
    // minTime: moment(),

    // displayDate:  this.formattime('2014-08-10' + ' ' + '00:00:00', 0) ,  // default:'hhbhbh'
    // hours24Format:'HH',
    // min:this.formatTime(new Date())
    // disableKeypress: true

  };
  BindingData: any;
  event: any;
  eventTime1: any = null;
  param: {
    date: any;
    // time1: time1,
    time1: any; time2: any; time3: any; notificationType: any; action: any; timeArray: any;
  };
  showPersonalized: boolean = false;
  eventTime3: any = null;
  eventTime2: any = null;
  dummyDate: string;
  submitted: boolean = false;
  showMessage: boolean;
  timeValuesSame: boolean = false;
  currentTimeValidationMessage: boolean = false;
  immediateNotification: number = 0;
  dontClosePopup: boolean;
  callClose: boolean;
  constructor(public ref: NbDialogRef<SchedulepopupComponent>,
    private datePipe: DatePipe,
    private elRef: ElementRef,
    // private alertService: AlertService,
    private learningService: LearningCartService,
    public events: EventsService,
    private cdf: ChangeDetectorRef,
    public toaster: ToasterService, //  private analyticsFirebase: FirebaseAnalyticsService,

  ) {


  }

  onchange(event, id) {
    console.log(event, "timeggo")
    if (id == 1) {
      this.eventTime1 = event.date
    }
    if (id == 2) {
      this.eventTime2 = event.date
    }
    if (id == 3) {
      this.eventTime3 = event.date
    }
    // return this.time1
  }
  async presentAlert(msg, title, type) {
    await this.toaster.prsentToast(msg, title, type, null);
  }
  toogle(data) {
    this.dateTime3 = null
    // this.time1 =this.formatTime(new Date())
    // this.time1 ="22:39"
    // this.time1 =moment('2014-08-10 13:32:33').format('MMMM Do YYYY, h:mm:ss a')
    // this.dateTime3 = this.formatDate(this.date)
    // date.setTime(date.getTime() + Math.floor(offset) * 36e5);
    this.time1 = null
    this.time2 = null
    this.time3 = null
    if (data == 1) {
      if (this.config.bindingData && this.config.bindingData.notification_type && this.config.bindingData.notification_type == 1) {
        this.dateTime3 = this.config.bindingData.notication_date ? this.formatDate(this.config.bindingData.notication_date) : null
      }
      this.checked1 = true;
      this.checked2 = false;
    } else {
      if (this.config.bindingData && this.config.bindingData.notification_type && this.config.bindingData.notification_type == 2) {
        this.dateTime3 = this.config.bindingData.notication_date ? this.formatDate(this.config.bindingData.notication_date) : null
        this.dummyDate = this.config.bindingData.notication_date ? this.formatdate(this.config.bindingData.notication_date) : null
        this.time1 = this.formattime('2014-08-10' + ' ' + this.config.bindingData.notification_time, 0);
      }
      this.checked2 = true;
      this.checked1 = false;
    }
  }
  ngOnInit() {
    //  this.open()
    console.log(this.config.bindingData, "scheduleData")
    this.BindingData = this.config.bindingData
    if (this.config.bindingData) {
      if (this.config.bindingData.notification_type && this.config.bindingData.notification_type == 2) {
        this.checked2 = false
        this.checked1 = true
        this.dateTime3 = this.config.bindingData.notication_date ? this.formatDate(this.config.bindingData.notication_date) : null
        this.dummyDate = this.config.bindingData.notication_date ? this.formatdate(this.config.bindingData.notication_date) : null
        // this.time1 = this.formattime('2014-08-10' + ' ' + this.config.bindingData.notification_time, 0);
        if (this.dateTime3) {
          this.showMessage = true
        }
        else {
          this.dateTime3 = this.formatDate(new Date(new Date().setDate(new Date().getDate())))
          // this.dummyDate = this.dateTime3
          this.dummyDate = this.formatdate(new Date(new Date().setDate(new Date().getDate())))

        }


      } else {
        this.checked1 = true
        this.checked2 = false
        this.dateTime3 = this.config.bindingData.notication_date ? this.formatDate(this.config.bindingData.notication_date) : null
        this.dummyDate = this.config.bindingData.notication_date ? this.formatdate(this.config.bindingData.notication_date) : null
        if (this.dateTime3) {
          this.showMessage = true
        }
        else {
          this.dateTime3 = this.formatDate(new Date(new Date().setDate(new Date().getDate())))
          this.dummyDate = this.formatdate(new Date(new Date().setDate(new Date().getDate())))
        }
      }
    }
    var timeArray = '10:00:00'
    var currentTime = new Date();
    console.log('09:52:00' > timeArray)
    if (this.formatTime(currentTime) > timeArray && this.formatDate(currentTime) == this.dateTime3) {
      this.currentTimeValidationMessage = true
      this.immediateNotification = 1
    } else {
      this.currentTimeValidationMessage = false
      this.immediateNotification = 0
    }


  }
  open() {
    // let element:HTMLElement = document.getElementById('datediv') as HTMLElement ;
    let element = this.divView.nativeElement.click()
    console.log(element)
    // element.click()
  }

  onDateChange(event) {
    this.dateTime3 = null
    console.log(this.dateTime3)

  }

  formatDate(date) {
    let d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    let year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [day, month, year].join('-');
  };

  formatdate(date) {
    let d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    let year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  };



  formatTime(time) {
    let duration = new Date(time);
    let formatted = this.datePipe.transform(duration, 'HH:mm');
    console.log('formatted', formatted);
    let str = new String(formatted);
    let formattedFinal = str.replace(/:/g, ':');
    console.log('formattedFinal', formattedFinal);
    return formattedFinal + ':00';
  };

  getDisplayTime(time) {
    let duration = new Date(time);
    let formatted = this.datePipe.transform(duration, 'h:mm a');
    console.log('formatted', formatted);
    let str = new String(formatted);
    let formattedFinal = str.replace(/:/g, ':');
    console.log('formattedFinal', formattedFinal);
    return formattedFinal;
  };

  formattime(time, i) {
    let d = new Date(time);
    let hr: any = '' + d.getHours();
    let mm = '' + d.getMinutes();


    if (hr.length < 2) hr = '0' + hr;
    if (mm.length < 2) mm = '0' + mm;
    hr = Number(hr) + i;
    if (hr == 0) {
      hr = "00"
      // mm = "mm"
    }
    return [hr, mm].join(':');
  }

  getDate(event) {
    if (this.checked2 == false) {
      this.event = event.date
      var timeArray = '10:00:00'
      var currentTime = new Date()
      console.log(this.formatTime(currentTime), "bhbh")
      if (this.formatTime(currentTime) > timeArray && this.formatDate(currentTime) == this.formatDate(this.event)) {
        this.currentTimeValidationMessage = true
        this.immediateNotification = 1
      } else {
        this.currentTimeValidationMessage = false
        this.immediateNotification = 0
      }
    }
    // this.dateconfig.max = this.event

  }

  getPersonalized() {
    this.currentTimeValidationMessage = false
    this.checked2 = true
    this.dateconfig = {

      // min : this.date.toDateString(),
      // min : this.date.toDateString(),
      min: this.formatDate(this.event ? this.event : this.dateTime3),
      max: this.formatDate(this.event ? this.event : this.dateTime3),
      enableMonthSelector: true,
      showNearMonthDays: false,
      // moveCalendarTo:this.dateTime3
      // unSelectOnClick:this.checked2 == false?true:false,
      // disabled:this.checked2 == false?true:false
    };
    this.date = this.formatDate(this.date)
    if (this.dateconfig.min == this.date) {
      this.time1 = null
      this.time2 = null
      this.time3 = null
      // this.datePickerConfig.minTime = this.formattime(new Date(),0)
      this.datePickerConfig = {
        // format: "YYYY-MM-DD",
        // firstDayOfWeek: "mo",
        // hours24Format: "HH",
        showTwentyFourHours: false,
        meridiemFormat: "A",
        timeSeparator: ':',

        // showGoToCurrent: false,
        // minTime: this.formattime(new Date(),1) ,
        minTime: moment(),
        // maxTime:moment('23:59:59','HHmmss')
        maxTime: moment().endOf('day')

        // min :this.formattime(new Date(),0)

        // displayDate:  this.formattime('2014-08-10' + ' ' + '00:00:00', 0) ,  // default:'hhbhbh'
        // hours24Format:'HH',
        // min:this.formatTime(new Date())
        // disableKeypress: true

      };
      //  delete this.datePickerConfig.maxTime
      console.log(this.datePickerConfig)
      // this.time1 = this.datePickerConfig.minTime
    }
  }
  prepareObject(date, time1, time2, time3, type, action) {
    var percent_separator = JSON.parse(localStorage.getItem('separators')).pipeValue;
    this.timeValuesSame = false
    var param = {
      date: this.event ? this.formatdate(this.event) : this.dummyDate,
      // time1: time1,
      time1: this.eventTime1,
      time2: this.eventTime2,
      time3: this.eventTime3,
      notificationType: type,
      action: action,
      timeArray: null
    }

    this.param = param



    if (type == 2) {
      const time = time1 && this.time1valid ? this.formatTime(time1) : null
      const param2 = time2 && this.time2valid ? this.formatTime(time2) : null
      const param3 = time3 && this.time3valid ? this.formatTime(time3) : null
      const array = []
      if (time != null || time != undefined) {
        array.push(time)
      }
      if (param2 != null || param2 != undefined) {
        array.push(param2)
      }
      if (param3 != null || param3 != undefined) {
        array.push(param3)
      }

      if (array.length > 1) {
        for (let i = 0; i < array.length; i++) {
          for (let j = i + 1; j < array.length; j++) {
            // if(array[i] && array[i+1]){
            if (array[i] == array[j]) {
              this.timeValuesSame = true
              break;
            }
            // }
          }
        }
      }
      console.log(this.timeValuesSame, "timesvalue")
      // array.push(time2)
      // array.push(time3)
      if (array) {
        // var timeArray = array.join("|")
        var timeArray = array.join(percent_separator)
        console.log(array, "timeArray")
      }
    } else {
      var timeArray = '10:00:00'
      // var currentTime = new Date()
      // if ( this.formatdate(currentTime) == param.date){

      // }
    }
    param.timeArray = timeArray

    // this.ref.close(param)
    if (this.config.where == 'inside') {
      if (type == 1) {
        this.submit(this.config.data, 1, type, null, param)
      } else {
        this.submit(this.config.data, 1, type, null, param)
        this.submitted = true
        setTimeout(() => {                           //<<<---using ()=> syntax
          // this.ref.close('close')
          if (this.dontClosePopup == false && this.callClose == true) {

            this.closePopup('submit');
          }
        }, 3000);

        // this.checked1 = false

      }
    } else {
      if (type == 1) {
        this.addDataToCart(this.config.data, type, null, param)
      } else {
        this.addDataToCart(this.config.data, type, null, param)
        this.submitted = true
        // this.checked1 = false
        setTimeout(() => {                           //<<<---using ()=> syntax
          // this.ref.close('close')
          if (this.dontClosePopup == false && this.callClose == true) {

            this.closePopup('submit');
          }
        }, 2000);

      }
    }
  }

  DisplayTime(date, time1, time2, time3, type, action) {
    var param = {
      // date: this.event?this.formatdate(this.event):this.dummyDate,
      // time1: time1,
      time1: this.eventTime1,
      time2: this.eventTime2,
      time3: this.eventTime3,
      notificationType: type,
      action: action,
      timeArray: null
    }

    if (type == 2) {
      const time = time1 && this.time1valid ? this.getDisplayTime(time1) : null
      const param2 = time2 && this.time2valid ? this.getDisplayTime(time2) : null
      const param3 = time3 && this.time3valid ? this.getDisplayTime(time3) : null
      const array = []
      if (time != null || time != undefined) {
        array.push(time)
      }
      if (param2 != null || param2 != undefined) {
        array.push(param2)
      }
      if (param3 != null || param3 != undefined) {
        array.push(param3)
      }
      // array.push(time2)
      // array.push(time3)
      if (array) {
        var timeArray = array.join(", ")
        console.log(array, "timeArray")
      }
    } else {
      var timeArray = '10:00:00'
    }
    param.timeArray = timeArray

    return timeArray

    // this.ref.close(param)

  }

  submit(data, typeId, notitype, type, paramData) {
    if (this.timeValuesSame == true) {
      // this.presentAlert(
      //   'You cannot select the same time',
      //   "Warning",
      //   "warning"
      // );
    }
    else {
      var currentTime = new Date()
      if (this.formatdate(currentTime) == paramData.date) {
        this.immediateNotification = 1
      }
      // this.alertService.deleteContent(this.config,1,null,this.param)
      let params = {
        // cartId: data.cart_id,
        cartId: data.cartId,
        typeId: this.config.typeId,
        scheduleDateTime: type == 'del' ? null : paramData.date,
        // userId: this.userData.id,
        // userId:19974,
        roleId: this.userData.roleId,
        immediateNotification: this.immediateNotification,
        scheduleTime: type == 'del' ? null : paramData.timeArray,
        notificationType: notitype
      };
      this.learningService.deleteCartData(params).then((response: any) => {
        console.log('response from deleting ' + response);
        if (response.type == true) {
          if (response['data'][0]['flag'] == 1) {
            this.showPersonalized = true
            this.dataSubmittedForTab = true;
            this.dontClosePopup = false
            this.callClose = true
            this.cdf.detectChanges();
            if (this.currentTimeValidationMessage != true) {
              this.presentAlert(
                response['data'][0]['msg'],
                "Success",
                "success"
              );
            } else {
              this.presentAlert(
                "Please schedule personalized notification for today's date",
                "Warning",
                "warning"
              );
            }
            // this.showPersonalized = true
            // this.dontClosePopup = false
            // this.callClose = true
            // this.checked2 = true
            if (this.config._this) {
              this.config._this.popupOnSubmit()
            }
            this.events.publish('user:callGetPushNotificationsCount', true);
            // this.cdf.detectChanges()

          } else {

            this.dontClosePopup = true
            this.callClose = false
            this.cdf.detectChanges()
            this.presentAlert(
              response['data'][0]['msg'],
              "Warning",
              "warning"
            );

            // setTimeout(() => {
            // this.cdf.detectChanges()

            // }, 10000);

          }
        } else {
          this.presentAlert(
            'something went wrong please try again later',
            "Warning!",
            "warning"
          );
        }
      }).catch((err) => {
        // this.presentAlert(
        //   'something went wrong please try again later',
        //   "Warning!",
        //   "warning"
        // );
        this.showPersonalized = true
        // this.checked2 = true
        this.cdf.detectChanges()
        // this.toogle(2)
        console.error('error deleting ' + err);
      });
    }
  }

  addDataToCart(item, notitype, type, paramData) {
    if (this.timeValuesSame == true) {
      // this.presentAlert(
      //   'You cannot select the same time',
      //   "Warning",
      //   "warning"
      // );
    }
    else {
      const scheduleType = type == 'add' ? 0 : 1;
      var currentTime = new Date()
      if (this.formatdate(currentTime) == paramData.date) {
        this.immediateNotification = 1
      }
      var params = {
        areaId: item.areaId,
        instanceId: item.instanceId,
        roleId: this.userData.roleId,
        // userId: this.userData.id,
        scheduleRuleId: type == 'add' ? null : 1,
        scheduleDateTime: type == 'add' ? null : paramData.date,
        immediateNotification: this.immediateNotification,
        isSchedule: scheduleType,
        isActive: 1,
        notificationType: notitype,
        scheduleTime: type == 'add' ? null : paramData.timeArray
        // id : 1,
        // tenantId : item.tenantId,
      }
      // if (this.currentPageNo === 1) {
      //   this.load.presentLoading('Please wait....');
      // }
      this.learningService.addDataToCart(params).then(
        (res) => {
          console.log(res, "res")
          if (res.type == true) {
            if (res['data'][0]['flag'] == 1) {
              this.config.data['success'] = true
              this.config.data['cartId'] = res['data'][0]['cartId'];
              this.config.data['isScheduled'] = scheduleType;
              this.dataSubmittedForTab = true;
              this.events.publish('user:callGetPushNotificationsCount', true);
              if (this.currentTimeValidationMessage != true) {
                this.presentAlert(
                  res['data'][0]['msg'],
                  "Success",
                  "success"
                );
              } else {
                this.presentAlert(
                  "Please schedule personalized notification for today's date",
                  "Warning",
                  "warning"
                );
              }
              this.showPersonalized = true
              this.dontClosePopup = false
              this.callClose = true
              // this.checked2 = true
              this.cdf.detectChanges()
            } else {
              this.presentAlert(
                res['data'][0]['msg'],
                "Warning",
                "warning"
              );
              this.dontClosePopup = true
              this.callClose = false
              this.cdf.detectChanges();

            }
          } else {
            this.presentAlert(
              'something went wrong please try again later',
              "Warning!",
              "warning"
            );
          }

        },
        (err) => {
          // this.load.dismiss();
          console.log("err ", err);
        }
      );
    }
  }

  onTime1(event) {
    if (event.target.checked == true) {
      if (this.time1) {
        this.time1valid = true
      } else {
        this.time1valid = false;
      }
    }
    else {
      this.time1valid = false;
    }
    // if(data==1){
    //   this.checked1=true;
    //   this.checked2=false;
    // }else{
    //   this.checked2=true;
    //   this.checked1=false;
    // }
  }

  onTime2(event) {
    if (event.target.checked == true) {
      if (this.time2) {
        this.time2valid = true
      } else {
        this.time2valid = false;
      }
    }
    else {
      this.time2valid = false;
    }
    // if(data==1){
    //   this.checked1=true;
    //   this.checked2=false;
    // }else{
    //   this.checked2=true;
    //   this.checked1=false;
    // }
  }

  onTime3(event) {
    if (event.target.checked == true) {
      if (this.time3) {
        this.time3valid = true
      } else {
        this.time3valid = false;
      }
    }
    else {
      this.time3valid = false;
    }
    // if(data==1){
    //   this.checked1=true;
    //   this.checked2=false;
    // }else{
    //   this.checked2=true;
    //   this.checked1=false;
    // }
  }

  getDisplayDate(dateTime3) {
    var medium: any = null
    console.log(typeof this.dateTime3, "hbhbhbhb")
    if (typeof this.dateTime3 !== 'string' && this.dateTime3) {
      medium = this.datePipe.transform(this.dateTime3, " d MMM, y")
    }
    else {
      let myDate = new Date(this.dateTime3.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3"))
      medium = this.datePipe.transform(myDate, " d MMM, y")
    }
    return medium

  }


  closePopup(id) {

    this.currentTimeValidationMessage = false
    if (this.timeValuesSame == false || id == 'out') {
      this.ref.close(this.config.data);
    }
  }
  ngOnDestroy() {
    this.config.bindingData = null;
  }

}
