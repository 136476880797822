import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'ngx-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss']
})
export class ActionsComponent implements OnInit, OnChanges {
  // @Input() link: string;
  // @Input() remove: boolean;
  // @Input() edit: boolean;
  @Input() actions = {};
  @Input() data = {};
  @Input() showCustomActionLabel = false;
  @Output() performAction = new EventEmitter<any>();
  @Input() label: any = [];
  @Input() alignment: any;
  // @Output() removeClick  = new EventEmitter<any>();
  // @Output() editClick  = new EventEmitter<any>();

  constructor() {
    // console.log('this.label ==> ' + this.label);
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log('ActionsComponent changes : ', changes);
    if ('alignment' in changes) {
      // do your thing
      // console.log('ActionsComponent alignment changes : ', changes);
    }
  }

  emitAction(item) {
    console.log('emitAction', item);
    this.performAction.emit(item);
  }
}
