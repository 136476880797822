<ngx-popup show="true" [popupConfig]="popupConfig" (actionEvent)="performActions($event)">
  <!-- <textarea class="form-control" rows="8" placeholder="Lorem ipsum"></textarea> -->
  <textarea id="commentBox" class="form-control" [placeholder]="popupConfig?.placeHolder" rows="8"
    [minlength]="popupConfig?.validation?.minLength" [maxlength]="popupConfig?.validation?.maxLength"
    [(ngModel)]="popupConfig.reason" name="commentBox" #commentBox="ngModel" pattern="^[a-zA-Z0-9 ]+$"
    [required]="popupConfig?.validation?.required" [disabled]="!popupConfig?.editable"></textarea>
  <div *ngIf="commentBox?.errors && (commentBox?.dirty || commentBox?.touched)">
    <small class="invalid-msg"
      [hidden]="!commentBox?.errors?.required">{{popupConfig?.validation?.errorMsgRequired}}</small>
    <small class="invalid-msg"
      [hidden]="!commentBox?.errors?.minlength">{{popupConfig?.validation?.errorMsgMinLength}}</small>
    <small class="invalid-msg"
      [hidden]="!commentBox?.errors?.maxlength">{{popupConfig?.validation?.errormaxLength}}</small>
    <small class="invalid-msg" *ngIf="commentBox.hasError('pattern')">Special charcters are not
      allowed</small>
  </div>
</ngx-popup>