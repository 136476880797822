import { Injectable } from '@angular/core';
import { DashboardService } from '../../providers/dashboard/dashboard.service';
import { ToasterService } from "../../../providers/toaster/toaster.service";
import { Router, NavigationExtras } from "@angular/router";
import { DeliveryService } from '../delivery/delivery.service';
@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor(private dashboardService: DashboardService, private toaster: ToasterService, private router: Router, private deliveryService: DeliveryService) { }

  getDataOnType(param, cb) {
    const userDetails = this.getCurrentUserData();
    const params = {
      trainerId: userDetails.referenceId,
      // typeId: this.tabIds,
      datalimit: 5,
      pageNo: 1,
      searchString: '',
      ...param
      // platformId: 3
    };
    if (cb) {
      this.dashboardService.getDataOnType(params).then(
        (res) => {
          if (res.type === true) {
            console.log(" Dashboard data", res);
            const data = res['data'];
            cb(res.type, data)

          } else {
            console.log("err ", res);
            this.presentToastMessage("Unable to get results at this time.", "Warning", 'warning');
            cb(false, [])

          }
        },
        (err) => {
          this.presentToastMessage("Unable to get results at this time.", "Warning", 'warning');
          cb(false, [])
          console.log("err ", err);
        }
      );
    } else {
      return this.dashboardService.getDataOnType(params);
    }

  }

  getCurrentUserData() {
    return JSON.parse(localStorage.getItem('currentUser'));
  }

  /*************** Present Toast Message  ************/

  async presentToastMessage(msg, title, type) {
    await this.toaster.prsentToast(msg, title, type, null);
  }

  /*************** Present Toast Message END ************/

  /*************** GO TO Details************/
  goToDetails(instance, type) {
    let route = '';
    console.log('type and instance details', type, instance);
    if (type == 1) {
      if (instance.areaId == 2) {
        // need to add wfId to the route
        if (instance.wfId) {
          route = 'cip/delivery-batch-details/' + instance.wfId + '/' + instance.instanceId + '/' + type + '/attendance';
        } else {
          route = `cip/cip-module-activity/2/${instance.prgramCourseId}/0/${instance.instanceId}/facilitator`;
        }
        console.log('route ==> ' + route);
        // this.router.navigateByUrl(route);

      }
      // if (instance.areaId = 29) {
      //   this.router.navigateByUrl('cip/assessor-workflow-details/' + instance.instanceId + '/nominee-list')
      // }
    }
    if (type == 2) {
      if (instance.areaId == 2) {
        route = 'cip/batch-details/' + instance.wfId + '/' + instance.instanceId + '/' + type + '/final-assessment';

        // this.router.navigateByUrl(route, navigationExtras);

      }
      if (instance.areaId == 29) {
        route = 'cip/assessor-workflow-details/' + instance.instanceId + '/nominee-list';

      }
    }
    let navigationExtras: NavigationExtras = {
      state: {
        isTitle: true,
        title: instance.name
      }
    }
    this.router.navigateByUrl(route, navigationExtras);
  }
  /*************** GO TO Details END ************/

  viewAll(item, type, params) {
    console.log('viewAll', params);
    this.router.navigateByUrl('cip/view-all/' + item.id + '/' + type + '/' + params.programList + '/' + params.categoryList);
  }

  getMilestoneData(param, cb) {
    const params = {
      ...param
    };
    this.dashboardService.getMileStoneData(params).then(
      (res) => {
        if (res.type === true) {
          console.log("getMileStoneData data", res);
          const data = res['data'];
          cb(res.type, data)

        } else {
          console.log("err ", res);
          this.presentToastMessage("Unable to get results at this time.", "Warning", 'warning');
          cb(false, [])

        }
      },
      (err) => {
        this.presentToastMessage("Unable to get results at this time.", "Warning", 'warning');
        cb(false, [])
        console.log("err ", err);
      }
    ).catch((error) => {
      this.presentToastMessage("Unable to get results at this time.", "Warning", 'warning');
      cb(false, [])
      console.log("err ", error);
    });
  }

  downloadProgramReport(param, cb) {
    const params = {
      ...param
    };
    this.dashboardService.downloadProgramReport(params).then(
      (res) => {
        if (res.type === true) {
          console.log("downloadProgramReport data", res);
          const data = res['data'];
          cb(res.type, data)

        } else {
          console.log("err ", res);
          this.presentToastMessage("Unable to get results at this time.", "Warning", 'warning');
          cb(false, [])

        }
      },
      (err) => {
        this.presentToastMessage("Unable to get results at this time.", "Warning", 'warning');
        cb(false, [])
        console.log("err ", err);
      }
    ).catch((error) => {
      this.presentToastMessage("Unable to get results at this time.", "Warning", 'warning');
      cb(false, [])
      console.log("err ", error);
    });
  }

  generateReportAndPayout(data, cb) {
    this.generatePPR(data).then(
      (res) => {
        if (res.type === true) {
          console.log("generatePPR", res);
          cb(res.type, res)
          // this.spinner.dismiss();
          this.presentToastMessage('Batch report send to manager and PPR Generated', 'Success', 'success');

        } else {
          console.log("err ", res);
          cb(res.type, res)
          // this.spinner.dismiss();
          this.presentToastMessage('Unable to generate Batch ppr', 'warning', 'Warning');
        }
      },
      (err) => {
        console.log("err ", err);
        // this.spinner.dismiss();
        cb(false, err)
        this.presentToastMessage('Unable to generate Batch ppr', 'warning', 'Warning');
      }
    ).catch(err => {
      cb(false, err)
      this.presentToastMessage('Unable to generate Batch ppr', 'warning', 'Warning');
    });
  }
  generatePPR(data) {
    const userDetails = this.getCurrentUserData();
    let params = {
      workflowIds: data.wfId,
      courseId: data.instanceId,
      tttcreatorId: data.creatorIds,
      batchId: data.instanceId,
      trainerId: userDetails.referenceId,
      programId: data.programId,
    };
    return this.deliveryService.getPPR(params)
  }
}
