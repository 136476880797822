import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from "@angular/core";
import { SharedService } from "../../modules/shared/providers/shared.service";
import { ContentOptionService } from '../../providers/content-option/content-option.service';
@Component({
  selector: "app-course-card-advance",
  templateUrl: "./course-card-advance.component.html",
  styleUrls: ["./course-card-advance.component.scss"],
})
export class CourseCardAdvanceComponent implements OnInit, OnChanges {
  @Input() cardItem: any;
  @Output() EventChange = new EventEmitter();
  @Output() goToEvent = new EventEmitter();
  @Input() flagStart = false;
  buttons = [];
  @Input() cardConfig: any = {
    type: "instanceType",
    title: "instanceName",
    viewLabel: "viewsCount",
    commentsLabel: "commentsCount",
    shareLabel: "sharedCount",
    showList: false,
    showPercentage: true,
    starCountLabel: "rating",
    image: "instanceThumbnail",
    isScheduled: 'isScheduled',
    isPinned: 'isPinned',
    isAddToCart: "isAddToCart",
    showStars: false,
    showShareCount: true,
    showMore: true,
    likeEnabled: true,
    likeCount: "likeCount",
    dislikeEnabled: true,
    dislikeCount: "dislikeCount",
  };
  cardEntity = CardEnumEntity;
  // {
  //   // showHover: true,
  //   // showImage: true,
  //   // image: 'cImage',
  //   // showHeart: true,
  //   // isFav: 'cisFav',
  //   // course: 'ccourse',
  //   // classroom: 'cClassroom',
  //   // courseName: 'cCourseName',
  //   // showDate: true,
  //   // courseDate: 'cCourseDate',
  //   // showPoint: true,
  //   // pointLabel: 'cPointLabel',
  //   // points: 'cPoints',
  //   // levelPrice: true,
  //   // levleLabel: 'cLevleLabel',
  //   // coursePrice: 'cCoursePrice',
  //   // progressLike: false,
  //   // completePercent: 'cCompletePercent',
  //   // showProgress: false,
  //   // showLike: false,
  //   // isLike: 'cIsLike',
  //   // showaAuthor: true,
  //   // authorName: 'cAuthorName',
  //   // courseDescri: 'cCourseDescri',
  //   // hoverPrice: true,
  //   // courseDiscount: 'cCourseDiscount',
  //   // originalPrice: 'cOriginalPrice',
  //   // discountPrice: 'cDiscountPrice',
  //   // showaEnroll: false,
  //   // showaBuynow: true,
  //   viewLabel: "viewsCounts",
  //   commentsLabel: "CommentsCount",
  //   shareLabel: "shareCount",
  //   showPercentage: false,
  //   showStars: true,
  //   showList: true,
  //   starCountLabel: "starCount",
  //   percentageLabel: "percentage",
  // };
  show: boolean = false;
  hideCard: boolean = false;
  innerArray: any[];
  flag: boolean = false;

  constructor(
    private contentOptionService: ContentOptionService,
    private sharedService: SharedService
  ) { }

  ngOnInit() {
    // console.log('cardItem', this.cardItem);

  }

  mouseEnter() {
    // this.show = true;
    this.hideCard = true;

  }

  mouseLeave() {
    // this.show = false;
    setTimeout(() => {
      this.hideCard = false;
    }, 100);
  }

  showIcon(cardsData) {
    this.show = true;
    const object = {
      cardData: cardsData,
      type: 'getContentOptions',
    }
    // this.EventChange.emit(object);
    this.getContentOptions(this.cardItem, null, null);
    // this.outputEvent('getContentOptions', cardsData);
  }

  hideIcon() {
    this.show = false;
  }

  changeEvent(event) {
    if (event) {
      // const {event , type } = event;
      if (this.cardItem) {
        // this.changeItem = { ...event.cardData };
        this.cardItem = { ...event.cardData };
      }
      this.EventChange.emit(event);
    }
    // if (event && event.type == 'getContentOptions') {
    //   this.getContentOptions(this.changeItem, null, this.cardItem);
    // }
  }


  getContentOptions(content, index, item) {
    // const params = {
    //   tenantId: this.currentUserData.tenantId,
    //   userId: this.currentUserData.id,
    //   areaId: content.areaId,
    //   instanceId: content.instanceId,
    //   roleId: this.currentUserData.roleId
    // };
    // this.searchService.getContentOptionDetails(params).then(
    //   (res) => {
    //     console.log('getContentOptions res ', res);
    //     if (res.type) {
    //       if (res.data && res.data.length > 0) {
    //         // this.actionSheetButtons = res.data;
    //         // console.log('')

    //       }
    //     } else {
    //       console.log('err ', res);
    //     }
    //   },
    //   (err) => {
    //     console.log('err ', err);
    //   }
    // );
    this.cardItem['loading'] = true;
    this.contentOptionService.getContentOptions(content, (type, res) => {
      if (type) {
        // old
        // this.makeActionButtonsReady(res.data[0], content, index, item);

        // new
        content = { ...res };
        this.cardItem = content;
        this.updateOptions();

        this.cardItem['loading'] = false;
      } else {
        console.log('error', res);
      }
    })
  }


  ngOnChanges(changes: SimpleChanges) {
    // console.log('options', changes)
    if (changes && changes.cardItem) {
      this.updateOptions();
    }
    // if(changes)
  }

  makeActionButtonsReady(resButtons, content, index, item) {
    // if (resButtons.is_pin !== 0) {
    //   this.tempActionSheetButtons[0].text = 'Pinned';
    //   this.tempActionSheetButtons[0].icon = 'pin-outline';
    //   this.tempActionSheetButtons[0].cssClass = 'active';
    // }
    // let cardList = [
    //   //   {
    //   //   icon: 'pin-outline',
    //   //   text: 'Pin',
    //   //   action: 'bookmark'
    //   // },
    //   {
    //     icon: 'clock-outline',
    //     text: 'Schedule',
    //     action: 'schedule'
    //   },
    //   {
    //     icon: 'shopping-cart-outline',
    //     text: 'Add To Cart',
    //     action: 'addToCart'
    //   }]
    // if (resButtons.isScheduled !== 0) {
    //   let item = {
    //     icon: 'clock-outline',
    //     text: 'Reschedule',
    //     action: 'schedule',
    //     cssClass: 'active'
    //   }
    //   cardList.push(item);
    //   // this.tempActionSheetButtons[1].text = 'Reschedule';
    //   // this.tempActionSheetButtons[1].icon = 'time-outline';
    //   // this.tempActionSheetButtons[1].cssClass = 'active';
    // } else {
    //   let item = {
    //     icon: 'clock-outline',
    //     text: 'Schedule',
    //     action: 'schedule',
    //     cssClass: ''
    //   }
    //   cardList.push(item);
    // }
    // if (resButtons.cartId !== 0) {
    //   let item =  {
    //     icon: 'shopping-cart-outline',
    //     text: 'Go to cart',
    //     action: 'addToCart'
    //   };

    // }else {

    // }
    // this.actionSheetButtons = this.tempActionSheetButtons;
    // content['isScheduled'] = resButtons.isScheduled;
    content['isScheduled'] = resButtons.isScheduled;
    content['isAddToCart'] = resButtons.cartId == 0 ? 0 : 1;
    // content['cart_id'] = resButtons.cartId;
    content['cartId'] = resButtons.cartId;
    content['isPinned'] = resButtons.isPinned;
    content['isPinnedShow'] = resButtons.isPinnedShow;
    // this.cardItem = content // new line neha
    // this.courseList[indexp][indexC] = content;
    item = content;
    this.updateOptions();
    // console.log('content', content);
    // return item
    // this.searchContentList[index]._source = content;
  }

  // goToEvents(event) {
  //   if (!this.flag && this.goToEvent) {
  //     const object = {
  //       cardData: event,
  //       type: 'goToContent',
  //     }
  //     this.goToEvent.emit(object);
  //     if (this.EventChange) this.EventChange.emit(object);
  //   }
  //   this.flag = false;
  // }

  fireEvent(event, data, b_data?: any) {
    // if (data.compStatus !== 'UPCOMING') {
    const dataOutput = {
      type: event,
      cardData: data,
      // liked : isLiked,
      buttonData: b_data
    };

    console.log('Fire event called : ', dataOutput);
    this.goToEvent.emit(dataOutput);
    // } else {
    //   return false;
    // }
  }
  // outputEvent(event, type) {
  //   // if(event && this.EventChange) {
  //   //   event['type'] = type;
  //   //   this.EventChange.emit(event, type);
  //   // }
  //   this.flag = true;
  //   if (event && this.EventChange) {
  //     if (type === this.cardEntity.pin) {
  //       event.isPinned = event.isPinned == 1 ? 0 : 1;
  //     } else if (type === this.cardEntity.schedule) {
  //       // event.isScheduled = event.isScheduled == 1 ? 0 : 1;
  //       event.isScheduled = event.isScheduled
  //     } else if (type === this.cardEntity.addtocart) {
  //       event.isAddToCart = event.isAddToCart == 1 ? 0 : 1;
  //     }
  //     event['type'] = type;
  //     const object = {
  //       cardData: event,
  //       type: type,
  //     }
  //     this.EventChange.emit(object);
  //   }
  // }


  updateOptions() {
    const options = this.contentOptionService.makeButtonsReady(this.cardItem);
    // const buttons = []
    this.buttons = [];
    // console.log('options', options)
    for (let opt of options) {
      if (opt.isVisible) {
        // let button = {
        //   text: opt.text,
        //   cssClass: opt.cssClass,
        //   icon: opt.icon,
        // }
        this.buttons.push(opt);
      }
    }

  }

  buttonClicked(button, data) {
    console.log('Button clicked...', button);
    if (button.action) {
      this.fireEvent(button.action, data, button);
    } else {
      console.log('Button action not available...');
    }
  }

  optionClicked(event, data) {
    if (data.buttons) {
      const button = this.sharedService.getObjFromArray(data.buttons, event.id, 'action');
      if (button) {
        this.buttonClicked(button, data);
      } else {
        // this.fireEvent(event.id, data, null);
        this.fireEvent(event.id, event.value);
      }
    } else {
      // this.fireEvent(event.id, data, null);
      this.fireEvent(event.id, event.value);
    }
  }

}


export enum CardEnumEntity {
  addtocart = "addToCart",
  pin = "bookmark",
  schedule = "schedule"
}
