import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'ngx-right-side-drawer',
  templateUrl: './right-side-drawer.component.html',
  styleUrls: ['./right-side-drawer.component.scss', '../../@theme/styles/_typography.scss']
})
export class RightSideDrawerComponent implements OnInit {
  // @Input() sidebarTitle: any;
  // @Input() buttonName: any;
  @Input() config: any;
  // sidebarTitle: 'Add Slot',
  // buttons: [
  //   {
  //     action: "yes",
  //     btnText: "Save",
  //     classList: 'btn-fill'
  //   },
  //   {
  //     action: "no",
  //     btnText: "Close",
  //     classList: 'btn-outline'
  //   },
  // ],
  // }
  @Input() customClass: any = "";
  @Output() actionEvent = new EventEmitter<any>();
  private body = document.querySelector('body');

  constructor() { }

  ngOnInit(): void {
    this.body.style.overflow = 'hidden';
  }

  save() {
    this.fireEvent('save', null);
    this.body.style.overflow = 'inherit';
  }

  outsideClick() {
    // this.outside.emit();
    this.fireEvent('outsideClick', null);
    this.body.style.overflow = 'inherit';
  }

  closeClick() {
    // this.close.emit();
    this.fireEvent('close', null);
    this.body.style.overflow = 'inherit';
  }
  fireEvent(inEvent, inData, extraData = null) {
    const res = {
      type: inEvent,
      data: inData,
      extraData: extraData
    };
    this.actionEvent.emit(res);
  }

  // sendClickedData(item) {
  //   console.log('sendClickedData', item);
  //   this.fireEvent(item.action, null)
  // }
}
