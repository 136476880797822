import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
// import { Storage } from '@ionic/storage';

import { LoginService } from '../providers/login/login.service';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  authStatus: any;

  constructor(
    private router: Router,
    // public storage: Storage,
    public authenticationService: LoginService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {

    this.authStatus = "";//this.authenticationService.isAuthenticated();
    console.log('AuthGuard with status', this.authStatus);

    return new Promise((resolve, reject) => {
      // this.storage.ready().then(() => {
      // console.log('storage ready');
      // this.storage.get('currentUser').then((val) => {
      console.log('state url', state.url);
      const val: any = localStorage.getItem('currentUser');
      // console.log('currentUser', val);
      if (val) {
        const userData = JSON.parse(val);
        // if (String(userData.coc_flag).toLowerCase() === 'y') {
        //   this.authenticationService.logout();
        // } else {
        //   // if (state.url == '/pages/features/home') {
        //   //   this.authenticationService.goToDashboard();
        //   //   resolve(false);
        //   // } else {
        //   //   resolve(true);
        //   // }
        //   resolve(true);
        //   // this.authenticationService.goToDashboard();
        // }
        resolve(true);
      } else {
        localStorage.setItem('redirectUrl', state.url);
        // this.router.navigate(['/auth/login']);
        this.authenticationService.redirectToLogin();
        resolve(false);
      }
      // });
      // });
    });

  }
}
