import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-icon-text',
  templateUrl: './icon-text.component.html',
  styleUrls: ['./icon-text.component.scss']
})
export class IconTextComponent implements OnInit, OnChanges {

  @Input() iconText: string;
  @Input() setting;

  // @Input() label;
  @Input() data;

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: any) {
    console.log('setting : ', this.setting);
    console.log('data : ', this.data);
  }

}
