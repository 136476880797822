import { Component, ElementRef, ViewChild, OnChanges, OnInit, EventEmitter, Output, Input, ChangeDetectionStrategy } from '@angular/core';
import KeenSlider from "keen-slider";
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'ngx-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit, OnChanges {
  @Input() length: any;
  @Input() setting: any = {};
  @ViewChild("sliderRef") sliderRef: ElementRef<HTMLElement>
  // @Output() onHover = new EventEmitter();
  // @Output() leaveHover = new EventEmitter();

  currentSlide: number = 0;
  rightBtn: boolean = false;
  // resizeFlag: boolean = false;
  // dotHelper: Array<Number> = []
  slider: KeenSlider = null

  constructor(public breakpointObserver: BreakpointObserver) { }

  ngOnInit() {
    // if (this.breakpointObserver.isMatched('(min-width: 500px)')) {
    //   console.log('Viewport has a minimum width of 500px!');
    //   this.resizeFlag = true;
    // }
  }

  ngAfterViewInit() {
    // console.log('sliderRef ==================',this.sliderRef);

    setTimeout(() => {
      this.slider = new KeenSlider(this.sliderRef.nativeElement, {
        initial: this.currentSlide,
        controls: false,
        // loop: true,
        // breakpoints: {
        // "(min-width: 768px)": {
        //   slidesPerView: 3,
        //   // mode: "free-snap",
        // },
        // "(max-width: 1200px)": {
        //   slidesPerView: 3,
        //   // mode: "free-snap",
        // },
        // },
        // breakpoints: {
        //   '(min-width: 720px) and (max-width: 1000px)': {
        //     loop: false,
        //   },
        // },
        ...this.setting,
        // created: slider => {
        //   console.log('slider.details()', slider.details());
        // },
        slideChanged: (s) => {
          this.currentSlide = s.details().relativeSlide;
          // console.log('slider.details', s.details());
          // s.resize();
        },
      })
      // this.dotHelper = [...Array(this.slider.details().size).keys()]
    })
  }

  ngOnChanges(): void {
    // this.resizeFlag = true;
    // console.log("this.resizeFlag", this.resizeFlag);
  }

  ngOnDestroy() {
    if (this.slider) this.slider.destroy()
  }

  // ngAfterViewChecked(): void{
  //   if(this.slider && this.resizeFlag) {
  //     console.log("trying to resize")
  //     this.slider.resize();
  //     this.resizeFlag = false;
  //   }
  // }

  // mouseOver(event) {
  // this.onHover.emit(event);
  // console.log("slider mouseOver ==", event);
  // this.sliderRef.nativeElement.classList.add()
  // this.show = true;
  // }

  // mouseOut(event) {
  // this.leaveHover.emit(event);
  // console.log("slider mouseOut ==", event);
  // this.show = false;
  // }


}
