<div class="testmonial d-flex p-4 mb-4" *ngIf="item">
  <img class="testmonial__img" [src]="item?.picture_url" onerror="this.onerror=null; this.src='../../../assets/images/user.png'" alt="Course Image">
  <div class="w-100 pl-4">
    <p class="body-text">{{ item?.comment}}</p>
    <div class="d-flex align-items-center justify-content-between">
      <div class="d-flex align-items-baseline">
        <img class="testmonial__icon" src="assets/icon-svg/reply.svg" width="13" height="13">
        <h4 class="testmonial__user text-capitalize text-gray pl-2 m-0">{{item?.name}}</h4>
      </div>
      <span *ngIf="item.status == 0">
        <label class="text-success border-right cursor-p pr-3 m-0" (click)="fireEvent('approve', item)">Accept</label>
        <label class="text-danger cursor-p pl-3 m-0" (click)="fireEvent('reject', item)">Reject</label>
      </span>
    </div>
  </div>
</div>
