<div class="survey text-white border-radius-4px p-2 {{cardsData[config.colorClass]}}" (click)='performCardClick()'
  [ngClass]="{'cursor-na': cardsData[config?.blockCard] == 1}">
  <!-- [src]="cardsData[config.image]" onerror="this.onerror=null; this.src='./assets/images/activity1.jpg' -->
  <div class="d-flex align-items-center">
    <svg-icon svgClass="fill-none stroke-white" class="lh-0" src="assets/icon-svg/User_icon.svg"
      [svgStyle]="{'width.rem': 1, 'height.rem': 1, 'stroke-width': 1.4}"></svg-icon>
    <span class="text-12-px pl-1">{{cardsData[config?.author]}}</span>
  </div>
  <div class="text-16-px line-clamp-2 line-wrap h-42-px my-2">{{cardsData[config?.title]}}</div>

  <div class="d-flex align-items-center justify-content-between">
    <div class="d-flex align-items-center" *ngFor="let info of config?.extraInfo">
      <svg-icon svgClass="fill-none stroke-white" class="lh-0" [src]="info?.iconSrc"
      [svgStyle]="{'width.rem': 1, 'height.rem': 1, 'stroke-width': 1.4}"></svg-icon>
      <span class="text-12-px pl-1">{{cardsData[info?.key]}}</span>
    </div>
    
    <!-- survey-msg text-success shadow border -->
    <div class="d-flex align-items-center lh-1" *ngIf="cardsData[config?.blockCard] == 1">
      <svg-icon svgClass="lh-0 fill-success stroke-white" src="assets/icon-svg/Circle_check_icon.svg" 
      [svgStyle]="{'width.rem': 1, 'height.rem': 1, 'stroke-width': 1.4}"></svg-icon>
      <span class="text-12-px pl-1">{{cardsData[config?.blockMsg]}}</span>
    </div>
  </div>
  
  <!-- <div *ngIf="cardsData[config?.blockCard] == 1">
    {{cardsData[config?.blockMsg]}}
  </div> -->
</div>