import { Injectable } from "@angular/core";
import { NbToastrService, NbIconConfig, NbDuplicateToastBehaviour } from "@nebular/theme";
@Injectable({
  providedIn: "root",
})
export class ToasterService {
  constructor(private toastrService: NbToastrService) { }

  async prsentToast(msg, title, type, icon) {
    if (type == "success") {
      const iconConfig: NbIconConfig = {
        icon: "checkmark-circle-2",
        pack: "eva",
        options: {
          preventDuplicates: true,
        }
      };
      this.toastrService.success(msg, title, iconConfig);
    } else if (type == "warning") {
      let option: NbDuplicateToastBehaviour = 'previous';
      const config = {
        icon: "alert-triangle-outline",
        pack: "eva",
        preventDuplicates: true,
        // limit: 1,
        duplicatesBehaviour: option,
        status: 'warning'
      };
      // this.toastrService.show(msg, title, iconConfig);
      this.toastrService.warning(
        msg,
        title,
        config
      );
    } else if (type == "error") {
      const iconConfig: NbIconConfig = {
        icon: "alert-triangle",
        pack: "eva",
        options: {
          preventDuplicates: true,
          limit: 1,
        }
      };
      // this.toastr.error(msg, title).onHidden.subscribe(
      //   () => this.toastr.clear(),
      // );
      // this.toastr.warning(msg, title).onHidden.subscribe(
      //   () => this.toastr.clear(),
      // );
      this.toastrService.warning(msg, title, iconConfig);
    } else if (type == "basic") {
      const iconConfig: NbIconConfig = {
        icon: "info-outline",
        pack: "eva",
      };
      this.toastrService.show(msg, title, iconConfig);
    } else if (type == "custom") {
      const iconConfig: NbIconConfig = {
        icon: "headphones-outline",
        pack: "eva",
      };
      this.toastrService.show(msg, title, iconConfig);
    }
  }
}
