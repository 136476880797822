// export const feature = {
//   webinar: {
//     'jisti': false,
//     'openvidu': false,
//     'BBB': true,
//   },
//   activity: {
//     'standardui': false, // OLD UI
//     'modernui': true,   // Modern UI
//   },
//   catgoryDropDown: {
//     // 'Bajaj': false,
//     // 'other': true,
//     'show': true,
//   },
//   wishlist: {
//     'show': true,
//     // 'Bajaj': false,
//     // 'other': true,
//   },
//   footer: {
//     'Bajaj': false,
//     'other': false,
//   },
//   brandName: 'Edge',
//   allowDynamicDbLogoImage: true,
//   pageTitle: 'EDGE',
//   faviconPNG: '',
//   faviconXICON: '',
// };

// New Setting
import { Feature } from "./Illume/bajaj-feature-env-enum";

// Saas Prod
// import { Feature } from './Edge/edge-feature-env-enum';

// SaasTest / Dev

// import { Feature } from "./Edge/edge-test-feature-env-enum";
export const feature = Feature;
