import { Injectable } from '@angular/core';

// import * as XLSX from 'xlsx';
import { ToasterService } from '../../../../providers/toaster/toaster.service';
import { webApi } from '../../../../../config';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class ExcelUploadAndPreviewService {

  baseUrl: any = webApi.baseUrl;
  private headers = new HttpHeaders();

  // getEventUrl: any = this.baseUrl + webApi.apiUrl.calendarevent;

  private EXCEL_EXTENSION = '.xlsx';
  private EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

  constructor(
    public http: HttpClient,
    private toaster: ToasterService,
  ) { }

  checkForProperData(array) {
    if (array && Array.isArray(array) && array.length != 0) {
      return true;
    }
    return false;
  }

  downloadFile(url, name) {
    const a = document.createElement('a');
    // const blob = new Blob([data], { type: '.xlsx' });
    const objectUrl = url;
    a.href = objectUrl
    if (name) {
      a.download = name;
    }
    // a.click();
    // window.open(objectUrl);

    a.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
    setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      a.remove();
    }, 100);
  }

  downloadBlobFile(data) {
    const a = document.createElement('a');
    const blob = new Blob([data], { type: '.xlsx' });

    const objectUrl = URL.createObjectURL(blob)
    a.href = objectUrl
    a.download = 'template.xlsx';
    // a.click();
    // window.open(objectUrl);

    a.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
    setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      URL.revokeObjectURL(objectUrl);
      a.remove();
    }, 100);
  }

  performExcelFileValidation({ event, maxExcelSizeUpload }) {
    // console.log('performFileExcelUpload', event, tabId);

    // let event = data.fileEvent;

    let files = event.target.files ? event.target.files : [];
    const object = {
      status: 0,
      data: null
    }
    if (files.length != 0) {
      const element = files[0];
      const last_dot = element.name.lastIndexOf('.');
      const fileType = element.name.slice(last_dot + 1);
      const allowedFormats = 'xlsx,xls';

      if (last_dot == -1) {
        // cb(1, null);
        this.presentToastMessage('Invalid file.', 'Warning', 'warning');
        // return;
      } else if (allowedFormats.toUpperCase().indexOf(String(fileType).toUpperCase()) == -1) {
        // cb(1, null);
        this.presentToastMessage('Invalid file format / type', 'Warning', 'warning');
      } else if (maxExcelSizeUpload <= element.size) {
        // cb(1, null);
        this.presentToastMessage('Uploaded file size greater than ' + this.getFileSize(maxExcelSizeUpload), 'Warning', 'warning');
      } else {
        object.status = 1;
        object.data = element;
        // cb(0, element);
      }
    } else {
      // cb(1, null);
      this.presentToastMessage('Please select a file', 'Warning', 'warning');
    }
    return object;
  }

  getFileSize(_size) {
    var fSExt = new Array('Bytes', 'KB', 'MB', 'GB'),
      i = 0; while (_size > 900) { _size /= 1024; i++; }
    return (Math.round(_size * 100) / 100) + ' ' + fSExt[i];
    // console.log('FILE SIZE = ',exactSize);

  }

  // convertExcelToJSON(ev, cb) {
  //   let workBook = null;
  //   let jsonData = null;
  //   const reader = new FileReader();
  //   try {
  //     const file = ev.target.files[0];
  //     reader.onload = (event) => {
  //       const data = reader.result;
  //       console.log('data', reader);
  //       workBook = XLSX.read(data, { type: 'binary' });
  //       jsonData = workBook.SheetNames.reduce((initial, name) => {
  //         const sheet = workBook.Sheets[name];
  //         initial[name] = XLSX.utils.sheet_to_json(sheet, { raw: true });
  //         return initial;
  //       }, {});
  //       const dataString = JSON.stringify(jsonData);
  //       console.log('data', jsonData);
  //       cb(1, jsonData)
  //     };
  //     reader.readAsBinaryString(file);
  //   } catch (e) {
  //     console.log('error', e);
  //     cb(0, jsonData)
  //   }
  // }

  /*************** Present Toast Message ************/
  async presentToastMessage(msg, title, type) {
    await this.toaster.prsentToast(msg, title, type, null);
  }
  /*************** Present Toast Message END ************/

  public httpPostRequest(url, params: any, tempHeaders: any = {}): Promise<any> {
    let headers = {
      headers: this.headers,
      ...tempHeaders
    }
    return this.http
      .post(url, params, headers)
      .toPromise()
      .then((response) => {
        return (response = response);
      })
      .catch(this.handleError);
  }

  private handleError(error: Response | any) {
    return Promise.reject(error.message || error);
  }
}
