import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from "@angular/core";
import { SharedService } from "../../modules/shared/providers/shared.service";
import { ContentOptionService } from '../../providers/content-option/content-option.service';

@Component({
  selector: "app-course-card-v3",
  templateUrl: "./course-card-v3.component.html",
  styleUrls: ["./course-card-v3.component.scss"],
})
export class CourseCardV3Component implements OnInit, OnChanges {

  show: boolean = false;
  disable: boolean = false;
  @Input() cardItem: any;
  @Input() flagStart: any = false;
  @Output() EventChange = new EventEmitter();
  @Output() goToEvent = new EventEmitter();
  @Input() cardConfig: any = {
    type: "instanceType",
    title: "instanceName",
    viewLabel: "viewsCount",
    commentsLabel: "commentsCount",
    shareLabel: "sharedCount",
    showList: false,
    showStars: false,
    showPercentage: true,
    starCountLabel: "rating",
    percentageLabel: "instanceProgress",
    image: "instanceThumbnail",
    isScheduled: 'isScheduled',
    isPinned: 'isPinned',
    isAddToCart: "isAddToCart",
    showMore: true,
    likeEnabled: true,
    likeCount: "likeCount",
    dislikeEnabled: true,
    dislikeCount: "dislikeCount",
  };
  buttons = [];
  cardEntity = CardEnumEntity;
  // {
  //   // showHover: true,
  //   // showImage: true,
  //   // image: 'cImage',
  //   // showHeart: true,
  //   // isFav: 'cisFav',
  //   // course: 'ccourse',
  //   // classroom: 'cClassroom',
  //   // courseName: 'cCourseName',
  //   // showDate: true,
  //   // courseDate: 'cCourseDate',
  //   // showPoint: true,
  //   // pointLabel: 'cPointLabel',
  //   // points: 'cPoints',
  //   // levelPrice: true,
  //   // levleLabel: 'cLevleLabel',
  //   // coursePrice: 'cCoursePrice',
  //   // progressLike: false,
  //   // completePercent: 'cCompletePercent',
  //   // showProgress: false,
  //   // showLike: false,
  //   // isLike: 'cIsLike',
  //   // showaAuthor: true,
  //   // authorName: 'cAuthorName',
  //   // courseDescri: 'cCourseDescri',
  //   // hoverPrice: true,
  //   // courseDiscount: 'cCourseDiscount',
  //   // originalPrice: 'cOriginalPrice',
  //   // discountPrice: 'cDiscountPrice',
  //   // showaEnroll: false,
  //   // showaBuynow: true,
  //   viewLabel: "viewsCounts",
  //   commentsLabel: "CommentsCount",
  //   shareLabel: "shareCount",
  //   showList: false,
  //   showStars: false,
  //   showPercentage: true,
  //   starCountLabel: "starCount",
  //   percentageLabel: "percentage",
  // };
  // flag: boolean = false;

  constructor(
    private contentOptionService: ContentOptionService,
    private sharedService: SharedService
  ) { }

  ngOnInit() {
    // console.log('CourseCardV3Component cardItem : ', this.cardItem);
    // console.log('CourseCardV3Component cardConfig : ', this.cardConfig);
  }

  mouseEnter(cardsData) {
    this.show = true;
    const object = {
      cardData: cardsData,
      type: 'getContentOptions',
    }
    this.EventChange.emit(object);
    // this.outputEvent('getContentOptions', cardsData);
  }

  mouseLeave() {
    this.show = false;
  }

  showIcon(cardsData) {
    this.show = true;
    const object = {
      cardData: cardsData,
      type: 'getContentOptions',
    }
    // this.EventChange.emit(object);
    this.getContentOptions(this.cardItem, null, null);
    // this.outputEvent('getContentOptions', cardsData);
  }

  hideIcon() {
    this.show = false;
  }

  getContentOptions(content, index, item) {
    this.cardItem['loading'] = true;
    this.contentOptionService.getContentOptions(content, (type, res) => {
      if (type) {
        // old
        // this.makeActionButtonsReady(res.data[0], content, index, item);

        // new
        content = { ...res };
        this.cardItem = content;
        this.updateOptions();

        this.cardItem['loading'] = false;
      } else {
        console.log('error', res);
      }
    })
  }

  // goToEvents(event) {
  //   if (!this.flag && this.goToEvent) {
  //     const object = {
  //       cardData: event,
  //       type: 'goToContent',
  //     }
  //     this.goToEvent.emit(object);
  //     if (this.EventChange) this.EventChange.emit(object);
  //   }
  //   this.flag = false;
  // }
  // outputEvent(event, type) {

  //   // if(event && this.EventChange) {
  //   //   event['type'] = type;
  //   //   this.EventChange.emit(event, type);
  //   // }
  //   this.flag = true;
  //   if (event && this.EventChange) {
  //     if (type === this.cardEntity.pin) {
  //       event.isPinned = event.isPinned == 1 ? 0 : 1;
  //     } else if (type === this.cardEntity.schedule) {
  //       // event.isScheduled = event.isScheduled == 1 ? 0 : 1;
  //       event.isScheduled = event.isScheduled
  //     } else if (type === this.cardEntity.addtocart) {
  //       event.isAddToCart = event.isAddToCart == 1 ? 0 : 1;
  //     }
  //     event['type'] = type;
  //     const object = {
  //       cardData: event,
  //       type: type,
  //     }
  //     this.EventChange.emit(object);
  //   }
  // }


  fireEvent(event, data, b_data?: any) {
    // if (data.compStatus !== 'UPCOMING') {
    const dataOutput = {
      type: event,
      cardData: data,
      // liked : isLiked,
      buttonData: b_data
    };

    console.log('Fire event called : ', dataOutput);
    this.goToEvent.emit(dataOutput);
    // } else {
    //   return false;
    // }
  }

  updateOptions() {
    const options = this.contentOptionService.makeButtonsReady(this.cardItem);
    // const buttons = []
    this.buttons = [];
    // console.log('options', options)
    for (let opt of options) {
      if (opt.isVisible) {
        // let button = {
        //   text: opt.text,
        //   cssClass: opt.cssClass,
        //   icon: opt.icon,
        // }
        this.buttons.push(opt);
      }
    }

  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log('options', changes)
    if (changes && changes.cardItem) {
      this.updateOptions();
    }
    // if(changes)
  }

  buttonClicked(button, data) {
    console.log('Button clicked...', button);
    if (button.action) {
      this.fireEvent(button.action, data, button);
    } else {
      console.log('Button action not available...');
    }
  }

  optionClicked(event, data) {
    if (data.buttons) {
      const button = this.sharedService.getObjFromArray(data.buttons, event.id, 'action');
      if (button) {
        this.buttonClicked(button, data);
      } else {
        // this.fireEvent(event.id, data, null);
        this.fireEvent(event.id, event.value);
      }
    } else {
      // this.fireEvent(event.id, data, null);
      this.fireEvent(event.id, event.value);
    }
  }

}

export enum CardEnumEntity {
  addtocart = "addToCart",
  pin = "bookmark",
  schedule = "schedule",
}
