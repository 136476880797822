<div class="p-3">
    <ul class="p-0">
        <li class="d-flex flex-wrap align-items-center justify-content-between pb-2" *ngFor="let item of [1,2,3,4,5,6,7,8];let i = index">
            <h4 class="font-weight-normal">{{i}}. Rate our communication Skill.</h4>
            <star-rating [starType]="'svg'" (ratingChange)="onRatingChange($event)" [size]="'medium'" [rating]="3" staticColor="warning" [showHalfStars]="true" [numOfStars]="4"></star-rating>
        </li>
    </ul>
    <div class="text-right">
        <button class="button btn-outline">Save</button>
        <button class="button btn-fill ml-3">Publish</button>
    </div>

</div>