<!-- <section hidden>
    <div class="swiper-button-prev"></div>
    <div class="swiper-button-next"></div>
    <swiper-container>
        <swiper-slide navigation="true" pagination="true" scrollbar="false" pagination-type="fraction"
            *ngFor="let item of [0,1,2,3,4,5,6,7,8,9,10]; let i=index">
            <video class="w-100 h-100"
                src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
                controls></video>
            <img class="w-100 h-100" src="https://dummyimage.com/1340x400/000/fff.jpg&text=Carousal+banner" alt="image">
        </swiper-slide>
    </swiper-container>
</section> -->


<!-- <swiper-slide *ngFor="let slide of [1,2,3,4,5,6]" class="slides">
    <img class="w-100 h-100" src="https://dummyimage.com/1340x400/000/fff.jpg&text=Carousal+banner" alt="image">
</swiper-slide> -->

<section class="position-relative">
    <div class="swiper-button-prev"></div>
    <div class="swiper-button-next"></div>
    <!-- (init)="swiperReady()" (slidechange)="swiperSlideChanged($event)" (tap)="swiperSlideTapped($event)"
        (slidermove)="swiperSliderMoved($event)" (doubletap)="swiperSlidDoubleTapped($event)"
        (activeIndexChange)="swiperActiveIndexChanged($event)" (touchstart)="swiperSlidertouchStarted($event)"
        (touchend)="swiperSlidertouchReleased($event)" -->
    <swiper-container class="swiper-container" #swiperRef swiperElement [config]="config" init="false">
        <swiper-slide *ngFor="let banner of banners; let i=index">
            <ng-container *ngIf="banner.portalSrc">
                <ng-container *ngIf="banner?.outputType == 1">
                    <div (click)="swiperElement()">
                        <img id="image_{{banner?.id}}" class="w-100 h-100" [src]="banner?.portalSrc" alt="" srcset=""
                            (error)="errorMessage($event)">
                    </div>
                </ng-container>
                <ng-container *ngIf="banner?.outputType == 2">
                    <!-- autoplay controls muted-->
                    <div class="position-relative h-100">
                        <!-- [muted]="true" -->
                        <video id="video_{{banner?.id}}" class="object-fit-cover w-100 h-100" [src]="banner?.portalSrc"
                            #myvideo webkit-playsinline="true" playsinline="true"
                            (error)="errorMessage($event)"></video>
                        <span *ngIf="currentVideo && !displayError"
                            class="position-absolute bg-white border-radius-24px w-2rem h-2rem bottom-16-px right-16-px z-10 cursor-p"
                            (click)="muteUnmute()">
                            <svg-icon svgClass="stroke-black fill-white lh-0"
                                [svgStyle]="{ 'width.rem': 2, 'height.rem': 2 }" src="assets/icon-svg/Audio_icon.svg"
                                [hidden]="currentVideo.muted"></svg-icon>
                            <svg-icon svgClass="stroke-black fill-white lh-0"
                                [svgStyle]="{ 'width.rem': 1.5, 'height.rem': 1.5, 'stroke-width': .8, 'padding.px': 4 }"
                                src="assets/icon-svg/Audio_mute_icon.svg" [hidden]="!currentVideo.muted"></svg-icon>
                        </span>
                    </div>
                </ng-container>
            </ng-container>
        </swiper-slide>
    </swiper-container>

    <div class="d-flex">
        <div class="swiper-pagination"></div>
    </div>
    <div class="swiper-scrollbar"></div>
</section>