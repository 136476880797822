import { Component, OnInit, Input } from '@angular/core';
import { NbDialogRef } from "@nebular/theme";
@Component({
  selector: 'ngx-popupwithicon',
  templateUrl: './popupwithicon.component.html',
  styleUrls: ['./popupwithicon.component.scss']
})
export class PopupwithiconComponent implements OnInit {

  @Input() config: any = null;
  closeEvent = {
    action: "close",
    btnText: "",
    classList: ''
  };
  constructor(protected ref: NbDialogRef<PopupwithiconComponent>) { }

  ngOnInit(): void {
  }

}
