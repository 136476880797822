import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { webApi } from '../../../config';
import { CookieService } from '../cookie/cookie.service';
import { UserService } from '../userservice/user.service';
@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  private baseUrl = webApi.baseUrl;
  private headers = new HttpHeaders();

  private getLangugeListAPI = this.baseUrl + webApi.apiUrl.getLanguages;
  insertLanguageLogsUrl = this.baseUrl + webApi.apiUrl.insertLanguageLogs;

  private langCookieName = 'lang';

  constructor(
    public http: HttpClient,
    private cookieService: CookieService,
    private userService: UserService,
  ) { }

  getLanguageList(param) {
    return this.http.post(this.getLangugeListAPI, param)
      .toPromise()
      .then(response => {
        return response;
      }).catch(this.handleError);
  }


  private handleError(error: Response | any) {
    return Promise.reject(error.message || error);
  }

  removeLangugaeCookie() {
    this.cookieService.deleteCookie(this.langCookieName);
  }

  getLanguageCookie() {
    return this.cookieService.getCookies(this.langCookieName)
  }

  setLangugaeCookie(val) {
    let tdata = new Date(Date.now() + 3600 * 1000 * 24);
    let options = {
      expires: tdata,
      'max-age': 24000,
      // domain: 'devlearner.edgelearning.co.in'
    };
    const cookie = this.getLanguageCookie();
    if (val == 'english') {
      if (cookie) {
        this.removeLangugaeCookie();
        window.location.reload();
      }
    } else {
      this.cookieService.setCookies(this.langCookieName, val, options);
    }
  }

  /***************** insert language change logs start ***************/
  isJSONStrValid(str) {
    try {
      return JSON.parse(str);
    } catch (e) {
      console.log('isJSONStrValid error : ', e);
      return null;
    }
    // return true;
  }

  isLanguageEnabled() {
    try {
      // let languageEnabled = this.isJSONStrValid(localStorage.getItem('display_settings'))[4];
      // if (languageEnabled && languageEnabled.value == '1') {
      let languageEnabled = this.isJSONStrValid(localStorage.getItem('displaySettings'))['LanguageEnabled'];
      if (languageEnabled && languageEnabled == '1') {
        return true;
      }
      return false;
    } catch (error) {
      console.log('isLanguageEnabled error : ', error);
      return false;
    }
  }

  checkForLanguageChange() {
    let currentUserData = this.userService.getUserDetails();
    let languageEnabled = this.isLanguageEnabled();
    if (currentUserData && languageEnabled) {

      // let selectedLang = this.getLangugaeCookie() || 'English';
      let currentLang: any = localStorage.getItem('currentLang');
      if (currentLang) {
        currentLang = this.isJSONStrValid(currentLang);
        if (currentUserData.languageId) {
          if (currentLang && (currentUserData.languageId != currentLang.id)) {
            console.log('Log changed language...');
            this.saveLanguageChangeLogs(currentLang);
          } else {
            console.log('No need to log, Language not changed...');
          }
        } else {
          console.log('Log default language...');
          this.saveLanguageChangeLogs(currentLang);
        }
      }

    } else {
      console.log('Multi-lingual feature is not availabel...');
    }
  }

  saveLanguageChangeLogs(currentLang) {
    let params = {
      languageId: currentLang ? currentLang.id : 1,
      languageCode: currentLang ? currentLang.code : 'english',
      // platformId: this.updateService.getPlatformId(),
      platformId: 3,
      languageAccessedOn: currentLang ? currentLang.dateTime : new Date(),
    }

    this.updateLastLangLocally(params);
    localStorage.setItem('currentLang', '');

    this.insertLanguageLogs(params).then(
      (result) => {
        if (result.type === true) {
          console.log('logs inserted...', result);

          this.updateLastLangLocally(params);
          localStorage.setItem('currentLang', '');

        } else {
          console.log('err ', result);
        }
      },
      (err) => {
        console.log('err ', err);
      }
    );
  }

  updateLastLangLocally(params) {
    let currentUserData = this.userService.getUserDetails();
    currentUserData.languageId = params.langId;
    localStorage.setItem('currentUser', JSON.stringify(currentUserData));
  }
  /***************** insert language change logs end ***************/

  /***************** API's start ***************/
  public insertLanguageLogs(params: any): Promise<any> {
    return this.http.post(this.insertLanguageLogsUrl, params, { headers: this.headers })
      .toPromise()
      .then(response => {
        return response
      })
      .catch(this.handleError);
  }
  /***************** API's end ***************/

}
