import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeHtml',
})
export class RemoveHtmlPipe implements PipeTransform {

  transform(value: any, length: number = 92): any {
    if(value){
      let fb = value.replace(/<[^>]+>/g, '');
      fb = fb.replace(/&nbsp;/g, '');
      if(fb.length > length){
        return fb.substring(0, length);
      }else{
        return fb;
      }
    }
  }

}
