import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'ngx-more-drawer',
  templateUrl: './more-drawer.component.html',
  styleUrls: ['./more-drawer.component.scss']
})
export class MoreDrawerComponent implements OnInit {

  menuContent = [
    // {
    //   "title": "Tools",
    //   "icon": "file-text-outline",
    //   "link": "",
    //   "menuId": 186,
    //   "menuPlatform": 3,
    //   "roleId": 8,
    //   "identifier": "LDTOOLS",
    //   "show": true,
    //   "isShow": false,
    //   "active": false,
    //   "children": []
    // },
    // {
    //   "title": "Call coaching",
    //   "icon": "headphones-outline",
    //   "link": "",
    //   "menuId": 187,
    //   "menuPlatform": 3,
    //   "roleId": 8,
    //   "identifier": "LDCC",
    //   "show": true,
    //   "isShow": false,
    //   "active": false,
    //   "children": []
    // },
    // {
    //   "title": "Trainer as Leader",
    //   "icon": "people-outline",
    //   "link": "",
    //   "menuId": 188,
    //   "menuPlatform": 3,
    //   "roleId": 8,
    //   "identifier": "LDTTT",
    //   "show": true,
    //   "isShow": false,
    //   "active": false,
    //   "children": []
    // },
    // {
    //   "title": "Employee Engagement Program",
    //   "icon": "person-done-outline",
    //   "link": "",
    //   "menuId": 189,
    //   "menuPlatform": 3,
    //   "roleId": 8,
    //   "identifier": "LDEEP",
    //   "show": true,
    //   "isShow": false,
    //   "active": false,
    //   "children": []
    // }
  ];
  constructor(private router: Router,) { }

  ngOnInit(): void {
    this.makeTabDataReady();
  }

  goToPage(item) {
    // console.log('goToPage', path);
    if (item.externalLink) {
      // this.router.navigate([item?.redirectionLink]);
      window.open(item.externalLink, '_blank')
    } else {
      this.router.navigate([item?.link]);
    }

    return false
  }

  makeTabDataReady() {
    const roleList = JSON.parse(localStorage.getItem('userTabsByRole'));
    if (roleList && roleList.length) {
      this.menuContent = this.getSelectedRoleMenu(roleList, 'MORE');
      // this.tabsArr.push(this.newTab);
      // this.changeLearnComponent();
      // console.log(this.tabsArr);
    } else {
      this.menuContent = [

      ];
    }

    // for (let i = 0; i < this.tabsArr.length; i++) {
    //     console.log(this.router.url);
    //     const currentCompRoute = this.router.url.substring(this.router.url.lastIndexOf('/') + 1);
    //     if (currentCompRoute === this.tabsArr[i].name) {
    //         this.currentSelectedTab = this.tabsArr[i].name;
    //     }
    // }

    // const usrData = JSON.parse(localStorage.getItem('currentUser'));
    // if (this.currentSelectedTab === 'home') {
    //     this.events.publish('user:login', usrData);
    // }
  }
  getSelectedRoleMenu(roleList, identifier) {
    for (let i = 0; i < roleList.length; i++) {
      //   if(roleList.length === 1 && roleList[i].roleId === 7){
      //     this.router.navigateByUrl['/tabs/train'];
      //   }
      //   if(roleList.length === 1 && roleList[i].roleId === 8){
      //     this.router.navigateByUrl['/tabs/home'];
      //   }
      //   if(roleList.length === 1 && roleList[i].roleId === 5){
      //     this.router.navigateByUrl['/tabs/employeeList'];
      //   }
      if (roleList[i].status === true) {
        const menuList = roleList[i].menu;
        if (menuList) {
          for (let index = 0; index < menuList.length; index++) {
            const element = menuList[index];
            if (element['identifier'] == identifier) {
              return element['children'];
              // return subMenuList;
            }
          }
        }

      }

    }
    return [];
  }
}
