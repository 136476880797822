import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { webApi } from "../../../config";
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class SearchService {
  private headers = new HttpHeaders();
  baseUrl: any = webApi.baseUrl;
  // dataUrl = 'assets/data/menus.json';


  // getContentOptionDetailsUrl = this.baseUrl + webApi.apiUrl.getContentOptionDetails;
  // { "areaId": { "gte": "34" } }
  // searchParams = {
  //   // "query": {
  //   "searchString": "jaydeep", // keyword
  //   "filterTermData": [], // filter with checkbox or radio button
  //   "filterRangeData": [], // filter with range data
  //   "from": 0, // starting point from where you want the document helpful for pagination
  //   "size": 6 // total number of documents you want at a time.
  //   // }
  // };

  breadcrumbsList = [];
  private dataSource = new BehaviorSubject(null);
  filterObservable = this.dataSource.asObservable();
  // searchDomain ='';
  // recommdationDomain = webApi.recommdationUrl;

  searchApi = webApi.apiUrl.searchApiURL;
  getCategoryListUrl = this.baseUrl + webApi.apiUrl.getCategoryList;
  searchSuggestionApi = webApi.apiUrl.autoSuggestionURL;
  private dataSource2 = new BehaviorSubject(null);
  categoryObservable = this.dataSource2.asObservable();


  categoryList: any = [];
  constructor(private http: HttpClient) {
    // this.searchDomain = decodeURI(JSON.parse(localStorage.getItem('searchDomain')));
    // this.searchApi = + webApi.apiUrl.searchApiURL;
    // searchSuggestionApi = this.searchDomain + webApi.apiUrl.searchApiURL;
    // this.getCategoryListUrl = this.baseUrl + webApi.apiUrl.getCategoryList;
    // this.searchSuggestionApi = this.searchDomain + webApi.apiUrl.autoSuggestionURL;
  }

  setCategory(list) {
    this.categoryList = list;
  }
  getCategory() {
    return this.categoryList;
  }
  public getCategoryList(data: any): Promise<any> {
    return this.http.post(this.getCategoryListUrl, data, { headers: this.headers })
      .toPromise()
      .then(response => {
        return response = response;
      })
      .catch(this.handleError);

  }


  public getSearchResults(data: any): Promise<any> {
    // this.searchParams.from = data.pageNo;
    // this.searchParams.size = data.pageLimit;
    const domain = decodeURI(JSON.parse(localStorage.getItem('searchDomain')));
    this.searchApi = domain + webApi.apiUrl.searchApiURL;
    const recommdationDomain = decodeURI(JSON.parse(localStorage.getItem('recommendationDomain')));
    let recommdationApi = recommdationDomain + webApi.apiUrl.recommdationSeeAllURL;
    let url = '';
    if (data.recommdation) {
      url = recommdationApi;
    } else {
      url = this.searchApi;
    }
    return this.http.post(url, data, { headers: this.headers })
      .toPromise()
      .then(response => {
        return response = response;
      })
      .catch(this.handleError);
  }

  // public getSearchResults(data: any): Observable<any> {
  //   this.searchParams.query.from = data.pageNo;
  //   this.searchParams.query.size = data.pageLimit;
  //   return this.http.post(this.searchApi, this.searchParams, { headers: this.headers }).pipe(
  //     switchMap(),
  //   );
  // }

  public getSearchSuggestionResults(data: any): Promise<any> {
    // this.searchParams.from = data.pageNo;
    // this.searchParams.size = data.pageLimit;
    const domain = decodeURI(JSON.parse(localStorage.getItem('searchDomain')))
    return this.http.post(domain + this.searchSuggestionApi, data, { headers: this.headers })
      .toPromise()
      .then(response => {
        return response = response;
      })
      .catch(this.handleError);
  }
  private handleError(error: Response | any) {
    return Promise.reject(error.message || error);
  }

  updatedDataSelection(data) {
    this.dataSource.next(data);
  }

  getFilterObservable() {
    return this.filterObservable;
  }

  updateCategory(data) {
    this.dataSource2.next(data);
  }

  getCategoryListData() {
    return this.categoryObservable;
  }

  // public getContentOptionDetails(data: any): Promise<any> {
  //   return this.http.post(this.getContentOptionDetailsUrl, data, { headers: this.headers })
  //     .toPromise()
  //     .then(response => {
  //       return response = response;
  //     })
  //     .catch(this.handleError);
  // }
}
