<div class="stepper-section" [ngClass]="orientation">
  <div class="inner-step select-step" [ngClass]="{ 'select-step': complete }"
    *ngFor="let item of stepper;let i = index;">
    <div class="steps">
      <div class="step-index">
        <span *ngIf="number" class="number">{{ i + 1 }}</span>
        <span *ngIf="!number" class="circle"></span>
      </div>
    </div>
    <div class="joinner"></div>
  </div>
</div>
