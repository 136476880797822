import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toFixed'
})
export class ToFixedNumberPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let formattedNumber: any = '';
    if(!isNaN(value)) {
        if(Number.isInteger(Number(value))) { 
            formattedNumber = Number(value); 
        } else{
            formattedNumber = Number(value).toFixed(2);
        }
    } else {
        formattedNumber = value;
    }

    return formattedNumber;
  }

}
