import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'ngx-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  @Input() notFound: any;
  @Output() getEvent = new EventEmitter();

  public searchControl: UntypedFormControl;
  searchCtrlSub: Subscription;

  searchString: any = '';
  searchTerm: any = '';

  constructor() { }

  ngOnInit(): void {
    this.searchControl = new UntypedFormControl();
    this.initFormControl();
    this.toggleSearchBar();
  }

  initFormControl() {
    if (this.searchCtrlSub) {
      this.searchCtrlSub.unsubscribe();
    }
    this.searchCtrlSub = this.searchControl.valueChanges.pipe(debounceTime(700)).subscribe(search => {
      console.log('Search term : ', search);
      // this.searchTerm = search;
      this.onSearchInput(search);
    });
  }

  setValueForSearch(value, obj) {
    if (this.searchControl) {
      this.searchControl.setValue(value, obj);
    }
  }

  /************* search content start ***********/
  onSearchEnter(searchTerm) {
    this.searchTerm = searchTerm;
    console.log('Enter key pressed...', this.searchTerm);
    if (this.searchTerm.length !== 0) {
      this.triggerSearch(this.searchTerm);
    }
  }

  onSearchInput(searchEv) {
    // console.log('onSearchInput...', searchEv.detail.value);
    // this.searchTerm = searchEv.detail.value;
    console.log('onSearchInput...', searchEv);
    this.searchTerm = searchEv;
    // if (this.searchTerm.length >= 3 || (this.searchTerm.length == 0 && this.notFound)) {
    //   this.triggerSearch(this.searchTerm);
    // }
    if (this.searchTerm.length >= 3) {
      this.triggerSearch(this.searchTerm);
    } else if (this.searchTerm.length == 0) { //  && this.notFound
      this.cancelSearch();
    }
  }

  triggerSearch(searchCourseTerm) {
    this.fireEvent('triggerSearch', this.searchTerm);
  }

  toggleSearchBar() {
    if (this.searchTerm === '') {
      this.searchString = 'Search...';
    }
  }

  cancelSearch() {
    this.setValueForSearch("", { emitEvent: false });
    this.fireEvent('cancelSearch', this.searchTerm);
    this.emptySearch();
  }

  emptySearch() {
    this.searchTerm = '';
    console.log('empty searchCourseTerm ', this.searchTerm);
  }
  /************* search content end ***********/

  fireEvent(event, data) {
    const dataOutput = {
      type: event,
      actionData: data,
    };
    console.log('Search action...', dataOutput);
    this.getEvent.emit(dataOutput);
  }

  ngOnDestroy() {
    // debugger
    this.searchCtrlSub.unsubscribe();
  }

}
