import { Injectable } from "@angular/core";
import { feature } from "../../../environments/feature-enviroment";
@Injectable({
  providedIn: "root",
})
export class BrandDetailsService {
  featureConfig = feature;
  constructor() { }

  //   brandObject = {
  //       ILLUME : {
  //         logo: 'assets/images/BajajFinserv_Logo.png',
  //         banner_image: 'assets/images/banner_new.jpg',
  //         dashboard_logo: 'assets/images/ILLUME-logo.png',
  //         tagLine: 'Login as employee, click on your organization ',
  //         termsandcondition: 'https://www.bajajfinserv.in/privacy-policy',
  //         brandName: 'ILLUME',
  //         userNameTerm: 'ECN/Username',
  //     },
  //     Edge : {
  //       logo: 'assets/images/Edge/edge-logo.png',
  //       banner_image: 'assets/images/Edge/dashbord_baner.png',
  //       dashboard_logo: 'assets/images/Edge/edge-logo.png',
  //       tagLine: 'Learning in the flow of life',
  //       termsandcondition: 'https://www.edgelearning.co.in/pages/privacy-policy.html',
  //       brandName: 'Edge',
  //       userNameTerm: 'Email',
  //   },
  // }

  brandObject = {
    ILLUME: {
      logo: "https://bhaveshedgetest.s3.ap-south-1.amazonaws.com/ILLUME-logo.png",
      // banner_image: "assets/images/banner_new.jpg",
      dashboard_logo: "https://bhaveshedgetest.s3.ap-south-1.amazonaws.com/ILLUME-logo.png",
      tagLine: "Login as employee, click on your organization ",
      termsandcondition: "https://www.bajajfinserv.in/privacy-policy",
      loginImage: "../../../assets/images/ILLUME-signin.jpg",
      brandName: "ILLUME",
      showLoginText: true,
      userNameTerm: "ECN/Username",
      oneSignal: {
        dev: {
          appId: "fb8ba139-e430-4b0e-b702-ef5ecbbbbd5f",
          safari_web_id:
            "web.onesignal.auto.1cc5c601-0ab1-487a-96d1-0b685be1e018",
        },
        prod: {
          appId: "fd7ac105-0e6e-43be-b7b8-9560ed3dada3",
          safari_web_id:
            "web.onesignal.auto.4ddec2dc-5c48-40c7-bde8-da7159bee241",
        },
      },
    },
    EDGE: {
      logo: "assets/images/Edge/edge-logo.png",
      // banner_image: "assets/images/Edge/dashbord_baner.png",
      dashboard_logo: "assets/images/Edge/edge-logo.png",
      tagLine: "Learning in the flow of life",
      loginImage: "assets/images/Edge/ILLUME-signin.jpg",
      termsandcondition:
        "https://www.edgelearning.co.in/pages/privacy-policy.html",
      brandName: "Edge",
      showLoginText: true,
      userNameTerm: "Email",
      oneSignal: {
        dev: {
          appId: "fb8ba139-e430-4b0e-b702-ef5ecbbbbd5f",
          safari_web_id:
            "web.onesignal.auto.1cc5c601-0ab1-487a-96d1-0b685be1e018",
        },
      },
    },
  };
  getCurrentBrandData() {
    const currentPageURL = window.location.hostname;
    console.log("currentPageURL", currentPageURL);
    let data;
    // if(currentPageURL.indexOf('saas') !== -1){
    //   data = this.brandObject['Edge'];
    // }else {
    //   data =  this.brandObject['ILLUME'];
    // }
    // data =  this.brandObject['ILLUME'];

    switch (String(this.featureConfig.brandName).toUpperCase()) {
      case "EDGE":
        data = this.brandObject["EDGE"];
        break;
      case "ILLUME":
        data = this.brandObject["ILLUME"];
        break;
      default:
        data = this.brandObject["ILLUME"];
        break;
    }

    // let subDomain = '';
    // let hostName = window.location.hostname;
    //  hostName = "coachkhoj.edgelearning.co.in/";
    // if (hostName !== 'localhost') {
    //   const splited = hostName.split('.');
    //   if (splited && splited.length !== 0) {
    //     subDomain = splited[0];
    //     if((String(subDomain).toLowerCase()).indexOf('coachkhoj') !== -1){
    //       data['loginImage'] = 'assets/images/coachkhoj/login.jpg';
    //       data['banner_image'] = 'assets/images/coachkhoj/dashbord_baner.jpg';
    //       data['showLoginText'] = false;
    //     }
    //   }
    // }

    console.log("Brand Data -==>", data);
    return data;
  }
}
