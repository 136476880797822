import { Component, OnInit } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { debounceTime } from 'rxjs/operators';
// import { Subscription } from "rxjs";
import { SearchService } from '../../../../providers/search/search.service';
import { ToasterService } from "../../../../providers/toaster/toaster.service";
import { Router, NavigationEnd, Event, ActivatedRoute } from '@angular/router';
import { EventsService } from '../../../../providers/events/events.service';
import { Location } from "@angular/common";

@Component({
  selector: "ngx-header-search",
  templateUrl: "./header-search.component.html",
  styleUrls: ["./header-search.component.scss"],
})
export class HeaderSearchComponent implements OnInit {
  categoryList: any = [];
  loading = false;
  notFound = false;
  showDropDown: any = false;
  innerArray: any = [];
  catIdArray: any = [];
  public searchControl: UntypedFormControl;
  innerItem: any = [];
  levelUp: boolean = false;
  parentCat: any = {};
  selectedCatArr: any = [];
  searchTerm = "";
  suggestionEnable = false;
  configDropdownList = {
    label: 'field_name',
  };
  breadcrumbsList = [];
  currentUserData: any;
  constructor(private searchService: SearchService,
    private route: ActivatedRoute,
    private eventsService: EventsService,
    private toastController: ToasterService, private router: Router,
    private location: Location,) {

  }
  suggestionList: string[] = [

  ];
  // languageChangeSubscriber = Subscription.EMPTY;
  ngOnInit(): void {
    this.searchControl = new UntypedFormControl();
    this.getCategories();
    this.initFormControl();
    // this.assignValueToSearch();
    this.router.events.subscribe((event: Event) => {
      // if (event instanceof NavigationStart) {
      //     // Show loading indicator
      // }
      // console.log('event', event);
      if (event instanceof NavigationEnd) {
        // Hide loading indicator
        // console.log('event', event);
        setTimeout(() => {
          this.focusFunction(false);
        }, 700)
        if (event['urlAfterRedirects'].indexOf('pages/search-result') == -1) {
          this.resetSearchData();
          this.resetCategoryList();
        } else {
          // this.bindSearchTerm(this.route.snapshot.queryParamMap.get('query'));
        }
        this.closeDropDown();
      }


    });
    this.eventsService.subscribe('intro:Page', (flag) => {
      // this.toggelPopup(flag);
      // this.showDropDown = flag;
      console.log('intro:openProfile')
    });
    this.currentUserData = JSON.parse(localStorage.getItem('currentUser'));
    this.route.queryParams.subscribe((queryParams: any) => {
      console.log('query params', queryParams);
      this.bindSearchTerm(queryParams['query']);
      // console.log('query params', queryParams);
    });
    // this.languageChangeSubscriber = this.eventsService.subscribe(
    //   "seaerch:languageChange",() =>{

    //   });
    /***** clear search when clicked on breadcrumb *****/
    // this.eventsService.subscribe('search:Clear', (flag) => {
    //   console.log('search:Clear');
    //   // this.assignValueToSearch();
    //   this.setValueForSearch("", { emitEvent: false });
    //   // this.router.navigate(
    //   //   // [],
    //   //   ['.'],
    //   //   {
    //   //     relativeTo: this.route,
    //   //     queryParams: {},
    //   //     queryParamsHandling: 'merge', // remove to replace all query params by provided
    //   //   });
    //   // this.location.replaceState('/#/pages/search-result');
    //   this.goToSearchPage({});
    // });
  }

  toggelDropdown() {
    this.showDropDown = !this.showDropDown;
    // for (let index = 0; index < this.categoryList.length; index++) {
    //   const element = this.categoryList[index];
    //   element['activeFlag'] = false;
    // }
  }

  closeDropDown() {
    if (this.showDropDown) {
      this.showDropDown = false;
    }
  }

  /**************************** Category Breadcrumbs ****************************/


  getCategories() {
    // if (this.networkservice.getOnlineStatus()) {
    const params = {
    };
    // this.load.presentLoading('Please wait...');
    this.loading = true;
    this.searchService.getCategoryList(params).then(
      (res) => {
        // this.load.dismiss();
        this.loading = false;
        if (res.type === true) {
          console.log('getCategories res', res);
          this.categoryList = res.data;
          this.searchService.setCategory(this.categoryList);
          if (this.categoryList.length == 0) {
            this.notFound = true;
          }
        }
      },
      (err) => {
        // this.load.dismiss();
        this.loading = false;
        console.log(err);
        this.presentToastMessage('Unable to get preferences at this time, Please try again');
      }
    );

    //  else {
    //   this.presentToastMessage('Unable connect to the server at this time, Please check your internet connection and try again', 1);
    // }
  }
  performAction(data) {
    console.log("Category selected!", data);
    let breadcrumbsList = [];

    if (data) {
      let catValue;
      if (data.field_value) {
        catValue = data.field_key + '_' + data.field_value;
      } else {
        catValue = data.field_key;
      }
      let breadCrumbs = {
        field_id: data.field_id,
        field_key: data.field_name,
        field_name: data.field_name,
        field_value: data.field_value,
        lov_type_id: data.lov_type_id,
        lov_type_key: data.lov_type_key,
        parent_field_id: data.parent_field_id,
        value_name: data.value_name,
        catFilterValue: catValue,
        catFilterKey: data.filter_on,
      }
      breadcrumbsList = data['breadcrumbs'] ? data['breadcrumbs'] : [];
      breadcrumbsList.push(breadCrumbs);

    }

    // this.searchService.breadcrumbsList = breadcrumbsList;
    this.breadcrumbsList = breadcrumbsList;
    this.searchService.updateCategory(breadcrumbsList);
    let queryParams = {};
    // this.setValueForSearch("", { emitEvent: false });
    if (this.searchTerm != '') {
      queryParams = {
        query: this.searchTerm,
      }
    }
    this.goToSearchPage(queryParams);
    this.closeDropDown();
  }

  /**************************** Category Breadcrumbs End ****************************/

  /**************************** Search Start ****************************/
  clearSearch() {
    this.searchTerm = '';
    // // this.searchOnPage(this.searchTerm);
    // if(this.breadcrumbsList && this.breadcrumbsList.length !=0){

    // }else {

    // }
    // this.setValueForSearch("", { emitEvent: true });
    // this.prepareSearchData("");
    // this.goBack();;

    let obj = {
      // query: "",
    }
    this.setValueForSearch("", { emitEvent: false });
    this.goToSearchPage(obj);
  }

  setValueForSearch(value, obj) {
    if (this.searchControl) {
      this.searchControl.setValue(value, obj);
    }
  }

  goBack() {
    // window.history.back();
    const url = localStorage.getItem('searchTriggredFrom');
    if (url) {
      this.router.navigateByUrl(url);
      localStorage.removeItem('searchTriggredFrom');
    } else {
      window.history.back();
    }
  }

  initFormControl() {
    this.searchControl.valueChanges.pipe(debounceTime(700)).subscribe(search => {
      // this.setFilteredItems();
      console.log('Search term : ', search);
      this.searchTerm = search;
      // if (this.searchTerm.length >= 3) {
      // this.searching = true;
      this.searchOnPage(search);
      // } else if (this.searchTerm.length == 0) {
      //   // this.searching = true;
      //   // this.searchOnPage(search);
      //   this.goBack();
      // }
      // this.searchOnPage(search);
    });
  }

  searchOnPage(event) {
    console.log('searchOnPage', event);
    const params = {
      "searchString": this.searchTerm,
      // tenantId: this.currentUserData.tenantId,
      eBand: this.currentUserData.band,
      Go1UserAccess: this.currentUserData.Go1UserAccess ? this.currentUserData.Go1UserAccess : 0,
      // empId: this.currentUserData.eid
    }
    this.searchService.getSearchSuggestionResults(params).then(
      (res) => {
        console.log('getSearchSuggestionResults', res);
        if (res && res['autoSuggestedData'] && Array.isArray(res['autoSuggestedData']) && res['autoSuggestedData'].length != 0) {
          this.suggestionList = res['autoSuggestedData'];
          this.suggestionEnable = true;
        } else {
          this.suggestionList = [];
          this.suggestionEnable = false;
        }
      },
      (err) => {
        console.log('err ', err);
        // this.presentToastMessage('Unable to get results at this time.', 1);
      }
    ).catch(
      (err) => {
        console.log('err ', err);
        // this.presentToastMessage('Unable to get results at this time.', 1);
      }
    );
  }

  focusFunction(event) {
    this.suggestionEnable = event;
  }

  /**************************** Search End ****************************/

  async presentToastMessage(msg) {
    // const toast = await this.toastController.create({
    //   message: msg,
    //   duration: 2000,
    //   // showCloseButton: true,
    //   // position: 'top',
    //   // closeButtonText: 'Done'
    // });
    // toast.present();
    this.toastController.prsentToast(msg, "Warning", "warning", null);
  }

  goToSearchPage(data) {
    if (String(this.router.url).indexOf('pages/search-result') === -1) {
      // localStorage.setItem('searchTriggredFrom', this.router.url);
      // this.resetCategoryList();
    }
    this.router.navigate(['pages/search-result'], { queryParams: data });
    this.suggestionList = [];
    this.suggestionEnable = false;
    this.closeDropDown();
  }

  prepareSearchData(value) {
    console.log('Search term', value);
    if (value && String(value).trim() != '') {
      let obj = {
        query: value,
      }
      if (String(this.router.url).indexOf('pages/search-result') === -1) {
        // localStorage.setItem('searchTriggredFrom', this.router.url);
        this.resetCategoryList();
      }
      this.setValueForSearch(value, { emitEvent: false });
      this.goToSearchPage(obj);
    } else {
      console.log('Search term is empty');
    }
  }

  resetSearchData() {
    localStorage.removeItem('searchTriggredFrom');
    this.searchTerm = '';
    // // this.searchOnPage(this.searchTerm);
    this.setValueForSearch("", { emitEvent: false });
    // this.resetCategoryList();
  }

  resetCategoryList() {
    this.searchService.breadcrumbsList = [];
    this.searchService.updateCategory([]);
    const filterDataObject = {
      rangeArray: [],
      termArray: [],
      fieldDateRange: [],
    };
    this.searchService.updatedDataSelection(filterDataObject);
  }

  bindSearchTerm(term) {
    console.log('bindSearchTerm', term);
    if (this.searchTerm != term && term) {
      this.searchTerm = term;
      this.setValueForSearch(term, { emitEvent: false });
    }
  }

  closeEvent(event) {
    // console.log('clickElseWhere', event);
    // if (this.showDropDown) {
    this.closeDropDown();
    // }
    if (this.suggestionEnable) {
      this.suggestionEnable = false;
    }
  }

  assignValueToSearch() {

    if (String(this.route.url).indexOf('pages/search-result')) {
      this.bindSearchTerm(this.route.snapshot.queryParamMap.get('query'));
    }
  }
}
