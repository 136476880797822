import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { webApi } from "../../../config";

@Injectable({
  providedIn: 'root'
})
export class AssignNotifyService {
  private baseUrl: any = webApi.baseUrl;
  private getNotificationEventListUrl = this.baseUrl + webApi.apiUrl.getNotificationEventList;
  private getNotificationCCListUrl = this.baseUrl + webApi.apiUrl.getNotificationCCList;
  constructor(public http: HttpClient) { }

  private config = {
    // api: webApi.baseUrl + webApi.apiUrl.getNotificationEventList,
    // localFilterParams: ['instanceName', 'instanceType'],
    // noDataContainerMessage: '',
    // searchPlaceholder: 'Search Content',
    params: {
      // searchString: '', // keyword
      eventIds: '132,133,134',
      instanceId: null,
      areaId: '',
      type: 1,
      selectedUserIds: '',
      "instanceName": null,
      "starDate": null,
      "endDate": null,
      // "areaId": this.courseContentDetails.instanceDetails.areaId,
      "dataString": null
    },
    // submitAPI: webApi.baseUrl + webApi.apiUrl.assignContent,
    // pageParamName: 'pageNo',
    // searchStringParamName: 'searchString',
    // dataLimit: '50',
    // showFooter: true,
    // type: 'manageraAddContent',
  };

  getNotificationEvents(param) {
    // const param = this.populateActivityCompletion(activity);
    return this.http
      .post(this.getNotificationEventListUrl, param)
      .toPromise()
      .then((response): any => {
        return (response = response);
      })
      .catch(this.handleError);
  }

  getNotificationCCList(param) {
    // const param = this.populateActivityCompletion(activity);
    return this.http
      .post(this.getNotificationCCListUrl, param)
      .toPromise()
      .then((response): any => {
        return (response = response);
      })
      .catch(this.handleError);
  }


  getPopupConfig() {
    return { ...this.config }
  }

  private handleError(error: Response | any) {
    return Promise.reject(error);
  }
}
