import { Component, OnInit, Input, ElementRef, ViewEncapsulation, HostListener } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
@Component({
  selector: 'app-read-more',
  templateUrl: './read-more.component.html',
  styleUrls: ['./read-more.component.scss'],
  // encapsulation: ViewEncapsulation.None,
})
export class ReadMoreComponent implements OnInit {

  @Input() text: string;
  @Input() maxLength: number = 100;
  currentText: string;
  // hideToggle: boolean = true;
  // public isCollapsed: boolean = true;
  hideToggle: boolean;
  public isCollapsed: boolean
  // @HostListener('click', ['$event']) onClick(event) {
  //   // console.log('component is clicked');
  //   // console.log(event);
  //   if (event) {
  //     event.stopPropagation()
  //   }
  // }

  @Input() type: number;

  constructor(private elementRef: ElementRef, private sanitizer: DomSanitizer,) {

  }

  ngOnInit() {
    console.log('read more', this.text)
  }

  toggleView(event) {
    if (event) {
      event.stopPropagation();
    }
    this.isCollapsed = !this.isCollapsed;
    this.determineView();
  }

  determineView() {
    if (!this.text || this.text.length <= this.maxLength) {
      this.currentText = this.text;
      this.isCollapsed = false;
      this.hideToggle = true;
      return;
    }
    this.hideToggle = false;
    if (this.isCollapsed == true) {
      const html = this.text;
      const div = document.createElement("div");
      div.innerHTML = html;
      this.currentText = div.innerText.substring(0, this.maxLength) + "...";
      // document.removeChild(div);
    } else if (this.isCollapsed == false) {
      this.currentText = this.text;
    }
  }

  makeHtmlReady(htmltext) {
    // return this.sanitizer.bypassSecurityTrustHtml(htmltext);
    if (this.type && this.type == 1) {
      return htmltext;
    } else {
      return this.sanitizer.bypassSecurityTrustHtml(htmltext);
    }
  }

  ngOnChanges() {
    this.isCollapsed = true;
    this.determineView();
  }

  // @Input() content: string;
  // @Input() limit: number;
  // @Input() completeWords: boolean;

  // isContentToggled: boolean;
  // nonEditedContent: string;

  // constructor() {

  // }

  // ngOnInit() {
  //   console.log(this.content);
  //   this.nonEditedContent = this.content;
  //   this.content = this.formatContent(this.content);
  // }

  // toggleContent() {
  //   this.isContentToggled = !this.isContentToggled;
  //   this.content = this.isContentToggled ? this.nonEditedContent : this.formatContent(this.content);
  // }

  // formatContent(content: string) {
  //   let addDots = '';
  //   if (this.nonEditedContent.length > this.limit) {
  //     addDots = '...';
  //   }
  //   if (this.completeWords) {

  //     this.limit = content.substr(0, this.limit).lastIndexOf(' ');
  //   }
  //   return `${content.substr(0, this.limit)}${addDots}`;
  // }

}
