import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ngx-custom-model',
  templateUrl: './custom-model.component.html',
  styleUrls: ['./custom-model.component.scss']
})
export class CustomModelComponent implements OnInit {
  @Output() closeClick = new EventEmitter<any>();
  @Input() show = false;
  
  constructor() { }

  ngOnInit(): void {
  }

  close() {
    this.show = false;
  }
  
}
