import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ngx-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent implements OnInit {
  @Input() label;
  @Input() data;
  oldValue = '';
  setting = {
    type: 'number',
    max: 999,
    min: 0
  }
  constructor() { }

  ngOnInit(): void {
    if (typeof this.label.componentAttribute === "string" && this.label.componentAttribute) {
      this.label.componentAttribute = JSON.parse(this.label.componentAttribute);
    }
  }

  checkForInputValidation(value) {
    // console.log('checkForInputValidation', this.oldValue);
    // console.log('checkForInputValidation', Number(this.data[this.label.bindingProperty]));
    // console.log('Number', Number.isInteger(Number(this.data[this.label.bindingProperty])));
    // console.log('Number check', parseInt(this.data[this.label.bindingProperty]));
    // console.log('Number check', Number.isInteger(parseInt(this.data[this.label.bindingProperty])));
    if (this.label.componentAttribute.type == 'number') {
      if (value == '') {
        this.oldValue = this.data[this.label.bindingProperty];
        this.data[this.label.bindingProperty] = '';
      } else if (!Number.isInteger(Number(this.data[this.label.bindingProperty]))) {
        this.setResetData();
      } else if (this.label.componentAttribute.min > Number(value)) {
        this.setResetData();
      } else if (this.label.componentAttribute.max < Number(value)) {
        this.setResetData();
      } else {
        this.oldValue = this.data[this.label.bindingProperty];
      }
    }
    // console.log('Number', this.data[this.label.bindingProperty]);
  }

  setResetData() {
    setTimeout(() => {
      this.data[this.label.bindingProperty] = this.oldValue;
    })
  }
}
