import { HttpClient } from '@angular/common/http';
import { ApplicationRef, Injectable, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription, fromEvent, interval, merge, of } from 'rxjs';
import { filter, skipWhile, switchMap, take, tap } from 'rxjs/operators';
import { webApi } from '../../../../../../config';
import { AlertService } from "../../../../../providers/alert/alert.service";
import { EventsService } from '../../../../../providers/events/events.service';
import { LoginService } from "../../../../../providers/login/login.service";
import { ToasterService } from "../../../../../providers/toaster/toaster.service";
@Injectable({
  providedIn: 'root'
})
export class CheckInactivityService implements OnInit, OnDestroy {
  // here we need to give the time in second ie how long we want the inactivity timer default i have kept as 5 sec
  private inactivityTime: number = 15;
  private inactivityAlertTime: number = 15;
  private timeLapsedSinceInactivity: number = 0;
  private minute: number = this.padZero(0);
  private seconds: number = this.padZero(0);
  private subscription: Subscription;
  private observable$: Observable<any>;
  private mergedObservable$: Observable<any>;
  public inactivityTimerEvent: Array<any>[] = [
    [document, 'click'],
    [document, 'wheel'],
    [document, 'scroll'],
    [document, 'mousemove'],
    [document, 'keyup'],
    [window, 'resize'],
    [window, 'scroll'],
    [window, 'mousemove'],
    [document, 'touchstart'],
    [window, 'touchstart'],
    [window, 'load'],
  ];
  remTime: any = 0;
  sessionTimeoutMsg = "Your session will expire in $remTime.\n\n Please click 'Continue' to stay active or click 'Sign out' to end your session now";
  popupConfig = {
    title: `Session Timeout`,
    // or click 'Sign out' to end your session now
    message: `Please click 'Continue' to stay active`,
    buttons: [
      {
        id: "continue",
        btnText: "Continue",
      },
      // {
      //   id: "logout",
      //   btnText: "Log Out",
      // },
    ],
  };
  currentUserData: any;
  currentUserLastAccess: any;

  baseUrl: any = webApi.baseUrl;
  saveLastAccessUrl: any = this.baseUrl + webApi.apiUrl.saveLastAccess;
  isAlertPresented: boolean = false;

  isTokenRefreshing = false;

  constructor(public ngZone: NgZone,
    public applicationRef: ApplicationRef,
    // public cdf: ChangeDetectorRef,
    private alertService: AlertService,
    private toastController: ToasterService,
    private loginService: LoginService,
    private events: EventsService,
    public http: HttpClient,
  ) {
    // this.resetSubscription();
    // this.createObservable();
    console.log('CheckInactivityService constructor called...');
  }

  ngOnInit() {
    console.log('CheckInactivityService ngOnInit called...');
    this.events.subscribe('check-inactivity:stop', (status) => {
      this.stopTimer(null);
    });

    this.events.subscribe('check-inactivity:update', (updatedUserDetails) => {
      this.updateTimer(updatedUserDetails);
    });
  }


  updateTimer(currentUserData) {
    this.currentUserData = JSON.parse(this.currentUserData);
    this.setInactivityTime(this.currentUserData.inactiveLogoutTime, this.currentUserData.inactiveAlertTime);
  }
  resetSubscription() {
    this.subscription = null;
    console.log('subscription  set to null');
    this.subscription = new Subscription();
    console.log('subscription   ========>>> ' + this.subscription);
  }
  // resetValues() {
  //   this.getUserData();
  //   // this.setInactivityTime(30, 15);
  //   // this.setInactivityTime(1800, 900);

  //   const observableArray$: Observable<any>[] = [];
  //   this.inactivityTimerEvent.forEach(x => {
  //     observableArray$.push(fromEvent(x[0], x[1]));
  //   });
  //   this.mergedObservable$ = merge(...observableArray$);

  //   // this.createObservable();
  //   this.startTimer(null);
  // }

  resetValues() {
    this.stopTimer(null);
    this.resetSubscription();
    // this.subscription = new Subscription();
    // this.currentUserLastAccess = null;
    const observableArray$: Observable<any>[] = [];
    this.inactivityTimerEvent.forEach(x => {
      observableArray$.push(fromEvent(x[0], x[1]));
    });
    this.mergedObservable$ = merge(...observableArray$);

    this.getUserData();
    // this.setInactivityTime(30, 15);
    // this.setInactivityTime(1800, 900);
  }

  // getUserData() {
  //   this.currentUserData = localStorage.getItem('currentUser');
  //   if (this.currentUserData) {
  //     // this.setInactivityTime(this.currentUserData.inactivityTime, this.currentUserData.inactivityAlertTime);
  //     this.setInactivityTime(this.currentUserData.inactiveLogoutTime, this.currentUserData.inactiveAlertTime);
  //   } else {
  //     this.setInactivityTime(1800, 900);
  //   }
  // }

  getUserData() {
    this.currentUserData = localStorage.getItem('currentUser');
    if (this.currentUserData) {
      this.currentUserData = JSON.parse(this.currentUserData);
      this.setInactivityTime(this.currentUserData.inactiveLogoutTime, this.currentUserData.inactiveAlertTime);
      // this.setInactivityTime(30, 10);
      this.startTimer(null);
    } else {
      // this.setInactivityTime(1800, 900);
      this.stopTimer(null);
      console.log('User not available...');
    }
  }

  setInactivityTime(inactivityTime, inactivityAlertTime) {
    this.inactivityTime = Number(inactivityTime);
    this.inactivityAlertTime = Number(inactivityAlertTime);
  }

  private createObservable(): void {
    this.ngZone.runOutsideAngular(() => {
      this.observable$ = this.mergedObservable$.pipe(
        filter(ev => this.isAlertPresented == false),
        switchMap(ev => interval(1000).pipe(take(this.inactivityTime))),
        tap(value => this.isItTimeToShowPopUp(value)),
        skipWhile(x => {
          this.timeLapsedSinceInactivity = x;
          return x != this.inactivityTime - 1;
        })
      );
      this.subscribeObservable();
    });
  }

  setLastAccess(isSetToLocal) {
    const currentUser = localStorage.getItem('currentUser');
    if (currentUser) {
      const lastAccess = this.getTimeStamp(null);
      this.currentUserLastAccess = lastAccess;
      if (isSetToLocal) { this.updateLastAccess(); }
    } else {
      console.log('User not available...');
    }
  }

  updateLastAccess() {
    localStorage.setItem('isActive', String(this.currentUserLastAccess));
    this.saveLastAccess().then(res => {
      console.log('updateLastAccess res ', res);
    }, err => {
      console.log('updateLastAccess err ', err);
    });
  }

  // private isItTimeToShowPopUp(val: number) {
  //   const timeLeftForInactive = this.inactivityTime - val;
  //   // console.log('timeLeftForInactive before !== inactivityAlertTime' + timeLeftForInactive);
  //   if (timeLeftForInactive == this.inactivityAlertTime) {
  //     // console.log('timeLeftForInactive : ', timeLeftForInactive, 'this.inactivityAlertTime : ', this.inactivityAlertTime)
  //     this.presentAlert();
  //   } else if (timeLeftForInactive <= this.inactivityAlertTime) {
  //     this.timeLapsedSinceInactivity = timeLeftForInactive;
  //     this.minute = this.padZero(Math.floor(timeLeftForInactive / this.inactivityAlertTime));
  //     this.seconds = this.padZero(timeLeftForInactive % this.inactivityAlertTime);
  //     // this.cdf.detectChanges();
  //     // console.log('Time left for session timeout...', timeLeftForInactive);
  //   } else {
  //     this.alertService.dismissAlert();
  //   }
  // }

  isItTimeToShowPopUp(val: number) {
    const timeLeftForInactive = this.inactivityTime - val;
    if (timeLeftForInactive == this.inactivityAlertTime) {
      console.log('timeLeftForInactive : ', timeLeftForInactive, 'this.inactivityAlertTime : ', this.inactivityAlertTime);
      this.setLastAccess(true);
      this.presentAlert();
    } else if (timeLeftForInactive <= this.inactivityAlertTime) {
      this.timeLapsedSinceInactivity = timeLeftForInactive;
      this.minute = this.padZero(Math.floor(timeLeftForInactive / this.inactivityAlertTime));
      this.seconds = this.padZero(timeLeftForInactive % this.inactivityAlertTime);
      this.remTime = timeLeftForInactive;
      // this.applicationRef.tick();
      console.log('Time left for session timeout...', timeLeftForInactive);
    } else {
      this.setLastAccess(false);
      // if (this.tokenExpired()) {
      //   this.setLastAccess(false);
      //   // token expired
      //   console.log('Token is expired...');
      //   if (!this.isTokenRefreshing) {
      //     this.refreshToken();
      //   } else {
      //     console.log('Token is refreshing...');
      //   }
      // } else {
      //   // token valid
      // }
    }
    // else {
    //   this.ngZone.run(() => {
    //     this.setLastAccess(false);
    //     // this.alertService.dismissAlert();
    //   });
    // }
  }

  private subscribeObservable() {
    // this.subscription = this.observable$.subscribe(x => {
    // console.log(`subscribed for ${x + 1} sec`);
    // this.unsubscribeObservable();
    // setTimeout(() => {
    //   this.doLogout(true);
    //   this.applicationRef.tick()
    // }, 1000);
    // });
    // if (this.subscription) {
    //   this.subscription.unsubscribe();
    // }
    this.subscription = this.observable$.subscribe(x => {
      console.log(`subscribed for ${x + 1} sec`);
      this.unsubscribeObservable();
      this.doLogout(true);
    });
  }

  private padZero(digit: any) {
    return digit <= 9 ? '0' + digit : digit;
  }

  unsubscribeObservable() {
    console.log('unsubscribed');
    this.subscription.unsubscribe();
    this.mergedObservable$ = of(<any>[]);
    // this.subscription = null;
  }

  startTimer(event) {
    this.createObservable();
    console.log('subscription started');
    document.body.click();
  }

  stopTimer(event) {
    if (this.subscription && !this.subscription.closed) {
      this.minute = this.padZero(0);
      this.seconds = this.padZero(0);
      this.unsubscribeObservable();
      console.log('StopTimer Called !!!!');
    }
  }

  isUserActive() {
    this.currentUserData = JSON.parse(localStorage.getItem('currentUser'));
    if (this.currentUserData) {
      if (this.currentUserData.checkInactivity == 1) {
        // const token = localStorage.getItem('auth_token');
        // const tokenExpiry = this.getTokenExpiry(token);
        const lastAccess = this.getLastAccess();
        const currentTime = this.getTimeStamp(null);
        const logoutTime = this.currentUserData.inactiveLogoutTime ? this.currentUserData.inactiveLogoutTime : 30;
        // const inactiveTime = this.currentUserData.inactiveLogoutTime;
        const inactiveDuration = (currentTime - Number(lastAccess));
        if (inactiveDuration <= logoutTime) {
          console.log('User is active : ', currentTime, lastAccess, logoutTime, inactiveDuration);
          this.setLastAccess(true);
          return true;
        } else {
          // return false;
          const requestedRoute = this.loginService.isSigmaUser();
          if (requestedRoute) {
            console.log('User is active...');
            this.setLastAccess(true);
            return true;
          } else {
            console.log('User is inactive...');
            return false;
          }
        }
      } else {
        console.log('No need to check inactivity...', this.currentUserData.checkInactivity);
        return true;
      }
    } else {
      console.log('User is present...', this.currentUserData);
      return false;
    }
  }

  getLastAccess() {
    const lastAccess = this.currentUserLastAccess;
    const lastAccessLocal = localStorage.getItem('isActive');
    if (lastAccess && lastAccess >= lastAccessLocal) {
      return lastAccess;
    } else {
      return lastAccessLocal;
    }
  }

  getTimeStamp(date) {
    // const timestamp = (Math.floor((new Date).getTime() / 1000));
    const tempDate = date ? date : new Date();
    const timestamp = (Math.floor(tempDate.getTime() / 1000));
    return timestamp;
  }

  presentAlert() {
    console.log('Session timeout...');
    this.ngZone.run(() => {
      this.isAlertPresented = true;
      this.alertService.showAlert(this.popupConfig).onClose.subscribe((data) => {
        this.isAlertPresented = false;
        console.log('popup closed', data);
        if (data) {
          if (data.id == 'logout') {
            this.doLogout(true);
          } else {
            this.setLastAccess(false);
          }
        }
      });
    });

  }

  doLogout(showToast: boolean) {
    console.log('You are going to logout...');
    this.ngZone.run(() => {
      this.stopTimer(null);
      // const requestedRoute = this.auth.isSigmaUser();
      // if (requestedRoute) {
      //   this.dismissAlert();
      //   this.router.navigate([requestedRoute]);
      // } else {
      // old
      // this.loginService.logout();
      // this.alertService.dismissAlert();
      // showToast == true ? this.presentToast('Session Expired!') : null;
      // new
      this.loginService.logout(res => {
        console.log('Logout done..');
        this.alertService.dismissAlert();
        if (showToast) {
          this.presentToast('Session expired!');
        }
      });
      // }
    });
  }

  refreshToken() {
    this.isTokenRefreshing = true;
    this.loginService.getAccessTokenUsingRefreshToken().subscribe(token => {
      this.isTokenRefreshing = false;
      if (token) {
        this.loginService.currentToken = token;
        localStorage.setItem('refresh_token', token);
        this.loginService.updateUserToken(token);
        console.log('Token is refreshed...');
        this.setLastAccess(true);
      } else {
        this.doLogout(true);
      }
    });
  }

  private tokenExpired() {
    const token = localStorage.getItem('auth_token');
    const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
    return (Math.floor((new Date).getTime() / 1000)) >= expiry;
  }

  public saveLastAccess(): Promise<any> {
    return this.http
      .post(this.saveLastAccessUrl, null)
      .toPromise()
      .then((response) => {
        return (response = response);
      })
      .catch(this.handleError);
  }

  private handleError(error: Response | any) {
    return Promise.reject(error.message || error);
  }

  async presentToast(msg) {
    this.toastController.prsentToast(msg, "Warning", "warning", null);
  }

  detectChanges() {
    // setTimeout(() => {
    //   this.applicationRef.tick();
    //   // this.cdf.detectChanges();
    //   console.log('detectChanges')
    // }, 3000)
    this.applicationRef.tick();
    // this.cdf.tick();
  }

  ngOnDestroy() {
    console.log('CheckInactivityService ngOnDestroy called...');
  }
}
