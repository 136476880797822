import { Injectable } from '@angular/core';
import { webApi } from '../../../../../config';
import { DeliveryService } from '../../../../cip/providers/delivery/delivery.service';
import { ToasterService } from '../../../../providers/toaster/toaster.service';
import { SharedService } from '../shared.service';

@Injectable({
  providedIn: 'root'
})
export class AttendanceInductionService {

  constructor(
    private deliveryService: DeliveryService,
    private sharedService: SharedService,
    private toaster: ToasterService,
  ) { }

  /************** get status list start **************/
  getStatusList(cb) {

    const currentUserData = JSON.parse(localStorage.getItem('currentUser'));

    let params = {
      "dataFlag": 1,
      "roleId": currentUserData.roleId,
    }
    let url = webApi.apiUrl.getAttendanceInductionUserStatus;
    if (url != '') {
      this.deliveryService.fetchSearchableSelectListDynamic(url, params).then(
        (res: any) => {
          console.log('getStatusList res ', res);
          if (res.type) {

            // const { statusList, statusConfig, remarkConfig } = res.data;
            // this.statusRemarkList = statusList;
            // this.statusConfig = JSON.parse(statusConfig);
            // this.remarkConfig = JSON.parse(remarkConfig);

            // this.fetchSearchData(this.currentPageNo, () => {
            //   console.log('Done');
            //   this.loading = false;
            // });

            cb(true, res.data);

          } else {
            // this.loading = false;
            this.presentToastMessage('Unable to get results at this time.', 'Warning!', 'warning');
            cb(true, null);
          }
        },
        (err: any) => {
          console.log('getStatusList err ', err);
          // this.loading = false;
          // this.setNotFound();
          this.presentToastMessage('Unable to get results at this time.', 'Warning!', 'warning');
          cb(false, null);
        }
      ).catch(err => {
        console.log('err ', err);
        // this.loading = false;
        // this.setNotFound();
        this.presentToastMessage('Unable to get results at this time.', 'Warning!', 'warning');
        cb(false, null);
      });
    } else {
      console.log('unable to get data url');
      cb(false, null);
    }
  }
  /************** get status list end **************/

  prepareDataString(type, data, tableLabels, tableData) {
    // this.selectedUsers = [];
    let checkAllString = '';
    let learnStr = '';

    if (type == 1) {
      for (let index = 0; index < tableLabels.length; index++) {
        if (tableLabels[index]['showCheckAll'] == 1 && tableLabels[index]['checkAll']) {
          // if ((this.tableLabels[index]['allMarked'] == 0) || (this.tableLabels[index]['allUnMarked'] == 0)) {
          //   if (checkAllString != '') {
          //     checkAllString += ',';
          //   }
          //   checkAllString += this.tableLabels[index]['bindingProperty'];
          // }
          checkAllString += 'ALL';
        }
      };

      for (let index = 0; index < tableData.length; index++) {
        const learner = tableData[index];
        const attendanceMarkedKey = 'isSelected'
        if ((learner[attendanceMarkedKey] == true)) {
          learnStr += learner.enrolId + '|' + learner.employeeId + '#';

          // this.selectedUsers.push(learner);
        }
      }
    } else {
      learnStr += data.enrolId + '|' + data.employeeId + '#';
    }

    console.log('checkAllString', checkAllString);
    console.log('learnStr', learnStr);
    return {
      allStr: checkAllString == '' ? null : checkAllString,
      learnerStr: learnStr == '' ? null : learnStr
    };
  }

  updateAttendanceStatus(paramsObject, selectedStatus, searchTerm, extraData, cb) {

    const currentUserData = JSON.parse(localStorage.getItem('currentUser'));

    // if (this.isFormValid(selectedStatus, remarkConfig)) {
    console.log('Form is valid...');

    let url = webApi.apiUrl.updateAttendanceStatusWithRemark;
    let params = {
      "activityId": paramsObject.activityId,
      "courseId": paramsObject.courseId,
      "moduleId": paramsObject.moduleId,
      // "learnerStr": "121212|33#343434|55",  // enrolId1|empId1#enrolId2|empId2
      "learnerStr": "",
      "attStat": selectedStatus.status,
      "attRemark": selectedStatus.remark,
      "roleId": currentUserData.roleId,
      actionId: null,
      searchStr: searchTerm,
    }

    const data = extraData;
    // const data = this.prepareDataString(type, userData);
    if (data.allStr == null && data.learnerStr == null) {
      let msg = 'Please select user(s)';
      this.presentToastMessage(msg, 'Warning', 'warning');
      return null;
    } else {
      if (data.allStr) {
        params.learnerStr = data.allStr;
      } else {
        params.learnerStr = data.learnerStr;
      }
    }

    // params = { ...params, ...data };

    if (url != '') {
      // this.loading = true;
      this.deliveryService.fetchSearchableSelectListDynamic(url, params).then(
        (res: any) => {
          // cb(true);
          if (res.type) {
            console.log('saveRemarkAndStatus res : ', res);
            this.presentToastMessage(res.message, 'Success', 'success');
            cb(true);
          } else {
            // this.loading = false;
            let msg = res.message || 'Unable to save at this time.';
            this.presentToastMessage(msg, 'Warning!', 'warning');
            cb(false);
          }
        },
        (err: any) => {
          console.log('saveRemarkAndStatus err : ', err);
          // this.loading = false;
          this.presentToastMessage('Unable to save at this time.', 'Warning!', 'warning');
          cb(false);
        }
      ).catch(err => {
        console.log('saveRemarkAndStatus err : ', err);
        // this.loading = false;
        this.presentToastMessage('Unable to save at this time.', 'Warning!', 'warning');
        cb(false);
      });
    } else {
      console.log('unable to get data url');
      cb(false);
    }
    // } else {
    //   console.log('Form is not valid...');
    // }
  }

  isFormValid(selectedStatus, remarkConfig, status) {
    if (selectedStatus.status) {
      if (selectedStatus.remark) {
        if (status) {
          return true;
        } else {
          if (this.sharedService.isInputValid(selectedStatus.remark, remarkConfig.componentAttribute)) {
            return true;
          } else {
            return false;
          }
        }
      } else {
        this.presentToastMessage('Please add remark', 'Warning', 'warning');
        return false;
      }
    } else {
      this.presentToastMessage('Please select status', 'Warning', 'warning');
      return false;
    }
  }

  validateRemarkAndStatus(paramsObject, extraParams, cb) {

    const currentUserData = JSON.parse(localStorage.getItem('currentUser'));

    let params = {
      "roleId": currentUserData.roleId,
      "activityId": paramsObject.activityId || null,
      "courseId": paramsObject.courseId || null,
      "dataFlag": 1
    }

    params = { ...params, ...extraParams };

    let url = webApi.apiUrl.validateDay1Batch;
    if (url != '') {
      this.deliveryService.fetchSearchableSelectListDynamic(url, params).then(
        (res: any) => {
          console.log('validateRemarkAndStatus res ', res);
          if (res.type) {
            this.presentToastMessage(res.message, 'Success', 'success');
            cb(true);
          } else {
            // this.loading = false;
            let msg = res.message || 'Unable to validate at this time.';
            this.presentToastMessage(msg, 'Warning!', 'warning');
            cb(false);
          }
        },
        (err: any) => {
          console.log('getStatusList err ', err);
          // this.loading = false;
          this.presentToastMessage('Unable to validate at this time.', 'Warning!', 'warning');
          cb(false);
        }
      ).catch(err => {
        console.log('err ', err);
        // this.loading = false;
        this.presentToastMessage('Unable to validate at this time.', 'Warning!', 'warning');
        cb(false);
      });
    } else {
      console.log('unable to get data url');
      cb(false);
    }
  }
  /*************** Validate attendance End  ************/

  async presentToastMessage(msg: string, title: string, type: string) {
    await this.toaster.prsentToast(msg, title, type, null);
  }

  formatDate(date) {
    try {
      let d = date ? new Date(date) : new Date();
      let month = '' + (d.getMonth() + 1);
      let day = '' + d.getDate();
      let year = d.getFullYear();

      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;

      return [year, month, day].join('-');
    } catch (error) {
      return null;
    }
  };
}
