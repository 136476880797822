import { Component, OnInit } from '@angular/core';
import { CommonFunctionsService } from '../../../../providers/common-functions/common-functions.service';
import { CookieService } from '../../../../providers/cookie/cookie.service';
import { LanguageService } from '../../../../providers/language/language.service';
import { SharedService } from '../../../../modules/shared/providers/shared.service';

@Component({
  selector: 'ngx-language-dropdown',
  templateUrl: './language-dropdown.component.html',
  styleUrls: ['./language-dropdown.component.scss']
})
export class LanguageDropdownComponent implements OnInit {
  languageEnabled = null;

  selectedLang = {};
  languageList = [];

  constructor(
    private cookieService: CookieService,
    private languageService: LanguageService,
    private commonFunctionService: CommonFunctionsService,
    private sharedService: SharedService,
  ) { }

  ngOnInit(): void {
    console.log('console')
    this.resetValues();
  }

  resetValues() {
    console.log('console')
    try {
      this.languageEnabled = JSON.parse(localStorage.getItem('displaySettings'))['LanguageEnabled'];
      if (this.languageEnabled == 1) {
        this.fetchLanguages();
      } else {
        this.removeLangugaeCookie();
      }
    } catch (error) {
      console.log('resetValues error : ', error);
    }
  }

  fetchLanguages() {
    this.languageService.getLanguageList({}).then((result) => {
      console.log('fetchLanguages', result);
      if (result['type'] && Array.isArray(result['data'])) {
        this.languageList = result['data'];
        this.setLanguage();
      } else {
        console.log('fetchLanguages type error', result)
      }
    }).catch(error => {
      console.log('fetchLanguages', error);
    })
  }

  setLanguage() {
    const langCookie = this.languageService.getLanguageCookie();
    if (langCookie) {
      // const selectedLang = this.commonFunctionService.getMatchedArray(this.languageList, [langCookie], 'code');
      this.selectedLang = langCookie;
      console.log('this.selectedLang', this.selectedLang);
    } else {
      this.selectedLang = this.languageList.length != 0 ? this.languageList[0]['code'] : '';
    }
    console.log('getCookie:', this.selectedLang);
  }

  removeLangugaeCookie() {
    this.languageService.removeLangugaeCookie();
  }

  changeLanguage(event) {
    console.log('changeDefaultLanguage', event);

    // languge change logs
    let selectedLang = this.sharedService.getObjFromArray(this.languageList, event, 'code');
    selectedLang['dateTime'] = new Date();
    localStorage.setItem('currentLang', JSON.stringify(selectedLang));
    // this.languageService.checkForLanguageChange();

    this.languageService.setLangugaeCookie(event);
    window.location.reload();
  }

}
