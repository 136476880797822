import { Component, OnInit, Input, OnChanges } from "@angular/core";

@Component({
  selector: "ngx-no-data",
  templateUrl: "./no-data.component.html",
  styleUrls: ["./no-data.component.scss"],
})
export class NoDataComponent implements OnInit, OnChanges {
  @Input() title: any;
  @Input() discription: any;
  @Input() customImage: string = '';
  @Input() showImage: boolean;

  constructor() { }

  ngOnInit(): void { }

  ngOnChanges(changes: any) {
    console.log('No data component changed : ', changes);
  }
}
