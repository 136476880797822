<input class="small-input text-center border" [maxlength]="setting?.max" (focus)="oldValue=data[label?.bindingProperty]"
    [disabled]="data[label?.disableProperty] == 1" [(ngModel)]="data[label?.bindingProperty]"
    (ngModelChange)="checkForInputValidation($event)" type="text"
    [placeholder]="label?.componentAttribute?.placeholder">

<!-- <div class="d-flex align-items-center">
    <input class="form-control form-control-sm" [maxlength]="setting?.max"
        (focus)="oldValue=data[label?.bindingProperty]" [disabled]="data[label?.disableProperty] == 1"
        [(ngModel)]="data[label?.bindingProperty]" (ngModelChange)="checkForInputValidation($event)" type="text"
        [placeholder]="label?.componentAttribute?.placeholder">
    <span class="p-1 cursor-p">
        <svg-icon src="assets/icon-svg/check.svg" class="lh-1 stroke-success"
            [svgStyle]="{'width.rem': 1, 'height.rem': 1}"></svg-icon>
    </span>
</div> -->


<!-- form-control form-control-sm -->
<!-- small-input text-center border -->