<div [ngClass]="{'row': routeFrom=='activity'}" >
    <div [ngClass]="{'col-md-2': routeFrom=='activity'}">
        <span *ngIf="routeFrom=='activity'">
        Send Testimonials
        </span>
        <h2 *ngIf="routeFrom=='detail'">
        Send Testimonials
    </h2>
    </div>
    <div  [ngClass]="{'col-md-10': routeFrom=='activity'}">
        <div class="card_container">
            <div class="borderdiv form-control"*ngFor="let ele of courseDetail?.content_managers;let i = index" [ngClass]="{'border-change': ele?.userId === clickedIndex}"(click)="toggleAuthor(ele,i)">
                <nb-user size="medium" [name]="ele?.fullName" title="" [showTitle]="false" [picture]="ele?.profilePicture">
                </nb-user>
            </div>
        </div>
        <form #form="ngForm">
        <div class=" mt-5" *ngIf = "showTextArea">
            <textarea name="comment" class="form-control" #comment="ngModel" name= "comment" placeholder="Enter comment" [(ngModel)]="userComment" required  minlength="50" maxlength="200" pattern=".{50,200}" rows="3" cols="103"></textarea>
            <div *ngIf="comment.errors && comment.dirty" class="alert alert-danger custom-alert">
                <small *ngIf="comment.errors?.required">comment is required</small>
                <small class="invalid-msg" *ngIf="comment.hasError('pattern')">Minimum 50 characters and maximum 200 characters
required</small>
            </div>
        </div>
    </form>
        <div class="mt-4 form-group d-flex justify-content-end footer-btn" *ngIf = "showTextArea">
            <button type="button" class="button-sm btn-outline border-0" (click) = "cancel()" >Cancel</button>
            <button type="button" class="button-sm btn-fill" (click) = "sendTestimonial(userComment)" [disabled] = "!form.valid" >Submit</button>
            </div>
            <hr *ngIf="routeFrom=='activity'">
    </div>
   
</div>
<!-- <div class="row pb-2">
    <div class="col-md-2"></div>
    <div class="col-md-10">
      

    </div>

</div> -->
<!-- <div class="float-right footer-btn">
<button type="button" class="customBtnWhite" >Cancel</button>
<button type="button" class="customBtn" >Submit</button>
</div> -->
