import { Injectable } from '@angular/core';
import { webApi } from "../../../config";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class InitService {
   baseUrl: any = webApi.baseUrl;
   getAppInitDataUrl: any = this.baseUrl + webApi.apiUrl.appInit;
  constructor(private http: HttpClient) { }

   public getAppLoadData(data: any): Promise<any> {
    return this.http
      .post(this.getAppInitDataUrl, data)
      .toPromise()
      .then((response) => {
        return (response = response);
      })
      .catch(this.handleError);
  }

   private handleError(error: Response | any) {
    return Promise.reject(error.message || error);
  }
}
