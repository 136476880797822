import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription, timer } from 'rxjs';

@Component({
  selector: 'ngx-circle-progress-count',
  templateUrl: './circle-progress-count.component.html',
  styleUrls: ['./circle-progress-count.component.scss']
})
export class CircleProgressCountComponent implements OnInit, OnDestroy {

  @ViewChild('circleProgress', { static: true }) cProgress;

  // @Input() configCProgress = {};
  // config = { 
  //   wrapperClass: 'ion-flex ion-align-items-center ion-justify-content-center ion-position-relative',
  //   svg: {
  //     classList: 'circle',
  //     width: '120',
  //     height: '120',
  //   }
  // }
  @Input() maxTime: any = 5;
  @Output() getEvent = new EventEmitter();

  countDown: Subscription;
  counter = 5;
  tick = 1000;

  // maxTime = 5;
  timer: any;
  hidevalue: any = false;

  constructor() { }

  ngOnInit() {
    this.updateCounter();
  }

  // ngOnChanges(changes: SimpleChanges): void {
  //   if ('configCProgress' in changes) {
  //     this.config = { ...this.config, ...this.configCProgress, };
  //     console.log('this.config cprogress', this.config)
  //   }
  // }

  ngAfterViewInit() {
  }

  updateCounter() {
    this.countDown = timer(0, this.tick)
      .subscribe(() => {
        if (this.counter >= 0) {
          --this.counter;
          this.updateProgress(this.counter);
          this.fireEvent('timer', this.counter);
        } else {
          this.unSubscribe();
        }
      })
  }

  updateProgress(counter) {
    var radius = this.cProgress.nativeElement.r.baseVal.value;
    var circumference = radius * 2 * Math.PI;

    var count = (counter / this.maxTime) * 100;

    console.log('radius =', radius);
    console.log('circumference =', circumference);

    this.cProgress.nativeElement.style.strokeDasharray = `${circumference} ${circumference}`;
    this.cProgress.nativeElement.style.strokeDashoffset = `${circumference}`;

    const offset = circumference - count / 100 * circumference;
    this.cProgress.nativeElement.style.strokeDashoffset = offset;
    console.log('offset =', offset);
    console.log(' this.cProgress =', this.cProgress);
  }

  // StartTimer() {
  //   this.timer = setTimeout(x => {
  //     if (this.maxTime <= 0) {

  //     }
  //     this.maxTime -= 1;
  //     if (this.maxTime > 0) {
  //       this.hidevalue = false;
  //       this.StartTimer();
  //     } else {
  //       this.hidevalue = true;
  //     }
  //   }, 1000);
  // }

  fireEvent(event, evData) {
    const dataOutput = {
      type: event,
      data: evData,
    };
    this.getEvent.emit(dataOutput);
  }

  unSubscribe() {
    if (this.countDown) {
      this.countDown.unsubscribe();
    }
  }

  ngOnDestroy() {
    this.unSubscribe();
  }
}
