import { Component, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { H5pPlayerService } from '../../providers/h5p-player/h5p-player.service';

@Component({
  selector: 'ngx-h5p-player',
  templateUrl: './h5p-player.component.html',
  styleUrls: ['./h5p-player.component.scss'],
})
export class H5pPlayerComponent implements OnInit {

  // contentURL: any = [
  //   { id: 1, src: 'http://35.154.184.40:8080/h5p/play/3660677057' },
  //   { id: 2, src: 'http://35.154.184.40:8080/h5p/play/60563f16aca91e067a09957d' },
  //   { id: 3, src: 'http://35.154.184.40:8080/h5p/play/60564073aca91e067a09957e' },
  //   { id: 4, src: 'http://35.154.184.40:8080/h5p/play/605997c1cdd6000446f025c1' },
  // ];

  // srcUrl = this.contentURL[0];

  loading: boolean;
  safeUrl: SafeResourceUrl;

  isFullScreenEnabled = false;
  isScromHorizontalFullScreen = false;
  isScromVerticalFullScreen = false;
  loaded: boolean = false;
  downloading: boolean = false;
  manifesturl: string = '';

  @Input() activity: any;
  @Input() activityHeight?: any = '40';
  @Output() h5pActivityCompletion = new EventEmitter<any>();
  @Output() iPhoneFullScreen = new EventEmitter<boolean>();
  @Output() valueChange = new EventEmitter();
  @Output() saveEvent = new EventEmitter<any>();

  userdetail: any;
  tempAttemptStr: any = '';
  tempResultStr: any = '';
  separators: any;
  compStatus: any;
  h5pEventListener: any;

  listenerCustomerContainerApp: any;
  constructor(
    private sanitizer: DomSanitizer,
    private h5pService: H5pPlayerService,
    private renderer: Renderer2
  ) {
    if (localStorage.getItem("currentUser")) {
      this.userdetail = JSON.parse(localStorage.getItem("currentUser"));
    }
  }

  ngOnInit() {
    this.resetValues();
    this.intializeData();
    this.setLoaded();
    // this.removeListener();
  }

  resetValues() {
    this.separators = JSON.parse(localStorage.getItem('separators'));
    this.tempAttemptStr = '';
    this.tempResultStr = '';
    this.compStatus = false;
    this.activity.attemptStr = this.tempAttemptStr;
    this.activity.resultStr = this.tempResultStr;
  }

  intializeData() {
    if (this.activity) {
      this.manifesturl = this.activity.reference;
      // this.manifesturl = decodeURIComponent(this.activity.reference);
    }
    // this.manifesturl = 'http://3.7.173.76:8080/h5p/play/60640b860056812096e50d31';
    this.viewContent();
  }

  async viewContent() {
    const h5pIframe = document.getElementById('iFrame-content');
    if (h5pIframe) {
      h5pIframe.setAttribute('allowfullscreen', 'allowfullscreen');
      h5pIframe.setAttribute('frameborder', '0');
      h5pIframe.setAttribute('allow', 'geolocation *; microphone *; camera *; midi *; encrypted-media *');
      h5pIframe.setAttribute('samesite', 'none');
    } else {
      console.log('Iframe not found...');
    }
    // this.removeListener();
    this.addListener();
  }

  addListener() {
    // if(!this.listenerCustomerContainerApp){
    //   this.listenerCustomerContainerApp = this.performAction.bind(this);
    // }
    // window.addEventListener('message', this.listenerCustomerContainerApp, true);
    this.h5pEventListener = this.renderer.listen('window', 'message', event => {
      this.performAction(event);
    });
  }

  removeListener() {
    // if(this.listenerCustomerContainerApp){
    //   window.removeEventListener('message', this.listenerCustomerContainerApp, true);
    // }
    if (this.h5pEventListener) {
      this.h5pEventListener();
    }
  }

  performAction(event) {
    // const url = new URL(this.manifesturl);
    // const origin = url.origin;

    if (event.origin.startsWith('https://devh5p.edgelearning.co.in')) { //Listen for events only from H5P Host
      console.log('addEventListener event.data ==> ', event.data);
      // if (event.data.message !== undefined && event.data.message.resultData !== undefined) {
      // alert(JSON.stringify(event.data.message));
      // }
      if (event.data && event.data.message) {
        const h5pRes = event.data.message;
        if (h5pRes.type == 'attempted' || h5pRes.type == 'completed') {
          this.makeAttemptsDataReady(h5pRes);
        } else {
          this.makeAnsweredDataReady(h5pRes);
        }
        //   if (h5pRes.type == 'answered') {
        //   this.makeAnsweredDataReady(h5pRes);
        // } else {
        //   console.log('events type not matched...', h5pRes.type);
        // }
      }
    } else {
      return;
    }
  }

  makeAttemptsDataReady(h5pRes) {
    const params = this.h5pService.populateAttemptDetail(this.activity, h5pRes);
    this.tempAttemptStr = params;
    if (h5pRes.type == 'completed') {
      this.updateContentCompletionStatus(h5pRes);
    }
  }

  makeAnsweredDataReady(h5pRes) {
    const params = this.h5pService.populateResultDetail(this.activity, h5pRes);
    const pipeSeprator = this.separators.pipeValue ? this.separators.pipeValue : '|';
    if (this.tempResultStr !== '') {
      this.tempResultStr += pipeSeprator + params;
    } else {
      this.tempResultStr = params;
    }
  }

  setLoaded() {
    this.loaded = true;
    this.downloading = false;
    this.isFullScreenEnabled = false;
  }

  /******************** save content consumption start ****************/
  updateContentCompletionStatus(h5pRes) {
    if (this.activity.completionstatus === 'Y') {
      this.activity.completionstatus = 'Y';
    } else if (h5pRes && h5pRes.completionStatus == 1 && h5pRes.successStatus == 1) {
      this.activity.completionstatus = 'Y';
    } else {
      this.activity.completionstatus = 'UP';
    }
    this.activity.attemptStr = this.tempAttemptStr;
    this.activity.resultStr = this.tempResultStr;
    this.saveH5pTracker(this.activity);
  }

  saveH5pTracker(activity) {
    this.compStatus = true;
    const params = this.h5pService.populateH5pDetail(activity);
    console.log('saveH5pTracker params : ', params);
    this.h5pService.saveH5pDetails(params).then((result) => {
      console.log('saveH5pTracker result : ', result);
      if (result.type) {
        this.updateActivityDetails(result.data, result.data1);
      } else {
        console.log('saveH5pTracker error...');
      }
    }).catch((error) => {
      console.log('saveH5pTracker error : ', error);
    });
  }

  updateActivityDetails(result, data) {
    if (data) {
      if (data.attemptRes && data.attemptRes.length > 0) {
        this.activity.attemptId = data.attemptRes[0].lastid;
      }
      if (data.contentComplitionRes && data.contentComplitionRes.length > 0) {
        this.activity.contentcomplitionId = data.contentComplitionRes[0].lastid;
        this.activity.res = data.contentComplitionRes;
      }
      if (data.activityCompletionRes && data.activityCompletionRes.length > 0) {
        if (this.activity.activitycompletionid === undefined || !this.activity.activitycompletionid) {
          this.activity.activitycompletionid = data.activityCompletionRes[0].lastid;
        }
        this.activity.res = data.activityCompletionRes;
        // this.saveEvent.emit(this.activity);
      }
      this.saveEvent.emit(this.activity);
    }
  }
  /******************** save content consumption end ****************/

  ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    this.removeListener();
    if (!this.compStatus) {
      this.updateContentCompletionStatus(null);
    }
    this.resetValues();
  }
}
