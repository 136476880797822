<!-- <div class="course-content">
	<div class="text-content">
		<h4>{{ courseTitle }}</h4>
	</div>
	<div class="cancelDiv">
		<div class='cancel' (click)='goBack()'>
			<i class="fa fa-times cancelIcon" aria-hidden="true"></i>
		</div>
	</div>
</div>

<div class="row">
	<div id='courseDescription' class="col-md-12 thirty-years-after-d">
		<p class="activityNameSize">{{courseDataContent.activityName}}</p>
	</div>
</div> -->
<!--
<div class="grid">
	<div class="item1">
		<img class="course-detail-icon" src='assets/images/orangeLearn.svg' />
	</div>
	<div class="item2">
		<div class="header">
			<h2>{{ courseTitle }}</h2>
		</div>
		<div class="course-completion">

			<p [ngClass]="{'another' : !courseTitle}">{{courseDataContent?.activityName}}</p>
		</div>
	</div>
	<div class="item3">
		<div class="cancelBtn" (click)='goBack()'>
			<i class="fa fa-times cancelIcon" aria-hidden="true"></i>
		</div>
	</div>
</div> -->

<div class="course-details-course" [ngClass]="{
    'show-content-center':
      courseDetail?.formatId === 9 ||
      courseDetail?.formatId === 10 ||
      courseDetail?.formatId === 11 ||
      courseDetail?.formatId === 8 ||
      courseDetail?.formatId === 12 ||
      courseDetail?.formatId == 5 ||
      courseDetail?.formatId == 4,
    'show-fullscreen':
      (courseDataContent?.activityTypeId === 11 && windowFullScreen) ||
      courseDetail?.formatId == 2 ||
      courseDetail?.formatId == 1 ||
      courseDetail?.formatId == 3 ||
      courseDetail?.formatId === 6 ||
      courseDetail?.formatId == 7 ||
      courseDetail?.activityTypeId == 11 ||
      courseDetail?.formatId == 15 ||
      courseDetail?.formatId == 16,
    'p-0': courseDetail?.formatId == 4 && windowFullScreen
  }">
  <div class="w-100 h-100 mx-auto">
    <div *ngIf="
        showdiv &&
        courseDetail?.isDeleted != 1 &&
        !dependencyConatiner.displayFlag
      " class="w-100 h-100">
      <div *ngIf="courseDetail?.formatId == 2" class="w-100 h-100">
        <!-- <ngx-audioplayer [currentActivityData]="courseDetail"
          (callActivityCompletion)="saveActivityCompletionData($event)"></ngx-audioplayer> -->
        <ngx-media-player class="d-flex align-items-center justify-content-center h-100 w-100"
          (saveEvent)="updateActivityCompletionForAll($event)" [activity]="courseDetail"></ngx-media-player>
      </div>
      <!--  class="w-100 h-100" -->
      <!-- <div
        [ngClass]="{ 'video-container-small-screen': !windowFullScreen }"
      >
        <ngx-videoplayer
          [currentActivityData]="courseDetail"
          (callActivityCompletion)="saveActivityCompletionData($event)"
        ></ngx-videoplayer>

      </div> -->
      <ng-container *ngIf="courseDetail?.formatId == 1">
        <ngx-media-player class="d-flex align-items-center justify-content-center h-100 w-100"
          (saveEvent)="updateActivityCompletionForAll($event)" [activity]="courseDetail"></ngx-media-player>
      </ng-container>
      <div *ngIf="courseDetail?.formatId == 7" class="text-center w-100 h-100">
        <!-- <img
          [ngClass]="{ 'make-image-fullscreen': windowFullScreen }"
          (error)="errorHandler($event)"
          (load)="imageLoaded($event)"
          [src]="courseDetail.reference"
        /> -->
        <ngx-preloader-img [url]="courseDetail?.reference" (loadError)="sendContentLoadErrorMessage('image')"
          (saveEvent)="updateActivityCompletionForAll(courseDetail)"></ngx-preloader-img>
      </div>
      <div *ngIf="courseDetail?.formatId == 3" class="h-100" class="w-100 h-100">
        <!-- <ngx-documentviewer
          [currentActivityData]="courseDetail"
          (loadError)="sendContentLoadErrorMessage('pdf', $event)"
          (callActivityCompletion)="saveActivityCompletionData($event)"
          [windowFullScreen]="windowFullScreen"
          [documentref]="courseDetail.reference"
        >
        </ngx-documentviewer> -->
        <ngx-pdf-viewer [activity]="courseDetail" (saveEvent)="updateActivityCompletionForAll(courseDetail)"
          (loadError)="sendContentLoadErrorMessage('pdf')" [documenturl]="courseDetail?.reference"
          [ViewerConfig]="pdfViewerConfig"></ngx-pdf-viewer>
      </div>
      <div *ngIf="courseDetail?.formatId == 4" class="w-100 h-100" [ngClass]="{ 'kpoint-screen': !windowFullScreen }">
        <!-- <app-k-point
          [currentActivityData]="courseDetail"
          (callActivityCompletion)="saveActivityCompletionData($event)"
        ></app-k-point> -->
        <ngx-k-point [activity]="courseDetail" (saveEvent)="updateActivityCompletionForAll($event)"></ngx-k-point>
      </div>
      <div *ngIf="courseDetail?.formatId == 5" [ngClass]="{ 'another-act-full-screen': windowFullScreen }"
        class="another-act d-flex justify-content-center align-items-center">
        <!-- (saveEvent)="onDocumentLoad($event)" -->

        <!-- <app-scorm-player
          (scromActivityCompletion)="getUpdatedActivity()"
          (scromInteraction)="getScormStatus($event)"
          [scorm]="courseDetail"
        ></app-scorm-player> -->
        <ngx-scorm-player (scromActivityCompletion)="getUpdatedActivity()" (scromInteraction)="getScormStatus($event)"
          [activity]="courseDetail"></ngx-scorm-player>
        <div *ngIf="scormStatus && false">
          <p>Status : {{ scormStatus?.lesson_status || "Incomplete" }}</p>
          <p *ngIf="scormStatus?.score_raw">
            Score : {{ scormStatus?.score_raw }}
          </p>
          <p *ngIf="scormStatus?.score_max">
            Max Score : {{ scormStatus?.score_max }}
          </p>
          <p *ngIf="scormStatus?.score_min">
            Min Score : {{ scormStatus?.score_min }}
          </p>
          <p *ngIf="scormStatus?.session_time">
            Session Time : {{ scormStatus?.session_time }}
          </p>
        </div>
      </div>
      <!-- H5P Player -->
      <div *ngIf="courseDetail?.formatId == 15" [ngClass]="{ 'another-act-full-screen': windowFullScreen }"
        class="d-flex align-items-center justify-content-center h-100 w-100">
        <ngx-h5p-player (saveEvent)="getUpdatedActivity()" [activity]="courseDetail">

        </ngx-h5p-player>
      </div>
      <!-- H5P Player End -->
      <ng-container *ngIf="courseDetail.formatId === 9">
        <div class="another-act d-flex justify-content-center align-items-center"
          [ngClass]="{ 'another-act-full-screen': windowFullScreen }">
          <a class="link" (saveEvent)="updateActivityCompletionForAll($event)" download [href]="courseDetail.reference">
            <button class="bodyMidButton" (click)="updateActivityCompletionForAll(courseDetail)">
              <i class="fa fa-download"></i> Download
            </button>
          </a>
        </div>
      </ng-container>

      <ng-container *ngIf="courseDetail.formatId === 10">
        <div class="another-act d-flex justify-content-center align-items-center"
          [ngClass]="{ 'another-act-full-screen': windowFullScreen }">
          <a class="link" (click)="updateActivityCompletionForAll(courseDetail)" download
            [href]="courseDetail.reference">
            <button class="bodyMidButton" (click)="updateActivityCompletionForAll(courseDetail)">
              <i class="fa fa-download"></i> Download
            </button>
          </a>
        </div>
      </ng-container>

      <ng-container *ngIf="courseDetail.formatId === 11">
        <div class="another-act d-flex justify-content-center align-items-center"
          [ngClass]="{ 'another-act-full-screen': windowFullScreen }">
          <a class="link" (click)="updateActivityCompletionForAll(courseDetail)" download
            [href]="courseDetail.reference">
            <button class="bodyMidButton" (click)="updateActivityCompletionForAll(courseDetail)">
              <i class="fa fa-download"></i> Download
            </button>
          </a>
        </div>
      </ng-container>

      <ng-container *ngIf="courseDetail.formatId === 12">
        <div class="another-act d-flex justify-content-center align-items-center"
          [ngClass]="{ 'another-act-full-screen': windowFullScreen }">
          <a class="link" (click)="updateActivityCompletionForAll(courseDetail)" download
            [href]="courseDetail.reference">
            <button class="bodyMidButton" (click)="updateActivityCompletionForAll(courseDetail)">
              <i class="fa fa-download"></i> Download
            </button>
          </a>
        </div>
      </ng-container>

      <ng-container *ngIf="courseDetail?.formatId == 8">
        <div class="another-act d-flex justify-content-center align-items-center"
          [ngClass]="{ 'another-act-full-screen': windowFullScreen }">
          <!-- <div (click)="open_browser(courseDetail)">
						<p class="link">
							Click here to open URL
						</p>
					</div> -->
          <button class="bodyMidButton" (click)="open_browser(courseDetail)">
            Open In New Window &nbsp;
            <i class="fas fa-external-link-alt" aria-hidden="true"></i>
          </button>
        </div>
      </ng-container>

      <div class="another-act d-flex justify-content-center align-items-center"
        [ngClass]="{ 'another-act-full-screen': windowFullScreen }"
        *ngIf="courseDetail?.activityTypeId === 11 && courseReadyFlag == true">
        <!-- <app-webinar-multi *ngIf="featureConfig?.webinar?.openvidu" [course]="courseDataContent"
					[userList]="usersData" [voiceCall]="voiceCall" [videoCall]="videoCall"
					(trainerCutsCalls)="getUpdatedActivity()" (callConnecting)="webinarActivityCompletion($event)"
					(callEnd)="webinarActivityCompletion($event)" [areaId]="5" [tenantId]="tenantId" [config]="config">
				</app-webinar-multi>
				<ngx-webinar-multi-jitsi *ngIf="featureConfig?.webinar?.jisti" [course]="courseDataContent"
					[userList]="usersData" [voiceCall]="voiceCall" [videoCall]="videoCall"
					(trainerCutsCalls)="getUpdatedActivity()" (callConnecting)="webinarActivityCompletion($event)"
					(callEnd)="webinarActivityCompletion($event)" [areaId]="5" [tenantId]="tenantId" [config]="config">
        </ngx-webinar-multi-jitsi>
        <ngx-webinar-bbb *ngIf="featureConfig?.webinar?.BBB" [course]="courseDataContent" [userList]="usersData"  (callConnecting)="webinarActivityCompletion($event)"
        (trainerCutsCalls)="getUpdatedActivity()"
        [areaId]="5"  [tenantId]="tenantId" [config]="config"></ngx-webinar-bbb> -->
        <!-- (callEnd)="webinarActivityCompletion($event)" -->
      </div>

      <ng-container *ngIf="courseDetail.formatId === 6">
        <!-- <youtube-player
          [videoId]="youtubeId"
          (ready)="savePlayer($event)"
          (change)="onStateChange($event)"
        >
        </youtube-player> -->
        <ngx-media-player (saveEvent)="updateActivityCompletionForAll($event)"
          class="d-flex align-items-center justify-content-center h-100 w-100"
          [activity]="courseDetail"></ngx-media-player>
      </ng-container>

      <ng-container *ngIf="courseDetail.formatId === 16">
        <ngx-article-activity [activity]="courseDetail" (loadError)="sendContentLoadErrorMessage('article')"
          (saveEvent)="updateActivityCompletionForAll(courseDetail)"
          class="d-flex align-items-center justify-content-center h-100 w-100"></ngx-article-activity>

      </ng-container>
    </div>
    <div class="begin-section d-flex text-center" [ngClass]="{ 'begin-section-fullscreen': windowFullScreen }"
      *ngIf="courseDetail?.isDeleted == 1 || dependencyConatiner.displayFlag">
      <div class="begin-inner">
        <span>
          <!-- <i class="fa fa-lock lock-act"></i> -->
          <img class="begin-icon mt-2" src="assets/icon-svg/activity-icons/filled/{{
              courseDetail?.actIcon
            }}.svg" alt="" srcset="" />
        </span>

        <h3 class="m-0 pb-3">{{ courseDetail?.activityName }}</h3>
        <!-- <p *ngIf="beginContainer?.descrition">{{beginContainer?.descrition}} </p> -->
        <div class="complet-msg font-weight-bold mb-2">
          <p class="m-0" *ngIf="courseDetail?.isDeleted == 1">
            {{ courseDetail?.isDeletedMsg }}
          </p>
          <p class="m-0" *ngIf="dependencyConatiner.displayFlag">
            {{ dependencyConatiner?.message }}
          </p>
        </div>
        <!-- </div> -->
      </div>
    </div>
  </div>
</div>