<div class="d-flex align-items-center justify-content-center h-100 upload_activity_container p-2">
    <!--*ngIf="activity?.assallowweb == 1"  -->
    <ng-container>
        <div class="w-75">
            <div class="begin-section d-flex text-center" *ngIf="showContainer">
                <div class="begin-inner">
                    <span>
            <img class="begin-icon mt-2" src="assets/icon-svg/activity-icons/filled/{{dependencyConatiner?.icon}}.svg"
              alt="" srcset="" />
          </span>

                    <h3 class="m-0 pb-3">{{ activity?.activityName }}</h3>
                    <div class="complet-msg font-weight-bold mb-2">

                        <p class="m-0" *ngIf="dependencyConatiner.displayFlag">
                            {{ dependencyConatiner?.message }}
                        </p>
                        <p class="m-0" *ngIf="dependencyConatiner?.subMessage">
                            {{ dependencyConatiner?.subMessage }}
                        </p>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="activity?.isDeleted == 0 && activity?.sessionendflag == 0">
                <div class="upload_activity_container_fileNameBox p-3">
                    <div class="d-flex align-items-center">

                        <svg-icon [svgClass]="'mr-2'" src="../../../../assets/icon-svg/activity-icons/outline/{{activity.actIcon}}.svg"></svg-icon>
                        <p class="m-0">
                            {{assigementFileName}}
                        </p>
                    </div>
                    <div class="d-flex align-items-center">
                        <!-- <p class="m-0 text-light-color">
            12mb
        </p> -->
                        <button class="commonBtn-lg ml-2" (click)="downloadResource(activity?.reference)">
              Download
            </button>
                    </div>
                </div>

                <div class="upload_activity_container_uploadBox my-2 cursor-p position-relative">
                    <ng-container *ngIf="!uploadingInProgress">
                        <span class="terms-remove-icon" *ngIf="userFileName !=''" (click)="removeFile($event)">x</span>
                        <input type="file" #fileUpload id="inputFile" name="inputFile" (change)="readFile($event)" (click)="fileUpload.value = null">
                        <ng-container *ngIf="userFileName =='' ">
                            <!-- <button type="submit" class="commonBtn">Browse File</button> -->
                            <p class="m-0">
                                Drag file here or <span class="text-primary">Browse</span>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="userFileName !=''">
                            <p class="m-0">
                                {{userFileName}}
                            </p>
                            <p class="m-0 my-2">
                                OR
                            </p>
                            <p class="m-0">
                                Upload <span class="text-primary">New File</span>
                            </p>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="uploadingInProgress">
                        <div class="w-50 m-auto">
                            <div class="progress">
                                <div class="progress-bar" role="progressbar" [style.width]="percentage + '%'" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                            <p class="m-0 text-light-color">
                                {{percentage}} %
                            </p>
                        </div>

                    </ng-container>
                </div>
                <!-- [disabled]="uploadingInProgress" -->
                <p class="m-0 mt-2 text-primary cursor-p text-center" *ngIf="uploadedFile && !uploadingInProgress">
                    <span class="cursor-p text-underline" (click)="submitAssignment()">
            Submit
          </span>
                </p>
            </ng-container>
        </div>

    </ng-container>
    <!-- <div class="begin-section d-flex text-center" *ngIf="activity?.assallowweb == 0">
        <div class="begin-inner">
            <span>
        <img class="begin-icon mt-2" src="assets/icon-svg/activity-icons/filled/{{dependencyConatiner?.icon}}.svg"
          alt="" srcset="" />
      </span>

            <h3 class="m-0 pb-3">{{ activity?.activityName }}</h3>
            <div class="complet-msg font-weight-bold mb-2">
                <p class="m-0" *ngIf="dependencyConatiner.displayFlag">
                    {{ dependencyConatiner?.message }}
                </p>
            </div>
        </div>
    </div> -->
</div>
