import { Injectable } from "@angular/core";

@Injectable()
export class Config { }

// export const baseUrl =  'http://localhost:9845/';
export const webApi = {
  // baseUrl: 'http://localhost:9845', // localhost
  // baseUrl: 'https://50f38e01e461.ngrok.io',
  // recommdationUrl: 'https://home.nextlabsonline.com',
  // baseUrl: "https://devwebapi.edgelearning.co.in",        // SSL Configured New DEV
  // baseUrl: 'https://webapi.edgelearning.co.in',        // SSL Configured New Test
  // baseUrl: 'https://migwebapi.edgelearning.co.in',     // SSL Configured New MIG
  // baseUrl: 'https://uatwebserver.edgelearning.co.in',  // SSL Configured New UAT
  // baseUrl: "https://prodwebserver.edgelearning.co.in", // SSL Configured New PROD
  // baseUrl: 'https://ebd50ed1a932.ngrok.io',
  // baseUrl: 'https://webapi.edgebox.in',
  baseUrl: 'https://saaswebserver.edgelearning.co.in',
  apiUrl: {
    /********************* App Update ********************/

    checkAppVersion: "/api/app_update/check_app_version",
    /********************* Auth ********************/
    login: "/api/studentPortal/userLogin",
    refresh_auth_token: "/api/studentPortal/refresh_auth_token",
    // tenantList: "/api/studentPortal/tenantList",
    tenantList: '/api/studentPortal/zbbtenantList',
    get_updated_user_details: "/api/studentPortal/get_updated_user_details",
    updated_user_login_details: "/api/studentPortal/updated_user_login_details",
    get_code_of_conduct_for_user:
      "/api/studentPortal/get_code_of_conduct_for_user",
    logout: "/api/studentPortal/user_logout",
    getToken: "/api/studentPortal/sendTokenForForgotPassword",
    resendToken: "/api/studentPortal/resendTokenForForgotPassword",
    verifyToken: "/api/studentPortal/verifyTokenForForgotPassword",
    resetPassword: "/api/studentPortal/setNewPasswordForForgotPassword",
    appInit: "/api/studentPortal/checkSubDomainIsValid",
    signUp: "/api/studentPortal/insertSignUpData",
    /*---- Sso URL ---- */
    // ssoLogin: "/api/studentPortal/ssouserLogin",
    ssoLogin: '/api/studentPortal/zbbssouserLogin',
    ssoverifyuser: "/api/studentPortal/ssoverifyuser",

    sassVerifyUser: '/api/saas/verify_token_login',

    /********************* LEARN ********************/
    // getScormList: "/app/web/getScormList",
    // saveScormTrackList: "/app/web/saveScormTrackers",
    // getScormUrl: '/app/web/getScormUrl',
    viewScormPackage: "/app/web/viewScromPackageById",
    deleteScormPackage: "/app/web/deleteScromPackageById",
    getScormPackage: "/app/web/getScorm",
    getKpointToken: "/api/kpoint/xauthtoken",
    isContentEnrol: '/api/learn/getenrolsts',
    get_all_modules_activities_list: "/api/studentPortal/moduleActivity",
    get_all_learn_modules_list_and_asset: "/api/learn/getactivitydata",
    save_activity_completion: "/api/studentPortal/activitycompletion",
    save_content_completion: "/api/learn/contentcompletion",
    submitAssigement: '/ttt/api/learner/save_assignment_by_lerner',
    /*----GET Module List-----*/
    get_all_learn_modules_list: "/api/studentPortal/learnModules",
    get_all_learn_modules_asset_list: "/api/learn/getmoduledata",
    getUpdatedActivityData: "/api/web/getUpdatedCourseActivityData",
    submitInstanceRating: '/api/learn/saverating',
    submitTestimonials: '/api/testimonials/inserttestimonials',
    getSharedUserList: '/api/learn/getsharecntuserlist',
    shareContent: '/api/learn/savesharecontent',
    /*----Webinar-----*/
    gettrainerList: "/api/web/getWebinarTrainerList",
    getlearnerlist: "/api/weqb/getWebinarLearnerList",
    batch_webinar_activity_completion:
      "/api/studentPortal/batch_webinar_activity_completion",
    /*----Quiz-----*/
    check_quiz_for_user: "/api/studentPortal/checkQuizUser",
    get_quiz_questions: "/api/studentPortal/getQuizQuestions",
    submit_quiz_questions_answers: "/api/studentPortal/submitQuizAnswers",
    get_quiz_review: "/api/studentPortal/getQuizQuesForReview",
    /*----feedback-----*/
    checkFeedbackUser: "/api/studentPortal/checkFeedbackUser",
    getFeedbackQuestions: "/api/studentPortal/getFeedbackQuestions",
    submitFeedbackAnswers: "/api/studentPortal/submitFeedbackAnswers",
    /*----Attendance-----*/
    getAttendanceActData: "/api/web/getMarkAttendanceActivityData",
    attendance: "/api/studentPortal/insert_attendance_usha_batch",
    /***********poll************ */
    getPollQuestionAnswers: "/api/studentPortal/getPollQuestionAnswers",
    submitPollAnswers: "/api/studentPortal/submitPollAnswers",
    /********survey***************** */
    getSurveyQuestionAnswers: "/api/studentPortal/getSurveyQuestionAnswers",
    submitSurveyAnswers: "/api/studentPortal/submitSurveyAnswers",
    /***************calendar***********/
    calendarevent: "/api/studentPortal/getCalendarEvents",
    confirm_batch: "/api/studentPortal/ttt_confirm_batch",
    confirmwebinarforttt: "/api/studentPortal/webinar_book_for_TTT",
    unenrol: "/api/studentPortal/unenrol_trainer",
    aproveenrol: "/api/studentPortal/insert_trainer_enrol",
    /************Preference Form**************/
    getPreferenceFormData: "/api/learn/getAllfielddata",
    getPreferenceForm: "/api/learn/getLpform",
    savePreferenceForm: "/api/learn/saveAllfielddata",

    /************ My learning **************/
    get_my_courses_with_filter: "/api/learn/getAllmyCourses",
    fetch_my_learning: "/api/learn/mylearning",
    fetchMyLearningFilter: "/api/mylearning/filter",
    fetchMyCoursesWithFilter: "/api/learn/getAllmycoursewithstatusandsearch",
    reEnrolDeEnrolContent: '/api/learn/enrol_deenrol_opencourse',

    /************ Nominations **************/
    getMyLearningTabs: '/api/learn/get_learning_tabs',
    getFiltersByPage: '/api/learn/get_filter_data_for_learner_tab',
    getNominations: '/api/learn/get_data_for_nomination_tab_filterwise',
    confirmNomination: '/ttt/api/learner/save_trainer_nomination_decision',

    /************Learning Cart**************/
    fetch_my_cart: "/api/Learningcart/getAllScheduleAndUnScheduleData",
    add_data_to_cart: "/api/Learningcart/addDataintolearningCartAndScheduleData",
    getLearningCartContentCount: '/api/Learningcart/getAllScheduleAndUnSchedulecontentCount',
    updateDeleteCartContent: '/api/Learningcart/updateScheduleUnscheduleAndDeleteData',
    getscheduleData: '/api/Learningcart/getscheduleData',

    /************ Pin Content **************/
    pinContent: '/api/learn/savepincontent',
    getPinContent: '/api/learn/getpincontent',

    /************Learning Raise Request**************/
    save_raise_request: '/api/learningRequest/insertlearningrequest',
    get_learner_data: '/api/learningRequest/getlearnerdata',
    /************Home**************/
    fetchHomePage: '/api/home/fetchHomePageData',
    fetchBannerList: '/api/learn/getpostersList',
    fetchContinueLearning: '/api/learn/getContinueLearning',
    fetchCategoryList: '/api/learn/getlearningCategoryList',
    /********** SEARCH ************/
    getCategoryList: '/api/web/get_category_tree',
    searchApiURL: '/api/content/contentSearch',
    autoSuggestionURL: '/api/content/autoSuggestion',
    getContentOptionDetails: '/api/Learningcart/getcartdetailsforcontent',
    /********** RECOMMDATION ************/
    recommdationTabListUrl: '/api/homepage/content',
    recommdationSeeAllURL: '/api/homepage/seeAllContent',
    /******************* CONTENT DETAILS ***************/
    fetchContentDetail: "/api/learn/getinstancedetails",
    enrolContent: '/api/learn/enrolAtbegin',
    /******************* Leader Board ******************/
    getLeaderBoard: '/api/studentPortal/getLearnerlederboardDetails',
    /******************* Profiles ******************/
    fetchDashboardBadge: '/api/learn/getdasboardbadgese',
    fetchDashboardCertificate: '/api/learn/getdasboardcertificate',
    fetchDashboardLearningHours: '/api/learn/getdasboardlearninghours',
    getCertificates: "/api/studentPortal/certificate",
    saveProfileImgUrl: '/api/studentPortal/saveProfileImage',
    removeProfileImgUrl: '/api/studentPortal/removeProfileImage',
    getTestmonialDetails: '/api/learn/getdasboardtestimonialstatus',
    getProfileDetails: '/api/manager/md/view_learner_profile_data',
    /******************* Certificate ******************/
    fetchDashboardSkill: "/api/learn/getdasboardskilllist",
    /************ notofications **************/
    get_unread_push_notifications_count: '/api/studentPortal/get_unread_push_notifications_count',
    getPushNotifications: '/api/studentPortal/getPushNotifications',
    readPushNotifications: '/api/studentPortal/update_push_notification_status',

    /*** h5p ****/
    saveH5pDetails: '/api/learn/h5pcontentcompletion',

    /*** TTT ****/
    getLTTTDashboard: '/ttt/api/learner/get_nomination_workflow_list',
    getNomination: '/ttt/api/learner/get_nomination_details',
    getDropdownValues: '/ttt/api/learner/ttt_get_nomination_dropdowns',
    submitNomination: '/ttt/api/learner/submit_nomination',
    getWorkflowSteps: '/ttt/api/learner/get_workflow_steps',
    getWorkflowStepDetails: '/ttt/api/learner/get_step_data',
    getLearnerTopicList: '/ttt/api/learner/learner_get_topic_list',
    getBookedSlotWithStepDetails: '/ttt/api/learner/get_viva_selected_slot_by_employee',
    getVivaFeedbackQuestions: '/ttt/api/learner/cip_get_viva_feedback_question_and_values',
    submitFeedbackAnswersTTT: '/ttt/api/learner/cip_submit_feedback_answers',
    getVivaSlotsByTopic: '/ttt/api/learner/cip_and_learner_get_calender_events',
    getTTTModuleActivity: '/ttt/api/learner/get_prework_course_modules',
    getWorkflowBatches: '/ttt/api/learner/get_workflow_batches',
    getBookedBatchDetails: '/ttt/api/learner/get_selected_batch_details',
    bookWorkflowBatch: '/ttt/api/learner/select_batch',
    getCertificateDetails: '/ttt/api/learner/ttt_get_certificate_details',
    // generateTTTCertificate: '/api/studentPortal/getTTTcertificate',
    getCIPCourseDetails: '/ttt/api/learner/ttt_get_cip_access_course_details',
    bookRescheduleSlot: '/ttt/api/learner/book_and_reschedule_slot',
    /*** CIP ****/
    getAssessorData: '/ttt/api/learner/cip_get_course_and_workflow_data',
    getTabs: '/ttt/api/learner/cip_get_all_tabs',
    getCIPNomineeList: '/ttt/api/learner/cip_get_nominee_list',
    getVivaSlots: '/ttt/api/learner/cip_get_slot_data',
    getAccesorTabs: '/ttt/api/learner/cip_get_assesor_workflow_tabs',
    getCIPEventsList: '/ttt/api/learner/cip_and_learner_get_calender_events',
    getCipTopicListUrl: '/ttt/api/learner/cip_get_topic_list',
    addEditSlotUrl: '/ttt/api/learner/cip_add_edit_cip_events',
    get_data_by_tenantwise: '/ttt/api/cip/get_data_by_tenantwise',
    getCIPModuleActivityTabs: "/ttt/api/cip/CIP_get_tab_list_for_courseCard",
    get_all_cip_dashboard: '/api/trainerDashboard/cip/get_all_cip_dashboard',
    get_web_cip_data: '/api/trainerDashboard/cip/get_web_cip_data',
    getAttendanceActivityUsers: '/ttt/api/cip/cip_get_activity_learners_list',
    getDeliveryCategotyProgramList: '/ttt/api/cip/cip_get_program_category_list',
    getDashboardWidgets: '/ttt/api/cip/cip_get_program_wise_widgets_list',
    generatePayoutForBatch: '/ttt/api/cip/generate_payout_for_batch',
    getPayoutData: '/ttt/api/cip/get_Milestone_data',
    downloadProgramReport: '/ttt/api/cip/cip_get_programwise_reports',
    getEvaluationFeedbackUser: '/ttt/api/cip/get_learnerlist_for_evalution_feedback',

    getAttendanceInductionUserStatus: '/api/learn/get_att_drop_data',
    getAttendanceInductionUsers: '/api/learn/get_att_learner_list',
    updateAttendanceStatusWithRemark: '/api/learn/update_attendance_status',
    validateDay1Batch: '/api/learn/validate_batch_dayone',

    /******************** CERTIFICATES & TESTIMONIALS**********/
    getTestimonials: '/ttt/api/cip/cip_get_mytestimonials',
    updateTestimonials: '/ttt/api/cip/cip_update_mytestimonials',
    getAllCertificates: '/ttt/api/cip/cip_get_certificate',
    getCertificateDownloadURL: '/ttt/api/cip/download_cip_certificate',

    /********************* DELIVERY ****************************/
    /********************* Attendance **************/
    getBatchTabs: '/ttt/api/cip/get_ttt_batch_tabs',
    getBatchActivityDropdown: '/ttt/api/cip/get_ttt_batch_activity_dropdown',
    getAttendanceList: '/ttt/api/cip/get_ttt_batch_learners_list',
    markUnmarkAttendance: '/ttt/api/cip/ttt_batch_mark_unmark_attendance',
    sendAttendanceReminder: '/ttt/api/learner/send_reminder_for_batch',
    /********************* Mocks **************/
    getMockList: '/ttt/api/cip/get_ttt_batch_learners_list_mocks',
    /****************** Final Assessment *******************/
    getFinalAssesmentList: '/ttt/api/cip/get_ttt_batch_learners_list_final_assessment',
    certifyLearnerFinalAssesment: '/ttt/api/cip/ttt_certify_learner',

    /** Assessor List
      ** Fetch Assessor List
    */
    getAssessorList: '/ttt/api/cip/get_ttt_batch_learners_list_assessor',

    /************* PPR *****************/
    generatePPR: '/ttt/api/report/schedule_batch_ppr_report_for_cip',

    /************* Manager API *****************/
    getManagerDashboardData: '/api/manager/md/getallmanagerDashboard',
    remindTeamMembers: '/api/manager/md/set_remind_for_learning',
    removePinnedContent: '/api/manager/md/hide_pinned_content_data',
    assignContent: '/api/manager/md/update_or_insert_enrollment_data',
    removeLearningContent: '/api/manager/md/web_hide_learning_data',
    getManagerInstanceDetails: '/api/manager/md/get_course_or_workflow_data',
    getInstanceContentDetails: '/api/manager/md/manager_create_learning_journey',
    saveLearningJourney: '/api/manager/md/create_course_and_workflow',
    removeContentFromLearning: '/api/manager/md/remove_assign_wf_course_activities',
    /*********** Comments ******************/
    getAllComments: '/api/web/get_all_comments_list_of_content',
    insertUpdateComment: '/api/web/insert_content_comments',

    /**** S3 Presigned URL ********/
    genratepresignedurl: '/api/damFileUpload/genratepresignedurl',

    getAreawiseModuleData: '/api/learn/get_areawise_module_data',


    saveLastAccess: '/api/studentPortal/update_last_access',
    registerUserInfo: '/api/common/registeruserinfo',
    registerUserDeviceInfo: '/api/studentPortal/inseruserdeviceandlogindetails',
    logoutFromOtherDevices: '/api/studentPortal/userlogoutfromotherdevices',
    getNotificationEventList: '/api/manager/md/get_notify_events',
    getAssignContent: '/api/manager/md/get_all_assign_data',

    // getNotificationCCList: '/api/notify/get_cc_list_md'
    getNotificationCCList: '/api/notify/get_cc_list_md',

    getLanguages: '/api/learn/get_language_list',
    insertLanguageLogs: '/api/learn/insert_multilingual_logs',

    /********************* Trainer Calendar *****************/
    getCalendarEvents: '/api/calendar/get_events',
    getCalendarFilters: '/api/calendar/get_events_filters',
    deleteEvent: '/api/calendar/update_nomination_status',

    /********************* Banner Carousel *****************/
    getBannerList: '/api/banner/get_all_banner_preview',
    saveBannerClicks: '/api/banner/save_banner_clicks',

    /********************* QR Scanner *****************/
    getQRDetails: '/api/qr/scan_qr',
    markAttendanceByLearner: '/api/studentPortal/mark_attendance_and_get_mark_data',
    getLearnerAttendanceDetails: '/api/learn/get_attendance_activity_details',

    generateQr: '/api/qr/generate',
    downloadBulkAttendance: '/api/attendance/get_attendance_headers_and_data',
    uploadBulkAttendance: '/api/attendance/validate_attendance_headers',

    /********************* Like/Dislike *****************/
    likeDislikeContent: '/api/learn/add_like_dislike',

    // Manager Approve, Reject Course
    approveRejectCourseRequest: '/api/manager/md/approved_and_reject_employees_for_self_approval',

    // Fetch Survey
    getSurveyList: '/api/home/get_surveys',
    getSurveyDetails: '/api/home/get_survey_questions',
    submitSurveyNew: '/api/home/submit_survey_answers',

    // Skill Mapping
    getMandatorySkillListUrl: '/api/learn/getdasboardskillrating',
    saveMandatorySkill: '/api/learn/saveSkillRating'
  },
};
