import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  Output,
  EventEmitter,
} from "@angular/core";
import { ScromService } from "../../providers/scrom/scrom.service";
import * as AcrossTabs from "../../../vendors/js/across-tabs.min.js";
import { CheckInactivityService } from "../../modules/shared/providers/utils/check-inactivity/check-inactivity.service";
// import { Subscription } from "rxjs/Subscription";
@Component({
  selector: "ngx-scorm-player",
  templateUrl: "./scorm-player.component.html",
  styleUrls: ["./scorm-player.component.scss"],
})
export class ScormPlayerComponent implements OnInit, OnDestroy {
  @Input() activity: any;
  @Output() scromActivityCompletion = new EventEmitter();
  @Output() scromInteraction?= new EventEmitter();
  // languageChangeSubscriber = Subscription.EMPTY;
  parent;
  manifesturl: string;
  loaded: boolean;
  isExist: boolean;
  scormInfo: any;
  downloading: boolean;
  scormDetails: any;
  isWorking: boolean = false;
  errormsg: any;
  packageId: any;
  userdetail: any;
  popupWindow: any;
  allTabs;
  openedTabs;
  closedTabs;
  scormStatus;

  scormClosed: boolean = false;

  // tslint:disable-next-line:max-line-length
  constructor(private scromService: ScromService, public checkInactivityService: CheckInactivityService) {
    this.loaded = false;
    this.isExist = false;
    this.getUserData();
  }
  ngOnInit() {
    this.intializeData();
  }

  getUserData() {
    if (localStorage.getItem("currentUser")) {
      this.userdetail = JSON.parse(localStorage.getItem("currentUser"));
      this.userdetail.token = localStorage.getItem('auth_token');
    }
    console.log('Current user details from scorm....', this.userdetail);
  }

  intializeData() {
    console.log("AcrossTabs", AcrossTabs);
    const _this = this;
    this.parent = new AcrossTabs.default.Parent({
      onHandshakeCallback: () => {
        console.log("onHandshakeCallback", event);
      },
      onChildCommunication: (event) => {
        console.log("onChildCommunication", event);
        this.scormStatus = event.msg;
        if (this.scromInteraction) {
          this.scromInteraction.emit(this.scormStatus);
        }
      },
      onPollingCallback: (event) => {
        console.log("onPollingCallback", event);
      },
      onChildDisconnect: (event) => {
        if (!_this.scormClosed) {
          _this.scormClosed = true;
          _this.deleteScormPackage();
          _this.scromActivityCompletion.emit();
          _this.checkInactivityService.resetValues();
        }
        _this.allTabs = this.parent.getAllTabs();
        _this.openedTabs = this.parent.getOpenedTabs();
        _this.closedTabs = this.parent.getClosedTabs();
        console.log("onChildDisconnect", event, _this.allTabs, _this.openedTabs, _this.closedTabs);
      },
      removeClosedTabs: true,
    });
    this.allTabs = this.parent.getAllTabs();
    this.openedTabs = this.parent.getOpenedTabs();
    this.closedTabs = this.parent.getClosedTabs();
    this.getUserData();
    if (this.activity) {
      this.isWorking = true;
      this.scormInfo = this.activity; // JSON.parse(atob(scorm));
      // this.activity.url = scormInfo.scromUrl;
      this.activity.downloadTime = new Date();
      // this.manifesturl = this.activity.reference;
      let reference = null;
      let filename = null;
      let folderpath = null;
      if (this.activity.reference) {
        reference = decodeURIComponent(this.activity.reference);
        filename = this.activity.reference.substring(
          this.activity.reference.lastIndexOf("/") + 1
        );
        filename = filename ? filename.split('?')[0] : filename;
        filename = decodeURIComponent(filename);
        folderpath = this.activity.reference.replace(/^.*\/\/[^\/]+/, "");
        folderpath = decodeURIComponent(folderpath.substring(1));
      }
      this.getScormPackages(
        reference,
        filename,
        folderpath,
        this.activity.fileinfo ? this.activity.fileinfo.mimetype : null
      );
    }
  }
  getScormPackages(reference, filename, folderpath, documenttype) {
    if (this.userdetail) {
      const data = {
        filename: filename,
        key: folderpath || this.activity.referanceKey,
        eid: this.userdetail.id,
        extension: documenttype,
        // scorm: btoa(JSON.stringify(unescape(encodeURIComponent(this.scorm)))),
        scorm: null, // btoa(JSON.stringify(this.scorm)),// this.encryptdata(this.scorm),
        url: reference,
        source: this.activity.contentSource,
        refKey: this.activity.referanceKey,
      };
      // const basicdata = {
      //   // cont_comp_Id, act_comp_Id, contentId, id, enrolId, completiontype, activityId, completionstatus, completionstate, viewed, contentwatchedtime, contenttime, languageId, tenantId, courseId, moduleId
      //   empId: this.activity.empId || this.activity.employeeId, // 37988,
      //   employeeId: this.activity.empId || this.activity.employeeId,
      //   usrId: this.activity.usrId,
      //   contentId: this.activity.contentId,
      //   enrolId: this.activity.enrolId,
      //   attempt: this.activity.attempt,
      //   activitycompletionid: this.activity.activitycompletionid,
      //   moduleId: this.activity.moduleId,
      //   courseId: this.activity.courseId,
      //   tenantId: this.activity.tenantId,
      //   completionstate: this.activity.completionstate,
      //   completionstatus: this.activity.completionstatus,
      //   roleId: this.userdetail.roleId,
      //   languageId: this.activity.defaultlangId,
      //   contentcomplitionId: this.activity.contentcomplitionId ? this.activity.contentcomplitionId : '',
      //   contentRepId: this.activity.contentRepId,
      //   wfId: this.activity.wfId ? this.activity.wfId : null,
      //   cont_comp_Id: this.activity.areaId == 34 ? this.activity.contentcomplitionId : null,
      //   act_comp_Id: this.activity.areaId == 34 ? null : this.activity.activitycompletionid,
      //   completiontype: this.activity.areaId === 34 ? 1 : 2,
      //   activityId: this.activity.areaId === 34 ? null : (this.activity.activityId || this.activity.instanceId),
      //   authToken: this.userdetail.token,
      //   areaId: this.activity.areaId,
      //   stepId: this.activity.stepId,
      //   // activityName: this.activity.activityName,
      //   // name: this.activity.name
      // };
      const basicdata = {
        enrolId: this.activity.enrolId,
        areaId: this.activity.areaId,
        activityId: this.activity.areaId === 34 ? null : (this.activity.activityId || this.activity.instanceId),
        authToken: this.userdetail.token,
        roleId: this.userdetail.roleId,
        playlistId: this.activity.playlistId ? this.activity.playlistId : null,


        /*** not needed ****/
        // empId: this.scorm.empId || this.scorm.employeeId, // 37988,
        // employeeId: this.scorm.empId || this.scorm.employeeId,
        // usrId: this.scorm.usrId,
        // contentId: this.scorm.contentId,
        // attempt: this.scorm.attempt,
        // activitycompletionid: this.scorm.activitycompletionid,
        // moduleId: this.scorm.moduleId,
        // courseId: this.scorm.courseId,
        // tenantId: this.scorm.tenantId,
        // completionstate: this.scorm.completionstate,
        // completionstatus: this.scorm.completionstatus,
        // languageId: this.scorm.defaultlangId,
        // contentcomplitionId: this.scorm.contentcomplitionId ? this.scorm.contentcomplitionId : '',
        // contentRepId: this.scorm.contentRepId,
        // wfId: this.scorm.wfId ? this.scorm.wfId : null,
        // cont_comp_Id: this.scorm.areaId == 34 ? this.scorm.contentcomplitionId : null,
        // act_comp_Id: this.scorm.areaId == 34 ? null : this.scorm.activitycompletionid,
        // completiontype: this.scorm.areaId === 34 ? 1 : 2,
        // // activityName: this.activity.activityName,
        // // name: this.activity.name
        // stepId: this.scorm.stepId ? this.scorm.stepId : null,
      };
      this.scromService
        .viewScormPackage(data)
        .then((result) => {
          this.isWorking = false;
          if (result.success === true) {
            this.packageId = result.package;

            this.manifesturl = this.scromService.getManifesturl(
              result,
              basicdata
            );

            // Old Code
            // this.popupWindow = window.open(this.manifesturl, '_blank', 'modal=yes,alwaysRaised=yes');

            // this.initScormAPI(this.activity.contentId);
            // this.popupWindow.onunload = () => {
            //   alert('Child window closed');
            //   // debugger;
            //   // this.scromActivityCompletion.emit(basicdata);
            // };

            // New Code
            var config = {
              // url: ' http://127.0.0.1:8081/child.html',
              url: this.manifesturl,
              windowName: "Child - " + 0,
              // tslint:disable-next-line:max-line-length
              windowFeatures:
                "toolbar=no,location=yes,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,copyhistory=no,width=1000,height=600",
            };
            this.parent.openNewTab(config);

            this.allTabs = this.parent.getAllTabs();
            this.openedTabs = this.parent.getOpenedTabs();
            this.closedTabs = this.parent.getClosedTabs();

            if (this.allTabs.length > 0) {
              this.checkInactivityService.stopTimer(null);
              this.scormClosed = false;
            }

            console.log("this.allTabs", this.allTabs);
            console.log("this.openedTabs", this.openedTabs);
            console.log("this.closedTabs", this.closedTabs);
          } else {
            this.errormsg = "Unable to fetch Scorm Package";
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
  deleteScormPackage() {
    if (this.packageId) {
      this.scromService
        .deleteScormPackage(this.packageId)
        .then((result) => {
          console.log(result);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  ngOnDestroy() {
    // this.languageChangeSubscriber.unsubscribe();
    this.deleteScromData();
  }

  deleteScromData() {
    if (this.parent) {
      this.parent.closeAllTabs();
      this.allTabs = this.parent.getAllTabs();
      this.openedTabs = this.parent.getOpenedTabs();
      this.closedTabs = this.parent.getClosedTabs();

      console.log("this.allTabs", this.allTabs);
      console.log("this.openedTabs", this.openedTabs);
      console.log("this.closedTabs", this.closedTabs);

      this.deleteScormPackage();
    }
  }
}
