<div class="course-filter" *ngIf="filter">
    <!-- <span class="search-result">500 results</span> -->
    <!-- <select class="form-control form-control-sm">
    <option selected>Sort</option>
    <option>Lowest Price</option>
    <option>Highest Price</option>
</select> -->
    <div class="filter-tag d-flex align-items-center justify-content-between border-bottom px-3 py-2">
        <h5 class="m-0 p-0">FILTERS</h5>
        <span class="cursor-p filter-clear" (click)="clearData()">Clear All</span>
    </div>

    <div class="filter-tag" *ngFor="let item of filter;let i = index">
        <div class="cursor-p d-flex justify-content-between align-items-center" (click)="showdiv(item)">
            <label class="tag-name cursor-p m-0">{{item?._source.field_name}}</label>
            <span class="arrow" *ngIf="!item.show">
            <i class="fas fa-chevron-down"></i>
        </span>
            <span class="arrow" *ngIf="item.show">
            <i class="fas fa-chevron-up"></i>
        </span>
        </div>

        <ul class="pl-1" [hidden]="item?.show">
            <!-- <div class="Search pb-2" *ngIf="i==2">
                <div class="searchBar focus fullserch">
                    <i class="fa fa-search search"></i>
                    <i class="fa fa-times close" (click)="clear()"></i>
                    <input type="text" maxlength="20" placeholder="Search Program Name">
                </div>
            </div> -->
            <li class="pb-2" *ngFor="let inner of item?._source?.filterValue | orderBy : item?._source?.applySort == 1 ? 'name': '' ;let j = index">
                <!-- <div class="custom-control custom-checkbox d-inline-block align-top">
                    <input type="checkbox" [(ngModel)]="inner.check" [disabled]="inner?.isDisabled" [checked]="inner?.check"
                            (ngModelChange)="applyFilterData($event, inner, item?._source)" class="custom-control-input"
                            id="customCheck + {{i}}+{{j}}">
                    <label class="custom-control-label cursor-p" for="customCheck + {{i}}+{{j}}"></label>
                    <span class="inner-name pl-2">{{inner?.name}} </span>
                </div>
                 -->
                <!-- new checkbox -->
                <div class="checkbox d-inline-block align-top" (click)="applyFilterData($event, inner, item?._source)">
                    <span class="checkbox--name text-capitalize">{{inner?.name}}</span>
                    <!-- (ngModelChange)="" [(ngModel)]="inner.check"  -->
                    <input type="checkbox" [disabled]="inner?.isDisabled" [checked]="inner?.check">
                    <span class="checkmark"></span>
                </div>
            </li>
        </ul>
    </div>
</div>
