<div class="calender pt-4">
  <div class="pl-4">
    <div class="text-18-px font-weight-bold">Events ({{events?.length}})</div>
    <ng-container *ngIf="events?.length != 0">
      <ng-container *ngFor="let item of events;let i = index">
        <!-- (click)="this.eventVisible = !this.eventVisible" -->
        <ngx-event-card [inputData]="item" (actionEvent)="performAction($event)"></ngx-event-card>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="events.length == 0">
      <div class="mt-2">
        No Data Found
      </div>
    </ng-container>
  </div>

  <div class="px-4">
    <!-- {{events | json}} -->
    <!--   [events]="eventSource" [events]="eventsPromise | async" -->
    <full-calendar #calendar [options]="calendarOptions" [events]="eventSource">
      <ng-template #eventContent let-arg>
        <div
          class="event bg-white {{arg?.event?.extendedProps.eventClassList}}  {{arg?.event?.extendedProps.eventBorderClassList}} d-block w-100 border-radius-2px cursor-p py-1">
          <div
            class="text-10-px body-text font-weight-bold lh-1 line-wrap line-clamp-1 overflow-hidden white-space-nowrap px-1 pb-1">
            {{arg?.event?.title}}
          </div>
          <div class="text-8-px body-text lh-1 line-wrap line-clamp-1 overflow-hidden white-space-nowrap h-10-px px-1">
            {{ arg?.event?.extendedProps?.subTitle }}
          </div>
        </div>
      </ng-template>
    </full-calendar>

    <!-- <full-calendar #calendar [initialView]="calendarOptions.initialView" [header]="{
                        left: 'prev,next today',
                        center: 'title',
                        right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
                      }" [plugins]="calendarOptions.plugins" [weekends]="calendarOptions.weekends" [events]="eventSource"
            (dateClick)="handleDateClick($event)"></full-calendar> -->
  </div>
</div>


<ngx-right-side-drawer [config]="config" *ngIf="eventVisible"
  (actionEvent)="performAction({data: $event, action: 'eventSidebar'})">
  <div class="py-2 px-1">
    <!-- <div class="lh-1 pb-3">
      <svg-icon svgClass="stroke-gray" [svgStyle]="{'width.rem': 1, 'height.rem': 1}"
        src="assets/icon-svg/course-card/type.svg"></svg-icon>
      <span class="fs-16 text-gray pl-3">Course</span>
    </div> -->

    <div class="d-flex align-items-center mb-3">
      <span class="mark-event-{{selectedEventData?.eventType}} w-1-rem h-1-rem rounded"></span>
      <h4 class="pl-3 m-0">{{selectedEventData?.title}}</h4>
    </div>

    <div class="pl-4 ml-2">
      <div class="font-weight-bold border-bottom pb-3 mb-3">{{selectedEventData?.subTitle}}</div>

      <ng-container *ngIf="selectedEventData?.description">
        <div class="d-flex align-items-center border-bottom pb-3 mb-3">
          <span class="align-self-baseline">
            <svg-icon svgClass="stroke-text fill-white"
              [svgStyle]="{'width.rem': 1, 'height.rem': 1, 'stroke-width': 1.2}"
              src="assets/icon-svg/Graduation_hat_icon.svg"></svg-icon>
          </span>
          <span class="fs-14 pl-3">
            <!-- {{selectedEventData?.description}} -->
            <app-read-more [id]="selectedEventData?.id" [text]="selectedEventData?.description" [maxLength]="100">
            </app-read-more>
          </span>
        </div>
      </ng-container>

      <!-- <div class="d-flex align-items-center lh-1 border-bottom pb-3 mb-3" *ngFor="let item of [1,2,3]">
        <svg-icon svgClass="stroke-text fill-white" [svgStyle]="{'width.rem': 1, 'height.rem': 1, 'stroke-width': 1.5}"
          src="assets/icon-svg/Graduation_hat_icon.svg"></svg-icon>
        <span class="fs-16 pl-3">Project Management</span>
      </div> -->
      <ng-container *ngIf="viewType == 1">
        <!-- {{selectedEventData?.extraParams | json}} -->
        <ng-container *ngFor="let displayIconData of selectedEventData?.extraParams| jsonFormatter;let isLast=last ">
          <!-- {{displayIconData | json}} -->
          <div class="d-flex align-items-center lh-1 border-bottom pb-3 mb-3" *ngIf="displayIconData?.visible == 1">

            <svg-icon svgClass="stroke-text fill-white" src="assets/icon-svg/{{displayIconData?.typeIcon}}.svg"
              [svgStyle]="{'width.rem': 1, 'height.rem': 1, 'stroke-width': 1.2}"></svg-icon>
            <span class="fs-14 pl-3">{{displayIconData?.typeValue}}</span>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngFor="let activityData of selectedEventData?.meetings| jsonFormatter;let isLast=last ">

        <div class="d-flex align-items-center justify-content-between lh-1 border-bottom pb-3 mb-3">
          <div>
            <svg-icon svgClass="stroke-text fill-white" src="assets/icon-svg/{{activityData?.typeIcon}}.svg"
              [svgStyle]="{'width.rem': 1, 'height.rem': 1, 'stroke-width': 1.5}"></svg-icon>
            <span class="pl-1">{{activityData?.typevalue}}</span>
          </div>
          <!--  (click)="performAction({data: activityData , action: 'msTeamLink'})" -->
          <button class="btn btn-sm btn-fill" *ngFor="let button of activityData?.buttons | jsonFormatter"
            (click)="performAction({data: activityData , action:button })">Join</button>
          <!-- <span
            (click)="performAction({data: {activityData: activityData, item: selectedEventData}, action: 'msTeamLink'})">
            JOIN</span> -->
        </div>
      </ng-container>
    </div>

  </div>

</ngx-right-side-drawer>