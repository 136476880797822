import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ToasterService } from '../../../../providers/toaster/toaster.service';
import { LoadingService } from '../../../../providers/loading/loading.service';
import { ExcelUploadAndPreviewService } from '../../providers/excel-upload-and-preview/excel-upload-and-preview.service';

@Component({
  selector: 'ngx-excel-upload-and-preview',
  templateUrl: './excel-upload-and-preview.component.html',
  styleUrls: ['./excel-upload-and-preview.component.scss']
})
export class ExcelUploadAndPreviewComponent implements OnInit, OnChanges {

  @Input() configExcelUpload = {};
  @Output() getEvents = new EventEmitter();

  labels = [
    { labelname: 'status', bindingProperty: 'Category', componentType: 'text', },
    { labelname: 'reason', bindingProperty: 'Category', componentType: 'text', },
    { labelname: 'ecn', bindingProperty: 'Category', componentType: 'text', },
    { labelname: 'attendance', bindingProperty: 'Category', componentType: 'text', },
  ];

  tableList = [
    {
      status: 'success',
      reason: 'Uploaded',
      ecn: '728145',
      attendance: 'Joined',
    },
    {
      status: 'success',
      reason: 'Uploaded',
      ecn: '728145',
      attendance: 'Joined',
    },
    {
      status: 'success',
      reason: 'Uploaded',
      ecn: '728145',
      attendance: 'Joined',
    },
    {
      status: 'success',
      reason: 'Uploaded',
      ecn: '728145',
      attendance: 'Joined',
    },
    {
      status: 'success',
      reason: 'Uploaded',
      ecn: '728145',
      attendance: 'Joined',
    },
    {
      status: 'success',
      reason: 'Uploaded',
      ecn: '728145',
      attendance: 'Joined',
    },
    {
      status: 'success',
      reason: 'Uploaded',
      ecn: '728145',
      attendance: 'Joined',
    },
    {
      status: 'success',
      reason: 'Uploaded',
      ecn: '728145',
      attendance: 'Joined',
    },
    {
      status: 'success',
      reason: 'Uploaded',
      ecn: '728145',
      attendance: 'Joined',
    },
  ]

  isUploaded = false;

  config = {
    fileClass: '',
    innerClass: 'text-center',
    titleSection: {
      show: true,
      class: 'text-22-px font-weight-bold',
      message: 'Upload your file',
    },
    titleNote: {
      show: true,
      class: 'text-12-px text-gray py-3',
      message: 'Supported file type: csv',
    },
    fileinput: {
      show: true,
      class: 'd-flex flex-column align-items-center justify-content-center bg-light position-relative h-10-rem w-80 dash-border rounded p-3 mx-auto',
      message: 'Supported file type: csv',
      innerHtml: '<div class="text-14-px py-1">Drag & Drop file here or <span class="text-primary link">Click here</span> for upload</div>',
      icon: {
        show: true,
        class: 'stroke-primary fill-primary-light',
        iconRef: 'assets/icon-svg/File_text_icon.svg',
        style: { 'width.rem': 3, 'height.rem': 3, 'stroke-width': .5 },
      },
    },
    footerIconText: {
      show: true,
      message: 'Download Template',
      messageClass: 'text-primary text-14-px font-weight-bold px-1',
      wrapperClass: 'd-flex align-items-center justify-content-center py-4',
      icon: {
        show: true,
        iconRef: 'assets/icon-svg/Download_icon.svg',
        class: 'stroke-primary',
        style: { 'width.rem': 1, 'height.rem': 1, 'stroke-width': 2.2 },
      },
    },
    maxExcelUpload: '26214400',
    uploadType: 1, // 1 direct excel upload , 2 - upload in json object , 3 - upload in sepearator string format,
    directUploadFlag: 1,
    sampelFile: '',
    sampelFileAPI: '',
    extraParams: {},
    downloadAPI: '',
    uploadFileAPI: '',
    labels: [],
    tabelData: [],
    fileUploaded: false,
    showTopUpload: false,
    uploadedMessage: '1/65 Details Created',
  }

  constructor(
    public spinner: LoadingService,
    private toaster: ToasterService,
    private excelUploadAndPreviewService: ExcelUploadAndPreviewService,
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('configExcelUpload' in changes) {
      this.config = { ...this.config, ...this.configExcelUpload, };
      console.log('ExcelUploadAndPreviewComponent configExcelUpload : ', this.config)
    }
  }

  performAction(event) {
    console.log('event', event);
    switch (event.type) {
      case 'fileUpload':
        this.performFileValidation(event.data.fileEvent);
        break;
      // case 'topFileUpload': this.performFileValidation(event.data.fileEvent); break;
      case 'chooseFileDownload':
        this.downloadSampelExcel(event);
        break;
      // case 'downloadResultSheet': this.performDownloadAction(event); break;
      default: console.log('invalid type', event);
    }
  }

  performFileValidation(event) {
    console.log('performFileValidation', event);
    const object = { event: event, maxExcelSizeUpload: this.config.maxExcelUpload };
    const result = this.excelUploadAndPreviewService.performExcelFileValidation(object);
    if (result['status'] == 1) {
      this.makeUploadDataReady(event, result);
    }

  }

  makeUploadDataReady(event, result) {
    switch (Number(this.config.uploadType)) {
      case 1:
        // Upload Direct excel
        this.uploadExcelFile(result);
        break;
        // case 2:
        //   // Convert into object
        //   this.convertToJson(event);
        break;
      default:
        break;
    }
  }

  uploadExcelFile(result) {
    if (this.config.directUploadFlag == 0) {
      this.fireEvent('excelSelected', result);
    } else {
      this.uploadExcelFileAPI(result);
    }
  }

  uploadExcelFileAPI(result) {
    console.log('uploadExcelFileAPI : ', result);

    this.spinner.presentLoading('Loading');

    const fd = new FormData();
    fd.append('file', result['data']);
    for (const key in this.config.extraParams) {
      fd.append(key, this.config.extraParams[key])
    }

    const url = this.config.uploadFileAPI;
    this.excelUploadAndPreviewService.httpPostRequest(url, fd).then((response) => {
      if (response['type'] && response['status'] == 1) {
        this.assignUploadedData(response['data']);
        this.fireEvent('excelUploaded', { data: response, success: 1 });
      } else {
        this.presentToastMessage('Unable to upload Excel File', 'Warning', 'warning');
        this.fireEvent('excelUploaded', { data: response, success: 0 });
      }
      this.spinner.dismiss();
    }).catch((error) => {
      console.log('uploadExcelFileAPI error', error);
      this.spinner.dismiss();
      this.fireEvent('excelUploaded', { data: error, success: 0 });
      this.presentToastMessage('Unable to Excel upload File', 'Warning', 'warning');
    })
  }

  assignUploadedData(response) {
    this.config.tabelData = this.excelUploadAndPreviewService.checkForProperData(response['tableData']) ? response['tableData'] : [];
    this.config.labels = this.excelUploadAndPreviewService.checkForProperData(response['labelData']) ? response['labelData'] : [];
    this.config.uploadedMessage = this.excelUploadAndPreviewService.checkForProperData(response['tableCount']) ? response['tableCount'][0]['validCount'] : '';
    this.config.showTopUpload = false;
    this.config.fileUploaded = true;
  }

  // convertToJson(event) {
  //   this.excelUploadAndPreviewService.convertExcelToJSON(event, (flag, result) => {
  //     if (flag) {
  //       if (this.config.directUploadFlag == 0) {
  //         this.fireEvent('excelSelected', result);
  //       } else {
  //         // this.uploadAssets();
  //       }
  //     }
  //   });
  // }

  downloadSampelExcel(event) {
    console.log('downloadSampelExcel', event);
    if (this.config.sampelFile) {
      this.excelUploadAndPreviewService.downloadFile(this.config.sampelFile, 'Bulk-upload-template');
    } else {
      this.downloadExcel(event, 1);
    }
  }

  // Download excel
  downloadExcel(event, type) {
    console.log('downloadExcel : ', event);
    const params = {
      ...this.config.extraParams
    };

    // this.spinner.show();
    this.spinner.presentLoading('Loading');

    let url = '';
    if (type == 1) {
      url = this.config.sampelFileAPI;
    } else {
      url = this.config.downloadAPI
    }

    this.excelUploadAndPreviewService.httpPostRequest(url, params, { responseType: 'blob' }).then((response) => {
      this.excelUploadAndPreviewService.downloadBlobFile(response);
      this.spinner.dismiss();
    }).catch((error) => {
      console.log('downloadExcel error', error);
      this.spinner.dismiss();
      this.presentToastMessage('Unable to download Excel File', 'Warning', 'warning');
    })
  }

  /*************** Present Toast Message ************/
  async presentToastMessage(msg, title, type) {
    await this.toaster.prsentToast(msg, title, type, null);
  }
  /*************** Present Toast Message END ************/

  fireEvent(event, data) {
    const dataOutput = {
      action: event,
      data: data,
    };
    console.log('Fire event called : ', dataOutput);
    this.getEvents.emit(dataOutput);
  }
}
