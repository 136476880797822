import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'mandetoryquestionpipe',
})
export class MandetoryquestionpipePipe implements PipeTransform {
  constructor(protected sanitizer: DomSanitizer) {}
  transform(value: any, question: any, type: any): SafeHtml {
    if (type == 'question') {
      if (question.isMandatory == 1) {
        const questionFinal = value + ' <b class="mandatoryQues">*</b>';
        return this.sanitizer.bypassSecurityTrustHtml(questionFinal);
      } else {
        return this.sanitizer.bypassSecurityTrustHtml(value);
      }
    } else {
      return this.sanitizer.bypassSecurityTrustHtml(question);
    }
  }

}
