import { Component, OnInit, Input, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { ContentCard } from '../../interfaces/content-card.enum';
import { ContentOptionService } from '../../providers/content-option/content-option.service';
@Component({
  selector: 'ngx-content-learn-card',
  templateUrl: './content-learn-card.component.html',
  styleUrls: ['./content-learn-card.component.scss']
})
export class ContentLearnCardComponent implements OnInit, OnChanges {
  @Input() cardsData: any;
  @Output() getEvent = new EventEmitter();
  @Input() config: ContentCard = {
    image: 'instanceThumbnail',
    title: 'instanceName',
    author: '',
    rating: '',
    description: '',
    showPinned: false,
    instanceType: 'instanceType',
    instanceTypeIcon: 'instanceTypeIcon',
    showStars: true,
    // moreOptionArray: 'moreOptions',
    // insideSpinner: false,
    scheduleFlag: 'isScheduled',
    instanceProgress: 'instanceProgress',
    // goToCartFlag: 'cart_id',
    goToCartFlag: 'cartId',
    numberOfStars: 'outof'
  };
  buttons = [];
  constructor(public contentOptionService: ContentOptionService) { }

  ngOnInit(): void {
  }

  fireEvent(event, data) {
    // if (data.compStatus !== 'UPCOMING') {
    const dataOutput = {
      type: event,
      cardData: data,
      // liked : isLiked,
    };

    console.log('Fire event called : ', dataOutput);
    this.getEvent.emit(dataOutput);
    // } else {
    //   return false;
    // }
  }

  mouseEnter(item) {
    item.show = true;
  }

  mouseLeave(item) {
    item.show = false;
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log('options', changes)
    if (changes && changes.cardsData) {
      const options = this.contentOptionService.makeButtonsReady(this.cardsData);
      // const buttons = []
      this.buttons = [];
      // console.log('options', options)
      for (let opt of options) {
        if (opt.isVisible) {
          // let button = {
          //   text: opt.text,
          //   cssClass: opt.cssClass,
          //   icon: opt.icon,
          // }
          this.buttons.push(opt);
        }
      }
      // console.log('buttons', this.buttons);
    }
    // if(changes)
  }
}
