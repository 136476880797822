import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'jsonFormatter'
})
export class JsonFormatterPipe implements PipeTransform {

  transform(items: string): any[] {
    if (!items) {
      return [];
    }
    if (Array.isArray(items)) {
      return items;
    }
    // return JSON.parse(items);
    try {
      return JSON.parse(items);
    } catch {
      return [];
    }
  }

}
