import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-user-image',
  templateUrl: './user-image.component.html',
  styleUrls: ['./user-image.component.scss']
})
export class UserImageComponent implements OnInit {
  @Input() labelData = {
    userImage: 'userProfile',
    name: 'fullName'
  };
  @Input() setting = {
    maxLength: 5,
  };
  @Input() defaultImage = '';
  @Input() data: any = {

  };
  constructor() {
    // console.log('data', this.labelData);
  }

  ngOnInit(): void {
    // console.log('image labelData ==========', this.labelData);
  }

  errorLoad() {
    this.data[this.labelData.userImage] = this.defaultImage;
    // this.imageError.flag = true;
    // this.imageRef.src = '<your_default_img>';
  }
}
