<div class="module" *ngFor="let module of inputData; let i = index">
    <h3 class="font-weight-normal border-bottom mt-2 mb-0">{{i+1}}. {{module?.modulename}}</h3>
    <ul class="list-unstyled">
        <li class="module__list d-flex align-items-start border-bottom cursor-p px-2 py-3" *ngFor="let activity of module?.activityData | jsonFormatter; let j = index" (click)="fireEvent('goToActivity', activity, i, j)">
            <!-- <img src="assets/icon-svg/activity-icons/outline/pdf.svg" alt="pdf" width="16"> *ngIf="activity?.compStatus != 1" -->
            <img class="ml-2" src="assets/icon-svg/activity-icons/outline/{{bindActivityIcon(activity)}}.svg" alt="act-icon">
            <div class="w-100 pl-3">
                <p class="d-flex m-0">{{activity?.activityName}}
                    <!-- <img src="assets/icon-svg/Check.svg" class="ml-1" *ngIf="activity?.compStatus == 1" alt="check" width="16"> -->
                </p>
                <!-- {{}} -->
                <label class="module__list--message line-wrap line-clamp-2 pt-1 m-0" *ngIf="activity?.description" [innerHtml]="activity?.description"></label>
                <div class="module__list--refresher pt-1" (click)="goToRefresherCourse($event, activity)" *ngIf="activity?.refresherDetails"> <a href="javascript:void(0)" class="text-primary hover-underline">Click here</a> to access the refresher course</div>
            </div>
            <span class="module__list--score font-weight-bold text-nowrap" *ngIf="activity?.compStatus == 1 && activity?.displayScore == 1">Score -  {{activity?.score}}/{{activity?.total}}</span>
        </li>
        <!-- <li class="module__list d-flex align-items-center border-bottom cursor-p px-2 py-3">
            <img src="assets/icon-svg/Check.svg" alt="check" width="16">
            <div class="w-100 pl-3">
                <p class="m-0">Contrary to popular belief</p>
                <label class="module__list--message m-0">Completed on 20-Mar-2021</label>
            </div>
            <span class="module__list--score font-weight-bold text-nowrap">Score - 26/100</span>
        </li> -->
    </ul>
</div>