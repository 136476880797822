import { Injectable } from '@angular/core';
import { webApi } from '../../../config';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class H5pPlayerService {

  private headers = new HttpHeaders();

  baseUrl: any = webApi.baseUrl;
  // saveH5pAttemptsurl: any = this.baseUrl + webApi.apiUrl.saveH5pAttempts;
  // saveH5pResultsurl: any = this.baseUrl + webApi.apiUrl.saveH5pResults;
  saveH5pDetailsUrl: any = this.baseUrl + webApi.apiUrl.saveH5pDetails;

  activityCompletionId: any = null;

  constructor(private http: HttpClient) { }

  setActivityCompletionId(activityCompId) {
    this.activityCompletionId = activityCompId;
  }

  getActivityCompletionId() {
    return this.activityCompletionId;
  }

  populateH5pDetail(content) {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const params = {
      userId: currentUser.id,
      empId: currentUser.eid,
      cont_comp_Id: content.areaId == 34 ? content.contentcomplitionId : null,
      act_comp_Id: content.areaId == 34 ? null : content.activitycompletionid,
      contentId: content.contentId,
      completiontype: content.areaId === 34 ? 1 : 2,
      courseId: content.courseId || null,
      moduleId: content.moduleId || null,
      activityId: content.areaId === 34 ? null : (content.activityId || content.instanceId),
      enrolId: content.enrolId,
      completionstatus: content.completionstatus,
      completionstate: content.completionstate,
      viewed: 1,
      contentwatchedtime: content.contentwatchedtime,
      contenttime: content.contenttime,
      languageId: content["defaultlangId"] ? content.defaultlangId : null,
      wfId: content.wfId ? content.wfId : null,
      attemptStr: content.attemptStr ? content.attemptStr : '',
      resultStr: content.resultStr ? content.resultStr : '',
      stepId: content.stepId,
      compAreaId: content.compAreaId ? content.compAreaId : null
    };
    return params;
  }

  getHashSeprator() {
    const separators = JSON.parse(localStorage.getItem('separators'));
    // const pipeSeprator = separators.pipeValue ? separators.pipeValue : '|';
    const hashSeprator = separators.hashValue ? separators.hashValue : '#';
    return hashSeprator;
  }

  populateAttemptDetail(content, h5pRes) {
    // const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const hashSeprator = this.getHashSeprator();
    const params = {
      // userId: currentUser.id,
      // empId: content.empId !== null ? content.empId : 0,
      attemptId: content.attemptId ? content.attemptId : 0,
      // activityId: content.activityId,
      // h5pId: content.contentId,
      // enrollId: content.enrolId !== null ? content.enrolId : 0,
      // languageId: content["defaultlangId"] ? content.defaultlangId : null,
      rawScore: h5pRes.rawScore ? h5pRes.rawScore : 0,
      maxScore: h5pRes.maxScore ? h5pRes.maxScore : 0,
      scaled: h5pRes.scaled ? h5pRes.scaled : 0,
      duration: h5pRes.duration ? h5pRes.duration : 0,
      completionStatus: h5pRes.completionStatus ? h5pRes.completionStatus : 0,
      successStatus: h5pRes.successStatus ? h5pRes.successStatus : 0,
    };
    const paramsStr = Object.values(params).join(hashSeprator);
    console.log('populateAttemptDetail : ', paramsStr);
    return paramsStr;
  }

  populateResultDetail(content, h5pRes) {
    // const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const hashSeprator = this.getHashSeprator();
    const params = {
      // userId: currentUser.id,
      attemptId: content.attemptId ? content.attemptId : 0,
      subContent: h5pRes.subContent ? h5pRes.subContent : '',
      interactionType: h5pRes.interactionType ? h5pRes.interactionType : '',
      description: h5pRes.description ? h5pRes.description : '',
      response: h5pRes.response ? h5pRes.response : '',
      correctResponse: h5pRes.correctResponse ? h5pRes.correctResponse : '',
      additionals: h5pRes.additionals ? h5pRes.additionals : '',
      rawScore: h5pRes.rawScore ? h5pRes.rawScore : 0,
      maxScore: h5pRes.maxScore ? h5pRes.maxScore : 0,
      scaled: h5pRes.scaled ? h5pRes.scaled : 0,
      duration: h5pRes.duration ? h5pRes.duration : 0,
      completionStatus: h5pRes.completionStatus ? h5pRes.completionStatus : 0,
      successStatus: h5pRes.successStatus ? h5pRes.successStatus : 0,
    };
    const paramsStr = Object.values(params).join(hashSeprator);
    console.log('populateResultDetail : ', paramsStr);
    return paramsStr;
  }

  // saveH5pAttempts(param): Promise<any> {
  //   return this.http.post(this.saveH5pAttemptsurl, param, { headers: this.headers })
  //     .toPromise()
  //     .then(response => {
  //       return response = response;
  //     })
  //     .catch(this.handleError);
  // }

  // saveH5pResults(param): Promise<any> {
  //   return this.http.post(this.saveH5pResultsurl, param, { headers: this.headers })
  //     .toPromise()
  //     .then(response => {
  //       return response = response;
  //     })
  //     .catch(this.handleError);
  // }

  saveH5pDetails(param): Promise<any> {
    return this.http.post(this.saveH5pDetailsUrl, param, { headers: this.headers })
      .toPromise()
      .then(response => {
        return response = response;
      })
      .catch(this.handleError);
  }

  private handleError(error: Response | any) {
    return Promise.reject(error.message || error);
  }
}
