import { Component, Input, OnInit, OnChanges, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ngx-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit, OnChanges {
  @Input() checkLabel: any = [];
  @Input() isChecked: any = false;
  @Input() isDisabled: any;
  @Output() performAction = new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges() {
    // console.log('ngOnChanges isChecked ' + this.isChecked);
    // console.log('ngOnChanges isDisabled ' + this.isDisabled);
    // this.isDisabled == 1 ? this.isDisabled = false : this.isDisabled = true;
  }

  emitAction(item) {
    // this.isChecked = !this.isChecked;
    console.log('emitAction', item);
    let checked = item.currentTarget.checked;
    this.performAction.emit(checked);
  }
}
