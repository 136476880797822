import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { webApi } from "../../../config";
@Injectable({
  providedIn: 'root'
})
export class LearnService {
  baseUrl: any = webApi.baseUrl;
  // fetchContentDetailUrl: any = this.baseUrl + webApi.apiUrl.fetchContentDetail;

  checkIfEnrolUrl = this.baseUrl + webApi.apiUrl.isContentEnrol;
  fetchMyLearningFilter: any = this.baseUrl + webApi.apiUrl.fetchMyLearningFilter;

  private headers = new HttpHeaders();
  // getMyCoursesWithFilterUrl = this.baseUrl + this.get_my_courses_with_filter;
  getMyCoursesWithFilterUrl = this.baseUrl + webApi.apiUrl.fetchMyCoursesWithFilter;

  // getLearnTabsListUrl = this.baseUrl + webApi.apiUrl.getMyLearningTabs;
  // getFiltersByPageUrl = this.baseUrl + webApi.apiUrl.getFiltersByPage;
  // getNominationsUrl = this.baseUrl + webApi.apiUrl.getNominations

  // submitCoursefeedback = this.baseUrl + webApi.apiUrl.likeDislikeContent;
  // removeCoursefeedback = this.baseUrl + webApi.apiUrl.likeDislikeContent;
  addRemoveContentFeedbackUrl = this.baseUrl + webApi.apiUrl.likeDislikeContent;

  constructor(public http: HttpClient) { }

  // public fetchContentDetail(params: any): Promise<any> {

  //   return this.http
  //     .post(this.fetchContentDetailUrl, params)
  //     .toPromise()
  //     .then((response) => {
  //       return (response = response);
  //     })
  //     .catch(this.handleError);
  // }

  public isContentEnrol(learnData: any): Promise<any> {
    return this.http.post(this.checkIfEnrolUrl, learnData)
      .toPromise()
      .then(response => {
        return response = response;
      })
      .catch(this.handleError);
  }

  public getLearnCourseList(learnData: any, type: any, subType: any): Promise<any> {
    const url = this.getFetchUrlBasedOnType(type);
    return this.http.post(url, learnData, { headers: this.headers })
      .toPromise()
      .then(response => {
        const res = {
          'data': response,
          'type': type,
          'subType': subType,
        };
        return res;
        // return response
      })
      .catch(this.handleError);
  }

  public getLearnFilter(learnData: any): Promise<any> {
    const url = this.fetchMyLearningFilter;
    return this.http.post(url, learnData, { headers: this.headers })
      .toPromise()
      .then(response => {
        // return response
        return response;
      })
      .catch(this.handleError);
  }

  getFetchUrlBasedOnType(type) {
    return this.getMyCoursesWithFilterUrl;
  }

  // public getLearnTabList(params: any): Promise<any> {
  //   return this.http.post(this.getLearnTabsListUrl, params, { headers: this.headers })
  //     .toPromise()
  //     .then(response => {
  //       return response = response;
  //     })
  //     .catch(this.handleError);
  // }

  // public getFiltersByPage(learnData: any): Promise<any> {
  //   return this.http.post(this.getFiltersByPageUrl, learnData, { headers: this.headers })
  //     .toPromise()
  //     .then(response => {
  //       // return response
  //       return response;
  //     })
  //     .catch(this.handleError);
  // }

  // public getNominations(learnData: any, type: any, subType: any): Promise<any> {
  //   return this.http.post(this.getNominationsUrl, learnData, { headers: this.headers })
  //     .toPromise()
  //     .then(response => {
  //       const res = {
  //         'data': response,
  //         'type': type,
  //         'subType': subType,
  //       };
  //       return res;
  //       // return response
  //     })
  //     .catch(this.handleError);
  // }

  // public submitUserCoursesFeedback(courseData: any): Promise<any> {
  //   return this.http.post(this.submitCoursefeedback, courseData, { headers: this.headers })
  //     .toPromise()
  //     .then(response => {
  //       return response = response;
  //     })
  //     .catch(this.handleError);
  // }

  // public removeUserCoursesFeedback(courseData: any): Promise<any> {
  //   return this.http.post(this.removeCoursefeedback, courseData, { headers: this.headers })
  //     .toPromise()
  //     .then(response => {
  //       return response = response;
  //     })
  //     .catch(this.handleError);
  // }

  public addRemoveContentFeedback(courseData: any): Promise<any> {
    return this.http.post(this.addRemoveContentFeedbackUrl, courseData, { headers: this.headers })
      .toPromise()
      .then(response => {
        return response = response;
      })
      .catch(this.handleError);
  }

  private handleError(error: Response | any) {
    return Promise.reject(error.message || error);
  }
}
