import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
// import { webApi } from '../../../config';
import { popupConfig } from '../../interfaces/popup-config.model';
import { AssignNotifyService } from '../../providers/assign-notify/assign-notify.service';
import { HelperService } from '../../providers/helper/helper.service';
import { ToasterService } from '../../providers/toaster/toaster.service';
@Component({
  selector: 'ngx-assign-notify-component',
  templateUrl: './assign-notify-component.component.html',
  styleUrls: ['./assign-notify-component.component.scss']
})
export class AssignNotifyComponentComponent implements OnInit, OnChanges {

  @ViewChild('notificationEventForm') notificationEventForm: NgForm;

  @Input() popupConfig: popupConfig;
  defaultPopupConfig: popupConfig = {
    header: false,
    title: 'Assign & Notify',
    footer: true,
    transparentHeader: {
      show: true,
    },
    buttons: [
      {
        action: "saveNSubmit",
        btnText: "Save",
        className: 'button-sm btn-fill',
      },
    ]
  };
  @Output() getEvents = new EventEmitter();
  @Output() getdrpClick = new EventEmitter();

  formDetail = {
    eventId: "",
    eventName: "",
    templateId: 1,
    subject: "",
    body: "",
    cc: ""
  };
  loader = true;
  htmlEditorValid = false;
  @Input() config = {
    // api: null,
    // localFilterParams: ['instanceName', 'instanceType'],
    // noDataContainerMessage: '',
    // searchPlaceholder: 'Search Content',
    params: {
      searchString: '', // keyword
      // areaId: '',
      instanceId: null,
      areaId: '2',
      eventIds: '132,133,134',
      type: 1,
      selectedIds: '',
      selectedUserIds: '',
    },
    submitAPI: null,
    // pageParamName: 'pageNo',
    // searchStringParamName: 'searchString',
    // dataLimit: '50',
    // showFooter: true,
    // type: 'manageraAddContent',
  };
  editorConfig = {
    required: true,
  };
  noDataFoundContainer = {
    showImage: true,
    title: 'Events are not available',
    discription: 'Events you are looking for are not available this time, please try after some time.',
  };

  isSubmitted = false;
  notificationEventList = [];
  notFound = false;

  currentPageNo = 1;
  dataLimit = 20;
  searchString = '';
  totalPageCount = 2;
  loading = false;
  usersList = [];
  dropdownSettings = {
    singleSelection: false,
    text: 'Select Users',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    enableSearchFilter: true,
    classes: 'multiselect',
    badgeShowLimit: 1,
    maxHeight: 250,
    lazyLoading: true,
    primaryKey: 'uniqueId',
  };
  selectedEventData = null;

  constructor(
    private toaster: ToasterService,
    private assignNotify: AssignNotifyService,
    private helperService: HelperService
  ) { }

  ngOnInit(): void {
    this.getNotificationEvents();
    if (!this.popupConfig) {
      this.popupConfig = this.defaultPopupConfig;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.popupConfig) {
      this.popupConfig = { ...this.defaultPopupConfig, ...changes.popupConfig.currentValue };
      console.log('Updated config : ', this.popupConfig);
    }
  }

  getNotificationEvents() {
    const params = {
      ...this.config.params,
    };
    // this.load.presentLoading('Please wait...');
    this.loader = true;

    this.assignNotify.getNotificationEvents(params).then(
      (res) => {
        // this.load.dismiss();
        this.loader = false;
        if (res.type === true && res.data && res.data.length != 0) {
          console.log("getNotificationEvents res", res);
          this.notificationEventList = res['data'];
          this.notFound = false;
        } else {
          this.presentToastMessage(
            "Unable to submit , please try again", 'Warning!', 'warning'
          );
          this.notFound = true;
        }
      },
      (err) => {
        // this.load.dismiss();
        console.log(err);
        this.loader = false;
        this.notFound = true;
        this.presentToastMessage(
          "Unable to submit , please try again", 'Warning!', 'warning'
        );
      }
    );
  }

  performActionOnEvent(event) {
    console.log('event', event);
    const action = event.type;
    switch (action) {
      case "saveNSubmit":
        this.checkForValidation(event);
        break;
      case "htmlEditorInputChanged":
        this.htmlEditorValid = event.data.isValid;
        this.formDetail.body = event.data.input;
        break;
      case "close":
        this.fireEvent("closeAssignNotify", null);
        break;
      default:
        console.log('Invalid action', event);
        // this.sendEvents(event.action, event.data);
        break;
    }
  }

  checkForValidation(event) {
    this.isSubmitted = true;
    if (this.notificationEventForm && this.notificationEventForm.valid && this.htmlEditorValid) {
      // this.notificationEventForm.onSubmit(event);
      let eventData = {
        ...this.formDetail
      }
      eventData['cc'] = this.makeCCStringReady();
      this.fireEvent("saveNSubmit", eventData);
    } else {
      // this.notificationEventForm.onSubmit(event);
      Object.keys(this.notificationEventForm.controls).forEach((key) => {
        this.notificationEventForm.controls[key].markAsDirty();
      });
      this.presentToastMessage(
        "Please fill all the required fields", 'Warning!', 'warning'
      );
    }

  }

  eventChanged(event) {
    console.log('selected event id', event);
    // let selectedItem = null;
    // this.notificationEventList.some((element) =>{
    //   if(element['eventId'] == event){

    //   }
    // })
    if (event) {
      let selectedItem = this.helperService.getSelectedArray(this.notificationEventList, 'eventId', event);
      if (selectedItem && selectedItem.length != 0) {
        const obj = { ...selectedItem[0] };
        this.selectedEventData = { ...selectedItem[0] };
        obj['cc'] = obj['cc'] ? JSON.parse(obj['cc']) : [];
        this.formDetail = { ...obj };
        this.resetDropData();
      }
      // else {
      //   this.formDetail = {};
      // }

    }
  }
  async presentToastMessage(msg, title, type) {
    await this.toaster.prsentToast(msg, title, type, null);
  }

  fireEvent(event, data) {
    // if (data.compStatus !== 'UPCOMING') {
    const dataOutput = {
      action: event,
      data: data,
      // liked : isLiked,
    };

    console.log('Fire event called : ', dataOutput);
    this.getEvents.emit(dataOutput);
    // } else {
    //   return false;
    // }
  }

  /*************** Get infinite scroll data start ************/
  resetDropData() {
    this.usersList = [];
    this.currentPageNo = 1;
    this.loading = false;
    this.searchString = '';
    this.totalPageCount = 2;

  }
  fetchMore(event: any, index) {
    console.log('fetchMore', index);
    setTimeout(() => {
      if (event.end === this.usersList.length - 1) {
        if (this.currentPageNo > this.totalPageCount) {

        } else {
          if (!this.loading) {
            this.loading = true;
            this.fetchDropdownValue(this.currentPageNo, () => {
              this.loading = false;
              console.log("Done");
            });
          }
        }
      }
    }, 500);
    console.log('endIndex')
    // }
  }

  fetchDropdownValue(currentPageNo, cb) {
    this.currentPageNo = currentPageNo;
    const params = {
      pageLimit: this.dataLimit,
      pageNo: currentPageNo,
      // typeId: dropdown.typeid,
      searchString: this.searchString,
      eventId: this.formDetail.eventId,
      platformId: 3,
      selectedIds: '',
    };
    console.log('params', params);
    this.fetchDropdownList(params, (result) => {
      if (result.type === true) {
        if (currentPageNo !== 1) {
          const nextcontentList = result.data;
          this.usersList = this.usersList.concat(
            nextcontentList
          );
        } else {
          this.usersList = result.data ? result.data : [];
          this.totalPageCount = result.totalPages;
        }
        if (this.usersList.length > 0) {
          this.currentPageNo++;
        } else {

        }
        cb(true);
      } else {
        cb(false);

      }
    });

  }

  fetchDropdownList(params: any, cb) {
    this.assignNotify.getNotificationCCList(params).then(res => {
      console.log(params.pageNo, ' page data list ', res);
      cb(res);
    },
      (err) => {
        console.log('err ', err);
        cb(null);
        // this.presentToastMessage('Unable to get results at this time.', 'Warning!', 'warning');
      }
    );
  }
  onSearch(evt: any) {
    console.log(evt);
    const string = evt;
    if (string.length >= 3 || (string.length == 0)) {
      this.searchString = string;
      this.triggerSearch(string);
    } else if (string.length == 0) {
      this.searchString = string;
      this.triggerSearch(string);
    }
  }

  triggerSearch(searchCourseTerm) {
    console.log("searchCourseTerm ", searchCourseTerm);
    if (!this.loading) {
      this.usersList = [];
      this.currentPageNo = 1;
      this.loading = true;
      this.fetchDropdownValue(this.currentPageNo, () => {
        this.loading = false;
        console.log("Done");
      });
    }
  }
  /*************** END infinite scroll data start ************/


  makeCCStringReady() {
    return this.helperService.makeSelectedStrReady(this.formDetail.cc, 'uniqueId', this.selectedEventData.joiner, false);
  }

  onOpen(event) {
    this.getdrpClick.emit(event)
    console.log('event log', event);
  }
}
