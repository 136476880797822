<div class="inner-list cursor-p">
  <div class="row ">
    <div class="inner-list--left col-md-4 pr-1" (click)="fireEvent('goToContent',cardsData)">
      <div class="course-image position-relative">
        <!-- <span class="layer position-absolute" *ngIf="item.isDisable == 1"> <i
                        class="fa fa-lock position-absolute"></i> </span> -->
        <img [src]="bindBackgroundImage(cardsData[config?.image])"
          onerror="this.onerror=null;this.src='assets/images/activity1.jpg';" alt="Course Image">
      </div>
    </div>
    <div class="inner-list--right col-md-8 d-flex flex-column justify-content-between">
      <div (click)="fireEvent('goToContent',cardsData)">
        <label class="d-block m-0 workflow">
          <!-- cardsData[config.instanceTypeIcon] -->
          <svg-icon [src]="cardsData[config.instanceTypeIcon]" [stretch]="true"></svg-icon>
          <span>{{cardsData[config.instanceType]}}</span>
        </label>
        <h2 class="m-0">{{cardsData[config.title]}}</h2>
        <p class="m-0">{{cardsData[config.description]}}</p>
      </div>

      <div class="d-flex align-items-end justify-content-between">
        <div>
          <div class="d-flex align-items-center pb-2">
            <div class="d-flex align-items-center lh-1">
              <svg-icon svgClass="stroke-gray fill-white" src="../../../assets/icon-svg/User_icon.svg"
                [svgStyle]="{'width.rem': 1, 'height.rem': 1, 'stroke-width': 1.4}"></svg-icon>
              <span class="fs-14 pl-1">{{cardsData[config.author] ?cardsData[config.author] :
                'NA'}}</span>
            </div>
            <!-- likes -->
            <ng-container *ngIf="config['likeEnabled']">
              <span class="h-16-px w-1-px bg-gray mx-2"></span>
              <div class="d-flex align-items-center lh-1">
                <svg-icon svgClass="stroke-gray fill-white" src="../../../assets/icon-svg/Thumbs_up_icon.svg"
                  [svgStyle]="{'width.rem': 1, 'height.rem': 1, 'stroke-width': 1.4}"></svg-icon>
                <span class="fs-14 pl-1">{{ cardsData[config['likeCount']] || 0 }} Likes</span>
              </div>
            </ng-container>
            <!-- dislikes -->
            <ng-container *ngIf="config['dislikeEnabled']">
              <span class="h-16-px w-1-px bg-gray mx-2"></span>
              <div class="d-flex align-items-center lh-1">
                <svg-icon svgClass="stroke-gray fill-white" src="../../../assets/icon-svg/Thumbs_down_icon.svg"
                  [svgStyle]="{'width.rem': 1, 'height.rem': 1, 'stroke-width': 1.4}"></svg-icon>
                <span class="fs-14 pl-1">{{ cardsData[config['dislikeCount']] || 0 }} Dislikes</span>
              </div>
            </ng-container>


            <div class="d-flex align-items-center lh-1"
              *ngIf="config?.languageEnabled && cardsData[config?.languageName]">
              <span class="h-16-px w-1-px bg-gray mx-2"></span>
              <svg-icon svgClass="stroke-gray fill-white" src="assets/icon/svg/course-card/language.svg"
                [svgStyle]="{'width.rem': 1, 'height.rem': 1, 'stroke-width': 1.4}"></svg-icon>
              <span class="fs-14 pl-1"> {{cardsData[config?.languageName]}}</span>
            </div>


          </div>
          <!-- <p class="author m-0"> By : <span>{{cardsData[config.author] ?cardsData[config.author] : 'NA'
                            }}</span></p> -->
          <!--  *ngIf="cardsData[config?.rating]" -->
          <star-rating *ngIf="config?.showStars" [starType]="'svg'" [size]="'medium'"
            [rating]="cardsData[config?.rating] ? cardsData[config?.rating] : 0" [showHalfStars]="true"
            [numOfStars]="cardsData[config?.numberOfStars]" [readOnly]="true"></star-rating>
        </div>
        <div class="allIcons text-right position-relative cursor-p" (mouseleave)="mouseLeave(cardsData)">
          <span class="more" (mouseenter)="mouseEnter(cardsData)">More
            <i class="fa fa-caret-down  option cursor-p"></i>
          </span>
          <div class="showlist position-absolute" [nbSpinner]="cardsData?.loading" nbSpinnerStatus="primary"
            nbSpinnerMessage="Loading..." nbSpinnerSize="large" *ngIf="show">
            <!-- <label class="m-0 d-flex align-items-center cursor-p" (click)="fireEvent('schedule',cardsData)">
              <svg-icon src="../../../assets/icon-svg/course-card/clock.svg" [stretch]="true">
              </svg-icon>
              <span class="more-text" *ngIf="cardsData[config?.scheduleFlag] ==0">Schedule</span>
              <span class="more-text" *ngIf="cardsData[config?.scheduleFlag] !=0">Reschedule</span>
            </label>
                        <label class="m-0 d-flex align-items-center cursor-p" (click)="fireEvent('addToCart',cardsData)">
              <svg-icon src="../../../assets/icon-svg/course-card/cart.svg" [stretch]="true">
              </svg-icon>
              <span class="more-text" *ngIf="cardsData[config?.goToCartFlag] != 0">Go To Cart</span>
              <span class="more-text" *ngIf="cardsData[config?.goToCartFlag] == 0">Add To Cart</span>
            </label> -->
            <ng-container *ngFor="let item of buttons">
              <!-- (click)="fireEvent( item.event.id,  item.event.value) -->
              <label class="m-0 d-block align-items-center cursor-p " (click)="optionClicked(item.event, cardsData)">
                <svg-icon [src]="item?.icon" [stretch]="true ">
                </svg-icon>
                <span class="more-text">{{ item?.text }}</span>
              </label>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
