import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { SharedService } from '../../providers/shared.service';

@Component({
  selector: 'ngx-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {

  @Input() config = {
    filterTitle: 'Filter',
    innerFilterData: [
      // {
      //   parentSectionTitle: 'Parent name 1',
      //   showParentSectionTitle: 1,
      //   expanded: 1,
      //   showSearchBar: 1,
      //   searchTerm: '',
      //   localFilterParams: ['itemName'],
      //   noDataContainerMessage: 'No Data',
      //   searchPlaceholder: 'Search Content',
      //   filterMetadata: { count: 11 },
      //   type: 2,
      //   displayKey: 'itemName',
      //   parameterKey: 'parentName1',
      //   paramterValueKey: 'key',
      //   selectedValue: '1',
      //   list: [
      //     {
      //       itemName: 'Item 1',
      //       key: '1',
      //       selected: 1
      //     },
      //     {
      //       itemName: 'Item 2',
      //       key: '2',
      //       selected: 0
      //     }
      //   ]
      // },
      // {
      //   parentSectionTitle: 'Parent name 2',
      //   showParentSectionTitle: 1,
      //   expanded: 1,
      //   showSearchBar: 1,
      //   searchTerm: '',
      //   localFilterParams: ['itemName'],
      //   noDataContainerMessage: 'No Data',
      //   searchPlaceholder: 'Search Content',
      //   filterMetadata: { count: 11 },
      //   type: 2,
      //   displayKey: 'itemName',
      //   parameterKey: 'parentName1',
      //   paramterValueKey: 'key',
      //   list: [
      //     {
      //       itemName: 'Item one',
      //       key: '1',
      //       selected: 1
      //     },
      //     {
      //       itemName: 'Item two',
      //       key: '2',
      //       selected: 0
      //     }
      //   ]
      // },
      // {
      //   parentSectionTitle: 'Date',
      //   showParentSectionTitle: 1,
      //   expanded: 1,
      //   showSearchBar: 1,
      //   searchTerm: '',
      //   localFilterParams: ['itemName'],
      //   noDataContainerMessage: 'No Data',
      //   searchPlaceholder: 'Search Content',
      //   filterMetadata: { count: 11 },
      //   displayKey: 'itemName',
      //   parameterKey: 'parentName2',
      //   paramterValueKey: 'key',
      //   type: 3,
      //   list: [
      //     {
      //       itemName: 'Item one',
      //       key: '1',
      //       selected: 0
      //     },
      //     {
      //       itemName: 'Item two',
      //       key: '2',
      //       selected: 1
      //     }
      //   ]
      // }
    ],
    selectedFilterValue: null,
  };

  @Output() getEvent = new EventEmitter();
  selectedFilterValue = {};

  @Input() selectedTab: any;
  @Input() isLoading: any;

  @ViewChild("formpicker") formpicker: any;

  constructor(
    private sharedService: SharedService,
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.config) {
      this.selectedFilterValue = {};
      this.makeIntialSelectedDataReady();
    }
  }

  makeIntialSelectedDataReady() {
    // this.selectedFilterValue = { ...this.selectedFilterValue, ...this.config.selectedFilterValue };
    //  && !this.sharedService.checkIfParams(this.config.selectedFilterValue)
    if (this.config) {
      if (Array.isArray(this.config.innerFilterData)) {
        // if (this.config && Array.isArray(this.config.innerFilterData)) {
        for (let index = 0; index < this.config.innerFilterData.length; index++) {
          const element: any = this.config.innerFilterData[index];
          const key = element['parameterKey'];

          if (!this.selectedFilterValue[key]) {
            this.selectedFilterValue[key] = {
              type: element['type'],
              parameterKey: element['parameterKey'],
              paramterValueKey: element['paramterValueKey'],
              extraData: element['extraData'],
              mainSeperator: element['outerSeparator'],
              secondarySeperator: element['innerSeparator'],
              selectedValue: [],
              selectedDate: {
                start: undefined,
                end: undefined
              }
            }
          };

          if (element.type == 3) {
            element.selectedValue = [];
          }


          if (element.list) {
            element.list.every((item, index) => {
              if (element.type == 1 && item[element['paramterValueKey']] == element.selectedValue) {
                this.pushDataBasedOnType(element, item['selected'], item, key);
                return false;
              } else if (element.type == 2) {
                if (item['selected'] == 1) {
                  // this.applyFilterData(null, item, element);
                  this.pushDataBasedOnType(element, item['selected'], item, key)
                }
                // else if (this.config.selectedFilterValue) {
                //   const tempKey = this.config.selectedFilterValue[key];
                //   if (tempKey && tempKey.selectedValue) {
                //     tempKey.selectedValue.every(selectedVal => {
                //       if (item.key == selectedVal.key) {
                //         // element.list[index]['selected'] = 1;
                //         item['selected'] = 1;
                //         this.pushDataBasedOnType(element, item['selected'], item, key);
                //       }
                //       return true;
                //     })
                //   }
                // }
                return true;
              }
            });
          }


          // console.log('Updated list : ', element.list);
        }
        // }

        this.fireEvent('filterValueChanged', this.selectedFilterValue);
        this.fireEvent('selectedFilterList', this.config?.innerFilterData);
      } else {
        console.log('Filter data is not array...');
      }
    } else {
      this.selectedFilterValue = this.config.selectedFilterValue;
      console.log('Already selected...', this.selectedFilterValue);
    }
  }

  clearAll() {
    if (this.sharedService.checkIfParams(this.selectedFilterValue)) {
      this.fireEvent('clearAllFilter', null);
    } else {
      console.log('No filter selected...');
    }
  }

  fireEvent(type, data) {
    const dataOutput = {
      type: type,
      data: data,
    };
    console.log('Fire event called : ', dataOutput);
    this.getEvent.emit(dataOutput);
  }

  toggelSectionCollpase(item) {
    item['expanded'] = item['expanded'] == 0 ? 1 : 0;
  }

  applyFilterData(event, item, parentItem) {
    console.log('applyFilterData $event, item, parentItem', event, item, parentItem);
    let isValid = true;
    const key = parentItem['parameterKey'];
    let action = 0;
    if (parentItem.type == 1) {
      console.log('radio type : ', item);
      parentItem.selectedValue = item[parentItem['paramterValueKey']];
      this.selectedFilterValue[key]['selectedValue'] = [item];
    } else if (parentItem.type == 2) {
      console.log('checkbox type : ', item);
      action = item['selected'] == 1 ? 0 : 1;
      item['selected'] = action;
    } else if (parentItem.type == 3) {
      console.log('date type : ', item);
      if (event.length == 1) {
        isValid = false;
      }
      item = event;
    } else if (parentItem.type == 4) {
      console.log('toggle type : ', item);
      // const key =
      item = {};
      item[parentItem['paramterValueKey']] = parentItem.selectedValue == 1 ? 0 : 1;
      // item[parentItem.parameterKey] =
      if (item[parentItem['paramterValueKey']] == 1) {
        parentItem.selectedValue = item[parentItem['paramterValueKey']];
      } else {
        parentItem.selectedValue = item[parentItem['paramterValueKey']];
        item = null;
      }
    } else {
      console.log('invalid type');
      return;
    }
    this.pushDataBasedOnType(parentItem, action, item, key)
    console.log('result this.selectedFilterValue', this.selectedFilterValue);
    if (isValid) {
      this.fireEvent('filterValueChanged', this.selectedFilterValue);
      this.fireEvent('selectedFilterList', this.config?.innerFilterData);
    }
  }

  pushDataBasedOnType(parentItem, action, item, key) {
    if (parentItem.type == 1 || parentItem.type == 4) {
      this.selectedFilterValue[key]['selectedValue'] = item ? [item] : [];
    } else if (parentItem.type == 2) {
      this.checckedUncheckedItem(parentItem['paramterValueKey'], action, item, this.selectedFilterValue[key]['selectedValue']);
    } else if (parentItem.type == 3) {
      let newArray = [];
      if (item) {
        if (Array.isArray(item)) {
          item.forEach((element) => {
            newArray.push(this.convertDate(element));
          })
        } else {
          newArray.push(this.convertDate(item));
        }
        // parentItem['selectedValue'] = newArray;
      } else {
        parentItem['selectedValue'] = newArray;
      }
      this.selectedFilterValue[key]['selectedValue'] = newArray;
    }
  }

  convertDate(date) {
    if (date.toDate) {
      return date.toDate();
    } else {
      return date;
    }
  }

  checckedUncheckedItem(key, action, item, array) {
    if (action == 0) {
      console.log('remove from selected', key, item);
      this.removeFromSelected(item, array, key);
    } else {
      console.log('add to selected');
      array.push(item);

    }
    console.log('result array', array);
  }

  removeFromSelected(item, arr, property) {
    if (arr.length > 0) {
      this.removeByAttr(arr, property, item[property]);
    } else {
      console.log("Selected array is empty");
    }
  }

  removeByAttr(arr, attr, value) {
    let i = arr.length;
    while (i--) {
      if (
        arr[i][attr] === value
        // JSON.stringify(arr[i]) === JSON.stringify(value)
      ) {
        arr.splice(i, 1);
      }
    }
    return arr;
  }

  /******** date range selection start *******/
  resetDate(key) {
    this.selectedFilterValue[key].selectedDate = {
      start: undefined,
      end: undefined
    }
    this.selectedFilterValue[key].selectedValue = [];
  }

  clearDate(event, item, parentItem) {
    console.log('clearDate $event, item, parentItem', event, item, parentItem);
    const key = parentItem.parameterKey;
    if (this.selectedFilterValue[key].selectedDate.start) {
      if (this.selectedFilterValue[key].selectedDate.end) {
        this.resetDate(key);
        this.applyFilterData([], item, parentItem);
      } else {
        this.resetDate(key);
      }
    }
  }

  dateChanged(event, item, parentItem) {
    console.log('dateChanged $event, item, parentItem', event, item, parentItem);
    let selectedDateRange = [];
    if (event.start || (event.start && event.end)) {
      if (event.end) {
        selectedDateRange = [event.start, event.end];
        // this.removeDatePickerFocus();
      } else {
        selectedDateRange = [event.start];
      }
      this.applyFilterData(selectedDateRange, item, parentItem);
    }
  }

  removeDatePickerFocus() {
    console.log('Elem : ', this.formpicker);
    // const input: any = document.querySelector("#formpicker");
    const input: any = document.getElementById("dateRangePicker");
    input.blur();
  }
  /******** date range selection end *******/

}
