<p class="body-text cursor-d m-0" (click)="textClick()" title="{{ text }}">
    <ng-container *ngIf="text !=null">
        <!-- setting - {{setting?.length}} -->
        <ng-container *ngIf="setting?.length ==0">
            {{ text }}
        </ng-container>
        <ng-container *ngIf="setting?.maxLength">
            <!-- {{ text.toString().trim() | slice:0:setting?.maxLength}}
            <ng-container *ngIf="text.length > setting?.maxLength">...
            </ng-container> -->
            {{text | truncate : setting?.maxLength}}
        </ng-container>
    </ng-container>

</p>