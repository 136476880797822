import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'ngx-trainer-evaluate-activity',
  templateUrl: './trainer-evaluate-activity.component.html',
  styleUrls: ['./trainer-evaluate-activity.component.scss']
})
export class TrainerEvaluateActivityComponent implements OnInit {
  @Output() componentData = new EventEmitter();
  @Input() activity: any = {};
  @Input() feedbackComponentActivityData: any = {};
  showComponent = '';
  currentUser = null;
  paramObject = null;
  constructor() {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));

  }

  ngOnInit(): void {
    this.paramObject = {
      courseId: this.activity.courseId,
      searchStr: '',
      // pageNo: 0,
      // limit: '',
      wfId: this.activity.workflowId,
      typeId: 5,
      activityId: this.activity.activityId
    }
    this.showComponent = 'showUsers';
  }


  performActionOnEvent(event) {
    console.log('event', event);
    const action = event.action.action ? event.action.action : event.action;
    switch (action) {
      case "evaluateFeedbackInternal":
        console.log('evaluateFeedbackInternal event', event);
        this.makeFeedbackDataReady(event.data);
        break;
      case "goBack":
        this.showComponent = 'showUsers';
        break;
      default:
        console.log('Invalid action', event);
        // this.sendEvents(event.action, event.data);
        break;
    }
  }

  makeFeedbackDataReady(data) {
    this.feedbackComponentActivityData = { ...this.activity, ...data };
    this.showComponent = 'feedback';
  }

  performActionOnChildComponentData(event) {
    this.componentData.emit(event);
  }
}
