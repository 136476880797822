import { Component, OnInit, Input } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { NbDialogRef } from "@nebular/theme";
@Component({
  selector: 'ngx-image-picker',
  templateUrl: './image-picker.component.html',
  styleUrls: ['./image-picker.component.scss']
})
export class ImagePickerComponent implements OnInit {
  @Input() config: any = {
    imageSource: '',
  };
  croppedImage: any = '';
  showCropper = false;
  intialImage = false;
  selectImage = false;

  constructor(protected ref: NbDialogRef<ImagePickerComponent>) { }

  ngOnInit(): void {
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    console.log('Crop Image ===>', this.croppedImage);
  }
  imageLoaded() {
    this.showCropper = true;
    console.log('Image loaded');
  }
  dissmissImageCropPopModal(index) {
    let object = {
      action: 'dismissPopup',
      image: this.croppedImage
    }
    if (index == 1) {
      object = {
        action: 'upload',
        image: this.croppedImage
      }
    } else if (index == 2) {
      object = {
        action: 'remove',
        image: this.croppedImage
      }
    }
    this.ref.close(object);
  }

  loadImageFailed(event) {
    console.log('loadImageFailed', event);
  }
}
