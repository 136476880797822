import { Injectable } from '@angular/core';
import { webApi } from "../../../../config";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class AssessorWorkflowDetailsService {
  private baseUrl: any = webApi.baseUrl;
  private getAccesorTabsUrl: any =
    this.baseUrl + webApi.apiUrl.getAccesorTabs;
  private getCIPNomineeUrl: any =
    this.baseUrl + webApi.apiUrl.getCIPNomineeList;
  private getVivaSlotsUrl: any =
    this.baseUrl + webApi.apiUrl.getVivaSlots;
  private getAssessorList: any = this.baseUrl + webApi.apiUrl.getAssessorList;
  courseData = null;
  constructor(private http: HttpClient) { }

  /*--------------Get TABS Data----------------*/

  public getTabs(data): Promise<any> {
    return this.http
      .post(this.getAccesorTabsUrl, data)
      .toPromise()
      .then((response) => {
        return (response = response);
      })
      .catch(this.handleError);
  }
  /*--------------END TABS Data----------------*/

  /*--------------Get TABS Data----------------*/

  public getCIPNominee(data): Promise<any> {
    return this.http
      .post(this.getCIPNomineeUrl, data)
      .toPromise()
      .then((response) => {
        return (response = response);
      })
      .catch(this.handleError);
  }
  /*--------------END TABS Data----------------*/


  /*--------------Get Slots Data----------------*/

  public getSlotsDetails(data): Promise<any> {
    return this.http
      .post(this.getVivaSlotsUrl, data)
      .toPromise()
      .then((response) => {
        return (response = response);
      })
      .catch(this.handleError);
  }
  /*--------------END TABS Data----------------*/

  public async getAssessorLearner(data: any): Promise<any> {
    try {
      const response = await this.http.post(this.getAssessorList, data).toPromise();
      return response;
    } catch (error) {
      return this.handleError(error);
    }
  };
  private handleError(error: Response | any) {
    return Promise.reject(error.message || error);
  }
}
