<div class="modal-dialog dialog modal-dialog-centered" role="document">
    <div class="modal-content" #parent>
        <div class="modal-header header">
            <p>Select the date to receive notification</p>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closePopup('out');this.currentTimeValidationMessage = false">
        <span aria-hidden="true">&times;</span>
      </button>
        </div>
        <div class="modal-body body py-0 d-flex justify-content-center">
            <div class="modal-left left">
                <!-- <div class="input-group d-flex align-items-baseline cursor-p pb-4" (click)="toogle(1)">
          <input type="radio" class="pr-3" [checked]="checked1" aria-label="Radio button for following text input" />
          <span class="pl-3">Standard Notification</span>
        </div> -->
                <!-- <div class="input-group align-items-baseline cursor-p" (click)="toogle(2)">
          <input type="radio" class="pr-3" [checked]="checked2" aria-label="Radio button for following text input" />
          <span class="pl-3">Personalized Notification</span>
        </div> -->
            </div>
            <div class="modal-right d-flex align-items-center">

                <div *ngIf="checked1">

                    <div class="row justify-content-center">
                        <div class="col-auto">

                            <dp-day-calendar theme="dp-material" [(ngModel)]="dateTime3" min="date" (onSelect)="getDate($event)" disabled="checked2 == true" [config]="dateconfig"></dp-day-calendar>
                        </div>
                        <div class="d-flex  col-auto">
                            <div class=" d-flex flex-column justify-content-between" *ngIf="checked2 == true">
                                <div class="card custom-card d-flex flex-row align-items-baseline p-2">
                                    <div>
                                        <dp-time-select [(ngModel)]="time1" theme="dp-material" mode="time" [config]="datePickerConfig" (onChange)="onchange($event)">
                                        </dp-time-select>
                                    </div>
                                    <div>
                                        <label class="container">
                  <input type="checkbox"  [disabled]="!time1" (click)="onTime1($event)" />
                  <span class="checkmark"></span>
                </label>
                                    </div>
                                </div>
                                <div class="card custom-card d-flex flex-row align-items-baseline p-2">
                                    <div>
                                        <dp-time-select [(ngModel)]="time2" theme="dp-material" mode="time" [config]="datePickerConfig" (onChange)="onchange($event)">
                                        </dp-time-select>
                                    </div>
                                    <div>
                                        <label class="container">
                  <input type="checkbox" [disabled]="!time2" (click)="onTime2($event)" />
                  <span class="checkmark"></span>
                </label>
                                    </div>
                                </div>
                                <div class="card custom-card d-flex flex-row align-items-baseline p-2">
                                    <div>
                                        <dp-time-select [(ngModel)]="time3" theme="dp-material" mode="time" [config]="datePickerConfig" (onChange)="onchange($event)">
                                        </dp-time-select>
                                    </div>
                                    <div>
                                        <label class="container">
                  <input type="checkbox" [disabled]="!time3" (click)="onTime3($event)" />
                  <span class="checkmark"></span>
                </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="example-wrapper notification" *ngIf=" checked1 == true ">
                        <p class=" pt-2 m-0" *ngIf="!submitted && dataSubmittedForTab && checked2 == false && currentTimeValidationMessage == false">
                            Thank You. You will receive a notification at 10 am on {{getDisplayDate(dateTime3) }} .
                        </p>
                        <p class=" pt-2 m-0" *ngIf="submitted && showPersonalized && currentTimeValidationMessage == false && timeValuesSame == false && callClose == true">
                            Thank You. You will receive a notification at {{DisplayTime('null',time1,time2,time3,2,'sub')}} on {{getDisplayDate(dateTime3) }}.
                        </p>
                        <p class=" pt-2 m-0 text-danger" *ngIf="currentTimeValidationMessage == true">
                            Kindly schedule personalized notification for today's date.
                        </p>
                        <p class=" pt-2 m-0 text-danger" *ngIf=" timeValuesSame == true">
                            You cannot save the duplicate time.
                        </p>
                    </div>

                    <div class="example-wrapper" (click)="getPersonalized()" *ngIf="showPersonalized && checked2 == false">
                        <p class="text-center pt-2 m-0 cursor-p personalized">
                            Personalized Notification
                        </p>
                    </div>


                </div>

            </div>
            <div>
                <!-- <div class="d-flex pt-3 col-6"> -->
                <!-- <div class="example-wrapper" *ngIf="checked2">
            <dp-day-calendar theme="dp-material" [(ngModel)]="dateTime3" (onSelect) = "getDate($event)"></dp-day-calendar>
          </div> -->
                <!-- <div class="pl-5 d-flex flex-column justify-content-between" *ngIf="checked2 == true">
            <div class="card custom-card d-flex flex-row align-items-baseline p-2">
              <div>
                <dp-time-select [(ngModel)]="time1" theme="dp-material" mode="time" [config]="datePickerConfig" (onChange) = "onchange($event,1)">
                </dp-time-select>
              </div>
              <div>
                <label class="container">
                  <input type="checkbox" [disabled]="!time1" (click)="onTime1($event)" />
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>
            <div class="card custom-card d-flex flex-row align-items-baseline p-2">
              <div>
                <dp-time-select [(ngModel)]="time2" theme="dp-material" mode="time" [config]="datePickerConfig"(onChange) = "onchange($event,2)">
                </dp-time-select>
              </div>
              <div>
                <label class="container">
                  <input type="checkbox" [disabled]="!time2" (click)="onTime2($event)" />
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>
            <div class="card custom-card d-flex flex-row align-items-baseline p-2">
              <div>
                <dp-time-select [(ngModel)]="time3" theme="dp-material" mode="time" [config]="datePickerConfig"(onChange) = "onchange($event,2)">
                </dp-time-select>
              </div>
              <div>
                <label class="container">
                  <input type="checkbox" [disabled]="!time3" (click)="onTime3($event)" />
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>
          </div>  -->
                <!-- </div> -->
            </div>
        </div>
        <div class="modal-footer footer">
            <button type="button" class="customBtnWhite" data-dismiss="modal" (click)="closePopup('out')">Cancel</button>
            <button type="button" class="commonBtn" [disabled]="!dateTime3   " [ngClass]="{ 'disabled': !dateTime3 }" *ngIf="checked1 && showPersonalized == false" (click)="prepareObject(dateTime3,time1,time2,time3,1,'submit',1)">Submit</button>
            <!-- <button type="button" class="btn btn-primary"[disabled] = "!dateTime3  ||  !(time1valid && time2valid && time3valid) " *ngIf = "checked2"(click) = "prepareObject(dateTime3,timePicker,'submit')">Submit</button> -->
            <button type="button" class="commonBtn" [disabled]="!dateTime3  ||  (!time1valid && !time2valid && !time3valid) " [ngClass]="{ 'disabled': !dateTime3 ||  (!time1valid && !time2valid && !time3valid) }" *ngIf="checked2 && showPersonalized == true" (click)="prepareObject(dateTime3,time1,time2,time3,2,'submit')">Submit</button>


        </div>
        <!-- <div class="modal-footer footer">
      <button type="button" class="btn btn-light" data-dismiss="modal" (click)="this.ref.close('close')">Cancel</button>
      <button type="button" class="btn btn-primary" [disabled]="!dateTime3" *ngIf="checked1"
        (click)="prepareObject(dateTime3,timePicker,'submit')">Submit</button>
      <button type="button" class="btn btn-primary"
        [disabled]="!dateTime3  ||  (!time1valid && !time2valid && !time3valid) " *ngIf="checked2"
        (click)="prepareObject(dateTime3,time1,time2,time3,'submit')">Submit</button>
    </div> -->
    </div>
</div>