import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'ngx-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})
export class StepperComponent implements OnInit {
  @Input() stepper: any;
  @Input() stepperConfig: any;
  @Input() custom: any;
  @Input() complete: any;
  @Input() orientation: string;
  @Input() number: boolean = false;
  @Output() stepClick = new EventEmitter<any>();
  
  constructor() { }

  ngOnInit(): void {
  }

}
