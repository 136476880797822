import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {

  transform(value, limit = 25, completeWords = false, ellipsis = '...') {
    if (!value) {
      return value;
    }
    const dataString = String(value);
    if (completeWords) {
      limit = dataString.substr(0, limit).lastIndexOf(' ');
    }
    return dataString.length > limit ? value.substr(0, limit) + ellipsis : value;
  }

}
