<div [hidden]="!show" class="popup-bg" (click)="closeClick()"></div>
<div class="popup">
    <div class="popup__dialog" [ngClass]="{'show': show}">
        <div class="popup__content">
            <div class="popup__header border-bottom p-3" *ngIf="popupConfig.header">
                <h3 class="d-inline text-white font-weight-normal">{{ popupConfig.title }}</h3>
                <!-- [svgClass]="popupConfig?.headerIcon?.iconClass" -->
                <svg-icon class="popup__header--close stroke-white float-right cursor-p" [svgStyle]="{'width.rem': .8, 'height.rem': .8}" (click)="closeClick()" [src]="'assets/icon-svg/x.svg'" alt="icon" [stretch]="true">
                </svg-icon>
                <ng-container *ngIf="popupConfig?.headerIcon?.show">
                    <!-- <img class="popup__header--close float-right mr-2 cursor-p" width="14" height="14" *ngFor="let item of popupConfig.headerIcon.buttons" (click)="fireEvent(item.action, item)" [src]="item?.icon" alt="icon"> -->
                    <svg-icon svgClass="popup__header--close float-right cursor-p mr-2" *ngFor="let item of popupConfig.headerIcon.buttons" (click)="fireEvent(item.action, item)" [src]="item?.icon" alt="icon" [stretch]="true">
                    </svg-icon>
                </ng-container>
            </div>
            <div class="popup__header bg-white p-3" *ngIf="popupConfig?.transparentHeader?.show">
                <h3 class="d-inline font-weight-normal">{{ popupConfig.title }}</h3>
                <svg-icon svgClass="popup__header--close stroke-gray float-right cursor-p" [svgStyle]="{'width.rem': .8, 'height.rem': .8}" (click)="closeClick()" [src]="'assets/icon-svg/x.svg'" alt="icon" [stretch]="true">
                </svg-icon>
            </div>
            <div class="popup__body p-3">
                <ng-content></ng-content>
            </div>
        </div>
        <div class="popup__footer text-right border-top w-100 p-3" *ngIf="popupConfig.footer">
            <!-- <button class="button-sm btn-outline" *ngIf="popupConfig.buttonOne">{{ popupConfig.btnoneLabel }}</button>
            <button class="button-sm btn-fill" *ngIf="popupConfig.buttonTwo">{{ popupConfig.btntwoLabel }}</button> -->
            <button type="button" class="{{item?.className}}" *ngFor="let item of popupConfig?.buttons; let isFirst = first" [ngClass]="{ 'ml-2': !isFirst }" (click)="fireEvent(item.action,item)">
              {{ item?.btnText }}
            </button>
        </div>
    </div>
</div>
