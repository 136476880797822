<div [nbSpinner]="loading" nbSpinnerMessage="Loading..." nbSpinnerStatus="primary"></div>

<div *ngIf="!loading" class="d-flex flex-column align-items-center justify-content-center h-100 w-100">
    <div class="text-16-px py-2" *ngIf="dependencyConatiner?.title">
        {{dependencyConatiner?.title}}
    </div>
    <div class="text-18-px font-weight-bold py-2" *ngIf="dependencyConatiner?.subTitle">
        {{dependencyConatiner?.subTitle}}
    </div>
    <ng-container *ngIf="dependencyConatiner?.btnShow">
        <button class="button btn-fill mt-3" [ngClass]="{'disabled': dependencyConatiner?.btnDisabled}"
            [disabled]="dependencyConatiner?.btnDisabled" (click)="markAttendance()">
            {{dependencyConatiner?.btnText}}
        </button>
    </ng-container>
    <div class="text-16-px text-center p-3" *ngIf="dependencyConatiner?.msg">
        {{dependencyConatiner?.msg}}
    </div>
</div>