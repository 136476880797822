import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import {
  CustomEvent,
  ImageViewerConfig,
} from "../../../../components/image-viewer/image-viewer-config.model";
import { ActivityService } from "../../../../providers/activity/activity.service";
import { ModuleService } from "../../../../providers/module/module.service";
import { ToasterService } from "../../../../providers/toaster/toaster.service";
@Component({
  selector: "ngx-quiz",
  templateUrl: "./quiz.component.html",
  styleUrls: ["./quiz.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class QuizComponent implements OnInit, OnDestroy, OnChanges {
  @Output() componentData = new EventEmitter();
  @Input() courseDataContentInput: any = {};
  @Input() courseDataSummaryInput: any = {};

  courseDataSummary: any;
  courseDataContent: any;
  courseTitle: any;
  userdetail: any;
  // activityData: any;
  behaviour: any;
  selectedAnswerData;
  multiSelectedAnswerData;
  feedbacktext: any;
  currentQuestionTypeId;
  selectedAnswer: any;

  singleSelectAns: any;

  // quiz1: any = [
  //   {
  //     id: 1,
  //     list: [
  //       {
  //         answers:
  //           "2##https://bhaveshedgetest.s3.ap-south-1.amazonaws.com/512.png#scdvfb#1#1|1#zxhjkjl##stkkfgf#1#1",
  //         contentRef:
  //           "https://bhaveshedgetest.s3.ap-south-1.amazonaws.com/alan.png",
  //         depQuestionId: 0,
  //         depQuestionValue: "",
  //         id: 1,
  //         optionList: [
  //           {
  //             optionMatch: "scdvfb",
  //             optionRef:
  //               "https://bhaveshedgetest.s3.ap-south-1.amazonaws.com/512.png",
  //             optionText: "",
  //             optionType: "2",
  //             penalty: "1",
  //             sFlag: false,
  //             score: "1",
  //             expand: false,
  //           },
  //           {
  //             optionMatch: "stkkfgf",
  //             optionRef: "",
  //             optionText: "zxhjkjl",
  //             optionType: "1",
  //             penalty: "1",
  //             sFlag: false,
  //             score: "1",
  //             expand: false,
  //           },
  //         ],
  //         matchPairList: [
  //           {
  //             id: 1,
  //             name: "match 1",
  //             status: false,
  //           },
  //           {
  //             id: 2,
  //             name: "match 2",
  //             status: false,
  //           },
  //           {
  //             id: 3,
  //             name: "match 3",
  //             status: false,
  //           },
  //           {
  //             id: 4,
  //             name: "match 4",
  //             status: false,
  //           },
  //         ],
  //         points: null,
  //         qName: "Demo 44",
  //         qSelectId: "1",
  //         qSelectLabel: "Pre-Made",
  //         qTypeId: "2",
  //         qTypeLabel: "Matching",
  //         qid: 1,
  //         qorder: 2,
  //         qqid: 11,
  //         questionId: 8,
  //         quizqId: 8,
  //         sName: "Section 2",
  //         sid: 2,
  //         tenantId: 1,
  //         timemodified: "2019-03-28T10:19:28.000Z",
  //         usermodified: 4,
  //         visible: 1,
  //       },
  //     ],
  //     qqid: 11,
  //     answerConfirmed: false,
  //   },
  //   {
  //     id: 2,
  //     list: [
  //       {
  //         // tslint:disable-next-line:max-line-length
  //         answers:
  //           "1#1#1##0#1#0|1#2##https://bhaveshedgetest.s3.ap-south-1.amazonaws.com/calendar-icon.png#0#1#0|1#1#adssdvnsdnkd##0#0#1|1#2##https://bhaveshedgetest.s3.ap-south-1.amazonaws.com/calendar-icon.png#0#1#0",
  //         contentRef: null,
  //         depQuestionId: null,
  //         depQuestionValue: "",
  //         id: 1,
  //         optionList: [
  //           {
  //             choiceType: "1",
  //             isCorrect: "0",
  //             penalty: "1",
  //             qOption: "1",
  //             qOptionRef: "",
  //             qOptionType: "1",
  //             sFlag: false,
  //             score: "0",
  //             expand: false,
  //           },
  //           {
  //             choiceType: "1",
  //             isCorrect: "0",
  //             penalty: "1",
  //             qOption: "",
  //             qOptionRef:
  //               "https://bhaveshedgetest.s3.ap-south-1.amazonaws.com/calendar-icon.png",
  //             qOptionType: "2",
  //             sFlag: false,
  //             score: "0",
  //             expand: false,
  //           },
  //           {
  //             choiceType: "1",
  //             isCorrect: "1",
  //             penalty: "0",
  //             qOption: "adssdvnsdnkd",
  //             qOptionRef: "",
  //             qOptionType: "1",
  //             sFlag: false,
  //             score: "0",
  //             expand: false,
  //           },
  //           {
  //             choiceType: "1",
  //             isCorrect: "0",
  //             penalty: "1",
  //             qOption: "",
  //             qOptionRef:
  //               "https://bhaveshedgetest.s3.ap-south-1.amazonaws.com/calendar-icon.png",
  //             qOptionType: "2",
  //             sFlag: false,
  //             score: "0",
  //             expand: false,
  //           },
  //         ],
  //         points: 2,
  //         qName: "Demo Question",
  //         qSelectId: "1",
  //         qSelectLabel: "Pre-Made",
  //         qTypeId: "1",
  //         qTypeLabel: "Choice",
  //         qid: 1,
  //         qorder: 0,
  //         qqid: 1,
  //         questionId: 1,
  //         quizqId: 1,
  //         sName: "Section 2",
  //         sid: 2,
  //         tenantId: 1,
  //         timemodified: "2019-03-28T10:19:28.000Z",
  //         usermodified: 4,
  //         visible: 1,
  //       },
  //     ],
  //     qqid: 1,
  //     answerConfirmed: false,
  //   },
  //   {
  //     id: 1,
  //     list: [
  //       {
  //         answers: "demo final 2#0#12#0|demo final 22#1#1#0",
  //         contentRef: "",
  //         depQuestionId: 0,
  //         depQuestionValue: "0",
  //         id: 4,
  //         optionList: [
  //           {
  //             cFlag: false,
  //             optionText: "demo final 2",
  //             penalty: "0",
  //             sFlag: false,
  //             score: "12",
  //             sequenceNo: "0",
  //           },
  //           {
  //             cFlag: false,
  //             optionText: "demo final 22",
  //             penalty: "0",
  //             sFlag: false,
  //             score: "1",
  //             sequenceNo: "1",
  //           },
  //         ],
  //         points: 13,
  //         qName: "demo final 2",
  //         qSelectId: "1",
  //         qSelectLabel: "Pre-Made",
  //         qTypeId: "3",
  //         qTypeLabel: "Sequence",
  //         qid: 1,
  //         qorder: 0,
  //         qqid: 13,
  //         questionId: 15,
  //         quizqId: 15,
  //         sName: "Section 2",
  //         sid: 2,
  //         tenantId: 1,
  //         timemodified: "2019-04-11T11:34:09.000Z",
  //         usermodified: 4,
  //         visible: 1,
  //       },
  //     ],
  //     qqid: 13,
  //     answerConfirmed: false,
  //   },
  //   {
  //     id: 4,
  //     list: [
  //       {
  //         answers:
  //           "2#1#wsfsegrh##3#0#1|2#1#fdasgdsd##2#0#1|2#1#wsafdvdsds##7#0#1",
  //         contentRef:
  //           "https://bhaveshedgetest.s3.ap-south-1.amazonaws.com/category.jpg",
  //         depQuestionId: null,
  //         depQuestionValue: null,
  //         id: 6,
  //         optionList: [
  //           {
  //             cFlag: false,
  //             choiceType: "2",
  //             isCorrect: "1",
  //             penalty: "0",
  //             qOption: "wsfsegrh",
  //             qOptionRef: "",
  //             qOptionType: "1",
  //             sFlag: false,
  //             score: "3",
  //           },
  //           {
  //             cFlag: false,
  //             choiceType: "2",
  //             isCorrect: "1",
  //             penalty: "0",
  //             qOption: "fdasgdsd",
  //             qOptionRef: "",
  //             qOptionType: "1",
  //             sFlag: false,
  //             score: "2",
  //           },
  //           {
  //             cFlag: false,
  //             choiceType: "2",
  //             isCorrect: "1",
  //             penalty: "0",
  //             qOption: "wsafdvdsds",
  //             qOptionRef: "",
  //             qOptionType: "1",
  //             sFlag: false,
  //             score: "7",
  //           },
  //         ],
  //         points: 12,
  //         qName: "Demo 44",
  //         qSelectId: "1",
  //         qSelectLabel: "Pre-Made",
  //         qTypeId: "1",
  //         qTypeLabel: "Choice",
  //         qid: 1,
  //         qorder: 1,
  //         qqid: 3,
  //         questionId: 3,
  //         quizqId: 3,
  //         sName: "Section 2",
  //         sid: 2,
  //         tenantId: 1,
  //         timemodified: "2019-04-11T13:28:00.000Z",
  //         usermodified: 4,
  //         visible: 1,
  //       },
  //     ],
  //     qqid: 3,
  //     answerConfirmed: false,
  //   },
  // ];

  activityData: any = {
    activityId: "",
    activityNo: "",
    activityTitle: "",
    activitycompletionid: "",
    attId: "",
    attempt: "",
    behaviorName: "",
    behaviour: "",
    closingDate: "",
    completionCriteria: "",
    completionstatus: "",
    contenttime: "",
    contentwatchedtime: "",
    corsQuizId: "",
    courseId: "",
    creditAllocId: "",
    dba: "",
    delayBetweenAttempts: "",
    delayTime: "",
    dependentActId: "",
    employeeId: "",
    enrolId: "",
    fileinfo: "",
    moduleId: "",
    navType: "",
    navigation: "",
    openDate: "",
    orderType: "",
    points: "",
    qOPId: "",
    qname: "",
    qpassword: "",
    questionsOnPage: "",
    quizCompCriteria: "",
    quizId: "",
    quizOrder: "",
    scoreSelection: "",
    show: "",
    ssName: "",
    tags: "",
    tenantId: "",
    timelimit: "",
    viewed: "",
  };

  quiz: any = [
    // {
    //   id: "",
    //   list: [
    //     {
    //       answers: "",
    //       contentRef: "",
    //       depQuestionId: "",
    //       depQuestionValue: "",
    //       id: "",
    //       optionList: [
    //         {
    //           optionMatch: "",
    //           optionRef: "",
    //           optionText: "",
    //           optionType: "",
    //           penalty: "",
    //           sFlag: "",
    //           score: "",
    //           expand: "",
    //         },
    //       ],
    //       matchPairList: [
    //         {
    //           id: "",
    //           name: "",
    //           status: "",
    //         },
    //       ],
    //       points: "",
    //       qName: "",
    //       qSelectId: "",
    //       qSelectLabel: "",
    //       qTypeId: "",
    //       qTypeLabel: "",
    //       qid: "",
    //       qorder: "",
    //       qqid: "",
    //       questionId: "",
    //       quizqId: "",
    //       sName: "",
    //       sid: "",
    //       tenantId: "",
    //       timemodified: "",
    //       usermodified: "",
    //       visible: "",
    //     },
    //   ],
    //   qqid: "",
    //   answerConfirmed: "",
    // },
  ];

  currentCourseData: any = {
    approvalStatus: "",
    cat_id: "",
    category: "",
    compStatus: "",
    completed_courses: "",
    courseDesc: "",
    courseTitle: "",
    courseType: "",
    courseType2: "",
    cpoints: "",
    endDate: "",
    enrolId: "",
    id: "",
    isBookmark: "",
    isDislike: "",
    isLike: "",
    nextStage: "",
    noDislikes: "",
    noLikes: "",
    noOfmodules: "",
    percComplete: "",
    startDate: "",
    total_courses: "",
    typeImage: "",
  };
  quizSubmitRes: any;
  timeInSeconds: any;
  time: any;
  runTimer: any;
  hasStarted: any;
  hasFinished: any;
  remainingTime: any;
  displayTime: any;

  activeQuizTimer: any;
  showScore: boolean;
  quizCompRes: any;
  notAnswered: any;
  questionProgress: any;
  defaultMatchLabel: any;
  selectedMatchAns: any;
  multiSelectAns: any;
  answerConfirmed: any;
  currentQuestionIndex: any;
  showfb: any;
  expand: any;
  reviewFlag: boolean;
  displayConfirm: boolean;
  last: any;
  answerSelected: any;
  displayNav: boolean;
  rightAns: any;
  quizScore: any;
  quesTotalScore: any;
  quesTotalPenalty: any;
  page_no: number;
  total_page: number;
  displayQues: any;
  displayQues1: any;
  displayOptns: any;
  quizSubmitted: any;
  seperatorObject: any;
  finalQuizQuestionsData: any;
  retrySubmit: any;
  quizSubmitError: any;
  backPressEventForQuiz: any;

  constructor(
    public moduleService: ModuleService,
    public activityService: ActivityService,
    // public spinner: NgxSpinnerService,
    public toaster: ToasterService,
    private sanitizer: DomSanitizer,
    private cdf: ChangeDetectorRef
  ) {
    // this.courseDataContent = CSP.getDataContent();
    // console.log(this.courseDataContent);
    // this.courseDataSummary = CSP.getDataSummary();
    // console.log(this.courseDataSummary);
    // const object = {
    //   courseDataContent : this.courseDataContent,
    //   courseDataSummary : this.courseDataSummary,
    // };
    // localStorage.setItem('quizCourseData', JSON.stringify(object));
    // if (localStorage.getItem('userdetail')) {
    //   this.userdetail = JSON.parse(localStorage.getItem('userdetail'));
    // }
    // this.spinner.show();
  }

  ngOnInit() { }

  showGetQuizQuestionError = false;
  disableMultipleCalls = false;

  getQuizQuestions() {
    //*****************************GET QUIZ QUESTIONS START *************************************///
    let data = null;
    if (this.activityData) {
      data = {
        qId: this.courseDataContent.quizId,
        tId: this.courseDataContent.tenantId,
        qOrder: this.activityData.quizOrder,
        qOPId: this.activityData.qOPId,
        empId: this.userdetail.eid,
        cId: this.activityData.courseId,
        mId: this.activityData.moduleId,
        aId: this.activityData.activityId,
        eId: this.activityData.enrolId,
        uId: this.userdetail.id,
        contentTime: this.activityData.activityDuration
          ? this.makeTimerDataReady(this.activityData.activityDuration)
          : null,
        languageId: this.courseDataContent.languageId
      };
    }
    this.activityService
      .quizQuestion(data)
      .then((result) => {
        if (result["data"] !== undefined && result["separators"] != {}) {
          this.quiz = result["data"];
          this.seperatorObject = result["separators"];

          this.activityService.currentQuizData.isReviewEn = !result['isReviewEn'] ? false : true;
          this.quiz = this.makeQuizDataReadyForMatchPair(this.quiz);
          this.resetReattemptQuiz();
          this.showGetQuizQuestionError = false;
          this.disableMultipleCalls = false;
          this.cdf.detectChanges();
          console.log("QUIZ Activity GET SUCCESSFUL--->", this.quiz);
          // this.spinner.hide();
          const event = {
            loaderFlag: false,
            responseData: null,
            errorFlag: false,
            // 'showQuizReview': false,
            // 'showQuiz': true,
            showComponent: "quiz",
          };
          // console.log('Event Emit', event);
          console.log("Event Emit", event);
          this.componentData.emit(event);
          if (this.quiz.length === 1) {
            this.last = true;
          }
          this.initTimer();
          this.timerTick();
          let timeSpent = 0;
          this.total_page = this.quiz.length;
          if (this.activityData.contentwatchedtime !== null) {
            timeSpent = this.activityData.contentwatchedtime;
          } else {
            timeSpent = this.timeInSeconds - this.remainingTime;
          }
          this.makeQuizCompDataReady(null, this.timeInSeconds, timeSpent);
        } else {
          // this.spinner.hide();
          const event = {
            loaderFlag: false,
            responseData: null,
            errorFlag: false,
            // 'showQuizReview': false,
            // 'showQuiz': true,
            showComponent: "quiz",
          };
          console.log("Event Emit", event);
          this.componentData.emit(event);

          this.disableMultipleCalls = false;
          this.cdf.detectChanges();
          this.showGetQuizQuestionError = true;
          // this.router.navigate(['../../../learn'], {
          //   relativeTo: this.routes
          // });
        }
      })
      .catch((result) => {
        // this.spinner.hide();
        const event = {
          loaderFlag: false,
          responseData: null,
          errorFlag: false,
          // 'showQuizReview': false,
          // 'showQuiz': true,
          showComponent: "quiz",
        };
        console.log("Event Emit", event);
        this.componentData.emit(event);

        this.showGetQuizQuestionError = true;
        this.disableMultipleCalls = false;
        this.cdf.detectChanges();
        console.log("ServerResponseError :", result);
      });

    //*****************************GET QUIZ QUESTIONS END *************************************///
  }

  getActivityData() {
    //*****************************GET QUIZ ACTIVITY CONTENT START *************************************///
    var params = {
      suptId: this.courseDataContent.supertypeId,
      subtId: this.courseDataContent.activityTypeId,
      aId: this.courseDataContent.activityId,
      uId: this.userdetail.id,
      eId: this.courseDataContent.enrolId,
      tempId: this.courseDataSummary.courseFrom == 2 || 3 ? true : false,
      enId: this.courseDataContent.enrolId,
      enrolId: this.courseDataContent.enrolId,
      instanceId: this.courseDataContent.areaId == 13 ? this.courseDataContent.contentId : this.courseDataContent.activityId,
      areaId: this.courseDataContent.areaId || 2
    };
    // if (this.courseDataSummary.courseFrom == 2 || 3) {
    //   params["eId"] = null;
    //   if (Number(this.userdetail["roleId"]) === 8) {
    //     params["tempId"] = true;
    //   } else {
    //     params["tempId"] = false;
    //   }
    // }
    this.moduleService
      .getLearnActivitiesList(params)
      .then((result: any) => {
        if (result && result.data && result.type) {
          // if (this.courseDataContent.areaId != 29) {
          //   result.data['wfId'] = this.courseDataContent.wfId ? this.courseDataContent.wfId : null;
          //   // result.data['stepId']  = 5;
          // } else {
          //   result.data['wfId'] = this.courseDataContent.wfId ? this.courseDataContent.wfId : null;
          //   result.data['stepId'] = this.courseDataContent.stepId ? this.courseDataContent.stepId : null;
          // }
          result.data['compAreaId'] = this.courseDataContent.compAreaId ? this.courseDataContent.compAreaId : null;
          this.activityData = result.data;
          // if (params["tempId"]) {
          //   this.activityData.enrolId = this.courseDataContent.enrolId;
          // }
          console.log("Activity Data ===>", this.activityData);
          if (this.activityData.navigation == 1) {
            this.displayNav = true;
          } else {
            this.displayNav = false;
          }
          if (this.activityData.behaviour == 1) {
            this.behaviour = this.activityData.behaviour;
            this.displayConfirm = true;
          } else {
            this.behaviour = this.activityData.behaviour;
            this.displayConfirm = false;
          }
          if (this.activityService.currentQuizData.reattemptFlag) {
            this.activityData['defaultlangId'] = this.courseDataContent['defaultlangId'];
            // this.activityData['defaultlangId'] =
            this.currentQuestionIndex = 0;
            this.quiz = [];
            this.last = false;
          }

          //  else {
          this.getQuizQuestions();
          // }
        } else {
          // this.spinner.hide();
          const event = {
            loaderFlag: false,
            responseData: null,
            errorFlag: false,
            // 'showQuizReview': false,
            // 'showQuiz': true,
            showComponent: "quiz",
          };
          console.log("Event Emit", event);
          this.componentData.emit(event);

          this.showGetQuizQuestionError = true;
          this.disableMultipleCalls = false;
          this.cdf.detectChanges();
        }
      })
      .catch((result) => {
        // this.spinner.hide();
        const event = {
          loaderFlag: false,
          responseData: null,
          errorFlag: false,
          // 'showQuiz': true,
          // 'showQuizReview': false,
          showComponent: "quiz",
        };
        console.log("Event Emit", event);
        this.componentData.emit(event);

        this.showGetQuizQuestionError = true;
        this.disableMultipleCalls = false;
        this.cdf.detectChanges();
        console.log("ServerResponseError :", result);
      });

    //*****************************GET QUIZ ACTIVITY CONTENT END *************************************///
  }

  setDefaultVarData() {
    this.finalQuizQuestionsData = [];
    this.retrySubmit = false;
    this.quizSubmitError = false;
    this.backPressEventForQuiz = false;
    this.notAnswered = false;
    this.questionProgress = 0;
    this.defaultMatchLabel = "Tap to select a matching tile";
    this.selectedMatchAns = "";
    this.multiSelectAns = [];
    this.answerConfirmed = false;
    this.currentQuestionIndex = 0;
    this.showfb = false;
    this.expand = false;
    this.reviewFlag = false;
    this.displayConfirm = false;
    this.last = false;
    this.answerSelected = false;
    this.displayNav = false;
    this.rightAns = 0;
    this.quizScore = 0;
    this.quesTotalScore = 0;
    this.quesTotalPenalty = 0;
    this.page_no = 0;
    this.total_page = 0;
    this.displayQues = {};
    this.displayQues1 = {};
    this.displayOptns = {};
    this.quizSubmitted = false;
  }

  makeQuizDataReadyForMatchPair(quesArray) {
    let totalQuizMarks = 0;
    if (quesArray.length > 0) {
      for (let p = 0; p < quesArray.length; p++) {
        const temp1 = quesArray[p].list;
        for (let q = 0; q < temp1.length; q++) {
          const temp = temp1[q];
          totalQuizMarks =
            totalQuizMarks +
            Number(temp.questionMarks ? temp.questionMarks : 0);
          if (temp.qTypeId == "2") {
            const cloneArrayOpt = temp.optionList.slice(0);
            for (let r = 0; r < cloneArrayOpt.length; r++) {
              const tempOption = cloneArrayOpt[r];
              const cloneArray = tempOption.matchPairList.slice(0);
              // let tempMatchPairList = makeMatchPairListReady(tempOption.matchPairList, tempOption.selectedMatchAns);
              for (let s = 0; s < cloneArray.length; s++) {
                if (
                  tempOption["matchPairList"][s].id ==
                  tempOption.selectedMatchAns
                ) {
                  tempOption["matchPairList"][s].selected = true;
                  tempOption["matchPairList"][s].status == 0;
                  // tempOption['matchPairList'][s].selected = ''
                } else {
                  // tempOption['matchPairList'][s].selected = tempOption['matchPairList'][s].name;
                  tempOption["matchPairList"][s].selected = false;
                  tempOption["matchPairList"][s].status == 1;
                }
                // cloneArray[s].selected = cloneArray.some(pair => pair.id === tempOption.selectedMatchAns);
              }
              tempOption.matchPairList = cloneArray;
            }
            temp.optionList = cloneArrayOpt;
          }
        }
      }
    }
    console.log("Total quiz marks : ", totalQuizMarks);
    this.activityData["totalQuizMarks"] = totalQuizMarks;
    return quesArray;
  }

  /**************** TIMER COMPONENT START ***************/

  makeTimerDataReady(activityDuration) {
    const resStr = activityDuration.split(":");
    // const formattedDuration = new Date();
    // formattedDuration.setHours(resStr[0], resStr[1], resStr[2]);
    // console.log('Quiz Duration ', formattedDuration);
    const seconds = +resStr[0] * 60 * 60 + +resStr[1] * 60 + +resStr[2];
    // console.log('Quiz Duration in seconds ', seconds);
    return seconds;
  }

  initTimer() {
    if (!this.timeInSeconds) {
      if (this.activityData.activityDuration) {
        this.timeInSeconds = this.makeTimerDataReady(
          this.activityData.activityDuration
        );
      } else {
        this.timeInSeconds = 1800;
      }
    }
    this.time = this.timeInSeconds;
    this.runTimer = false;
    this.hasStarted = false;
    this.hasFinished = false;

    if (this.activityData.contentwatchedtime !== null) {
      this.remainingTime =
        this.timeInSeconds - this.activityData.contentwatchedtime;
    } else {
      this.remainingTime = this.timeInSeconds;
    }

    this.displayTime = this.getSecondsAsDigitalClock(this.remainingTime);
    console.log(this.displayTime);
  }

  timerTick() {

    if (this.activeQuizTimer) {
      clearTimeout(this.activeQuizTimer);
    }
    this.activeQuizTimer = setTimeout(() => {
      this.remainingTime--;
      this.displayTime = this.getSecondsAsDigitalClock(this.remainingTime);
      if (this.remainingTime > 0) {
        this.timerTick();
      } else {
        this.hasFinished = true;
        // this.result();
        // this.submitAnswer(this.quiz[this.currentQuestionIndex], 4);
        if (this.quiz && this.quiz.length > 0) {
          let i = this.currentQuestionIndex;
          for (i; i < this.quiz.length; i++) {
            // for (et i; i < this.quiz.length; i++) {
            if (i === this.quiz.length - 1) {
              this.last = true;
            }
            this.submitAnswer(this.quiz[i], 4);
          }
        }
      }
    }, 1000);
  }

  getSecondsAsDigitalClock(inputSeconds: number) {
    const sec_num = parseInt(inputSeconds.toString(), 10); // don't forget the second param
    const hours = Math.floor(sec_num / 3600);
    const minutes = Math.floor((sec_num - hours * 3600) / 60);
    const seconds = sec_num - hours * 3600 - minutes * 60;
    let hoursString = "";
    let minutesString = "";
    let secondsString = "";
    hoursString = hours < 10 ? "0" + hours : hours.toString();
    minutesString = minutes < 10 ? "0" + minutes : minutes.toString();
    secondsString = seconds < 10 ? "0" + seconds : seconds.toString();
    return hoursString + ":" + minutesString + ":" + secondsString;
    // return  minutesString + ':' + secondsString;
  }
  /**************** TIMER COMPONENT END ***************/

  getUserData() {
    // this.userdetail = JSON.parse(localStorage.getItem("userDetails"));
    // this.userdetail.eid = localStorage.getItem("employeeId");
    if (localStorage.getItem("currentUser")) {
      this.userdetail = JSON.parse(localStorage.getItem("currentUser"));
      // this.tenantId = this.userdetail.tenantId;
    }
  }

  dragStart(event) {
    event.dataTransfer.setData("Text", event.target.id);
  }

  dragging(event) {
    console.log("Button is being dragged");
  }

  allowDrop(event) {
    event.preventDefault();
    // event.target.style.border = 'none';
  }

  drop(event, mainData, index, stat) {
    event.preventDefault();
    const data = event.dataTransfer.getData("Text");
    // const data1 = event.currentTarget.outerHTML;
    console.log('maindata ->', mainData);
    if (
      event.currentTarget.outerText == "" ||
      event.currentTarget.outerText == "Drop Matching Tiles"
    ) {
      event.target.appendChild(document.getElementById(data));
      // document.getElementById('demo').innerHTML = 'The p element was dropped.';
      console.log("The p element was dropped.", event);
      if (stat == 1) {
        mainData.optionList[index].matchPairList[index].status = 0;
        mainData.optionList[index].matchPairList[index].selected = "";
        // mainData.optionList[index].matchPairList[index].status = false;
        // mainData.optionList[index].matchPairList[index].selected = false;
        this.selectOption(event, mainData, index, stat);
      } else {
        mainData.optionList[index].matchPairList[index].status = 1;
        mainData.optionList[index].matchPairList[index].selected =
          event.toElement.firstElementChild.innerText;
        // mainData.optionList[index].matchPairList[index].status = true;
        // mainData.optionList[index].matchPairList[index].selected = true;
        // mainData.optionList[index].matchPairList[index].selectedMatchOption =
        // event.toElement.firstElementChild.innerText;
        this.selectOption(event, mainData, index, stat);
      }
    } else {
      // this.toastr
      //   .info("Cannot move the tile to the dropped place.", "Information")
      //   .onHidden.subscribe(() => this.toastr.clear());
      this.presentAlert(
        "Cannot move the tile to the dropped place.",
        "Information"
      );
    }
    // this.selectOption(mainData, index);
  }

  selectOption(QuesIndex, mainData, index, optData) {
    // console.log('SELECT');
    if (mainData.qTypeId == "1") {
      console.log('1st maindata check', mainData);
      mainData.uSelected = "";

      for (var i = 0; i < mainData.optionList.length; i++) {
        if (mainData.optionList[i].choiceType == "1") {
          mainData.optionList[i].sFlag = false;
        }
      }
      if (mainData.optionList[index].sFlag == true) {
        mainData.optionList[index].sFlag = false;
      } else {
        mainData.optionList[index].sFlag = true;
      }
      if (mainData.optionList[index].qOption) {
        mainData.uSelected = mainData.optionList[index].qOption;
        if (mainData.optionList[index].isCorrect == "0") {
          mainData.optionList[index].cFlag = "false";
        } else {
          mainData.optionList[index].cFlag = "true";
        }
      } else {
        mainData.uSelected = mainData.optionList[index].qOptionRef;
        if (mainData.optionList[index].isCorrect == "0") {
          mainData.optionList[index].cFlag = "false";
        } else {
          mainData.optionList[index].cFlag = "true";
        }
      }
      console.log("mainData--->", mainData);
      console.log("INDEX--->", index);
    } else if (mainData.qTypeId == "2") {
      // for (var i = 0; i < mainData.optionList.length; i++) {
      if (mainData.optionList[index].matchPairList[index].status == 1) {
        console.log('2nd maindata check', mainData);
        mainData.optionList[index].sFlag = true;
        mainData.optionList[index].cFlag = true;

        const selectedText = String(
          mainData.optionList[index].matchPairList[index].selected
        ).replace(/<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g, "");
        const matchingOption = String(
          mainData.optionList[index].optionMatch
        ).replace(/<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g, "");
        if (selectedText == matchingOption) {
          mainData.optionList[index].cFlag = "true";
        } else {
          mainData.optionList[index].cFlag = "false";
        }
      } else {
        mainData.optionList[index].sFlag = false;
        mainData.optionList[index].cFlag = false;
      }
      // }
    } else if (mainData.qTypeId == "3") {
      console.log('3st maindata check', mainData);
      for (var i = 0; i < mainData.optionList.length; i++) {
        var temp = parseInt(mainData.optionList[i].sequenceNo);
        if (i == temp) {
          mainData.optionList[i].cFlag = "true";
        } else {
          mainData.optionList[i].cFlag = "false";
        }
      }
    }
  }

  // selectOption1(mainData, index) {
  //   mainData.optionList[index].cFlag = true;
  //   mainData.optionList[index].sFlag = true;

  //   console.log("mainData===>", mainData);
  //   console.log("index===>", index);
  // }

  selectOption1(mainData) {
    if (mainData && mainData.optionList) {
      mainData.optionList.forEach(option => {
        option.cFlag = true;
        option.sFlag = true;
      });
      console.log(" Image mainData===>", mainData);
    } else {
      console.error("Either mainData or mainData.optionList is undefined");
    }
  }



  makeAnsweredQuestionsDataReady(questionData) {
    const answeredQuesData = {
      question: "",
      answer: "",
      score: "",
      penalty: "",
    };
  }

  checkIfQuestionIsMandatory(questionsData) {
    console.log("questionsData", questionsData);
    if (questionsData.isMandatory === 1) {
      return true;
    } else {
      return false;
    }
  }

  makeFinalQuizQuestionDataReady(quizQuestionsData) {
    const tempQuesAnsData = {
      quesId: "",
      score: "",
      penalty: "",
      sectionIds: "",
      answerCorrect: "",
      answerConfirmed: "",
      Answered: "",
      quesOptions: "",
      quesAns: "",
      empId: "",
      cId: "",
      mId: "",
      aId: "",
      qId: "",
      quesLen: 0,
      tS: "",
      tP: "",
      quizComp: "",
      tId: "",
      uId: "",
      cWT: "",
    };

    let quesLength = 0;

    const dollarSeprator =
      this.seperatorObject == {} ? "$" : this.seperatorObject["dollarValue"];
    const hashSeprator =
      this.seperatorObject == {} ? "#" : this.seperatorObject["hashValue"];
    const pipeSeprator =
      this.seperatorObject == {} ? "|" : this.seperatorObject["pipeValue"];
    const tildSeprator =
      this.seperatorObject == {} ? "~" : this.seperatorObject["tildValue"];

    if (quizQuestionsData.length > 0) {
      // if ( quizQuestionsData.length === 1) {
      //   tempQuesAnsData.quesLen = quizQuestionsData.quesLen;
      // } else {
      //   tempQuesAnsData.quesLen = quizQuestionsData.length;
      // }

      for (let i = 0; i < quizQuestionsData.length; i++) {
        tempQuesAnsData.quesLen =
          tempQuesAnsData.quesLen + quizQuestionsData[i].quesLen;

        const questionData = quizQuestionsData[i];

        tempQuesAnsData.empId = questionData.empId;
        tempQuesAnsData.cId = questionData.cId;
        tempQuesAnsData.mId = questionData.mId;
        tempQuesAnsData.aId = questionData.aId;
        tempQuesAnsData.qId = questionData.qId;
        tempQuesAnsData.tS = questionData.tS;
        tempQuesAnsData.tP = questionData.tP;
        tempQuesAnsData.quizComp = questionData.quizComp;
        tempQuesAnsData.tId = questionData.tId;
        tempQuesAnsData.uId = questionData.uId;
        tempQuesAnsData.cWT = questionData.cWT;

        if (tempQuesAnsData.quesId !== "") {
          // tempQuesAnsData.quesId += '$';
          tempQuesAnsData.quesId += dollarSeprator;
        }
        if (
          String(questionData.quesId) !== "" &&
          String(questionData.quesId) !== "null"
        ) {
          tempQuesAnsData.quesId += questionData.quesId;
        }

        if (tempQuesAnsData.score !== "") {
          // tempQuesAnsData.score += '$';
          tempQuesAnsData.score += dollarSeprator;
        }
        if (
          String(questionData.score) !== "" &&
          String(questionData.score) !== "null"
        ) {
          tempQuesAnsData.score += questionData.score;
        }

        if (tempQuesAnsData.penalty !== "") {
          // tempQuesAnsData.penalty += '$';
          tempQuesAnsData.penalty += dollarSeprator;
        }
        if (
          String(questionData.penalty) !== "" &&
          String(questionData.penalty) !== "null"
        ) {
          tempQuesAnsData.penalty += questionData.penalty;
        }

        if (tempQuesAnsData.sectionIds !== "") {
          // tempQuesAnsData.sectionIds += '$';
          tempQuesAnsData.sectionIds += dollarSeprator;
        }
        if (
          String(questionData.sectionIds) !== "" &&
          String(questionData.sectionIds) !== "null"
        ) {
          tempQuesAnsData.sectionIds += questionData.sectionIds;
        }

        if (tempQuesAnsData.answerCorrect !== "") {
          // tempQuesAnsData.answerCorrect += '$';
          tempQuesAnsData.answerCorrect += dollarSeprator;
        }
        if (
          String(questionData.answerCorrect) !== "" &&
          String(questionData.answerCorrect) !== "null"
        ) {
          tempQuesAnsData.answerCorrect += questionData.answerCorrect;
        }

        if (tempQuesAnsData.answerConfirmed !== "") {
          // tempQuesAnsData.answerConfirmed += '$';
          tempQuesAnsData.answerConfirmed += dollarSeprator;
        }
        if (
          String(questionData.answerConfirmed) !== "" &&
          String(questionData.answerConfirmed) !== "null"
        ) {
          tempQuesAnsData.answerConfirmed += questionData.answerConfirmed;
        }

        if (tempQuesAnsData.Answered !== "") {
          // tempQuesAnsData.Answered += '$';
          tempQuesAnsData.Answered += dollarSeprator;
        }
        if (
          String(questionData.Answered) !== "" &&
          String(questionData.Answered) !== "null"
        ) {
          tempQuesAnsData.Answered += questionData.Answered;
        }

        if (tempQuesAnsData.quesOptions !== "") {
          // tempQuesAnsData.quesOptions += '$';
          tempQuesAnsData.quesOptions += dollarSeprator;
        }
        if (
          String(questionData.quesOptions) !== "" &&
          String(questionData.quesOptions) !== "null"
        ) {
          tempQuesAnsData.quesOptions += questionData.quesOptions;
        }

        if (tempQuesAnsData.quesAns !== "") {
          // tempQuesAnsData.quesAns += '$';
          tempQuesAnsData.quesAns += dollarSeprator;
        }
        if (
          String(questionData.quesAns) !== "" &&
          String(questionData.quesAns) !== "null"
        ) {
          tempQuesAnsData.quesAns += questionData.quesAns;
        }
      }

      const timeSpent = this.timeInSeconds - this.remainingTime;

      const quesAnsData = {
        empId: tempQuesAnsData.empId,
        cId: tempQuesAnsData.cId,
        mId: tempQuesAnsData.mId,
        aId: tempQuesAnsData.aId,
        qId: tempQuesAnsData.qId,
        quesId: tempQuesAnsData.quesId,
        quesLen: tempQuesAnsData.quesLen,
        quesAns: tempQuesAnsData.quesAns,
        quesOptions: tempQuesAnsData.quesOptions,
        answerCorrect: tempQuesAnsData.answerCorrect,
        answerConfirmed: tempQuesAnsData.answerConfirmed,
        sectionIds: tempQuesAnsData.sectionIds,
        score: tempQuesAnsData.score,
        penalty: tempQuesAnsData.penalty,
        // tS: tempQuesAnsData.tS,
        tS: this.calculate_totoal_score(tempQuesAnsData.score),
        tP: tempQuesAnsData.tP,
        quizComp: tempQuesAnsData.quizComp,
        tId: tempQuesAnsData.tId,
        uId: tempQuesAnsData.uId,
        Answered: tempQuesAnsData.Answered,
        // cWT: tempQuesAnsData.cWT,
        cWT: timeSpent,
        actCompId: this.activityData.activitycompletionid
          ? this.activityData.activitycompletionid
          : 0,
        enId: this.activityData.enrolId,
        ct: this.activityData.contenttime,
        wfId: this.courseDataContent.wfId
          ? this.courseDataContent.wfId
          : null,
        stepId: this.courseDataContent.stepId
          ? this.courseDataContent.stepId
          : null,
        compAreaId: this.courseDataContent.compAreaId ? this.courseDataContent.compAreaId : null,
        languageId: this.courseDataContent.languageId
      };

      console.log("final quiz quesAnsData : ", quesAnsData);
      return quesAnsData;
    }
  }

  calculate_totoal_score(scoreStr) {
    const dollarSeprator =
      this.seperatorObject == {} ? "$" : this.seperatorObject["dollarValue"];

    const scoreStrArr = scoreStr.split(dollarSeprator);
    let totoalScore = 0;
    for (let i = 0; i < scoreStrArr.length; i++) {
      totoalScore += Number(scoreStrArr[i]);
    }
    return totoalScore;
  }

  makeQuestionAnsDataReday(questionsData, quizCompFlag) {
    // if (this.activityData.behaviour == 2) {
    //   this.quesTotalScore = 0;
    //   this.quesTotalPenalty = 0;
    //   }
    console.log("currentPageQuesData ", questionsData);
    const questions = questionsData.list;
    let quesIds = "";
    const quesLen = "";
    let quesAnswers = "";
    let quesScore = "";
    let quesPenalty = "";
    // let quesTotalScore = 0;
    // let quesTotalPenalty = 0;
    let singleAnswers = "";
    const multiAnswers = "";
    let matchOptions = "";
    const matchAnswers = "";
    let sequenceAnswers = "";
    let shortAnswers = "";

    let allOptionValues = "";
    let ansConfirmed = "";
    let ansCorrect = "";
    let sectionIds = "";

    let answeredQues = "";
    let answeredFlag = true;

    const dollarSeprator =
      this.seperatorObject == {} ? "$" : this.seperatorObject["dollarValue"];
    const hashSeprator =
      this.seperatorObject == {} ? "#" : this.seperatorObject["hashValue"];
    const pipeSeprator =
      this.seperatorObject == {} ? "|" : this.seperatorObject["pipeValue"];
    const tildSeprator =
      this.seperatorObject == {} ? "~" : this.seperatorObject["tildValue"];

    if (questions.length > 0) {
      for (let i = 0; i < questions.length; i++) {
        const question = questions[i];
        if (quesIds !== "") {
          // quesIds += '$';
          quesIds += dollarSeprator;
        }
        if (
          String(question.questionId) !== "" &&
          String(question.questionId) !== "null"
        ) {
          quesIds += question.questionId;
        }

        if (quesScore !== "") {
          // quesScore += '$';
          quesScore += dollarSeprator;
        }

        if (quesPenalty !== "") {
          // quesPenalty += '$';
          quesPenalty += dollarSeprator;
        }

        if (sectionIds !== "") {
          // sectionIds += '$';
          sectionIds += dollarSeprator;
        }

        if (ansCorrect !== "") {
          // ansCorrect += '$';
          ansCorrect += dollarSeprator;
        }

        if (ansConfirmed !== "") {
          // ansConfirmed += '$';
          ansConfirmed += dollarSeprator;
        }

        if (answeredQues !== "") {
          // answeredQues += '$';
          answeredQues += dollarSeprator;
        }

        if (allOptionValues !== "") {
          // allOptionValues += '$';
          allOptionValues += dollarSeprator;
        }

        if (quesAnswers !== "") {
          // quesAnswers += '$';
          quesAnswers += dollarSeprator;
        }
        if (question.qTypeId === "1") {
          let optScore = 0;
          let optPenalty = 0;
          let optionValues = "";
          const selectedOptArr = [];
          const correctOptArr = [];
          for (let j = 0; j < question.optionList.length; j++) {
            if (optionValues !== "") {
              // optionValues += '~';
              optionValues += tildSeprator;
            }

            if (question.optionList[j].sFlag) {
              if (singleAnswers !== "") {
                // singleAnswers += '#';
                singleAnswers += hashSeprator;
              }

              selectedOptArr.push(question.optionList[j]);

              if (question.optionList[j].qOption) {
                if (
                  String(question.optionList[j].qOption) !== "" &&
                  String(question.optionList[j].qOption) !== "null"
                ) {
                  singleAnswers += question.optionList[j].qOption;
                }
              } else {
                if (
                  String(question.optionList[j].qOptionRef) !== "" &&
                  String(question.optionList[j].qOptionRef) !== "null"
                ) {
                  singleAnswers += question.optionList[j].qOptionRef;
                }
              }
              if (question.optionList[j].isCorrect === "1") {
                question.optionList[j].cFlag = "true";
                if (
                  String(question.optionList[j].score) !== "" &&
                  String(question.optionList[j].score) !== "null"
                ) {
                  optScore += Number(question.optionList[j].score);
                }
                this.quesTotalScore += Number(question.optionList[j].score);

                correctOptArr.push(question.optionList[j]);
              } else {
                question.optionList[j].cFlag = "false";
                if (
                  String(question.optionList[j].penalty) !== "" &&
                  String(question.optionList[j].penalty) !== "null"
                ) {
                  optPenalty += Number(question.optionList[j].penalty);
                }
                this.quesTotalPenalty += Number(question.optionList[j].penalty);
              }
            }

            let optionValue = "";
            // if (optionValue !== '') {
            //   optionValue += '#';
            // }
            optionValue += question.optionList[j].choiceType;
            // optionValue += '#';
            optionValue += hashSeprator;

            optionValue += question.optionList[j].qOptionType;
            // optionValue += '#';
            optionValue += hashSeprator;

            optionValue += question.optionList[j].qOption;
            // optionValue += '#';
            optionValue += hashSeprator;

            optionValue += question.optionList[j].qOptionRef;
            // optionValue += '#';
            optionValue += hashSeprator;

            optionValue += question.optionList[j].score;
            // optionValue += '#';
            optionValue += hashSeprator;

            optionValue += question.optionList[j].penalty;
            // optionValue += '#';
            optionValue += hashSeprator;

            optionValue += question.optionList[j].feedback;
            // optionValue += '#';
            optionValue += hashSeprator;

            optionValue += question.optionList[j].isCorrect;
            // optionValue += '#';
            optionValue += hashSeprator;

            optionValue += question.optionList[j].sFlag;
            // optionValue += '#';
            optionValue += hashSeprator;

            optionValue += question.optionList[j].cFlag;

            optionValues += optionValue;
          }
          // singleAnswers.substring(singleAnswers.length - 1);
          // singleAnswers = singleAnswers.slice(0, -1);
          quesAnswers += singleAnswers;
          singleAnswers = "";
          quesScore += optScore;
          quesPenalty += optPenalty;
          allOptionValues += optionValues;
          // sectionIds += question.sid;
          sectionIds += question.sid ? question.sid : question.sectionId;

          // if (selectedOptArr.length === 0) {
          //   answeredQues += 0;
          //   ansCorrect += 0;
          //   answeredFlag = false;
          //   question.Answered = 0;
          // } else {
          //   answeredQues += 1;
          //   answeredFlag = true;
          //   question.Answered = 1;
          if (correctOptArr.length === 0) {
            ansCorrect += 0;
            answeredQues += 0;
          } else if (selectedOptArr.length === correctOptArr.length) {
            ansCorrect += 2;
            answeredQues += 1;
          } else {
            ansCorrect += 1;
            answeredQues += 1;
          }
          // }

          if (selectedOptArr.length === 0) {
            answeredFlag = false;
            question.Answered = 0;
          } else {
            answeredFlag = true;
            question.Answered = 1;
          }

          if (!this.hasFinished) {
            if (!answeredFlag && this.checkIfQuestionIsMandatory(question)) {
              // this.toastr
              //   .warning(
              //     "Please answer all mandatory questions that are marked by " +
              //       "*" +
              //       ".",
              //     "Warning!!!"
              //   )
              //   .onHidden.subscribe(() => this.toastr.clear());
              this.presentAlert(
                "Please answer all mandatory questions that are marked by " +
                "*" +
                ".",
                "Warning!!!"
              );
              this.disablSubmit = false;
              this.cdf.detectChanges();
              this.notAnswered = this.checkIfQuestionIsMandatory(question);
              return;
            }
          }
        }
        if (question.qTypeId === "2") {
          let optScore = 0;
          let optPenalty = 0;
          let optionValues = "";
          const selectedOptArr = [];
          const correctOptArr = [];
          matchOptions = "";
          for (let j = 0; j < question.optionList.length; j++) {
            if (optionValues !== "") {
              // optionValues += '~';
              optionValues += tildSeprator;
            }

            const option = question.optionList[j];

            if (option.expand === true) {
              option.expand = false;
            }

            if (matchOptions !== "") {
              // matchOptions += '~';
              matchOptions += tildSeprator;
            }
            if (option.optionText) {
              if (
                String(option.optionText) !== "" &&
                String(option.optionText) !== "null"
              ) {
                matchOptions += option.optionText;
                for (let k = 0; k < option.matchPairList.length; k++) {
                  if (option.matchPairList[k].selected) {
                    selectedOptArr.push(option);

                    if (matchOptions !== "") {
                      // matchOptions += '#';
                      matchOptions += hashSeprator;
                    }
                    if (
                      String(option.matchPairList[k].selected) !== "" &&
                      String(option.matchPairList[k].selected) !== "null"
                    ) {
                      matchOptions += option.matchPairList[k].selected;
                    }
                    const selectedText = String(
                      option.matchPairList[k].selected
                    ).replace(/<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g, "");
                    const matchingOption = String(option.optionMatch).replace(
                      /<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g,
                      ""
                    );
                    if (selectedText === matchingOption) {
                      option.cFlag = "true";

                      correctOptArr.push(option);
                      if (
                        String(question.optionList[j].score) !== "" &&
                        String(question.optionList[j].score) !== "null"
                      ) {
                        optScore += Number(question.optionList[j].score);
                      }
                      this.quesTotalScore += Number(
                        question.optionList[j].score
                      );
                    } else {
                      option.cFlag = "false";
                      if (
                        String(question.optionList[j].penalty) !== "" &&
                        String(question.optionList[j].penalty) !== "null"
                      ) {
                        optPenalty += Number(question.optionList[j].penalty);
                      }
                      this.quesTotalPenalty += Number(
                        question.optionList[j].penalty
                      );
                    }
                  }
                }
              }
            } else {
              if (
                String(option.optionRef) !== "" &&
                String(option.optionRef) !== "null"
              ) {
                matchOptions += option.optionRef;
                for (let k = 0; k < option.matchPairList.length; k++) {
                  if (option.matchPairList[k].selected) {
                    selectedOptArr.push(option);

                    if (matchOptions !== "") {
                      // matchOptions += '#';
                      matchOptions += hashSeprator;
                    }
                    if (
                      String(option.matchPairList[k].selected) !== "" &&
                      String(option.matchPairList[k].selected) !== "null"
                    ) {
                      matchOptions += option.matchPairList[k].selected;
                    }
                    const selectedText = String(
                      option.matchPairList[k].selected
                    ).replace(/<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g, "");
                    const matchingOption = String(option.optionMatch).replace(
                      /<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g,
                      ""
                    );
                    if (selectedText === matchingOption) {
                      option.cFlag = "true";

                      correctOptArr.push(option);
                      if (
                        String(question.optionList[j].score) !== "" &&
                        String(question.optionList[j].score) !== "null"
                      ) {
                        optScore += Number(question.optionList[j].score);
                      }
                      this.quesTotalScore += Number(
                        question.optionList[j].score
                      );
                    } else {
                      option.cFlag = "false";
                      if (
                        String(question.optionList[j].penalty) !== "" &&
                        String(question.optionList[j].penalty) !== "null"
                      ) {
                        optPenalty += Number(question.optionList[j].penalty);
                      }
                      this.quesTotalPenalty += Number(
                        question.optionList[j].penalty
                      );
                    }
                  }
                }
              }
            }

            let optionValue = "";
            optionValue += question.optionList[j].optionType;
            // optionValue += '#';
            optionValue += hashSeprator;

            optionValue += question.optionList[j].optionText;
            // optionValue += '#';
            optionValue += hashSeprator;

            optionValue += question.optionList[j].optionRef;
            // optionValue += '#';
            optionValue += hashSeprator;

            optionValue += question.optionList[j].optionMatch;
            // optionValue += '#';
            optionValue += hashSeprator;

            optionValue += question.optionList[j].score;
            // optionValue += '#';
            optionValue += hashSeprator;

            optionValue += question.optionList[j].penalty;
            // optionValue += '#';
            optionValue += hashSeprator;

            optionValue += question.optionList[j].sFlag;
            // optionValue += '#';
            optionValue += hashSeprator;

            optionValue += question.optionList[j].cFlag;

            optionValues += optionValue;
          }
          quesAnswers += matchOptions;
          quesScore += optScore;
          quesPenalty += optPenalty;
          allOptionValues += optionValues;
          // sectionIds += question.sid;
          sectionIds += question.sid ? question.sid : question.sectionId;

          if (correctOptArr.length === 0) {
            ansCorrect += 0;
            answeredQues += 0;
          } else if (selectedOptArr.length === correctOptArr.length) {
            ansCorrect += 2;
            answeredQues += 1;
          } else {
            ansCorrect += 1;
            answeredQues += 1;
          }

          // if (selectedOptArr.length === 0) {
          //   answeredFlag = false;
          //   question.Answered = 0;
          // } else {
          //   answeredFlag = true;
          //   question.Answered = 1;
          // }

          // if (selectedOptArr.length === 0) {
          //   answeredFlag = false;
          //   question.Answered = 0;
          // } else if (selectedOptArr.length !== question.optionList.length) {
          //   answeredFlag = false;
          //   question.Answered = 0;
          // } else {
          //   answeredFlag = true;
          //   question.Answered = 1;
          // }

          if (!this.hasFinished) {
            if (!answeredFlag && this.checkIfQuestionIsMandatory(question)) {
              // this.toastr
              //   .warning(
              //     "Please answer all mandatory questions that are marked by " +
              //       "*" +
              //       ".",
              //     "Warning!!!"
              //   )
              //   .onHidden.subscribe(() => this.toastr.clear());
              this.presentAlert(
                "Please answer all mandatory questions that are marked by " +
                "*" +
                ".",
                "Warning!!!"
              );
              this.disablSubmit = false;
              this.cdf.detectChanges();
              this.notAnswered = this.checkIfQuestionIsMandatory(question);
              return;
            }
          }
        }
        if (question.qTypeId === "3") {
          this.selectOption(0, questions, 0, 0);
          let optScore = 0;
          let optPenalty = 0;
          let optionValues = "";
          const selectedOptArr = [];
          const correctOptArr = [];
          sequenceAnswers = "";
          for (let j = 0; j < question.optionList.length; j++) {
            if (optionValues !== "") {
              // optionValues += '~';
              optionValues += tildSeprator;
            }

            const option = question.optionList[j];
            if (sequenceAnswers !== "") {
              // sequenceAnswers += '~';
              sequenceAnswers += tildSeprator;
            }
            if (
              String(option.optionText) !== "" &&
              String(option.optionText) !== "null"
            ) {
              sequenceAnswers += option.optionText;
              if (sequenceAnswers !== "") {
                // sequenceAnswers += '#';
                sequenceAnswers += hashSeprator;
              }
              if (sequenceAnswers !== "") {
                sequenceAnswers += j;
              }
            }

            if (j === Number(option.sequenceNo)) {
              option.cFlag = "true";
              correctOptArr.push(option);
              if (
                String(option.score) !== "" &&
                String(option.score) !== "null"
              ) {
                optScore += Number(option.score);
              }
              this.quesTotalScore += Number(option.score);
            } else {
              option.cFlag = "false";
              if (
                String(option.penalty) !== "" &&
                String(option.penalty) !== "null"
              ) {
                optPenalty += Number(option.penalty);
              }
              this.quesTotalPenalty += Number(option.penalty);
            }

            let optionValue = "";
            optionValue += question.optionList[j].optionText;
            // optionValue += '#';
            optionValue += hashSeprator;

            optionValue += question.optionList[j].sequenceNo;
            // optionValue += '#';
            optionValue += hashSeprator;

            optionValue += question.optionList[j].score;
            // optionValue += '#';
            optionValue += hashSeprator;

            optionValue += question.optionList[j].penalty;
            // optionValue += '#';
            optionValue += hashSeprator;

            optionValue += question.optionList[j].sFlag;
            // optionValue += '#';
            optionValue += hashSeprator;

            optionValue += question.optionList[j].cFlag;

            optionValues += optionValue;
          }
          quesAnswers += sequenceAnswers;
          quesScore += optScore;
          quesPenalty += optPenalty;
          allOptionValues += optionValues;
          // sectionIds += question.sid;
          sectionIds += question.sid ? question.sid : question.sectionId;

          if (correctOptArr.length === 0) {
            ansCorrect += 0;
            answeredQues += 0;
          } else if (question.optionList.length === correctOptArr.length) {
            ansCorrect += 2;
            answeredQues += 1;
          } else {
            ansCorrect += 1;
            answeredQues += 1;
          }
          question.Answered = 1;
        }
        if (question.qTypeId === "4") {
          let optScore = 0;
          let optPenalty = 0;
          let optionValues = "";
          let enteredText = "";
          for (let j = 0; j < question.optionList.length; j++) {
            if (optionValues !== "") {
              // optionValues += '~';
              optionValues += tildSeprator;
            }

            const option = question.optionList[j];
            // if (shortAnswers !== '') {
            //   shortAnswers += '~';
            // }
            if (String(option.sAns) !== "" && String(option.sAns) !== "null") {
              shortAnswers += option.sAns;
              answeredQues += 1;
            } else {
              answeredQues += 0;
            }

            let optionValue = "";

            optionValue += question.optionList[j].minLen;
            // optionValue += '#';
            optionValue += hashSeprator;

            optionValue += question.optionList[j].maxLen;

            optionValues += optionValue;
          }
          quesAnswers += shortAnswers;
          quesScore += 0;
          quesPenalty += 0;
          allOptionValues += optionValues;
          // sectionIds += question.sid;
          sectionIds += question.sid ? question.sid : question.sectionId;

          // question.Answered = 1;
          if (shortAnswers.length === 0) {
            answeredFlag = false;
            question.Answered = 0;
          } else {
            answeredFlag = true;
            question.Answered = 1;
          }

          if (!this.hasFinished) {
            if (!answeredFlag && this.checkIfQuestionIsMandatory(question)) {
              // this.toastr
              //   .warning(
              //     "Please answer all mandatory questions that are marked by " +
              //       "*" +
              //       ".",
              //     "Warning!!!"
              //   )
              //   .onHidden.subscribe(() => this.toastr.clear());
              this.presentAlert(
                "Please answer all mandatory questions that are marked by " +
                "*" +
                ".",
                "Warning!!!"
              );
              this.disablSubmit = false;
              this.cdf.detectChanges();
              this.notAnswered = this.checkIfQuestionIsMandatory(question);
              return;
            } else if (question.optionList[0]["isValid"] === false) {
              // this.toastr
              //   .warning(question.optionList[0]["msg"], "Warning!!!")
              //   .onHidden.subscribe(() => this.toastr.clear());
              this.presentAlert(question.optionList[0]["msg"], "Warning!!!");
              // this.presentMandatoryQuestionPrompt();
              this.disablSubmit = false;
              this.cdf.detectChanges();
              this.notAnswered = true;
              return;
            }
          }
        }
        // ansConfirmed += questionsData.answerConfirmed;
        ansConfirmed += true;
        this.notAnswered = false;
      }
    }

    const timeSpent = this.timeInSeconds - this.remainingTime;

    const quesAnsData = {
      empId: this.userdetail.eid,
      cId: this.activityData.courseId,
      mId: this.activityData.moduleId,
      aId: this.activityData.activityId,
      qId: this.activityData.quizId,
      quesId: quesIds,
      quesLen: questions.length,
      quesAns: quesAnswers,
      quesOptions: allOptionValues,
      answerCorrect: ansCorrect,
      answerConfirmed: ansConfirmed,
      sectionIds: sectionIds,
      score: quesScore,
      penalty: quesPenalty,
      tS: this.quesTotalScore,
      tP: this.quesTotalPenalty,
      quizComp: quizCompFlag,
      tId: this.userdetail.tenantId,
      uId: this.userdetail.id,
      Answered: answeredQues,
      cWT: timeSpent,
      actCompId: this.activityData.activitycompletionid
        ? this.activityData.activitycompletionid
        : 0,
      enId: this.activityData.enrolId,
      ct: this.activityData.contenttime,
    };

    console.log("quesAnsData ", quesAnsData);
    this.finalQuizQuestionsData.push(quesAnsData);
    return quesAnsData;
  }

  // submitAnswer(currentPageQuesData, optId) {
  //   if (optId === 1) {
  //     if (!currentPageQuesData.answerConfirmed) {
  //       if (this.currentQuestionIndex === this.quiz.length - 1) {
  //         if (!this.notAnswered) {
  //           this.confirmAnswer();
  //         }
  //         this.submitQuestionAnswer(currentPageQuesData, true);
  //       } else {
  //         if (!this.notAnswered) {
  //           this.confirmAnswer();
  //         }
  //         this.submitQuestionAnswer(currentPageQuesData, false);
  //       }
  //     }
  //   } else if (optId === 2) {
  //     if (!currentPageQuesData.answerConfirmed) {
  //       if (this.activityData.behaviour === 1) {
  //         if (this.currentQuestionIndex === this.quiz.length - 1) {
  //           this.submitQuestionAnswer(currentPageQuesData, true);
  //         } else {
  //           this.submitQuestionAnswer(currentPageQuesData, false);
  //         }
  //       }
  //     }
  //     if (!this.notAnswered) {
  //       this.nextPage();
  //     }
  //   } else if (optId === 3) {
  //     if (!currentPageQuesData.answerConfirmed) {
  //       if (this.activityData.behaviour === 1) {
  //         if (this.currentQuestionIndex === this.quiz.length - 1) {
  //           this.submitQuestionAnswer(currentPageQuesData, true);
  //         } else {
  //           this.submitQuestionAnswer(currentPageQuesData, false);
  //         }
  //       }
  //     }
  //     if (!this.notAnswered) {
  //       this.result();
  //       this.confirmAnswer();
  //     }
  //   } else {
  //     if (this.reviewFlag) {
  //       this.result();
  //     } else {
  //       this.confirmAnswer();
  //       this.submitQuestionAnswer(currentPageQuesData, true);
  //     }
  //   }
  // }
  disablSubmit = false;
  // submitAnswer(currentPageQuesData, optId) {
  //   this.disablSubmit = true;
  //   this.cdf.detectChanges();
  //   if (optId === 1) {
  //     if (!currentPageQuesData.answerConfirmed) {
  //       if (this.currentQuestionIndex === this.quiz.length - 1) {
  //         this.submitQuestionAnswer(currentPageQuesData, true, (goToResult, data) => {
  //           this.disablSubmit = false;
  //           this.cdf.detectChanges();
  //           console.log('done');
  //         });
  //       } else {
  //         this.submitQuestionAnswer(currentPageQuesData, false, () => {
  //           this.disablSubmit = false;
  //           this.cdf.detectChanges();
  //           console.log('done');
  //         });
  //       }
  //     }
  //     if (!this.notAnswered) {
  //       this.disablSubmit = false;
  //       this.cdf.detectChanges();
  //       this.confirmAnswer();
  //     }
  //   } else if (optId === 2) {
  //     if (!currentPageQuesData.answerConfirmed) {
  //       if (this.activityData.behaviour === 1) {
  //         if (this.currentQuestionIndex === this.quiz.length - 1) {
  //           this.submitQuestionAnswer(currentPageQuesData, true, () => {
  //             this.disablSubmit = false;
  //             this.cdf.detectChanges();
  //           });
  //         } else {
  //           this.submitQuestionAnswer(currentPageQuesData, false, () => {
  //             this.disablSubmit = false;
  //             this.cdf.detectChanges();
  //           });
  //         }
  //       }
  //     }
  //     if (!this.notAnswered) {
  //       this.nextPage();
  //       this.disablSubmit = false;
  //       this.cdf.detectChanges();
  //     }
  //   } else if (optId === 3) {
  //     let isWorking = false;
  //     if (!currentPageQuesData.answerConfirmed) {
  //       if (this.activityData.behaviour === 1) {
  //         if (this.currentQuestionIndex === this.quiz.length - 1) {
  //           this.submitQuestionAnswer(currentPageQuesData, true, () => {
  //             if (!this.notAnswered) {
  //               isWorking = true;
  //               this.confirmAnswer();
  //               this.result();
  //             }
  //             this.disablSubmit = false;
  //             this.cdf.detectChanges();
  //           });
  //         } else {
  //           this.submitQuestionAnswer(currentPageQuesData, false, () => {
  //             if (!this.notAnswered) {
  //               isWorking = true;
  //               this.confirmAnswer();
  //               this.result();
  //             }
  //             this.disablSubmit = false;
  //             this.cdf.detectChanges();
  //           });
  //         }
  //       }
  //     } else if (!this.notAnswered && !isWorking) {
  //       this.confirmAnswer();
  //       this.result();
  //       this.disablSubmit = false;
  //       this.cdf.detectChanges();
  //     }
  //   } else {
  //     if (this.reviewFlag) {
  //       this.result();
  //       this.disablSubmit = false;
  //       this.cdf.detectChanges();
  //     } else {
  //       this.submitQuestionAnswer(currentPageQuesData, true, () => {
  //         this.confirmAnswer();
  //         if (this.last) {
  //           this.result();
  //         }
  //         this.disablSubmit = false;
  //         this.cdf.detectChanges();
  //       });
  //     }
  //   }
  // }
  submitAnswer(currentPageQuesData, optId) {
    this.disablSubmit = true;
    this.currentPageQuesData = currentPageQuesData;
    console.log('currentpagequestiondata ->', this.currentPageQuesData)
    this.currentOptId = optId;
    this.cdf.detectChanges();
    if (optId === 1) {
      if (!currentPageQuesData.answerConfirmed) {
        if (this.currentQuestionIndex === this.quiz.length - 1) {
          this.submitQuestionAnswer(
            currentPageQuesData,
            true,
            (goToResult, data) => {
              if (!goToResult) {
                this.confirmAnswer(false);
                this.showSubmitError = true;
              } else {
                this.showSubmitError = false;
                // this.presentErrorPrompt('Unable to submit your answers at this time,
                // Please try again to submit', 1, finalQuizData);
              }
              this.disablSubmit = false;
              this.cdf.detectChanges();
              console.log("done");
            }
          );
        } else {
          this.submitQuestionAnswer(
            currentPageQuesData,
            false,
            (goToResult, data) => {
              if (!goToResult) {
                this.confirmAnswer(false);
                this.showSubmitError = true;
              } else {
                this.showSubmitError = false;
                // this.presentErrorPrompt('Unable to submit your answers at this time,
                // Please try again to submit', 1, finalQuizData);
              }
              this.disablSubmit = false;
              this.cdf.detectChanges();
              console.log("done");
            }
          );
        }
      }
      if (!this.notAnswered) {
        this.disablSubmit = false;
        this.cdf.detectChanges();
        this.confirmAnswer(true);
      }
    } else if (optId === 2) {
      if (!currentPageQuesData.answerConfirmed) {
        if (this.activityData.behaviour === 1) {
          if (this.currentQuestionIndex === this.quiz.length - 1) {
            this.submitQuestionAnswer(
              currentPageQuesData,
              true,
              (goToResult, data) => {
                if (!goToResult) {
                  this.prevPage();
                  this.showSubmitError = true;
                } else {
                  this.showSubmitError = false;
                  // this.presentErrorPrompt('Unable to submit your answers at this time,
                  // Please try again to submit', 1, finalQuizData);
                }
                this.disablSubmit = false;
                this.cdf.detectChanges();
              }
            );
          } else {
            this.submitQuestionAnswer(
              currentPageQuesData,
              false,
              (goToResult, data) => {
                if (!goToResult) {
                  this.prevPage();
                  this.showSubmitError = true;
                } else {
                  this.showSubmitError = false;
                  // this.presentErrorPrompt('Unable to submit your answers at this time,
                  // Please try again to submit', 1, finalQuizData);
                }
                this.disablSubmit = false;
                this.cdf.detectChanges();
              }
            );
          }
        }
      } else {
        // if (this.activityData.navigation === 2 && this.activityData.behaviour === 1) {
        if (this.currentQuestionIndex === this.quiz.length - 1) {
          this.submitQuestionAnswer(currentPageQuesData, true, (result, finalQuizData) => {
            console.log('Answer submitted online : ', result);
            // if (!this.notAnswered) {
            //   this.nextPage();
            // }
          });
        } else {
          this.submitQuestionAnswer(currentPageQuesData, false, (result, finalQuizData) => {
            console.log('Answer submitted online : ', result);
            // if (!this.notAnswered) {
            //   this.nextPage();
            // }
          });
        }
        // }
      }
      if (!this.notAnswered) {
        this.nextPage();
        this.disablSubmit = false;
        this.cdf.detectChanges();
      }
    } else if (optId === 3) {
      let isWorking = false;
      if (!currentPageQuesData.answerConfirmed) {
        if (this.activityData.behaviour === 1) {
          if (this.currentQuestionIndex === this.quiz.length - 1) {
            this.submitQuestionAnswer(
              currentPageQuesData,
              true,
              (goToResult, data) => {
                if (!this.notAnswered && goToResult) {
                  isWorking = true;
                  this.confirmAnswer(true);
                  this.result();
                } else {
                  this.showSubmitError = true;
                  // this.presentErrorPrompt('Unable to submit your answers at this time,
                  // Please try again to submit', 1, finalQuizData);
                }
                this.disablSubmit = false;
                this.cdf.detectChanges();
              }
            );
          } else {
            this.submitQuestionAnswer(
              currentPageQuesData,
              false,
              (goToResult, data) => {
                if (!this.notAnswered && goToResult) {
                  isWorking = true;
                  this.confirmAnswer(true);
                  this.result();
                } else {
                  this.showSubmitError = true;
                }
                this.disablSubmit = false;
                this.cdf.detectChanges();
              }
            );
          }
        }
      } else if (!this.notAnswered && !isWorking) {
        this.confirmAnswer(true);
        this.result();
        this.disablSubmit = false;
        this.cdf.detectChanges();
      }
    } else {
      if (this.reviewFlag) {
        this.result();
        this.disablSubmit = false;
        this.cdf.detectChanges();
      } else {
        // this.submitQuestionAnswer(currentPageQuesData, true, () => {
        //   this.confirmAnswer();
        //   if (this.last) {
        //     this.result();
        //   }
        //   this.disablSubmit = false;
        //   this.cdf.detectChanges();
        // });
        this.disablSubmit = true;
        this.cdf.detectChanges();
        this.submitQuestionAnswer(
          currentPageQuesData,
          true,
          (result, finalQuizData) => {
            console.log("Answer submitted online after timeout : ", result);
            if (result && result) {
              this.confirmAnswer(true);
              this.disablSubmit = false;
              this.cdf.detectChanges();
              this.result();
            } else if (!result && !result) {
              this.disablSubmit = false;
              this.cdf.detectChanges();
              this.showSubmitError = true;
              // this.presentErrorPrompt('Unable to submit your answers at this time,
              // Please try again to submit', 1, finalQuizData);
            }
          }
        );
      }
    }
  }

  nextPage() {
    this.currentQuestionIndex++;
    if (this.currentQuestionIndex === this.quiz.length - 1) {
      this.last = true;
      this.questionProgress = 1.0;
    } else {
      // const totalQuesCount = this.quiz.length - 1;
      this.questionProgress =
        this.currentQuestionIndex / (this.quiz.length - 1);
    }
  }

  prevPage() {
    console.log("", this.answerSelected);
    this.currentQuestionIndex--;
    if (this.currentQuestionIndex !== this.quiz.length - 1) {
      this.last = false;
      this.questionProgress =
        this.currentQuestionIndex / (this.quiz.length - 1);
    }
  }

  confirmAnswer(flag) {
    this.answerConfirmed = true;
    // this.quiz[this.currentQuestionIndex].answerConfirmed = flag;
    // this.currentPageQuesData.answerConfirmed = flag;

    this.quiz[this.currentQuestionIndex].answerConfirmed = true;
    this.currentPageQuesData.answerConfirmed = true;

  }

  // submitQuestionAnswer(currentPageQuesData, quizCompFlag) {
  //   const questionData = this.makeQuestionAnsDataReday(
  //     currentPageQuesData,
  //     quizCompFlag
  //   );
  //   console.log('MAKEQADATAREADY:--', questionData);
  //   if (!this.notAnswered) {
  //     var url = ENUM.domain + ENUM.url.submitQuizAnswers;
  //     this.CSP.submitQuizData(url, questionData).then(
  //       (res: any) => {
  //         this.quizSubmitRes = res.data;
  //         console.log(res);
  //         if (this.last && quizCompFlag) {
  //           this.result1();
  //         }
  //       },
  //       err => {
  //         console.log(err);
  //       }
  //     );
  //     const timeSpent = this.timeInSeconds - this.remainingTime;
  //     this.makeQuizCompDataReady(null, this.timeInSeconds, timeSpent);
  //   }
  // }

  completeQuizSubmitRes = null;
  async submitQuestionAnswer(currentPageQuesData, quizCompFlag, cb) {
    // const questionData = this.makeQuestionAnsDataReday(currentPageQuesData, quizCompFlag);
    // console.log('MAKEQADATAREADY:--', questionData);
    // if (!this.notAnswered) {
    //   this.submitQuestionAnswerAsync(questionData, (result) => {
    //     if (result.type === true && result.data) {
    //       this.quizSubmitRes = result.data;
    //       const timeSpent = this.timeInSeconds - this.remainingTime;
    //       // this.makeQuizCompDataReady(null, this.timeInSeconds, timeSpent);
    //       // if (this.quizSubmitRes && this.quizSubmitRes[0].ccFlag === 'Y') {
    //       //   this.makeQuizCompDataReady('Y', this.timeInSeconds, timeSpent);
    //       // } else {
    //       //   this.makeQuizCompDataReady(null, this.timeInSeconds, timeSpent);
    //       // }
    //       this.quizSubmitted = true;
    //       cb(true, questionData);
    //       console.log(result);
    //     } else {
    //       console.log(result);
    //       this.quizSubmitted = false;
    //       cb(false, questionData);
    //     }
    //     // cb();
    //   });
    // }

    /********  new code start ********/
    let finalQuizData: any;
    let questionData: any;
    let quizCompStatus = false;

    if (this.finalQuizQuestionsData && this.finalQuizQuestionsData.length > 0) {
      quizCompStatus = this.finalQuizQuestionsData[
        this.finalQuizQuestionsData.length - 1
      ].quizComp;
    }

    if (!this.retrySubmit && !quizCompStatus) {
      questionData = this.makeQuestionAnsDataReday(
        currentPageQuesData,
        quizCompFlag
      );
    } else if (!this.retrySubmit && this.hasFinished && quizCompStatus) {
      questionData = this.makeQuestionAnsDataReday(
        currentPageQuesData,
        quizCompFlag
      );
    }

    finalQuizData = this.makeFinalQuizQuestionDataReady(
      this.finalQuizQuestionsData
    );
    this.retrySubmit = false;

    if (this.last) {
      if (!this.notAnswered) {
        this.submitQuestionAnswerAsync(finalQuizData, (result) => {
          if (result) {
            if (result.type === true && result.data) {
              console.log("Quiz result online : ", result);
              this.quizSubmitRes = result.data;
              this.completeQuizSubmitRes = result;
              const timeSpent = this.timeInSeconds - this.remainingTime;
              this.quizSubmitted = true;
              this.quizSubmitError = false;
              cb(true, finalQuizData);
            } else {
              console.log("Quiz result online error : ", result);
              this.quizSubmitted = false;
              this.quizSubmitError = true;
              cb(false, finalQuizData);
            }
          } else {
            console.log("Quiz result online error : ", result);
            this.quizSubmitError = true;
            cb(false, finalQuizData);
          }
        });
      }
    }
    /**********   new code end  *********/
  }

  submitQuestionAnswerAsync(params: any, cb) {
    // this.spinner.show();

    const event = {
      loaderFlag: true,
      responseData: null,
      errorFlag: false,
      // 'showQuizReview': false,
      // 'showQuiz': true,
      showComponent: "quiz",
    };
    console.log("Event Emit", event);
    this.componentData.emit(event);
    this.activityService.submitQuestionAnswers(params).then(
      (res) => {
        cb(res);
        // this.spinner.hide();
        if (this.activityData.behaviour == 2) {
          const event = {
            loaderFlag: false,
            responseData: null,
            showComponent: "quiz",
            errorFlag: false,
            quizSubmitResponse: null,
            quizData: null,
          };
          //  console.log('Event Emit', event);
          this.componentData.emit(event);
        }
      },
      (err) => {
        console.log(err);
        // this.spinner.hide();
        const event = {
          loaderFlag: false,
          responseData: null,
          errorFlag: false,
          // 'showQuizReview': false,
          // 'showQuiz': true,
          showComponent: "quiz",
        };
        console.log("Event Emit", event);
        this.componentData.emit(event);
      }
    );
  }

  makeQuizCompDataReady(quizStatus, quizDuration, quizTimeSpent) {
    const quizcCompData = {
      duration: quizDuration,
      currentTime: quizTimeSpent,
      status: quizStatus,
    };
    this.saveQuizComp(quizcCompData);
  }

  saveQuizComp(event) {
    this.activityData['languageId'] = this.courseDataContent.languageId;
    const activity = this.activityData;
    if (activity.contenttime === null) {
      activity.contenttime = event.duration;
    }
    activity.contentwatchedtime = event.currentTime;
    // We  removed if (activity.completionstatus === 'Y') {  activity.completionstatus = 'Y';}
    // this condition is not usable  if it is nessesary then uncoment below code and comment this one;
    //   if (event.status === 'Y') {
    //   activity.completionstatus = 'Y';
    //   this.saveQuizCompletionData(activity);
    // } else {
    //   activity.completionstatus = 'UP';
    //   this.saveQuizCompletionData(activity);
    // }

    if (activity.completionstatus === "Y") {
      activity.completionstatus = "Y";
    } else if (event.status === "Y") {
      activity.completionstatus = "Y";
    } else {
      activity.completionstatus = "UP";
    }
    console.log('activity', activity);
    this.saveQuizCompletionData(activity);
  }

  saveQuizCompletionData(activityData) {
    console.log('activityData', activityData);
    this.activityService.saveActivityCompletion(activityData).then(
      (res: any) => {
        if (res.type === true) {
          this.quizCompRes = res;
          if (
            this.activityData.activitycompletionid === undefined ||
            !this.activityData.activitycompletionid
          ) {
            this.activityData.activitycompletionid = res.data[0][0].lastid;
          }
          console.log("Quiz completion res", res);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  // result1() {
  //   console.log('Quiz data with answers ', this.quiz);

  //   this.CSP.currentQuizData.quizData = this.quiz;
  //   this.CSP.currentQuizData.quizSumitData = this.quizSubmitRes;

  //   const timeSpent = this.timeInSeconds - this.remainingTime;
  //   this.makeQuizCompDataReady('UP', this.timeInSeconds, timeSpent);
  //   // this.showScore = true;
  // }

  result() {
    console.log("Quiz data with answers ", this.quiz);

    this.activityService.currentQuizData.quizData = this.quiz;
    this.activityService.currentQuizData.quizSumitData = this.quizSubmitRes;

    // const timeSpent = this.timeInSeconds - this.remainingTime;
    // if (this.quizSubmitRes && this.quizSubmitRes[0].ccFlag === 'Y') {
    //   this.makeQuizCompDataReady('Y', this.timeInSeconds, timeSpent);
    // } else {
    //   this.makeQuizCompDataReady(null, this.timeInSeconds, 0);
    // }

    // this.CSP.saveResultData(this.quizSubmitRes);
    const event = {
      loaderFlag: false,
      responseData: null,
      errorFlag: false,
      // 'showQuizReview': true,
      // 'showQuiz': false,
      showComponent: "quiz-review",
      quizSubmitResponse: this.quizSubmitRes,
      quizData: this.quiz,
      showAutoPlayConatiner: true
    };
    if (this.quizSubmitRes[0].ccFlag === "Y") {
      event["responseData"] = this.completeQuizSubmitRes[
        "activityCompletion"
      ][0];
    } else {
      event["responseData"] = null;
      // if (event.showAutoPlayConatiner){
      event["extraParams"] = this.completeQuizSubmitRes[
        "activityCompletion"
      ][0];
      // }
    }

    console.log("Event Emit", event);
    this.componentData.emit(event);
    // this.router.navigate(['quiz-review'], { relativeTo: this.routes });
    // this.showScore = true;
  }

  gotoNext(data) {
    console.log("DATA==>", data);
  }

  goBack() {
    // this.router.navigate(['../../learn'], { relativeTo: this.routes });
    // window.history.back();
  }

  // submitServiceCall(parameters) {

  // 	var url = ENUM.domain + ENUM.url.submitQuizAnswers;
  // 	this.CSP.submitQuizData(url, parameters)
  // 		.then((result: any) => {
  // 			console.log('RESULT==>', result);
  // 		}).catch(result => {
  // 			console.log('ServerResponseError :', result);
  // 		})
  // }

  checkIfValidInputText(currentIndex, currentQues) {
    // const currentQuestion = this.quiz[currentIndex].list;
    const currentQuestion = currentQues;
    if (
      currentQuestion.optionList[0].sAns.length <
      currentQuestion.optionList[0].minLen
    ) {
      currentQuestion.optionList[0]["isValid"] = false;
      currentQuestion.optionList[0]["msg"] =
        "Answer must be at least" +
        " " +
        currentQuestion.optionList[0]["minLen"] +
        " " +
        "character.";
    } else if (
      currentQuestion.optionList[0].sAns.length >
      currentQuestion.optionList[0].maxLen
    ) {
      currentQuestion.optionList[0]["isValid"] = false;
      currentQuestion.optionList[0]["msg"] =
        "Answer must be less than" +
        " " +
        currentQuestion.optionList[0]["maxLen"] +
        " " +
        "character.";
    } else {
      currentQuestion.optionList[0]["isValid"] = true;
    }
  }

  makequizQuestionReady(question, type): SafeHtml {
    if (type == "question") {
      if (question.isMandatory == 1) {
        let questionFinal = question.qText + ' <b class="mandatoryQues">*</b>';
        return this.sanitizer.bypassSecurityTrustHtml(questionFinal);
      } else {
        return this.sanitizer.bypassSecurityTrustHtml(question.qText);
      }
    } else {
      return this.sanitizer.bypassSecurityTrustHtml(question);
    }
  }
  makefeedback(question) {
    if (question) {
      var fb = question.replace(/<[^>]+>/g, "");
      fb = fb.replace(/&nbsp;/g, "");
      if (fb.length > 92) {
        return fb.substring(0, 92);
      } else {
        return fb;
      }
    }
  }
  isCollapsed = false;
  hideToggle = false;
  toggleView(question) {
    this.isCollapsed = !this.isCollapsed;
    this.determineView(question);
  }
  maxLength = 5;
  determineView(question) {
    if (!question || question.length <= this.maxLength) {
      // this.currentText = this.text;
      this.isCollapsed = false;
      this.hideToggle = true;
      return;
    }
    this.hideToggle = false;
    if (this.isCollapsed == true) {
      question = question.substring(0, this.maxLength) + "...";
    } else if (this.isCollapsed == false) {
      question = question;
    }
  }

  backPressEventQuiz() {
    if (
      this.finalQuizQuestionsData &&
      this.finalQuizQuestionsData.length !== 0
    ) {
      const finalQuizData = this.makeFinalQuizQuestionDataReady(
        this.finalQuizQuestionsData
      );
      this.retrySubmit = false;
      if (finalQuizData && !this.quizSubmitted) {
        this.backPressEventForQuiz = true;
        this.submitQuestionAnswerAsync(finalQuizData, (result) => {
          if (result) {
            if (result.type === true && result.data) {
              this.quizSubmitRes = result.data[0];
              console.log("Quiz result online : ", result);
              this.quizSubmitError = false;
              this.quizSubmitted = true;
            } else {
              // this.presentErrorPrompt();
              console.log("Quiz result online error : ", result);
              this.quizSubmitError = true;
            }
          } else {
            // this.presentErrorPrompt();
            console.log("Quiz result online error : ", result);
            this.quizSubmitError = true;
          }
        });
      } else {
        if (!this.hasFinished && !this.quizSubmitted) {
          const timeSpent = this.timeInSeconds - this.remainingTime;
          this.makeQuizCompDataReady(null, this.timeInSeconds, timeSpent);
        }
      }

    } else {
      if (this.activeQuizTimer) {
        clearTimeout(this.activeQuizTimer);
      }
    }

  }

  readfb(fbtext) {
    this.feedbacktext = fbtext;
    this.showfb = true;
  }
  closefb() {
    this.showfb = false;
  }
  resetReattemptQuiz() {
    const quizData = {
      quizData: "",
      reviewFlag: false,
      reattemptFlag: false,
    };
    this.activityService.currentQuizData.reviewFlag = quizData.reviewFlag;
    this.activityService.currentQuizData.quizData = quizData.quizData;
    this.activityService.currentQuizData.reattemptFlag = quizData.reattemptFlag;
  }

  showSubmitError = false;
  // modalReceiveInvitation: any = {
  //   title: 'Error',
  //   subtitle: '',
  //   message: 'Unable to submit quiz question. Do you want to retry ?',
  //   event_first: 'Yes',
  //   event_second: 'No'
  // };
  currentPageQuesData: any;
  currentOptId: any;
  retrySubmission() {
    console.log("this.currentPageQuesData ==>", this.currentPageQuesData);
    console.log("this.currentOptId ==>", this.currentOptId);
    this.retrySubmit = true;

    this.submitAnswer(this.currentPageQuesData, this.currentOptId);
  }

  getQuizIntialDataAgain() {
    this.showGetQuizQuestionError = false;
    this.disableMultipleCalls = true;
    // this.spinner.show();
    const event = {
      loaderFlag: true,
      responseData: null,
      errorFlag: false,
      // 'showQuiz': true,
      // 'showQuizReview': false,
      showComponent: "quiz",
    };
    console.log("Event Emit", event);
    this.componentData.emit(event);
    this.cdf.detectChanges();
    this.getActivityData();
  }

  ngOnDestroy() {
    this.backPressEventQuiz();
    // if (this.activeQuizTimer) {
    //   clearTimeout(this.activeQuizTimer);
    // }
  }

  ngOnChanges() {
    if (this.courseDataContentInput && this.courseDataSummaryInput) {
      this.courseDataContent = this.courseDataContentInput;
      this.courseTitle = this.courseDataSummaryInput.courseTitle || this.courseDataSummaryInput.instanceName;
      this.courseDataSummary = this.courseDataSummaryInput;
      this.getUserData();
      this.reIntializeData();
    }
  }
  reIntializeData() {
    const event = {
      loaderFlag: true,
      responseData: null,
      errorFlag: false,
      showComponent: "quiz",
      // 'showQuiz': true,
    };
    console.log("Event Emit", event);
    this.componentData.emit(event);

    this.showScore = false;
    this.setDefaultVarData();
    this.getUserData();
    this.getActivityData();
  }


  handleEvent(event: CustomEvent) {
    console.log(`${event.name} has been clicked on image ${event.imageIndex + 1}`);

    switch (event.name) {
      case "exit":
        console.log("exit logic");
        if (!this.showImageInFullScreen) {
          document.exitFullscreen();
        } else {
          this.showImageInFullScreen = false;
        }
        break;
    }
  }


  config: ImageViewerConfig = {
    wheelZoom: true,
    allowFullscreen: false,
    customBtns: [
      {
        name: "exit",
        icon: "fa fa-times",
        buttonClass: "showOnTop",
        tooltip: "Exit",
      },
    ],
  };
  imageIndexOne = 0;
  images = [
    // 'https://images.unsplash.com/photo-1591027265828-5678792f7c33?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1000&q=80'
    // 'https://i.ytimg.com/vi/nlYlNF30bVg/hqdefault.jpg',
    // 'https://www.askideas.com/media/10/Funny-Goat-Closeup-Pouting-Face.jpg'
  ];
  showImageInFullScreen = false;
  openInFullScreen(imageRef) {
    if (!imageRef && imageRef == "" && !this.isURL(imageRef)) {
      // this.toastr.warning("Invalid image!", "Warning");
      this.presentAlert("Invalid image!", "Warning!!!");
      return null;
    }
    this.images[0] = imageRef;
    this.showImageInFullScreen = true;

    // this.toggleClassChange = true;
    // setTimeout(()=>openInFullScreen{
    //   const element = document.querySelector('ngx-image-viewer');
    //   this.makeElementFullScreen(element);
    // }, 2);
  }

  isURL(str) {
    const regex = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
    const pattern = new RegExp(regex);
    return pattern.test(str);
  }

  async presentAlert(msg, title) {
    // this.alert = await this.alertController.create({
    //   header: "",
    //   subHeader: "",
    //   backdropDismiss: false,
    //   message: msg,
    //   buttons: [
    //     {
    //       text: "OK",
    //       cssClass: "okbutton",
    //       handler: (blah) => {
    //         console.log("Confirm Cancel: blah");
    //         // this.router.navigateByUrl('/tabs/learn');
    //         // if (this.courseActivityData.frompage == 'module') {
    //         //   this.router.navigateByUrl('/module');
    //         // }
    //         // if (this.courseActivityData.frompage == 'trainer') {
    //         //   this.router.navigateByUrl('/trainer-detail');
    //         // }
    //         if (this.formIsValid) {
    //           window.history.back();
    //         }
    //       },
    //     },
    //   ],
    // });
    // this.alert.present();
    await this.toaster.prsentToast(msg, title, "warning", null);

  }
}
