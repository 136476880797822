import {
  Component,
  OnInit,
  Output,
  Input,
  EventEmitter,
  ChangeDetectorRef,
} from "@angular/core";
import { Subscription } from "rxjs";

import { ModuleService } from "../../providers/module/module.service";
import { ActivityService } from "../../providers/activity/activity.service";
import { ToasterService } from "../../providers/toaster/toaster.service";
import { EventsService } from "../../providers/events/events.service";

import { PdfViewer } from "../../interfaces/pdf-viewer";

@Component({
  selector: "ngx-activities-container",
  templateUrl: "./activities-container.component.html",
  styleUrls: ["./activities-container.component.scss"],
})
export class ActivitiesContainerComponent implements OnInit {
  @Output() componentData = new EventEmitter();
  @Input() courseDataContentInput: any = {};
  @Input() courseDataSummaryInput: any = {};
  languageChangeSubscriber = Subscription.EMPTY;
  pdfViewerConfig: Partial<PdfViewer> = {
    height: "71vh",
    zoom: "70%",
    mobileFriendlyZoomSetting: "70%",
  };
  playerReady = false;
  featureConfig;
  // activityCompletionCalled = false;
  // componentEventData = {
  //   'loaderFlag' : false,
  //   'activityResponseData': null,
  // };
  trainerData: any;
  usersData: any;
  mimetype: any;
  courseDataSummary: any;
  courseDataContent: any;
  courseTitle: any;
  courseDetail: any;
  url: any;
  showdiv: boolean = false;
  // googleProvider = ["pdf", "txt", "doc", "docx"];
  // microsoftProvider = ["xlsx", "odt", "odp", "ods", "ppt", "pptx"];
  provider: boolean = false;
  document: any;
  description: any;
  userdetail: any;
  completionstatus: any;
  courseFrom: any;
  voiceCall: boolean;
  videoCall: boolean;
  tenantId: number;
  windowFullScreen = false;
  courseReadyFlag: boolean;
  activityName: any;
  // config: WebinarConfig;
  config = null;

  // isYoutube: boolean;
  scormStatus: any;
  // languageChangeSubscriber = Subscription.EMPTY;

  activityCompRes: any;
  saveActCompWorking = false;

  constructor(
    public activityService: ActivityService,
    public moduleService: ModuleService,
    public cdf: ChangeDetectorRef,
    // public documentservice: DocumentViewerService,
    private eventsService: EventsService,
    public toaster: ToasterService // public multiLanguageService: MultiLanguageService
  ) {
    this.completionstatus = "UP";
    this.provider = false;
    this.showdiv = false;

    // this.componentEventData.loaderFlag = true;

    // this.spinner.show();
    // this.componentData.emit(true);
    this.courseReadyFlag = false;
    this.courseDetail = {
      fileinfo: {
        documenttype: null,
      },
      reference: null,
      show: false,
    };

    // this.featureConfig = feature;

    this.languageChangeSubscriber = this.eventsService.subscribe(
      "course:languageChange",
      (selectedactivityLanguage) => {
        //do what ever needs doing when data changes
        console.log("selectedLanguage", selectedactivityLanguage);
        if (selectedactivityLanguage) {
          // this.isYoutube = false;
          this.showdiv = false;
          // this.imageError.flag = false;
          this.cdf.markForCheck();
          this.courseDataContent = selectedactivityLanguage;
          setTimeout(() => {
            this.reIntializeData();
          }, 300);
        }
      }
    );
  }

  ngOnInit() {
    // const event = {
    //   'loaderFlag': true,
    //   'responseData': null,
    // }
    // this.componentData.emit(event);
    // // this.courseDataContent = this.activityService.getDataContent();
    // // this.courseDataSummary = this.activityService.getDataSummary();
    // console.log('DATA--->', this.courseDataContent);
    // console.log('DATA--->', this.courseDataSummary);
    if (document["fullscreenElement"]) {
      this.windowFullScreen = true;
    } else {
      this.windowFullScreen = false;
    }
    //     this.windowFullScreen = document['fullscreenEnabled'] || document['mozFullscreenEnabled '] ||
    // document['webkitFullscreenEnabled '] || document['mswebkitFullscreenEnabled'];
    console.log("windowFullScreen--->", this.windowFullScreen);
    ["", "webkit", "moz", "ms"].forEach((prefix) =>
      document.addEventListener(
        prefix + "fullscreenchange",
        () => {
          if (!window.screenTop && !window.screenY) {
            this.windowFullScreen = false;
            console.log("not fullscreen");
          } else {
            this.windowFullScreen = true;
            console.log("fullscreen");
          }
          this.resizeWindowIframe();
        },
        false
      )
    );
  }

  getFileFormat(filename) {
    if (!filename) return null;
    return filename.split(".").pop();
  }

  ngOnDestroy(): void {
    this.languageChangeSubscriber.unsubscribe();
  }

  // this is for get trainer data and learnerdata
  gettrainersData(data) {
    // console.log('gettrainersData', data);
    const params = {
      cId: data.courseId,
      mId: data.moduleId,
      tId: data.tenantId,
    };
    this.moduleService
      .gettrainer(params)
      .then((result: any) => {
        if (result.type == true) {
          this.usersData = result["data"][0];
          this.config = {
            voice: this.voiceCall,
            video: this.videoCall,
            creator: this.userdetail.roleId === 7 ? true : false,
            areaId: null,
            instanceId: null,
            tenantId: null,
            type: null,
            recording: false,
            allowCalls: true,
            startDate: "",
            EndDate: "",
          };
          this.courseReadyFlag = true;
          // this.spinner.hide();
          // this.componentEventData.loaderFlag = false;
          // this.spinner.show();
          // this.componentData.emit(this.componentEventData);
          const event = {
            loaderFlag: false,
            errorFlag: false,
            activityResponseData: null,
            showComponent: "activity-container",
          };
          // this.spinner.show();
          this.componentData.emit(event);

          console.log(" this.trainerData --->", this.trainerData);
          // this.toastr.success('Enrol Request Sent Successfully', 'Success!');
        } else {
          // this.toastr.error(
          //   'Please try again after sometime',
          //   'Error Occured!'
          // );
          // this.toastr
          //   .warning("", "Warning!")
          //   .onHidden.subscribe(() => this.toastr.clear());
          this.presentAlert(
            "Something went wrong please try again later",
            "Warning!",
            "warning"
          );
        }
      })
      .catch((result) => {
        const event = {
          loaderFlag: false,
          errorFlag: true,
          activityResponseData: null,
          showComponent: "activity-container",
        };
        // this.spinner.show();
        this.componentData.emit(event);
        // this.toastr
        //   .warning("Something went wrong please try again later", "Warning!")
        //   .onHidden.subscribe(() => this.toastr.clear());
        // console.log("ServerResponseError :", result);
        this.presentAlert(
          "Something went wrong please try again later",
          "Warning!",
          "warning"
        );
      });
  }
  getUsersData(data) {
    console.log("getUsersList", data);
    const params = {
      cId: data.courseId,
      aId: 2,
      tId: this.userdetail.tenantId,
    };
    if (this.featureConfig.webinar.BBB) {
      params["flag"] = 1;
    } else {
      params["flag"] = 0;
    }
    this.moduleService
      .getlearner(params)
      .then((result: any) => {
        console.log("DATA--->", result);
        if (result.type == true) {
          this.usersData = result["data"][0];
          this.config = {
            voice: this.voiceCall,
            video: this.videoCall,
            creator: this.userdetail.roleId === 7 ? true : false,
            areaId: null,
            instanceId: null,
            tenantId: null,
            type: null,
            recording: this.userdetail.roleId === 7 ? true : false,
            allowCalls: true,
            startDate: "",
            EndDate: "",
          };
          this.courseReadyFlag = true;
          // this.spinner.hide();
          const event = {
            loaderFlag: false,
            activityResponseData: null,
            errorFlag: false,
            showComponent: "activity-container",
          };
          // this.spinner.show();
          this.componentData.emit(event);
          // this.toastr.success('Enrol Request Sent Successfully', 'Success!');
        } else {
          // this.toastr.error(
          //   'Please try again after sometime',
          //   'Error Occured!'
          // );
          const event = {
            loaderFlag: false,
            activityResponseData: null,
            errorFlag: true,
            showComponent: "activity-container",
          };
          // this.spinner.show();
          this.componentData.emit(event);
          // this.toastr
          //   .warning("Something went wrong please try again later", "Warning!")
          //   .onHidden.subscribe(() => this.toastr.clear());
          this.presentAlert(
            "Something went wrong please try again later",
            "Warning!",
            "warning"
          );
        }
      })
      .catch((result) => {
        const event = {
          loaderFlag: false,
          activityResponseData: null,
          errorFlag: true,
          showComponent: "activity-container",
        };
        // this.spinner.show();
        this.componentData.emit(event);
        // this.toastr
        //   .warning("Something went wrong please try again later", "Warning!")
        //   .onHidden.subscribe(() => this.toastr.clear());
        this.presentAlert(
          "Something went wrong please try again later",
          "Warning!",
          "warning"
        );
        console.log("ServerResponseError :", result);
      });
  }

  disableCompletionCall = false;

  webinarActivityCompletion(event) {
    // console.log('this.courseFrom', this.courseFrom)
    // console.log('$event', $event);
    // if ($event) {
    //   this.courseDetail.completionstatus = 'Y';
    //   // this.activityService.saveActivityCompletion(this.courseDetail);
    //   this.saveActivityCompletionData(this.courseDetail);
    // }
    console.log("call connecting", event);
    if (event && !this.disableCompletionCall) {
      this.disableCompletionCall = true;
      if (event.webinarInformation.isCreator === true) {
        const eventOutput = {
          loaderFlag: true,
          responseData: null,
          errorFlag: false,
          showComponent: "activity-container",
        };
        this.componentData.emit(eventOutput);
        if (this.courseDataContent) {
          let params = {
            courseId: this.courseDataContent.courseId,
            moduleId: this.courseDataContent.moduleId,
            activityId: this.courseDataContent.activityId,
            tId: this.userdetail.tenantId,
          };
          params["userStr"] = this.getJoinedDataForWebinar(
            event.calledParticipantsList
          );
          if (event.type === "callInvitation") {
            params["isCompleted"] = 0;
          }
          if (event.type === "callEnded") {
            params["isCompleted"] = 1;
          }

          this.activityService
            .saveActivityCompletionForWebinar(params)
            .then((res: any) => {
              console.log("Webinar activity Completion Save Result : ", res);
              if (res["type"] === true) {
                // this.activityCompRes = res;
                // if (this.courseDetail.activitycompletionid === undefined || !this.courseDetail.activitycompletionid) {
                //   this.courseDetail.activitycompletionid = res['data'][0][0].lastid;
                // }
                const event = {
                  loaderFlag: false,
                  responseData: res["data"][0][0],
                  errorFlag: false,
                  showComponent: "activity-container",
                };
                if (params["isCompleted"] == 1) {
                  event["showComponent"] = "activity-container";
                }
                this.componentData.emit(event);
                this.disableCompletionCall = false;
                console.log("Activity completion res", res);
              } else {
                this.disableCompletionCall = false;
              }
            })
            .catch((err) => {
              console.log("Webinar activity Completion Save Error : ", err);
              const event = {
                loaderFlag: false,
                responseData: null,
                errorFlag: true,
                showComponent: "activity-container",
              };
              // if (params['isCompleted'] == 1) {
              //   event['showComponent'] = 'activity-container';
              // }
              this.componentData.emit(event);
              // this.toastr.warning('Something went wrong please try again later', 'Warning!');
              this.disableCompletionCall = false;
              // console.log("ServerResponseError :", err);
            });
        }
      }
    }
  }

  getJoinedDataForWebinar(calledParticipantsList) {
    const string = calledParticipantsList
      .map(function (elem) {
        return elem.id + "#" + elem.enrolId;
      })
      .join("|");
    return string;
  }

  saveActivityCompletionData(activityData) {
    // if (!this.saveActCompWorking) {
    if (Number(this.userdetail["roleId"]) === 8) {
      if (activityData.formatId == 15) {
        const eventPass = {
          loaderFlag: false,
          errorFlag: false,
        };
        if (activityData.res && activityData.res[0]) {
          if (activityData.completionstatus === "Y") {
            eventPass["showComponent"] = "next-activity";
            eventPass["responseData"] = activityData.res[0];
          }
          const temRes = {
            data: [
              activityData.res
            ]
          }
          this.activityCompRes = temRes;
        } else {
          eventPass["showComponent"] = "activity-container";
          eventPass["responseData"] = null;
        }
        this.componentData.emit(eventPass);
      } else {
        const event = {
          // 'loaderFlag': true,
          responseData: null,
          errorFlag: false,
          showComponent: "activity-container",
        };
        // if (activityData.completionstatus === 'Y'){
        //   event['loaderFlag'] = true;
        // }else {
        //   // 'showComponent': 'activity-container',
        //   event['loaderFlag'] = false;
        // }
        event["loaderFlag"] = false;
        this.componentData.emit(event);
        this.saveActCompWorking = true;
        // this.activityService.saveActivityCompletion(activityData).then(
        this.activityService.saveContentCompletion(activityData).then(
          (res) => {
            this.saveActCompWorking = false;
            if (res["type"] === true) {
              const eventPass = {
                loaderFlag: false,
                // 'responseData': res,
                errorFlag: false,
              };
              if (
                res &&
                res["data"] &&
                res["data"].length != 0 &&
                res["data"][0].length != 0
              ) {
                if (activityData.completionstatus === "Y") {
                  eventPass["showComponent"] = "next-activity";
                  eventPass["responseData"] = res["data"][0][0];
                }
              } else {
                // 'showComponent': 'activity-container',
                eventPass["showComponent"] = "activity-container";
                eventPass["responseData"] = null;
              }
              this.componentData.emit(eventPass);
              this.activityCompRes = res;

              if (this.courseDetail.areaId != 34 && (this.courseDetail.activitycompletionid === undefined || !this.courseDetail.activitycompletionid)) {
                this.courseDetail.activitycompletionid =
                  res["data"][0][0].lastid;
              }
              if (this.courseDetail.areaId == 34 && (this.courseDetail.contentcomplitionId === undefined || !this.courseDetail.contentcomplitionId)) {
                this.courseDetail.contentcomplitionId =
                  res["data"][0][0].lastid;
              }
              console.log("Activity completion res", res);
            }
          },
          (err) => {
            const eventPass = {
              loaderFlag: false,
              responseData: null,
              errorFlag: true,
              showComponent: "activity-container",
            };
            this.componentData.emit(eventPass);
            this.saveActCompWorking = false;
            console.log(err);
          }
        );
      }
    } else {
      const eventPass = {
        loaderFlag: false,
        responseData: null,
        errorFlag: false,
        showComponent: "activity-container",
      };
      this.componentData.emit(eventPass);
      // params['tempId'] = false;
    }
    // } else {
    // }
  }

  open_browser(course) {
    console.log(course);
    this.url = course.reference;
    if (this.url) {
      this.courseDetail.completionstatus = "Y";
      // this.saveActivityCompletionData(this.courseDetail);
      if (this.saveActCompWorking === false) {
        this.saveActivityCompletionData(this.courseDetail);
      }
      window.open(this.url, "_blank");
    } else {
      // this.toastr
      //   .warning("Please try again after sometime", "Error Occured!")
      //   .onHidden.subscribe(() => this.toastr.clear());
      this.presentAlert(
        "Something went wrong please try again later",
        "Warning!",
        "warning"
      );
    }
  }

  // fnYoutubeId(url) {
  //   const VID_REGEX = /(?:youtube(?:-nocookie)?\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  //   if (url.match(VID_REGEX)) {
  //     // this.youtubeId = url.match(VID_REGEX)[1];
  //   }
  // }
  // fnYoutubeVideo(url) {
  //   if (this.isURL(url)) {
  //     const list = ["youtu.be", "www.youtube.com"];
  //     // this.isYoutube = false;
  //     const hostname = new URL(url).hostname;
  //     const index = list.indexOf(hostname);
  //     return index >= 0 ? true : false;
  //   }
  // }

  // isURL(str) {
  //   try {
  //     new URL(str);
  //   } catch (_) {
  //     return false;
  //   }
  //   return true;
  // }

  ngOnChanges() {
    if (this.courseDataContentInput && this.courseDataSummaryInput) {
      this.courseDataContent = this.courseDataContentInput;
      this.courseDataSummary = this.courseDataSummaryInput;
      this.getUserData();
      this.reIntializeData();
    }
    console.log("On Changes");
  }

  reIntializeData() {
    this.resetDependancyContainer();
    if (this.courseDataContent) {
      this.courseTitle = this.courseDataContent.courseName;
      this.courseDetail = this.courseDataContent;
    }
    if (!this.courseDetail.completionstatus) {
      this.courseDetail.completionstatus = this.completionstatus;
    }
    if (this.courseDetail.formatId == 1) {
      // this.videoservice.setVideoTrack(this.courseDetail);
      if (
        !this.courseDetail.completionstatus &&
        this.courseDetail.completionstatus !== "Y"
      ) {
        this.courseDetail.completionstatus = this.completionstatus;
      }

      // this.videoservice.setActivity(this.courseDetail);
    } else if (this.courseDetail.formatId == 2) {
      // this.audioTrack = {
      //   name: this.courseDetail.activityName,
      //   artist: this.courseDetail.activityName,
      //   url: this.courseDetail.reference,
      //   cover: "assets/images/cover1.jpg",
      // };
      // this.audioTrackList = [];
      // this.audioTrackList.push(this.audioTrack);
      // this.audioservice.setTrack(this.audioTrackList);
      if (
        !this.courseDetail.completionstatus &&
        this.courseDetail.completionstatus !== "Y"
      ) {
        this.courseDetail.completionstatus = this.completionstatus;
      }
    }
    //  else if (this.courseDetail.formatId == 7) {
    //   // this.courseDetail.completionstatus = 'Y';
    //   // this.activityService.saveActivityCompletion(this.courseDetail);
    // }
    // else if (
    //   this.courseDetail.formatId == 3
    // ) {
    //   // let provider = this.findprovider(this.courseDetail.reference);
    //   // this.documentservice.setDocument(this.courseDetail.reference, provider);
    //   this.provider = true;
    //   // this.courseDetail.completionstatus = 'Y';
    //   // this.saveActivityCompletionData(this.courseDetail);
    // }
    // else if (this.courseDetail.formatId == 4) {
    // }
    else if (this.courseDataContent.activityTypeId === 11) {
      if (this.courseDataSummary["courseFrom"] === undefined) {
        this.voiceCall = false;
        this.videoCall = false;
        this.gettrainersData(this.courseDetail);
        this.courseFrom = "learner";
      } else {
        this.voiceCall = true;
        this.videoCall = true;
        this.courseFrom = "trainer";
        this.getUsersData(this.courseDetail);
      }
    }
    // if (
    //   (this.courseDataContent.reference &&
    //     this.courseDataContent.formatId == 6) ||
    //   this.courseDataContent.formatId == 8 ||
    //   this.courseDataContent.mimeType == "application/x-msdownload"
    // ) {
    //   // this.isYoutube = this.fnYoutubeVideo(this.courseDetail.reference);
    //   // this.fnYoutubeId(this.courseDetail.reference);
    // }

    this.showdiv = true;

    console.log(
      "course=============================>>>>>>>>>>>>>",
      this.courseTitle
    );
  }

  getUserData() {
    // this.userdetail = JSON.parse(localStorage.getItem("userDetails"));
    // this.userdetail.eid = localStorage.getItem("employeeId");
    if (localStorage.getItem("currentUser")) {
      this.userdetail = JSON.parse(localStorage.getItem("currentUser"));
    }
  }

  getUpdatedActivity() {
    const event = {
      loaderFlag: true,
      responseData: null,
      errorFlag: false,
      showComponent: "activity-container",
    };
    this.componentData.emit(event);
    const params = {
      activityId: this.courseDataContent.activityId,
      moduleId: this.courseDataContent.moduleId,
      courseId: this.courseDataContent.courseId,
      enrolId: this.courseDataContent.enrolId,
      areaId: this.courseDataSummary.areaId,
      contentId: this.courseDataContent.contentId,
    };
    this.activityService
      .getUpdatedActivityData(params)
      .then((res) => {
        if (res["type"] === true) {
          const event = {
            loaderFlag: false,
            responseData: res["data"]["updatedActivityData"],
            errorFlag: false,
            showComponent: "activity-container",
          };

          this.componentData.emit(event);
          // this.disableCompletionCall = false;
          console.log("Activity completion res", res);
        } else {
          const event = {
            loaderFlag: false,
            responseData: null,
            errorFlag: false,
            showComponent: "activity-container",
          };

          this.componentData.emit(event);
        }
      })
      .catch((error) => {
        const event = {
          loaderFlag: false,
          responseData: null,
          errorFlag: true,
          showComponent: "activity-container",
        };
        // if (params['isCompleted'] == 1) {
        //   event['showComponent'] = 'activity-container';
        // }
        this.componentData.emit(event);
      });
  }

  resizeWindowIframe() {
    setTimeout(() => {
      const node = document.querySelector(
        "youtube-player > iframe"
      ) as HTMLIFrameElement;
      if (node) {
        node.height = String(
          document.querySelector("#fullDiv").clientHeight - 10
        );
        this.playerReady = true;
      }
    }, 100);
  }

  getScormStatus(event) {
    this.scormStatus = event;

    // Autoplay Playlist changes
    if (this.courseDataSummary.areaId == 34 && this.courseDataContent.playlistId) {
      const event = {
        loaderFlag: false,
        responseData: null,
        errorFlag: false,
        showComponent: "activity-container",
        showAutoPlayConatiner: true,
        extraParams: this.scormStatus
      };
      this.componentData.emit(event);
    }

  }

  // imageLoaded(event) {
  //   console.log("Event", event);
  //   if (event && event.target) {
  //     const x = event.srcElement.x;
  //     const y = event.srcElement.y;
  //     if (x !== 0 && y !== 0) {
  //       const width = event.srcElement.width;
  //       const height = event.srcElement.height;
  //       const portrait = height > width ? true : false;
  //       console.log("Loaded: ", width, height, "portrait: ", portrait);
  //       if (this.courseDetail.completionstatus !== "Y") {
  //         this.courseDetail.completionstatus = "Y";
  //         this.saveActivityCompletionData(this.courseDetail);
  //       }
  //     }
  //   }
  // }
  dependencyConatiner = {
    displayFlag: false,
    message: "",
  };
  sendContentLoadErrorMessage(type) {
    switch (type) {
      case "pdf":
        this.dependencyConatiner.message =
          "PDF file is corrupted. Please Contact site administrator.";
        // else {
        //     this.dependencyConatiner.message =
        //     "Unable to generate PDF link. Please try again later.";
        // }
        break;
      case "image":
        this.dependencyConatiner.message =
          "Image file corrupted or not accessible. Please Contact site administrator";
        break;
      case "article":
        this.dependencyConatiner.message =
          "Article Url is corrupted or not accessible. Please Contact site administrator";
        break;
      default:
        break;
    }
    this.dependencyConatiner.displayFlag = true;
  }

  resetDependancyContainer() {
    this.dependencyConatiner = {
      displayFlag: false,
      message: "",
    };
  }

  updateActivityCompletionForAll(event) {
    let activity = this.courseDetail;
    // event.formatId == 3 ||
    if (
      event.formatId == 7 ||
      event.formatId == 9 ||
      event.formatId == 10 ||
      event.formatId == 11 ||
      event.formatId == 12 ||
      event.formatId == 16
    ) {
      event.completionstatus = "Y";
      activity = event;
    } else if (event.formatId == 3) {
      // if (event.previousPage == event.totalPages) {
      //     event.completionstatus = 'Y';
      // } else {
      //     event.completionstatus = 'UP';
      // }
      activity = event;
    } else if (event.formatId == 5) {
      // if (event.scormTrack) {
      //   const scormData = event.scormTrack;
      //   event.trackList = this.scormService.populateScormDetail(event);
      //   if (scormData['cmi.core.lesson_status'] === 'passed' || scormData['cmi.core.lesson_status'] === 'completed') {
      //     event.completionstatus = 'Y';
      //   } else {
      //     event.completionstatus = 'UP';
      //   }
      // } else {
      //   event.completionstatus = 'UP';
      // }
      // activity = event;
    } else if (event.formatId == 15) {
      activity = event;
    } else if (activity.completionstatus === "Y") {
      activity.completionstatus = "Y";
    } else if (event.status === "Y") {
      activity.completionstatus = "Y";
    } else {
      activity.completionstatus = "UP";
    }
    activity.contenttime = event.duration
      ? event.duration
      : activity.contenttime;
    activity.contentwatchedtime = event.currentTime
      ? event.currentTime
      : activity.contentwatchedtime;

    // Old Code
    // activity.contenttime = this.convertTimeIntoInt(activity.contenttime);
    // activity.contentwatchedtime = this.convertTimeIntoInt(
    //   activity.contentwatchedtime
    // );
    //  New Data
    activity.contenttime = activity.contenttime;
    activity.contentwatchedtime = activity.contentwatchedtime;
    activity.contentId = activity.contentRepId; // Changes by Bhavesh Tandel
    if (this.saveActCompWorking === false) {
      this.saveActivityCompletionData(activity);
    }
    //  else if (event.acticon !== 'video' || event.acticon !== 'audio' || event.acticon !== 'kpoint') {
    //   this.saveActivityCompletionData(activity);
    // }
  }

  convertTimeIntoInt(duration) {
    if (duration) {
      return parseInt(duration, 10);
    } else {
      // if (duration == null) {
      //   return 0;
      // }
      // return duration;
      return 0;
    }
  }

  async presentAlert(msg, title, type) {
    await this.toaster.prsentToast(msg, title, type, null);
  }
}
