<div class="elist position-relative border-bottom cursor-p py-2 my-2">
  <div class="d-flex justify-content-between">
    <div class="pl-2 {{inputData?.eventBorderClassList}}">
      <div class="fs-14 font-weight-bold">{{inputData?.title}}</div>
      <div class="fs-12">{{inputData?.subTitle}}</div>

      <!-- <ng-container *ngFor="let displayIcon of inputData?.extraParams | jsonFormatter">
        <div class="fs-12 d-flex" *ngIf="displayIcon?.typeValue">
          <svg-icon svgClass="stroke-text fill-white" src="assets/icon-svg/{{displayIcon?.typeIcon}}.svg"
            [svgStyle]="{'width.rem': .8, 'height.rem': .8, 'stroke-width': 1.5}"></svg-icon>
          <span class="pl-1">{{displayIcon?.typeValue}}</span>
        </div>
      </ng-container> -->

      <div class="fs-12 d-flex align-items-center" *ngIf="inputData?.contentType">
        <svg-icon svgClass="stroke-text fill-white" src="assets/icon-svg/Course_type_icon.svg"
          [svgStyle]="{'width.rem': .8, 'height.rem': .8, 'stroke-width': 1.5}"></svg-icon>
        <span class="pl-1">{{inputData?.contentType}}</span>
      </div>
      <div class="fs-12 d-flex align-items-center">
        <svg-icon svgClass="stroke-text fill-white" src="assets/icon-svg/Clock_icon.svg"
          [svgStyle]="{'width.rem': .8, 'height.rem': .8, 'stroke-width': 1.5}"></svg-icon>
        <span *ngIf="!inputData?.allDay" class="pl-1">{{inputData?.startTimeOnly}} - {{inputData?.endTimeOnly}}</span>
        <span *ngIf="inputData?.allDay" class="pl-1"> All Day</span>
      </div>
    </div>
    <!-- <div class="fs-12 font-italic pr-1">{{inputData?.status}}</div> -->
  </div>
  <!-- <div class="icons position-absolute right-0-px top-0-px pt-3 pr-1">
    <div class="d-flex">
      <div
        class="icon bg-success lh-0 d-flex align-inputDatas-center justify-content-center h-24-px w-24-px rounded-circle mr-2">
        <svg-icon svgClass="stroke-white cursor-p" src="../../../assets/icon-svg/check.svg"
          [svgStyle]="{'width.rem': 1.1, 'height.rem': 1.1}">
        </svg-icon>
      </div>
      <div
        class="icon bg-danger lh-0 d-flex align-inputDatas-center justify-content-center h-24-px w-24-px rounded-circle">
        <svg-icon svgClass="stroke-white cursor-p" src="../../../assets/icon-svg/x.svg"
          [svgStyle]="{'width.rem': .7, 'height.rem': .7}">
        </svg-icon>
      </div>
    </div>
  </div> -->
  <!--  *ngIf="button?.btnVisibilitySts == 1 || utton?.btnVisibilitySts == 3" -->
  <ng-container *ngFor="let button of inputData?.buttons">
    <span class="fs-12 link hover-underline px-2 mx-1"
      *ngIf="button?.btnVisibilitySts == 1 || button?.btnVisibilitySts == 3" (click)="fireEvent(button,inputData)">
      {{button?.text}}
    </span>
  </ng-container>
</div>