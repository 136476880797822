import { Injectable } from "@angular/core";
import { webApi } from "../../../config";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class PreferencesService {
  baseUrl: any = webApi.baseUrl;
  getPreferencesFormUrl: any = this.baseUrl + webApi.apiUrl.getPreferenceForm;
  getPreferenceFormDataUrl: any =
    this.baseUrl + webApi.apiUrl.getPreferenceFormData;
  savePreferenceFormUrl: any = this.baseUrl + webApi.apiUrl.savePreferenceForm;
  getMandatorySkillListUrl = this.baseUrl + webApi.apiUrl.getMandatorySkillListUrl;
  saveMandatorySkillsUrl = this.baseUrl + webApi.apiUrl.saveMandatorySkill;

  firstTimeLoginPopupConfig = {
    title: "Login Info",
    message: "",
    buttons: [
      {
        id: "confirm",
        btnText: "Ok",
      },
      // {
      //   id: "cancel",
      //   btnText: "No",
      // },
    ],
  }

  afterSixMonthsPopupConfig = {
    title: "Login Info",
    message: "",
    buttons: [
      {
        id: "confirm",
        btnText: "Yes",
      },
      {
        id: "cancel",
        btnText: "No",
      },
    ],
  }

  constructor(public http: HttpClient) { }
  public getPreferenceFormData(data: any): Promise<any> {
    return this.http
      .post(this.getPreferenceFormDataUrl, data)
      .toPromise()
      .then((response) => {
        return (response = response);
      })
      .catch(this.handleError);
  }
  public savePreferenceForm(data: any): Promise<any> {
    return this.http
      .post(this.savePreferenceFormUrl, data)
      .toPromise()
      .then((response) => {
        return (response = response);
      })
      .catch(this.handleError);
  }
  public getPreferencesForm(data: any): Promise<any> {
    return this.http
      .post(this.getPreferencesFormUrl, data)
      .toPromise()
      .then((response) => {
        return (response = response);
      })
      .catch(this.handleError);
  }

  getMandatorySkillList(data: any): Promise<any> {
    return this.http.post(this.getMandatorySkillListUrl, data)
      .toPromise()
      .then(response => {
        return response = response;
      })
      .catch(this.handleError);
  }

  saveMandatorySkill(data: any): Promise<any> {
    return this.http.post(this.saveMandatorySkillsUrl, data)
      .toPromise()
      .then(response => {
        return response = response;
      })
      .catch(this.handleError);
  }

  private handleError(error: Response | any) {
    return Promise.reject(error.message || error);
  }
}
