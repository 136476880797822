<div class="row align-items-center mb-2">
    <!-- <div class="col-md-2">
        <select class="form-control cursor-p">
      <option selected>Select Topic</option>
      <option>Topic 1</option>
      <option>Topic 1</option>
      <option>Topic 1</option>
    </select>
    </div> -->
    <div class="col-md-4 text-left">
        <div class="btn-group ">
            <!-- <div class="btn cursor-p" (click)="setView(CalendarView.Month)" [ngClass]="{'btn-primary': view !== CalendarView.Month, 'btn-outline-secondary': view === CalendarView.Month}">
                Month
            </div> -->
            <div class="btn cursor-p" (click)="setView(CalendarView.Week)" [ngClass]="{'btn-primary': view !== CalendarView.Week,  'btn-outline-secondary': view === CalendarView.Week}">
                Week
            </div>
            <div class="btn cursor-p" (click)="setView(CalendarView.Day)" [ngClass]="{'btn-primary': view !== CalendarView.Day,  'btn-outline-secondary': view === CalendarView.Day}">
                Day
            </div>
        </div>
    </div>
    <div class="col-md-4 text-center">
        <!-- (click)="openEventForm($event)" -->
        <h3 class="m-0">{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
    </div>
    <div class="col-md-4 text-right">
        <div class="btn-group">
            <div class="btn btn-primary" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()">
                Previous
            </div>
            <div class="btn btn-outline-secondary" mwlCalendarToday [(viewDate)]="viewDate">
                Today
            </div>
            <div class="btn btn-primary" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()">
                Next
            </div>
        </div>
    </div>


</div>
<div [ngSwitch]="view">
    <!-- (eventTimesChanged)="eventTimesChanged($event)" -->
    <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" (beforeViewRender)="dayCalender($event)" [viewDate]="viewDate" [events]="events" [refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen" (dayClicked)="dayClicked($event.day)" (eventClicked)="handleEvent('Clicked', $event.event)">
    </mwl-calendar-month-view>
    <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" (beforeViewRender)="dayCalender($event)" (hourSegmentClicked)="hourSegementClicked($event.date)" [viewDate]="viewDate" [events]="events" [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)">
    </mwl-calendar-week-view>
    <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day" (beforeViewRender)="dayCalender($event)" (hourSegmentClicked)="hourSegementClicked($event.date)" [viewDate]="viewDate" [events]="events" [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)">
    </mwl-calendar-day-view>
</div>

<!-- Everything you see below is just for the demo, you don't need to include it in your app -->
<!--
<br /><br /><br />

<h3>
    Edit events
    <button class="btn btn-primary float-right" (click)="addEvent()">
    Add new
  </button>
    <div class="clearfix"></div>
</h3>

<div class="table-responsive">
    <table class="table table-bordered">
        <thead>
            <tr>
                <th>Title</th>
                <th>Primary color</th>
                <th>Secondary color</th>
                <th>Starts at</th>
                <th>Ends at</th>
                <th>Remove</th>
            </tr>
        </thead>

        <tbody>
            <tr *ngFor="let event of events">
                <td>
                    <input type="text" class="form-control" [(ngModel)]="event.title" (keyup)="refresh.next()" />
                </td>
                <td>
                    <input type="color" [(ngModel)]="event.color.primary" (change)="refresh.next()" />
                </td>
                <td>
                    <input type="color" [(ngModel)]="event.color.secondary" (change)="refresh.next()" />
                </td>
                <td>
                    <input class="form-control" type="text" mwlFlatpickr [(ngModel)]="event.start" (ngModelChange)="refresh.next()" [altInput]="true" [convertModelValue]="true" [enableTime]="true" dateFormat="Y-m-dTH:i" altFormat="F j, Y H:i" placeholder="Not set" />
                </td>
                <td>
                    <input class="form-control" type="text" mwlFlatpickr [(ngModel)]="event.end" (ngModelChange)="refresh.next()" [altInput]="true" [convertModelValue]="true" [enableTime]="true" dateFormat="Y-m-dTH:i" altFormat="F j, Y H:i" placeholder="Not set" />
                </td>
                <td>
                    <button class="btn btn-danger" (click)="deleteEvent(event)">
            Delete
          </button>
                </td>
            </tr>
        </tbody>
    </table>
</div> -->

<!-- <ng-template #modalContent let-close="close">
  <div class="modal-header">
    <h5 class="modal-title">Event action occurred</h5>
    <button type="button" class="close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div>
      Action:
      <pre>{{ modalData?.action }}</pre>
    </div>
    <div>
      Event:
      <pre>{{ modalData?.event | json }}</pre>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="close()">
      OK
    </button>
  </div>
</ng-template> -->