<!-- <nb-user [picture]="image" size="medium" name="John Doe" showName=false onlyPicture></nb-user> -->
<!-- *ngIf="data[labelData?.userImage] && data[labelData?.name]" -->
<span [title]="data[labelData?.name]">
  <ng-container *ngIf="!(data?.isvisible == 0)">
    <!-- <img *ngIf="labelData?.userImage" (error)="errorLoad()" [src]="data[labelData?.userImage]" alt=""> -->
    <!-- <span class="body-text pl-2" *ngIf="labelData?.name">{{data[labelData?.name]}}</span> -->
<ng-container *ngIf="setting?.maxLength">
    <nb-user [picture]="data[labelData?.userImage]" size="small" [name]="data[labelData?.name]| truncate : setting?.maxLength"></nb-user>
</ng-container>
<ng-container *ngIf="setting?.length ==0">
    <nb-user [picture]="data[labelData?.userImage]" size="small" [name]="data[labelData?.name]"></nb-user>
</ng-container>
</ng-container>
</span>