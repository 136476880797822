
// If we want to use the directive ( hide-header.directive ) for more than one directive, we need to create a SharedModule.
// So that we can add the SharedModule to all the modules we want to use the directive in.

import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentClickListnerDirective } from './doucment-click-listner/document-click-listner.directive';
import { DndDirective } from './dragAndDrop/dnd.directive';
// import { CarouselItemDirective } from '../components/carousel-item/carousel-item.directive';

@NgModule({
  declarations: [
    DocumentClickListnerDirective,
    DndDirective
  ],
  exports: [
    DocumentClickListnerDirective,
    DndDirective
  ],
  imports: [
    CommonModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class DirectivesModule { }
