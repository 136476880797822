import { Injectable } from '@angular/core';
import { ActivityService } from '../activity/activity.service';
@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor(private activityService: ActivityService) { }

  makeSelectedStrReady(arr, att, operator, isFilter) {
    if (arr && Array.isArray(arr) && arr.length != 0) {
      if (isFilter) {
        arr = arr.filter((obj) => {
          // return obj.isSelected == 1;
          return obj.isTemp == true && obj[operator] == 1;
        });
      }
      return Array.prototype.map
        .call(arr, (item) => {
          return item[att];
        })
        .join(operator);
    } else {
      return '';
    }

  }

  getSelectedArray(arr, att, status) {
    return arr.filter((obj) => {
      // return obj.isSelected == 1;
      return obj[att] == status;
    });
  }

  formatSendDateTime(Dtime, isTimeRequired) {
    // const t = new Date(Dtime);
    // // const formattedDateTime = this.datepipe.transform(t, 'yyyy-MM-dd h:mm');
    // const year = t.getFullYear() + '-' + (t.getMonth() + 1) + '-' + t.getDate();
    // const time = ' ' + t.getHours() + ':' + t.getMinutes() + ':' + t.getSeconds();
    // const formattedDateTime = year + time;
    // return formattedDateTime;

    if (Dtime != null) {
      const t = new Date(Dtime);
      // const formattedDateTime = this.datepipe.transform(t, 'yyyy-MM-dd h:mm');
      const year = t.getFullYear() + '-' + ("0" + (t.getMonth() + 1)).slice(-2) + '-' + ("0" + (t.getDate())).slice(-2);
      if (isTimeRequired) {
        const time = ' ' + ("0" + (t.getHours())).slice(-2) + ':' + ("0" + (t.getMinutes())).slice(-2) + ':' + ("0" + (t.getSeconds())).slice(-2);
        return (year + time);
      } else {
        return (year);
      }

      // console.log(formattedDateTime, "formatSendDate")
      // return formattedDateTime;
    } else {
      return null
    }
  }

  removeFromSelected(item, arr, attribute) {
    if (arr.length > 0) {
      return this.removeByAttr(arr, attribute, item[attribute]);
    } else {
      console.log("Selected array is empty");
      return []
    }
  }

  removeByAttr(arr, attr, value) {
    let i = arr.length;
    while (i--) {
      if (
        arr[i] &&
        arr[i].hasOwnProperty(attr) &&
        arguments.length > 2 &&
        arr[i][attr] === value
      ) {
        arr.splice(i, 1);
      }
    }
    return arr;
  }

  downloadResource(link, type) {
    console.log('Download ', link);
    if (type == 1) {
      this.generatePresignedUrl(link, (ref, type) => {
        if (type) {
          this.download(ref);
        } else {
          console.log('getPreSignedUrl err :');
        }
      });
    } else {
      this.download(link);
    }

  }

  generatePresignedUrl(reference, cb) {
    const activityData = {
      filename: reference,
    };
    this.activityService.getPreSignedUrl(activityData).then(res => {
      console.log('getPreSignedUrl res : ', res);
      if (res.type === true) {
        cb(res.data, true);
      } else {
        cb(null, false);
      }
    }, err => {
      console.log('getPreSignedUrl err : ', err);
      cb(null, false);
    });
  }

  private download(link) {
    if (link) {
      const element = document.createElement('a');
      // const filename = link.replace(/^.*[\\\/]/, '');
      element.href = link.replaceAll(' ', '%20');
      element.download = 'download';
      element.style.display = 'none';
      // element.setAttribute('target', '_blank');
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    } else {
      console.log('Download link not available...');
    }
  }

  cloneObject(obj) {
    if (obj) {
      return JSON.parse(JSON.stringify(obj))
    }
    return obj
  }
}
