<ngx-extended-pdf-viewer *ngIf="documenturl" [hidden]="loading" [(src)]="documenturl" [zoom]="documentViewerConfig.zoom"
  [showSidebarButton]="documentViewerConfig.showSidebarButton" [showFindButton]="documentViewerConfig.showFindButton"
  [showPagingButtons]="documentViewerConfig.showPagingButtons" [showZoomButtons]="documentViewerConfig.showZoomButtons"
  [showPresentationModeButton]="documentViewerConfig.showPresentationModeButton"
  [showOpenFileButton]="documentViewerConfig.showOpenFileButton"
  [showPrintButton]="documentViewerConfig.showPrintButton"
  [showDownloadButton]="documentViewerConfig.showDownloadButton"
  [showBookmarkButton]="documentViewerConfig.showBookmarkButton"
  [showSecondaryToolbarButton]="documentViewerConfig.showSecondaryToolbarButton"
  [showRotateButton]="documentViewerConfig.showRotateButton"
  [showHandToolButton]="documentViewerConfig.showHandToolButton"
  [showScrollingButton]="documentViewerConfig.showScrollingButton"
  [showSpreadButton]="documentViewerConfig.showSpreadButton"
  [showPropertiesButton]="documentViewerConfig.showPropertiesButton"
  [useBrowserLocale]="documentViewerConfig.useBrowserLocale" [textLayer]="documentViewerConfig.textLayer"
  [mobileFriendlyZoom]="documentViewerConfig.mobileFriendlyZoom" [showBorders]="documentViewerConfig.showBorders"
  [enablePinchOnMobile]="documentViewerConfig.enablePinchOnMobile" (pdfLoaded)="onPdfEvent('pdfLoaded', $event)"
  (pdfLoadingFailed)="onPdfEvent('pdfLoadingFailed', $event)" (pagesLoaded)="onPagesLoaded($event)"
  (pageRendered)="onPdfEvent('pageRendered', $event)" (pageChange)="onPdfEvent('pageChange', $event)"
  [height]="documentViewerConfig.height">
</ngx-extended-pdf-viewer>
<!--   [height]="documentViewerConfig.height" -->
<div *ngIf="loading" [nbSpinner]="loading" class="spinner_size" nbSpinnerSize="giant" nbSpinnerStatus="primary"></div>
<!-- Downloading {{ downloadPerc }}% -->
<!-- </div> -->