import { Directive, HostListener, OnChanges, Input, ElementRef, SimpleChanges } from '@angular/core';
// import * as screenfull from 'screenfull';

@Directive({
    selector: '[ngxToggleFullscreen]'
})
export class ToggleFullscreenDirective implements OnChanges {

    @Input('ngxToggleFullscreen')
    isFullscreen: boolean;

    constructor(private el: ElementRef) { }

    ngOnChanges(changes: SimpleChanges) {
      if (!changes.isFullscreen.isFirstChange()) {
        if (this.isFullscreen) {
          // screenfull.request(this.el.nativeElement);
          this.makeElementFullScreen(this.el.nativeElement);
        } else {
          // screenfull.exit();
          this.exitFromFullscreen(this.el.nativeElement);

        }
      }
    //   if (!changes.isFullscreen.isFirstChange()) {
    //   const document: any = window.document;
    //   const fullScreen = document.fullscreenElement ||
    //   document.webkitFullscreenElement ||
    //   document.mozFullScreenElement;
    //   if(fullScreen){
    //     this.exitFromFullscreen(this.el.nativeElement);
    //   } else {
    //     this.makeElementFullScreen(this.el.nativeElement);
    //   }
    // }
  }
    // ngAfterContentInit(){
    //   this.makeElementFullScreen(this.el.nativeElement);
    // }
    makeElementFullScreen(element){
      const document: any = window.document;
      const fullScreen = document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement;
      if (!fullScreen) {
        // can use exitFullscreen
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.mozRequestFullScreen) { /* Firefox */
          element.mozRequestFullScreen();
        } else if (element.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
          element.webkitRequestFullscreen();
        } else if (element.msRequestFullscreen) { /* IE/Edge */
          element.msRequestFullscreen();
        }
      }


    }

    exitFromFullscreen(element){

      const document: any = window.document;
      if (document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement) {
        // can use exitFullscreen
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      }

    }
}
