export const Feature = {
  webinar: {
    'jisti': false,
    'openvidu': true,
    'BBB': false,
  },
  activity: {
    'standardui': false, // OLD UI
    'modernui': true,   // Modern UI
  },
  catgoryDropDown: {
    // 'Bajaj': false,
    // 'other': true,
    'show': false,
  },
  wishlist: {
    'show': false,
    // 'Bajaj': false,
    // 'other': true,
  },
  footer: {
    'Bajaj': false,
    'other': false,
  },
  brandName: 'ILLUME',
  allowDynamicDbLogoImage: false,
  pageTitle: 'ILLUME',
  faviconPNG: '',
  faviconXICON: '',
  firebase: {
    // Temp Of Edge
    apiKey: "AIzaSyAaCxf2FgKol6lr9AYjAH1uk-VrgaxhpjQ",
    authDomain: "com-edge-learn.firebaseapp.com",
    databaseURL: "https://com-edge-learn.firebaseio.com",
    projectId: "com-edge-learn",
    storageBucket: "com-edge-learn.appspot.com",
    messagingSenderId: "1063122640156",
    appId: "1:1063122640156:web:252dac436288bae6f7aed9",
    measurementId: "G-B3C00VCK1F"
  },
};
