import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-ev-feedback',
  templateUrl: './ev-feedback.component.html',
  styleUrls: ['./ev-feedback.component.scss', '../../../@theme/styles/_typography.scss']
})
export class EvFeedbackComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
