import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ngx-event-card',
  templateUrl: './event-card.component.html',
  styleUrls: ['./event-card.component.scss']
})
export class EventCardComponent implements OnInit {
  @Input() inputData: any;
  @Output() actionEvent = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

  fireEvent(inEvent, inData) {
    const res = {
      action: inEvent,
      data: inData,
    };
    this.actionEvent.emit(res);
  }
}
