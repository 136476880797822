import { AfterViewChecked, AfterViewInit, Component, HostListener, OnInit } from "@angular/core";
// import { AnalyticsService } from "./@core/uctils/analytics.service";
// import { SeoService } from "./@core/utils/seo.service";
import { NbIconLibraries } from "@nebular/theme";

import { LoginService } from "./providers/login/login.service";
import { ThemeService } from "./providers/theme/theme.service";
// import { InitService } from "./providers/init/init.service";
import { ActivatedRoute, Router } from "@angular/router";
import { MsalService } from "@azure/msal-angular";
import { AuthenticationResult } from "@azure/msal-browser";
import { Subscription } from "rxjs";
import { CheckInactivityService } from './modules/shared/providers/utils/check-inactivity/check-inactivity.service';
import { YellowMessangerService } from "./modules/shared/providers/yellow-messanger/yellow-messanger.service";
import { AlertService } from "./providers/alert/alert.service";
import { EventsService } from './providers/events/events.service';
import { LanguageService } from "./providers/language/language.service";
import { NotificationsService } from './providers/notifications/notifications.service';
import { PreferencesService } from "./providers/preferences/preferences.service";
import { UserService } from './providers/userservice/user.service';
@Component({
  selector: "ngx-app",
  template: `
    <!-- <nb-layout windowMode> -->
    <nb-layout-column>
    <ngx-spinner
      bdColor="rgba(0, 0, 0, 0.8)"
      size="medium"
      color="#fff"
      type="ball-clip-rotate-pulse"
      [fullScreen]="true"
      ><p style="color: white">Loading...</p></ngx-spinner
    >
    <router-outlet></router-outlet>
    </nb-layout-column>

<!-- </nb-layout> -->
 `,
  // providers: [CheckInactivityService]
})
export class AppComponent implements OnInit, AfterViewInit, AfterViewChecked {

  edgeLearning: boolean;
  loggedIn: boolean;
  private subscription: Subscription;
  returnurl: string;
  // errorMsg: any;
  // private readonly _destroying$ = new Subject<void>();
  popupConfig = {
    title: "Login Info",
    message: "",
    buttons: [
      {
        id: "",
        btnText: "Okay",
      },
    ],
  };

  constructor(
    // private analytics: AnalyticsService,
    // private seoService: SeoService,
    private themeService: ThemeService,
    private authService: MsalService,
    private iconLibraries: NbIconLibraries,
    private loginService: LoginService,
    private router: Router,
    private routes: ActivatedRoute,
    private alertService: AlertService,
    private preferencesService: PreferencesService,
    private events: EventsService,
    public notificationService: NotificationsService,
    public checkInactivityService: CheckInactivityService,
    private userService: UserService,
    // private initService: InitService,
    private yellowMessangerService: YellowMessangerService,
    private languageService: LanguageService,
  ) {
    this.iconLibraries.registerFontPack("font-awesome", {
      iconClassPrefix: "fa",
    });
    const theme = {
      "commonColor": "#0072bc",
      "pageBackground": "#ebeff5",
      "header": "#ffffff",
      "logo": "https://bhaveshedgetest.s3.ap-south-1.amazonaws.com/Portal11599137422000_-_outside.png",
      "sidemenu": "#ffffff",
      "icon": "#0072bc",
      "button": "#0072bc"
    };
    localStorage.setItem('theme', JSON.stringify(theme));
  }

  ngOnInit() {
    // this.analytics.trackPageViews();
    // this.seoService.trackCanonicalChanges();
    this.themeService.changeTheme();
    // this.loginService.goToDashboard();
    // this.checkVisibility();
    this.handelSubscription();
    // this.userService.registerUserInfo(false);

    // init chatbot
    // this.yellowMessangerService.addChatbotInitListner();

    // Multi-lingual logs
    this.languageService.checkForLanguageChange();
  }

  handelSubscription() {
    this.events.subscribe('user:checkLogout', (data) => {
      this.get_updated_details(data);
    });
    // this.events.publish('user:getPushNotificationsCount', false);
    this.events.subscribe('user:callGetPushNotificationsCount', (status) => {
      // if (status) {
      this.getUnreadPushNotificationsData();
      // }
    });
    setTimeout(() => {
      this.getUnreadPushNotificationsData();
    }, 1500);
    // this.broadcastService.subscribe("msal:loginFailure", (payload) => {
    //   console.log("login failure " + JSON.stringify(payload));
    //   this.loggedIn = false;
    // });

    // this.broadcastService.subscribe("msal:loginSuccess", async (payload) => {
    //   try {
    //     const user = this.authService.getUser();
    //     let tenant = localStorage.getItem("tenantinfo");
    //     let tenantId = null;
    //     if (tenant) {
    //       tenant = JSON.parse(tenant);
    //       tenantId = tenant["tenantId"];
    //     } else {
    //       tenantId = 1;
    //     }

    //     if (user) {
    //       const param = {
    //         email: user.displayableId,
    //         tenantId: tenantId,
    //         sso: 1,
    //         usrId: user.userIdentifier,
    //         type: 3,
    //       };
    //       this.authenticateUser(param, (flag, res) => {
    //         // // this.ngxSpinner.hide();
    //         localStorage.removeItem("tenantinfo");
    //         if (flag) {
    //           this.loginService.configureUserInfo(res);
    //           localStorage.setItem("ssoaccess", "true");
    //         } else {
    //           this.router.navigate(["../auth/login"], {
    //             relativeTo: this.routes,
    //           });
    //         }
    //       });
    //     }
    //     this.loggedIn = true;
    //   } catch (e) {}
    // });
    this.authService.handleRedirectObservable().subscribe({
      next: (result: AuthenticationResult) => {
        if (result) {
          const isSigma = localStorage.getItem('sso_inprogress');
          localStorage.setItem('sso_inprogress', 'false');
          this.authService.instance.setActiveAccount(result.account);
          console.log("this.authService.handleRedirectObservable ", result);
          try {
            const user = result.account;
            let tenant = localStorage.getItem("tenantinfo");
            let tenantId = null;
            if (tenant) {
              tenant = JSON.parse(tenant);
              tenantId = tenant["tenantId"];
            } else {
              tenantId = 1;
            }
            if (user) {
              const param = {
                email: user.username,
                tenantId: tenantId,
                sso: 1,
                usrId: user.localAccountId,
                type: 3,
              };
              this.authenticateUser(param, (flag, data) => {
                localStorage.removeItem("tenantinfo");
                if (flag) {
                  let encryptedData = data['data'];
                  // let bytes = CryptoJS.AES.decrypt(encryptedData, 'worldisfullofdevelopers');
                  data = { data, ...this.loginService.decryptEncodedData(encryptedData) };
                  this.loginService.configureUserInfo(null, data);
                  this.loginService.setAccessMode(isSigma);
                  localStorage.setItem("ssoaccess", "true");
                  this.loggedIn = true;
                  this.checkInactivityService.setLastAccess(true);
                  this.checkInactivityService.resetValues();
                } else {
                  console.log("authenticate user called ");
                  this.loggedIn = false;
                  this.router.navigate(["../auth/login"], {
                    relativeTo: this.routes,
                  });
                }
              });
            }
          } catch (e) { }
        }
      },
      error: (error) => console.log(error),
    });

    // this.checkInactivityService.startTimer(null);
    this.checkInactivity(this.checkInactivityService.isUserActive());
  }

  // checkVisibility() {
  //     const _this = this;
  //     document.addEventListener('visibilitychange', () => {
  //       if (document.hidden) {
  //         //  do whatever you want
  //         _this.pauseApp(null);
  //       }
  //       else {
  //         //  do whatever you want
  //         _this.resumeApp(null);
  //       }
  //     });
  //     document.addEventListener('beforeunload', () => {
  //       //  do whatever you want
  //       _this.closeApp(null);
  //     });
  // }

  @HostListener('document:visibilitychange', ['event'])
  visibilityChange() {
    if (document.hidden) {
      this.pauseApp(null);
    } else {
      this.resumeApp(null);
    }
  }
  pauseApp(result) {
    // Background
    console.log('App pause event : ', result);
    this.events.publish('app:pause', {});
    this.checkInactivityService.setLastAccess(true);
  }

  resumeApp(result) {
    // Foreground
    console.log('App resume event : ', result);
    this.events.publish('app:resume', {});

    if (this.checkInactivityService.isUserActive()) {
      this.get_updated_details();
    } else {
      this.checkInactivity(false);
      // this.checkInactivityService.presentAlert('Please login again!');
    }
  }

  closeApp(result) {
    console.log('App close event : ', result);
    // this.checkInactivityService.setLastAccess(true);
  }


  checkInactivity(reset) {
    console.log('checking inactivity on app load...', reset);
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    if (!currentUser && !reset) {
      // this.showToast('Session expired!');
      console.log('User is active...');
      this.userService.registerUserInfo(false);
    } else {
      if (reset) {
        this.checkInactivityService.resetValues();
      } else {
        // this.checkInactivityService.stopTimer(null);
        // this.authService.logout();
        this.checkInactivityService.doLogout(true);
      }
    }
  }

  authenticateUser(param, cb) {
    if (
      localStorage.getItem("ssoaccess") &&
      localStorage.getItem("ssoaccess") === "false"
    ) {
      cb(null, null);
    } else {
      this.loginService
        .msalLogin(param)
        .then((res) => {
          console.log(res);
          var temp: any = res;
          // this.ngxSpinner.hide();
          if (temp.type == false) {
            this.popupConfig.title = 'Login Info';
            this.popupConfig.message = temp.data1;
            this.alertService.showAlert(this.popupConfig).onClose.subscribe((data) => {
              console.log('popup closed')
            })
            localStorage.setItem("ssoaccess", "false");
            cb(null, null);
          } else if (temp.type == true) {
            // this.loginService.setUserDataForLoginAndRedirect(temp);

            cb(true, temp);
          }
        })
        .catch((res) => {
          console.log(res);
          cb(false, null);
        });
    }
  }

  ngAfterViewInit() {
    //   this.getUnreadPushNotificationsData();
    // init chatbot
    this.yellowMessangerService.initBot();
  }

  ngAfterViewChecked() {
    // init chatbot
    // this.yellowMessangerService.initBot();
  }

  closeModal(event) {
    console.log("event", event);
  }


  /**************** get updated user details start *************/
  get_updated_details(data?) {
    // if (this.networkService.getOnlineStatus()) {
    let currentUser = localStorage.getItem('currentUser');
    if (currentUser) {
      currentUser = JSON.parse(currentUser);
      let userinfo = {
        email: currentUser['username'],
        tenantId: currentUser['tenantId'],
        sso: currentUser['sso_login'],
        usrId: currentUser['id']
      };
      this.loginService.get_updated_user_details().then((res) => {
        if (res.type === true) {
          let encryptedData = res['data'];
          if(!data) localStorage.setItem('UUID', this.userService.getUserUUID());
          // let bytes = CryptoJS.AES.decrypt(encryptedData, 'worldisfullofdevelopers');
          res = { res, ...this.loginService.decryptEncodedData(encryptedData) };
          const userDeatils = res.data;
          if (Number(userDeatils.force_logout) === 1) {
            this.presentLogoutAlert('You are going to logout now', 1);
          } else if (userDeatils.emp_status.toLowerCase() !== 'active') {
            this.presentLogoutAlert('You are going to logout now', 0);
          } else if (userDeatils['activeUUID']) {
            const uuid = this.userService.getUserUUID();
            let id = localStorage.getItem('UUID');
            if(!userDeatils['activeUUID'].includes(uuid) && id) this.checkInactivityService.doLogout(true);
          } else {
            this.update_current_user_details(currentUser, res);
            if (String(userDeatils.lp_flag) === '1' && userDeatils.lp_last_date != null) {
              this.presentAlert(userDeatils.lp_dialogue_msg, 0);
            } else if (String(userDeatils.lp_flag) === '1' && userDeatils.lp_last_date == null) {
              this.router.navigateByUrl('/pages/preferences');
            }
            // else {
            //   this.update_current_user_details(currentUser, res);
            // }
          }
        }
      });
    }
  }


  // update_current_user_details(currentDetails, updatedDetails) {
  //   const data = currentDetails;
  //   const userDetails = updatedDetails.data;

  //   const updatedUsrDetails = {
  //     ...data, ...userDetails
  //   };

  //   console.log('Updated current user details : ', updatedUsrDetails);
  //   this.loginService.configureUserInfo(updatedUsrDetails, updatedDetails);

  // }

  update_current_user_details(currentDetails, updatedDetails) {
    const data = currentDetails;
    const userDetails = updatedDetails.data;
    const updatedUsrDetails = {
      ...data, ...userDetails
    };
    console.log('Updated current user details : ', updatedUsrDetails);
    this.loginService.configureUserInfo(updatedUsrDetails, updatedDetails);
    const updatedUserDetails = JSON.parse(localStorage.getItem('currentUser'));
    const roleChanged = this.loginService.switchRole(updatedUserDetails, currentDetails.roleId);
    if (roleChanged) {
      this.events.publish('user:updateCurrentUserDetails', true);
    }
  }
  async presentAlert(msg, status) {
    const sixMonthsSetting = this.preferencesService.afterSixMonthsPopupConfig;
    sixMonthsSetting.message = msg;
    this.alertService.showAlert(sixMonthsSetting).onClose.subscribe((data) => {
      if (data.id === 'confirm') {
        this.loginService.goToPreference();
      } else {
        this.loginService.savePreferenceFormData();
      }
    });
  }


  /**************** get updated user details end *************/


  getUnreadPushNotificationsData() {
    if (localStorage.getItem('currentUser')) {
      const userDetails = JSON.parse(localStorage.getItem('currentUser'));
      if (userDetails) {
        // const userID = userDetails.id;
        const params = {
          userId: userDetails.id,
          currentRole: userDetails.roleId,
          tenantId: userDetails.tenantId
        };

        this.notificationService.getUnreadPushNotificationsCount(params).then(
          res => {
            if (res.type === true) {
              console.log('UnRead Notifications res :', res);
              // this.load.dismiss();

              // this.notificationService.unReadCount = res.data[0].all_unread;
              // this.notificationService.cartCount = res['cart'] ? res['cart']['mycartcnt'] : 0;
              // if (this.notificationService.cartCount > 99) {
              //   this.notificationService.cartCount = "99+";
              // }
              // if (this.notificationService.unReadCount > 99) {
              //   this.notificationService.unReadCount = "99+";
              // }

              this.notificationService.updateBadgeCount(res);

              this.events.publish('user:getPushNotificationsCount', true);
            } else {
              // this.load.dismiss();
              console.log('err ', res);
            }
          },
          err => {
            // this.load.dismiss();
            console.log(err);
          }
        );
      }
    }
  }

  presentLogoutAlert(msg, action) {
    this.popupConfig.title = 'Illume';
    this.popupConfig.message = msg;
    this.alertService.showAlert(this.popupConfig).onClose.subscribe((data) => {
      if (action == 1) {
        const userData = {
          cocFlag: 'Y',
          flFlag: 0,
        };
        this.update_user_login_details(userData);
      }
      // this.loginService.logout();
      this.checkInactivityService.doLogout(false);
    })
  }
  update_user_login_details(updateData) {
    // if (this.networkService.getOnlineStatus()) {
    // this.storage.get('currentUser').then(result => {
    const result = JSON.parse(localStorage.getItem('currentUser'));
    const userinfo = {
      usrId: result.id,
      cocFlag: updateData.cocFlag,
      flFlag: updateData.flFlag,
      tenantId: result.tenantId,
    };
    let data;
    this.loginService.update_user_login_details(userinfo).then((res) => {
      if (res.type === true) {
        console.log('res', res);
        if (String(updateData.cocFlag).toLowerCase() === 'n') {
          data = result;
          data.coc_flag = updateData.cocFlag;
          // this.storage.remove('currentUser');
          // this.storage.set('currentUser', data);
          localStorage.setItem('currentUser', JSON.stringify(data));
          this.loginService.goToDashboard();
        }
      }
    });
    // });    } else {
    // console.log('Unable to update user details in offline');
    // }
  }
  ngOnDestroy() {
    // this._destroying$.next(null);
    // this._destroying$.complete();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
