import { Component, OnInit, Input } from '@angular/core';
import { ContentOptionService } from '../../providers/content-option/content-option.service';
import { ToasterService } from '../../providers/toaster/toaster.service';
import { NbDialogRef } from "@nebular/theme";
import { UntypedFormControl } from "@angular/forms";
import { debounceTime } from 'rxjs/operators';
@Component({
  selector: 'ngx-share-content',
  templateUrl: './share-content.component.html',
  styleUrls: ['./share-content.component.scss']
})
export class ShareContentComponent implements OnInit {
  currentUserData: any;

  currentPageNo: any;
  totalPageCount: any;
  working: any;
  public searchControl: UntypedFormControl;
  dataLimit: any;
  searchTerm: any;
  totalContentCount: any;
  searchUserList: any = [];
  @Input() instanceData: any = null;
  notFound: any;
  loading: boolean = true;
  shareFormSubmitting = false;
  selectedUserList = [];
  noDataFoundContainer = {
    showImage: true,
    title: 'Content not available',
    discription: 'Users you are looking for are not available this time, please try after some time.',
  };
  filterMetadata = { count: 0 };
  // users: { name: string, title: string }[] = [
  //   { name: 'Carla Espinosa', title: 'Nurse' },
  //   { name: 'Bob Kelso', title: 'Doctor of Medicine' },
  //   { name: 'Janitor', title: 'Janitor' },
  //   { name: 'Perry Cox', title: 'Doctor of Medicine' },
  //   { name: 'Ben Sullivan', title: 'Carpenter and photographer' },
  // ];
  constructor(public contentOptionService: ContentOptionService,
    private toaster: ToasterService, protected ref: NbDialogRef<ShareContentComponent>) { }

  ngOnInit(): void {
    this.resetValues();
    this.searchControl = new UntypedFormControl();
    this.initFormControl();
    this.getUserData();
  }

  initFormControl() {
    this.searchControl.valueChanges
      .pipe(debounceTime(700))
      .subscribe((search) => {
        // this.setFilteredItems();
        console.log("Search term : ", search);
        this.searchTerm = search;
        if (this.searchTerm.length >= 3 || (this.searchTerm.length == 0 && this.notFound)) {
          //   if (!this.isWorking) {
          this.triggerSearch(search);
          //   }
        } else if (this.searchTerm.length == 0) {
          // this.searchTerm = search;
          // this.triggerSearch(true, (res) => {
          //   console.log('resetSearch completed...', res);
          // });
          this.triggerSearch(search);
        }
      });
  }

  triggerSearch(searchCourseTerm) {
    console.log("searchCourseTerm ", searchCourseTerm);
    if (!this.loading) {
      this.searchUserList = [];
      this.currentPageNo = 1;
      this.loading = true;
      this.getSearchedData((res) => {
        console.log('Search completed...', res);
      });
    }
  }

  getSearchedData(cb) {
    this.fetchSearchData(1, () => {
      console.log('Searched preferences fetched...');
      this.loading = false;
      cb(true);
    });
  }
  clearSearch() {
    this.searchTerm = '';
    // // this.searchOnPage(this.searchTerm);
    this.setValueForSearch("", { emitEvent: true });

    // this.prepareSearchData("");
    // this.goBack();;
  }


  setValueForSearch(value, obj) {
    if (this.searchControl) {
      this.searchControl.setValue(value, obj);
    }
  }

  resetValues() {
    this.currentUserData = {};
    this.notFound = false;
    this.totalPageCount = 3;
    this.working = false;
    this.selectedUserList = [];
    this.dataLimit = 20;
    this.currentPageNo = 1;
    this.searchTerm = '';
    this.totalContentCount = 0;
    this.searchUserList = [];
    this.loading = false;
  }

  getUserData() {
    this.currentUserData = JSON.parse(localStorage.getItem('currentUser'));
    this.loading = true;
    this.fetchSearchData(this.currentPageNo, () => {
      console.log('Done');
      this.moveSelectedElem(
        this.searchUserList,
        this.selectedUserList,
        true
      );
      this.loading = false;

      // this.searchFilterList = this.incomingFilterData;
    });
  }

  /**************** infinite scroll start **************/
  loadMoreData(event) {
    // if (this.networkservice.getOnlineStatus()) {
    // setTimeout(() => {
    if (this.currentPageNo != 1) {
      if (this.currentPageNo > this.totalPageCount) {
        // if (this.infiniteScroll) {
        //   this.infiniteScroll.disabled = true;
        // }
      } else {
        if (!this.working) {
          this.working = true;
          // if (this.networkservice.getOnlineStatus()) {
          this.fetchSearchData(this.currentPageNo, () => {
            this.working = false;
            console.log("Done");
            // this.infiniteScroll.complete();
          });
          // }
        }
      }
    }
    // }, 500);
    // } else {
    //   console.log("Unable to get data at this time");
    // }
  }
  /**************** infinite scroll end **************/
  /*************** get infinite scroll data start ************/
  fetchSearchData(currentPageNo, cb) {
    this.notFound = false;
    this.currentPageNo = currentPageNo;

    const params = {
      searchString: this.searchTerm, // keyword
      // from: this.currentPageNo, // starting point from where you want the document helpful for pagination
      areaId: this.instanceData.areaId,
      instanceId: this.instanceData.instanceId,
      pageLimit: this.dataLimit,
      pageNo: this.currentPageNo,
      empId: this.currentUserData.eid,
      selectedIds: this.selectedUserList.length > 0 ? this.makeSelectedStrReady(this.selectedUserList, 'id',
        ',', false) : '',
    };
    console.log('params', params);
    this.getSharedUserList(params, (result) => {
      if (result.type === true) {
        if (this.currentPageNo !== 1) {
          const nextSearchUserList = result.data;
          // this.totalContentCount = result.totalDocCount.value;
          // this.totalContentCount = result.totalCount;
          // this.totalPageCount = Math.ceil(this.totalContentCount / this.dataLimit);
          this.searchUserList = this.searchUserList.concat(
            nextSearchUserList
          );
        } else {
          this.searchUserList = result.data;
          // this.totalContentCount = result.totalCount;
          this.totalPageCount = result.totalpage;
          // this.totalPageCount = Math.ceil(this.totalContentCount / this.dataLimit);
        }

        if (this.searchUserList.length > 0) {
          this.currentPageNo++;
        } else {
          this.setNotFound();
        }
        cb(true);
      } else {
        cb(false);
        // if (this.searchUserList.length == 0) {
        this.setNotFound();
        // }
      }
    });

  }

  getSharedUserList(params: any, cb) {
    this.contentOptionService.getSharedContentUserList(params).then(
      (res) => {
        // if (res.type === true) {
        console.log(params.pageNo, ' page data list ', res);
        // } else {
        //   console.log('err ', res);
        //   this.searching = false;
        //   this.presentToastMessage('Unable to get results at this time.', 1);
        // }
        cb(res);
      },
      (err) => {
        console.log('err ', err);
        this.loading = false;
        // if (this.searchUserList.length == 0) {
        //   this.notFound = true;
        // }
        this.setNotFound();
        // this.searching = false;
        this.presentToastMessage('Unable to get results at this time.', 'Warning!', 'warning');
      }
    );
  }
  /*************** get infinite scroll data end ************/

  async presentToastMessage(msg, title, type) {
    await this.toaster.prsentToast(msg, title, type, null);
  }

  closePopup(event) {
    this.ref.close(event);
  }

  checkChanged(user) {
    // user['isSelected'] = !user['isSelected'];
    // if (user['isSelected']) {
    //   user['isTemp'] = true
    //   this.addToSelected(user, this.selectedUserList);
    //   this.removeFromSelected(user, this.searchUserList);
    //   // this.removeFromSelected(user, this.selectedUserList);
    // } else {
    //   // this.removeFromSelected(user, this.selectedUserList);
    // }
    if (user['isSelected'] == 0) {

      user['isSelected'] = 1;

      if (!user.isTemp) {

        user.isTemp = true;

        this.addToSelected(user, this.selectedUserList);

        this.removeFromSelected(user, this.searchUserList);

      }

    } else {

      user['isSelected'] = 0;

      // this.removeFromSelected(user, this.selectedUserList);

    }
    console.log('selected Array', this.selectedUserList);
  }

  addToSelected(item, arr) {
    if (arr && arr.length >= 0) {
      arr.push(item);
    } else {
      console.log("Selected array not defined");
    }
  }

  removeFromSelected(item, arr) {
    if (arr.length > 0) {
      this.removeByAttr(arr, "id", item.id);
    } else {
      console.log("Selected array is empty");
    }
  }

  removeByAttr(arr, attr, value) {
    let i = arr.length;
    while (i--) {
      if (
        arr[i] &&
        arr[i].hasOwnProperty(attr) &&
        arguments.length > 2 &&
        arr[i][attr] === value
      ) {
        arr.splice(i, 1);
      }
    }
    return arr;
  }

  makeSelectedStrReady(arr, att, operator, isFilter) {
    if (isFilter) {
      arr = arr.filter((obj) => {
        // return obj.isSelected == 1;
        return obj.isTemp == true && obj.isSelected == 1;
      });
    }
    return Array.prototype.map
      .call(arr, (item) => {
        return item[att];
      })
      .join(operator);
  }

  moveSelectedElem(source, target, rmFlag) {
    const tempSource = [...source];
    tempSource.forEach((elem, index) => {
      if (String(elem.isDefault) === '1') {
        elem.isSelected = 1;
      }
      if (String(elem.isSelected) == '1') {
        target.push(elem);
        if (rmFlag) {
          // source.splice(index, 1);
          for (let i = 0; i < source.length; i++) {
            if (source[i].id == elem.id) {
              source.splice(i, 1);
              break;
            }
          }
        }
      }
    });
  }

  setNotFound() {
    if (this.searchTerm !== '') {
      this.noDataFoundContainer = {
        showImage: true,
        title: 'Searched users are not available',
        discription: 'Searched users are not available this time, please try after some time.',
      };
    } else {
      this.noDataFoundContainer = {
        showImage: true,
        title: 'Users not available',
        discription: 'Users you are looking for are not available this time, please try after some time.',
      };
    }
    if (this.searchUserList.length != 0 || this.filterMetadata.count > 0) {
      this.notFound = false;
    } else {
      this.notFound = true;
    }
  }

  /******************* submit form data start ***************/

  shareContentToUser() {
    // if (this.networkservice.getOnlineStatus()) {
    const shareto = this.makeSelectedStrReady(this.selectedUserList, 'id',
      ',', true);
    if (shareto.length != 0) {
      const params = {
        empId: this.currentUserData.eid,
        areaId: this.instanceData.areaId,
        instanceId: this.instanceData.instanceId,
        shareto: shareto,
      };
      // this.load.presentLoading('Please wait...');
      this.shareFormSubmitting = true;
      this.contentOptionService.shareContent(params).then(
        (res) => {
          // this.load.dismiss();
          if (res.type === true && res.data && res.data.length != 0) {
            console.log("saveshareContent res", res);
            const submitRes = res.data;
            this.closePopup(res.data)
            // this.presentToastMessage(submitRes[0].msg);
            // this.dialogRef.close();

            // this.presentAlertSubmit(submitRes[0].msg).subscribe(() => {
            //   // if (this.currentUserData.lastlogin) {
            //   // window.history.back();
            //   // } else {
            //   this.router.navigateByUrl("/pages/features/home");
            //   // }

            // })
            this.shareFormSubmitting = false;
            this.presentToastMessage(
              submitRes[0].msg, 'Success!', 'success'
            );
          } else {
            this.presentToastMessage(
              "Unable to submit , please try again", 'Warning!', 'warning'
            );
          }
        },
        (err) => {
          // this.load.dismiss();
          console.log(err);
          this.shareFormSubmitting = false;
          this.presentToastMessage(
            "Unable to submit , please try again", 'Warning!', 'warning'
          );
        }
      );
    } else {
      this.presentToastMessage(
        "Please select users from list", 'Warning!', 'warning'
      );
    }
  }

  /******************* submit form data END ***************/


}
