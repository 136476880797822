<div class="dialog-viewer">
    <div class="modal-header">
        <h6 class="modal-title">
            {{ config?.title }}
        </h6>
    </div>
    <div class="modal-body">
        <p>{{ config?.message }}</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" *ngFor="let item of config?.buttons; let isFirst = first" [ngClass]="{ 'ml-2': !isFirst }" (click)="this.ref.close(item)">
      {{ item?.btnText }}
    </button>
    </div>
</div>
