import { Injectable } from '@angular/core';
import { LearningCartService } from '../learning-cart/learning-cart.service';
import { ToasterService } from '../toaster/toaster.service';
import { LearnService } from '../../providers/learn/learn.service';
import { ModuleService } from "../../providers/module/module.service";
import { webApi } from '../../../config';
import { HttpClient } from '@angular/common/http';
import { NbDialogService } from '@nebular/theme';
import { SchedulepopupComponent } from '../../components/schedulepopup/schedulepopup.component';
import { EventsService } from '../../providers/events/events.service';
import { Router } from '@angular/router';
import { HelperService } from '../../cip/providers/helper/helper.service';
// import { ShareContentComponent } from '../../components/share-content/share-content.component';
@Injectable({
  providedIn: 'root'
})
export class ContentOptionService {

  baseUrl: any = webApi.baseUrl;
  get_schedule_data = this.baseUrl + webApi.apiUrl.getscheduleData;
  getContentOptionDetailsUrl = this.baseUrl + webApi.apiUrl.getContentOptionDetails;
  submitInstanceRating = this.baseUrl + webApi.apiUrl.submitInstanceRating;
  submitInstanceTestimonials = this.baseUrl + webApi.apiUrl.submitTestimonials;
  getSharedUserList = this.baseUrl + webApi.apiUrl.getSharedUserList;
  shareContentUrl = this.baseUrl + webApi.apiUrl.shareContent;
  pinContentUrl = this.baseUrl + webApi.apiUrl.pinContent;
  fetchPinnedContentUrl = this.baseUrl + webApi.apiUrl.getPinContent;
  dialogRef = null;

  reEnrolDeEnrolContentUrl = this.baseUrl + webApi.apiUrl.reEnrolDeEnrolContent;

  constructor(private learningCartService: LearningCartService,
    private moduleService: ModuleService,
    private dialogService: NbDialogService,
    private learnService: LearnService,
    private events: EventsService,
    private router: Router,
    public http: HttpClient, private toaster: ToasterService,
    private helperService: HelperService,
  ) {
  }
  /*** START API CALLS ***/
  getCurrentUserData() {
    return JSON.parse(localStorage.getItem('currentUser'))
  }

  public getContentOptionDetails(data: any): Promise<any> {
    return this.http.post(this.getContentOptionDetailsUrl, data)
      .toPromise()
      .then(response => {
        return response = response;
      })
      .catch(this.handleError);
  }

  public getScheduleData(param: any): Promise<any> {
    return this.http
      .post(this.get_schedule_data, param)
      .toPromise()
      .then((response) => {
        return (response = response);
      })
      .catch(this.handleError);
  }

  public submitTestimonials(data: any): Promise<any> {
    return this.http
      .post(this.submitInstanceTestimonials, data)
      .toPromise()
      .then((response) => {
        return (response = response);
      })
      .catch(this.handleError);
  }

  public submitRating(data: any): Promise<any> {
    return this.http
      .post(this.submitInstanceRating, data)
      .toPromise()
      .then((response) => {
        return (response = response);
      })
      .catch(this.handleError);
  }

  public getSharedContentUserList(data: any): Promise<any> {
    return this.http
      .post(this.getSharedUserList, data)
      .toPromise()
      .then((response) => {
        return (response = response);
      })
      .catch(this.handleError);
  }

  public shareContent(data: any): Promise<any> {
    return this.http
      .post(this.shareContentUrl, data)
      .toPromise()
      .then((response) => {
        return (response = response);
      })
      .catch(this.handleError);
  }

  public bookmark(data: any): Promise<any> {
    return this.http
      .post(this.pinContentUrl, data)
      .toPromise()
      .then((response) => {
        return (response = response);
      })
      .catch(this.handleError);
  }

  public fetchPinnedContent(data: any): Promise<any> {
    return this.http
      .post(this.fetchPinnedContentUrl, data)
      .toPromise()
      .then((response) => {
        return (response = response);
      })
      .catch(this.handleError);
  }

  public reEnrolDeEnrolContent(data: any): Promise<any> {
    return this.http
      .post(this.reEnrolDeEnrolContentUrl, data)
      .toPromise()
      .then((response) => {
        return (response = response);
      })
      .catch(this.handleError);
  }

  private handleError(error: Response | any) {
    return Promise.reject(error.message || error);
  }

  /*** END API CALLS ***/


  /*** START FUNCTION CALLS ***/

  addDataToCart(item, cb) {
    // this.currentUserData = ;
    const currentUserData = this.getCurrentUserData();
    var params = {
      areaId: item.areaId,
      instanceId: item.instanceId,
      roleId: currentUserData.roleId,
      // userId: currentUserData.id,
      scheduleRuleId: null,
      scheduleDateTime: null,
      scheduleTime: null,
      isSchedule: 0,
      isActive: 1,
      immediateNotification: 0,
      // scheduleDateTime: type == 'add'?null:this.formatDate(this.dateTime3),
      // isSchedule: type == 'add'?0:1,
      // isActive: 1,
      notificationType: null,
      // id : 1,
      // tenantId : item.tenantId,
    }
    // if (this.currentPageNo === 1) {
    //   this.load.presentLoading('Please wait....');
    // }
    this.learningCartService.addDataToCart(params).then(
      (res) => {
        console.log(res, "res");
        if (res && res['type'] && res['data'] && res['data'].length != 0) {
          this.presentToastMessage(
            res['data'][0]['msg'],
            "Success",
            "success"
          );
          this.events.publish('user:callGetPushNotificationsCount', true);
          cb(res, true)
        } else {
          cb(res, false)
        }

      },
      (err) => {
        // this.load.dismiss();
        cb(null, false)
        console.log("err ", err);
      }
    );
  }

  showPopup(cardData, cb) {
    const currentUserData = this.getCurrentUserData();
    let params = {
      cartId: cardData.cartId,
      tenantId: currentUserData.tenantId,
      userId: currentUserData.id,
      roleId: currentUserData.roleId,
      areaId: cardData.areaId,
      instanceId: cardData.instanceId
    };
    this.getScheduleData(params).then((response: any) => {
      if (response['type']) {
        const model = this.showAlertSchedule(cardData, response['data'][0])
        this.dialogRef = model;
        model.onClose.subscribe(
          (context) => {
            // console.log(context);
            this.dialogRef = null;
            cb(context);
            this.events.publish('user:callGetPushNotificationsCount', true);
          });
      } else {
        console.log('getScheduleData error', response);
        cb(null);
      }
    }).catch((err) => {
      // this.showConatinerSpinner = false
      console.error('Error Fetching Cart List ' + err);
      cb(null);
    });
  }

  showAlertSchedule(data, response) {

    let param = {
      data: data,
      // typeId: this.typeId,
      where: 'outside',
      bindingData: response,
    }
    console.log("config", param);
    return this.dialogService.open(SchedulepopupComponent, {
      context: {
        // config: newVariables,
        config: { ...param },
      },
      closeOnEsc: false,
      closeOnBackdropClick: false,
    });
  }

  updateContentData(content, updatedDetails) {
    // console.log('Clicked content details : ', content, updatedDetails);

    // content.isPinnedShow = updatedDetails.isPinnedShow;
    // content.isScheduledShow = updatedDetails.isScheduledShow;
    // content.isCartShow = updatedDetails.isCartShow;
    // content.isPinned = updatedDetails.isPinned ? updatedDetails.isPinned : 0;
    // content.isScheduled = updatedDetails.isScheduled;
    // content.cartId = updatedDetails.cartId;
    // content.isDeEnrolShow = updatedDetails.isDeEnrolShow;
    // content.isReEnrolShow = updatedDetails.isReEnrolShow;
    // content.buttons = updatedDetails.buttons ?? [];

    content = { ...content, ...updatedDetails };
    return content;
  }

  getContentOptions(content, cb) {
    const currentUserData = this.getCurrentUserData();

    const params = {
      tenantId: currentUserData.tenantId,
      userId: currentUserData.id,
      areaId: content.areaId,
      instanceId: content.instanceId,
      roleId: currentUserData.roleId
    };
    this.getContentOptionDetails(params).then(
      (res) => {
        console.log('getContentOptions res ', res);
        if (res.type) {
          if (res.data && res.data.length > 0) {
            // this.actionSheetButtons = res.data;
            // console.log('')
            // this.makeActionButtonsReady(res.data[0], content, index);

            // old
            // cb(true, res)

            // new
            content = this.updateContentData(content, res.data[0]);
            cb(true, content)
          }
        } else {
          console.log('err ', res);
          cb(false, res)
        }
      },
      (err) => {
        console.log('err ', err);
        cb(false, err)
      }
    );
  }

  checkIfContentIsEnrol(contentData, cb) {
    const currentUserData = this.getCurrentUserData();
    const params = {
      empId: currentUserData.eid,
      areaId: contentData.areaId,
      instanseId: contentData.instanceId
    };
    this.learnService.isContentEnrol(params).then(
      (res) => {
        console.log('checkIfContentIsEnrol res ', res);
        // if (res.type) {
        //   cb(true, res);
        // } else {
        //   cb(false, res);
        // }
        cb(res);
      },
      (err) => {
        console.log('err ', err);
        // this.presentToastMessage('Unable to get results at this time.', 1);
      }
    );
  }

  goToContent(cardData, cb) {
    console.log('Clicked Content details : ', cardData);
    if (cardData.areaId === 3) {
      cb(true);
      // this.router.navigate(['/pages/details/' + cardData.areaId + '/' + cardData.instanceId]);
      this.router.navigate(['/pages/details/' + cardData.areaId + '/' + cardData.instanceId + '/' + 0]);
    } else {
      if (cardData['enrolId']) {
        cb(true);
        // if (cardData.areaId === 34) {
        //   this.moduleService.course = cardData;
        //   this.router.navigate(['/pages/module-activity']);
        // } else {
        //   this.moduleService.course = cardData;
        //   this.router.navigate(['/pages/module-activity']);
        // }
        // this.router.navigate(['/pages/module-activity/' + cardData.areaId + '/' + cardData.instanceId + '/' + 0]);
        this.router.navigate(['/pages/module-activity/' + cardData.areaId + '/' + cardData.instanceId + '/' + 0 + '/' + '0']);
      } else {
        const currentUserData = this.getCurrentUserData();
        if (currentUserData.roleId == 8) {
          this.checkIfContentIsEnrol(cardData, (res) => {
            if (res.type) {
              if (res.data && res.data.length > 0) {
                cb(true);
                if (cardData.areaId === 34 || cardData.areaId === 13) {
                  cardData['enrolId'] = res.data[0]['_enrolId'];
                  // this.router.navigate(['/pages/module-activity/' + cardData.areaId + '/' + cardData.instanceId + '/' + 0]);
                  this.router.navigate(['/pages/module-activity/' + cardData.areaId + '/' + cardData.instanceId + '/' + 0 + '/' + '0']);
                } else {
                  if (res.data[0]._enrolcnt <= 0) {
                    // this.router.navigate(['/pages/details/' + cardData.areaId + '/' + cardData.instanceId]);
                    this.router.navigate(['/pages/details/' + cardData.areaId + '/' + cardData.instanceId + '/' + 0]);
                  } else {
                    cardData['enrolId'] = res.data[0]['_enrolId'];
                    // this.router.navigate(['/pages/module-activity/' + cardData.areaId + '/' + cardData.instanceId + '/' + 0]);
                    this.router.navigate(['/pages/module-activity/' + cardData.areaId + '/' + cardData.instanceId + '/' + 0 + '/' + '0']);
                  }
                }
              } else {
                cb(false);
                console.log('invalid response:', res);
                this.presentToastMessage(res.data[0].msg, 'Warning', 'warning');
              }
            } else {
              cb(false);
              console.log('invalid response:', res);
              const msg = res[0].msg ? res[0].msg : res.data[0].msg;
              this.presentToastMessage(msg, 'Warning', 'warning');
            }
          });
        } else if (currentUserData.roleId == 7) {
          cb(true);
          // trainer - delivery tab
          this.helperService.goToDetails(cardData, 1);
        } else {
          console.log('Role not matched...');
          cb(false);
        }
      }

    }
  }

  async presentToastMessage(msg, title, type) {
    await this.toaster.prsentToast(msg, title, type, null);
  }

  ratingChanged(contentData, cb) {
    const userData = JSON.parse(localStorage.getItem('currentUser'));
    const params = {
      rateId: contentData.rating['rateId'],
      rate: contentData.rating.rate,
      outof: contentData.rating.out_of,
      roleId: userData.roleId,
      entityId: userData.referenceId,
      areaId: contentData['areaId'] ? contentData.areaId : null,
      instanceId: contentData['instanceId'] ? contentData['instanceId'] : null,
    };
    this.submitRating(params).then((response) => {
      console.log('ratingChanged response', response);
      if (response && response['type'] && response['data'] && Array.isArray(response['data'])) {
        const data = response['data'][0];
        contentData.rating['rateId'] = data.lastinstupdateId;
        if (data.avgRating && data.avgRating.list && data.avgRating.list.length > 0) {
          contentData.avgRating = data.avgRating;
        }
        // else {
        //   contentData.avgRating = {
        //     list: []
        //   };
        // }
        cb(true, contentData);
      } else {
        cb(false, contentData);
        // this.presentToastMessage('Unable to submit data', 1);
        this.presentToastMessage('Unable to submit data', 'Warning', 'warning');
      }
    }).catch((error) => {
      cb(false, contentData);
      console.log('ratingChanged error', error);
      // this.presentToastMessage('Unable to submit data', 1);
      this.presentToastMessage('Unable to submit data', 'Warning', 'warning');
    });
  }

  sendTestimonial(contentData, param, cb) {
    // const userData = JSON.parse(localStorage.getItem('currentUser'));
    const params = {
      // rateId: contentData.rating['rateId'],
      // roleId: userData.roleId,
      areaId: contentData['areaId'] ? contentData.areaId : null,
      instanceId: contentData['instanceId'] ? contentData['instanceId'] : null,
      // userId: userData.id,
      userTestimonial: param.userInput,
      contentManagerId: param.managerId,
      // tenantId: userData.tenantId
      // areaId ,instanceId,userId,userTestimonial,contentManagerId, tenantId

    };
    this.submitTestimonials(params).then((response) => {
      console.log('submitTestimonials response', response);
      if (response && response['type'] && response['data']) {
        const data = response['data'][0];
        if (data['flag'] == 1) {
          cb(true, contentData);
          this.presentToastMessage(data['msg'], 'Success', 'success');
        } else {
          cb(true, contentData);
          this.presentToastMessage(data['msg'], 'Warning', 'warning');
        }
        // contentData.rating['rateId'] = data.lastinstupdateId;

      } else {
        cb(false, contentData);
        // this.presentToastMessage('Unable to submit data', 1);
        this.presentToastMessage('Unable to submit data', 'Warning', 'warning');
      }
    }).catch((error) => {
      cb(false, contentData);
      console.log('submitTestimonials error', error);
      // this.presentToastMessage('Unable to submit data', 1);
      this.presentToastMessage('Unable to submit data', 'Warning', 'warning');
    });
  }

  bookmarkContent(item, cb) {
    // this.currentUserData = ;
    const currentUserData = this.getCurrentUserData();
    var params = {
      empId: currentUserData.eid,
      sts: item.isPinned == 1 ? 0 : 1,
      instanseId: item.instanceId,
      areaId: item.areaId,
      instanceId: item.instanceId,
    }
    // if (this.currentPageNo === 1) {
    //   this.load.presentLoading('Please wait....');
    // }
    this.bookmark(params).then(
      (res) => {
        console.log(res, "res");
        if (res && res['type'] && res['data'] && res['data'].length != 0) {
          this.presentToastMessage(
            res['data'][0]['msg'],
            "Success",
            "success"
          );
          // this.events.publish('user:callGetPushNotificationsCount', true);
          cb(res, true)
        } else {
          cb(res, false)
        }

      },
      (err) => {
        // this.load.dismiss();
        cb(null, false)
        console.log("err ", err);
      }
    );
  }


  // openSharedPopup(contentDetails) {
  //   // let param = {
  //   //   data: contentDetails,
  //   //   // typeId: this.typeId,
  //   //   // where: 'outside',
  //   //   // bindingData: response,
  //   // }
  //   console.log("contentDetails", contentDetails);
  //   return this.dialogService.open(ShareContentComponent, {
  //     context: {
  //       // config: newVariables,
  //       instanceData: { ...contentDetails },
  //     },
  //     closeOnEsc: false,
  //     closeOnBackdropClick: false,
  //   });
  // }

  makeDisplaySettingReady(settingsArr, content) {
    const actionButtons = [];
    const actionButtonsStatus = {
      'ShareConetentEnabled': false,
      'TestimonialEnabled': false,
      'GiveRatingEnabled': false,
      'CommentsEnabled': false,
      'DownloadContentEnabled': false,
      'likeEnabled': false,
      'dislikeEnabled': false,
    };
    for (let i = 0; i < settingsArr.length; i++) {
      const setting = settingsArr[i];
      if (setting.value == "1" && setting.key !== "DisplayRatingOnCardEnabled") {
        if (setting.key == "ShareConetentEnabled") {
          setting.name = 'Share';
          // setting.icon = 'assets/icon/svg/details/actions/share-fill.svg';
          setting.icon = 'assets/icon-svg/Share_icon.svg',
            setting.elementId = null;
          setting.action = 'share';
          actionButtonsStatus.ShareConetentEnabled = true;
        } else if (setting.key == "TestimonialEnabled") {
          setting.name = 'Send testimonial';
          // setting.icon = 'assets/icon/svg/details/actions/send-fill.svg';
          setting.icon = 'assets/icon-svg/Send_icon.svg',
            setting.elementId = 'sendTestimonial';
          setting.action = 'send';
          actionButtonsStatus.TestimonialEnabled = true;
        } else if (setting.key == "GiveRatingEnabled") {
          setting.name = 'Rating';
          // setting.icon = 'assets/icon/svg/details/actions/star-half-fill.svg';
          setting.icon = 'assets/icon-svg/Rating_icon.svg',
            setting.elementId = 'leaveRating';
          setting.action = 'rating';
          actionButtonsStatus.GiveRatingEnabled = true;
        } else if (setting.key == "CommentsEnabled") {
          setting.name = 'Comment';
          setting.icon = '';
          setting.elementId = 'comment';
          setting.action = 'comment';
          actionButtonsStatus.CommentsEnabled = true;
        } else if (setting.key == "DownloadContentEnabled") {
          setting.name = 'Download';
          // setting.icon = 'assets/icon/svg/details/actions/Download_fill_icon.svg';
          setting.icon = 'assets/icon-svg/Download_fill_icon.svg',
            setting.elementId = 'download';
          setting.action = 'download';
          actionButtonsStatus.DownloadContentEnabled = true;
        } else if (setting.key == "likeEnabled") {
          // setting.name = content.likeCount || '0';
          setting.name = this.formatNum(content.likeCount);
          // setting.icon = 'assets/icon/svg/Thumbs_up_icon.svg';
          setting.icon = 'assets/icon-svg/Thumbs_up_icon.svg',
            setting.elementId = 'like';
          setting.action = 'like';
          actionButtonsStatus.likeEnabled = true;
        } else if (setting.key == "dislikeEnabled") {
          // setting.name = content.dislikeCount || '0';
          setting.name = this.formatNum(content.dislikeCount);
          // setting.icon = 'assets/icon/svg/Thumbs_down_icon.svg';
          setting.icon = 'assets/icon-svg/Thumbs_down_icon.svg',
            setting.elementId = 'dislike';
          setting.action = 'dislike';
          actionButtonsStatus.dislikeEnabled = true;
        }
        // else if (setting.key == "DisplayRatingOnCardEnabled") {
        //   setting.name = '';
        //   setting.icon = '';
        //   setting.elementId = '';
        // }
        if (setting.key != 'CommentsEnabled' && setting.key != 'DownloadContentEnabled') {
          actionButtons.push(setting);
        }
      }
    }
    // return actionButtons;
    const data = {
      buttons: actionButtons,
      buttonsStatus: actionButtonsStatus,
    };
    return data;
  }

  updateCardConfig(cardConfig) {
    const cardDisplaySetting = localStorage.getItem('displaySettings') ? JSON.parse(localStorage.getItem('displaySettings')) : null;
    if (cardDisplaySetting) {
      console.log('updateCardConfig cardDisplaySetting : ', cardDisplaySetting);
      cardConfig.showStars = cardDisplaySetting['DisplayRatingOnCardEnabled'] == '1' ? true : false;
      cardConfig.displayRatingEnabled = cardDisplaySetting['DisplayRatingOnCardEnabled'] == '1' ? true : false;
      cardConfig.showShareCount = cardDisplaySetting['ShareConetentcountOnCardEnabled'] == '1' ? true : false;
      cardConfig.CommentCountEnabled = cardDisplaySetting['CommentCountEnabled'] == '1' ? true : false;
      cardConfig.displayCommentEnabled = cardDisplaySetting['CommentCountEnabled'] == '1' ? true : false;
      cardConfig.displayRaiseLREnabled = cardDisplaySetting['LearningRequestEnabled'] == '1' ? true : false;
      cardConfig.languageEnabled = cardDisplaySetting['LanguageEnabled'] == '1' ? true : false;
      cardConfig.likeEnabled = cardDisplaySetting['likeEnabled'] == '1' ? true : false;
      cardConfig.dislikeEnabled = cardDisplaySetting['dislikeEnabled'] == '1' ? true : false;
    }
    return cardConfig;
  }

  makeButtonsReady(courseData) {
    const options = [
      {
        text: courseData.isPinned == 0 ? 'Pin' : 'Pinned',
        cssClass: courseData.isPinned == 0 ? 'inactive' : 'active',
        icon: '../../../assets/icon-svg/course-card/pin.svg',
        event: {
          id: 'bookmark',
          value: courseData,
        },
        isVisible: courseData.isPinnedShow == 0 ? false : true
        // isVisible: courseData.isPinnedShow == 1 ? true : false
      },
      {
        text: courseData.isScheduled == 0 ? 'Schedule' : 'Reschedule',
        cssClass: courseData.isScheduled == 0 ? 'inactive' : 'active',
        icon: '../../../assets/icon-svg/course-card/clock.svg',
        event: {
          id: 'schedule',
          value: courseData,
        },
        isVisible: courseData.isScheduledShow == 0 ? false : true
        // isVisible: courseData.isScheduledShow == 1 ? true : false
      },
      {
        text: courseData.cartId == 0 ? 'Add to cart' : 'Go to cart',
        cssClass: courseData.cartId == 0 ? 'inactive' : 'active',
        icon: '../../../assets/icon-svg/course-card/cart.svg',
        event: {
          id: 'addToCart',
          value: courseData,
        },
        isVisible: courseData.isCartShow == 0 ? false : true
        // isVisible: courseData.isCartShow == 1 ? true : false
      },
      {
        text: 'Generate PPR',
        cssClass: courseData.isPPREnable == 0 ? 'inactive' : 'active',
        icon: 'assets/icon-svg/course-card/pin.svg',
        event: {
          id: 'generateReportAndPPR',
          value: courseData,
        },
        // isVisible: courseData.isPPRShow == 0 ? false : true
        isVisible: courseData.isPPRShow == 1 ? true : false
      },
      {
        text: 'Re-enroll',
        cssClass: 'inactive',
        icon: 'assets/icon/svg/course-sheet/re-enroll.svg',
        event: {
          id: 're-enrol',
          value: 1,
        },
        isVisible: courseData.isReEnrolShow == 0 ? false : true
      },
      {
        text: 'De-enroll',
        cssClass: 'inactive',
        icon: 'assets/icon/svg/course-sheet/de-enroll.svg',
        event: {
          id: 'de-enrol',
          value: 0,
        },
        isVisible: courseData.isDeEnrolShow == 0 ? false : true
      }
    ];
    return options;
  }

  closePopup() {
    if (this.dialogRef) {
      this.dialogRef.close();
      this.dialogRef = null;
    }
  }

  reEnrolDeEnrol(content, extraData, cb) {
    const params = {
      "areaId": content.areaId,
      "instanceId": content.instanceId,
      "isActive": extraData.isActive,
      "comment": extraData.comment ?? null
    };
    // this.load.presentLoading('Please wait...');
    this.reEnrolDeEnrolContent(params).then((res) => {
      if (res && res.type) {
        cb(true);
        // this.presentToastMessage(res.data, 0);
        this.presentToastMessage(
          res.data,
          "Success",
          "success"
        );
      } else {
        // this.presentToastMessage('Unable to process at this time.', 1);
        this.presentToastMessage('Unable to process at this time.', 'Warning', 'warning');
        cb(false);
      }
    }).catch((err: any) => {
      console.log('error ' + err);
      // this.presentToastMessage('Unable to process at this time.', 1);
      this.presentToastMessage('Unable to process at this time.', 'Warning', 'warning');
      cb(null);
    });
  }

  getDeEnrolReasonPopUpData(content) {
    const popoverData = {
      type: 'reason',
      placeHolder: 'Please add your reason for de-enrollment',
      errorMsg: 'Please enter valid reason',
      validation: {
        required: true,
        minLength: 20,
        maxLength: 100,
        errorMsgRequired: 'De-enrolling reason is required',
        errorMsgMinLength: 'Min 20 characters required.',
        errormaxLength: 'Max 100 characters are allowed.'
      },
      extraParams: content,
    };
    return popoverData;
  }

  /************ like-dislike content start ***********/
  addRemoveContentFeedback(courseData, feedbackType, cb) {
    const param = {
      instanceId: courseData.instanceId,
      type: feedbackType,
      areaId: courseData.areaId,
    };
    this.learnService.addRemoveContentFeedback(param).then(
      res => {
        // this.load.dismiss();
        if (res.type === true) {
          console.log('addRemoveContentFeedback res ', res);
          cb(res);
        } else {
          console.log('addRemoveContentFeedback err ', res);
          // this.presentToastMessage('Unable to update at this time.', 1);
          this.presentToastMessage('Unable to update at this time.', 'Warning', 'warning');
          cb(null);
        }
      },
      err => {
        // this.load.dismiss();
        console.log('addRemoveContentFeedback err : ', err);
        // this.presentToastMessage('Unable to update at this time.', 1);
        this.presentToastMessage('Unable to update at this time.', 'Warning', 'warning');
        cb(null);
      }
    );
  }

  getUpdatedLikeDislikeStatusAndCount(currentCount, feedbackType, feedbackStatus, courseData) {
    let likeDislikeCount = currentCount;
    let likeCount = courseData.likeCount;
    let dislikeCount = courseData.dislikeCount;
    if (feedbackType === 0) {
      courseData.isLike = 0;
      courseData.isDislike = 0;
      likeDislikeCount = likeDislikeCount - 1;
    } else {
      this.resetLikeDislikeCount(feedbackType, feedbackStatus, courseData, likeCount, dislikeCount);
      likeDislikeCount = likeDislikeCount + 1;
    }

    // Format like/dislike count
    courseData['likeCountFormatted'] = this.formatNum(courseData.likeCount);
    courseData['dislikeCountFormatted'] = this.formatNum(courseData.dislikeCount);

    return { tempLikeDislikeCount: likeDislikeCount, tempCourseData: courseData }
  }

  resetLikeDislikeCount(feedbackType, feedbackStatus, courseData, likeCount, dislikeCount) {
    if (feedbackType === 1) {
      if (feedbackStatus === 0) {
        this.resetLikeCount(courseData, likeCount);
      } else {
        this.resetDislikeCount(courseData, dislikeCount);
        courseData.isLike = 1;
        courseData.likeCount = likeCount + 1;
      }
    } else if (feedbackType === 2) {
      if (feedbackStatus === 0) {
        this.resetDislikeCount(courseData, dislikeCount);
      } else {
        this.resetLikeCount(courseData, likeCount);
        courseData.isDislike = 1;
        courseData.dislikeCount = dislikeCount + 1;
      }
    }
  }

  resetLikeCount(courseData, likeCount) {
    if (courseData.isLike == 1) {
      courseData.isLike = 0;
      courseData.likeCount = likeCount - 1;
    }
  }

  resetDislikeCount(courseData, dislikeCount) {
    if (courseData.isDislike == 1) {
      courseData.isDislike = 0;
      courseData.dislikeCount = dislikeCount - 1;
    }
  }

  formatNum(num = 0, digits = 2) {
    num = Number(num);
    // return String(num);

    // Approach 1
    if (num < 1e3) return num;
    if (num >= 1e3 && num < 1e6)
      return +(num / 1e3).toFixed(1) + "K";
    if (num >= 1e6 && num < 1e9)
      return +(num / 1e6).toFixed(1) + "M";
    if (num >= 1e9 && num < 1e12)
      return +(num / 1e9).toFixed(1) + "B";
    if (num >= 1e12) return +(num / 1e12).toFixed(1) + "T";

    // Approach 2
    // // Thousands, millions, billions etc..
    // let s = ["", "k", "m", "b", "t"];

    // // Dividing the value by 3.
    // let sNum = Math.floor(("" + num).length / 3);

    // // Calculating the precised value.
    // let sVal: any = parseFloat(
    //   (sNum != 0
    //     ? num / Math.pow(1000, sNum)
    //     : num
    //   ).toPrecision(2)
    // );

    // if (sVal % 1 != 0) {
    //   sVal = sVal.toFixed(1);
    // }

    // // Appending the letter to precised val.
    // return sVal + s[sNum];

    // Approach 3
    // let units = ['', 'k', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'];
    // let floor = Math.floor(Math.abs(num).toString().length / 3);
    // let value = +(num / Math.pow(1000, floor));
    // let formattedNum = value.toFixed(value > 1 ? digits : 2);
    // formattedNum += floor ? units[floor - 1] : units[floor];
    // return formattedNum;
  }
  /************ like-dislike content end ***********/

  /*** END FUNCTION CALLS ***/
}
