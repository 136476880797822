import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation, OnChanges, SimpleChanges } from '@angular/core';
import { popupConfig } from '../../interfaces/popup-config.model';
@Component({
  selector: 'ngx-certificates',
  templateUrl: './certificates.component.html',
  styleUrls: ['./certificates.component.scss']
})
export class CertificatesComponent implements OnInit {
  @Input('data') data: any;
  @Output() getEvent = new EventEmitter();
  item: any;
  popupClick = true;

  constructor() { }

  ngOnInit(): void {
    console.log('data ' + this.data);
  }

  ngOnChanges(): void {
    if(this.data !== undefined) {
      this.item = this.data;
      console.log('item ' + this.item);
    }
  }

  fireEvent(event: any, data: any, ev: any) {
    const dataOutput = {
      type : event,
      cardData: data,
      event: ev
    };
    this.getEvent.emit(dataOutput);
  };

}
