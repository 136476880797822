import { AttendanceInductionComponent } from './attendance-induction/attendance-induction.component';
import { BannerDetailsComponent } from './banner-details/banner-details.component';
import { CustomModelComponent } from './custom-model/custom-model.component';
import { EventCardComponent } from './events-calendar/event-card/event-card.component';
import { EventsCalendarNewComponent } from './events-calendar/events-calendar-new/events-calendar-new.component';
import { ExcelUploadAndPreviewComponent } from './excel-upload-and-preview/excel-upload-and-preview.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { FilterTableComponent } from './filter-table/filter-table.component';
import { FilterComponent } from './filter/filter.component';
import { SurveyCardComponent } from './survey-card/survey-card.component';

export const components: any[] = [
  CustomModelComponent,
  FilterTableComponent,
  FilterComponent,
  AttendanceInductionComponent,
  EventsCalendarNewComponent,
  EventCardComponent,
  BannerDetailsComponent,
  FileUploadComponent,
  ExcelUploadAndPreviewComponent,
  SurveyCardComponent,
];

export * from './custom-model/custom-model.component';
export * from './filter-table/filter-table.component';
export * from './filter/filter.component';
export * from './attendance-induction/attendance-induction.component';
export * from './events-calendar/events-calendar-new/events-calendar-new.component';
export * from './excel-upload-and-preview/excel-upload-and-preview.component';
export * from './events-calendar/event-card/event-card.component';

export * from './banner-details/banner-details.component';
export * from './file-upload/file-upload.component';
export * from './survey-card/survey-card.component';
