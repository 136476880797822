<div class="filter learn-rs__filter border" [ngClass]="{'learn-rs__filter--open': openFilter}">
    <div class="d-flex align-items-center justify-content-between border-bottom px-3 py-2">
        <h5>Filter</h5><span class="cursor-p filter__clear" (click)="clearAll()">Clear All</span>
    </div>
    <div *ngIf="filterList.length > 0">
    <div class="filter__inner border-bottom p-3" *ngFor="let item of filterList">
        <span class="d-flex align-items-center justify-content-between mb-3">
            <h5>{{item[0].title}}</h5>
            <img src="assets/icon-svg/my-learning/chevron-down.svg" alt="arrow-down">
        </span>
        <span class="filter__inner--search border d-flex w-100 mb-2">
            <img src="assets/icon-svg/my-learning/search.svg" alt="search">
            <input class="border-0 w-100 pl-2" type="text" placeholder="Search {{item[0].title}}">
        </span>
        <div class="filter__inner--content pl-1">
            <div class="pb-2" *ngFor="let subitem of item; let i = index;" (click)="changeCheckbox(subitem, i)">
                <div class="checkbox d-inline-block align-top">
                    <span class="inner-name text-capitalize">{{subitem?.itemName}}</span>
                    <input type="checkbox" [checked]="subitem?.selected">
                    <span class="checkmark"></span>
                </div>
            </div>
        </div>
    </div>
    </div>
</div>