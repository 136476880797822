import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { InitService } from '../providers/init/init.service';
import { LoginService } from '../providers/login/login.service';
import { ThemeService } from "../providers/theme/theme.service";
@Injectable({
  providedIn: 'root'
})
export class AppGuard implements CanActivate {
  constructor(
    private router: Router,
    public authenticationService: LoginService,
    private appInitService: InitService,
    private themeService: ThemeService,
  ) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.initAppForUser(state, resolve);
      //  resolve(true);
    });
  }
  initAppForUser(state, resolve) {
    this.getAppLoadData((flag, data) => {
      // setting theme
      if (data.response['theme']) {
        const themeData = data.response['theme'];
        localStorage.setItem('theme', JSON.stringify(themeData));
        this.themeService.changeTheme();
      }
      const apiRes = data.response;
      console.log('app config res : ', apiRes.appConfig);
      if (apiRes.appConfig && apiRes.appConfig.length > 0) {
        const tempConfig = apiRes.appConfig[0];
        // this.setAppConfig(tempConfig);
        if (tempConfig.maintenance == 1) {
          this.checkForMaintenance(tempConfig, state, resolve);
        } else {
          this.checkForDomain(flag, data, resolve, state);
        }
      } else {
        resolve(true);
      }
    });
  }

  checkForMaintenance(tempConfig, state, resolve) {
    const userLoggedIn = JSON.parse(localStorage.getItem('currentUser'));
    const ssoTenant = JSON.parse(localStorage.getItem('tenantinfo'));
    const ssoAccess = localStorage.getItem('ssoaccess');
    if (tempConfig.allowLogin == 1) {
      if (state.url == '/auth/login/new') {
        resolve(true);
      } else if (userLoggedIn) {
        // resolve(true);
        if (state.url == '/') {
          console.log('App guard called...');
          this.authenticationService.goToDashboard();
          resolve(false);
        } else {
          resolve(true);
        }
      } else {
        this.router.navigate(['/misc/maintenance']);
        resolve(false);
      }
    } else {
      this.router.navigate(['/misc/maintenance']);
      resolve(false);
    }
  }

  checkIfRouteIsValid(route, role) {
    const n = route.search(role);
    if (n != -1) {
      return true;
    } else {
      return false;
    }
  }

  checkForDomain(flag, data, resolve, state) {
    if (!flag) {
      console.log('Invalid domain');
      if (data) {
        let found = false;
        if (data.params.subDomain) {
          const arr1 = data.response.exceptions ? data.response.exceptions.split(',') : null;
          if (arr1) {
            // found = arr1.includes(data.params.subDomain);
            found = arr1.some(x => x.toLowerCase() == String(data.params.subDomain).toLowerCase())
          }
        } else {
          console.log('Subdomain not found...');
        }
        if (data.response && data.response.redirectionURL['link'] && !found) {
          window.location.href = data.response.redirectionURL['link'];
        } else {
          const tenantInfo = this.getDefaultTenant(data);
          this.authenticationService.setDomainBasedTenant(tenantInfo);
        }
        resolve(true);
      } else {
        console.log('Invalid data');
        resolve(false);
      }
    } else {
      // old
      // this.authenticationService.setDomainBasedTenant(data.response.data);
      // new
      if (data.response) {
        const tenant = data.response.data ?? null;
        if (tenant && data.response.isSaas) {
          const currentUser = localStorage.getItem('currentUser');
          if (currentUser || this.checkIfRouteIsValid(state.url, '/auth/login/')) {
            this.authenticationService.setDomainBasedTenant(tenant);
          } else {
            window.location.href = tenant.logOutUrl;
          }
        } else {
          this.authenticationService.setDomainBasedTenant(tenant);
        }
      } else {
        // const tenantInfo = this.getDefaultTenant(data);
        // this.authenticationService.setDomainBasedTenant(tenantInfo);
        console.log('Unable to get response : ', data);
      }
      resolve(true);
    }
  }

  setAppConfig(appConfig) {
    localStorage.setItem('appConfig', JSON.stringify(appConfig));
  }

  getDefaultTenant(data) {
    const demoData = {
      organizationType: 1,
      subEndDate: null,
      subStartDate: null,
      tenantCode: data.params.subDomain,
      tenantId: null,
      tenantName: data.params.subDomain,
      visible: 1,
      logOutUrl: ''
      // logOutUrl: 'https://www.edgelearning.co.in'
    };
    return demoData;
  }
  /********************** app load handling start ********************/
  getAppLoadData(cb) {
    const hostName = window.location.hostname;
    const callBack = cb;
    let tempSubDomain = '';
    if (hostName !== 'localhost') {
      const splited = hostName.split('.');
      if (splited && splited.length !== 0) {
        tempSubDomain = splited[0];
      }
    } else {
      tempSubDomain = 'localhost';
    }
    const param = {
      subDomain: tempSubDomain,
      requestFrom: 3,
    };
    this.appInitService.getAppLoadData(param).then((res) => {
      const data = {
        params: param,
        response: res,
      };
      if (res['type'] == false) {
        cb(false, data);
      } else if (res['type'] === true) {
        cb(true, data);
      }
    })
      .catch((err) => {
        console.log('getAppLoadData err : ', err);
        this.getAppLoadData(callBack);
      });
  }
  /********************** app load handling end ********************/
}
