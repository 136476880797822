import { Component, OnInit, Input } from "@angular/core";
import { NbDialogRef } from "@nebular/theme";
@Component({
  selector: "ngx-dialog-viewer",
  templateUrl: "./dialog-viewer.component.html",
  styleUrls: ["./dialog-viewer.component.scss"],
})
export class DialogViewerComponent implements OnInit {
  @Input() config: any = null;
  constructor(protected ref: NbDialogRef<DialogViewerComponent>) { }

  ngOnInit(): void { }
}
