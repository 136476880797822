<div class="modal-dialog dialog modal-dialog-centered" role="document" nbSpinnerStatus="primary" [nbSpinner]="shareFormSubmitting">
    <div class="modal-content content" [nbSpinner]="loading && selectedUserList?.length !=0 && searchUserList?.length !=0" nbSpinnerStatus="primary">
        <div class="modal-header header">
            <p>Share this course</p>
            <button type="button" (click)="closePopup(null)" class="close" data-dismiss="modal" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
        </div>
        <div class="modal-body py-0 body">
            <div class="search-bar-container">
                <!-- (keyup.enter)="prepareSearchData($event.target.value)"  -->
                <input type="text" class="search-bar-container-search mb-1" placeholder="Add Employee" [formControl]="searchControl" />
                <span class="search-bar-container-cancel" *ngIf="searchTerm !== ''" (click)="clearSearch()">
              <nb-icon class="cancel-icon" icon="close-outline" class="mr-2"></nb-icon>
            </span>
            </div>

            <div nbInfiniteList [threshold]="250" class="search-container" (bottomThreshold)="loadMoreData($event)">
                <div *ngFor="let user of selectedUserList| advFilterPipe:searchTerm:['ecn', 'fullname']:filterMetadata;">
                    <div class="borderdiv d-flex justify-content-between align-items-center">
                        <nb-user size="large" [picture]="user?.picture_url" [name]="user.fullname" [title]="'Employee Id :' + user?.ecn">
                        </nb-user>
                        <div *ngIf="user.isTemp == true" class="checkbox d-inline-block align-top pb-2" (click)="checkChanged(user)">
                            <!-- <span class="checkbox--name text-capitalize">{{inner?.name}}</span> -->
                            <!-- (ngModelChange)="" [(ngModel)]="inner.check"  -->
                            <input type="checkbox" [checked]="user?.isSelected">
                            <span class="checkmark"></span>
                        </div>
                        <div *ngIf="user.isSelected && user.isTemp != true">
                            <!-- <p class="mb-0">Shared</p> -->
                            <nb-icon icon="done-all-outline" class="mr-3 all-select-icon "></nb-icon>
                        </div>
                    </div>
                </div>
                <div *ngFor="let user of searchUserList">
                    <div class="borderdiv d-flex justify-content-between align-items-center">
                        <nb-user size="large" [picture]="user?.picture_url" [name]="user.fullname" [title]="'Employee Id :' + user?.ecn">
                        </nb-user>
                        <div class="checkbox d-inline-block align-top pb-2" (click)="checkChanged(user)">
                            <!-- <span class="checkbox--name text-capitalize">{{inner?.name}}</span> -->
                            <!-- (ngModelChange)="" [(ngModel)]="inner.check"  -->
                            <input type="checkbox" [checked]="user?.isSelected">
                            <span class="checkmark"></span>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="working || loading">
                    <div class="search-spinner-container" [nbSpinner]="true" nbSpinnerStatus="primary">

                    </div>
                </ng-container>
                <ng-container *ngIf="!loading && !working && notFound">
                    <div class="no-data-found-container-search">
                        <!-- noDataFoundContainer?.title -->
                        <ngx-no-data [showImage]="noDataFoundContainer?.showImage" [title]="" [discription]="noDataFoundContainer?.discription"></ngx-no-data>
                    </div>
                </ng-container>
            </div>

            <!-- </nb-list>
              </nb-card>
        </nb-body> -->

        </div>
        <div class=" modal-footer footer footer-btn d-flex justify-content-end">
            <!-- <button type="button" class="customBtn"><nb-icon icon="link-outline"></nb-icon>
                    Copy Link</button> -->
            <div class="d-flex">
                <button type="button" class="customBtnWhite" (click)="closePopup(null)">Cancel</button>
                <button type="button" class="commonBtn" [disabled]="shareFormSubmitting" (click)="shareContentToUser()">Share</button>
            </div>
        </div>
    </div>
</div>