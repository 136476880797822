<div class="p-3">
    <p>Coach Name - Coach one</p>
    <ngx-table [config]="config" [tableData]="callList" [pagination]="true" [label]="labels"></ngx-table>
</div>

<!-- <ng-container>
    <div class="d-flex align-items-center justify-content-center flex-column h-100">
        <h3 class="pb-1">Demo Coach</h3>
        <p>22-07-2022 10:00:00 To 23-02-2022 10:00:00</p>
        <button class="commonBtn-lg start-btn cursor-p">Go to Meeting</button>
    </div>
</ng-container> -->