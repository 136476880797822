import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { ContentOptionService } from '../../providers/content-option/content-option.service';
import { ContentCard } from '../../interfaces/content-card.enum';
import { SharedService } from '../../modules/shared/providers/shared.service';
// import { Subscription } from 'rxjs';
// import { NbMenuService } from '@nebular/theme';

@Component({
  selector: 'ngx-content-search-card',
  templateUrl: './content-search-card.component.html',
  styleUrls: ['./content-search-card.component.scss']
})
export class ContentSearchCardComponent implements OnInit {
  @Input() cardsData: any;
  @Input() config: ContentCard = {
    image: '',
    title: '',
    author: '',
    rating: '',
    description: '',
    showPinned: false,
    instanceType: '',
    instanceTypeIcon: 'instanceTypeIcon',
    showStars: true,
    // moreOptionArray: 'moreOptions',
    // insideSpinner: false,
    scheduleFlag: 'isScheduled',
    // goToCartFlag: 'cart_id',
    goToCartFlag: 'cartId',
    numberOfStars: 'outof',
    languageEnabled: true,
    languageName: 'language_name'
  };
  // @Input() moreOptionArray = [
  //   //   {
  //   //   icon: 'pin-outline',
  //   //   text: 'Pin',
  //   //   action: 'bookmark'
  //   // },
  //   {
  //     icon: 'clock-outline',
  //     text: 'Schedule',
  //     action: 'schedule',
  //   },
  //   {
  //     icon: 'shopping-cart-outline',
  //     text: 'Add To Cart',
  //     action: 'addToCart'
  //   }];
  show = false;
  @Output() getEvent = new EventEmitter();
  buttons = [];
  listItems = [{ title: 'schedule' }, { title: 'pin' }, { title: 'Add to cart' }];
  // recordsSub: Subscription;
  // private menuService: NbMenuService,

  constructor(
    private contentOptionService: ContentOptionService,
    private sharedService: SharedService
  ) { }

  ngOnInit(): void {
    // console.log(this.cardsData)
    // console.log(this.config)
    // this.recordsSub = this.menuService.onItemClick().subscribe((event) => {
    //   this.onItemSelection(event.item);
    // })
  }

  // onItemSelection(title) {
  //   console.log(title)
  //   if (title === 'Log out') {
  //     // Do something on Log out
  //     console.log('Log out Clicked ')
  //   } else if (title === 'Profile') {
  //     // Do something on Profile
  //     console.log('Profile Clicked ')
  //   }
  // }

  ngOnDestroy() {
    // this.recordsSub.unsubscribe();
  }

  mouseEnter(cardsData) {
    this.show = true;
    // const dataOutput = {
    //   type: 'getContentOptions',
    //   cardData: cardsData,
    //   // liked : isLiked,
    // };
    this.fireEvent('getContentOptions', cardsData);
  }

  mouseLeave(cardsData) {
    this.show = false;
  }

  bindBackgroundImage(img) {
    if (img === null || img === '' || img === 'assets/images/courseicon.jpg') {
      return 'assets/images/activity1.jpg';
    } else {
      return img;
    }
  }

  fireEvent(type, data, b_data?: any) {
    // if (data.compStatus !== 'UPCOMING') {
    const dataOutput = {
      type: type,
      cardData: data,
      // liked : isLiked,
      buttonData: b_data
    };

    console.log('Fire event called : ', dataOutput);
    this.getEvent.emit(dataOutput);
    // } else {
    //   return false;
    // }
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log('options', changes)
    if (changes && changes.cardsData) {
      const options = this.contentOptionService.makeButtonsReady(this.cardsData);
      // const buttons = []
      this.buttons = [];
      console.log('options', options)
      for (let opt of options) {
        if (opt.isVisible) {
          // let button = {
          //   text: opt.text,
          //   cssClass: opt.cssClass,
          //   icon: opt.icon,
          // }
          this.buttons.push(opt);
        }
      }
      // console.log('buttons', this.buttons);
    }
    // if(changes)
  }

  buttonClicked(button, data) {
    console.log('Button clicked...', button);
    if (button.action) {
      this.fireEvent(button.action, data, button);
    } else {
      console.log('Button action not available...');
    }
  }

  optionClicked(event, data) {
    if (data.buttons) {
      const button = this.sharedService.getObjFromArray(data.buttons, event.id, 'action');
      if (button) {
        this.buttonClicked(button, data);
      } else {
        // this.fireEvent(event.id, data, null);
        this.fireEvent(event.id, event.value);
      }
    } else {
      // this.fireEvent(event.id, data, null);
      this.fireEvent(event.id, event.value);
    }
  }

}
