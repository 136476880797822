import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PdfService {

  private progressSubject: Subject<number> = new Subject<number>();
  public progress$: Observable<number> = this.progressSubject.asObservable();

  constructor() { }

  updateProgress(progress: number): void {
    this.progressSubject.next(progress);
  }
}
