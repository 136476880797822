import { Injectable } from '@angular/core';
import { webApi } from '../../../config';
import { HttpClient, HttpHeaders, HttpEventType } from '@angular/common/http';
import { map, } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AssignmentService {
  private headers = new HttpHeaders();
  baseUrl: any = webApi.baseUrl;
  private submitAssigementUrl: any = this.baseUrl + webApi.apiUrl.submitAssigement;
  constructor(private http: HttpClient) { }

  populateAssignmentDetails(content, file, deletedfileArr) {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const params = {
      userId: currentUser.id,
      empId: currentUser.eid,
      cont_comp_Id: content.areaId == 34 ? content.contentcomplitionId : null,
      act_comp_Id: content.areaId == 34 ? null : content.activitycompletionid,
      contentId: content.contentId,
      completiontype: content.areaId === 34 ? 1 : 2,
      courseId: content.courseId || null,
      moduleId: content.moduleId || null,
      activityId: content.areaId === 34 ? null : (content.activityId || content.instanceId),
      enrolId: content.enrolId,
      completionstatus: content.completionstatus ? content.completionstatus : 'Y',
      completionstate: content.completionstate ? content.completionstate : 1,
      viewed: 1,
      contentwatchedtime: content.contentwatchedtime,
      contenttime: content.contenttime,
      languageId: content["defaultlangId"] ? content.defaultlangId : null,
      wfId: content.wfId ? content.wfId : null,
      stepId: content.stepId,
      assignmentid: content.assignmentId,
      asscompId: content.ass_comp_id,
    };
    const fd = new FormData();
    fd.append("file", file);
    // fd.append('content', JSON.stringify(param));
    fd.append("deletedfileArr", JSON.stringify(deletedfileArr));
    // fd.append('filedata', JSON.stringify(param));
    console.log('params', params);
    Object.keys(params).forEach((item) => {
      // console.log('item', item, params['item']);
      fd.append(item, params[item]);
    });
    return fd;
  }


  submitAssignment(param): Observable<any> {

    return this.http.post<any>(this.submitAssigementUrl, param, {
      reportProgress: true,
      observe: 'events'
    }).pipe(map((event) => {

      switch (event.type) {

        case HttpEventType.UploadProgress:
          const progress = Math.round(100 * event.loaded / event.total);
          return { status: 'progress', percentage: progress };

        case HttpEventType.Response:
          return { status: 'completed', data: event.body };
        // return ;
        default:
          return { status: 'default', data: event.type };
        // return `Unhandled event: ${event.type}`;
      }
    })
    );
  }

  // private handleError(error: Response | any) {
  //   return Promise.reject(error.message || error);
  // }
}
