import { Component, OnInit } from "@angular/core";
import { LoginService } from "../../../../providers/login/login.service";
import { Router } from "@angular/router";
import { EventsService } from '../../../../providers/events/events.service';
import { CacheService } from '../../../../modules/shared/providers/utils/cache-serivce/cache.service';
import { CheckInactivityService } from '../../../../modules/shared/providers/utils/check-inactivity/check-inactivity.service'
import { YellowMessangerService } from "../../../../modules/shared/providers/yellow-messanger/yellow-messanger.service";
// import { NbPopoverDirective } from '@nebular/theme';
@Component({
  selector: "ngx-profile-drawer",
  templateUrl: "./profile-drawer.component.html",
  styleUrls: ["./profile-drawer.component.scss"],
})
export class ProfileDrawerComponent implements OnInit {
  // @ViewChild(NbPopoverDirective) popover: NbPopoverDirective;
  roleList = [
    // { value: "learner", label: "Learner", status: true },
    // { value: "manager", label: "Manager", status: false },
    // { value: "trainer", label: "Trainer", status: false },
  ];
  listArray = {
    // list: [
    //   // { value: "ttt", label: "TTT" },
    //   { value: "my_cart", label: "My Cart" },
    //   { value: "my_calender", label: "My Calender" },
    //   // { value: "notification", label: "Notification" },
    // ],
    list2: [
      // { value: "my_profile", label: "My Profile" },
      // { value: "edit_skills", label: "Edit Skills" },
      // { value: "change_password", label: "Change Password" },
    ],

  };
  list3 = [
    { value: "help", label: "Help" },
    { value: "logout", label: "Logout" },
  ];
  finalArray = [];
  currentUser: any;
  constructor(private router: Router, private loginService: LoginService,
    private eventsService: EventsService, private cacheService: CacheService,
    private checkInactivityService: CheckInactivityService,
    private yellowMessangerService: YellowMessangerService
  ) { }

  ngOnInit(): void {
    this.roleList = JSON.parse(localStorage.getItem("userTabsByRole"));
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.cacheService.updateS3URL(this.currentUser.picture_url, (updatedS3URL: any) => {
      this.currentUser.picture_url = updatedS3URL;
      localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
    });
    // this.finalArray = Object.keys(this.listArray);
    this.makeTabDataReady();
  }



  performActionForClick(item) {
    if (item) {
      switch (item.value) {
        case "ttt":
          break;
        case "my_cart":
          break;
        case "my_calender":
          this.goToPage("pages/calender");
          break;
        case "notification":
          break;
        case "my_profile": this.goToPage("pages/features/profile");
          break;
        case "edit_skills": this.goToPage("pages/preferences");
          break;
        case "change_password":
          break;
        case "help":
          // this.eventsService.publish('help:popup', true);
          window.open('https://faqadmin.edgelearning.co.in', '_blank');
          break;
        case "logout":
          this.logout();
          break;
        default:
          console.log("invalid item value", item.value);
          break;
      }
    } else {
      console.log("invalid item", item);
    }
  }
  goToPage(path) {
    console.log('goToPage', path);
    if (path) {
      this.router.navigate([path]);
    }
    // return false
  }


  logout() {
    this.eventsService.publish('help:popup', false);
    // this.checkInactivityService.doLogout(false);
    this.checkInactivityService.stopTimer(null);
    this.loginService.logout(res => {
      console.log('logout : ', res);
    });
  }

  performActionForRoleChange(selectedRole) {
    console.log(selectedRole);
    console.log('roleList', this.roleList);
    if (selectedRole && selectedRole['sectionId'] == 2) {
      if (selectedRole.roleId !== this.currentUser.roleId) {
        this.loginService.changeUserRole(selectedRole);
        this.loginService.redirectAccordingToRole(selectedRole);
        // this.eventsService.redirectAccordingToRole(selectedRole);
        // break;
        // localStorage.setItem('userTabsByRole', JSON.stringify(this.roleList));
        this.roleList = JSON.parse(localStorage.getItem("userTabsByRole"));
        this.currentUser = JSON.parse(localStorage.getItem("currentUser"));

        // init chatbot
        // this.yellowMessangerService.initAndShowChatBot();
        // show/hide chatbot
        this.yellowMessangerService.showHideChatBot();
      }
    } else {
      window.open(selectedRole.redirectlink, '_blank')
    }

  }

  selectUserRole(selectedRole) {
    for (let i = 0; i < this.roleList.length; i++) {
      if (this.roleList[i].roleId === selectedRole.roleId) {
        this.roleList[i].status = true;
        // this.profileservice.selectedRoleIndex = i;
        // this.tabsService.tabsData = this.roleList[i].tabs;
      } else {
        this.roleList[i].status = false;
      }
    }

    localStorage.setItem("userTabsByRole", JSON.stringify(this.roleList));
  }


  makeTabDataReady() {
    const roleList = JSON.parse(localStorage.getItem('userTabsByRole'));
    if (roleList && roleList.length) {
      this.listArray.list2 = this.getSelectedRoleMenu(roleList, 'PROFILE');
      // this.tabsArr.push(this.newTab);
      // this.changeLearnComponent();
      // console.log(this.tabsArr);
    } else {
      this.listArray.list2 = [

      ];
    }

    // for (let i = 0; i < this.tabsArr.length; i++) {
    //     console.log(this.router.url);
    //     const currentCompRoute = this.router.url.substring(this.router.url.lastIndexOf('/') + 1);
    //     if (currentCompRoute === this.tabsArr[i].name) {
    //         this.currentSelectedTab = this.tabsArr[i].name;
    //     }
    // }

    // const usrData = JSON.parse(localStorage.getItem('currentUser'));
    // if (this.currentSelectedTab === 'home') {
    //     this.events.publish('user:login', usrData);
    // }
  }

  getSelectedRoleMenu(roleList, identifier) {
    for (let i = 0; i < roleList.length; i++) {
      //   if(roleList.length === 1 && roleList[i].roleId === 7){
      //     this.router.navigateByUrl['/tabs/train'];
      //   }
      //   if(roleList.length === 1 && roleList[i].roleId === 8){
      //     this.router.navigateByUrl['/tabs/home'];
      //   }
      //   if(roleList.length === 1 && roleList[i].roleId === 5){
      //     this.router.navigateByUrl['/tabs/employeeList'];
      //   }
      if (roleList[i].status === true) {
        const menuList = roleList[i].menu;
        if (menuList) {
          for (let index = 0; index < menuList.length; index++) {
            const element = menuList[index];
            if (element['identifier'] == identifier) {
              return element['children'];
              // return subMenuList;
            }
          }
        }

      }

    }
    return [];
  }
}
