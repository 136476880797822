import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ContentOptionService } from "../../providers/content-option/content-option.service";
import { ToasterService } from "../../providers/toaster/toaster.service";

@Component({
  selector: 'ngx-content-rating',
  templateUrl: './content-rating.component.html',
  styleUrls: ['./content-rating.component.scss']
})
export class ContentRatingComponent implements OnInit {

  ratingLoading = false;
  @Input() courseDetail = null;
  @Output() ratingChanged = new EventEmitter();
  currentUser: any;
  // update
  constructor(public contentOptionService: ContentOptionService, public toaster: ToasterService,) {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
  }

  ngOnInit(): void {
  }

  onRatingChange(event) {
    console.log('onRatingChange', event);
    if (event && event['rating'] && event['rating'] != this.courseDetail.rating.rate) {
      this.ratingLoading = true;
      this.courseDetail.rating.rate = event['rating'];
      // const params = {
      //   rateId: this.courseDetail.rating['rateId'],
      //   rate: this.courseDetail.rating.rate,
      //   outof: this.courseDetail.rating.out_of,
      //   roleId: this.currentUser.roleId,
      //   entityId: this.currentUser.referenceId,
      //   areaId: this.courseDetail['areaId'] ? this.courseDetail.areaId : null,
      //   instanceId: this.courseDetail['instanceId'] ? this.courseDetail['instanceId'] : null,
      // }
      // console.log('params', params);

      // this.ratingLoading = false;
      // this.contentOptionService.submitRating(params).then((response) => {
      //   console.log('response', response);
      //   if (response && response['type'] && response['data'] && Array.isArray(response['data'])) {
      //     const data = response['data'][0];
      //     this.courseDetail.rating['rateId'] = data.lastinstupdateId;
      //     this.presentToaster(data.msg, 'Success', 'success');
      //     this.ratingChanged.emit(this.courseDetail.rating);
      //   } else {
      //     // this.courseDetail.rating['rateId'] =
      //     this.presentToaster('Unable to submit data', 'Warning', 'warning');
      //   }
      // }).catch((error) => {
      //   this.ratingLoading = false;
      //   console.log('error', error);
      //   this.presentToaster('Unable to submit data', 'Warning', 'warning');
      // })
      this.contentOptionService.ratingChanged(this.courseDetail, (status, res) => {
        this.ratingLoading = false;
        if (status) {
          console.log('status data', res);
          this.courseDetail = res;
          this.ratingChanged.emit(this.courseDetail.rating);
        } else {
          console.log('Ratings not updated..');
        }
      });
    }
  }

  async presentToaster(msg, title, type) {
    await this.toaster.prsentToast(msg, title, type, null);
  }
}
