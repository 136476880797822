import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { webApi } from './../../../src/config';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  baseUrl: any = webApi.baseUrl;
  private headers = new HttpHeaders();

  constructor(
    public http: HttpClient,
  ) { }

  getUserData() {
    const currentUser = localStorage.getItem('currentUser');
    return currentUser ? JSON.parse(currentUser) : null;
  }

  /**************** check if object is valid start **************/
  checkIfParams(params) {
    if (params && !this.isEmpty(params)) {
      return true;
    } else {
      return false;
    }
  }

  isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }
  /**************** check if object is valid end **************/

}
