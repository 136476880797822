import { Injectable } from '@angular/core';
import { webApi } from "../../../../config";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  private baseUrl: any = webApi.baseUrl;
  private getTabUrl: any =
    this.baseUrl + webApi.apiUrl.getTabs;
  private getAssessorDataUrl: any =
    this.baseUrl + webApi.apiUrl.getAssessorData;
  //  baseurl1 = "http://localhost:9845";
  getPolicyDataUrl: any = this.baseUrl + webApi.apiUrl.get_data_by_tenantwise;
  getDashboardWidgetsurl = this.baseUrl + webApi.apiUrl.getDashboardWidgets;
  generatePayoutUrl = this.baseUrl + webApi.apiUrl.generatePayoutForBatch;
  getPayoutDataUrl = this.baseUrl + webApi.apiUrl.getPayoutData;
  downloadProgramReportUrl = this.baseUrl + webApi.apiUrl.downloadProgramReport;
  constructor(private http: HttpClient) { }

  /*--------------Get TABS Data----------------*/

  public getTabs(data): Promise<any> {
    return this.http
      .post(this.getTabUrl, data)
      .toPromise()
      .then((response) => {
        return (response = response);
      })
      .catch(this.handleError);
  }
  /*--------------END TABS Data----------------*/

  /*--------------Get Assessor Data----------------*/
  public getDataOnType(data): Promise<any> {
    return this.http
      .post(this.getAssessorDataUrl, data)
      .toPromise()
      .then((response) => {
        return (response = response);
      })
      .catch(this.handleErrorMessage);
  }
  /*--------------END Assessor Data----------------*/

  /*--------------Get Assessor Data----------------*/
  public getDashboardWidgets(data): Promise<any> {
    return this.http
      .post(this.getDashboardWidgetsurl, data)
      .toPromise()
      .then((response) => {
        return (response = response);
      })
      .catch(this.handleErrorMessage);
  }
  /*--------------END Assessor Data----------------*/

  /*---------------policy data---------------------*/
  public getPolicyData(data): Promise<any> {
    return this.http
      .post(this.getPolicyDataUrl, data)
      .toPromise()
      .then((response) => {
        return (response = response);
      })
      .catch(this.handleError);
  }
  /*---------------End policy data-------*/

  /*---------------Payout data---------------------*/
  public generatePayout(data): Promise<any> {
    return this.http
      .post(this.generatePayoutUrl, data)
      .toPromise()
      .then((response) => {
        return (response = response);
      })
      .catch(this.handleError);
  }
  /*---------------End Payout data-------*/

  /*---------------Payout data---------------------*/
  public getMileStoneData(data): Promise<any> {
    return this.http
      .post(this.getPayoutDataUrl, data)
      .toPromise()
      .then((response) => {
        return (response = response);
      })
      .catch(this.handleError);
  }
  /*---------------End Payout data-------*/

  /*---------------Program Report data---------------------*/
  public downloadProgramReport(data): Promise<any> {
    return this.http
      .post(this.downloadProgramReportUrl, data)
      .toPromise()
      .then((response) => {
        return (response = response);
      })
      .catch(this.handleError);
  }
  /*---------------End Program Report data-------*/

  private handleError(error: Response | any) {
    return Promise.reject(error.message || error);
  }

  private handleErrorMessage(error: Response | any) {
    return Promise.reject(error);
  }
}
