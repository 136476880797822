<!-- <div ngxDocumentClickListner (clickElsewhere)="listenClick($event)">
    <ng-container *ngIf="!isEditable && data[label?.bindingProperty]">
        <div (click)="data[label.disableProperty] != 1 ? enableEdit(true) : return;">
            {{data[label?.bindingProperty]}}</div>
    </ng-container> -->

<!--  *ngIf="!data[label?.bindingProperty] || isEditable" -->
<ng-container>
    <div id="text-div-" class="d-flex align-items-center">
        <input class="form-control form-control-sm" [maxlength]="setting?.max"
            (focus)="oldValue=data[label?.bindingProperty]"
            [disabled]="data[label?.disableProperty] == 1 || data[label?.rowDisableProperty] == 1"
            [(ngModel)]="data[label?.bindingProperty]" (ngModelChange)="checkForInputValidation($event)" type="text"
            [placeholder]="label?.componentAttribute?.placeholder">
        <!--  && data[label?.disableProperty] != 1 -->
        <span class="p-1 cursor-p"
            *ngIf="label?.componentAttribute?.showSubmitBtn != 0 && data[label?.bindingProperty] && data[label?.rowDisableProperty] != 1"
            (click)="submit(data[label?.bindingProperty])">
            <svg-icon src="assets/icon-svg/check.svg" svgClass="lh-1 stroke-success"
                [svgStyle]="{'width.rem': 1, 'height.rem': 1}"></svg-icon>
        </span>
    </div>
</ng-container>
<!-- </div> -->

<!-- form-control form-control-sm -->
<!-- small-input text-center border -->