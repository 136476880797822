<!-- <p>field-icon works!</p> -->

<ng-container *ngIf="!(data?.isvisible == 0)">
    <svg-icon [src]="data[labelData?.userImage]"></svg-icon>
    <!-- <img *ngIf="labelData?.userImage" (error)="errorLoad()" [src]="data[labelData?.userImage]" alt=""> -->
    <span class="body-text pl-2" title="{{data[labelData?.name]}}" *ngIf="labelData?.name">
      <!-- <ng-container *ngIf="data[labelData?.name] !=null">

        <ng-container *ngIf="!setting">
            {{ data[labelData?.name] }}
        </ng-container>
        <ng-container *ngIf="setting?.maxLength">

            {{data[labelData?.name] | truncate : setting?.maxLength}}
</ng-container>
</ng-container> -->
<ng-container *ngIf="setting?.length ==0">
  {{data[labelData?.name]  }}
</ng-container>
<ng-container *ngIf="setting?.maxLength">

  {{data[labelData?.name]  | truncate : setting?.maxLength}}
</ng-container>

</span>
</ng-container>