<div class="navigation-wrapper">
    <div class="keen-slider keen-slider--vertical" #sliderRef>
        <ng-content></ng-content>
    </div>
    <ng-container *ngIf="setting.arrow">
        <svg [ngClass]="'arrow arrow--left ' + (currentSlide === 0 ? 'arrow--disabled' : '')" (click)="slider.prev()" xmlns="http://www.w3.org/2000/svg" width="7.072" height="14.271" viewBox="0 0 7.072 14.271">
      <path d="M18.765,21.155,13.5,15.078,18.765,9" transform="translate(-12.75 -7.942)" fill="none" stroke="#ffffff"
        stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
    </svg>

        <!-- slider.details().size - 1 === currentSlide -->
        <svg *ngIf="slider" class="arrow arrow--right" [ngClass]="{'arrow--disabled': slider.details().size - 1 === currentSlide}" (click)="slider.next()" xmlns="http://www.w3.org/2000/svg" width="7.811" height="14.121" viewBox="0 0 7.811 14.121">
      <path d="M9,18l6-6L9,6" transform="translate(-7.939 -4.939)" fill="none" stroke="#ffffff" stroke-linecap="round"
        stroke-linejoin="round" stroke-width="1.5" />
    </svg>
    </ng-container>
</div>

<!-- <div class="dots">
    <button (click)="slider.moveToSlideRelative(i)" *ngFor="let slide of dotHelper; let i = index"
      [class]="'dot ' + (i === currentSlide ? 'active' : '')"></button>
  </div> -->