import { Injectable } from '@angular/core';
import { webApi } from "../../../../../config";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class SearchableSelectService {

  private baseUrl: any = webApi.baseUrl;

  constructor(private http: HttpClient,) { }

  makeSelectedStrReady(arr, att, operator, isFilter) {
    if (isFilter) {
      arr = this.filterArray(arr);
    }
    return Array.prototype.map
      .call(arr, (item) => {
        return item[att];
      })
      .join(operator);
  }

  filterArray(arr) {
    arr = arr.filter((obj) => {
      // return obj.isSelected == 1;
      return obj.isTemp == true && obj.isSelected == 1;
    });
    return arr;
  }

  /*--------------Start Data----------------*/

  public fetchSearchableSelectListDynamic(api, data): Promise<any> {
    const url = this.baseUrl + api;
    return this.http
      .post(url, data)
      .toPromise()
      .then((response) => {
        return (response = response);
      })
      .catch(this.handleError);
  }
  /*--------------END  Data----------------*/

  /*--------------Submit  Data----------------*/

  public submitSearchableSelectListDynamic(api, data): Promise<any> {
    const url = this.baseUrl + api;
    return this.http
      .post(url, data)
      .toPromise()
      .then((response) => {
        return (response = response);
      })
      .catch(this.handleError);
  }
  /*--------------END Submit Data----------------*/


  submitSelectedData(api, data, selectedIds, extraParams, cb) {
    const params = {
      // empId: this.currentUserData.eid,
      areaId: data.areaId,
      instanceId: data.instanceId,
      selectedIds: selectedIds,
      ...extraParams,
    };
    // this.load.presentLoading('Please wait...');

    this.submitSearchableSelectListDynamic(api, params).then(
      (res) => {
        // this.load.dismiss();
        if (res.type === true && res.data && res.data.length != 0) {
          console.log("saveshareContent res", res);
          const submitRes = res.data;
          // this.closePopup(res.data)
          // this.presentToastMessage(submitRes[0].msg);
          // this.dialogRef.close();

          if (submitRes[0].flag == 1) {
            // this.shareFormSubmitting = false;
            // this.presentToastMessage(
            //   submitRes[0].msg, 'Success!', 'success'
            // );
            // this.resetValues();
            // this.getUserData();
            // this.dataSubmitted = true;
            cb(1, res)
          } else {
            // this.presentToastMessage(
            //   submitRes[0].msg, 'Warning!', 'warning'
            // );
            cb(2, res)
          }
          // cb(false, res)
        } else {
          // this.presentToastMessage(
          //   "Unable to submit , please try again", 'Warning!', 'warning'
          // );
          // this.shareFormSubmitting = false;
          cb(3, null)
        }
      },
      (err) => {
        // this.load.dismiss();
        console.log(err);
        cb(3, null)
        // this.shareFormSubmitting = false;
        // this.presentToastMessage(
        //   "Unable to submit , please try again", 'Warning!', 'warning'
        // );
      }
    );
  }

  private handleError(error: Response | any) {
    return Promise.reject(error.message || error);
  }
}
