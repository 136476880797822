<ngx-popup [show]="true" [popupConfig]="popupConfig" (actionEvent)="performActionPopup($event)">
  <!-- <div> -->

  <ng-container *ngIf="inputData?.messageMedia">

    <ng-container *ngIf="loading">
      <div [nbSpinner]="loading" class="w-100 h-22-rem" nbSpinnerSize="giant" nbSpinnerStatus="primary">
      </div>
    </ng-container>

    <ng-container *ngIf="!loading && !notFound">
      <ng-container *ngIf="inputData?.messageMediaType == 7">
        <div class="h-22-rem">
          <img class="w-100 h-100" [src]="inputData?.messageMedia" alt="image" (error)="errorMessage($event)">
        </div>
      </ng-container>
      <ng-container *ngIf="inputData?.messageMediaType == 1">
        <!-- <video class="w-100 h-100" [src]="inputData?.messageMedia" controls autoplay webkit-playsinline="true"
          playsinline="true"></video> -->
        <div class="h-22-rem">
          <ngx-media-player [playerConfig]="medialPlayerOptions" [activity]="inputData?.extraData"
            (errorEvent)="errorMessage($event)"></ngx-media-player>
        </div>
      </ng-container>
      <ng-container *ngIf="inputData?.messageMediaType == 4">
        <div class="h-22-rem">
          <ngx-k-point [activity]="inputData?.extraData"></ngx-k-point>
        </div>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="!loading && notFound">
      <div class="d-flex flex-column align-items-center justify-content-center h-22-rem">
        <svg-icon *ngIf="inputData?.messageMediaType == 7" svgClass="stroke-gray fill-white" class="lh-0"
          src="assets/icon-svg/Image_icon.svg"
          [svgStyle]="{'width.rem': 3, 'height.rem': 3, 'stroke-width': 1.1}"></svg-icon>
        <svg-icon *ngIf="inputData?.messageMediaType == 1 || inputData?.messageMediaType == 4"
          svgClass="stroke-gray fill-white" class="lh-0" src="assets/icon-svg/Video_icon.svg"
          [svgStyle]="{'width.rem': 3, 'height.rem': 3, 'stroke-width': 1.2}"></svg-icon>
        <div class="text-18-px text-gray font-weight-bold">Something went wrong!</div>
      </div>
    </ng-container>

  </ng-container>

  <ng-container *ngIf="inputData?.messageContent">
    <div class="pt-2" [innerHTML]="inputData?.messageContent"></div>
  </ng-container>

  <!-- <div class="ion-text-lg" *ngIf="inputData?.messageContent">
      <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Perspiciatis, dolorem in. Exercitationem dicta, porro
        quaerat tempora consectetur dolorem dolorum optio eveniet in sapiente nam sequi deserunt error voluptate
        temporibus delectus.</p>
    </div> -->

  <!-- </div> -->

</ngx-popup>