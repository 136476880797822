import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ToasterService } from '../../providers/toaster/toaster.service';
import { ModuleService } from "../../providers/module/module.service";
import { Router } from '@angular/router';
@Component({
  selector: 'ngx-module-activity-list',
  templateUrl: './module-activity-list.component.html',
  styleUrls: ['./module-activity-list.component.scss',]
})
export class ModuleActivityListComponent implements OnInit {

  @Input() inputData: any = [];
  @Input() inputType: number = 0;
  @Output() actionEvent = new EventEmitter();

  constructor(public toaster: ToasterService, public moduleService: ModuleService, public router: Router,) { }

  ngOnInit() { }

  ngOnChanges(changes: any) {
    console.log('ModuleActivityListComponent changes called...', changes);
  }

  fireEvent(inEvent, inData, modIndex, actIndex) {
    if ((inData.activityTypeId == 12 || inData.activityTypeId == 16) && inData.compStatus == 0) {
      this.presentToastMessage(inData.msg, 'warning');
    } else {
      inData.modIndex = modIndex;
      inData.actIndex = actIndex;
      const res = {
        type: inEvent,
        data: inData,
      };
      this.actionEvent.emit(res);
    }
  }

  async presentToastMessage(msg, type) {
    // const toast = {
    //   message: msg,
    //   cssClass: type,
    // };
    // this.toasterService.showToast(toast);
    const title = "Warning";
    await this.toaster.prsentToast(msg, title, type, null);
  }

  bindActivityIcon(detail) {
    if (detail?.compStatus == 1) {
      return 'Check';
    } else if (detail.activityTypeId == 5) {
      return 'quiz';
    } else if (detail.activityTypeId == 6 || detail.activityTypeId == 16 || detail.activityTypeId == 12) {
      return 'feedback';
    } else if (detail.activityTypeId == 17) {
      return 'feedback';
    } else if (detail.activityTypeId == 11) {
      return 'webinar';
    } else if (detail.activityTypeId == 9) {
      return 'attendance';
    } else if (detail.formatId == 1) {
      return 'video';
    } else if (detail.formatId == 2) {
      return 'audio';
    } else if (detail.formatId == 3) {
      return 'pdf';
    } else if (detail.formatId == 4) {
      return 'kpoint';
    } else if (detail.formatId == 5) {
      return 'scrom';
    } else if (detail.formatId == 6) {
      return 'youtube';
    } else if (detail.formatId == 7) {
      return 'image';
    } else if (detail.formatId == 8) {
      return 'url';
    } else if (detail.formatId == 9) {
      return 'practice_file';
    } else if (detail.formatId == 10) {
      return 'ppt';
    } else if (detail.formatId == 11) {
      return 'excel';
    } else if (detail.formatId == 12) {
      return 'word';
    } else if (detail.formatId == 15) {
      return 'h5p';
    } else {
      return '';
    }
  }

  goToRefresherCourse(event, activity) {
    if (activity.refresherDetails) {
      event.stopPropagation();
      console.log('goToRefresherCourse', event, activity);
      let course = JSON.parse(activity.refresherDetails);
      // this.router.navigate(["pages/module-activity/" + course.areaId + '/' + course.instanceId + '/' + 0]);
      this.router.navigate(["pages/module-activity/" + course.areaId + '/' + course.instanceId + '/' + 0 + '/' + 0]);
    }
  }
}
