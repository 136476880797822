<!-- <div class="checkbox d-inline-block align-top" (click)="emitAction($event)">
    <span class="checkbox--name text-capitalize">{{checkLabel}}</span>
    <input type="checkbox" [checked]='isChecked == true'>
    <span class="checkmark"></span>
</div> -->
<!-- <img src="../../../assets/icon-svg/sort-list.svg" alt=""> -->
<div class="checkboxDiv d-inline-block">
    <input class="checkbox cursor-p" type="checkbox" [(ngModel)]="isChecked" [checked]='isChecked == true' (change)="emitAction($event)" [disabled]="isDisabled == 1 ? true : false">
    <span class="checkbox--name text-capitalize body-text pl-1">{{checkLabel}}</span>
    <!-- <span class="tableCheck focus"></span> -->
</div>