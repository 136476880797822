<ngx-popup [nbSpinner]="loader" (actionEvent)="performActionOnEvent($event)" [popupConfig]="popupConfig" [show]="'false'">
    <ng-container *ngIf="!notFound">
        <form #notificationEventForm="ngForm">
            <div class="form-group">
                <label for="">Select Event</label>
                <select #eventName="ngModel" name="eventName" required [(ngModel)]="formDetail.eventId" (ngModelChange)="eventChanged($event)" class="form-control form-control-sm cursor-p">
      <option value="" disabled>Please select Notification Event</option>
      <option *ngFor="let item of notificationEventList" [value]="item?.eventId">{{item?.eventName}}</option>
    </select>
                <div *ngIf="eventName?.errors && (eventName?.dirty || eventName?.touched)">
                    <small class="invalid-msg" [hidden]="!eventName?.errors?.required">Event is
        required</small>

                </div>
            </div>

            <div class="form-group" *ngIf="formDetail.eventId">
              <label>CC</label>
              <angular2-multiselect #cc="ngModel" name="cc" (open)="onOpen($event)" (onOpen)="onOpen($event)" (onScrollToEnd)="fetchMore($event)" [loading]="loading" [data]="usersList" [(ngModel)]="formDetail.cc" [settings]="dropdownSettings">
                <c-search>
                    <ng-template>
                        <div class="position-relative h-100">
                            <input type="text" [(ngModel)]="searchString" name="searchCC" (ngModelChange)="onSearch($event)" placeholder="Search" />
                            <i [hidden]="searchString?.length ==0" class="position-absolute select-cancel fa fa-times" (click)="onSearch('')"></i>
                        </div>
                    </ng-template>
                </c-search>
                <c-badge>
                    <ng-template let-item="item">
                        <label class="font-weight-normal m-0">{{item.fieldName}}</label>
                    </ng-template>
                </c-badge>

                <c-item>
                    <ng-template let-item="item">
                        <div class="user-role fs-12 d-grid align-items-center w-100">
                            <span class="text-left">{{ item.fieldName }}</span>
                            <span class="text-center">
                                <ng-container *ngIf="item.roleNames">
                                    <app-read-more [maxLength]="15" [text]="item.roleNames"></app-read-more>
                                </ng-container>
                            </span>
                            <span class="text-right">{{ item.userName }}</span>
                        </div>
                    </ng-template>
                </c-item>
            </angular2-multiselect>
          </div>

            <div class="form-group">
                <label>Add Subject</label>
                <input #subjectName="ngModel" name="subject" required type="text" [(ngModel)]="formDetail.subject" class="form-control form-control-sm" placeholder="Subject">
                <div *ngIf="subjectName?.errors && (subjectName?.dirty || subjectName?.touched)">
                    <small class="invalid-msg" [hidden]="!subjectName?.errors?.required">Subject is
        required</small>

                </div>
            </div>
            <!-- (getEvents)="htmlEditorValueChanged($event)" -->
            <div class="form-group">
                <label>Edit Body</label>
                <ngx-html-editor [config]="editorConfig" [isSubmitted]="isSubmitted" (getEvents)="performActionOnEvent($event)" [bindingText]="formDetail.body"></ngx-html-editor>

            </div>
        </form>
    </ng-container>
    <ng-container *ngIf="notFound">
        <<ngx-no-data [showImage]="noDataFoundContainer?.showImage" [title]="" [discription]="noDataFoundContainer?.discription"></ngx-no-data>
    </ng-container>

</ngx-popup>
