import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { webApi } from "../../../config";

@Injectable({
  providedIn: "root",
})
export class KpointService {
  constructor(private http: HttpClient) {}

  getToken(param) {
    const url = webApi.baseUrl + webApi.apiUrl.getKpointToken;
    return new Promise((resolve) => {
      this.http
        .post(url, param)
        // .map(res => res.json())
        .subscribe(
          (data) => {
            resolve(data);
          },
          (err) => {
            resolve(err);
          }
        );
    });
  }
}
