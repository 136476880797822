<div [class]="configLocal.fileClass">
    <div [class]="configLocal.innerClass">
        <div [class]="configLocal.titleSection.class" *ngIf="configLocal.titleSection.show">
            {{configLocal.titleSection.message}}</div>
        <div [class]="configLocal.titleNote.class" *ngIf="configLocal.titleNote.show">{{configLocal.titleNote.message}}
        </div>

        <div [class]="configLocal.fileinput.class" *ngIf="configLocal.fileinput.show" appDnd
            (fileDropped)="onFileDropped($event)">
            <input type="file" class="position-absolute invisible top-0-px left-0-px w-100 h-100 cursor-p" #fileUpload
                (click)="fileUpload.value = null" [multiple]="config?.multiUpload"
                (change)="emitFileUploadEvent('fileUpload', $event)">
            <svg-icon [svgClass]="configLocal.fileinput.icon.class" [src]="configLocal.fileinput.icon.iconRef"
                [svgStyle]="configLocal.fileinput.icon.style"></svg-icon>
            <!-- <div class="text-14-px" *ngIf="configLocal.fileinput.message">Drag & Drop file here or <span class="text-primary link">Click here</span> for
                upload</div> -->
            <div [innerHTML]="configLocal.fileinput.innerHtml" (click)="fileUpload.click()"></div>
        </div>

        <ng-container *ngIf="configLocal.footerIconText.show">
            <div [class]="configLocal.footerIconText.wrapperClass" (click)="downloadSample($event)">
                <svg-icon [svgClass]="configLocal.footerIconText.icon.class"
                    [src]="configLocal.footerIconText.icon.iconRef"
                    [svgStyle]="configLocal.footerIconText.icon.style"></svg-icon>
                <span [class]="configLocal.footerIconText.messageClass">{{configLocal.footerIconText.message}}</span>
            </div>
        </ng-container>
    </div>
</div>