<div class="row" [nbSpinner]='ratingLoading'>
    <div class="col-md-2 py-2">

        <h5>Rate Content</h5>
    </div>
    <div class="col-md-10 py-2">
        <star-rating [starType]="'svg'" (ratingChange)="onRatingChange($event)" [size]="'large'" [rating]="courseDetail?.rating?.rate" staticColor="warning" [showHalfStars]="true" [numOfStars]="courseDetail?.rating?.out_of"></star-rating>
        <!-- <div>
          {{courseDetailSummary['courseCertificate']?.isCertavailableMsg}}
      </div> -->
      <hr>
    </div>
</div>