import { PlyrComponent } from "ngx-plyr";
import * as Plyr from "plyr";

import {
  Component,
  OnInit,
  Input,
  Output,
  ViewChild,
  AfterViewInit,
  OnDestroy,
  ChangeDetectorRef,
  EventEmitter,
  OnChanges,
} from "@angular/core";

let _this: any;

@Component({
  selector: "ngx-media-player",
  templateUrl: "./media-player.component.html",
  styleUrls: ["./media-player.component.scss"],
})
export class MediaPlayerComponent
  implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  // get the component instance to have access to plyr instance
  @ViewChild(PlyrComponent, { static: true }) plyr: PlyrComponent;

  // or get it from plyrInit event
  player: Plyr;

  //   playerSource = [
  //     {
  //     type: 'audio',
  //     sources: [
  //       {
  //         src: 'https://cdn.plyr.io/static/demo/Kishi_Bashi_-_It_All_Began_With_a_Burst.mp3',
  //         // type: 'audio/mp3',
  //       },
  //     ],
  //     plyrPlaysInline: true
  //   },{
  //     type: 'video',
  //     sources: [
  //       {
  //         src: 'https://cdn.plyr.io/static/demo/View_From_A_Blue_Moon_Trailer-576p.mp4',
  //         // type: 'video/mp4',
  //       },
  //     ],
  //     plyrPlaysInline: true
  //   },{
  //     type: 'video',
  //     sources: [
  //       {
  //         src: 'https://youtube.com/watch?v=bTqVqk7FSmY',
  //         provider: 'youtube',
  //       },
  //     ],
  //     plyrPlaysInline: true
  //   }
  // ];

  playerSource = [];

  playerOptions = {
    duration: null,
    // controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'captions', 'settings', 'pip', 'airplay', 'fullscreen']
    controls: [
      "play",
      "progress",
      "current-time",
      "mute",
      "volume",
      "fullscreen",
    ],
    autoplay: false,
    autopause: false,
    hideControls: false,
    clickToPlay: false,
    youtube: {
      autoplay: false,
    }
  };

  @Input() playerConfig: any;
  @Input() activity: any = {
    contenttime: 0,
  };
  @Output() saveEvent = new EventEmitter<any>();
  @Output() errorEvent = new EventEmitter<any>();

  @ViewChild("vid") video: any; // HTMLVideoElement;
  currentProgress: number;

  passData = {
    status: null,
    duration: "",
    currentTime: "",
  };

  videoElement: any;

  constructor(public cdf: ChangeDetectorRef) { }

  ngOnInit() {
    if (
      navigator.userAgent.indexOf("Safari") != -1 &&
      navigator.userAgent.indexOf("Mac") != -1 &&
      navigator.userAgent.indexOf("Chrome") == -1
    ) {
      document.getElementById("videoEl").setAttribute("crossorigin", "true");
    }
    // console.log("Current activity data ", this.activity);
    this.makeVideoTrackDataReady();
  }

  ngOnChanges(changes: any) {
    console.log("Media player component loaded changes : ", changes);
    if (changes.playerConfig) {
      this.playerOptions = { ...this.playerOptions, ...this.playerConfig };
    }
    if (this.activity && this.playerOptions) {
      console.log('Current plater options ', this.playerOptions);
      console.log('Current activity data ', this.activity);
      // this.makeVideoTrackDataReady();
    }
  }

  ngAfterViewInit() {
    // this.isDocumentReady();
  }

  // isDocumentReady() {
  //   let stateCheck = setInterval(() => {
  //     if (document.readyState === 'complete') {
  //       this.playByButton();
  //       const startbtn = document.getElementById("startbtn");
  //       if (startbtn) {
  //         startbtn.click();
  //       }
  //       clearInterval(stateCheck); // document ready 
  //     }
  //   }, 100);
  // }

  // playByButton() {
  //   const startbtn = document.getElementById("startbtn");
  //   // const content = document.getElementById("content");
  //   // const loader = document.getElementById("loader");
  //   // const player = document.getElementById("music");

  //   //Add an event listner to the start button
  //   startbtn.addEventListener("click", () => {
  //     //  Show the loader and hide the button
  //     // loader.style.display = "";
  //     startbtn.style.display = "none";
  //     //  Wait for the music to start
  //     this.autoplayMedia(this.player);
  //   });
  // }

  autoplayMedia(instance) {
    if (instance) {
      //  && this.player['isYouTube']
      if (this.activity.isAutoPlay) {
        setTimeout(() => {
          // instance.muted = true;
          instance.play();
        }, 100);
      } else {
        console.log('Autoplay not available...');
      }
    } else {
      console.log('Player not available...');
    }
  }

  plyrReady(event: Plyr.PlyrEvent) {
    console.log("plyrReady", event);
    // this.setProgress(100);
    this.getProgress();
    this.setPlayerDuration();
    if (this.activity.contentwatchedtime) {
      this.setProgress(this.activity.contentwatchedtime);
    }

    // Autoplay changes
    this.autoplayMedia(event.detail.plyr);
  }

  plyrCanPlay(event: Plyr.PlyrEvent) {
    // if (this.activity.contentwatchedtime) {
    //   this.setProgress(this.activity.contentwatchedtime);
    // }
  }

  plyrLoadedData(event: Plyr.PlyrEvent) {
    if (this.activity.contentwatchedtime) {
      this.setProgress(this.activity.contentwatchedtime);
    }
  }

  played(event: Plyr.PlyrEvent) {
    console.log("played", event);
    // this.makePassDataReady(null, this.player.duration, this.player.currentTime);
    // this.saveEvent.emit(this.passData);
  }

  plyrPause(event: Plyr.PlyrEvent) {
    console.log("plyrPause", event);
    // this.makePassDataReady(null, this.player.duration, this.player.currentTime);
    // this.saveEvent.emit(this.passData);
  }

  plyrEnded(event: Plyr.PlyrEvent) {
    console.log("plyrEnded", event);
    this.getProgress();
    // this.makePassDataReady(null, this.player.duration, this.player.currentTime);
    this.makePassDataReady(null, this.player.duration, this.player.duration);
    this.saveEvent.emit(this.passData);
  }

  plyrTimeUpdate(event: Plyr.PlyrEvent) {
    // console.log('plyrTimeUpdate', event);
    this.makePassDataReady(null, this.player.duration, this.player.currentTime);
    // this.saveEvent.emit(this.passData);
  }

  plyrError(event: Plyr.PlyrEvent) {
    console.log("plyrError", event);
    this.errorEvent.emit(event);
  }

  plyrEnterFullScreen(event: Plyr.PlyrEvent) {
    console.log("plyrEnterFullScreen", event);
  }

  plyrExitFullScreen(event: Plyr.PlyrEvent) {
    console.log("plyrExitFullScreen", event);
  }

  plyrStateChange(event: Plyr.PlyrEvent) {
    console.log("plyrStateChange", event);
    // setTimeout(() => {
    // this.makePassDataReady(null, this.player.duration, this.player.currentTime);
    // this.saveEvent.emit(this.passData);
    // }, 500);
  }

  setProgress(time) {
    this.player.currentTime = time; // Seeks to 10 seconds
  }

  getProgress() {
    this.currentProgress = this.player.currentTime;
  }

  setPlayerDuration() {
    this.activity.contenttime = this.player.duration;
  }

  // togglefullscreen (e) {
  //   const state = document['fullScreen'] || document['mozFullScreen'] || document['webkitIsFullScreen'];
  //   const event = state ? 'FullscreenOn' : 'FullscreenOff';
  //   console.log('fullscreen ', event);
  //   if (_this.plt.is('cordova')) {
  //     // allow user rotate
  //     if (state) {
  //       _this.screenOrientation.unlock();
  //       _this.screenOrientation.lock(_this.screenOrientation.ORIENTATIONS.LANDSCAPE);
  //       // this.plt.backButton.unsubscribe();
  //     } else if (!state) {
  //       _this.screenOrientation.unlock();
  //       _this.screenOrientation.lock(_this.screenOrientation.ORIENTATIONS.PORTRAIT);
  //     }
  //   }
  //   _this = _this;
  // }

  makePassDataReady(status, duration, currentTime) {
    if (!status && duration && currentTime) {
      // if (parseInt(duration, 10) >= parseInt(currentTime, 10)) {
      // if (duration - currentTime < 10) {
      //   console.log('makePassDataReady duration', duration);
      //   console.log('makePassDataReady currentTime', currentTime)
      // }
      // console.log('makePassDataReady duration', duration);
      // console.log('makePassDataReady currentTime', currentTime)
      const totalDuration = Math.round(duration);
      // const watchedDuration = Math.round(currentTime) + 5;
      const watchedDuration = Math.round(currentTime);
      // if (totalDuration >= watchedDuration) {
      if (totalDuration > watchedDuration) {
        status = null;
      } else {
        status = "Y";
      }
    }
    this.passData = {
      status: status,
      duration: duration,
      currentTime: currentTime,
    };
  }

  makeVideoTrackDataReady() {
    let type = "";
    let provider = null;
    switch (Number(this.activity.formatId)) {
      case 1: // video
        type = "video";
        break;
      case 2: // Audio
        type = "audio";
        break;
      case 6: // Youtube
        type = "video";
        provider = "youtube";
        break;
      default:
        return "";
    }
    const playerTrack = {
      type: type,
      plyrPlaysInline: true,
      sources: [
        {
          src: this.activity.reference,
        },
      ],
    };
    if (provider) {
      playerTrack["sources"][0]["provider"] = provider;
    }
    console.log("playerTrack", playerTrack);
    this.playerSource = [playerTrack];

    if (this.activity.isAutoPlay) {
      this.playerOptions.autoplay = true;
      this.playerOptions.youtube.autoplay = true;
    }
  }

  ngOnDestroy() {
    this.getProgress();
    // this.makePassDataReady(null, this.player.duration, this.player.currentTime);
    this.saveEvent.emit(this.passData);
    if (this.player) {
      this.player.destroy();
    }
  }
}
