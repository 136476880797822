import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';

@Component({
  selector: 'ngx-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit, OnChanges {

  @Input() config = {};
  @Output() getEvent = new EventEmitter();

  configLocal = {
    fileClass: '',
    innerClass: 'text-center',
    titleSection: {
      show: true,
      class: 'text-22-px font-weight-bold',
      message: 'Upload your file',
    },
    titleNote: {
      show: true,
      class: 'text-12-px text-gray py-3',
      message: 'Supported file type: csv',
    },
    fileinput: {
      show: true,
      class: 'd-flex flex-column align-items-center justify-content-center bg-light position-relative h-10-rem w-80 dash-border rounded p-3 mx-auto',
      message: 'Supported file type: csv',
      innerHtml: '<div class="text-14-px py-1">Drag & Drop file here or <span class="text-primary link">Click here</span> for upload</div>',
      icon: {
        show: true,
        class: 'stroke-primary fill-primary-light',
        iconRef: 'assets/icon-svg/File_text_icon.svg',
        style: { 'width.rem': 3, 'height.rem': 3, 'stroke-width': .5 },
      },
    },
    footerIconText: {
      show: true,
      message: 'Download Template',
      messageClass: 'text-primary text-14-px font-weight-bold px-1',
      wrapperClass: 'd-flex align-items-center justify-content-center py-4',
      icon: {
        show: true,
        iconRef: 'assets/icon-svg/Download_icon.svg',
        class: 'stroke-primary',
        style: { 'width.rem': 1, 'height.rem': 1, 'stroke-width': 2.2 },
      },
    }
  }

  constructor() { }

  ngOnInit() {
    // this.configLocal = { ...this.configLocal, ...this.config };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('config' in changes) {
      this.configLocal = { ...this.configLocal, ...this.config, };
      console.log('FileUploadComponent config : ', this.configLocal)
    }
  }

  fireEvent(type, data) {
    // if (data.compStatus !== 'UPCOMING') {
    const dataOutput = {
      type: type,
      data: data,
      // liked : isLiked,
    };

    console.log('Fire event called : ', dataOutput);
    this.getEvent.emit(dataOutput);
    // } else {
    //   return false;
    // }
  }

  emitFileUploadEvent(type, data) {
    console.log('emitFileUploadEvent', type, data);

    this.fireEvent(type, {
      // selectedFormatDropData: this.fileTypeObject,
      fileEvent: data,
    })
  }

  onFileDropped(files) {
    console.log('fileUpload', files);
    const eventObject = {
      target: {
        files: files
      }
    };
    this.emitFileUploadEvent('fileUpload', eventObject);
  }

  downloadSample(event) {
    this.fireEvent('chooseFileDownload', event);
  }

}
