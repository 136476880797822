import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ActivityService } from '../../../../providers/activity/activity.service';
import { EventsService } from '../../../../providers/events/events.service';
import { ToasterService } from '../../../../providers/toaster/toaster.service';

@Component({
  selector: 'ngx-mark-attendance-learner',
  templateUrl: './mark-attendance-learner.component.html',
  styleUrls: ['./mark-attendance-learner.component.scss']
})
export class MarkAttendanceLearnerComponent implements OnInit, OnChanges {

  @Input() courseActivityData: any = {};
  @Input() courseModuleActivityData: any = {};
  @Input() courseDataSummaryInput: any = {};

  @Output() getEvent = new EventEmitter();

  currentUserData: any;

  markedStatus: boolean = false;
  attendanceCheckRes: any = {
    completionId: null,
    enrolId: null,
    inDate: null,
    status: null,
    trainerId: null
  };

  dependencyConatiner = {
    showActConatiner: true,
    backgroundImg: '',
    title: '',
    subTitle: '',
    msg: '',
    btnShow: true,
    btnDisabled: false,
    btnIcon: '',
    btnText: 'Mark Attendance',
  }

  loading = false;

  constructor(
    public activityService: ActivityService,
    private eventsService: EventsService,
    public toaster: ToasterService,
  ) { }

  ngOnInit(): void {
    this.getUserData();
  }

  ngOnChanges(changes: any) {
    console.log('Activity container component changes Triggered : ', changes);
    if (this.courseActivityData.acticon == 'attendance' || this.courseActivityData.actIcon == 'attendance') {
      this.prepareDependancyContainer(this.courseModuleActivityData);
      this.loading = true;
      this.getAttendanceActivityData((status, result) => {
        this.loading = false;
        if (status) {
          if (this.markedStatus) {
            this.updateMsgAfterMarked();
          } else {
            // this.dependencyConatiner['msg'] = '';
            // this.dependencyConatiner['btnDisabled'] = false;
          }
        } else {
          console.log('Unable to get attendance data at this time ...', result);
        }
      });
    }
  }

  prepareDependancyContainer(activity) {
    console.log('Preparing dependancy container...', activity);
    this.dependencyConatiner['title'] = activity.modulename;
    this.dependencyConatiner['subTitle'] = activity.activityName;
  }

  /******** get userdata start **********/
  getUserData() {
    this.currentUserData = JSON.parse(localStorage.getItem('currentUser'));
  }
  /******** get userdata end **********/

  /******** attendance activity start **********/
  getAttendanceActivityData(cb) {
    if (!this.currentUserData) {
      this.getUserData();
    }
    const params = {
      cId: this.courseActivityData.courseId,
      mId: this.courseActivityData.moduleId,
      actId: this.courseActivityData.activityId,
      // tId: this.currentUserData.tenantId,
      // empId: this.currentUserData.eid
    };
    this.activityService.getAttendanceData(params).then(res => {
      if (res.type === true) {
        console.log('Attendance Activity res', res);
        if (res.data) {
          let attCompletion = res.data.attData[0];
          if (attCompletion) {
            // this.attendanceCheckRes = res.data[0];
            this.attendanceCheckRes['status'] = attCompletion.status;
            this.attendanceCheckRes['trainerId'] = attCompletion.trainerId;
            this.attendanceCheckRes['inDate'] = attCompletion.inDate;
            this.attendanceCheckRes['completionId'] = attCompletion.completionId;
            this.attendanceCheckRes['enrolId'] = attCompletion.enrolId;
            this.attendanceCheckRes['msg'] = attCompletion.msg;
            if (attCompletion.status) {
              this.markedStatus = true;
            } else {
              this.markedStatus = false;
            }
          }
        }
        cb(true, res);
      } else {
        cb(false, res);
      }
    }, err => {
      console.log(err);
      cb(false, null);
    });
  }

  markAttendance() {
    const currentRole = this.currentUserData.roleId;
    if (currentRole == 8 || currentRole == 10) {
      // if (this.networkservice.getOnlineStatus()) {
      let activity = this.courseActivityData;
      activity.completionstatus = 'Y';

      const params = this.activityService.populateContentCompletion(activity);
      console.log('markAttendance mark attendance params : ', params);

      this.activityService.markAttendance(params).then(res => {
        // console.log('Mark Attendance Activity res', res);
        if (res.type === true) {
          if (res.data) {
            let attCompletion = res.data.attCompletion[0];
            let contCompletion = res.data.contCompletion[0];

            if (attCompletion) {
              this.attendanceCheckRes = attCompletion;
              this.markedStatus = true;
              this.updateMsgAfterMarked();

              if (this.attendanceCheckRes.status == 1) {
                this.presentToastMessage(this.attendanceCheckRes.msg, "Success!", 'success');
              } else {
                this.presentToastMessage(this.attendanceCheckRes.msg);
              }
            }

            if (contCompletion) {
              this.courseActivityData.completionstatus = contCompletion.compStatus;
              if (!this.courseActivityData.activitycompletionid) {
                this.courseActivityData.activitycompletionid = contCompletion.lastid;
              }

              const resData = {
                result: contCompletion,
                responseData: contCompletion,
                activity: this.courseActivityData
              };
              this.getEvent.emit(resData);
            }
          }
          // }
        }
      }, err => {
        console.log('markAttendance err : ', err);
        this.presentToastMessage('Unable to mark your attendance at this time. Please try again later');
      });
      // }
    }
  }

  updateMsgAfterMarked() {
    let tempMsg = 'Thank you for attending. Your attendance has been marked on ' + this.attendanceCheckRes.inDate;
    this.dependencyConatiner['msg'] = this.attendanceCheckRes.msg ? this.attendanceCheckRes.msg : tempMsg;
    this.dependencyConatiner['btnDisabled'] = true;
    this.dependencyConatiner['btnShow'] = false;
  }
  /******** attendance activity end **********/

  async presentToastMessage(msg, title = "Warning", type = "warning") {
    // const toast = await this.toastController.create({
    //   message: msg,
    //   duration: 2000,
    //   // showCloseButton: true,
    //   // position: 'top',
    //   // closeButtonText: 'Done'
    // });
    // toast.present();
    this.toaster.prsentToast(msg, title, type, null);
  }

}
