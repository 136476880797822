import { Component, OnInit, Input } from '@angular/core';
import { SearchService } from '../../providers/search/search.service';
@Component({
  selector: 'ngx-advance-filter',
  templateUrl: './advance-filter.component.html',
  styleUrls: ['./advance-filter.component.scss']
})
export class AdvanceFilterComponent implements OnInit {
  @Input() filter: any = null;

  filterTypeObject = {
    '2': 'metadata',
    '3': 'metadata',
    '1': 'id',
  };
  @Input() filterDataObject = {
    rangeArray: {

    },
    termArray: {

    },
    fieldDateRange: {
    },
  };

  constructor(private searchService: SearchService) { }

  ngOnInit(): void {
  }

  showdiv(item) {
    item['show'] = !item['show'];
  }

  applyFilterData(event, inner, item) {
    console.log('action', event);
    console.log('innerItem', inner);
    console.log('item', item);
    let key = item['filter_on'];
    let extractDataKey = this.filterTypeObject[item.filter_type];
    inner['check'] = !inner['check'];
    if (inner['check']) {
      this.checked(inner, extractDataKey, item, key);
    } else {
      this.unChecked(inner, extractDataKey, item, key);
    }
    console.log('applyFilterData', this.filterDataObject);
    this.searchService.updatedDataSelection(this.filterDataObject);
  }

  checked(inner, extractDataKey, item, key) {
    let dataToBePushed = inner[extractDataKey];
    if (String(item.filter_type) === '2') {
      // if (this.filterDataObject.rangeArray[key] &&
      //   Array.isArray(this.filterDataObject.rangeArray[key])) {
      //   this.filterDataObject.rangeArray[key].push(dataToBePushed);
      // } else {
      //   this.filterDataObject.rangeArray[key] = [dataToBePushed];
      // }
      // this.selectionDisable(item.filterValue, inner);
      // this.addSelected(this.filterDataObject.rangeArray, key, dataToBePushed);
      this.toggleSelection(item.filterValue, inner, key, extractDataKey, this.filterDataObject.rangeArray);
      // this.filterDataObject.rangeArray.push(inner);
    } else if (String(item.filter_type) === '3') {
      // if (this.filterDataObject.fieldDateRange[key] &&
      //   Array.isArray(this.filterDataObject.fieldDateRange[key])) {
      //   this.filterDataObject.fieldDateRange[key].push(dataToBePushed);
      // } else {
      //   this.filterDataObject.fieldDateRange[key] = [dataToBePushed];
      // }
      // this.selectionDisable(item.filterValue, inner);
      // this.addSelected(this.filterDataObject.fieldDateRange, key, dataToBePushed);
      this.toggleSelection(item.filterValue, inner, key, extractDataKey, this.filterDataObject.fieldDateRange);
    } else {
      // if (this.filterDataObject.termArray[key] &&
      //   Array.isArray(this.filterDataObject.termArray[key])) {
      //   this.filterDataObject.termArray[key].push(dataToBePushed);
      // } else {
      //   this.filterDataObject.termArray[key] = [dataToBePushed];
      // }
      this.addSelected(this.filterDataObject.termArray, key, dataToBePushed);
      // this.filterDataObject.termArray.push(inner);
    }
  }

  unChecked(inner, extractDataKey, item, key) {
    if (String(item.filter_type) === '2') {
      // this.removeFromSelected(inner, this.filterDataObject.rangeArray[key], extractDataKey);
      // if (this.filterDataObject.rangeArray[key].length == 0) {
      //   delete this.filterDataObject.rangeArray[key];
      // }
      // this.selectionDisable(item.filterValue, inner);
      this.removeSelected(inner, this.filterDataObject.rangeArray, key, extractDataKey);
    } else if (String(item.filter_type) === '3') {
      // this.removeFromSelected(inner, this.filterDataObject.fieldDateRange[key], extractDataKey);
      // if (this.filterDataObject.fieldDateRange[key].length == 0) {
      //   delete this.filterDataObject.fieldDateRange[key];
      // }
      // this.selectionDisable(item.filterValue, inner);
      this.removeSelected(inner, this.filterDataObject.fieldDateRange, key, extractDataKey);
    } else {
      // this.removeFromSelected(inner, this.filterDataObject.termArray[key], extractDataKey);
      // if (this.filterDataObject.termArray[key].length == 0) {
      //   delete this.filterDataObject.termArray[key];
      // }
      this.removeSelected(inner, this.filterDataObject.termArray, key, extractDataKey);
    }
  }

  // applyFilterData(event, inner, item) {
  //   console.log('action', event);
  //   console.log('innerItem', inner);
  //   console.log('item', item);
  //   let key = item['filterKey'];
  //   let extractDataKey = this.filterTypeObject[item.filter_type];
  //   let dataToBePushed = inner[extractDataKey];
  //   if (event) {
  //     if (String(item.filter_type) === '2') {

  //     } else {


  //     }
  //   } else {
  //     if (String(item.filter_type) === '2') {
  //       this.removeFromSelected(item, this.filterDataObject.rangeArray[key], extractDataKey);
  //     } else {
  //       this.removeFromSelected(item, this.filterDataObject.termArray[key], extractDataKey);
  //     }
  //   }
  //   console.log('applyFilterData', this.filterDataObject);
  // }
  removeFromSelected(item, arr, property) {
    if (arr.length > 0) {
      this.removeByAttr(arr, property, item[property]);
    } else {
      console.log("Selected array is empty");
    }
  }

  removeByAttr(arr, attr, value) {
    let i = arr.length;
    while (i--) {
      if (
        // arr[i] === value
        JSON.stringify(arr[i]) === JSON.stringify(value)
      ) {
        arr.splice(i, 1);
      }
    }
    return arr;
  }

  clearData() {
    this.filterDataObject = {
      rangeArray: {

      },
      termArray: {

      },
      fieldDateRange: {

      }
    }
    this.searchService.updatedDataSelection(this.filterDataObject);
  }

  selectionDisable(arr, item) {
    arr.forEach(x => {
      if (x.id !== item.id) {
        x.isDisabled = x.isDisabled ? !x.isDisabled : !x.check;
      }
    });
  }

  toggleSelection(arr, item, key, extractDataKey, filterArr) {
    arr.forEach(x => {
      if (x.id !== item.id) {
        x.check = false;
        if (filterArr[key] && filterArr[key].length > 0) {
          this.removeSelected(x, filterArr, key, extractDataKey);
        }
      }
    });
    let dataToBePushed = item[extractDataKey];
    this.addSelected(filterArr, key, dataToBePushed);
  }

  addSelected(filterArr, key, dataToBePushed) {
    if (filterArr[key] && Array.isArray(filterArr[key])) {
      filterArr[key].push(dataToBePushed);
    } else {
      filterArr[key] = [dataToBePushed];
    }
  }

  removeSelected(selValue, filterArr, key, extractDataKey) {
    this.removeFromSelected(selValue, filterArr[key], extractDataKey);
    if (filterArr[key] && Array.isArray(filterArr[key]) && filterArr[key].length == 0) {
      delete filterArr[key];
    } else {
      console.log('Not an array...', filterArr[key]);
    }
  }

}
