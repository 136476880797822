import { Injectable } from "@angular/core";
import { webApi } from "../../../config";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { CryptoService } from "../../modules/shared/providers/utils/crypto/crypto.service";
@Injectable({
  providedIn: "root",
})
export class ScromService {
  baseUrl: any = webApi.baseUrl;
  // getScormListUrl: any = this.baseUrl + webApi.apiUrl.getScormList;
  // saveScormTracklisturl: any = this.baseUrl + webApi.apiUrl.saveScormTrackList;
  viewScormPackageUrl: any = this.baseUrl + webApi.apiUrl.viewScormPackage;
  getScormPackageUrl: any = this.baseUrl + webApi.apiUrl.getScormPackage;
  deleteScormPackageUrl: any = this.baseUrl + webApi.apiUrl.deleteScormPackage;
  private headers = new HttpHeaders();

  constructor(
    private http: HttpClient,
    private cryptoService: CryptoService) {}

  // getScormList(data: any): Promise<any> {
  //   // const url = this.getScormListUrl;
  //   return this.http
  //     .post(this.getScormListUrl, data, { headers: this.headers })
  //     .toPromise()
  //     .then((response) => {
  //       return (response = response);
  //     })
  //     .catch(this.handleError);
  // }
  viewScormPackage(data: any): Promise<any> {
    const url = this.getScormPackageUrl;
    // const url = this.getScormPackageUrl + '?id=' + data.id + '&key=' + data.key + '&extension=' + data.extension + '&eid=' + data.eid;
    return this.http
      .post(url, data, { headers: this.headers })
      .toPromise()
      .then((response) => {
        return (response = response);
      })
      .catch(this.handleError);
  }
  deleteScormPackage(id: any): Promise<any> {
    // const url = this.getScormListUrl;
    const url = this.deleteScormPackageUrl + "?id=" + id;
    return this.http
      .get(url, { headers: this.headers })
      .toPromise()
      .then((response) => {
        return (response = response);
      })
      .catch(this.handleError);
  }

  // saveScormTrackList(list): Promise<any> {
  //   return this.http
  //     .post(this.saveScormTracklisturl, list, { headers: this.headers })
  //     .toPromise()
  //     .then((response) => {
  //       return (response = response);
  //     })
  //     .catch(this.handleError);
  // }

  getManifesturl(result, basicdata) {
    return (
      webApi.baseUrl +
      webApi.apiUrl.viewScormPackage +
      "?id=" +
      result.package +
      "&" +
      "scormId=" +
      result.scormId +
      "&" +
      "scorm=" +
      this.getEncryptedData(basicdata) +
      ""
    );
  }

  getEncryptedData(data) {
    console.log('tempdata : ', data);
    const tempData = JSON.stringify(data);
    // return this.encryptMd5(tempData);
    const aesEncData = this.encryptMd5(this.cryptoService.encryptData(tempData, null));
    return aesEncData;
    // console.log('Encrypted data : ', tempData, aesEncData);
    // const aesDecData = this.cryptoService.decryptData(aesEncData, null);
    // const decData = JSON.parse(atob(aesDecData));
    // console.log('Decrypted data : ', aesDecData, decData);
  }

  encryptMd5(tempData) {
    const encData = btoa(tempData);
    return encData;
  }
  private handleError(error: Response | any) {
    return Promise.reject(error.message || error);
  }
}
