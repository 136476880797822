import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContentOptionService } from '../../../../providers/content-option/content-option.service';
// import { ContentCardList } from '../../interfaces/content-card.model';
import { ContentCardList } from '../../../shared/interfaces/content-card.model';
import { SharedService } from '../../../shared/providers/shared.service';

@Component({
  selector: 'ngx-content-card',
  templateUrl: './content-card.component.html',
  styleUrls: ['./content-card.component.scss']
})
export class ContentCardComponent implements OnInit {

  @Input() config: ContentCardList = {
    image: 'instanceThumbnail',
    showType: true,
    type: 'instanceType',
    typeId: 'instanceTypeId',
    typeIcon: 'instanceTypeIconApp',
    showSubType: true,
    subType: 'instanceSubType',
    subTypeId: 'instanceSubTypeId',
    subTypeIcon: 'instanceSubTypeIconApp',
    title: 'instanceName',
    showDesc: false,
    desc: '',
    // showDate: false,
    // startDate: 'validFromDate',
    // endDate: 'validToDate',
    // date: '',
    showRating: 'showRating',
    rating: 'rating',
    ratingOutOf: 'outof',
    showProgress: 'isprogress',
    prrogressInPerc: 'instanceProgress',
    showExtraParams: 'showExtraParams',
    // paramsArray: [],
    paramsArray: 'extraParams',
    params: {
      value: 'typeValue',
      text: 'typetext',
      icon: 'typeIcon'
    },
    // showButton: true,
    // buttonText: 'Enrol',
    showButton: 'showButtons',
    // buttonsArray: [],
    buttonsArray: 'buttons',
    buttons: {
      action: 'action',
      text: 'text',
      class: 'class'
    },
    showOptions: 'isMoreEnable',
    compStatus: 'compStatus',
    compStatusId: 'compStatusId',
    compStatusMsg: 'compStatusMsg',
    languageEnabled: false,
    languageName: 'language_name'
  };

  // @Input() config: ContentCardList = {
  //   image: 'instanceThumbnail',
  //   title: 'instanceName',
  //   showType: true,
  //   type: 'instanceType',
  //   typeIcon: 'instanceTypeIconApp',
  //   typeId: 'instanceTypeId',
  //   startDate: 'validFromDate',
  //   endDate: 'validToDate',
  //   showDesc: false,
  //   desc: '',
  //   showDate: false,
  //   date: '',
  //   // showButton: false,
  //   // buttonText: '',
  //   rating: 'rating',
  //   ratingOutOf: 'outof',
  // };
  @Input() data: any = [];

  @Output() getEvent = new EventEmitter();

  buttons = [];

  constructor(
    public contentOptionService: ContentOptionService,
    private sharedService: SharedService
  ) { }

  ngOnInit() { }

  ngOnChanges(changes: any) {
    // console.log('Content card component changed : ', changes);
    if (changes && changes.data) {
      const options = this.contentOptionService.makeButtonsReady(this.data);
      // const buttons = []
      this.buttons = [];
      // console.log('options', options)
      for (let opt of options) {
        if (opt.isVisible) {
          // let button = {
          //   text: opt.text,
          //   cssClass: opt.cssClass,
          //   icon: opt.icon,
          // }
          this.buttons.push(opt);
        }
      }
      // console.log('Content card options : ', this.buttons);
    }
  }

  bindBackgroundImage(img) {
    if (img === null || img === '' || img === 'assets/images/courseicon.jpg') {
      return 'assets/images/activity1.jpg';
    } else {
      return img;
    }
  }

  fireEvent(event, e_data, b_data?: any) {
    // if (data.compStatus !== 'UPCOMING') {
    const dataOutput = {
      type: event,
      // data: e_data,
      cardData: e_data,
      buttonData: b_data
    };
    this.getEvent.emit(dataOutput);
    // } else {
    //   return false;
    // }
  }

  mouseEnter(item) {
    item.show = true;
  }

  mouseLeave(item) {
    item.show = false;
  }

  buttonClicked(button, data) {
    console.log('Button clicked...', button);
    if (button.action) {
      this.fireEvent(button.action, data, button);
    } else {
      console.log('Button action not available...');
    }
  }

  optionClicked(event, data) {
    if (data.buttons) {
      const button = this.sharedService.getObjFromArray(data.buttons, event.id, 'action');
      if (button) {
        this.buttonClicked(button, data);
      } else {
        // this.fireEvent(event.id, data, null);
        this.fireEvent(event.id, event.value);
      }
    } else {
      // this.fireEvent(event.id, data, null);
      this.fireEvent(event.id, event.value);
    }
  }
}
