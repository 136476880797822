import { AfterViewInit, CUSTOM_ELEMENTS_SCHEMA, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { register } from 'swiper/element/bundle';

import { Swiper } from 'swiper';
import { SwiperOptions } from 'swiper/types/swiper-options';

// // @ts-ignore
// import { Navigation, Pagination, Scrollbar } from 'swiper/modules';

@Component({
  selector: 'ngx-swiper',
  templateUrl: './swiper.component.html',
  styleUrls: ['./swiper.component.scss'],
  // schemas: [CUSTOM_ELEMENTS_SCHEMA]

})
export class SwiperComponent implements OnInit, AfterViewInit, OnChanges {

  @Input('banners') banners: any;
  @Input('bannerPopupOpened') bannerPopupOpened: any;
  @Output() getEvents = new EventEmitter();

  @ViewChild('swiperRef') swiperRef: ElementRef | undefined;
  // @Output() getEvents = new EventEmitter();

  swiper?: Swiper;

  // http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4

  bannersList = [
    {
      id: 1,
      portalSrc: 'assets/banner_image (1).jpg',
      appSrc: 'https://bflillumeproduction.s3.ap-south-1.amazonaws.com/Illume-Support-on-HR-Care-Migration.jpg',
      outputType: 1,
      messageType: 1,
      messageContent: 'https://www.google.com',
      messageMedia: null,
      messageMediaType: null,
      messageSubType: 1,
    },
    {
      id: 2,
      portalSrc: 'assets/vide_test.mp4',
      appSrc: 'assets/vide_test.mp4',
      outputType: 2,
      messageType: null,
      messageContent: null,
      messageMedia: null,
      messageMediaType: null,
      messageSubType: null,
    },
    {
      id: 3,
      portalSrc: 'assets/banner_image (2).jpg',
      appSrc: 'https://images.unsplash.com/photo-1498050108023-c5249f4df085',
      outputType: 1,
      messageType: 1,
      messageContent: 'https://www.google.com',
      messageMedia: null,
      messageMediaType: null,
      messageSubType: 2,
    },
    {
      id: 4,
      portalSrc: 'assets/vide_test.mp4',
      appSrc: 'assets/vide_test.mp4',
      outputType: 2,
      messageType: null,
      messageContent: null,
      messageMedia: null,
      messageMediaType: null,
      messageSubType: null,
    },
    {
      id: 5,
      portalSrc: 'assets/banner_image (3).jpg',
      appSrc: 'https://bflillumeproduction.s3.ap-south-1.amazonaws.com/Illume-Support-on-HR-Care-Migration.jpg',
      outputType: 1,
      messageType: 2,
      messageContent: '<p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Perspiciatis, dolorem in. Exercitationem dicta, porroquaerat tempora consectetur dolorem dolorum optio eveniet in sapiente nam sequi deserunt error voluptate temporibus delectus.</p>',
      messageMedia: 'https://images.pexels.com/photos/4144144/pexels-photo-4144144.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
      messageMediaType: 1,
      messageSubType: null,
    },
    {
      id: 6,
      portalSrc: 'assets/vide_test.mp4',
      appSrc: 'assets/vide_test.mp4',
      outputType: 2,
      messageType: null,
      messageContent: null,
      messageMedia: null,
      messageMediaType: null,
      messageSubType: null,
    },
    {
      id: 7,
      portalSrc: 'assets/banner_image (4).jpg',
      appSrc: 'https://images.unsplash.com/photo-1580927752452-89d86da3fa0a',
      outputType: 1,
      messageType: 2,
      messageContent: '<p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Perspiciatis, dolorem in. Exercitationem dicta, porroquaerat tempora consectetur dolorem dolorum optio eveniet in sapiente nam sequi deserunt error voluptate temporibus delectus.</p>',
      messageMedia: 'assets/vide_test.mp4',
      messageMediaType: 2,
      messageSubType: null,
    },
    {
      id: 8,
      portalSrc: 'assets/vide_test.mp4',
      appSrc: 'assets/vide_test.mp4',
      outputType: 2,
      messageType: null,
      messageContent: null,
      messageMedia: null,
      messageMediaType: null,
      messageSubType: null,
    },
  ];

  // swiper parameters
  // swiperParams = {
  //   // modules: [Navigation],

  //   mousewheel: {
  //     invert: true,
  //   },
  //   effect: 'fade',
  //   slidesPerView: 1,
  //   spaceBetween: 10,
  //   breakpoints: {
  //     640: {
  //       slidesPerView: 2,
  //     },
  //     1024: {
  //       slidesPerView: 1,
  //     },
  //   },
  //   autoplay: {
  //     delay: 4000,
  //   },
  //   longSwipesRatio: .1,
  //   loop: true,
  //   navigation: true,
  //   // effect: 'fade',
  //   // fadeEffect: {
  //   //   crossFade: true
  //   // },
  //   // navigation: {
  //   //   nextEl: '.swiper-button-next',
  //   //   prevEl: '.swiper-button-prev',
  //   // },
  //   injectStyles: [
  //     `
  //       .swiper-button-next,
  //       .swiper-button-prev {
  //         background-color: white;
  //         padding: 8px 16px;
  //         border-radius: 100%;
  //         border: 2px solid black;
  //         color: red;
  //       }
  //       .swiper-pagination-bullet{
  //         width: 40px;
  //         height: 40px;
  //         background-color: red;
  //       }
  //   `,
  //   ],

  //   on: {
  //     init() {
  //       console.log('Swiper inittialized...');
  //     },
  //   },
  // };


  // ===================================================

  public config: SwiperOptions = {
    // effect: 'creative',
    // creativeEffect: {
    //   prev: {
    //     // will set `translateZ(-400px)` on previous slides
    //     translate: [0, 0, -400],

    //   },
    //   next: {
    //     // will set `translateX(100%)` on next slides
    //     translate: ['100%', 0, 0],
    //   },
    // },

    // freeMode: {
    //   enabled: true,
    //   sticky: true,
    // },

    // mousewheel: {
    //   invert: true,
    // },

    longSwipesRatio: .1,
    // loop: true,

    pagination: {
      el: '.swiper-pagination',
      type: 'fraction',
      // type: 'bullets',
    },
    autoplay: {
      delay: 4000,
    },
    slidesPerView: 1,
    spaceBetween: 25,
    // breakpoints: {
    //   320: {
    //     slidesPerView: 1.5,
    //   },
    //   768: {
    //     slidesPerView: 2.5,
    //   },
    //   1280: {
    //     slidesPerView: 1,
    //   }
    // },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    scrollbar: {
      el: '.swiper-scrollbar',
      enabled: false,
      draggable: true
    },
    // followFinger: false
    loop: true,
    // speed: 4000,
    // // loopedSlides: 2
    // observer: true,
    // observeParents: true,
    autoHeight: false,
    setWrapperSize: true,
    watchOverflow: true
  }

  currentVideo: any;

  muteUnmuteStatus: any;

  pressAndHoldStatus = false;

  displayError: any;

  constructor() { }

  ngOnInit(): void {
    // this.banners = this.bannersList;
  }

  ngAfterViewInit(): void {
    // register();

    // setTimeout(() => {
    //   this.initSwiper();
    // }, 100);
    this.initSwiperNew();
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('SwiperComponent : ', changes);
    if ('banners' in changes) {
      if (Array.isArray(this.banners) && this.banners.length > 0) {
        this.updateSwiper();
      } else {
        // this.banners = this.bannersList;
      }
    }
    if ('bannerPopupOpened' in changes) {
      if (this.bannerPopupOpened == false && this.swiper) {
        this.startAutoplay();
      }
    }
  }

  updateSwiper() {
    if (this.swiper) {
      this.swiper.update();
    }
  }

  initSwiperNew() {
    setTimeout(() => {
      let swiperEl = this.swiperRef?.nativeElement;
      let swiper = swiperEl.swiper;
      if (swiper) {

        this.swiperReady();

        swiper.on('init', function (val) {
          console.log('swiper init', val);
        })

        swiper.autoplay.start();

        let _this = this;

        swiper.on('activeIndexChange', function () {
          console.log('activeIndexChange changed', swiper.realIndex);
          // if (swiper.realIndex == 3) {
          //   swiper.autoplay.stop();
          // }
        });

        swiper.on('click', function (val) {
          console.log('slide click', val);
          _this.swiperSlideTapped(val);
        })

        swiper.on('slideChange', function (val) {
          console.log('slide changed', swiper.realIndex);
          _this.swiperSlideChanged(val);
        });

        swiper.on('touchStart', function (val) {
          console.log('slide touch started', val);
          // swiper.autoplay.pause();
          _this.swiperSlidertouchStarted(val);
        });

        swiper.on('touchEnd', function (val) {
          console.log('slide touch released', val);
          // swiper.autoplay.resume();
          _this.swiperSlidertouchReleased(val);
        });
      }
    }, 100);
  }

  // initSwiper() {
  //   // swiper element
  //   let swiperEl: any = document.querySelector('swiper-container');

  //   // now we need to assign all parameters to Swiper element
  //   Object.assign(swiperEl, this.swiperParams);

  //   // and now initialize it
  //   swiperEl.initialize();

  //   setTimeout(() => {
  //     let swiper = swiperEl.swiper;
  //     if (swiper) {
  //       swiper.autoplay.start();

  //       swiper.on('activeIndexChange', function () {
  //         console.log('activeIndexChange changed', swiper.realIndex);
  //       });

  //       swiper.on('slideChange', function () {
  //         console.log('slide changed', swiper.realIndex);
  //       });
  //     }
  //   }, 100);

  // }

  swiperElement() {
    // this.getEvents.emit();
  }

  // swiper 9 start 
  swiperReady() {
    this.swiper = this.swiperRef?.nativeElement.swiper;
    // let swiperEl = this.swiperRef?.nativeElement;
    // this.swiper = swiperEl.swiper;
    console.log('swiperReady event : ', this.swiper);
    if (this.swiper) {
      console.log('Swiper initialized...');
      // if (this.swiper.autoplay) {
      //   this.swiper.autoplay.start();
      // }

      setTimeout(() => {
        this.swiperSlideChanged(null);
      }, 100);
    }
  }

  removeVideoEventListner(currentVideo) {
    if (currentVideo) {
      // currentVideo.removeEventListener('ended', this.performActionOnListner, true);
      currentVideo.removeAllListeners('ended');
      // currentVideo.replaceWith(currentVideo.cloneNode(true));
    }
  }

  performActionOnListner(videoId) {
    console.log("The video has ended : ", videoId);
    this.removeVideoEventListner(this.currentVideo);
    if (!this.pressAndHoldStatus) {
      this.startAutoplay();
    }
  }

  swiperSlideChanged(e: any) {
    // console.log('swiperSlideChanged : ', e);
    // console.log('Current active index is : ', this.swiper.activeIndex);

    this.displayError = false;
    if (this.swiper) {
      // let currentBanner = this.banners[this.swiper.activeIndex];
      let currentBanner = this.banners[this.swiper.realIndex];

      if (this.currentVideo) {
        this.pauseVid(this.currentVideo);
        this.currentVideo = null;
      }

      setTimeout(() => {
        if (currentBanner && currentBanner.outputType == 2) {
          this.stopAutoplay();
          let videoId = 'video_' + currentBanner.id;
          this.currentVideo = document.getElementById(videoId);

          this.currentVideo.addEventListener('ended', (event) => {
            this.performActionOnListner(videoId);
          }, true);

          // this.currentVideo.addEventListener('ended', () => {
          //   // console.log("The video has ended : ", videoId);
          //   if (!this.pressAndHoldStatus) {
          //     this.startAutoplay();
          //   }
          // })
          // // vid.onended = function () {
          // //   console.log("The video has ended");
          // // };

          this.playVid(this.currentVideo);
        } else {
          if (!this.pressAndHoldStatus) {
            this.startAutoplay();
          }
        }
      }, 50);
    }
  }

  getCurrentBanner() {
    try {
      // let currentBanner = this.banners[this.swiper.activeIndex];
      let currentBanner = this.banners[this.swiper.realIndex];
      return currentBanner;
    } catch (error) {
      console.log('getCurrentBanner error : ', error);
      return null;
    }
  }

  swiperSlideTapped(e: any) {
    console.log('swiperSlideTapped : ', e);
    // this.stopAutoplay();
    // this.getEvents.emit();
    let banner = this.getCurrentBanner();
    if (banner) {
      if (banner.outputType == 1 && banner.messageType == 2) {
        this.stopAutoplay();
      }
      this.fireEvent('bannerClicked', banner);
    }
  }

  swiperSliderMoved(e: any) {
    // console.log('swiperSliderMoved : ', e);
  }

  swiperSlidDoubleTapped(e: any) {
    // console.log('swiperSlidDoubleTapped : ', e);
  }

  swiperActiveIndexChanged(e: any) {
    // console.log('swiperActiveIndexChanged : ', e);
  }

  goNext() {
    if (this.swiper) {
      this.swiper.slideNext();
    }
  }

  goPrev() {
    if (this.swiper) {
      this.swiper.slidePrev();
    }
  }

  startAutoplay() {
    if (!this.swiper.autoplay.running && !this.bannerPopupOpened) {
      this.swiper.autoplay.start();
    }
  }

  stopAutoplay() {
    if (this.swiper.autoplay.running) {
      this.swiper.autoplay.stop();
    }
  }

  pauseAutoplay() {
    if (!this.swiper.autoplay.paused) {
      this.swiper.autoplay.pause();
    }
  }

  resumeAutoplay() {
    if (this.swiper.autoplay.paused) {
      this.swiper.autoplay.resume();
    }
  }

  playVid(vid) {
    if (vid.readyState !== 4) {
      vid.load();
    }
    vid.muted = true;
    vid.currentTime = 0;
    vid.play();
  }

  pauseVid(vid) {
    if (!vid.paused) {
      vid.pause();
      // vid.currentTime = 0;
    }
  }

  muteUnmute() {
    console.log('muteUnmute');
    if (this.currentVideo) {
      this.currentVideo.muted = !this.currentVideo.muted;
    }
    // this.muteUnmuteStatus = !this.muteUnmuteStatus;
  }

  swiperSlidertouchStarted(e: any) {
    console.log('swiperSlidertouchStarted : ', e);
    this.pressAndHoldStatus = true;

    // this.pauseAutoplay();
    this.stopAutoplay();

    // let currentBanner = this.banners[this.swiper.activeIndex];
    let currentBanner = this.banners[this.swiper.realIndex];
    if (currentBanner) {
      let bannerElemId = '';
      if (currentBanner.outputType == 2) {
        bannerElemId = 'video_' + currentBanner.id;
      }
      if (currentBanner.outputType == 1) {
        bannerElemId = 'image_' + currentBanner.id;
      }
      // setTimeout(() => {
      const bannerElem = document.getElementById(bannerElemId);
      this.preventLongPressMenu(bannerElem, e);
      // }, 250);
    }
  }

  swiperSlidertouchReleased(e: any) {
    console.log('swiperSlidertouchReleased : ', e);
    this.pressAndHoldStatus = false;

    // this.resumeAutoplay();
    this.startAutoplay();
  }

  /************* Disable context menu start ************/
  // Approach 1
  disableRightClick(elem = null) {
    if (elem) {
      elem.addEventListener("contextmenu", (event) => {
        event.preventDefault();
        event.stopPropagation();
        return false;
      });
    } else {
      document.addEventListener("contextmenu", (event) => {
        event.preventDefault();
        event.stopPropagation();
        return false;
      });
    }
    // this.preventLongPressMenu(document.getElementById('theimage'));
  }

  // Approach 2
  absorbEvent_(event) {
    var e = event || window.event;
    e.preventDefault && e.preventDefault();
    e.stopPropagation && e.stopPropagation();
    e.cancelBubble = true;
    e.returnValue = false;
    return false;
  }

  preventLongPressMenu(node, event) {
    node.ontouchstart = this.absorbEvent_(event);
    node.ontouchmove = this.absorbEvent_(event);
    node.ontouchend = this.absorbEvent_(event);
    node.ontouchcancel = this.absorbEvent_(event);
  }

  // Approach 3 - working
  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }
  /************* Disable context menu end ************/

  fireEvent(inEvent, inData) {
    const res = {
      type: inEvent,
      data: inData,
    };
    this.getEvents.emit(res);
  }

  /************* Media error handling start ************/
  errorMessage(e) {
    console.log('errorMessage : ', e);
    // this.notFound = true;
    this.displayError = true;
    this.errorMessageVideo(e);
  }

  errorMessageVideo(e) {
    // video playback failed - show a message saying why
    switch (e.target.error.code) {
      case e.target.error.MEDIA_ERR_ABORTED:
        console.log('You aborted the video playback.');
        break;
      case e.target.error.MEDIA_ERR_NETWORK:
        console.log('A network error caused the video download to fail part-way.');
        break;
      case e.target.error.MEDIA_ERR_DECODE:
        console.log('The video playback was aborted due to a corruption problem or because the video used features your browser did not support.');
        break;
      case e.target.error.MEDIA_ERR_SRC_NOT_SUPPORTED:
        console.log('The video could not be loaded, either because the server or network failed or because the format is not supported.');
        break;
      default:
        console.log('An unknown error occurred.');
        break;
    }
  }
  /************* Media error handling end ************/
}
