import {
  Component,
  OnInit,
  Input,
  Output,
  ViewChild,
  ElementRef,
  EventEmitter,
} from "@angular/core";

@Component({
  selector: "ngx-preloader-img",
  templateUrl: "./preloader-img.component.html",
  styleUrls: ["./preloader-img.component.scss"],
})
export class PreloaderImgComponent implements OnInit {
  viewImage = false;

  @Input() url: string;
  @Output() saveEvent = new EventEmitter<any>();
  // @Output() openImage = new EventEmitter<any>();
  @Output() loadError = new EventEmitter<any>();
  @ViewChild("image", { static: true }) imageRef: ElementRef;

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: any) {
    console.log("Image viewer component changes Triggered : ", changes);
  }

  ngAfterViewInit() {
    console.log("image", this.imageRef);
    // this.imageRef.src = this.url;
  }

  loadImage(event) {
    // if (event && event.target) {
    //   const x = event.srcElement.x;
    //   const y = event.srcElement.y;
    //   if ((x !== 0) && (y !== 0)) {
    //     const width = event.srcElement.width;
    //     const height = event.srcElement.height;
    //     const portrait = height > width ? true : false;
    //     console.log('Loaded: ', width, height, 'portrait: ', portrait);
    //   }
    // }
    console.log("loadImage event", event);
    this.viewImage = true;
    this.saveEvent.emit();
  }

  errorLoad() {
    this.viewImage = true;
    this.loadError.emit();
    // this.imageError.flag = true;
    // this.imageRef.src = '<your_default_img>';
  }

  // onImageClicked(event) {
  //   this.openImage.emit(this.url);
  // }
}
