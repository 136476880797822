<div class="quizContainer pt-3" [hidden]="!showScore">
  <div class="asses-head mx-3">
    <div class="p-2">
      <h3 class="m-0">Quiz Assessment for {{ courseTitle }}</h3>
    </div>
    <div class="question_number">
      <h1 class="question_No">
        {{ currentQuestionIndex + 1 }} / {{ quiz.length }}
      </h1>
    </div>
  </div>
  <div class="review-question p-3">
    <div class="matcard">
      <!-- [ngClass]="{'disableQuestion': reviewFlag || quiz[currentQuestionIndex].answerConfirmed}" -->
      <div class="marginbottom" *ngFor="let Ques of quiz[currentQuestionIndex].list; let i = index">
        <div class="width100" *ngIf="Ques.qTypeId == 1">
          <div class="width100">
            <div *ngIf="Ques.questionTypeId == 1">
              <h5 class="questionSName text-gray">{{ Ques.sName }}</h5>
              <h3 class="question" [innerHtml]="makequizQuestionReady(Ques, 'question', i + 1)">
                <!-- {{ Ques.qText }}
                              <span class="mandatoryQues" *ngIf="Ques.isMandatory == 1">&nbsp;*</span> -->
              </h3>
            </div>

            <div *ngIf="Ques.questionTypeId == 2">
              <h5 class="questionSName text-gray">{{ Ques.sName }}</h5>
              <div class="d-flex w-10-rem h-6-rem">
                <img class="w-100 rounded border" [src]="Ques.contentRef" />
                <span tooltip="Open Image In Full Screen Mode" flow="up">
                  <i class="fas fa-external-link-alt open_image_full_icon ml-2" aria-hidden="true"
                    (click)="openInFullScreen(Ques.contentRef)"></i>
                </span>
              </div>
            </div>

            <p class="questionInstructions mb-3" *ngIf="Ques.instructions">
              <span class="questionInstructionsLabel"> Instructions : </span>
              <!-- <span class="questionInstructionsData" [innerHtml]="makequizQuestionReady(Ques.instructions,'instructions')"></span> -->
              <span class="questionInstructionsData">
                {{ Ques.instructions | removeHtml: 92 }}
                <span class="read_more_feedback" *ngIf="(Ques.instructions | removeHtml: 92)?.length >= 90"
                  (click)="readfb(Ques.instructions)">
                  Read More
                </span>
              </span>
            </p>

            <div class="answer">
              <div *ngIf="!reviewFlag">
                <div class="multiple_choice_question_container">
                  <div class="" *ngFor="let options of Ques.optionList; let j = index">
                    <button *ngIf="options.qOptionType == '1'" (click)="selectOption(i, Ques, j, options)"
                      class="disableQuestion number_parent_container" [ngClass]="{
                        options: !options.sFlag,
                        activeCorrect: options.sFlag && options.cFlag == 'true',
                        activeWrong: options.sFlag && options.cFlag == 'false'
                      }">
                      <div class="number_container">{{ j + 1 }} ) &nbsp;</div>
                      <div [innerHtml]="
                          makequizQuestionReady(options.qOption, 'qOption')
                        "></div>
                      <!-- {{ options.qOption }} -->
                    </button>
                    <div class="text-right" *ngIf="options.qOptionType == '2'">
                      <span tooltip="Open Image In Full Screen Mode" flow="up">
                        <i class="fas fa-external-link-alt open_image_full_icon" aria-hidden="true"
                          (click)="openInFullScreen(options.qOptionRef)"></i>
                      </span>
                    </div>
                    <button *ngIf="options.qOptionType == '2'" class="disableQuestion option_img_button"
                      (click)="selectOption(i, Ques, j, options)" [ngClass]="{
                        options: !options.sFlag,
                        activeCorrect:
                          options.sFlag && options.cFlag === 'true',
                        activeWrong: options.sFlag && options.cFlag === 'false'
                      }">
                      <div class="number_container">{{ j + 1 }} ) &nbsp;</div>
                      <!-- <div [innerHtml]="makequizQuestionReady(options,'qOption')"></div> -->
                      <img class="optionsImg" [src]="options.qOptionRef" />
                    </button>
                    <div class="questionFeedback" *ngIf="
                        options.sFlag &&
                        options.feedback &&
                        Ques.answerConfirmed
                      ">
                      <p class="questionInstructions">
                        <span class="questionInstructionsLabel">
                          Feedback :
                        </span>
                        <span class="questionInstructionsData">{{ options.feedback | removeHtml: 92 }}
                          <span class="read_more_feedback" *ngIf="options.feedback | removeHtml: 92"
                            (click)="readfb(options.feedback)">
                            Read More
                          </span>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="reviewFlag">
                <div class="multiple_choice_question_container">
                  <div *ngFor="let options of Ques.optionList; let j = index">
                    <button *ngIf="options.qOptionType == '1'" class="options disableQuestion" [ngClass]="{
                        options: !options.sFlag,
                        activeCorrect:
                          options.sFlag && options.cFlag === 'true',
                        activeWrong: options.sFlag && options.cFlag === 'false'
                      }">
                      <div class="number_container">{{ j + 1 }} ) &nbsp;</div>
                      <div [innerHtml]="
                          makequizQuestionReady(options.qOption, 'qOption')
                        "></div>
                      <!-- {{ options.qOption }} -->
                    </button>
                    <div class="text-right" *ngIf="options.qOptionType == '2'">
                      <span tooltip="Open Image In Full Screen Mode" flow="up">
                        <i class="fas fa-external-link-alt open_image_full_icon" aria-hidden="true"
                          (click)="openInFullScreen(options.qOptionRef)"></i>
                      </span>
                    </div>
                    <button *ngIf="options.qOptionType == '2'" class="options option_img_button disableQuestion"
                      [ngClass]="{
                        options: !options.sFlag,
                        activeCorrect: options.sFlag && options.cFlag == 'true',
                        activeWrong: options.sFlag && options.cFlag == 'false'
                      }">
                      <div class="number_container">{{ j + 1 }} ) &nbsp;</div>
                      <!-- <div [innerHtml]="makequizQuestionReady(options.qOption ,'qOption')"></div> -->
                      <img class="optionsImg" [src]="options.qOptionRef" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="width100" *ngIf="Ques.qTypeId == 2">
          <div class="width100">
            <div *ngIf="Ques.questionTypeId == 1">
              <h5 class="questionSName text-gray">{{ Ques.sName }}</h5>
              <h3 class="question" [innerHtml]="makequizQuestionReady(Ques, 'question', i + 1)">
              </h3>
            </div>

            <div *ngIf="Ques.questionTypeId == 2">
              <h5 class="questionSName text-gray">{{ Ques.sName }}</h5>
              <div class="d-flex w-10-rem h-6-rem">
                <img class="w-100 rounded border" [src]="Ques.contentRef" />
                <span tooltip="Open Image In Full Screen Mode" flow="up">
                  <i class="fas fa-external-link-alt open_image_full_icon ml-2" aria-hidden="true"
                    (click)="openInFullScreen(Ques.contentRef)"></i>
                </span>
              </div>
            </div>

            <div class="answer" [ngClass]="{ disableQuestion: Ques.Answered == 1 }">
              <div *ngIf="!reviewFlag">
                <div class="select_drop_list_container" *ngFor="let options of Ques.optionList; let j = index">
                  <div class="">
                    <button *ngIf="options.optionType == '1'" class="options minH disableQuestion" [ngClass]="{
                        activeCorrect: options.cFlag === 'true',
                        activeWrong: options.cFlag === 'false'
                      }">
                      <div [innerHTML]="options.optionText"></div>
                    </button>
                    <div class="text-right" *ngIf="options.optionType == '2'">
                      <span tooltip="Open Image In Full Screen Mode" flow="up">
                        <i class="fas fa-external-link-alt open_image_full_icon" aria-hidden="true"
                          (click)="openInFullScreen(options.optionRef)"></i>
                      </span>
                    </div>
                    <button *ngIf="options.optionType == '2'" class="options option_img_button minH" [ngClass]="{
                        activeCorrect: options.cFlag === 'true',
                        activeWrong: options.cFlag === 'false'
                      }">
                      <img class="optionsImg" [src]="options.optionRef" />
                    </button>
                  </div>
                  <div class="dropable" id="droptarget{{ i }}{{ j }}" (dragover)="allowDrop($event)"
                    (drop)="drop($event, Ques, i, 0)">
                    <!-- Drop Matching Tiles -->
                    <button id="dropSrc{{ i }}{{ j }}" [innerHTML]="options.selectedMatchedOption"
                      class="selectedMatchedOption">
                      {{ options.selectedMatchedOption }}
                    </button>
                  </div>
                  <div class="dropable" id="droptarget{{ i }}{{ j }}" (dragover)="allowDrop($event)"
                    (drop)="drop($event, Ques, i, 1)">
                    <button (dragstart)="dragStart($event)" (drag)="dragging($event)" draggable="true"
                      id="droptarget{{ i }}{{ j }}" class="options">
                      <!-- {{ options.optionMatch }} -->
                      <div [innerHTML]="options.optionMatch"></div>
                      <!-- {{ options.matchPairList[j].name }} -->
                    </button>
                  </div>
                </div>
              </div>

              <div *ngIf="reviewFlag">
                <div class="select_drop_list_container" *ngFor="let options of Ques.optionList; let j = index">
                  <div class="">
                    <button *ngIf="options.optionType == '1'" class="options minH" [ngClass]="{
                        activeCorrect: options.cFlag === 'true',
                        activeWrong: options.cFlag === 'false'
                      }" [innerHtml]="
                        makequizQuestionReady(options.optionText, 'optionText')
                      ">
                      <!-- {{ options.optionText }} -->
                    </button>
                    <div class="text-right" *ngIf="options.optionRef == '2'">
                      <span tooltip="Open Image In Full Screen Mode" flow="up">
                        <i class="fas fa-external-link-alt open_image_full_icon" aria-hidden="true"
                          (click)="openInFullScreen(options.optionRef)"></i>
                      </span>
                    </div>
                    <button *ngIf="options.optionType == '2'" class="options option_img_button minH" [ngClass]="{
                        activeCorrect: options.cFlag === 'true',
                        activeWrong: options.cFlag === 'false'
                      }">
                      <img class="optionsImg" [src]="options.optionRef" />
                    </button>
                  </div>
                  <div class="">
                    <!-- Drop Matching Tiles -->
                    <button id="dropSrc{{ i }}{{ j }}" class="selectedMatchedOption">
                      {{ options.selectedMatchedOption }}
                    </button>
                  </div>
                  <div class="" id="droptarget{{ i }}{{ j }}">
                    <button id="droptarget{{ i }}{{ j }}" class="options">
                      <!-- {{ options.optionMatch }} -->
                      <div [innerHTML]="options.optionMatch"></div>
                      <!-- {{ options.matchPairList[j].name }} -->
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="" *ngIf="Ques.qTypeId == 3">
          <div class="width100">
            <div class="" *ngIf="Ques.questionTypeId == 1">
              <h5 class="questionSName text-gray">{{ Ques.sName }}</h5>
              <h3 class="question" [innerHtml]="makequizQuestionReady(Ques, 'question', i + 1)">
                <!-- {{ Ques.qText }}
                              <span class="mandatoryQues" *ngIf="Ques.isMandatory == 1">&nbsp;*</span> -->
              </h3>
            </div>

            <div class="" *ngIf="Ques.questionTypeId == 2">
              <h5 class="questionSName text-gray">{{ Ques.sName }}</h5>
              <div class="image_center">
                <!-- <div>
                                  <h3 class="question">Q{{i+1}}.</h3>
                              </div> -->
                <img class="contentImg" [src]="Ques.contentRef" />
                <span tooltip="Open Image In Full Screen Mode" flow="up">
                  <i class="fas fa-external-link-alt open_image_full_icon ml-2" aria-hidden="true"
                    (click)="openInFullScreen(Ques.contentRef)"></i>
                </span>
              </div>
            </div>

            <div class="answer">
              <div *ngIf="!reviewFlag">
                <div>
                  <div class="sequence_list" [sortablejs]="Ques.optionList" (change)="selectOption1(Ques, i)">
                    <div *ngFor="let options of Ques.optionList; let j = index" class="sequence_list_option">
                      <button class="options" [ngClass]="{
                          activeCorrect: options.cFlag === 'true',
                          activeWrong: options.cFlag === 'false'
                        }">
                        <div class="button_option_container">
                          <div>{{ j + 1 }} ) &nbsp;</div>
                          <div [innerHtml]="
                              makequizQuestionReady(
                                options.optionText,
                                'optionText'
                              )
                            ">
                            {{ options.optionText }}
                          </div>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="reviewFlag">
                <div>
                  <div (change)="selectOption1(Ques, i)">
                    <div *ngFor="let options of Ques.optionList; let j = index" class="sequence_list">
                      <button class="options" [ngClass]="{
                          activeCorrect: options.cFlag === 'true',
                          activeWrong: options.cFlag === 'false',
                          disableQuestion: Ques.Answered == 1
                        }">
                        <div class="button_option_container">
                          <div class="number_container">
                            {{ j + 1 }} ) &nbsp;
                          </div>
                          <div [innerHtml]="
                              makequizQuestionReady(
                                options.optionText,
                                'optionText'
                              )
                            ">
                            <!-- {{ options.optionText }} -->
                          </div>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="inline" *ngIf="Ques.qTypeId == 4">
          <div class="inline_div">
            <div *ngIf="Ques.questionTypeId == 1" class="inline_div">
              <h5 class="questionSName text-gray">{{ Ques.sName }}</h5>
              <h3 class="question" [innerHtml]="makequizQuestionReady(Ques, 'question', i + 1)">
                <!-- {{ Ques.qText }}
                              <span class="mandatoryQues" *ngIf="Ques.isMandatory == 1">&nbsp;*</span> -->
              </h3>
            </div>

            <div *ngIf="Ques.questionTypeId == 2" class="inline_div mb-3">
              <h5 class="questionSName text-gray">{{ Ques.sName }}</h5>
              <div class="image_center">
                <!-- <div>
                                  <h3 class="question">Q{{i+1}}.</h3>
                              </div> -->
                <img class="contentImg" [src]="Ques.contentRef" />
                <span tooltip="Open Image In Full Screen Mode" flow="up">
                  <i class="fas fa-external-link-alt open_image_full_icon ml-2" aria-hidden="true"
                    (click)="openInFullScreen(Ques.contentRef)"></i>
                </span>
              </div>
            </div>
            <p class="questionInstructions mb-3" *ngIf="Ques.instructions">
              <span class="questionInstructionsLabel"> Instructions : </span>
              <!-- <span class="questionInstructionsData" [innerHtml]="makequizQuestionReady(Ques.instructions ,'instruction')"> </span> -->
              <span class="questionInstructionsData">
                {{ Ques.instructions | removeHtml: 92 }}
                <span class="read_more_feedback" *ngIf="(Ques.instructions | removeHtml: 92)?.length >= 90"
                  (click)="readfb(Ques.instructions)">
                  Read More
                </span>
              </span>
            </p>

            <div classs="answer" [ngClass]="{ disableQuestion: Ques.Answered == 1 }">
              <div *ngIf="!reviewFlag">
                <div class="">
                  <textarea rows="4" cols="12" matInput placeholder="message" class="form-control commntbx"
                    name="message" [(ngModel)]="Ques.selectedOption"></textarea>
                </div>
              </div>
              <div *ngIf="reviewFlag">
                <div class="">
                  <textarea rows="4" cols="12" matInput placeholder="message" class="form-control commntbx"
                    name="message" [(ngModel)]="Ques.selectedOption"></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>

        <p class="questionFeedback" *ngIf="quiz[currentQuestionIndex].answerConfirmed && Ques.feedback">
          <span class="questionFeedbackLabel"> Additional Information : </span>
          <!-- <span class="questionFeedbackData" [innerHtml]="makequizQuestionReady(Ques.feedback ,'feedback')"> </span> -->
          <span class="questionFeedbackData">
            {{ makefeedback(Ques.feedback) }}
            <span class="read_more_feedback" *ngIf="makefeedback(Ques.feedback)?.length >= 90"
              (click)="readfb(Ques.feedback)">
              Read More
            </span>
          </span>
        </p>
      </div>
    </div>
  </div>

  <!-- footer -->
  <div class="footerDiv">
    <div class="footer" *ngIf="activityData.navigation == 1">
      <div class="stepper">
        <!-- <mat-slider
          min="1"
          max="{{ total_page }}"
          step="1"
          value="{{ quiz[currentQuestionIndex].id }}"
          thumbLabel
          tickInterval="1"
          class="slider"
          color="primary"
        ></mat-slider> -->
        <p>{{ total_page }}</p>
      </div>

      <div class="nextbtn">
        <button class="nxt" (click)="showResult()">Go to Result</button>
      </div>
    </div>

    <div class="footer" *ngIf="activityData.navigation == 2">
      <div class="back">
        <button class="round" (click)="prevPage()" *ngIf="currentQuestionIndex != 0">
          <i class="fas fa-arrow-left"></i>
        </button>
      </div>

      <div class="stepper">
        <!-- <mat-slider
          min="1"
          max="{{ total_page }}"
          step="1"
          value="{{ displayQues.id }}"
          thumbLabel
          tickInterval="1"
          class="slider"
          color="primary"
        ></mat-slider> -->
      </div>

      <div class="nextbtn">
        <button class="nxt" (click)="showResult()">Go to Result</button>
      </div>
    </div>
  </div>
</div>

<!-- ============================================================================================ -->
<!-- result -->
<div class="quizContainer" [hidden]="showScore">
  <div class="result-question p-3">
    <div class="text-center pb-3">
      <h3 class="title123">Quiz Assessment for {{ courseTitle }}</h3>
    </div>
    <div class="matcard">
      <h1 class="completedstatus m-0">The assessment is complete</h1>
      <h3 class="question text-center">This is your score:</h3>
      <div class="answer">
        <div class="d-flex">
          <div class="block1">
            <p class="total">Total Questions</p>
            <h1 class="points">{{ resultData?.totalQuestions }}</h1>
          </div>
          <div class="block1">
            <p class="total">Total Right Answers</p>
            <h1 class="points rightans">{{ resultData?.rightAnswers }}</h1>
          </div>
          <div class="block1">
            <p class="total">Total Score</p>
            <h1 class="points score">{{ resultData?.totalScore }}</h1>
          </div>
        </div>
      </div>
      <div *ngIf="resultData?.passFlag == 1">
        <h1 class="lastLine" *ngIf="activityData?.completionCriteria == 1">
          Good! You have completed the assessment.
        </h1>
        <h1 class="lastLine" *ngIf="activityData?.completionCriteria == 2">
          Brilliant! You have passed the assessment.
        </h1>
      </div>
      <div *ngIf="resultData?.passFlag == 0">
        <h1 class="lastLine">Better luck next time.</h1>
        <h3 class="lastLine">You have failed the assessment.</h3>
        <h4 class="lastLine" *ngIf="resultData?.requiredMarks != 0">
          To pass the quiz you have to score minimum
          <b>{{ resultData?.requiredMarks }} </b>.
        </h4>
      </div>

      <div class="attemptsDiv" *ngIf="resultData?.remAttempts > 0 && resultData?.isUnlimited == 0">
        <h4 class="lastLine">
          You have
          <b class="highlightText1">{{ resultData?.remAttempts }} </b> more attempt(s) left.
          <b class="highlightText2" (click)="check_quiz_for_user_for_reattempt()">Click here to re-attempt!</b>
        </h4>
      </div>
      <div class="attemptsDiv" *ngIf="resultData?.remAttempts < 0 && resultData?.isUnlimited != 0">
        <h4 class="lastLine">
          You have <b class="highlightText1"> {{ "unlimited" }} </b> attempt(s).
          <b class="highlightText2" (click)="check_quiz_for_user_for_reattempt()">Click here to re-attempt!</b>
        </h4>
      </div>
    </div>
  </div>

  <div class="footerDiv">
    <div class="footer">
      <div class="floatRight" *ngIf="isReviewEn">
        <button class="review" (click)="showReview()">Review</button>
      </div>
    </div>
  </div>
</div>

<div class="addSelfBackdrop2" [ngClass]="{ show_popup: showfb }">
  <div class="addSelfDemoModal2" [ngClass]="{ show_popup: showfb }">
    <div class="feedback_popup_container">
      <!-- <h1 class="modalheader"> Feedback Instruction</h1> -->
      <p class="closeicon">
        <i class="fa fa-times" aria-hidden="true" (click)="closefb()"></i>
      </p>
    </div>
    <div>
      <base target="_blank" />
      <p [innerHtml]="feedbacktext"></p>
    </div>
  </div>
</div>
<ngx-image-viewer class="image_popup" *ngIf="showImageInFullScreen" [src]="images" [(config)]="config"
  [(index)]="imageIndexOne" (customEvent)="handleEvent($event)">
</ngx-image-viewer>