import { HostListener, Injectable } from '@angular/core';
import { UserService } from '../../../../providers/userservice/user.service';
// import { AutoCloseOverlaysService } from 'src/app/providers/autoCloseOverlays/auto-close-overlays.service';

@Injectable({
  providedIn: 'root'
})
export class YellowMessangerService {

  chatbotActive: any = false;
  chatbotInit: any = false;
  chatbotVisible: any = false;

  eventListnerAdded: any = false;

  botIcon = '';

  YellowMessengerPlugin: any;

  currentUserData: any;

  appLoadedStatus: any = false;

  constructor(
    // public autoCloseOverlays: AutoCloseOverlaysService
    private userService: UserService
  ) {
    // this.setPlugin();
    // this.addChatbotEventListner();
  }

  /*********** Add listners start ***********/
  addChatbotInitListner() {
    window.addEventListener("load", (event) => {
      console.log("page is fully loaded");
      this.initAndShowChatBot();
    });
  }

  addChatbotEventListner() {
    if (!this.eventListnerAdded) {
      let _this = this;
      window.addEventListener('message', function (event) {
        // make sure to verify origin to prevent XSS attacks.
        // https://blog.yeswehack.com/yeswerhackers/introduction-postmessage-vulnerabilities/
        if (event.origin !== window.origin) throw new Error('Message not allowed');
        console.log("eventData : ", event);
        _this.processChatbotEvent(event);
      })
      this.eventListnerAdded = true;
    } else {
      console.log('Event listner already added...');
    }
  }
  /*********** Add listners end ***********/

  /*********** get user details start ***********/
  getUserData() {
    this.currentUserData = this.userService.getUserDetails();
  }
  /*********** get user details end ***********/

  /*********** get and set plugin start ***********/
  setPlugin() {
    // setTimeout(() => {
    if (window['YellowMessengerPlugin']) {
      this.YellowMessengerPlugin = window['YellowMessengerPlugin'];
      // this.setBotIcon();
    }
    // }, 1500);
  }
  /*********** get and set plugin end ***********/

  /*********** get and set bot icon start ***********/
  // setBotIcon() {
  //   let YellowMessengerPlugin = window['YellowMessengerPlugin'];
  //   this.botIcon = YellowMessengerPlugin.options.skin.botIcon;
  // }

  getBotIcon() {
    let YellowMessengerPlugin = window['YellowMessengerPlugin'];
    if (YellowMessengerPlugin.options.skin) {
      this.botIcon = YellowMessengerPlugin.options.skin.botIcon;
    }
    return this.botIcon;
  }
  /*********** get and set bot icon end ***********/

  /*********** check bot is enabled for user by role start ***********/
  isBotEnabled() {
    let userDetails = this.userService.getUserDetails();
    // if (userDetails && (userDetails.roleId == 8 || userDetails.roleId == 5)) {
    if (userDetails) {
      if (userDetails.isBotEnabled) {
        return { status: true, payload: userDetails.botPayload };
      } else {
        console.log('Chatbot not available for this role...');
      }
    } else {
      console.log('User details not available...');
    }
    return { status: false, payload: '' };
  }
  /*********** check bot is enabled for user by role end ***********/

  /*********** initialize bot start ***********/
  initBot() {
    let currentUser = this.userService.getUserDetails();
    if (currentUser && currentUser.isBotEnabled && currentUser.botPayload) {
      // this.initAndShowChatbotRecursively(0, null);
      this.checkForChatbotPluginRecursively();
    } else {
      console.log('initBot : User/Bot/Payload not found', currentUser);
    }
  }

  initAndShowChatBotOnAppLoad(status = false) {
    if (!this.chatbotInit) {
      this.initAndShowChatBot();
    } else {
      console.log('Bot already initialize on app load...');
    }
  }

  initAndShowChatBot() {
    // setTimeout(() => {
    if (!this.chatbotInit) {
      this.initChatbot();
    }
    if (this.chatbotInit) {
      // this.chatbotVisible = true;
      this.showChatbot();
    }
    // }, 1000);
  }

  initAndShowChatbotRecursively(i = 0, userData) {
    console.log('initAndShowChatbotRecursively iteration - ', i);
    let userDetails = userData || this.userService.getUserDetails();
    //  && userDetails.isBotEnabled
    if (userDetails) {
      if (this.chatbotInit) {
        // this.chatbotVisible = true;
        this.showChatbot();
        console.log('initAndShowChatbotRecursively iteration completed...');
        return;
      }
      if (i == 21) {
        console.log('initAndShowChatbotRecursively iteration exited due to long wait time...');
        return;
      }
      setTimeout(() => {
        this.initChatbot();
        this.initAndShowChatbotRecursively(++i, userDetails);
      }, 500);
    } else {
      console.log('User/Bot not available...');
      return;
    }
  }

  checkForChatbotPluginRecursively(i = 0) {
    console.log('checkForChatbotPluginRecursively iteration - ', i);
    let YellowMessengerPlugin = window['YellowMessengerPlugin'];
    if (YellowMessengerPlugin) {
      console.log('Bot plugin found...');
      console.log('Bot initializing...');
      this.initAndShowChatBot();
      return;
    }
    if (i == 21) {
      console.log('checkForChatbotPluginRecursively iteration exited due to long wait time...');
      return;
    }
    setTimeout(() => {
      this.checkForChatbotPluginRecursively(++i);
    }, 500);
  }

  initChatbot() {
    const { status, payload } = this.isBotEnabled();
    if (status) {
      let YellowMessengerPlugin = window['YellowMessengerPlugin'];
      if (YellowMessengerPlugin) {
        if (!this.chatbotInit && payload) {

          // let ymConfig = window['ymConfig'];
          // ymConfig['payload'] = payload;

          YellowMessengerPlugin.init({
            // payload: 'Tejaswi Bhokare|994808|tejaswi.bhokare@bajajfinserv.in|EMPLOYEE|EMPCare',
            payload: payload || '',
            "hideChatButton": false
          });

          this.chatbotInit = true;

          this.addChatbotEventListner();

        } else {
          console.log('Yellowmessanger payload not found');
        }
      } else {
        console.log('Yellowmessanger plugin not found');
      }
    }
  }
  /*********** initialize bot end ***********/

  /*********** toggle visibility of bot start ***********/
  showHideChatBot() {
    if (!this.chatbotInit) {
      this.initChatbot();
    }
    if (this.chatbotInit) {
      const { status, payload } = this.isBotEnabled();
      // setTimeout(() => {
      if (status) {
        this.showChatbot();
        // if (!this.chatbotVisible) {
        //   this.showChatbot();
        // }else{
        //   this.hideChatbot();
        // }
      } else {
        console.log('Chatbot not available for this role...');
        this.hideChatbot();
      }
      // }, 2000);
    } else {
      console.log('Bot is not initialized...');
    }
  }

  showChatbot() {
    // const { status, payload } = this.isBotEnabled();
    // if (status) {
    if (!this.chatbotVisible) {
      // let YellowMessengerPlugin = window['YellowMessengerPlugin'];
      // YellowMessengerPlugin.show();
      // setTimeout(() => {
      window['YellowMessengerPlugin'].show();
      this.chatbotVisible = true;
      // }, 1500);
    }
    // }
  }

  hideChatbot() {
    if (this.chatbotVisible) {
      // let YellowMessengerPlugin = window['YellowMessengerPlugin'];
      // YellowMessengerPlugin.hide();
      // setTimeout(() => {
      window['YellowMessengerPlugin'].hide();
      this.chatbotVisible = false;
      // }, 1500);
    }
  }
  /*********** toggle visibility of bot end ***********/

  /*********** toggle chat window of bot start ***********/
  toggleChatbot() {
    let YellowMessengerPlugin = window['YellowMessengerPlugin'];
    YellowMessengerPlugin.toggleChat();
  }

  openChatbot() {
    if (this.chatbotInit) {
      const { status, payload } = this.isBotEnabled();
      if (status) {
        let YellowMessengerPlugin = window['YellowMessengerPlugin'];
        if (!this.chatbotActive) {
          // window.history.pushState(null, null, location.href);
          // this.autoCloseOverlays.setStateInit(true);
          YellowMessengerPlugin.openBot();
          this.chatbotActive = true;
        }
      } else {
        console.log('Bot not available for this role');
        // this.presentToastMessage('Bot not available for this role...', 1);
      }
    } else {
      console.log('Plugin not initialized');
      // this.presentToastMessage('Bot not available at this time...', 1);
    }
  }

  // @HostListener('window:popstate') // Use @HostListener to listen for DOM events
  closeChatbot(status) {
    if (this.chatbotActive) {
      let YellowMessengerPlugin = window['YellowMessengerPlugin'];
      YellowMessengerPlugin.closeBot();
      this.chatbotActive = false;
    }
  }
  /*********** toggle chat window of bot end ***********/

  /*********** send data to bot end ***********/
  sendChatbotEvent(eventName, eventData) {
    // find the yellow.ai widget iframe
    const ymIframe: any = document.getElementById('ymIframe');
    // event_name should be a valid name supported by the widget.
    const message = JSON.stringify({ code: eventName, data: eventData });
    // send a cross-site message using 
    // window.postMessage(message, targetOrigin, transfer) API.
    ymIframe.contentWindow?.postMessage(message, window.location.origin);
  }
  /*********** send data to bot end ***********/

  /*********** perform acton on bot events start ***********/
  processChatbotEvent(event) {
    try {
      if (event.data) {
        let evData;
        if (typeof event.data == 'string') {
          evData = JSON.parse(event.data);
        } else {
          evData = event.data;
        }
        this.performActionOnChatbotEvent(evData);
      }
    } catch (error) {
      console.log('addChatbotEventListner error : ', error);
    }
  }

  performActionOnChatbotEvent(evData) {
    switch (evData.event_code) {
      case 'ym-bot-opened':
        console.log('Bot opened');
        break;
      case 'ym-bot-closed':
        console.log('Bot closed');
        // this.closeChatbot(null);

        if (this.chatbotActive) {
          // window.history.back();
          // this.autoCloseOverlays.destroy();

          this.chatbotActive = false;
        }

        break;
      case 'ym_event_quick_reply':
        console.log('Quick Reply clicked');
        break;
      case 'ym_event_image_clicked':
        console.log('Image opened in preview');
        break;
      case 'ym_event_card_action':
        console.log('Card action button clicked');
        break;
      case 'ym-bot-loaded-on-page-reload':
        console.log('When the refresh context is false, the bot will receive events as soon as the page reloads');
        break;
      case 'ym_home':
        console.log('Home button clicked');
        break;
      case 'message-received':
        console.log('New message received by user that is, sent by the bot');
        break;
      case 'page-url-based-trigger':
        console.log('Whenever a notification is sent in the bot (for electron apps)');
        break;
      default:
        console.log('Event not found...');
        break;
    }
  }
  /*********** perform acton on bot events end ***********/

}
