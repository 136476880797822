<div class="categories" *ngIf="categoryList">
    <div class="categories-inner d-flex">
        <div class="inner-list">
            <ul>

                <li [ngClass]="{'active': item?.activeFlag}" class="m-0 cursor-p d-flex align-items-center justify-content-between" (mouseover)="innnerTag(item)" (onmouseleave)="removeInner()" *ngFor="let item of categoryList" (click)="performAction(item)">
                    {{ item[config['label']] }}
                    <nb-icon icon="chevron-right-outline" *ngIf="item?.children.length > 0"></nb-icon>

                </li>
            </ul>
        </div>
        <ngx-header-drop-list *ngIf="innerArray.length !=0" [config]="config" (getEvent)="performAction($event)" [categoryList]="innerArray"></ngx-header-drop-list>
    </div>
</div>