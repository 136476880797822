import { Injectable } from "@angular/core";
import { NbDialogService } from "@nebular/theme";
import { DialogViewerComponent } from "../../components/dialog-viewer/dialog-viewer.component";
import { PopupwithiconComponent } from "../../components/popupwithicon/popupwithicon.component";
import { ToasterService } from '../toaster/toaster.service';
@Injectable({
  providedIn: "root",
})
export class AlertService {
  timeArray: string;
  currentUserData = JSON.parse(localStorage.getItem("currentUser"));
  dateTime3: any;
  scheduleData: any;
  dialogRef: any;
  constructor(private dialogService: NbDialogService,
    public toaster: ToasterService,


  ) { }

  showAlert(config) {
    console.log("config", config);
    const newVariables = JSON.parse(JSON.stringify(config));
    this.dialogRef = this.dialogService.open(DialogViewerComponent, {
      context: {
        // config: newVariables,
        config: { ...config },
      },
      closeOnEsc: false,
      closeOnBackdropClick: false,
    },
    );
    return this.dialogRef;
  }

  showAlertWithIcon(config, component?: any) {
    console.log("config", config);
    component = component ? component : PopupwithiconComponent;
    return this.dialogService.open(component, {
      context: {
        config: { ...config },
      },
      closeOnEsc: false,
      closeOnBackdropClick: false,
    },
    );
  }

  dismissAlert() {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }



}
