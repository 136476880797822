import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { ActivityService } from "../../../providers/activity/activity.service";
import { LoadingService } from "../../../providers/loading/loading.service";
import { ToasterService } from "../../../providers/toaster/toaster.service";

@Component({
  selector: "ngx-feedback",
  templateUrl: "./feedback.component.html",
  styleUrls: [
    "./feedback.component.scss",
    "../../../@theme/styles/_typography.scss",
  ],
})
export class FeedbackComponent implements OnInit {
  @Output() componentData = new EventEmitter();
  @Output() publishInternalEvent = new EventEmitter();
  @Input() activity: any = {};
  @Input() inInternalFlag: boolean = false;
  @ViewChild("name") name: any;
  feedbacklist: any = [];
  // count: any;
  courseActivityData: any = [];
  currentUser: any;
  submit: any;
  alert: any;
  currentCourseData: any = [];
  formIsValid: boolean = false;
  disableSubmit: any = false;
  separators: any = {
    pipeValue: "",
    hashValue: "",
    dollarValue: "",
    tildValue: "",
  };
  noDataFound = false;
  alreadySubmmited = false;
  // themes = document.getElementsByTagName('body');
  // themObj: any = {
  //   'obj' : {}
  // };
  themeDetails: any;

  constructor(
    public activityService: ActivityService,
    public load: LoadingService,
    public toaster: ToasterService //  private analyticsFirebase: FirebaseAnalyticsService,
  ) {}

  ngOnInit() {
    // console.log('body========>>>>>>>>>', this.themes);
    // const cssThemes = this.themes[0].style.cssText;
    // const splitted = cssThemes.split(' ');
    // console.log('cssThemes', splitted);
    // for(let i = 0; i < splitted.length; i++) {
    //   let splitkeyval = splitted[i].split(':');
    //   let keyobj = splitkeyval[0];
    //   this.themObj['obj'][splitkeyval[0]] = splitkeyval[1].toString();
    // }
    // console.log('this.themObj', this.themObj);
    this.themeDetails = JSON.parse(localStorage.getItem("theme"));
    console.log("this.themObj", this.themeDetails);
    this.courseActivityData = this.activity;
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    // console.log("this.themObj", this.currentUser);
    console.log("Feedback activity data : ", this.courseActivityData);
    if (this.isTTT()) {
      this.vivaQuestion();
    } else {
      this.question();
    }
  }

  isTTT() {
    if (
      this.courseActivityData.activityTypeId == 12 ||
      this.courseActivityData.activityTypeId == 16
    ) {
      return true;
    } else {
      return false;
    }
    // if (this.courseActivityData.stepId == 3 || this.courseActivityData.stepId == 5 || this.courseActivityData.stepId == 6) {
    //   return true;
    // }

    // return false;
  }
  logRatingChange(rating) {
    console.log("changed rating: ", rating);
    // do your stuff
  }

  selectOption(displayQues, i) {
    console.log(displayQues);
    console.log(i);
    if (displayQues.questionTypeId != 1) {
      for (let j = 0; j < displayQues.optionList.length; j++) {
        displayQues.optionList[j].sFlag = false;
      }
      if (displayQues.optionList[i].sFlag == true) {
        displayQues.optionList[i].sFlag = false;
      } else {
        displayQues.optionList[i].sFlag = true;
      }
      console.log("MainData===>", displayQues);
      console.log("Index", i);
    }
  }
  onRateChange(count, index) {
    // this.count = count.rating;
    // this.count = count;
    console.log("onRateChange", count);
    this.feedbacklist[index].sRateAns = count.rating;
    // for (let i = 0; i < this.feedbacklist.length; i++) {
    //   if (i == index) {
    //     console.log(this.feedbacklist[i]);
    //     this.feedbacklist[i].sRateAns = this.count;
    //     // for (var j = 0; j < this.questionlist[i].rating.length; j++) {
    //     //   this.questionlist[i].rating[j].sFlag = false;
    //     //   this.questionlist[i].rating[j].sRateAns = this.count;
    //     // }
    //     // this.questionlist[i].rating[j].sRateAns =  this.count;
    //   }
    // }
  }

  // Normal Question
  question() {
    const param = {
      fId: this.courseActivityData.feedbackId,
      // fId: 210,
      tId: this.courseActivityData.tenantId,
    };
    const event = {
      loaderFlag: true,
      responseData: null,
      errorFlag: false,
      // 'showQuizReview': false,
      // 'showQuiz': true,
      showComponent: "quiz",
    };
    console.log("Event Emit", event);
    this.componentData.emit(event);
    // this.load.presentLoading("Please wait...");
    this.activityService.feedbackQuestion(param).then(
      (res) => {
        console.log(res);
        this.load.dismiss();
        if ((res.type = "true")) {
          this.feedbacklist = res.data;
          const tempResSep = res.separetors;
          if (tempResSep) {
            this.separators.pipeValue = tempResSep["pipeValue"];
            this.separators.hashValue = tempResSep["hashValue"];
            // this.separators.dollarValue = tempResSep['dollarValue'],
            // this.separators.tildValue = tempResSep['tildValue']
          }
          console.log(this.feedbacklist);
          this.makeFeedbackFormDataReady();
          // this.saveFeedbackCompletion('UP');
          this.makeFeedbackCompletionDataReady("UP");
          const event = {
            loaderFlag: false,
            responseData: null,
            errorFlag: false,
            // 'showQuizReview': false,
            // 'showQuiz': true,
            // showComponent: "quiz",
          };
          console.log("Event Emit", event);
          this.componentData.emit(event);
          console.log("RESULT===>", this.feedbacklist);
        }
      },
      (err) => {
        // this.load.dismiss();
        const event = {
          loaderFlag: false,
          responseData: null,
          errorFlag: false,
          // 'showQuizReview': false,
          // 'showQuiz': true,
          showComponent: "quiz",
        };
        console.log("Event Emit", event);
        this.componentData.emit(event);
        console.log(err);
      }
    );
  }

  //  Viva Question Get
  vivaQuestion() {
    const param = {
      cId: this.courseActivityData.courseId,
      mId: this.courseActivityData.moduleId,
      actId: this.courseActivityData.activityId,
      // empId: this.currentUser.eid,
      userId: this.courseActivityData.userId,
      fId: this.courseActivityData.feedbackId,
      // fId: 210,
      tId: this.courseActivityData.tenantId,
    };
    const event = {
      loaderFlag: true,
      responseData: null,
      errorFlag: false,
      // 'showQuizReview': false,
      // 'showQuiz': true,
      showComponent: "quiz",
    };
    console.log("Event Emit", event);
    this.componentData.emit(event);
    // this.load.presentLoading("Please wait...");
    this.activityService.getVivaQuestions(param).then(
      (res) => {
        console.log(res);
        this.load.dismiss();
        if ((res.type = "true")) {
          if (res.data && res.data.length != 0) {
            this.feedbacklist = res.data;
            const tempResSep = res.separetors;
            this.alreadySubmmited = res.data[0].isAnswered;
            if (tempResSep) {
              this.separators.pipeValue = tempResSep["pipeValue"];
              this.separators.hashValue = tempResSep["hashValue"];
              // this.separators.dollarValue = tempResSep['dollarValue'],
              // this.separators.tildValue = tempResSep['tildValue']
            }
            console.log(this.feedbacklist);
            this.noDataFound = false;
          } else {
            this.noDataFound = true;
          }
          // this.makeFeedbackFormDataReady();
          // this.saveFeedbackCompletion('UP');
          // this.makeFeedbackCompletionDataReady("UP");
          const event = {
            loaderFlag: false,
            responseData: null,
            errorFlag: false,
            // 'showQuizReview': false,
            // 'showQuiz': true,
            // showComponent: "quiz",
          };
          console.log("Event Emit", event);

          this.componentData.emit(event);
          console.log("RESULT===>", this.feedbacklist);
        }
      },
      (err) => {
        // this.load.dismiss();
        const event = {
          loaderFlag: false,
          responseData: null,
          errorFlag: false,
          // 'showQuizReview': false,
          // 'showQuiz': true,
          showComponent: "quiz",
        };
        console.log("Event Emit", event);
        this.componentData.emit(event);
        console.log(err);
      }
    );
  }

  resultsubmit() {
    // this.load.presentLoading(
    //   "Please wait while your answer(s) being submitted..."
    // );
    const event = {
      loaderFlag: true,
      responseData: null,
      errorFlag: false,
      // 'showQuizReview': false,
      // 'showQuiz': true,
      showComponent: "quiz",
    };
    console.log("Event Emit", event);
    this.componentData.emit(event);
    let quesId = [];
    let value = [];
    for (var i = 0; i < this.feedbacklist.length; i++) {
      let valuePushed = false;
      if (
        this.feedbacklist[i].optionList &&
        this.feedbacklist[i].rating == undefined
      ) {
        quesId.push(this.feedbacklist[i].fqid);

        for (var j = 0; j < this.feedbacklist[i].optionList.length; j++) {
          if (this.feedbacklist[i].optionList[j].sFlag == true) {
            value.push(this.feedbacklist[i].optionList[j].option);
            valuePushed = true;
          } else if (this.feedbacklist[i].optionList[j].sAns) {
            value.push(this.feedbacklist[i].optionList[j].sAns);
            valuePushed = true;
          }
        }
      }
      if (this.feedbacklist[i].rating) {
        quesId.push(this.feedbacklist[i].fqid);
        value.push(this.feedbacklist[i].sRateAns);
        valuePushed = true;
        // for(var j=0;j<this.feedbacklist[i].rating.length;j++){
        // 	if(this.feedbacklist[i].rating[j].sRateAns != 0){
        // 		value.push(this.feedbacklist[i].rating[j].sRateAns);
        // 	}
        // }
      }
      if (!valuePushed) {
        value.push("Not attempted");
      }
    }

    // let dollarSeprator = this.separators.dollarValue ? this.separators.dollarValue : '$';
    const hashSeprator = this.separators.hashValue
      ? this.separators.hashValue
      : "#";
    const pipeSeprator = this.separators.pipeValue
      ? this.separators.pipeValue
      : "|";
    // let tildSeprator = this.separators.tildValue ? this.separators.tildValue : '~';

    const valueStr = value.join(pipeSeprator);
    const quesIdStr = quesId.join(pipeSeprator);

    const feedId = this.courseActivityData.feedbackId
      ? this.courseActivityData.feedbackId
      : 0;
    const courseId = this.courseActivityData.courseId
      ? this.courseActivityData.courseId
      : 0;
    const moduleId = this.courseActivityData.moduleId
      ? this.courseActivityData.moduleId
      : 0;
    const activityId = this.courseActivityData.activityId
      ? this.courseActivityData.activityId
      : 0;
    const enrolId = this.courseActivityData.enrolId
      ? this.courseActivityData.enrolId
      : 0;
    const actiCmpId = this.courseActivityData.activitycompletionid
      ? this.courseActivityData.activitycompletionid
      : 0;
    const wfId = this.courseActivityData.wfId
      ? this.courseActivityData.wfId
      : null;
    const stepId = this.courseActivityData.stepId
      ? this.courseActivityData.stepId
      : null;
    const compAreaId = this.courseActivityData.compAreaId
      ? this.courseActivityData.compAreaId
      : null;
    if (
      feedId !== 0 &&
      courseId !== 0 &&
      moduleId !== 0 &&
      activityId !== 0 &&
      enrolId !== 0
    ) {
      const param = {
        empId: this.currentUser.eid,
        fId: feedId,
        qId: quesIdStr,
        val: valueStr,
        valLen: value.length,
        tId: this.currentUser.tenantId,
        uId: this.currentUser.id,
        cId: courseId,
        mId: moduleId,
        aId: activityId,
        enId: enrolId,
        actCompId: actiCmpId,
        platform: 2,
        wfId: wfId,
        stepId: stepId,
        compAreaId: compAreaId,
      };
      console.log("FEEDBACKANSWER===>", param);
      this.activityService.submitfeedback(param).then(
        (res) => {
          console.log(res);
          // this.load.dismiss();
          if (res.type && res.data) {
            this.submit = res.data[0];
            // this.saveFeedbackCompletion('Y');
            // this.makeFeedbackCompletionDataReady('Y');
            this.presentAlert(this.submit.msg, "Success", "success");
            const event = {
              loaderFlag: false,
              responseData: this.submit,
              errorFlag: false,
              showComponent: "next-activity",
            };
            if (this.currentUser.roleId == 7) {
              this.goBack();
            }
            //
            this.componentData.emit(event);
            this.disableSubmit = false;
          } else {
            this.presentAlert(
              "Unable to submit your feedback at this time, please try again",
              "Warning",
              "warning"
            );
            this.disableSubmit = false;
          }
        },
        (err) => {
          console.log(err);
          // this.load.dismiss();
          const event = {
            loaderFlag: false,
            responseData: null,
            errorFlag: false,
            // 'showQuizReview': false,
            // 'showQuiz': true,
            showComponent: "quiz",
          };
          console.log("Event Emit", event);
          this.componentData.emit(event);
          this.presentAlert(
            "Unable to submit your feedback at this time, please try again",
            "Warning",
            "warning"
          );
          this.disableSubmit = false;
        }
      );
    } else {
      this.load.dismiss();
      console.log("Feedback submit params not proper");
      this.presentAlert(
        "Unable to submit your feedback at this time, please try again",
        "Warning",
        "warning"
      );
      this.disableSubmit = false;
    }
  }

  resultsubmitTTT(isPublishflag) {
    // this.load.presentLoading(
    //   "Please wait while your answer(s) being submitted..."
    // );
    const event = {
      loaderFlag: true,
      responseData: null,
      errorFlag: false,
      // 'showQuizReview': false,
      // 'showQuiz': true,
      showComponent: "quiz",
    };
    console.log("Event Emit", event);
    this.componentData.emit(event);
    let quesId = [];
    let value = [];
    let compValuesId = [];
    let feedbackCompId = null;
    for (var i = 0; i < this.feedbacklist.length; i++) {
      compValuesId.push(this.feedbacklist[i].compValuesId);
      feedbackCompId = this.feedbacklist[i].completionId;
      quesId.push(this.feedbacklist[i].fqid);
      let valuePushed = false;
      if (this.feedbacklist[i].questionTypeId != 4) {
        // quesId.push(this.feedbacklist[i].fqid);
        for (var j = 0; j < this.feedbacklist[i].optionList.length; j++) {
          if (this.feedbacklist[i].optionList[j].sFlag == true) {
            value.push(this.feedbacklist[i].optionList[j].option);
            valuePushed = true;
          } else if (this.feedbacklist[i].optionList[j].sAns) {
            value.push(this.feedbacklist[i].optionList[j].sAns);
            valuePushed = true;
          }
        }
      }
      if (this.feedbacklist[i].questionTypeId == 4) {
        value.push(this.feedbacklist[i].sRateAns);
        valuePushed = true;
      }
      if (!valuePushed) {
        value.push("Not attempted");
      }
    }

    // let dollarSeprator = this.separators.dollarValue ? this.separators.dollarValue : '$';
    const hashSeprator = this.separators.hashValue
      ? this.separators.hashValue
      : "#";
    const pipeSeprator = this.separators.pipeValue
      ? this.separators.pipeValue
      : "|";
    // let tildSeprator = this.separators.tildValue ? this.separators.tildValue : '~';

    const valueStr = value.join(pipeSeprator);
    const quesIdStr = quesId.join(pipeSeprator);
    const valueCompIds = compValuesId.join(pipeSeprator);
    const feedId = this.courseActivityData.feedbackId
      ? this.courseActivityData.feedbackId
      : 0;
    const courseId = this.courseActivityData.courseId
      ? this.courseActivityData.courseId
      : 0;
    const moduleId = this.courseActivityData.moduleId
      ? this.courseActivityData.moduleId
      : 0;
    const activityId = this.courseActivityData.activityId
      ? this.courseActivityData.activityId
      : 0;
    const enrolId = this.courseActivityData.enrolId
      ? this.courseActivityData.enrolId
      : 0;
    const actiCmpId = this.courseActivityData.activitycompletionid
      ? this.courseActivityData.activitycompletionid
      : 0;
    // const wfId = this.courseActivityData.wfId
    //   ? this.courseActivityData.wfId
    //   : null;

    if (
      feedId !== 0 &&
      courseId !== 0 &&
      moduleId !== 0 &&
      activityId !== 0 &&
      enrolId !== 0
    ) {
      const param = {
        empId: this.courseActivityData.employeeId,
        fId: feedId,
        qId: quesIdStr,
        val: valueStr,
        valLen: value.length,
        tId: this.currentUser.tenantId,
        uId: this.courseActivityData.userId,
        cId: courseId,
        mId: moduleId,
        aId: activityId,
        enId: enrolId,
        actCompId: actiCmpId,
        platform: 2,
        workflowId: this.courseActivityData.workflowId,
        userId: this.currentUser.id,
        stepId: this.courseActivityData.stepId,
        valueCompIds: valueCompIds,
        feedCompId: feedbackCompId,
        isPublishflag: isPublishflag,
      };
      console.log("FEEDBACKANSWER===>", param);
      this.activityService
        .submitQuestionAnswersTTT(param)
        .then(
          (res) => {
            console.log(res);
            // this.load.dismiss();
            if (res.type) {
              const message = {
                msg: res["message"],
              };
              this.submit = message;
              // this.saveFeedbackCompletion('Y');
              // this.makeFeedbackCompletionDataReady('Y');
              this.presentAlert(this.submit.msg, "Success", "success");
              const event = {
                loaderFlag: false,
                responseData: this.submit,
                errorFlag: false,
                showComponent: "next-activity",
              };
              this.componentData.emit(event);
              if (this.currentUser.roleId == 7) {
                this.goBack();
              }
            } else {
              this.presentAlert(
                "Unable to submit your feedback at this time, please try again",
                "Warning",
                "warning"
              );
              this.disableSubmit = false;
            }
          },
          (err) => {
            console.log(err);
            // this.load.dismiss();
            const event = {
              loaderFlag: false,
              responseData: null,
              errorFlag: false,
              // 'showQuizReview': false,
              // 'showQuiz': true,
              showComponent: "quiz",
            };
            this.disableSubmit = false;
            console.log("Event Emit", event);
            this.componentData.emit(event);
            this.presentAlert(
              "Unable to submit your feedback at this time, please try again",
              "Warning",
              "warning"
            );
          }
        )
        .catch((err) => {
          this.disableSubmit = false;
        });
    } else {
      this.load.dismiss();
      console.log("Feedback submit params not proper");
      this.presentAlert(
        "Unable to submit your feedback at this time, please try again",
        "Warning",
        "warning"
      );
      this.disableSubmit = false;
    }
  }
  makeFeedbackCompletionDataReady(feedBackStatus) {
    const activity = this.courseActivityData;
    // const activity = this.playerService.getActivity();
    if (activity.completionstatus === "Y") {
      activity.completionstatus = "Y";
    } else if (feedBackStatus === "Y") {
      activity.completionstatus = "Y";
    } else {
      activity.completionstatus = "UP";
    }

    this.saveFeedbackCompletion(activity);
  }

  saveFeedbackCompletion(activity) {
    // const activity = this.courseActivityData;
    // activity.completionstatus = feedBackStatus;
    this.activityService.saveActivityCompletion(activity).then(
      (res: any) => {
        if (res.type === true) {
          // this.quizCompRes = res;
          if (
            this.courseActivityData.activitycompletionid === undefined ||
            !this.courseActivityData.activitycompletionid
          ) {
            this.courseActivityData.activitycompletionid =
              res.data[0][0].lastid;
          }
          console.log("Feedback completion res", res);
        } else {
          console.log("Feedback completion res", res);
        }
      },
      (err) => {
        console.log("Feedback completion err", err);
      }
    );
  }

  goBack() {
    // if (this.courseActivityData.frompage == 'module') {
    //   this.router.navigateByUrl('/module');
    // }
    // if (this.courseActivityData.frompage == 'trainer') {
    //   this.router.navigateByUrl('/trainer-detail');
    // }
    if (this.inInternalFlag) {
      this.isInternalBack();
    } else {
      window.history.back();
    }
  }

  async presentAlert(msg, title, type) {
    // this.alert = await this.alertController.create({
    //   header: "",
    //   subHeader: "",
    //   backdropDismiss: false,
    //   message: msg,
    //   buttons: [
    //     {
    //       text: "OK",
    //       cssClass: "okbutton",
    //       handler: (blah) => {
    //         console.log("Confirm Cancel: blah");
    //         // this.router.navigateByUrl('/tabs/learn');
    //         // if (this.courseActivityData.frompage == 'module') {
    //         //   this.router.navigateByUrl('/module');
    //         // }
    //         // if (this.courseActivityData.frompage == 'trainer') {
    //         //   this.router.navigateByUrl('/trainer-detail');
    //         // }
    //         if (this.formIsValid) {
    //           window.history.back();
    //         }
    //       },
    //     },
    //   ],
    // });
    // this.alert.present();
    await this.toaster.prsentToast(msg, title, type, null);
  }
  getQuestionNumberForSection(index: number, sectionName: string): number {
    let count = 1;
    for (let i = 0; i < index; i++) {
      if (this.feedbacklist[i].secName === sectionName) {
        count++;
      }
    }
    
    return count;
  }
  makeFeedbackFormDataReady() {
    for (let i = 0; i < this.feedbacklist.length; i++) {
      this.feedbacklist[i].qId = i + 1;
      this.feedbacklist[i]["isValid"] = true;
      if (
        this.feedbacklist[i].questionTypeId === 2 ||
        this.feedbacklist[i].questionTypeId === 3
      ) {
        for (let j = 0; j < this.feedbacklist[i].optionList.length; j++) {
          this.feedbacklist[i].optionList[j].sFlag = false;
        }
      } else if (this.feedbacklist[i].questionTypeId === 1) {
        this.feedbacklist[i].questionPlaceholder =
          "Minimum " +
          this.feedbacklist[i].optionList[0].minLength +
          " and maximum " +
          this.feedbacklist[i].optionList[0].maxLength +
          " characters";
        this.feedbacklist[i].minLen = parseInt(
          this.feedbacklist[i].optionList[0].minLength
        );
        this.feedbacklist[i].maxLen = parseInt(
          this.feedbacklist[i].optionList[0].maxLength
        );
        this.feedbacklist[i].optionList[0]["sAns"] = "";
      } else {
        let temp = parseInt(this.feedbacklist[i].optionList[0].noStar);
        let tempRatingObj = {};
        let tempRatingArr = [];
        for (let j = 0; j < temp; j++) {
          tempRatingObj = {
            option: j + 1,
          };
          tempRatingArr.push(tempRatingObj);
        }
        this.feedbacklist[i].rating = tempRatingArr;
        // this.feedbacklist[i]['sRateAns'] = null;
        this.feedbacklist[i]["sRateAns"] = 0;
      }
    }
  }

  checkIfValidInputText(currentIndex) {
    let currentQuestion = this.feedbacklist[currentIndex];
    if (
      String(currentQuestion.optionList[0].sAns).length + 1 <
        currentQuestion.minLen ||
      this.name.value.length < currentQuestion.minLen
    ) {
      currentQuestion["isValid"] = false;
      currentQuestion["msg"] =
        "Answer must at least have" +
        " " +
        currentQuestion["minLen"] +
        " " +
        "character.";
    } else {
      if (currentQuestion.isMandatory === 1) {
        if (!currentQuestion.optionList[0].sAns) {
          currentQuestion["isValid"] = false;
          currentQuestion["msg"] = "Above question is mandatory";
        } else {
          currentQuestion["isValid"] = true;
        }
      } else {
        currentQuestion["isValid"] = true;
      }
    }
  }

  checkIfValidInputSelect(currentIndex) {
    let currentQuestion = this.feedbacklist[currentIndex];
    const selectedAnd = [];
    for (let j = 0; j < currentQuestion.optionList.length; j++) {
      if (currentQuestion.optionList[j].sFlag) {
        selectedAnd.push(currentQuestion.optionList[j]);
      }
    }
    if (currentQuestion.isMandatory === 1) {
      if (selectedAnd.length > 0) {
        currentQuestion["isValid"] = true;
      } else {
        currentQuestion["isValid"] = false;
        currentQuestion["msg"] = "Above question is mandatory";
      }
    } else {
      currentQuestion["isValid"] = true;
    }
  }

  checkIfValidInputStar(currentIndex) {
    let currentQuestion = this.feedbacklist[currentIndex];
    // if (!currentQuestion.sRateAns) {
    //   currentQuestion['isValid'] = false;
    // } else {
    if (currentQuestion.isMandatory === 1) {
      if (!currentQuestion.sRateAns) {
        currentQuestion["isValid"] = false;
        currentQuestion["msg"] = "Above question is mandatory";
      } else {
        currentQuestion["isValid"] = true;
      }
    } else {
      currentQuestion["isValid"] = true;
    }
    // currentQuestion['isValid'] = true;
  }

  checkIfValidForm() {
    let ansSubmitted = [];
    for (let i = 0; i < this.feedbacklist.length; i++) {
      const currentQuestion = this.feedbacklist[i];
      if (currentQuestion.questionTypeId === 1) {
        this.checkIfValidInputText(i);
        if (currentQuestion.isValid) {
          ansSubmitted.push(currentQuestion);
        }
      } else if (
        currentQuestion.questionTypeId === 2 ||
        currentQuestion.questionTypeId === 3
      ) {
        this.checkIfValidInputSelect(i);
        if (currentQuestion.isValid) {
          ansSubmitted.push(currentQuestion);
        }
      } else if (currentQuestion.questionTypeId === 4) {
        this.checkIfValidInputStar(i);
        if (currentQuestion.isValid) {
          ansSubmitted.push(currentQuestion);
        }
      }
    }
    if (
      this.feedbacklist.length > 0 &&
      ansSubmitted.length === this.feedbacklist.length
    ) {
      this.formIsValid = true;
      // return true;
    } else {
      this.formIsValid = false;
      // return false;
    }
  }

  submitFeedbackForm() {
    // this.load.presentLoading('Please wait while your answer(s) being submitted...');
    this.disableSubmit = true;
    this.checkIfValidForm();
    if (this.formIsValid) {
      this.resultsubmit();
    } else {
      //  this.presentAlert('Please answer the questions.');
      //  this.load.dismiss();
      this.disableSubmit = false;
    }
  }
  makeFeedbackQuestionReady(question) {
    let questionFinal;
    if (question.isMandatory == 1) {
      questionFinal = question.fName + ' <b class="mandatoryQues">*</b>';
      // return this.sanitizer.bypassSecurityTrustHtml(questionFinal);
    } else {
      questionFinal = question.fName;
    }
    return questionFinal;
  }

  submitFeedbackFormTTT(isPublishflag) {
    // this.load.presentLoading('Please wait while your answer(s) being submitted...');
    this.disableSubmit = true;
    this.checkIfValidForm();
    if (this.formIsValid) {
      this.resultsubmitTTT(isPublishflag);
    } else {
      this.disableSubmit = false;
    }
  }

  isInternalBack() {
    this.sendInternalEvent("goBack", null);
  }

  sendInternalEvent(event, data) {
    const dataOutput = {
      action: event,
      data: data,
    };
    this.publishInternalEvent.emit(dataOutput);
  }

  getQuestionsForSection(sectionName: string, startIndex: number): any[] {
    return this.feedbacklist.filter((q, index) => q.secName === sectionName && index >= startIndex);
  }
}
