import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { popupConfig } from '../../interfaces/popup-config.model';

@Component({
  selector: 'ngx-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss', '../../@theme/styles/_typography.scss']
})
export class PopupComponent implements OnInit {
  @Output() actionEvent = new EventEmitter<any>();
  @Input() show = false;
  @Input() popupConfig: popupConfig = {
    header: true,
    title: '',
    footer: false,
    buttons: [],
    transparentHeader: {
      show: false,
    },
  }

  // {
  //   action: "",
  //   btnText: "Save",
  //   className: 'button-sm btn-fill/outline', 
  // },

  constructor() { }

  ngOnInit(): void {
  }

  closeClick() {
    this.fireEvent('close', null);
  }

  fireEvent(inEvent, inData) {
    const res = {
      type: inEvent,
      data: inData,
    };
    this.actionEvent.emit(res);
  }
}
