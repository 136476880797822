import { Injectable } from '@angular/core';
import { webApi } from '../../../../../config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventsService } from '../../../../providers/events/events.service';
import { ToasterService } from '../../../../providers/toaster/toaster.service';

@Injectable({
  providedIn: 'root'
})
export class MarkAttendanceService {

  baseUrl: any = webApi.baseUrl;
  private headers = new HttpHeaders();

  generateQRUrl = this.baseUrl + webApi.apiUrl.generateQr;

  downloadSampleFileUrl = this.baseUrl + webApi.apiUrl.downloadBulkAttendance;
  uploadFileUrl = this.baseUrl + webApi.apiUrl.uploadBulkAttendance;

  excelUploadConfig = {
    fileClass: '',
    innerClass: 'text-center',
    titleSection: {
      show: true,
      class: 'text-22-px font-weight-bold',
      message: 'Upload your file',
    },
    titleNote: {
      show: true,
      class: 'text-12-px text-gray py-3',
      message: 'Supported file type: excel',
    },
    fileinput: {
      show: true,
      class: 'd-flex flex-column align-items-center justify-content-center bg-light position-relative h-10-rem w-80 dash-border rounded p-3 mx-auto',
      message: 'Supported file type: excel',
      innerHtml: '<div class="text-14-px py-1">Drag & Drop file here or <span class="text-primary link">Click here</span> for upload</div>',
      icon: {
        show: true,
        class: 'stroke-primary fill-primary-light',
        iconRef: 'assets/icon-svg/File_text_icon.svg',
        style: { 'width.rem': 3, 'height.rem': 3, 'stroke-width': .5 },
      },
    },
    footerIconText: {
      show: true,
      message: 'Download Template',
      messageClass: 'text-primary text-14-px font-weight-bold px-1',
      wrapperClass: 'd-flex align-items-center justify-content-center py-4',
      icon: {
        show: true,
        iconRef: 'assets/icon-svg/Download_icon.svg',
        class: 'stroke-primary',
        style: { 'width.rem': 1, 'height.rem': 1, 'stroke-width': 2.2 },
      },
    },
    maxExcelUpload: '26214400',
    uploadType: 1, // 1 direct excel upload , 2 - upload in json object , 3 - upload in sepearator string format,
    directUploadFlag: 1,
    sampelFile: '',
    sampelFileAPI: this.downloadSampleFileUrl,
    // sampelFileAPI: '',
    extraParams: {},
    downloadAPI: '',
    uploadFileAPI: this.uploadFileUrl,
    // uploadFileAPI: '',
    labels: [],
    tabelData: [],
    fileUploaded: false,
    showTopUpload: false,
    uploadedMessage: '1/65 Details Created',
  }

  constructor(
    public http: HttpClient,
    public events: EventsService,
    private toasterService: ToasterService,
  ) { }

  public generateQR(params: any): Promise<any> {
    return this.http.post(this.generateQRUrl, params, { headers: this.headers })
      .toPromise()
      .then(response => {
        // return response
        return response;
      })
      .catch(this.handleError);
  }

  private handleError(error: Response | any) {
    return Promise.reject(error.message || error);
  }
}
