import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { popupConfig } from '../../interfaces/popup-config.model';
import { SharedService } from '../../providers/shared.service';
import { ToasterService } from '../../providers/toaster/toaster.service';

@Component({
  selector: 'ngx-reason-popup',
  templateUrl: './reason-popup.component.html',
  styleUrls: ['./reason-popup.component.scss']
})
export class ReasonPopupComponent implements OnInit {

  @Input() config: any = {};
  @Output() actionEvent = new EventEmitter<any>();

  popupConfig: any = {
    title: '',
    footer: true,
    buttons: [
      {
        action: "cancel",
        btnText: "Cancel",
        className: 'button-sm btn-outline',
      },
      {
        action: "next",
        btnText: "Save",
        className: 'button-sm btn-fill',
      },
    ],
    transparentHeader: {
      show: true,
    },
    reason: '',
    placeHolder: '',
    validation: {
      required: false,
      minLength: null,
      maxLength: null,
      errorMsgRequired: '',
      errorMsgMinLength: '',
      errormaxLength: ''
    },

    editable: true,

  }

  isFormValid = false;
  closeEvent: any;

  constructor(
    protected ref: NbDialogRef<ReasonPopupComponent>,
    private toaster: ToasterService,
    private sharedService: SharedService
  ) {
    console.log('ReasonPopupComponent config :', this.config);
    console.log('ReasonPopupComponent ref :', ref);
  }

  ngOnInit(): void {
    this.resetValues();
    console.log('ngOnInit ReasonPopupComponent config :', this.config);
    if (this.sharedService.checkIfParams(this.config)) {
      this.popupConfig = { ...this.popupConfig, ...this.config };
    }
  }

  ngOnChanges(changes: any) {
    console.log('ReasonActionSheetComponent changes called...', changes);
    try {
      if ('config' in changes) {
        this.popupConfig = { ...this.popupConfig, ...this.config };
      }
    } catch (error) {
      console.log('EventsCalendarComponent changes error', error);
    }
  }

  resetValues() {
    this.isFormValid = true;
    this.closeEvent = {
      action: "close",
      btnText: "",
      classList: '',
      data: null
    };
  }

  cancel(event) {
    // this.fireEvent(event, null);
    this.ref.close(this.closeEvent);
  }

  next(event) {
    const errorObj = this.validateForm(this.popupConfig.reason);
    console.log('Form is valid...', errorObj, this.popupConfig);
    if (errorObj.isValid) {
      // this.fireEvent(event, this.config.reason);
      this.closeEvent.action = event;
      this.closeEvent.data = this.popupConfig.reason;
      this.ref.close(this.closeEvent);
    } else {
      this.isFormValid = false;
      this.presentAlert(errorObj.reason, 'warning', 'warning');
    }
  }

  validateForm(reason) {
    const obj = {
      isValid: true,
      reason: ''
    }
    const regEx = new RegExp("^[a-zA-Z0-9 ]+$");
    if (reason == '') {
      obj.isValid = false;
      obj.reason = this.popupConfig.validation.errorMsgRequired;
    } else if (!regEx.test(reason)) {
      obj.isValid = false;
      obj.reason = 'Special charcters are not allowed';
    } else if (String(reason).length < this.popupConfig.validation.minLength) {
      obj.isValid = false;
      obj.reason = this.popupConfig.validation.errorMsgMinLength;
    } else if (String(reason).length > this.popupConfig.validation.maxLength) {
      obj.isValid = false;
      obj.reason = this.popupConfig?.validation?.errormaxLength;
    }
    return obj;
  }

  fireEvent(inEvent, inData) {
    const res = {
      type: inEvent,
      data: inData,
    };
    this.actionEvent.emit(res);
  }

  performActions(event) {
    switch (event.type) {
      case 'close':
        this.cancel(event.type)
        break;
      case 'cancel':
        this.cancel(event.type)
        break;
      case 'next':
        this.next(event.type)
        break;
      case 'submit':
        this.next(event.type)
        break;
      default:
        break;
    }
  }

  async presentAlert(msg, title, type) {
    await this.toaster.prsentToast(msg, title, type, null);
  }

}
